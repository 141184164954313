import React, {
	ChangeEvent,
	FC,
	memo,
	useCallback,
	useEffect,
	useState
} from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { FormControlLabel, RadioGroup } from '@material-ui/core'
import cx from 'classnames'
import { isEqual } from 'lodash'
import Numeral from 'numeral'

import { onViewerModelError } from '../SolutionAnalysisActions'
import {
	convertCoDataWithUnits,
	getCoDataValue
} from '../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostComparisonService'
import SolutionCustomOrientation from '../SolutionCustomOrientation'
import OrientationAnalysisResults from './OrientationAnalysisResults'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Card from 'Scenes/Components/Card/Card'
import { CastorPartBenefits } from 'Scenes/Components/CastorPartBenefits/CastorPartBenefits'
import CastorRadio from 'Scenes/Components/CastorRadio'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import { useSolutionReducer } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import PartCardFooter from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/PartCard/PartCardFooter'
import { printStatusScore } from 'Services/Constants'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { displayInRange } from 'Services/global/calculateRange'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { OrientationData } from 'Services/models/IOrinetationData'
import { Part } from 'Services/models/IPart'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import { PrintIssueId } from 'Services/models/PartPrintIssue'
import { getIssue, getResultScore } from 'Services/PrintIssueService'
import {
	CUSTOMIZE_FORM_COST_AND_LEAD_HEADER,
	EMISSIONS_COMPARISON_CO2,
	LAYERS,
	SOLUTION_ORIENTATION_HEADER,
	SUPPORT
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './SolutionOrientation.scss'

const RadioGroupTSX: any = RadioGroup
const FormControlLabelTSX: any = FormControlLabel
const MIN_ORIENTATION_COUNT = 3
const MIN_ORIENTATION_INDEX = 2

interface IProps {
	showChangeOrientationModal: boolean
	loadingCalculation: boolean
	onCancel: Function
	onConfirm: Function
	orientationsData: OrientationData[]
	configuration: any
	orientationModalExplanationText: string
	orientationInfoButtonClicked: Function
	part: Part
	solution: any
	chosenOrientationVector: number[]
	isAmOriginalMaterial: boolean
	tempSolutionPostProcessToggles: any
	configurationPrintIssues: PartPrintIssue[]
}

const renderBenefits = (
	{ benefits, analysisResult }: OrientationData,
	indexOrientation: number,
	customProps: Record<string, any>,
	isAmOriginalMaterial: boolean
) => {
	const displayBenefit = benefits || []
	const orientationBoxDirection =
		indexOrientation > MIN_ORIENTATION_INDEX ? 'auto-end' : 'left-start'

	return (
		<div className="grid-list-benefits">
			<CastorPartBenefits
				customProps={customProps}
				showHoverData={false}
				benefits={displayBenefit || []}
				small
				iconClassName="solution-orientation--orientations--card--content--icon"
				className="grid-list-benefits"
				isAmOriginalMaterial={isAmOriginalMaterial}
			/>
			<InfoBox
				boxContactElement={
					<OrientationAnalysisResults analysisResultsRows={analysisResult} />
				}
				boxClassName="analysis-result-hover-box"
				boxDirection={orientationBoxDirection}
				boxContactClassName="analysis-result-hover-box--contact"
				inPortal
			/>
		</div>
	)
}

const SolutionOrientation: FC<IProps> = ({
	showChangeOrientationModal,
	onCancel,
	onConfirm,
	orientationsData,
	loadingCalculation,
	orientationInfoButtonClicked,
	chosenOrientationVector: chosenOrientationVectorProp,
	solution,
	configuration,
	part,
	tempSolutionPostProcessToggles,
	configurationPrintIssues
}) => {
	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES
	)
	const { isAmOriginalMaterial } = useSolutionReducer(configuration.id)
	const disableLightUserOrientation = !Feature.isFeatureActive(
		FeatureComponentId.TRAY_ORIENTATION
	)
	const showSmallItems = orientationsData.length <= MIN_ORIENTATION_COUNT
	const [radioValue, setRadioValue] = useState<string>()
	const [currentChosenOrientationVector, setCurrentChosenOrientationVector] =
		useState<number[]>()
	const [showCustomOrientationAlert, setShowCustomOrientationAlert] =
		useState<boolean>(false)
	const [customOrientationCreated, setCustomOrientationCreated] =
		useState<boolean>(false)
	const [chosenOrientationVector, setChosenOrientationVectorState] = useState<
		number[]
	>([])
	const prevChosenOrientationVector = usePrevious(chosenOrientationVectorProp)

	useEffect(() => {
		if (
			!isEqual(prevChosenOrientationVector, chosenOrientationVectorProp) &&
			!loadingCalculation
		) {
			setChosenOrientationVectorState(chosenOrientationVectorProp)
		}
	}, [
		chosenOrientationVectorProp,
		prevChosenOrientationVector,
		loadingCalculation
	])

	useEffect(() => {
		if (
			chosenOrientationVector?.length > 0 &&
			orientationsData &&
			!loadingCalculation
		) {
			setCurrentChosenOrientationVector(chosenOrientationVector)
			const select_orientation = orientationsData.find(
				(orientation: OrientationData) =>
					(isEqual(orientation.trayNormalVector, chosenOrientationVector) &&
						!orientation.configurationId) ||
					(isEqual(orientation.trayNormalVector, chosenOrientationVector) &&
						orientation.configurationId === configuration.id)
			)
			if (select_orientation) {
				setRadioValue(select_orientation.name)
			}
		}

		const customOrientationCreated = orientationsData.find(
			orientation => orientation.configurationId == configuration.id
		)
		setCustomOrientationCreated(!!customOrientationCreated)
	}, [
		orientationsData,
		chosenOrientationVector,
		configuration.id,
		loadingCalculation
	])

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const chosenOrientation: OrientationData | undefined =
			(orientationsData &&
				orientationsData.length &&
				orientationsData.find(
					(orientation: OrientationData) => orientation.name === e.target.value
				)) ||
			undefined
		if (!chosenOrientation?.failOnSize) {
			setRadioValue(e.target.value)
			setCurrentChosenOrientationVector(chosenOrientation?.trayNormalVector)
		}
	}

	const onAlertCancel = () => {
		setCurrentChosenOrientationVector(chosenOrientationVector)
		onCancel()
	}

	const onAlertConfirm = () => {
		onConfirm(false, currentChosenOrientationVector)
	}

	const renderOrientationLabel = (
		orientation: OrientationData,
		selected: boolean,
		orientationInfoButtonClicked: Function,
		indexOrientation: number
	) => {
		const {
			totalCost: basicTotalCost,
			maxCostDetails,
			minCostDetails
		} = orientation?.costDetails || {}

		let totalCost = Numeral(basicTotalCost).format('0,0')

		//Check ranges is ON
		if (isShowValuesInRanges && minCostDetails && maxCostDetails) {
			totalCost = displayInRange(
				minCostDetails?.totalCost,
				maxCostDetails?.totalCost,
				totalCost
			)
		}
		const alwaysShowCO2Details = !!Feature.isFeatureOn(
			FeatureComponentId.ALWAYS_SHOW_CO2_DETAILS
		)
		const showCo2Benefit = !!Feature.isFeatureOn(FeatureComponentId.CO2_BENEFIT)

		const { coData, CO2HasPotential } = getCoDataValue(
			orientation.gainCO2,
			alwaysShowCO2Details
		)

		const convertedCoData = convertCoDataWithUnits(coData)

		const fullTrayAssumption =
			solution?.costDetails?.threeDPrintingCostsBreakDown?.fullTrayAssumption

		if (!orientation?.costDetails) {
			orientation.result = CADAnalysisResult.failed

			return (
				<Card
					imageUrl={orientation.imageURL}
					addSizeWrapper={false}
					title={`${CUSTOMIZE_FORM_COST_AND_LEAD_HEADER}: $${totalCost}`}
					customContent={<></>}
					className={`solution-orientation--orientations--card ${
						selected ? 'card--selected' : ''
					}`}
					contentTitleClassName="solution-orientation--orientations--card--content--title"
					imageClassName="solution-orientation--orientations--card--image"
					contentClassName="solution-orientation--orientations--card--content"
					sizeWrapperClassName="solution-orientation--orientations--card--image--wrapper"
					footer={renderCardFooter(
						orientation,
						orientationInfoButtonClicked,
						indexOrientation,
						{}
					)}
				/>
			)
		}

		return (
			<Card
				imageUrl={orientation.imageURL}
				addSizeWrapper={false}
				title={`${CUSTOMIZE_FORM_COST_AND_LEAD_HEADER}: $${totalCost}`}
				customContent={
					<Flexbox
						flexDirection={'column'}
						className="orientation-custom-content"
					>
						<Flexbox
							justifyContent={'space-between'}
							className="main-text"
							data-qa="data-qa-orientation-cost-estimation"
						>
							<div>{CUSTOMIZE_FORM_COST_AND_LEAD_HEADER}</div>
							<div data-qa="data-qa-orientation-cost-estimation-value">
								${totalCost}
							</div>
						</Flexbox>
						{solution?.printerTechnology?.isUseSupport ? (
							<Flexbox
								justifyContent={'space-between'}
								className="sub-text"
								data-qa="data-qa-orientation-support"
							>
								<div>{SUPPORT}</div>
								<div data-qa="data-qa-orientation-support-value">
									{orientation.supportMassPercentage}%
								</div>
							</Flexbox>
						) : (
							<></>
						)}
						<Flexbox
							justifyContent={'space-between'}
							data-qa="data-qa-orientation-layers"
						>
							<div>{LAYERS}</div>
							<div data-qa="data-qa-orientation-layers-value">
								{Numeral(Math.round(orientation.numberOfLayers)).format('0,0')}
							</div>
						</Flexbox>
						{fullTrayAssumption != null && !fullTrayAssumption ? (
							''
						) : (
							<Flexbox justifyContent={'space-between'}>
								<div data-qa="data-qa-orientation-parts-per-build">
									{getString('ORIENTATION_PART_PER_BUILD')}
								</div>
								<div data-qa="data-qa-orientation-parts-per-build-value">
									{orientation.numberOfPartsPerTray}
								</div>
							</Flexbox>
						)}
						{!isAmOriginalMaterial && CO2HasPotential ? (
							<Flexbox
								justifyContent={'space-between'}
								className="saving-text"
								data-qa="data-qa-orientation-co2-savings"
							>
								<div>{getString('ORIENTATION_POTENTIAL_SAVINGS')}</div>
								<div data-qa="data-qa-orientation-co2-savings-value">
									{convertedCoData.total.weight} {convertedCoData.total.unit}
									{EMISSIONS_COMPARISON_CO2}
								</div>
							</Flexbox>
						) : (
							!isAmOriginalMaterial && (
								<Flexbox justifyContent={'space-between'}>
									<div>{getString('ORIENTATION_POTENTIAL_SAVINGS')}</div>
									<div>{getString('NO_POTENTIAL_SAVINGS_FOUND')}</div>
								</Flexbox>
							)
						)}
					</Flexbox>
				}
				className={`solution-orientation--orientations--card ${
					selected ? 'card--selected' : ''
				}`}
				contentTitleClassName="solution-orientation--orientations--card--content--title"
				imageClassName="solution-orientation--orientations--card--image"
				contentClassName="solution-orientation--orientations--card--content"
				sizeWrapperClassName="solution-orientation--orientations--card--image--wrapper"
				footer={renderCardFooter(
					orientation,
					orientationInfoButtonClicked,
					indexOrientation,
					{
						showCo2Info: showCo2Benefit,
						gainCO2: orientation.gainCO2,
						coData,
						convertedCoData,
						alwaysShowCO2Details,
						CO2HasPotential
					}
				)}
			/>
		)
	}
	const renderCardFooter = (
		orientation: OrientationData,
		orientationInfoButtonClicked: Function,
		indexOrientation: number,
		customProps: Record<string, any>
	) => {
		if (!orientation.analysisResult || !orientation.analysisResult.length) {
			return (
				<PartCardFooter
					customProps={customProps}
					result={orientation.result}
					benefits={orientation.benefits || []}
					orientationInfoButtonClicked={orientationInfoButtonClicked}
					benefitIconClassName="solution-orientation--orientations--card--content--icon"
					labelTextClassName="solution-orientation--orientations--card--content--label-text"
					showHoverData={false}
					isMissingInfo={
						orientation.status === CADAnalysisResult.missingInformation
					}
					configuration={configuration}
				/>
			)
		}

		return (
			<PartCardFooter
				customProps={customProps}
				result={orientation.result}
				orientationInfoButtonClicked={orientationInfoButtonClicked}
				labelTextClassName="solution-orientation--orientations--card--content--label-text"
				customBenefitSection={renderBenefits(
					orientation,
					indexOrientation,
					customProps,
					isAmOriginalMaterial
				)}
				showHoverData={false}
				configuration={configuration}
			/>
		)
	}

	const handleCustomOrientationChoose = () => {
		const customOrientation = orientationsData.find(
			orientation => orientation.configurationId
		)
		if (customOrientation) {
			setCurrentChosenOrientationVector(customOrientation?.trayNormalVector)
			setRadioValue(customOrientation.name)
		}
	}

	const renderRadioSelector = () => (
		<RadioGroupTSX
			name="orientation"
			value={radioValue}
			onChange={handleChange}
			class="solution-orientation--orientations"
		>
			{orientationsData ? (
				orientationsData.map((orientation: OrientationData, idx: number) => {
					const disabledOrientation =
						orientation.status === CADAnalysisResult.failed ||
						orientation.result === CADAnalysisResult.notPrintable

					return (
						<FormControlLabelTSX
							key={orientation.name}
							disabled={disabledOrientation || disableLightUserOrientation}
							value={orientation.name}
							control={<CastorRadio className="solution-orientation--radio" />}
							className={cx('solution-orientation--wrapper', {
								'small-item': showSmallItems,
								'hidden-orientation': orientation.configurationId
							})}
							classes={{
								label: `solution-orientation--label--wrapper`
							}}
							label={renderOrientationLabel(
								orientation,
								radioValue === orientation.name,
								orientationInfoButtonClicked,
								idx
							)}
						/>
					)
				})
			) : (
				<div />
			)}
		</RadioGroupTSX>
	)

	// added specific print issues to disable custom configuration
	const disableCustomConfigurationAccordingToPrintIssue = useCallback(() => {
		const threadsIssueConfiguration = getIssue({
			printIssuesList: configurationPrintIssues,
			printIssueId: PrintIssueId.Threads,
			isPartLevel: false
		})
		const isIssueExistAndActive =
			threadsIssueConfiguration && threadsIssueConfiguration?.active
		const score = isIssueExistAndActive
			? getResultScore(threadsIssueConfiguration)
			: null
		return isIssueExistAndActive ? score === printStatusScore.failed : false
	}, [configurationPrintIssues])

	const customOrientation = Feature.isFeatureOn(
		FeatureComponentId.CUSTOM_ORIENTATION
	)

	const renderOrientationContent = () => {
		const disableCustomConfiguration =
			disableCustomConfigurationAccordingToPrintIssue()

		return (
			<Flexbox alignItems="flex-start" overflow="hidden">
				<Flexbox
					data-qa="data-qa-orientation-explanation"
					flexDirection="column"
					alignItems="flex-start"
					className="solution-orientation--explanation"
				>
					<div>{SOLUTION_ORIENTATION_HEADER[0]}</div>
					<br />
					<div>{SOLUTION_ORIENTATION_HEADER[1]}</div>
					<br />
					<div>
						{SOLUTION_ORIENTATION_HEADER[2]}
						{customOrientation && SOLUTION_ORIENTATION_HEADER[3]}
						{SOLUTION_ORIENTATION_HEADER[4]}
					</div>
					<br />
					{customOrientation && orientationsData ? (
						orientationsData
							?.filter(
								(orientation: OrientationData) =>
									orientation.configurationId === configuration.id
							)
							?.map((orientation: OrientationData, idx: number) => {
								const disabledOrientation =
									orientation.status === CADAnalysisResult.failed ||
									orientation.result === CADAnalysisResult.notPrintable

								return (
									<FormControlLabelTSX
										key={orientation.name}
										disabled={
											disabledOrientation || disableLightUserOrientation
										}
										value={orientation.name}
										control={
											<CastorRadio
												checked={radioValue === orientation.name}
												className="solution-orientation--radio"
												onChange={handleCustomOrientationChoose}
											/>
										}
										className={cx('solution-orientation--wrapper', {
											'small-item': showSmallItems,
											'custom-orientation': orientation.configurationId
										})}
										classes={{
											label: `solution-orientation--label--wrapper`
										}}
										label={renderOrientationLabel(
											orientation,
											radioValue === orientation.name,
											orientationInfoButtonClicked,
											idx
										)}
									/>
								)
							})
					) : (
						<div />
					)}
					{customOrientation && !disableCustomConfiguration && (
						<ButtonWithLoader
							className="solution-orientation-custom"
							color="primary"
							disabled={loadingCalculation}
							onClick={() => setShowCustomOrientationAlert(true)}
						>
							{customOrientationCreated
								? getString('CHANGE_CUSTOM_ORIENTATION')
								: getString('ADD_CUSTOM_ORIENTATION')}
						</ButtonWithLoader>
					)}
				</Flexbox>

				{renderRadioSelector()}
			</Flexbox>
		)
	}

	return (
		<>
			<CastorAlert
				disabled={disableLightUserOrientation}
				headerTitle={getString('PRINTING_ORIENTATION_ALERT_TITLE')}
				onCancel={onAlertCancel}
				show={showChangeOrientationModal}
				onConfirm={onAlertConfirm}
				alertClass="solution-orientation--alert"
				alertBodyClass="solution-orientation--alert--body"
				loadingCalculation={loadingCalculation}
				fullScreen
				onButtonHoverText={
					disableLightUserOrientation
						? getString('LIGHT_USER_BUTTONS_INFORMATION')
						: ''
				}
				buttonHoverClassName={
					disableLightUserOrientation ? 'details-popup--contact-us' : ''
				}
			>
				{orientationsData?.length ? renderOrientationContent() : <div />}

				<SolutionCustomOrientation
					solution={solution}
					part={part}
					configuration={configuration}
					showAlert={showCustomOrientationAlert}
					customOrientationCreated={customOrientationCreated}
					onViewerModelError={(error: any) =>
						onViewerModelError(configuration.id, error)
					}
					onCancel={() => setShowCustomOrientationAlert(false)}
					onConfirm={() => setShowCustomOrientationAlert(false)}
					tempSolutionPostProcessToggles={tempSolutionPostProcessToggles}
					configurationPrintIssues={configurationPrintIssues}
				/>
			</CastorAlert>
		</>
	)
}

export default WithFeatureToggleHOC(
	memo(SolutionOrientation),
	FeatureComponentId.TRAY_ORIENTATION
)
