import { FC, memo } from 'react'

import cx from 'classnames'

import ProjectPartItem from '../PartsThumbnailView/ProjectPartItem'
import { format2D } from 'Scenes/Home/NewUploadProject/constants'
import { InapplicableStatus } from 'Services/Constants'

interface ProjectPartTileProps {
	suggestion: any
	isBundle: boolean
	starred: boolean
}

const ProjectPartTile: FC<ProjectPartTileProps> = ({ suggestion, starred }) => {
	if (
		suggestion.isDrawing &&
		suggestion.drawingStatusCode === InapplicableStatus
	) {
		return <></>
	}

	const { formatType } = suggestion

	return (
		<ProjectPartItem
			starred={starred}
			suggestion={suggestion}
			cardClassName="single-part-project--part--card"
			cardSizeWrapperClassName={cx(
				'single-part-project--part--card--size-wrapper',
				{ drawing: formatType === format2D }
			)}
		/>
	)
}

export default memo(ProjectPartTile)
