import React, { FC, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import {
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import cx from 'classnames'

import { Route } from '../SideBarTypes'
import UpdateProjectName from '../ViewItem/UpdateProjectName'
import { useMouseBoundary } from './DraggableHooks'
import ProjectsListWrapper from './ProjectsListWrapper'
import {
	onUpdateFolderName,
	onUserFolderDelete,
	updateEditId
} from 'global actions'
import { FOLDER } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	classes: Record<string, any>
	color: string
	caret: string
	rtlActive?: boolean
	collapseItemText: string
	itemIcon: string
	itemText: string
	state: {
		[key: string]: boolean
	}
	view: Route
	viewKey: number
	currentPathname: string
	nestLevel?: number
	isDropDisabled?: boolean
	disableDrag?: boolean
	setIsDropDisabled?: (disabled: boolean) => void
	activeRoute: (routeName: string, folderId?: string) => boolean
	openCollapse: (collapse: string) => void
}

const DraggableFolder: FC<IProps> = ({
	color,
	classes,
	caret,
	rtlActive,
	collapseItemText,
	itemIcon,
	itemText,
	state,
	view,
	viewKey,
	currentPathname,
	nestLevel = 0,
	isDropDisabled,
	disableDrag,
	activeRoute,
	openCollapse,
	setIsDropDisabled
}) => {
	const { editId } = useSelector((s: RootStateOrAny) => s.userHome)
	const [disableFolder, setDisableFolder] = useState(true)
	const dispatch = useDispatch()
	const navLinkClasses = cx(classes.collapseItemLink)
	const [folderName, setFolderName] = useState(view.name)

	const onFolderDeleteClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
		dispatch(onUserFolderDelete(view.id))
	}

	const Icon: JSX.Element | any = view.icon

	const onFolderMouseDown = () => {
		if (setIsDropDisabled) {
			setIsDropDisabled(true)
		}
	}

	const onFolderMouseUp = () => {
		if (setIsDropDisabled && isDropDisabled) {
			setIsDropDisabled(false)
		}
		openCollapse(view.state)
	}

	useMouseBoundary({
		id: view.id,
		onEnter: () => {
			setDisableFolder(false)
		},
		onLeave: () => {
			setDisableFolder(true)
		},
		isDropDisabled
	})

	return (
		<Draggable
			draggableId={`${FOLDER}-${view.id}`}
			index={viewKey}
			key={`${FOLDER}-${view.id}`}
			isDragDisabled={!!disableDrag}
		>
			{(providedDraggable, snapshotDraggable) => (
				<div
					className={cx({ 'dragging-folder': snapshotDraggable.isDragging })}
					ref={providedDraggable.innerRef}
					{...providedDraggable.draggableProps}
					id={view.id}
				>
					<Droppable
						droppableId={view.id as string}
						key={view.id}
						isDropDisabled={disableFolder || isDropDisabled}
					>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={cx('droppable-zone', {
									'dragging-over': snapshot.isDraggingOver
								})}
							>
								<ListItem className={cx('sidebar--user-folder')}>
									{editId === view.id ? (
										<div className="sidebar--projects-toolbar--new-folder--input">
											<UpdateProjectName
												oldProjectName={folderName}
												id={view.id as string}
												onUpdateName={onUpdateFolderName}
												placeholder={getString('EDIT_FOLDER_PLACEHOLDER')}
											/>
										</div>
									) : (
										<NavLink
											to={'#'}
											className={cx(
												navLinkClasses,
												'sidebar--user-folder--link'
											)}
											onMouseDown={onFolderMouseDown}
											onMouseUp={onFolderMouseUp}
											{...providedDraggable.dragHandleProps}
										>
											<ListItemIcon className={itemIcon}>
												{view.icon ? <Icon /> : <></>}
											</ListItemIcon>
											<ListItemText
												title={folderName}
												primary={
													<div
														className="sidebar--user-folder--folder-name-wrapper"
														data-qa={`data-qa-sidebar-folder-${view.name}`}
													>
														<div className="sidebar--user-folder--folder-name">
															<span>{folderName}</span>
														</div>
														<div className="sidebar--user-folder--folder-name-button-wrapper">
															<EditIcon
																data-qa={`data-qa-sidebar-remove-folder-${folderName}`}
																onClick={(e: React.MouseEvent) => {
																	e.preventDefault()
																	dispatch(updateEditId(view.id))
																}}
																className={cx(
																	'clear-input',
																	'sidebar--user-folder--delete-or-edit-icon'
																)}
															/>
															<ClearIcon
																data-qa={`data-qa-sidebar-remove-folder-${folderName}`}
																onClick={e => onFolderDeleteClick(e)}
																className={cx(
																	'clear-input',
																	'sidebar--user-folder--delete-or-edit-icon'
																)}
															/>
														</div>
													</div>
												}
												secondary={
													<b
														className={cx(caret, {
															'sidebar--user-folder--caret': true,
															[classes.caretActive]: state[view.state]
														})}
													/>
												}
												disableTypography={true}
												className={`${itemText} sidebar--text`}
												id={`sidebar-my-projects-folder-${view.id}`}
											/>
										</NavLink>
									)}
									<Collapse
										in={!!state[view.state]}
										unmountOnExit
										classes={{ root: 'sidebar--user-folder--nested-collapse' }}
									>
										<List
											className={cx(
												classes.list,
												classes.collapseList,
												'collapse-block__list',
												'sidebar--user-folder--list'
											)}
										>
											<ProjectsListWrapper
												list={view}
												listIndex={viewKey}
												caret={caret}
												state={state}
												itemIcon={itemIcon}
												itemText={itemText}
												classes={classes}
												color={color}
												rtlActive={rtlActive}
												collapseItemText={collapseItemText}
												activeRoute={activeRoute}
												openCollapse={openCollapse}
												currentPathname={currentPathname}
												disableDrag={disableDrag}
												nestLevel={nestLevel + 1}
											/>
											{provided.placeholder}
										</List>
									</Collapse>
								</ListItem>
							</div>
						)}
					</Droppable>
				</div>
			)}
		</Draggable>
	)
}

export default DraggableFolder
