import React, { FC, memo, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
	connect,
	DispatchProp,
	RootStateOrAny,
	useDispatch,
	useSelector
} from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import { GridList, GridListTile } from '@material-ui/core'
import { isBoolean } from 'lodash'

import * as ProjectAnalysisActions from '../../Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import { setupProjectAnalysis } from '../../Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import Card from '../Card/Card'
import NavBarAndMaterial from '../NavBarAndMaterial'
import TransparentButton from '../TransparentButton'
import WeightReductionProgress from '../WeightReductionProgress/index'
import {
	getWeightReducedValue,
	getWeightReductionRate
} from './WeightReductionService'
import { getProjectsBundle } from 'Scenes/Home/ProjectBundle/ProjectAnalysisBundle/ProjectBundleActions'
import { getProjectInformation } from 'Scenes/Home/ProjectPage/ProjectPageAction'
import Loader from 'Scenes/Loader/Loader'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { partConfigurationsRoute } from 'Services/routeFuncs'
import { DOWNLOAD, REDUCE_WEIGHT_BUTTON_TEXT } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'

import './WeightReduction.scss'

const LinkTSX: any = Link
const GridListTileTSX: any = GridListTile
const GridListTSX: any = GridList

interface IProps extends RouteComponentProps<MatchParams> {
	weightReducedSuggestedParts: any[]
	WeightReductionMaterialsData: any
	getProjectsBundle: Function
	showWeightReduceProgressPopup: boolean
	printersFullData: any
	reduceWeightPartIdClick: number
	reduceWeightPartSelectedIsStl: boolean
	reduceWeightPartSelectedHasBrepData: boolean
	getMoreWeightReduction: (
		projectId: string,
		weightReducedSuggestedPartsLength: number
	) => any
	setupProjectAnalysis: (projectId: string) => any
	getProjectInformation: (projectId: string) => any
	reducePartWeightClick: (
		partId: number,
		stepURL: string,
		hasBrepData: boolean
	) => any
	reducePartWeightCloseClick: () => any
	isBundle: boolean
	projectHasMoreWeightReductions: boolean
}

interface IReduxStore {
	ProjectBundleReducer: any
	ProjectAnalysisReducer: any
	ProjectPageReducer: any
	user: any
}

interface MatchParams {
	projectId: string
}

const AllWeightReductionsPartsView: FC<IProps> = ({
	weightReducedSuggestedParts,
	WeightReductionMaterialsData,
	projectHasMoreWeightReductions,
	showWeightReduceProgressPopup,
	printersFullData,
	reduceWeightPartSelectedIsStl,
	reduceWeightPartSelectedHasBrepData,
	reduceWeightPartIdClick,
	getMoreWeightReduction,
	reducePartWeightClick,
	reducePartWeightCloseClick,
	isBundle,
	match: {
		params: { projectId }
	}
}) => {
	const dispatch = useDispatch()
	const { userUnitSystem } = useSelector((state: RootStateOrAny) => state.user)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric

	useEffect(() => {
		dispatch(getProjectInformation(projectId))
	}, [])

	useEffect(() => {
		if (isBoolean(isBundle) && !weightReducedSuggestedParts?.length) {
			if (isBundle) {
				dispatch(getProjectsBundle(projectId))
			} else {
				dispatch(setupProjectAnalysis(projectId))
			}
		}
	}, [])

	const renderSubLink = (part: Part) => {
		if (!Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION_OLD)) {
			return (
				<TransparentButton
					onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
						reducePartWeightClick(part.id, part.stepURL, part.hasBrepData)
						e.stopPropagation()
						e.preventDefault()
					}}
				>
					{REDUCE_WEIGHT_BUTTON_TEXT}
				</TransparentButton>
			)
		}
		return (
			<a
				href={part.weightReducedStlURL}
				className="all-weight-reduction--grid--item--subtitle--link"
				onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
					e.stopPropagation()
				}
			>
				{DOWNLOAD}
			</a>
		)
	}

	const renderWeightData = (part: Part) => {
		return (
			<div className="all-weight-reduction--grid--item--subtitle">
				<div>
					{getString('WEIGHT_REDUCED_PART').format(
						getWeightReductionRate(part.volume, part.weightReducedVolume),
						getWeightReducedValue(
							part.volume,
							part.weightReducedVolume,
							unitSystem,
							part.weightReductionDensity
						)
					)}
				</div>
			</div>
		)
	}

	if (!weightReducedSuggestedParts?.length) {
		return <div></div>
	}

	return (
		<NavBarAndMaterial
			title={getString('WEIGHT_REDUCTION')}
			isWeightReduction={true}
		>
			<InfiniteScroll
				dataLength={weightReducedSuggestedParts.length}
				next={() =>
					getMoreWeightReduction(projectId, weightReducedSuggestedParts.length)
				}
				hasMore={projectHasMoreWeightReductions}
				loader={
					<Loader
						load={true}
						size={50}
						showFlex={false}
						wrapperClassName="admin-parts--parts-table--loader"
					/>
				}
				height="92vh"
			>
				<WeightReductionProgress
					show={showWeightReduceProgressPopup}
					onConfirm={reducePartWeightClick}
					onCancel={reducePartWeightCloseClick}
					WeightReductionMaterialsData={WeightReductionMaterialsData}
					partId={reduceWeightPartIdClick}
					radioButtonSelected={'1'}
					printersFullData={printersFullData}
					isSTLFile={reduceWeightPartSelectedIsStl}
					hasBrepData={reduceWeightPartSelectedHasBrepData}
					initialStep={1}
				/>
				<div className="all-weight-reduction">
					<GridListTSX cols={4} className="all-weight-reduction--grid">
						{weightReducedSuggestedParts.map(part => (
							<GridListTileTSX
								className="all-weight-reduction--grid--item"
								key={part.id}
								sm={4}
								classes={{ tile: 'all-weight-reduction--grid--tile' }}
							>
								<LinkTSX
									to={{
										pathname: partConfigurationsRoute(projectId, part.id),
										state: { isWeightReduction: true }
									}}
								>
									<Card
										imageUrl={part.imageURL}
										imageAlt="..."
										title={part.partNumber}
										contentCustomComponent={renderWeightData(part)}
										sizeWrapperClassName={
											'all-weight-reduction--grid--size-wrapper'
										}
										partFormat={part?.formatType}
									/>
								</LinkTSX>
							</GridListTileTSX>
						))}
					</GridListTSX>
				</div>
			</InfiniteScroll>
		</NavBarAndMaterial>
	)
}

const mapStateToProps = ({
	ProjectBundleReducer,
	ProjectAnalysisReducer,
	ProjectPageReducer,
	user: { printersFullData }
}: IReduxStore): IProps => {
	const { isBundle } = ProjectPageReducer

	const reducer = isBundle ? ProjectBundleReducer : ProjectAnalysisReducer
	return {
		...reducer,
		isBundle,
		printersFullData
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...ProjectAnalysisActions }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	WithFeatureToggleHOC(memo(AllWeightReductionsPartsView), [
		FeatureComponentId.WEIGHT_REDUCTION
	])
)
