import isNull from 'lodash/isNull'

import {
	DataTableFieldType,
	IDataTableField
} from 'Scenes/Components/DataTable/IDataTableField'
import {
	InapplicableStatus,
	MIN_PROPERTIES_CONFIGURATIONS_FOR_EDITABLE_FIELDS,
	partResults,
	ZERO_NUMBER_FIELD_VALUE
} from 'Services/Constants'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { FormatType, Part, PartStatus } from 'Services/models/IPart'
import {
	PartProperty,
	PartPropertyName
} from 'Services/models/IPartPropertiest'
import { WARN_BOUNDING_BOX } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import {
	LengthUnit,
	UnitsConversionService,
	UnitSystem,
	WeightUnit
} from 'Services/UnitsConversionService'

export const buildGridTemplateColumns = (
	partProperties: any = [],
	extendedTolerance?: boolean
): string => {
	let gridTemplateColumns = ''
	partProperties?.map((property: any) => {
		switch (property.type) {
			case 'number':
				gridTemplateColumns += '3.5% '
				break
			case 'string':
				gridTemplateColumns += '9% '
				break
			case 'dropdown':
				gridTemplateColumns += extendedTolerance ? '9% ' : '7% '
				break
			case 'link':
				gridTemplateColumns += '10% '
				break
			case 'warn':
				gridTemplateColumns += '12% '
				break
			default:
				gridTemplateColumns += '6% '
				break
		}
	})
	gridTemplateColumns += 'auto auto' // For buttons
	return gridTemplateColumns
}

export const getGridColumnsGapInVW = (columnsCount: number) => {
	const singleColumnGap = 6.7
	const step = 0.45
	return singleColumnGap - columnsCount * step
}

const getPropertyUnit = (unit: string, unitSystem: UnitSystem) => {
	if (unitSystem === UnitSystem.imperial) {
		if (unit && unit.includes(LengthUnit.mm)) {
			return unit.replace(LengthUnit.mm, LengthUnit.inch)
		} else if (unit && unit.includes(`[${WeightUnit.g}]`)) {
			return unit.replace(WeightUnit.g, WeightUnit.lb)
		}
	} else {
		return unit
	}
}

export const buildPartPropertiesHeaders = (
	partPropertiesHeaders: any[] = [],
	unitSystem: UnitSystem
): IDataTableField[] => {
	const partPropertiesHeadersRow: IDataTableField[] = []
	partPropertiesHeaders?.forEach((header: any) => {
		partPropertiesHeadersRow.push({
			text: header.labelName,
			type: DataTableFieldType.HeaderWithDescription,
			data: {
				className: 'part-properties-table-header',
				description: getPropertyUnit(header.units, unitSystem),
				descriptionClassName: 'part-properties-table-header-description'
			}
		})
	})

	partPropertiesHeadersRow.push(
		{
			text: '',
			type: DataTableFieldType.Header,
			data: { className: 'part-properties-table-header' }
		},
		{
			text: '',
			type: DataTableFieldType.Header,
			data: { className: 'part-properties-table-header' }
		}
	)
	return partPropertiesHeadersRow
}

export const getPartPropertyError = (
	partProperty: any,
	partProperties: any
) => {
	if (
		partProperty.mandatory &&
		(!partProperty.value ||
			(partProperty.type === 'number' && partProperty.value == 0))
	) {
		return getString('REQUIRED')
	}

	if (partProperty.minValue && partProperty.value < partProperty.minValue) {
		return getString('VALUE_GREATER_THAN').format(partProperty.minValue)
	}

	if (partProperty.maxValue && partProperty.value > partProperty.maxValue) {
		return getString('VALUE_LESS_THAN').format(partProperty.maxValue)
	}

	// Return error by depends field.
	if (partProperty.dependsField) {
		if (
			!partProperties.data.find(
				(property: any) => property.propertyName === partProperty.dependsField
			)?.value ||
			!!partProperty.isError
		) {
			return partProperty.dependsError
		}
	}
	return null
}

const makeDataColumn = (
	property: any,
	partProperties: any,
	partId: number,
	propIndex: number,
	partIndex: number,
	updatePartProperty: Function,
	isInapplicable: boolean,
	show3dButton: boolean,
	specificTolerancesExist: boolean,
	setPartToViewer: Function,
	setPartToPDFViewer: Function,
	setPartToToleranceModal: Function
) => {
	let type: any = DataTableFieldType.Text
	let data = {}
	const minValue = +property.minValue || ZERO_NUMBER_FIELD_VALUE
	const maxValue = +property.maxValue || null

	switch (property.type) {
		case 'number':
			if (isInapplicable) {
				type = null
				data = ''
			} else {
				type = property.editable
					? DataTableFieldType.DataTableInputNumber
					: DataTableFieldType.Text
				data = {
					tooltip: property.showTooltip,
					tooltipText: getString('PART_PROPERTIES_FIELD_TOOLTIP').format(
						property.labelName
					),
					minValue: minValue,
					maxValue: maxValue,
					step: property.step,
					allowDot: property.allowDot,
					className: 'without-border',
					error: getPartPropertyError(property, partProperties),
					onChange: (value: number) => {
						updatePartProperty(partId, propIndex, value, property.propertyName)
					}
				}
			}
			break
		case 'string':
			const dynamicClassName =
				property?.style?.fontStyle === 'italic'
					? 'without-border-italic'
					: 'without-border'

			type =
				property.editable && !isInapplicable
					? DataTableFieldType.DataTableInputText
					: DataTableFieldType.Text
			data = {
				tooltip: property.showTooltip,
				tooltipText: getString('PART_PROPERTIES_FIELD_TOOLTIP').format(
					property.labelName
				),
				className: dynamicClassName,
				error: getPartPropertyError(property, partProperties),
				onChange: (e: any) => {
					updatePartProperty(
						partId,
						propIndex,
						e.target.value,
						property.propertyName
					)
				},
				showErrorForTextField: !!property.showErrorForTextField
			}
			break
		case 'dropdown':
			if (isInapplicable) {
				type = null
				data = ''
			} else {
				type = property.editable
					? DataTableFieldType.DataTableDropdown
					: DataTableFieldType.Text
				data = {
					options: property.options,
					className: 'without-border',
					error: getPartPropertyError(property, partProperties),
					onChange: (e: any) => {
						updatePartProperty(
							partId,
							propIndex,
							e.target.value,
							property.propertyName
						)
					},
					...(specificTolerancesExist && {
						extra: {
							iconName: 'plus',
							iconClassName: 'icon extra',
							wrapperClassName: 'tolerance-wrapper',
							className: 'cursor-pointer',
							onClick: () => setPartToToleranceModal()
						}
					})
				}
			}
			break
		case 'link':
			type = DataTableFieldType.TextWithIconLink
			data = {
				iconName: 'pdf',
				iconClassName: 'icon',
				className: 'cursor-pointer',
				isLink: false,
				property: property,
				onClick: () => setPartToPDFViewer(),
				...(show3dButton && {
					extra: {
						iconName: 'cad',
						iconClassName: 'icon extra',
						className: 'cursor-pointer',
						onClick: () => setPartToViewer()
					}
				})
			}
			break
		case 'warn':
			type = isInapplicable ? null : DataTableFieldType.TextWithIconWarn
			data = isInapplicable
				? ''
				: {
						iconOnTheRight: true,
						iconName: 'warn',
						iconClassName: 'icon-red',
						className: 'cursor-pointer',
						iconHoverText: WARN_BOUNDING_BOX
				  }
			break
		default:
			if (isInapplicable) {
				type = null
				data = ''
			} else {
				type = DataTableFieldType.Text
				data = {
					error: getPartPropertyError(property, partProperties)
				}
				break
			}
	}

	const disableAllFieldsExceptTitle =
		isInapplicable &&
		(isNull(type) ||
			(type === DataTableFieldType.Text &&
				property.text !== getString('INAPPLICABLE') &&
				property.labelName !== getString('PART_PROPERTY_LABEL_NAME')))

	if (disableAllFieldsExceptTitle) {
		return {}
	}

	return {
		key: `${partIndex}${propIndex}`,
		text: property.text || '',
		type,
		data
	}
}

export const buildPartPropertyRows = (
	_partsProperties: any[],
	partId: number,
	projectId: number,
	partsPropertiesCalculating: { rowIndex: number; calculating: boolean },
	partsPropertiesReset: { rowIndex: number; reset: boolean },
	updatePartProperty: Function,
	onConfirmClick: Function,
	onPartsPropertiesReset: Function,
	partsPropertiesAll: Array<Part>,
	setPartToViewer: Function,
	setPartToPDFViewer: Function,
	setPartToToleranceModal: (part: Part) => void,
	unitSystem: UnitSystem
) => {
	const conversionService = new UnitsConversionService(
		UnitSystem.metric,
		unitSystem
	)
	const minLengthInch = 0.039
	const minVolumeCubicInch = 0.001
	const minWeightLb = 0.001

	if (_partsProperties?.length) {
		_partsProperties.forEach(modifiedProperty => {
			const currentPart = partsPropertiesAll.find(
				part => part.id === modifiedProperty.partId
			)
			const isInapplicable =
				modifiedProperty.drawingStatusCode === InapplicableStatus
			const configurationsCount =
				currentPart?.configurationsCount || ZERO_NUMBER_FIELD_VALUE
			const configurationCountDisable =
				!Feature.isFeatureOn(
					FeatureComponentId.EDIT_MULTIPLE_CONFIGURATION_PART_FIELDS
				) &&
				configurationsCount > MIN_PROPERTIES_CONFIGURATIONS_FOR_EDITABLE_FIELDS
			const isStandardCost = !!currentPart?.standardCost
			const warnAlertCheckDataList = modifiedProperty.data.filter(
				(pr: any) =>
					[
						PartPropertyName.width,
						PartPropertyName.depth,
						PartPropertyName.height
					].includes(pr.propertyName) && !pr.value
			)
			modifiedProperty.data = modifiedProperty.data.filter(
				(property: any) => property.showInUI
			)
			modifiedProperty.data.forEach((property: any) => {
				switch (property.propertyName) {
					case PartPropertyName.quantity: {
						property.editable = true
						if (!property.value) {
							property.text = ''
							property.showTooltip = true
							break
						}

						property.text = property.value
						if (configurationCountDisable) {
							property.editable = false
							property.showTooltip = true
						}

						if (isStandardCost) {
							property.editable = false
						}
						break
					}

					case PartPropertyName.originalMaterialMatch: {
						property.text = property.value
						property.showErrorForTextField = true
						if (configurationCountDisable) {
							property.editable = false
							property.showTooltip = true
						}

						break
					}
					case PartPropertyName.status: {
						property.text = getString('VALID_INFORMATION')

						if (isInapplicable) {
							property.text = getString('INAPPLICABLE')
						} else if (property.value === PartStatus.missingInformation) {
							property.text = getString('MISSING_INFORMATION')
						} else if (property.value === PartStatus.awaitingCombinedHeatmap) {
							property.text = getString('UPDATING...')
						}

						property.type =
							!isInapplicable && warnAlertCheckDataList.length
								? 'warn'
								: 'string'
						break
					}
					case PartPropertyName.packingDensity: {
						property.text = toFixedOnlyIfNeeded(property.value)
						break
					}
					case PartPropertyName.depth:
					case PartPropertyName.width:
					case PartPropertyName.height: {
						const value =
							property.value &&
							conversionService.convertLength(Number(property.value), false)
						property.text = value ? parseFloat(Number(value).toFixed(3)) : ''
						if (unitSystem === UnitSystem.imperial) {
							property.minValue = minLengthInch
						}
						break
					}
					case PartPropertyName.volume: {
						const value =
							property.value &&
							conversionService.convertVolume(Number(property.value), false)
						property.text = Number(value)
							? parseFloat(Number(value).toFixed(3))
							: ''
						if (unitSystem === UnitSystem.imperial) {
							property.minValue = minVolumeCubicInch
						}
						break
					}
					case PartPropertyName.mass: {
						let value = Number(property.value)
						if (value && unitSystem === UnitSystem.imperial) {
							const valueKg = value / 1000
							value = valueKg && conversionService.convertWeight(valueKg, false)
						}

						property.text = Number(value)
							? parseFloat(Number(value).toFixed(3))
							: ''

						if (unitSystem === UnitSystem.imperial) {
							property.minValue = minWeightLb
						}
						break
					}
					default: {
						property.text = property.value
					}
				}
			})
		})
	}

	return _partsProperties
		.filter(
			(partProperties: any) => !partId || partProperties.partId === partId
		)
		.map((partProperties: any, partIndex: number) => {
			if (partIndex === 0) {
				buildGridTemplateColumns(partProperties.data)
			}
			const partId = partProperties.partId
			const part: Part =
				partsPropertiesAll.find(p => p.id === partId) || ({} as Part)
			let show3dButton =
				(part?.result === partResults.printable ||
					part?.result === partResults.borderline) &&
				part?.isDrawing &&
				part?.meshUploadURL &&
				Feature.isFeatureOn(FeatureComponentId.DRAWING_3D)

			const boundingBoxX = partProperties?.data.find(
				(part: PartProperty) => part.labelName === 'Bounding Box - X'
			)
			const boundingBoxY = partProperties?.data.find(
				(part: PartProperty) => part.labelName === 'Bounding Box - Y'
			)
			const volume = partProperties?.data.find(
				(part: PartProperty) => part.labelName === 'Volume'
			)

			const formatType = part?.formatType
			const specificTolerancesExist =
				part.formatType === FormatType.threeDByPDF ||
				part.formatType === FormatType.pdf

			const isInapplicable =
				((formatType === FormatType.pdf ||
					formatType === FormatType.metadata) &&
					partProperties.drawingStatusCode === InapplicableStatus) ||
				(formatType === FormatType.threeD &&
					!boundingBoxX?.value &&
					!boundingBoxY?.value &&
					!volume?.value)
			const partIndexInAllProperties = _partsProperties.findIndex(
				property => property.partId === partId
			)

			partProperties.isInapplicable = isInapplicable

			const partPropertyRow: any[] = partProperties.data.map(
				(property: any, propIndex: number) =>
					makeDataColumn(
						property,
						partProperties,
						partId,
						propIndex,
						partIndex,
						updatePartProperty,
						isInapplicable,
						!!show3dButton,
						specificTolerancesExist,
						() => setPartToViewer(part),
						() => setPartToPDFViewer(part),
						() => setPartToToleranceModal(part)
					)
			)
			const allowResetAllPartsProperties = Feature.isFeatureOn(
				FeatureComponentId.ALLOW_RESET_ALL_PARTS_PROPERTIES
			)
			if (!isInapplicable) {
				if (allowResetAllPartsProperties) {
					partPropertyRow.push({
						text: getString('RESET'),
						type: DataTableFieldType.TransparentButtonWithLoader,
						data: {
							disabled:
								partsPropertiesCalculating.calculating ||
								!partProperties.allowReset,
							onClick: () =>
								onPartsPropertiesReset(projectId, [partId], partIndex),
							buttonClassName: 'part-properties-table-button',
							buttonLoading:
								partsPropertiesReset.reset &&
								partsPropertiesReset.rowIndex === partIndex
						}
					})
				}
				partPropertyRow.push({
					text: getString('RECALCULATE'),
					type: DataTableFieldType.ButtonWithLoader,
					data: {
						onClick: () => {
							onConfirmClick(partIndexInAllProperties, [partProperties])
						},
						buttonClassName: `part-properties-table-button tiny`,
						disabled:
							isInapplicable ||
							partsPropertiesCalculating.calculating ||
							!partProperties?.changed ||
							partsPropertiesReset.reset,
						buttonLoading:
							partsPropertiesCalculating.rowIndex === partIndexInAllProperties
					}
				})
			} else {
				partPropertyRow.push({
					text:
						formatType === FormatType.pdf
							? getString('INAPPLICABLE_INFO')
							: getString('INAPPLICABLE_INFO_3D'),
					type: DataTableFieldType.Text,
					data: {
						className: 'full-columns'
					}
				})
			}

			return partPropertyRow
		})
}

export const onRecalculateClick = (
	projectId: number,
	partsProperties: any[],
	partIndex: number,
	onPartsPropertiesChange: Function,
	setPartPropertyCalculateAlert: Function,
	page: number,
	limit: number
) => {
	const _partsProperties = partsProperties.map(partProperties => {
		return partProperties.data.reduce(
			(obj: any, properties: any) => {
				obj[properties['propertyName']] = properties['value']
				return obj
			},
			{
				partId: partProperties.partId,
				specificTolerances: partProperties.specificTolerances
			}
		)
	})
	setPartPropertyCalculateAlert(false)
	onPartsPropertiesChange(projectId, _partsProperties, partIndex, page, limit)
}
