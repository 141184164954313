export const stylesChart = {
	card: {},
	horizontalFlex: { flex: 1, alignItems: 'flex-start' },
	footerFlex: { height: '100%' },
	footer: { flex: 1, marginLeft: 0 },
	pieSuper: { flex: 2 },
	text: {
		width: '100%',
		textAlign: 'left',
		lineHeight: '16px'
	}
}
