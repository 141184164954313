import { FC, memo, useCallback, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ImageList, ImageListItem } from '@material-ui/core'

import PartsListView from '../../ProjectAnalysis/ProjectSuggestionPage/PartsListView/PartsListView'
import { changePartsPage } from 'global actions'
import CastorPagination from 'Scenes/Components/CastorPagination/CastorPagination'
import { changeSearchPhrase } from 'Scenes/Components/FilterPartsGrid/FilterPartsAction'
import FlexBox from 'Scenes/Components/FlexBox'
import SearchBar from 'Scenes/Components/SearchBar/SearchBar'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { ProjectAnalysisPartsView } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionInterfaces'
import {
	ClientChallengesType,
	ClientNoOpportunitiesType,
	ClientSuggestionType,
	StringChallengesTitle,
	StringNoOpportunities,
	StringOpportunities,
	TabsItem
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionService'
import PartsHeader from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionMultipleParts/PartsHeader'
import ProjectPartTile from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionMultipleParts/ProjectPartTile'
import { styles } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionMultipleParts/style'
import ProjectSuggestionPartsFilter from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionPartsFilter'
import Loader from 'Scenes/Loader/Loader'
import { Project, ProjectClusterStatus } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as NoOpportunitySvg } from 'assets/img/svg/no-opportunity.svg'

interface IProps {
	setShowPDFModal: (value: boolean) => void
	showPDFModal: boolean
}
const ProjectBundleMultipleParts: FC<IProps> = ({ setShowPDFModal }) => {
	const { projectId } = useParams() as { projectId: string }
	const {
		weightReducedParts,
		projects,
		project,
		totalPartsCount,
		standalonePartsCount,
		allPartsInapplicable,
		allPartsNotCostEffective,
		selectedFilterPart,
		searchPhrase,
		selectedFilterValues,
		showStandardCostFinishIcon,
		partsView,
		leadingConfigurationData,
		partsLoading,
		paginationData,
		refetchParts,
		resultType,
		selectedTab,
		suggestions,
		projectClusterStatus
	} = useSelector(
		(state: RootStateOrAny) => state.ProjectBundleSuggestionReducer
	)
	const textKey = useMemo(() => {
		switch (selectedTab) {
			case TabsItem.opportunities:
				return StringOpportunities[resultType as ClientSuggestionType]
			case TabsItem.challenges:
				return StringChallengesTitle[resultType as ClientChallengesType]
			default:
				return StringNoOpportunities[resultType as ClientNoOpportunitiesType]
		}
	}, [resultType, selectedTab])

	const { roles, contactUsEmail } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const dispatch = useDispatch()

	const assemblyProjects = projects?.filter(
		(project: Project) => project.isAssembly
	)

	const onExportPDFClick = useCallback(() => {
		setShowPDFModal(true)
	}, [])

	const isPcUpdating = useMemo(
		() =>
			selectedTab === TabsItem.opportunities &&
			resultType === ClientSuggestionType.amPC &&
			(project?.clusterStatus === ProjectClusterStatus.awaitingAnalysis ||
				projectClusterStatus === ProjectClusterStatus.awaitingAnalysis),
		[selectedTab, resultType, project, projectClusterStatus]
	)

	const renderParts = (partsView: ProjectAnalysisPartsView) => {
		if (isPcUpdating) {
			return (
				<ImageListItem cols={3} style={styles.partsRowsAwaiting}>
					<FlexBox
						style={styles.partsRowsAwaitingMessage}
						alignItems="center"
						justifyContent="center"
						width="100%"
						height="100%"
					>
						<IconFactory
							iconName={ProjectClusterStatus.awaitingAnalysis}
							className="cluster-section--subheader--icon"
						/>
						<div className="text">
							{getString('COMBINE_MULTIPLE_INTO_ONE_REQ_BODY')}
						</div>
					</FlexBox>
				</ImageListItem>
			)
		}

		if (partsLoading)
			return (
				<div style={styles.partsRows}>
					<Loader
						load={partsLoading}
						wrapperClassName="loading-wrapper suggestion-multiple-parts-loading"
					/>
				</div>
			)

		if (allPartsInapplicable) {
			return (
				<ImageListItem cols={3}>
					<p className="all-inapplicable">
						{getString('ALL_PARTS_INAPPLICABLE')}
					</p>
				</ImageListItem>
			)
		}

		if (partsView === ProjectAnalysisPartsView.list) {
			return (
				<ImageListItem cols={3} style={styles.partsRows} key="listView">
					<PartsListView
						partsLoading={partsLoading}
						resultType={resultType}
						selectedTab={selectedTab}
						suggestions={suggestions}
						leadingConfigurationData={leadingConfigurationData}
						lockedParts={!!project?.lockedParts}
						amountOfLockedParts={project?.amountOfLockedParts}
						roles={roles}
						contactUsEmail={contactUsEmail}
						isBundle={true}
						textKey={textKey}
					/>
				</ImageListItem>
			)
		}
		return (
			<ImageList
				style={{
					...styles.partsHeader,
					flexDirection: 'row',
					zIndex: 99
				}}
				cols={3}
			>
				{suggestions?.length === 0 && !partsLoading ? (
					<ImageListItem cols={3} style={styles.partsRows}>
						<div className="suggestion-parts-list--wrapper empty">
							<FlexBox
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								width="100%"
								height="100%"
								minHeight="386px"
							>
								<NoOpportunitySvg />
								<div className="text">
									{getString('NO_OPPORTUNITIES_FOUND').format(
										getString(textKey),
										resultType === ClientSuggestionType.amPC ||
											resultType === ClientSuggestionType.amWR
											? getString('PARTS_OPPORTUNITIES')
											: ' '
									)}
								</div>
							</FlexBox>
						</div>
					</ImageListItem>
				) : (
					<></>
				)}
				{suggestions?.map((suggestion: Record<string, any>) => (
					<ImageListItem
						key={suggestion.id}
						style={styles.partTile}
						id="grid-list-tile"
						cols={1}
						classes={{ item: 'grid-list--tile' }}
					>
						<ProjectPartTile
							suggestion={suggestion}
							starred={suggestion.part?.starred || suggestion.cluster?.starred}
							isBundle
						/>
					</ImageListItem>
				))}
			</ImageList>
		)
	}

	return (
		<>
			<ImageList
				style={{
					...styles.partsGrid,
					flexDirection:
						partsView === ProjectAnalysisPartsView.list ? 'column' : 'row'
				}}
				cols={3}
			>
				<ImageListItem
					id="grid-list"
					key="Subheader"
					cols={3}
					style={styles.partsHeader}
				>
					<FlexBox flexDirection="column" overflow="hidden">
						<SearchBar
							value={searchPhrase}
							onChange={(value: string) => dispatch(changeSearchPhrase(value))}
							className="suggestion-parts-header--search-bar"
						/>
						<ProjectSuggestionPartsFilter />

						<PartsHeader
							resultType={resultType}
							selectedTab={selectedTab}
							assemblyProjects={assemblyProjects}
							bundleId={projectId}
							selectedFilterValues={selectedFilterValues}
							showStandardCostFinishIcon={showStandardCostFinishIcon}
							showExportButton={true}
							project={project}
							suggestions={suggestions}
							weightReducedParts={weightReducedParts}
							is2dProject={false}
							allPartsCount={totalPartsCount}
							standalonePartsCount={standalonePartsCount}
							allPartsInapplicable={allPartsInapplicable}
							allPartsNotCostEffective={allPartsNotCostEffective}
							searchPhrase={searchPhrase}
							projectId={projectId}
							selectedFilterPart={selectedFilterPart}
							partsView={partsView}
							totalPartsCount={paginationData.totalPartsCount}
							page={paginationData.page}
							limit={paginationData.limit}
							onExportPDFClick={onExportPDFClick}
							refetchParts={refetchParts}
						/>
					</FlexBox>
				</ImageListItem>
				{renderParts(partsView)}
				<ImageListItem
					id="grid-list"
					key="Pagination"
					cols={3}
					style={styles.partsPagination}
				>
					<CastorPagination
						showPagination={!isPcUpdating && paginationData.totalPagesCount > 1}
						pageNumber={paginationData.page}
						isLastPage={!paginationData.enableNext}
						total={paginationData.totalPartsCount}
						limit={paginationData.limit}
						className={{ pagination: 'parts-pagination' }}
						showingFrom={!isPcUpdating && paginationData.showingFrom}
						showingTo={!isPcUpdating && paginationData.showingTo}
						onNextClick={() =>
							dispatch(changePartsPage(paginationData.page + 1))
						}
						onPrevClick={() =>
							dispatch(changePartsPage(paginationData.page - 1))
						}
						onPageClick={(page: number) => dispatch(changePartsPage(page))}
					/>
				</ImageListItem>
			</ImageList>
		</>
	)
}

export default memo(ProjectBundleMultipleParts)
