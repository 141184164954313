import { every, find, isBoolean, pick } from 'lodash'

import { amResults } from './CustomizeListOfSuggestions'
import { IDefaultSuggestions } from 'Services/models/IDefaultSuggestions'

export const DOLLAR = 'costDollar'
export const PERCENTAGE = 'costPercentage'
export const MIN_VALUE = 0
export const MIN_VALUE_DOLLAR = 1
export const MAX_DOLLAR_VALUE = 10000
export const MAX_PERCENTAGE_VALUE = 90
export const EMPTY_PLACEHOLDER = '0'
export const EMPTY_PLACEHOLDER_DOLLAR = '1'

export const prepareListOfSuggestions = (
	suggestions: Array<IDefaultSuggestions>,
	userDefaultSuggestions: Record<string, boolean>
) => {
	return suggestions
		.filter((suggestion: Record<string, any>) => suggestion.showInUI)
		.map(suggestion => {
			const userSuggestionShowDefault =
				userDefaultSuggestions?.[suggestion.resultType]
			suggestion.showAsDefault = isBoolean(userSuggestionShowDefault)
				? userSuggestionShowDefault
				: suggestion.showAsDefault
			return pick(suggestion, [
				'showAsDefault',
				'resultType',
				'stringKey',
				'name'
			])
		})
}

export const prepareToSendSuggestions = (
	suggestions: Array<IDefaultSuggestions>
) => {
	return suggestions.reduce((acc: Record<number, boolean>, item) => {
		acc[item.resultType] = !!item.showAsDefault

		return acc
	}, {})
}

export const checkAllIsOn = (listOfSuggestion: Array<Record<string, any>>) =>
	every(amResults, key => {
		const item = find(listOfSuggestion, { resultType: key })
		return item ? item.showAsDefault : false
	})
