import { ChangeEvent, FC, memo, ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { MenuItem, Select } from '@material-ui/core'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import cx from 'classnames'

import FlexBox from '../FlexBox'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

interface IProps {
	showPagination: boolean
	pageNumber: number
	isLastPage: boolean
	showingFrom: string
	showingTo: string
	limit: number
	total: number
	className?: Record<string, any>
	showOfStringKey?: string
	linkTo?: (pageNumber: number) => string
	onNextClick?: () => void
	onPrevClick?: () => void
	onPageClick?: (page: number) => void
}

interface IPage {
	value: number
}

const CastorPaginationDropdown: FC<IProps> = ({
	pageNumber,
	showingFrom,
	showingTo,
	limit,
	total,
	showOfStringKey = 'SHOW_OF',
	linkTo,
	onPageClick,
	onPrevClick,
	onNextClick
}) => {
	const [pageList, setPageList] = useState<IPage[]>([])
	const [open, setOpen] = useState(false)
	const [active, setActive] = useState(pageNumber)
	const lastPageNumber = Math.ceil(total / limit)
	const nextPageNumber = pageNumber + 1
	const prevPageNumber = pageNumber - 1

	useEffect(() => {
		const pages: IPage[] = []
		for (let i = 1; i <= lastPageNumber; i++) {
			pages.push({
				value: i
			})
		}
		setPageList(pages)
	}, [lastPageNumber])

	useEffect(() => {
		setActive(pageNumber)
	}, [pageNumber])

	const handleChange = (event: ChangeEvent<HTMLInputElement> & any) => {
		if (onPageClick) {
			onPageClick(event.target.value)
		}
		setActive(event.target.value)
		setOpen(false)
	}

	const handleIconClick = () => {
		setOpen(true)
	}

	const handleSelectClick = () => {
		setOpen(!open)
	}

	return (
		<FlexBox className="pagination" alignItems="center" justifyContent="center">
			<div
				className="pagination-show-of"
				data-qa={'data-qa-pagination-current-showing'}
			>
				{getString(showOfStringKey).format(showingFrom, showingTo)}
			</div>
			<div>{getString('PAGE')}</div>
			<div className="pagination-dropdown">
				<Select
					open={open}
					onChange={handleChange}
					onClick={handleSelectClick}
					onClose={() => setOpen(false)}
					disableUnderline={true}
					IconComponent={() => <ArrowDropDown onClick={handleIconClick} />}
					displayEmpty
					id="drop-down-pagination"
					className="test2"
					MenuProps={{
						PopoverClasses: {
							root: 'dropdown'
						}
					}}
					classes={{
						root: `select-with-icons--select--root`
					}}
					value={active}
					renderValue={(value: unknown) => value as ReactNode}
				>
					{pageList.map((page: IPage) =>
						linkTo ? (
							<Link to={linkTo(page.value)} className="page-link">
								<MenuItem className="test" value={page.value} key={page.value}>
									{page.value}
								</MenuItem>
							</Link>
						) : (
							<MenuItem className="test" value={page.value} key={page.value}>
								{page.value}
							</MenuItem>
						)
					)}
				</Select>
			</div>
			<div className="pagination-arrows">
				{onPrevClick ? (
					<KeyboardArrowLeft
						data-qa="data-qa-pagination-prev-arrow"
						onClick={() => {
							onPrevClick()
						}}
						className={cx({ disabled: pageNumber === 1 })}
					/>
				) : linkTo ? (
					<Link to={linkTo(prevPageNumber)} className="page-link">
						<KeyboardArrowLeft
							data-qa="data-qa-pagination-prev-arrow"
							className={cx({ disabled: pageNumber === 1 })}
						/>
					</Link>
				) : null}
				{onNextClick ? (
					<KeyboardArrowRight
						data-qa="data-qa-pagination-next-arrow"
						onClick={() => {
							onNextClick()
						}}
						className={cx({ disabled: pageNumber === lastPageNumber })}
					/>
				) : linkTo ? (
					<Link to={linkTo(nextPageNumber)} className="page-link">
						<KeyboardArrowRight
							data-qa="data-qa-pagination-next-arrow"
							className={cx({ disabled: pageNumber === lastPageNumber })}
						/>
					</Link>
				) : null}
			</div>
		</FlexBox>
	)
}

export default memo(CastorPaginationDropdown)
