import React, { FC, useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { COPY_SUFFIX, SelectedRadioType } from './NewOrCopyConstants'
import NewOrCopyRadioSelector from './NewOrCopyRadioSelector'
import { findPrinterByMaterial } from './NewOrCopyService'
import { addedDeviationExtToProperty } from 'Scenes/admin/AdminHome/AdminPrinterMaterials/AdminPrinterMaterialService'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import AmMaterialSelector from 'Scenes/Components/MaterialSelector/AmMaterialSelector/AmMaterialSelector'
import MaterialSelector from 'Scenes/Components/MaterialSelector/TmMaterialSelector/MaterialSelector'
import { useMaterial } from 'Scenes/Home/NewUploadProject/UploadBlocks/UploadBlocksHooks'
import { defaultMetal, defaultPrinterMetal } from 'Services/Constants'
import { Material } from 'Services/models/IMaterial'
import {
	customizeNewPrinterMaterial,
	customizeNewUserMaterial
} from 'Services/routeFuncs'
import { OK } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { defaultPrinterMaterial, defaultMaterial } = getTheme()

interface CopyTMMaterialProps {
	show: boolean
	setShow: (show: boolean) => void
	isAmMaterial?: boolean
}

const PopupMaterial: FC<CopyTMMaterialProps> = ({
	show,
	setShow,
	isAmMaterial
}) => {
	const [selectedValue, setSelectedValue] = useState(SelectedRadioType.new)
	const [defaultChosenMaterial] = useMaterial()
	const [tmMaterial, setTMMaterial] = useState<Material | null>(
		defaultChosenMaterial
	)

	const history = useHistory()

	const {
		userId,
		printersFullData,
		materials,
		material: amMaterial
	} = useSelector(
		({
			CustomizeUserMaterialsReducer,
			user,
			AmMaterialSelectorReducer
		}: RootStateOrAny) => ({
			userId: CustomizeUserMaterialsReducer.userId,
			printersFullData: user.printersFullData,
			materials: user.materials,
			material: AmMaterialSelectorReducer.material
		})
	)

	const onConfirmAMMaterial = () => {
		setShow(false)

		if (selectedValue === SelectedRadioType.new) {
			history.push(customizeNewPrinterMaterial())
		} else {
			const preparedMaterial = addedDeviationExtToProperty(
				findPrinterByMaterial(amMaterial, printersFullData)
			)
			history.push(customizeNewPrinterMaterial(), {
				material: {
					...preparedMaterial,
					name: preparedMaterial?.name + COPY_SUFFIX
				}
			})
		}
	}

	const onConfirmTMMaterial = () => {
		setShow(false)

		if (selectedValue === SelectedRadioType.new) {
			history.push(customizeNewUserMaterial(userId))
		} else {
			const tmMaterialInList = materials.find(
				(material: Material) => material.id === tmMaterial?.id
			)

			history.push(customizeNewUserMaterial(userId), {
				material: {
					...tmMaterialInList,
					name: tmMaterial?.name + COPY_SUFFIX
				}
			})
		}
	}

	const onCancel = () => {
		setShow(false)
		setSelectedValue(SelectedRadioType.new)
	}

	return (
		<CastorAlert
			alertClass="customize-material-alert"
			show={show}
			headerTitle={getString('USER_MATERIAL_ADD_NEW')}
			onConfirm={() =>
				isAmMaterial ? onConfirmAMMaterial() : onConfirmTMMaterial()
			}
			onCancel={onCancel}
			confirmOptionalText={OK}
		>
			<NewOrCopyRadioSelector
				selectedValue={selectedValue}
				setSelectedValue={setSelectedValue}
				newLabel={getString('CREATE_NEW_MATERIAL_TITLE').format(
					isAmMaterial ? getString('AM_MATERIAL') : getString('TM_MATERIAL')
				)}
				copyLabel={getString('CREATE_COPIED_MATERIAL_TITLE').format(
					isAmMaterial ? getString('AM_MATERIAL') : getString('TM_MATERIAL')
				)}
			/>
			{SelectedRadioType.copy === selectedValue &&
				(isAmMaterial ? (
					<AmMaterialSelector
						hideMaterialNameSelector={false}
						hiddenMaterialNameSelector={true}
						isButtonSelector={true}
						disabled={false}
						defaultMaterialForTypeArr={[
							defaultPrinterMetal,
							defaultPrinterMaterial
						]}
						defaultPrinterMaterial={defaultChosenMaterial}
						selectClassName="upload-project-material-selector-field"
						qaDataElementNameType={'data-qa-original-material-type-'}
						qaDataElementNameCategory={'data-qa-original-category-'}
						qaDataElementNameMaterial={'data-qa-original-material-'}
					/>
				) : (
					<MaterialSelector
						hideMaterialNameSelector={false}
						hiddenMaterialNameSelector={true}
						isButtonSelector={true}
						onChange={(material: Material) => setTMMaterial(material)}
						disabled={false}
						style={{ maxWidth: 50 }}
						defaultMaterialForTypeArr={[defaultMetal, defaultMaterial]}
						defaultMaterial={defaultChosenMaterial}
						selectClassName="upload-project-material-selector-field"
						qaDataElementNameType={'data-qa-original-material-type-'}
						qaDataElementNameCategory={'data-qa-original-category-'}
						qaDataElementNameMaterial={'data-qa-original-material-'}
					/>
				))}
		</CastorAlert>
	)
}

export default PopupMaterial
