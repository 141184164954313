export enum InfoData {
	projectAndParts = 'projectAndParts',
	personalInfo = 'personalInfo',
	subscriptionInfo = 'subscriptionInfo',
	materialsInfo = 'materialsInfo',
	printersInfo = 'printersInfo'
}

export enum SortBy {
	subscription = 'subscription',
	created = 'created'
}

export const togglePermission = {
	adminUserManager: 'adminUserManager',
	organizationOwner: 'organizationOwner',
	userOrganization: 'userOrganization'
}

export const adminUserTableParam = {
	addedCustomization: 'added_customization',
	totalPartsScanned: 'amount_of_scanned_parts',
	totalPartsCharged: 'amount_of_charged_parts',
	totalPartsDeleted: 'amount_of_deleted_parts',
	totalProjectUploaded: 'amount_of_uploaded_projects',
	company: 'company',
	created: 'createdAt',
	email: 'email',
	city: 'city',
	state: 'state',
	country: 'country',
	zipCode: 'zip_code',
	lat: 'lat',
	long: 'long',
	currency: 'currency',
	locale: 'locale',
	trial: 'trial',
	verified: 'verified',
	subscriptionDate: 'subscription_date',
	expireDate: 'subscription_expire_date',
	partsCredit: 'parts_credit',
	allTimeUploaded: 'amount_of_uploaded_files',
	partDownloadCount: 'partDownloadCount',
	remainingParts: 'amount_of_remain_parts',
	totalPartsLocked: 'amount_of_locked_parts',
	id: 'id',
	name: 'name',
	roles: 'roles',
	admin: 'admin',
	organizationOwner: 'organization_owner'
}

export const ALL_SITES_OPTION = '-1'
export const NOT_ADMIN_OPTION = '-2'
