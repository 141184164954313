import { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import ProjectPartItem from '../ProjectPartItem/ProjectPartItem'
import { starPartClicked } from 'Scenes/Components/StarIcon/StarIconActions'
import { format2D } from 'Scenes/Home/NewUploadProject/constants'
import {
	onPartClick,
	onRemovePartClick,
	onRemovePartConfirm
} from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import { InapplicableStatus } from 'Services/Constants'
import { Part } from 'Services/models/IPart'

const ProjectPartItemTSX: any = ProjectPartItem

interface ProjectPartTileProps {
	part: Part
	isBundle: boolean
}

const ProjectPartTile: FC<ProjectPartTileProps> = ({ part, isBundle }) => {
	const dispatch = useDispatch()
	const user = useSelector((state: RootStateOrAny) => state.user)

	if (part.isDrawing && part.drawingStatusCode === InapplicableStatus) {
		return <></>
	}

	const { id, partNumber, externalId, formatType } = part

	return (
		<ProjectPartItemTSX
			part={part}
			cardClassName="single-part-project--part--card"
			cardSizeWrapperClassName={cx(
				'single-part-project--part--card--size-wrapper',
				{ drawing: formatType === format2D }
			)}
			starPartClicked={() => dispatch(starPartClicked(part))}
			onRemovePartClick={() => dispatch(onRemovePartClick(id, partNumber))}
			onRemovePartConfirm={() =>
				dispatch(onRemovePartConfirm(id, externalId, isBundle))
			}
			onPartClick={() => dispatch(onPartClick(part, false, user))}
		/>
	)
}

export default memo(ProjectPartTile)
