export enum ImanufacturingTypes {
	mold = 'mold',
	cnc = 'cnc',
	AM = 'AM',
	cast = 'cast',
	investmentCast = 'investmentCast',
	sandCast = 'sandCast',
	standardCost = 'standardCost',
	lathe = 'lathe'
}
