import { uniqBy } from 'lodash'

import {
	PRINTER_MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR,
	PRINTER_MATERIAL_CHANGED_IN_MATERIAL_SELECTOR,
	PRINTER_MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR,
	SETUP_PRINTER_MATERIAL_PICKER,
	SUB_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR
} from '../../../../global actions/types'
import {
	filteredMaterialsByCategory,
	generateMaterialCategories,
	generateMaterialTypes
} from './AmMaterialSelectorService'
import { Material } from 'Services/models/IMaterial'
import { PrinterMaterial } from 'Services/models/IPrinterMaterial'
import { getTheme } from 'themes/getTheme'

const { defaultPrinterMaterial }: Record<string, any> = getTheme()
const materialPlaceholder = {
	name: '',
	id: -1,
	type: '',
	category: '',
	printerName: ''
}

export class AmMaterialSelectorInitialState {
	readonly printerMaterials: PrinterMaterial[] = []
	readonly defaultMaterialForTypeArr: Record<string, any>[] = []
	readonly material: Record<string, any> = defaultPrinterMaterial
	readonly materialType: string = defaultPrinterMaterial.type
	readonly materialCategory: string = defaultPrinterMaterial.category
	readonly materialSubCategory: string = defaultPrinterMaterial.subCategory
	readonly materialSubCategoriesArr: Record<string, any>[] = []
	readonly materialTypesArr: Record<string, any>[][] = []
	readonly materialCategoriesArr: Record<string, any>[] = []
	readonly materialArr: string[] = []
	readonly showCategorySelector: boolean = true
	readonly showMaterialsFilters: boolean = false
	readonly hideMaterialNameSelector: boolean = false
	readonly hiddenMaterialNameSelector: boolean = true
	readonly printerMaterialsSubCategories: Record<string, any>[] = []
	readonly printerMaterialsCategories: Record<string, any>[] = []
	readonly allPrinters: Record<string, any>[] = []
}
const initialState: AmMaterialSelectorInitialState =
	new AmMaterialSelectorInitialState()

const amMaterialSelectorReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SETUP_PRINTER_MATERIAL_PICKER: {
			const {
				defaultMaterialForTypeArr,
				printerMaterialsSubCategories,
				printerMaterials
			} = action.payload
			const materialCategoriesArr = generateMaterialCategories(
				printerMaterials,
				defaultPrinterMaterial.type
			)
			const materialTypesArr = generateMaterialTypes(printerMaterials)

			const material =
				printerMaterials.find(
					(material: Material) =>
						material.name === defaultPrinterMaterial.name &&
						material.category.toLocaleLowerCase() === defaultPrinterMaterial.category.toLocaleLowerCase()
				) || defaultPrinterMaterial

			const materialSubCategoriesArr = filteredMaterialsByCategory(
				printerMaterials,
				material.category
			)
			material.chooseMaterial = false

			return {
				...state,
				defaultMaterialForTypeArr,
				printerMaterials,
				materialSubCategoriesArr,
				printerMaterialsSubCategories,
				materialCategoriesArr,
				materialTypesArr,
				showCategorySelector: materialCategoriesArr.length > 1,
				material,
				materialType: material.type,
				materialCategory: material.category?.toLowerCase(),
				materialSubCategory: material.subCategory
			}
		}
		case PRINTER_MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR: {
			const {
				printerMaterials,
				defaultMaterialForTypeArr,
				printerMaterialsSubCategories
			} = state
			const selectedTypeDefaultMaterial = defaultMaterialForTypeArr?.find(
				material => material.type == action.payload
			)

			const setMaterial = printerMaterialsSubCategories?.find(
				material =>
					material?.category?.toLowerCase() ===
						selectedTypeDefaultMaterial?.category?.toLowerCase() &&
					material?.subCategory?.toLowerCase() ===
						selectedTypeDefaultMaterial?.subCategory?.toLowerCase()
			)

			const materialCategory = setMaterial?.category?.toLowerCase()
			const materialCategoriesArr = generateMaterialCategories(
				printerMaterials || [],
				action.payload
			)

			return {
				...state,
				materialSubCategoriesArr: filteredMaterialsByCategory(
					printerMaterials || [],
					materialCategory
				),
				materialCategory,
				materialType: setMaterial?.type,
				materialCategoriesArr,
				showCategorySelector: materialCategoriesArr.length > 1,
				material: setMaterial || materialPlaceholder,
				materialSubCategory: setMaterial?.subCategory
			}
		}
		case PRINTER_MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR: {
			const { printerMaterials, printerMaterialsSubCategories } = state

			const setMaterial = printerMaterialsSubCategories?.find(
				material => material?.category?.toLowerCase() === action.payload
			)

			const materialSubCategoriesArr = filteredMaterialsByCategory(
				printerMaterials || [],
				action.payload
			)

			return {
				...state,
				materialCategory: action.payload?.toLowerCase(),
				materialSubCategoriesArr: materialSubCategoriesArr,
				material:
					setMaterial || materialSubCategoriesArr[0] || materialPlaceholder,
				materialSubCategory: setMaterial?.subCategory
			}
		}
		case SUB_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR: {
			return {
				...state,
				material: state.printerMaterials?.find(
					material => action.payload === material.subCategory
				),
				materialSubCategory: action.payload
			}
		}
		case PRINTER_MATERIAL_CHANGED_IN_MATERIAL_SELECTOR: {
			const setMaterial = state.printerMaterials?.find(
				material => action.payload.value === material.id
			)
			if (setMaterial) {
				const printerId = JSON.parse(setMaterial?.printers) || []
				const printerName = action.payload.allPrinters.find(
					(p: any) => p.id === printerId[0]
				)
				setMaterial.printerName = printerName?.name || ''
				setMaterial.chooseMaterial = true
			}
			return {
				...state,
				material: setMaterial || materialPlaceholder
			}
		}
		default:
			return state
	}
}

export default amMaterialSelectorReducer
