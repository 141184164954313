import { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import Customize2DDrawingCost from '../Customize2DDrawingCost/Customize2DDrawingCost'
import { renderFormHeader, scrollToElement } from '../CustomizeFormCommon'
import CustomizeMaterialCost from '../CustomizeMaterialCost/CustomizeMaterialCost'
import CustomizeMetadataCost from '../CustomizeMetadataCost'
import CustomizeUserPostProcesses from '../CustomizeUserPostProcesses/CustomizeUserPostProcesses'
import CustomizePrinterProps from './CustomizePrinterProps'
import { History } from 'history'
import { fetchCustomizeData } from 'Scenes/Home/Customize/CustomizeActions'
import { setupPrinterPropsData } from 'Scenes/Home/Customize/CustomizeCostAndLeadSettings/CostAndLeadActions'
import {
	ADDITIVE_MANUFACTURING_SETTINGS_ROUTE,
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	drawingCost2dNames: Record<string, any>
	history: History
}

const setSettingsContent = () => {
	const isDrawingCost = Feature.isFeatureOn(
		FeatureComponentId.DRAWING_COST_COST_AND_LEAD
	)

	return (
		<div className="customize-cost-and-lead-settings">
			<CustomizePrinterProps />
			<CustomizeMaterialCost />
			<CustomizeUserPostProcesses />
			{isDrawingCost && <Customize2DDrawingCost />}
			<CustomizeMetadataCost />
		</div>
	)
}

const AdditiveManufacturingCustomizeCostAndLeadSettings: FC<IProps> = ({
	drawingCost2dNames,
	history
}) => {
	const dispatch = useDispatch()

	const { settingsData, costAndLeadNames } = useSelector(
		(state: RootStateOrAny) => state.CostAndLeadReducer
	)

	const { printersFullData } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	useEffect(() => {
		dispatch(
			fetchCustomizeData(
				costAndLeadNames,
				settingsData.userSettings,
				drawingCost2dNames,
				undefined,
				adminSelectedOrganizationId
			)
		)
	}, [])
	useEffect(() => {
		dispatch(setupPrinterPropsData(printersFullData))
	}, [])

	useEffect(() => {
		if (window.location.hash) {
			scrollToElement(window.location.hash)
		}
	}, [])

	useEffect(() => {
		if (window.location.hash) {
			scrollToElement(window.location.hash)
		}
	}, [window.location.hash])
	if (!settingsData.userSettings) {
		return <div />
	}
	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<CastorForm
				formHeader={renderFormHeader(
					history,
					USER_HOME_ROUTE +
						CUSTOMIZE_ROUTE +
						ADDITIVE_MANUFACTURING_SETTINGS_ROUTE
				)}
				formTitle={getString('CUSTOMIZE_FORM_COST_AND_LEAD_HEADER')}
				content={setSettingsContent()}
			/>
		</NavBarAndMaterial>
	)
}
export default memo(AdditiveManufacturingCustomizeCostAndLeadSettings)
