import React, {
	FC,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { cloneDeep } from 'lodash'

import GeneralToleranceSelector from './GeneralToleranceSelector'
import PDFViewer from './PDFViewer'
import {
	createDataSheetRow,
	createHeaders,
	prepareSpecificTolerancesData,
	setupTolerancesTable,
	validateData
} from './SpecificTolerancesService'
import { updateTolerancePartProperty } from 'global actions'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import DataSheet from 'Scenes/Components/DataSheet'
import { Danger } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { FormatType, Part } from 'Services/models/IPart'
import {
	GroupedTolerance,
	IDefaultPartDrawingTolerance,
	ToleranceOption,
	ToleranceType,
	ToleranceValueType
} from 'Services/models/SpecificTolerance'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'

import './SpecificTolerancesModal.scss'

type IProps = {
	showModal: boolean
	part: Part
	generalToleranceValue: ToleranceClass
	defaultPartDrawingTolerances: IDefaultPartDrawingTolerance[]
	generalToleranceOptions: ToleranceOption[]
	specificTolerances: Record<string, any>
	onCloseModal: () => void
}

const SpecificTolerancesModal: FC<IProps> = ({
	showModal,
	part,
	specificTolerances,
	generalToleranceValue,
	defaultPartDrawingTolerances,
	generalToleranceOptions,
	onCloseModal
}) => {
	const [generalTolerance, setGeneralTolerance] = useState(
		generalToleranceValue
	)
	const [tolerances, setTolerances] = useState<
		Record<string, GroupedTolerance[]>
	>({})
	const [changesPerformed, setChangesPerformed] = useState(false)
	const [toleranceImg, setToleranceImg] = useState('')
	const [toleranceValueIdSelected, setToleranceValueIdSelected] = useState('')
	const dispatch = useDispatch()
	const { userUnitSystem } = useSelector((state: RootStateOrAny) => state.user)

	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric

	const fileURL = useMemo(
		() =>
			part?.formatType === FormatType.threeDByPDF
				? part.combinePdfUrl
				: part.fileURL,
		[part.combinePdfUrl, part.fileURL, part?.formatType]
	)

	const confirmIsAllowed = useMemo(
		() =>
			changesPerformed &&
			Object.keys(tolerances).every(key =>
				tolerances[key].every(tolerance => !tolerance.error)
			),
		[changesPerformed, tolerances]
	)

	const onShowToleranceImageClick = useCallback(
		(url: string, toleranceValueId: string) => {
			setToleranceImg(url)
			setToleranceValueIdSelected(toleranceValueId)
		},
		[]
	)

	const onHideToleranceImageClick = useCallback(() => {
		setToleranceValueIdSelected('')
	}, [])

	const onValueUpdate = useCallback(
		(
			groupName: keyof typeof tolerances,
			type: ToleranceType,
			data: ToleranceValueType[]
		) => {
			let updatedData = cloneDeep(data)
			const updatedTolerances = cloneDeep(tolerances)
			const indexOfToleranceType = updatedTolerances[groupName].findIndex(
				tolerance => tolerance.type === type
			)
			let error = ''
			const addInitialRow = updatedData.every(
				datum => datum.filter(cell => !cell.readOnly && cell.value).length > 0
			)

			updatedData = updatedData.map(datum => {
				const errorText = validateData(datum)
				if (errorText) {
					error = errorText
				}
				return datum
			})
			updatedTolerances[groupName][indexOfToleranceType].values = updatedData
			updatedTolerances[groupName][indexOfToleranceType].error = error

			if (addInitialRow && !error) {
				const initialRow = createDataSheetRow(
					updatedTolerances[groupName][indexOfToleranceType].bilateralValues,
					updatedTolerances[groupName][indexOfToleranceType]
						.applicableToSurfaceArea
				)
				updatedTolerances[groupName][indexOfToleranceType].values.push(
					initialRow
				)
			}
			setTolerances(updatedTolerances)
			setChangesPerformed(true)
		},
		[tolerances]
	)

	const onGeneralToleranceChange = (value: ToleranceClass) => {
		setGeneralTolerance(value)
		setChangesPerformed(true)
	}

	const onModalConfirm = () => {
		const updatedSpecificTolerances = prepareSpecificTolerancesData(
			tolerances,
			unitSystem
		)
		dispatch(
			updateTolerancePartProperty(
				part.id,
				updatedSpecificTolerances,
				generalTolerance
			)
		)
		onCloseModal()
	}

	useEffect(() => {
		const tolerances = setupTolerancesTable(
			defaultPartDrawingTolerances,
			specificTolerances || {},
			unitSystem,
			onHideToleranceImageClick,
			onShowToleranceImageClick,
			toleranceValueIdSelected
		)
		setTolerances(tolerances)
	}, [
		defaultPartDrawingTolerances,
		specificTolerances,
		showModal,
		unitSystem,
		toleranceValueIdSelected,
		onHideToleranceImageClick,
		onShowToleranceImageClick
	])

	useEffect(() => {
		setGeneralTolerance(generalToleranceValue)
		setChangesPerformed(false)
		onHideToleranceImageClick()
	}, [generalToleranceValue, onHideToleranceImageClick, showModal])

	return (
		<CastorAlert
			headerTitle={getString('TOLERANCE_TABLE_HEADER')}
			onCancel={() => onCloseModal()}
			onConfirm={onModalConfirm}
			show={showModal}
			alertBodyClass="specific-tolerances--alert-body"
			disabled={!confirmIsAllowed}
			inPortal
			fullScreen
		>
			<h3>{getString('TOLERANCE_TABLE_HEADER')}</h3>
			<div>
				<GeneralToleranceSelector
					generalTolerance={generalTolerance}
					generalToleranceOptions={generalToleranceOptions}
					onGeneralToleranceChange={onGeneralToleranceChange}
				/>
			</div>
			<div className="specific-tolerances--wrapper">
				<div className="specific-tolerances--pdf-viewer--container">
					<PDFViewer
						imageURL={toleranceImg}
						fileUrl={fileURL}
						toleranceValueIdSelected={toleranceValueIdSelected}
						onImageClose={onHideToleranceImageClick}
					/>
				</div>
				<div className="specific-tolerances--table-section">
					{Object.keys(tolerances).map(key => {
						return (
							<div className="specific-tolerances--group-wrapper" key={key}>
								<h5 className="specific-tolerances--group-header">
									{getString(key)}
								</h5>
								{tolerances[key as keyof typeof tolerances].map(tolerance => {
									return (
										<div>
											<DataSheet
												data={tolerance.values}
												headers={createHeaders(tolerance, unitSystem)}
												onDataUpdated={data => {
													onValueUpdate(key, tolerance.type, data)
												}}
												containerClassName="specific-tolerances--group-table"
											/>
											{tolerance.error ? (
												<Danger>{tolerance.error}</Danger>
											) : (
												<div className="error-placeholder" />
											)}
										</div>
									)
								})}
							</div>
						)
					})}
				</div>
			</div>
		</CastorAlert>
	)
}

export default memo(SpecificTolerancesModal)
