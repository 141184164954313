import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	getProjectPartsMappingByProjectId,
	ignoreBlanksProjectPartMapping,
	saveProjectPartsMapping,
	updateProjectPartMappingInfo
} from './ProjectPartsMappingService'
import {
	CHANGE_PARTS_MAPPING_PAGE,
	GET_PROJECT_PART_MAPPING,
	GET_PROJECT_PART_MAPPING_LOADING,
	PROJECT_PART_MAPPING_CHANGE_3D_PART,
	PROJECT_PART_MAPPING_LOADING
} from './ProjectPartsMappingTypes'
import { HANDLE_NOTIFICATION } from 'global actions/types'
import { store } from 'index'
import { ProjectStatus } from 'Services/models/IProject'
import { getProjectAnalysisStatus } from 'Services/Network'
import Poller from 'Services/PollingService/Poller'
import { projectRoute } from 'Services/routeFuncs'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

let mappingPoller = new Poller()

export const getProjectPartsMapping = (projectOrBundleId: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: GET_PROJECT_PART_MAPPING_LOADING,
			payload: true
		})

		const {
			parts2DNames,
			partsMapping,
			projectStatus = null
		} = await getProjectPartsMappingByProjectId(projectOrBundleId)
		dispatch({
			type: GET_PROJECT_PART_MAPPING,
			payload: {
				parts2DNames,
				partsMapping,
				projectStatus
			}
		})

		dispatch({
			type: GET_PROJECT_PART_MAPPING_LOADING,
			payload: false
		})
	}
}

export const updateProjectPartMapping = (
	projectOrBundleId: string,
	projectId: string,
	name3D: string,
	name2D: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const { partsMapping } = await updateProjectPartMappingInfo(
			projectOrBundleId,
			projectId,
			name3D,
			name2D
		)

		dispatch({
			type: PROJECT_PART_MAPPING_CHANGE_3D_PART,
			payload: {
				partsMapping
			}
		})
	}
}
export const ignoreAllBlanksProjectPartMapping = (
	projectOrBundleId: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await ignoreBlanksProjectPartMapping(projectOrBundleId)
			const { partsMapping } = await getProjectPartsMappingByProjectId(
				projectOrBundleId
			)
			dispatch({
				type: PROJECT_PART_MAPPING_CHANGE_3D_PART,
				payload: {
					partsMapping
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
		} catch (err) {
			console.log(err)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
		}
	}
}

export const clearLoading = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: PROJECT_PART_MAPPING_LOADING,
			payload: false
		})
	}
}

export const startAnalyzing = (projectOrBundleId: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: PROJECT_PART_MAPPING_LOADING,
				payload: true
			})
			await saveProjectPartsMapping(projectOrBundleId)
			store.dispatch(getMappingPoller(projectOrBundleId))
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
		} catch (err) {
			console.log(err)
			dispatch({
				type: PROJECT_PART_MAPPING_LOADING,
				payload: false
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
		}
	}
}

export const getMappingPoller = (projectOrBundleId: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		let timeOutDateTime = new Date()
		timeOutDateTime.setHours(timeOutDateTime.getHours() + 1)
		mappingPoller = new Poller(
			2000,
			timeOutDateTime,
			() => getProjectAnalysisStatus(projectOrBundleId),
			(res: any) =>
				res.data.projectStatus == ProjectStatus.awaitingAnalysis ||
				res.data.projectStatus == ProjectStatus.awaitingExplode,
			res => {
				if (res) {
					switch (res.data.projectStatus) {
						case ProjectStatus.awaitingAnalysis: // Multi parts case
						case ProjectStatus.awaitingExplode: // Assembly case
							dispatch({
								type: PROJECT_PART_MAPPING_LOADING,
								payload: false
							})
							window.location.href = projectRoute(projectOrBundleId)
							break
						default:
							break
					}
				} else {
					throw new Error(getString('SOMETHING_WENT_WRONG'))
				}
			}
		)

		mappingPoller
			.start()
			.then(res => {
				setTimeout(() => {
					dispatch({
						type: PROJECT_PART_MAPPING_LOADING,
						payload: false
					})
				}, 2000)
			})
			.catch(error => {
				console.error(error)
			})
	}
}

export const changePartsMappingPage = (page: number) => {
	return {
		type: CHANGE_PARTS_MAPPING_PAGE,
		payload: { page }
	}
}
