import { useEffect } from 'react'

export const useKeyDown = (keyCode: number, callBackFunction: Function) => {
	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === keyCode) {
				callBackFunction()
			}
		}
		document.addEventListener('keydown', onKeyDown)
		return () => {
			document.removeEventListener('keydown', onKeyDown)
		}
	}, [keyCode, callBackFunction])
}
