import {
	PRINTER_MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR,
	PRINTER_MATERIAL_CHANGED_IN_MATERIAL_SELECTOR,
	PRINTER_MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR,
	SETUP_PRINTER_MATERIAL_PICKER,
	SUB_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR
} from '../../../../global actions/types'
import { PrinterMaterial } from 'Services/models/IPrinterMaterial'

export const setup = (
		printerMaterials: PrinterMaterial[],
		onChange: Function,
		initialMaterial: Record<string, any>,
		defaultMaterialForTypeArr: Record<string, any>[],
		printerMaterialsSubCategories: Record<string, any>[],
		printerMaterialsCategories: Record<string, any>[]
	) => {
		return {
			type: SETUP_PRINTER_MATERIAL_PICKER,
			payload: {
				printerMaterials,
				onChange,
				initialMaterial,
				defaultMaterialForTypeArr,
				printerMaterialsSubCategories,
				printerMaterialsCategories
			}
		}
	},
	materialTypeChanged = (value: string) => {
		return {
			type: PRINTER_MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR,
			payload: value
		}
	},
	materialCategoryChanged = (value: string) => {
		return {
			type: PRINTER_MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR,
			payload: value
		}
	},
	subCategoryChanged = (value: string) => {
		return {
			type: SUB_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR,
			payload: value
		}
	},
	materialChanged = (value: string, allPrinters: Record<string, any>[]) => {
		return {
			type: PRINTER_MATERIAL_CHANGED_IN_MATERIAL_SELECTOR,
			payload: { value, allPrinters }
		}
	}
