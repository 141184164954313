import { FC, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import { TabsItem } from '../ProjectAnalysisSuggestionService'
import { getPartsListViewHeaders } from './PartsListViewService'
import { changeOrderByName } from 'Scenes/Components/FilterPartsGrid/FilterPartsAction'

import { ReactComponent as SortByIcon } from 'assets/img/svg/sort-by.svg'

type IProps = {
	assemblyProjects?: boolean
	isQuickCADUpload?: boolean
	toPrint?: boolean
	isGenerativeDesignOn?: boolean
}

const ASC = 'asc'
const DESC = 'desc'

const allowedSortingNames = ['name', 'material']

const PartsListHeaders: FC<IProps> = () => {
	const dispatch = useDispatch()
	const { sortBy, sortingCriteria, selectedTab } = useSelector(
		(state: RootStateOrAny) => state.ProjectSuggestionReducer
	)
	const partListViewHeaders = useMemo(() => getPartsListViewHeaders(selectedTab), [selectedTab])

	return (
		<div className={cx('suggestion-parts-list--header suggestion')}>
			{partListViewHeaders?.map(
				(header: { name: string; displayName: string; sort?: string }) => {
					return (
						<div
							key={header.name}
							className="suggestion-parts-list--header--item"
						>
							{header.displayName}
							{(header.sort && selectedTab === TabsItem.opportunities) ||
							(header.sort && allowedSortingNames.includes(header.name)) ? (
								<>
									<SortByIcon
										className={`sort-by ${
											header.name === sortingCriteria ? sortBy : ''
										}`}
										onClick={() =>
											dispatch(
												changeOrderByName(
													sortBy === ASC ? DESC : ASC,
													header.name
												)
											)
										}
									/>
								</>
							) : (
								<></>
							)}
						</div>
					)
				}
			)}
		</div>
	)
}

export default PartsListHeaders
