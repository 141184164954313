import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { prepareToSendSuggestions } from './CustomizeSugesstionsService'
import {
	CUSTOMIZE_COST_SAVING_THRESHOLD,
	CUSTOMIZE_DEFAULT_SUGGESTIONS,
	FETCH_LIST_OF_SUGGESTION,
	FETCH_LIST_OF_SUGGESTION_CHANGE,
	FETCH_LIST_OF_SUGGESTION_CHANGE_AM,
	FETCH_LIST_OF_SUGGESTION_SUCCESS,
	SAVE_COST_DOLLAR_SUGGESTION_CHANGE,
	SAVE_COST_DOLLAR_SUGGESTION_LOADING,
	SAVE_COST_PERCENTAGE_SUGGESTION_CHANGE,
	SAVE_LIST_OF_SUGGESTION_LOADING,
	SAVE_LIST_OF_SUGGESTION_SUCCESS,
	SET_SELECTED_COST_SUGGESTION
} from './CustomizeSuggestionsTypes'
import { HANDLE_NOTIFICATION } from 'global actions/types'
import { store } from 'index'
import {
	getDefaultSuggestions,
	updateCostSavingThreshold,
	updateDefaultSuggestions
} from 'Services/NetworkSuggestions'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const onSaveListSuggestions = (organizationId: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: SAVE_LIST_OF_SUGGESTION_LOADING,
				payload: true
			})
			const { listOfSuggestion } = store.getState().CustomizeSuggestionsReducer

			const preparedListOfSuggestion =
				prepareToSendSuggestions(listOfSuggestion)

			const { data: { userSettings = {} } = {} } =
				await updateDefaultSuggestions(organizationId, preparedListOfSuggestion)

			if (userSettings) {
				dispatch({
					type: CUSTOMIZE_DEFAULT_SUGGESTIONS,
					payload: {
						defaultSuggestions: userSettings?.defaultSuggestions
					}
				})

				dispatch({
					type: SAVE_LIST_OF_SUGGESTION_SUCCESS
				})
			}

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
			dispatch({
				type: SAVE_LIST_OF_SUGGESTION_LOADING,
				payload: false
			})
		} catch (err) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})

			dispatch({
				type: SAVE_LIST_OF_SUGGESTION_LOADING,
				payload: false
			})
			console.error(err)
		}
	}
}

export const onChangeListSuggestions = (
	resultType: number,
	checked: boolean
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: FETCH_LIST_OF_SUGGESTION_CHANGE,
			payload: { resultType, checked }
		})
	}
}
export const onChangeAMListSuggestions = (
	checked: boolean
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: FETCH_LIST_OF_SUGGESTION_CHANGE_AM,
			payload: { checked }
		})
	}
}

export const fetchListOfSuggestions = (organizationId: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const { defaultSuggestions } = store.getState().user

			dispatch({
				type: FETCH_LIST_OF_SUGGESTION,
				payload: true
			})

			const response = await getDefaultSuggestions(organizationId)

			if (response.data) {
				dispatch({
					type: FETCH_LIST_OF_SUGGESTION_SUCCESS,
					payload: {
						defaultSuggestions: response.data?.defaultSuggestions,
						userDefaultSuggestions: defaultSuggestions
					}
				})
			}

			dispatch({
				type: FETCH_LIST_OF_SUGGESTION,
				payload: false
			})
		} catch (err: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: err.message || getString('SOMETHING_WENT_WRONG')
				}
			})

			dispatch({
				type: FETCH_LIST_OF_SUGGESTION,
				payload: false
			})
			console.error(err)
		}
	}
}

export const onSaveCostSuggestions = (
	organizationId: number,
	thresholdPercent: number,
	thresholdValue: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: SAVE_COST_DOLLAR_SUGGESTION_LOADING,
				payload: true
			})

			const {
				data: {
					userSettings: {
						costSavingThresholdPercent = thresholdPercent,
						costSavingThresholdValue = thresholdValue
					} = {}
				} = {}
			} = await updateCostSavingThreshold(
				organizationId,
				thresholdPercent,
				thresholdValue
			)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
			dispatch({
				type: CUSTOMIZE_COST_SAVING_THRESHOLD,
				payload: {
					costSavingThresholdPercent,
					costSavingThresholdValue
				}
			})
			dispatch({
				type: SAVE_COST_DOLLAR_SUGGESTION_LOADING,
				payload: false
			})
		} catch (err) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
			console.error(err)
		}
	}
}

export const onSelectCostSuggestion = (value: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: SET_SELECTED_COST_SUGGESTION,
				payload: {
					selectedCostSuggestion: value
				}
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const onUpdateCostDollarValue = (value: number | null) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SAVE_COST_DOLLAR_SUGGESTION_CHANGE,
			payload: value
		})
	}
}

export const onUpdateCostPercentageValue = (value: number | null) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SAVE_COST_PERCENTAGE_SUGGESTION_CHANGE,
			payload: value
		})
	}
}
