import React, { FC, useEffect } from 'react'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Fade } from '@material-ui/core'

import { ConfigurationResultTypes } from '../../MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import SolutionAnalysis from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysis'
import ConfigurationRemoveAlert from '../common/ConfigurationRemoveAlert'
import { useAllData } from '../PartAnalysisSelector'
import ConfigurationTabButtons from './ConfigurationTabButtons'
import ConfigurationDetails from './ConfigurationTabDetails'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import { getAllAlternativeSolutions } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisActions'
import { fadeAnimation } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisConstants'
import { SolutionConfigurationTypes } from 'Services/models/ISolutionConfiguration'

const SolutionAnalysisObject: any = SolutionAnalysis

interface ConfigurationTabProps {
	value: number
	index: number
	configuration: any
	partSolution: any
	showRemoveAlert: boolean
	isCastorTwo: boolean
	setShowRemoveAlert: Function
	activeTabChange: Function
	removeConfiguration: {
		label: string
		id: number
	}

	[name: string]: any
}

const ConfigurationTab: FC<ConfigurationTabProps> = props => {
	const dispatch = useDispatch()

	const {
		value,
		index,
		configuration,
		partSolution,
		showRemoveAlert,
		setShowRemoveAlert,
		activeTabChange,
		removeConfiguration,
		isCastorTwo = false,
		...other
	} = props

	const partAnalysis = useAllData()
	const {
		part,
		cluster,
		initialBatchSize,
		initialToleranceValue,
		disablePart,
		removeConfigurationLoading
	} = partAnalysis

	let showCurrentTab = isCastorTwo
		? value === index
		: configuration.resultType === ConfigurationResultTypes.WeightReduction
		? true
		: value === index

	const simpleConfiguration =
		configuration.type === SolutionConfigurationTypes.simple

	useEffect(() => {
		if (
			showCurrentTab &&
			configuration &&
			configuration?.alternativeSolutions?.length
		) {
			dispatch(
				getAllAlternativeSolutions(
					configuration?.id,
					configuration?.organizationId
				)
			)
		}
	}, [showCurrentTab, configuration])

	return (
		<Fade timeout={fadeAnimation} in={showCurrentTab}>
			<div
				role="tabpanel"
				hidden={!showCurrentTab}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}
			>
				{showCurrentTab && (
					<>
						<ConfigurationTabButtons
							partSolution={partSolution}
							configuration={configuration}
						/>
						<ErrorBoundary extra="SolutionAnalysisObject">
							<SolutionAnalysisObject
								disableAlternativeSolution
								isNewDesign
								initialBatchSize={initialBatchSize}
								configuration={configuration}
								part={part}
								cluster={cluster}
								key={configuration.id}
							/>
						</ErrorBoundary>
						<ErrorBoundary extra="ConfigurationDetails">
							<ConfigurationDetails
								activeTabChange={activeTabChange}
								disablePart={disablePart}
								initialToleranceValue={initialToleranceValue}
								configuration={configuration}
								part={part}
								partSolution={partSolution}
								cluster={cluster}
							/>
						</ErrorBoundary>
						{showRemoveAlert && (
							<ConfigurationRemoveAlert
								configurationName={removeConfiguration.label}
								simpleConfiguration={simpleConfiguration}
								showRemoveAlert={showRemoveAlert}
								setShowRemoveAlert={setShowRemoveAlert}
								removeConfigurationLoading={removeConfigurationLoading}
								configurationId={removeConfiguration.id}
							/>
						)}
					</>
				)}
			</div>
		</Fade>
	)
}

export default ConfigurationTab
