import { getThemeString } from '../../../themes/getThemeString'
import CastorFormHeader from '../../Components/CastorForm/CastorFormHeader'
import Devider from '../../Components/Devider/Devider'
import CustomizeFormSection from './CustomizeFormSection'
import OrganizationPanel from './OrganizationPanel/OrganizationPanel'
import { History } from 'history'
import {
	ADDITIVE_MANUFACTURING_SETTINGS_ROUTE,
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './CustomizeForm.scss'

export const renderCustomizeInHousePrinters = (linkTo: string) => {
	if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_IN_HOUSE_PRINTERS)) {
		return <></>
	}
	return (
		<>
			<CustomizeFormSection
				componentId={2}
				explanationHeader={getString('CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE')}
				explanationArray={getString(
					'CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY'
				)}
				linkTo={linkTo}
				qaDataElementName="data-qa-customize-in-house-printers"
			/>
			<Devider className="customize-form--divider" componentId={2} />
		</>
	)
}

export const renderFormHeader = (history: History, goBack: string | null) => {
	return (
		<div className="customize-settings--header">
			<CastorFormHeader
				goBack={goBack ? () => history.push(goBack) : null}
				explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
			<OrganizationPanel showEdit showAdd />
		</div>
	)
}

export const renderSemiProPrinters = (
	desktopPrinter: boolean,
	onToggleDesktopPrinter: Function,
	isShowSemiProfessionalPrintersToggle: boolean,
	adminSelectedOrganizationId: number
) => {
	if (
		!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_USER_SEMI_PRO_PRINTERS) ||
		!isShowSemiProfessionalPrintersToggle
	) {
		return <div></div>
	}
	return (
		<>
			<Devider className="customize-form--divider" componentId={2} />
			<CustomizeFormSection
				componentId={2}
				explanationHeader={getString('CUSTOMIZE_USER_SEMI_MATERIAL_TITLE')}
				explanationArray={getString(
					'CUSTOMIZE_USER_SEMI_MATERIAL_EXPLANATION_ARRAY'
				)}
				switchChecked={desktopPrinter}
				onToggleChange={() =>
					onToggleDesktopPrinter(desktopPrinter, adminSelectedOrganizationId)
				}
				qaDataElementName="data-qa-semi-professional-printers"
			/>
		</>
	)
}
export const renderCustomizeFilters = (linkTo: string) => {
	if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_FILTERS)) {
		return <div />
	}
	return (
		<>
			<CustomizeFormSection
				explanationHeader={getString('CUSTOMIZE_SOLUTION_FILTER_TITLE')}
				explanationArray={getString(
					'CUSTOMIZE_SOLUTION_FILTER_EXPLANATION_ARRAY'
				)}
				linkTo={linkTo}
				qaDataElementName="data-qa-customize-solution-filtering"
			/>
			<Devider className="customize-form--divider" />
		</>
	)
}
export const renderSustainabilityLifeCycle = (linkTo: string) => {
	if (!Feature.isFeatureOn(FeatureComponentId.SUSTAINABILITY_LIFE_CYCLE)) {
		return <div />
	}
	return (
		<>
			<Devider className="customize-form--divider" />
			<CustomizeFormSection
				explanationHeader={getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE')}
				explanationArray={getString('CUSTOMIZE_CO2_CALCULATIONS_ARRAY')}
				linkTo={linkTo}
				qaDataElementName="data-qa-customize-sustainability"
			/>
		</>
	)
}

export const scrollToElement = (id: string) => {
	const element = document.querySelector(id)
	if (element) {
		element.scrollIntoView({
			behavior: 'smooth'
		})
	}
}

export const goBackToRelatedCastor2 = () => {
	let goBackTo = USER_HOME_ROUTE + CUSTOMIZE_ROUTE
	if (Feature.isFeatureOn(FeatureComponentId.CASTOR_2_SETTINGS)) {
		goBackTo += ADDITIVE_MANUFACTURING_SETTINGS_ROUTE
	}
	return goBackTo
}
