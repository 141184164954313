import { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import ProjectResultDistributionChart from '../ProjectAnalysisChart/ProjectResultDistributionChart/ProjectResultDistributionChart'
import { starPartClicked } from 'Scenes/Components/StarIcon/StarIconActions'
import {
	exportPartsToExcel,
	onPartClick,
	onPartPropertiesClick,
	onRemovePartConfirm,
	onRemoveProjectClick,
	onStandardCostClick
} from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import { styles } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectMultipleParts/style'
import SinglePartProjectContent from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/SinglePartProjectContent'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import { Part } from 'Services/models/IPart'
import { Project } from 'Services/models/IProject'

import offTheShelfImg from 'assets/img/offTheShelfPlaceholder.png'

const ProjectResultDistributionChartTSX: any = ProjectResultDistributionChart

interface ProjectResultsContentProps {
	is2dProject: boolean
	isMetaDataProject: boolean
	project: Project
	onlyPart: Part
	isSinglePartProject: boolean
	amountOfLockedParts: string
	showStandardCostFinishIcon: boolean
	showExportButton: boolean
	onlyPartResultTitle: string
	selectedFilterPart: string
}

const ProjectResultsContent: FC<ProjectResultsContentProps> = ({
	is2dProject,
	isMetaDataProject,
	project,
	onlyPart,
	isSinglePartProject,
	amountOfLockedParts,
	showStandardCostFinishIcon,
	showExportButton,
	onlyPartResultTitle,
	selectedFilterPart
}) => {
	const user = useSelector((state: RootStateOrAny) => state.user)
	const {
		numberOfPrintableParts,
		numberOfAllBenefits,
		benefitsState,
		numberOfPrintablePart,
		allPartsInapplicable,
		allPartsNotCostEffective,
		printabilityData,
		totalPartsCount
	} = useSelector((state: RootStateOrAny) => state.ProjectAnalysisReducer)
	const { roles, contactUsEmail, userDetails } = user
	const history = useHistory()
	const dispatch = useDispatch()
	const image = project?.assemblyImage || offTheShelfImg
	const name = project.name
	const projectId = project.id
	const isAssembly = project.isAssembly

	if (isAssembly) {
		return (
			<ProjectResultDistributionChartTSX
				style={styles.assemblyProjectResultChart}
				contentStyle={styles.chartWhenAssembly}
				isAssembly={isAssembly}
				image={image}
				name={name}
				assemblyImage={styles.assemblyImage}
				numberOfPrintablePart={numberOfPrintablePart}
				benefitsState={benefitsState}
				numberOfAllBenefits={numberOfAllBenefits}
				project={project}
				is2dProject={is2dProject}
				isMetaDataProject={isMetaDataProject}
				allPartsInapplicable={allPartsInapplicable}
				numberOfPrintableParts={numberOfPrintableParts}
				printabilityData={printabilityData}
				totalPartsCount={totalPartsCount}
			/>
		)
	}
	if (isSinglePartProject) {
		const { partNumber, externalId } = onlyPart
		return (
			<SinglePartProjectContent
				is2dProject={is2dProject}
				allPartsNotCostEffective={allPartsNotCostEffective}
				amountOfLockedParts={amountOfLockedParts}
				part={onlyPart}
				showStandardCostFinishIcon={showStandardCostFinishIcon}
				showExportButton={showExportButton}
				onStandardCostClick={() => dispatch(onStandardCostClick())}
				history={history}
				userEmail={userDetails.email}
				contactUsEmail={contactUsEmail}
				lightUser={roles.includes(UserRole.LIGHT)}
				onRemoveProjectClick={() =>
					dispatch(onRemoveProjectClick(project, history))
				}
				onExportClick={() =>
					dispatch(exportPartsToExcel(projectId, selectedFilterPart))
				}
				starPartClicked={() => dispatch(starPartClicked(onlyPart))}
				onRemovePartClick={() =>
					dispatch(onRemoveProjectClick(project, history, partNumber))
				}
				onRemovePartConfirm={() =>
					dispatch(onRemovePartConfirm(partNumber, externalId, false))
				}
				onPartClick={() => dispatch(onPartClick(onlyPart, false, user))}
				partResultTitle={onlyPartResultTitle}
				onPartPropertiesClick={(projectId: string) =>
					dispatch(onPartPropertiesClick(projectId))
				}
			/>
		)
	}
	return (
		<ProjectResultDistributionChartTSX
			contentStyle={{ maxHeight: '25em' }}
			numberOfPrintablePart={numberOfPrintablePart}
			benefitsState={benefitsState}
			numberOfAllBenefits={numberOfAllBenefits}
			project={project}
			is2dProject={is2dProject}
			isMetaDataProject={isMetaDataProject}
			allPartsInapplicable={allPartsInapplicable}
			numberOfPrintableParts={numberOfPrintableParts}
			printabilityData={printabilityData}
			totalPartsCount={totalPartsCount}
		/>
	)
}

export default memo(ProjectResultsContent)
