import React, { memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import * as CustomizeToleranceCostActions from './CustomizeToleranceCostActions'
import CustomizeToleranceCostButton from './CustomizeToleranceCostButton'
import CustomizeToleranceCostInput from './CustomizeToleranceCostInput'
import CustomizeToleranceCostSelect from './CustomizeToleranceCostSelect'
import {
	onApplyAllToleranceData,
	onEditAllToggle,
	onEditToggleChange
} from './CustomizeToleranceCostService'
import {
	ItemGrid,
	Table
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { TOLERANCE_COSTS_TABLE } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { LengthUnit } from 'Services/UnitsConversionService'

import * as styles from '../customizeStyles.css'
import '../Customize2DDrawingCost/Customize2DDrawingCost.scss'
import './index.scss'

const ItemGridTSX: any = ItemGrid

interface IProps {}

interface IReduxStore {
	CostAndLeadReducer?: any
	CustomizeReducer: any
}

const CustomizeToleranceCostTbl: React.FC<IProps> = ({}) => {
	const allowedSelect = ['linearDimension']
	const dispatch = useDispatch()
	const { settingsData, isEditAllToleranceCost } = useSelector(
		(state: RootStateOrAny) => {
			return state.CostAndLeadReducer
		}
	)

	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => {
			return state.CustomizeReducer
		}
	)
	const {
		editToleranceData,
		toleranceEditToggle,
		toleranceApplyToggle,
		cancelToleranceEditData,
		toleranceEditAllToggle,
		updateAllToleranceData
	} = bindActionCreators(CustomizeToleranceCostActions, dispatch)

	const userSettings = settingsData.userSettings
	const tolerancePricesArranged = userSettings?.tolerancePricesArranged || []

	const renderPrice = (toleranceData: Record<string, any>) => {
		let inputStyle = styles.editedValue
		if (toleranceData.isPriceDefault) {
			inputStyle = styles.defaultValue
		}
		const apply = toleranceData.apply
		const isEdit = toleranceData.edit
		const minValue = toleranceData.minValue
		const maxValue = toleranceData.maxValue
		const errorMsg = toleranceData.alert

		return (
			<CustomizeToleranceCostInput
				toleranceData={toleranceData}
				errorMessage={errorMsg}
				valueInput={
					toleranceData?.editPrice?.tolerancePrice ||
					toleranceData?.tolerancePrice
				}
				isEdit={isEdit}
				isApply={apply}
				minValue={minValue}
				maxValue={maxValue}
				isDefault={toleranceData.isPriceDefault}
				styles={inputStyle}
				editToleranceData={editToleranceData}
			/>
		)
	}

	const selectToMultiply = (toleranceData: Record<string, any>) => {
		let inputStyle = styles.editedValue
		if (toleranceData.isMultiplyDefault) {
			inputStyle = styles.defaultValue
		}
		const apply = toleranceData.apply
		const isEdit = toleranceData.edit
		const minValue = toleranceData.minValue
		const maxValue = toleranceData.maxValue
		const errorMsg = toleranceData.alert

		const value =
			toleranceData?.editToMultiply?.toMultiply === 0 ||
			toleranceData?.editToMultiply?.toMultiply === 1
				? toleranceData?.editToMultiply?.toMultiply
				: toleranceData?.toMultiply
		const isDisabled = !allowedSelect.includes(toleranceData.toleranceType)

		return (
			<CustomizeToleranceCostSelect
				toleranceData={toleranceData}
				isDisabled={isDisabled}
				errorMessage={errorMsg}
				valueInput={value}
				isEdit={isEdit}
				isApply={apply}
				minValue={minValue}
				maxValue={maxValue}
				isDefault={toleranceData.isMultiplyDefault}
				styles={inputStyle}
				editToleranceData={editToleranceData}
			/>
		)
	}

	const renderAction = (toleranceData: Record<string, any>) => {
		const isEdit = toleranceData.edit
		return (
			<CustomizeToleranceCostButton
				toleranceData={toleranceData}
				isEdit={isEdit}
				toleranceApplyToggle={toleranceApplyToggle}
				toleranceEditToggle={toleranceEditToggle}
				onEditToggle={onEditToggleChange}
				cancelToleranceEditData={cancelToleranceEditData}
			/>
		)
	}

	const tableData = () => {
		let tableData: any = []
		const toleranceNames = getString('TOLERANCE_COST_TABLE_NAMES') || {}
		for (const tolerance of tolerancePricesArranged) {
			const name = (
				<span>
					{toleranceNames[tolerance.toleranceType].format(LengthUnit.mm) ||
						tolerance.toleranceType}
				</span>
			)
			const value = renderPrice(tolerance)
			const toMultiply = selectToMultiply(tolerance)
			const action = renderAction(tolerance)
			tableData.push([name, value, toMultiply, action])
		}
		return tableData
	}

	const editAll = (
		<div id="editAll">
			<TransparentButton
				className="custom-input--action"
				onClick={() => {
					onEditAllToggle(
						toleranceEditToggle,
						toleranceEditAllToggle,
						tolerancePricesArranged,
						isEditAllToleranceCost,
						cancelToleranceEditData
					)
				}}
			>
				{isEditAllToleranceCost
					? getString('CANCEL')
					: getString('DRAWING_COSTS_EDIT_ALL')}
			</TransparentButton>
			{isEditAllToleranceCost ? (
				<TransparentButton
					onClick={() =>
						onApplyAllToleranceData(
							tolerancePricesArranged,
							updateAllToleranceData,
							toleranceEditToggle,
							isEditAllToleranceCost,
							adminSelectedOrganizationId
						)
					}
					className="custom-input--action"
				>
					{getString('APPLY_ALL')}
				</TransparentButton>
			) : (
				<></>
			)}
		</div>
	)

	return (
		<div>
			<ItemGridTSX>
				<Table
					editTableStyle={'drawingCostTableCell'}
					tableHead={[
						TOLERANCE_COSTS_TABLE.PROPERTY_NAME,
						TOLERANCE_COSTS_TABLE.PRICE,
						TOLERANCE_COSTS_TABLE.TO_MULTIPLY,
						editAll
					]}
					tableData={tableData()}
				/>
			</ItemGridTSX>
		</div>
	)
}

export default memo(CustomizeToleranceCostTbl)
