import { memo } from 'react'
import { FC } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { SolutionSingleFilter } from './SolutionSingleFilter'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import { useSolutionFiltersRecalculateWarn } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { Material } from 'Services/models/IMaterial'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import {
	PART_ANALYSIS_CONFIGURE_FILTER_TITLE,
	PART_ANALYSIS_CONFIGURE_FILTER_TITLE_HOVER
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as Add } from 'assets/img/svg/icons/circle_close.svg'

import '../../SolutionAnalysis.scss'
import './../SolutionConfigure.scss'

const AddIcon: any = Add

interface IProps {
	className?: string
	isDesktopPrinterConfiguration?: boolean
	configurationId?: number
	userHavePrinters?: boolean
	allFieldsMandatory?: boolean
	printingTechnologies?: IPrintingTechnology[]
	onInhousePrinterClick?: Function
	onFilterFeatureClick: Function
	printerTechnology?: any
	configurationFilters?: any
	originalMaterial: Material
}

const SolutionConfigureFilters: FC<IProps> = ({
	className = '',
	configurationId,
	onFilterFeatureClick,
	printerTechnology,
	originalMaterial,
	configurationFilters = {}
}) => {
	const { filters, filterItemsList } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[
				configurationId as number
			] || new AdvancedSettingsInitialState()
	)
	const recalculateWarningMessage = useSolutionFiltersRecalculateWarn(
		configurationId as number,
		originalMaterial,
		configurationFilters
	)
	return (
		<div
			id={`part-analysis-material-properties-filters_${configurationId}`}
			className={className}
		>
			<Flexbox
				flexDirection="column"
				alignItems="center"
				width="100%"
				height="100%"
				position="relative"
			>
				<Flexbox
					className={'solution-analysis-object-content-configure-top-header'}
					alignItems="baseline"
					justifyContent="space-between"
					width="90%"
					marginBottom="28px"
				>
					<div className="solution-analysis-object-content-configure-widget-title--with-info">
						<div className="solution-analysis-object-content-configure-widget-title">
							{PART_ANALYSIS_CONFIGURE_FILTER_TITLE}
						</div>
						<InfoBox
							inPortal={true}
							boxContactElement={PART_ANALYSIS_CONFIGURE_FILTER_TITLE_HOVER}
							boxDirection="bottom"
							boxClassName="solution-analysis-object-content-configure-widget--popper"
							boxContactClassName="solution-analysis-object-content-configure-widget--popper--data"
						/>
					</div>
					<TransparentButton
						onClick={() => onFilterFeatureClick()}
						className="solution-analysis-object-content-configure-widget-feature"
						data-qa="data-qa-configuration-add-filter-btn"
					>
						<AddIcon className="add-circle" /> {getString('ADD')}{' '}
						{getString('FILTERS')}
					</TransparentButton>
				</Flexbox>
				<div className="solution-analysis-object-content-configure-filters">
					{filterItemsList?.map((filter: any) => (
						<SolutionSingleFilter
							key={filter?.key || filter?.id}
							showSolutionFilters={filters}
							filter={filter}
							printerTechnology={printerTechnology}
							originalMaterial={originalMaterial}
						/>
					))}
				</div>
				{recalculateWarningMessage ? (
					<p
						data-qa="data-qa-material-filter-warning-message"
						className="solution-warn"
					>
						{getString('RECALCULATE_CONFIGURATION_RESULT')}
					</p>
				) : (
					<></>
				)}
			</Flexbox>
		</div>
	)
}

export default memo(SolutionConfigureFilters)
