import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import cx from 'classnames'
import { VictoryPie } from 'victory'

import ProjectResultDistributionCustomLabel from '../ProjectResultDistributionCustomLabel'
import CastorRadio from 'Scenes/Components/CastorRadio'
import Flexbox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import Muted from 'Scenes/Components/thirdParty/CreativeTim/components/Typography/Muted.jsx'
import {
	benefitsAnalysisType,
	IBenefitStateType
} from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisInterfaces'
import { findBenefitTitle } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisService'
import {
	benefitAnalysisColors,
	piePassedColor,
	piePassedGradientStartColor
} from 'Services/colors'
import { Locale } from 'Services/models/IUser'
import { BENEFITS_RESULT_TITLE, INDEX } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getUserLanguage } from 'Services/Utils/startupTools'

interface IProps {
	styles: {
		card: Record<string, unknown>
		horizontalFlex: Record<string, unknown>
		footerFlex: Record<string, unknown>
		footer: Record<string, unknown>
		pieSuper: Record<string, unknown>
		text: Record<string, unknown>
	}
	numberOfPrintableParts: any
	numberOfPrintablePart: any
	numberOfAllBenefits: any
	benefitsState: any
	isAssembly: boolean
}

const VictoryPieTSX: any = VictoryPie
const FormControlLabelTSX: any = FormControlLabel
const RadioGroupTSX: any = RadioGroup

const BenefitAnalysisChart: FC<IProps> = ({
	styles,
	numberOfPrintableParts,
	numberOfAllBenefits,
	benefitsState,
	isAssembly
}) => {
	const [currentBenefit, setCurrentBenefit] = useState<benefitsAnalysisType>(
		benefitsAnalysisType.costSaving
	)

	const currentBenefitState: IBenefitStateType = useMemo(
		() =>
			benefitsState.find(
				(benefit: IBenefitStateType) => benefit.name === currentBenefit
			),
		[benefitsState, currentBenefit]
	)

	const language = getUserLanguage()
	const isEnLocale = language === Locale.en

	useEffect(() => {
		const nonEmptyBenefit: IBenefitStateType | undefined = benefitsState.find(
			(benefit: IBenefitStateType) => benefit.numberOfParts > 0
		)
		if (nonEmptyBenefit) {
			setCurrentBenefit(benefitsAnalysisType[nonEmptyBenefit.name])
		}
	}, [benefitsState])

	const renderPrintableBenefitsResultsStatsResultLabel = () => {
		return (
			<div className={cx('project-results--label--title')}>
				<div className="project-results--label--title--info">
					<IconFactory
						iconName={'benefit'}
						className="project-results--label--title--icon benefit-sign"
					/>
					<div className="project-results--label--text">
						{BENEFITS_RESULT_TITLE}
					</div>
				</div>
				<div
					className={cx('project-results--label--info', {
						hidden: !numberOfAllBenefits,
						high: isAssembly
					})}
				>
					<span className="project-results--label--title--out-of">
						{getString('PROJECT_ANALYSIS_BENEFITS_OUT_OF').format(
							numberOfPrintableParts
						)}
					</span>
					<span className="project-results--label--title--printable">
						{getString('PROJECT_ANALYSIS_OUT_OF_COST_SAVING_PRINTABLE').format(
							numberOfAllBenefits,
							numberOfAllBenefits > 1 && isEnLocale ? 's' : ''
						)}
					</span>
				</div>
			</div>
		)
	}

	const renderPrintableBenefitsResultsStatsPie = () => {
		const chartData = currentBenefitState?.printableChartData
		const noCurrentBenefit = currentBenefitState?.numberOfParts === 0
		const noBenefits = numberOfAllBenefits === 0

		return (
			<div
				className={cx('project-results--cost-saving-chart', {
					'limited-height': isAssembly,
					'grayed-out': noBenefits || noCurrentBenefit
				})}
			>
				<svg className="project-results--cost-saving-chart--gradient">
					<defs>
						<linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="0%" stopColor={piePassedGradientStartColor} />
							<stop offset="100%" stopColor={piePassedColor} />
						</linearGradient>
					</defs>
				</svg>
				<VictoryPieTSX
					labelComponent={
						noBenefits ? <div /> : <ProjectResultDistributionCustomLabel />
					}
					data={chartData}
					style={{
						labels: { fill: 'dark gray', fontSize: 19 },
						parent: {
							maxWidth: 345
						},
						data: {
							stroke: (datum: Record<string, unknown>) =>
								datum.y && datum.stroke,
							strokeWidth: 3
						}
					}}
					innerRadius={80}
					radius={125}
					colorScale={[
						benefitAnalysisColors[currentBenefit],
						'url(#gradient1)'
					]}
				/>
			</div>
		)
	}

	const renderPrintableBenefitsResultsStatsResultFooter = () => {
		const numberOfPartsWithBenefits = benefitsState.length

		if (!numberOfAllBenefits) {
			return (
				<div className="project-results--no-parts--description">
					{getString('NO_PROJECT_BENEFITS')}
				</div>
			)
		}
		return (
			<div
				className={cx('project-results--label--index', {
					'radio-buttons-grid': numberOfPartsWithBenefits > 3,
					'limited-height': isAssembly
				})}
			>
				<h5>{INDEX}</h5>
				<RadioGroupTSX
					id="part-benefits-options"
					name="select benefit"
					className={cx('radio-buttons-grid', { 'limited-width': isAssembly })}
					value={currentBenefit}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setCurrentBenefit(e.target.value as benefitsAnalysisType)
					}
				>
					{benefitsState.map((benefit: IBenefitStateType, i: number) => {
						const title = findBenefitTitle(benefit.name)
						return (
							<FormControlLabelTSX
								key={benefit.name + i}
								value={benefit.name}
								classes={{ root: 'form-control-root' }}
								control={
									<CastorRadio
										style={{
											color: benefitAnalysisColors[benefit.name],
											height: 'auto',
											marginRight: '-5px'
										}}
										classes={{}}
										size="small"
									/>
								}
								label={
									<Muted
										style={styles.text}
										className={cx({
											bolded: benefit.name === currentBenefit
										})}
									>
										{title}
									</Muted>
								}
							/>
						)
					})}
				</RadioGroupTSX>
			</div>
		)
	}

	return (
		<Flexbox
			flexDirection="column"
			className={cx('project-results--block', {
				'without-assembly-block': !isAssembly
			})}
			style={styles.footerFlex}
		>
			{renderPrintableBenefitsResultsStatsResultLabel()}
			{renderPrintableBenefitsResultsStatsPie()}
			{renderPrintableBenefitsResultsStatsResultFooter()}
		</Flexbox>
	)
}

export default memo(BenefitAnalysisChart)
