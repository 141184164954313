import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER } from '../../../../global actions/types/CastorBannerTypes'
import { IPartsResults } from '../../ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisInterfaces'
import { GET_PROJECTS_SUGGESTION_BUNGLE } from './ProjectBundleTypes'
import {
	GET_PROJECT_ANALYSIS_NO_PERMISSION,
	HANDLE_LOADER
} from 'global actions/types'
import { store } from 'index'
import { CastorBannerType } from 'Scenes/Components/CastorBanner/CastorBannerType.enum'
import { ReanalyzingStatus } from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SideBarTypes'
import { removeUpdatingProjectBanner } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import { RESET_PROJECTS_BUNGLE } from 'Scenes/Home/ProjectBundle/ProjectAnalysisBundle/ProjectBundleTypes'
import { USER_HAS_NO_PERMISSION } from 'Services/Constants'
import history from 'Services/history'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getProjectSuggestionsResults } from 'Services/NetworkSuggestions'
import { partConfigurationsRoute } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

export const getProjectsBundle = (projectId: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: RESET_PROJECTS_BUNGLE
		})

		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		try {
			let { data } = await await getProjectSuggestionsResults('', projectId)
			const reanalyzingStatus = data.projects[0].reanalyzingStatus
			const needToShowPopup = reanalyzingStatus === ReanalyzingStatus.pending

			removeUpdatingProjectBanner()

			if (needToShowPopup) {
				let bannerText = getString('BANNER_PROJECT_QUEUED_TO_RECALCULATE')
				let bannerType = CastorBannerType.QUEUED_TO_RECALCULATE

				dispatch({
					type: SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER,
					payload: {
						text: bannerText,
						type: bannerType
					}
				})
			}

			const { partsResults }: { partsResults: IPartsResults } = data || {}

			const skipProjectPage =
				Feature.isFeatureOn(FeatureComponentId.SKIP_PROJECT_PAGE) &&
				partsResults?.notCostEffectiveCount === 0

			// Skip to part if we have global skip or if there is only one cost-effective part.
			if (
				partsResults?.skipToPartId &&
				(Feature.isFeatureOn(FeatureComponentId.GLOBAL_SKIP_PROJECT_PAGE) ||
					skipProjectPage)
			) {
				const partRoute = partConfigurationsRoute(
					partsResults?.skipToPartProjectId,
					partsResults?.skipToPartId
				)
				history.push(partRoute)
				return
			}

			const userReducer = store.getState().user

			dispatch({
				type: GET_PROJECTS_SUGGESTION_BUNGLE,
				payload: {
					bundleId: projectId,
					projects: data.projects,
					partsResults,
					features: userReducer.features,
					clusters: [],
					userId: userReducer.userDetails.id
				}
			})

			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (err: any) {
			console.error(err)
			if (err.code === USER_HAS_NO_PERMISSION) {
				dispatch({
					type: GET_PROJECT_ANALYSIS_NO_PERMISSION,
					payload: err.message
				})
			}
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}
