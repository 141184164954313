import { isNumber } from 'lodash'

import {
	CHANGE_FILTERS_KEY,
	CHANGE_INITIAL_PROJECT_SETUP,
	CHANGE_ORDER_NAME,
	CHANGE_PARTS_PAGE,
	CHANGE_RESULT_TYPE,
	CHANGE_SEARCH_PHRASE,
	DO_REFRESH_CONFIGURATIONS,
	GET_PROJECT_PARTS_STARTED,
	GET_PROJECT_PARTS_SUCCESS,
	GET_PROJECT_PARTS_TO_PRINT_STARTED,
	GET_PROJECT_PARTS_TO_PRINT_SUCCESS,
	GET_PROJECT_SUGGESTIONS_PARTS_TO_PRINT_STARTED,
	GET_PROJECT_SUGGESTIONS_PRINT_SUCCESS,
	HANDLE_NOTIFICATION,
	PROJECT_SUGGESTION_UPDATE_SUGGESTIONS_LIST,
	UPDATE_PROJECT_CHALLENGES
} from '../../../global actions/types'
import {
	benefitsFiltersValues,
	filters,
	statusFiltersValues
} from './filterPartsEnum'
import { getPartFeaStatusByFeaSolution } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionFea/SolutionFeaContentService'
import { SelectedTypeEnum } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ChallengeFilter/ChallengeFilterAlertService'
import {
	convertBackPriceToVolume,
	convertCMtoMM
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/PartsListView/PartsListViewService'
import {
	ClientChallengesType,
	makePartBeSuggestion,
	TabsItem
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionService'
import { GET_PROJECT_SUGGESTIONS } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionTypes'
import { getProjectsParts } from 'Services/Network'
import {
	getChallengesParts,
	getNoSuggestionParts,
	getProjectSuggestionsResults,
	getSuggestionParts,
	updateChallengeFilersValue
} from 'Services/NetworkSuggestions'
import {
	CONFIGURATION_RESULT_PRINTABLE,
	SHOW_NOTIFICATION,
	SOMETHING_WENT_WRONG
} from 'Services/Strings'
import { UnitSystem } from 'Services/UnitsConversionService'

export const changeFilterKey = (
	selectedFilter,
	isBundle,
	singleFilter = false
) => {
	return {
		type: CHANGE_FILTERS_KEY,
		payload: { selectedFilter, isBundle, singleFilter }
	}
}

export const changeSearchPhrase = searchPhrase => {
	return {
		type: CHANGE_SEARCH_PHRASE,
		payload: searchPhrase
	}
}

export const changeOrderByName = (sortBy, sortingCriteria) => {
	return {
		type: CHANGE_ORDER_NAME,
		payload: {
			sortBy,
			sortingCriteria
		}
	}
}
export const changeResultType = resultType => {
	return {
		type: CHANGE_RESULT_TYPE,
		payload: {
			resultType
		}
	}
}

export const filterParts = (
	id,
	searchPhrase,
	filterValues,
	isBundle,
	page,
	limit,
	toPrint = false,
	user,
	updatePagination = false,
	searchHasChanged = false
) => {
	return async dispatch => {
		let status = []
		let benefits = []
		let assembliesIds = []
		let starred
		let standaloneParts

		filterValues.forEach(filter => {
			switch (filter) {
				case filters.ALL:
					return
				case filters.NOT_PRINTABLE:
				case filters.PRINTABLE:
				case filters.NOT_PRINTABLE:
					status.push(statusFiltersValues[filter])
					return
				case filters.SAVED:
					starred = 1
					return
				case filters.BUY_TO_FLY:
				case filters.COST_SAVING:
				case filters.TIME_SAVING:
				case filters.CO2_SAVING:
				case filters.COMPLEX_GEOMETRY:
					benefits.push(benefitsFiltersValues[filter])
					const printableStatus = status.indexOf(
						statusFiltersValues[CONFIGURATION_RESULT_PRINTABLE]
					)
					if (printableStatus < 0) {
						status.push(statusFiltersValues[CONFIGURATION_RESULT_PRINTABLE])
					}
					return
				case filters.STANDALONE_PARTS:
					standaloneParts = 1
					return
				default:
					assembliesIds.push(filter)
					return
			}
		})

		try {
			dispatch({
				type: toPrint
					? GET_PROJECT_PARTS_TO_PRINT_STARTED
					: GET_PROJECT_PARTS_STARTED
			})

			const {
				data: {
					parts,
					totalPartsCount,
					leadingConfigurationData,
					generativeDesignParts,
					feaSolutionsData
				}
			} = await getProjectsParts({
				projectId: isBundle ? '' : id,
				bundleId: isBundle ? id : '',
				searchPhrase,
				status,
				starred,
				benefits,
				standaloneParts,
				assembliesIds,
				page: toPrint ? 1 : page,
				limit: toPrint ? 0 : limit
			})

			for (const part of parts) {
				const partFeaSolution = feaSolutionsData.find(
					feaSolution => feaSolution.partId === part.id
				)
				if (partFeaSolution) {
					part.feaResult = getPartFeaStatusByFeaSolution(partFeaSolution)
				} else {
					part.feaResult = null
				}
			}

			dispatch({
				type: toPrint
					? GET_PROJECT_PARTS_TO_PRINT_SUCCESS
					: GET_PROJECT_PARTS_SUCCESS,
				payload: {
					parts,
					totalPartsCount,
					leadingConfigurationData,
					user,
					updatePagination: searchHasChanged || updatePagination,
					page,
					limit,
					generativeDesignParts
				}
			})

			dispatch({
				type: CHANGE_INITIAL_PROJECT_SETUP,
				payload: false
			})

			if (searchHasChanged) {
				dispatch({
					type: CHANGE_PARTS_PAGE,
					payload: { page }
				})
			}
		} catch (err) {
			console.log(err)
		}
	}
}

export const filterSuggestionsParts = (
	id,
	searchPhrase,
	isBundle,
	page,
	limit,
	toPrint = false,
	user,
	updatePagination = false,
	sortBy = '',
	sortingCriteria,
	selectedResultType,
	selectedTab
) => {
	return async dispatch => {
		if (!isNumber(selectedResultType)) return
		try {
			dispatch({
				type: toPrint
					? GET_PROJECT_SUGGESTIONS_PARTS_TO_PRINT_STARTED
					: GET_PROJECT_PARTS_STARTED
			})

			let suggestionsData = []
			let totalSuggestionsCountData = 0

			let fetchParts

			switch (selectedTab) {
				case TabsItem.opportunities:
					fetchParts = getSuggestionParts
					break
				case TabsItem.challenges:
					fetchParts = getChallengesParts
					break
				default:
					fetchParts = getNoSuggestionParts
					break
			}

			const {
				data: {
					suggestions = [],
					totalSuggestionsCount = 0,
					parts,
					totalPartsCount
				}
			} = await fetchParts({
				projectId: isBundle ? '' : id,
				bundleId: isBundle ? id : '',
				searchPhrase,
				page: toPrint ? 1 : page,
				limit: toPrint ? 0 : limit,
				sortBy,
				sortingCriteria,
				selectedResultType
			})

			suggestionsData =
				selectedTab === TabsItem.opportunities
					? suggestions
					: makePartBeSuggestion(parts)
			totalSuggestionsCountData =
				selectedTab === TabsItem.opportunities
					? totalSuggestionsCount
					: totalPartsCount

			let { data } = await await getProjectSuggestionsResults(
				isBundle ? '' : id,
				isBundle ? id : ''
			)

			dispatch({
				type: PROJECT_SUGGESTION_UPDATE_SUGGESTIONS_LIST,
				payload: {
					partsResults: data?.partsResults
				}
			})

			dispatch({
				type: toPrint
					? GET_PROJECT_SUGGESTIONS_PRINT_SUCCESS
					: GET_PROJECT_SUGGESTIONS,
				payload: {
					suggestions: suggestionsData,
					totalSuggestionsCount: totalSuggestionsCountData,
					user,
					updatePagination,
					page,
					limit,
					sortBy,
					sortingCriteria,
					selectedResultType
				}
			})

			dispatch({
				type: CHANGE_INITIAL_PROJECT_SETUP,
				payload: false
			})
		} catch (err) {
			console.log(err)
		}
	}
}

export const onUpdateChallengesFilters = (
	value,
	type,
	challengeType,
	projectId,
	isBundle,
	customizeUnitSystem,
	userUnitSystem
) => {
	return async dispatch => {
		try {
			dispatch({
				type: UPDATE_PROJECT_CHALLENGES,
				payload: {
					id: projectId,
					loading: true
				}
			})
			let val = value

			if (
				challengeType === ClientChallengesType.hightCostToVolume &&
				type === SelectedTypeEnum.input
			) {
				const convertVolume = customizeUnitSystem
					? convertBackPriceToVolume(+val, userUnitSystem)
					: +val

				val =
					userUnitSystem === UnitSystem.metric
						? convertCMtoMM(convertVolume)
						: convertVolume
			}

			const data = await updateChallengeFilersValue(
				val,
				type,
				challengeType,
				projectId
			)

			if (data) {
				let { data } = await await getProjectSuggestionsResults(
					isBundle ? '' : projectId,
					isBundle ? projectId : ''
				)

				dispatch({
					type: PROJECT_SUGGESTION_UPDATE_SUGGESTIONS_LIST,
					payload: {
						partsResults: data?.partsResults
					}
				})
				dispatch({
					type: DO_REFRESH_CONFIGURATIONS,
					payload: { refetchParts: true }
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: SOMETHING_WENT_WRONG
					}
				})
			}
		} catch (err) {
			console.error(err)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: SOMETHING_WENT_WRONG
				}
			})
		} finally {
			dispatch({
				type: UPDATE_PROJECT_CHALLENGES,
				payload: {
					id: projectId,
					loading: false
				}
			})
		}
	}
}
