export enum CADAnalysisResult {
  printable = 'printable',
  notPrintable = 'notPrintable',
  borderline = 'borderline',
  notProcessed = 'notProcessed',
  failed = 'failed',
  notCostEffective = 'notCostEffective',
  notPrintSuitable = 'notPrintSuitable',
  dependencyAnalysisFailed = 'dependencyAnalysisFailed',
  missingInformation = 'missingInformation',
  noOpportunities = 'noOpportunities'
}
