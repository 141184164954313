import { cloneDeep } from 'lodash'

import { filters } from './filterPartsEnum'

export const changeFilterValue = (
	value: string,
	selectedValues: string[],
	singleFilter: boolean = false
): string[] => {
	let currSelected = cloneDeep(selectedValues)
	const isNotAllSelected = currSelected.length >= 1 && value !== filters.ALL
	const isAllSelected = currSelected.length >= 1 && value === filters.ALL

	// if value exist need to uncheck
	if (currSelected.includes(value)) {
		currSelected = currSelected.filter(
			(currSelected: string) => currSelected !== value
		)
	} else {
		if (singleFilter) {
			currSelected = [value]
		} else {
			currSelected.push(value)
		}
	}

	// if there is some check and not All
	if (isNotAllSelected) {
		currSelected = currSelected.filter(
			(currSelected: string) => currSelected !== filters.ALL
		)
	}

	// if there is some check and All
	if (isAllSelected) {
		currSelected = [filters.ALL]
	}

	// if nothing selected
	if (currSelected.length === 0) currSelected.push(filters.ALL)

	return currSelected
}

export const prepareFiltersForLocalStorage = (
	currentFilters: string[] | null
) => {
	if (!currentFilters) return null
	const updatedValuesToLS = currentFilters.map(value => {
		const key = Object.keys(filters).find(
			key => filters[key as keyof typeof filters] === value
		)
		return key || value
	})
	return updatedValuesToLS
}

export const prepareFiltersForReducer = (filtersFromLS: string[] | null) => {
	if (!filtersFromLS) return null
	const updatedValuesToReducer = filtersFromLS.map(value => {
		return filters[value as keyof typeof filters] || value
	})
	return updatedValuesToReducer
}

export const filterAll = filters.ALL
export const filterPartStatus = [
	filters.SAVED,
	filters.PRINTABLE,
	filters.NOT_PRINTABLE
]
export const filterProperty2D = [
	filters.COST_SAVING,
	filters.BUY_TO_FLY,
	filters.TIME_SAVING,
	filters.CO2_SAVING
]
export const filterProperty3D = [...filterProperty2D, filters.COMPLEX_GEOMETRY]
export const filterSolutionsProperty = [
	filters.COST_SAVING,
	filters.TIME_SAVING,
	filters.CO2_SAVING
]
export const filterProductionMethod = [
	filters.LATHE_CNC,
	filters.CASTING_CNC,
	filters.SHEET_METAL,
	filters.OPTIMIZED
]

export const filterPartProjectType = [filters.STANDALONE_PARTS]
