import extendedTablesStyle from '../../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import React, { FC, useCallback } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'

import * as CustomizeUserMaterialsActions from '../CustomizeUserMaterialsActions.js'
import {
	EDIT,
	IN_HOUSE_PRINTERS_ACTION,
	MATERIAL_COST_DELETE_ITEM,
	USER_MATERIAL_NAME,
	USER_MATERIAL_NO_MATERIALS_FOUND,
	USER_MATERIAL_TYPE
} from '../../../../../Services/Strings.js'
import ItemGrid from '../../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import {
	Button,
	Muted,
	Table
} from '../../../../Components/thirdParty/CreativeTim/components/index.js'
import { findPrinterByMaterial } from '../NewOrCopyMaterials/NewOrCopyService'
import { addedDeviationExtToProperty } from 'Scenes/admin/AdminHome/AdminPrinterMaterials/AdminPrinterMaterialService'
import { Material } from 'Services/models/IMaterial.js'
import { customizeNewPrinterMaterial } from 'Services/routeFuncs.js'

const extendedTablesStyleTSX: any = extendedTablesStyle

interface CustomizeTableProps {
	classes: typeof extendedTablesStyleTSX
	showEdit?: boolean
}

const CustomizeUserMaterialsTable: FC<CustomizeTableProps> = ({
	classes,
	showEdit = true
}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { printersFullData } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const { userPrinterMaterials, userId, adminSelectedOrganizationId } =
		useSelector((state: RootStateOrAny) => ({
			userPrinterMaterials: state.CustomizeUserMaterialsReducer.userId
				? state.CustomizeUserMaterialsReducer.userPrinterMaterials
				: state.user.userPrinterMaterials,
			userId: state.CustomizeUserMaterialsReducer.userId,
			adminSelectedOrganizationId:
				state.CustomizeReducer.adminSelectedOrganizationId
		}))

	const onDeletePrinterMaterialClick = useCallback(
		(itemToDelete: Material) => {
			dispatch(
				CustomizeUserMaterialsActions.onDeletePrinterMaterialClick(
					itemToDelete,
					userId,
					adminSelectedOrganizationId
				)
			)
		},
		[dispatch, userId, adminSelectedOrganizationId]
	)

	const renderActionButtons = useCallback(
		(item: Material & { printers: string; dataValues?: Material }) => {
			return (
				<>
					{showEdit && (
						<Button
							title={EDIT}
							onClick={() => {
								const material = addedDeviationExtToProperty(
									findPrinterByMaterial(item, printersFullData)
								)
								history.push(customizeNewPrinterMaterial(), {
									isEdit: true,
									material
								})
							}}
							color="transparent"
							style={{ margin: '0 10px 0 0', padding: 'unset' }}
							customClass={classes.actionButton}
						>
							<EditIcon className={classes.iconEdit} />
						</Button>
					)}
					<Button
						title={MATERIAL_COST_DELETE_ITEM}
						onClick={() => onDeletePrinterMaterialClick(item)}
						color="dangerNoBackground"
						style={{ margin: 'unset', padding: 'unset' }}
						customClass={classes.actionButton}
					>
						<Close className={classes.icon} />
					</Button>
				</>
			)
		},
		[
			history,
			onDeletePrinterMaterialClick,
			classes,
			userPrinterMaterials,
			printersFullData
		]
	)

	const renderPrinterMaterialsTblData = useCallback(() => {
		if (!userPrinterMaterials || !userPrinterMaterials.length) {
			return [['', <Muted>{USER_MATERIAL_NO_MATERIALS_FOUND}</Muted>, '']]
		}

		return userPrinterMaterials.map(
			(
				userPrinterMaterial: Material & {
					printers: string
					dataValues?: Material
				}
			) => [
				userPrinterMaterial.name,
				userPrinterMaterial.type,
				renderActionButtons(userPrinterMaterial)
			]
		)
	}, [userPrinterMaterials, renderActionButtons])

	return (
		<ItemGrid
			data-qa={'data-qa-additive-manufacturing-materials-table'}
			classes={{ grid: 'customize-user-material--table-wrapper' }}
		>
			<Table
				editTableStyle={'materialCostTableCell'}
				tableHead={[
					USER_MATERIAL_NAME,
					USER_MATERIAL_TYPE,
					IN_HOUSE_PRINTERS_ACTION
				]}
				tableData={renderPrinterMaterialsTblData()}
			/>
		</ItemGrid>
	)
}

export default withStyles(extendedTablesStyleTSX)(CustomizeUserMaterialsTable)
