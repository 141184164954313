import React, { FC } from 'react'

import { TabsItem } from '../../ProjectAnalysisSuggestionService'
import { ITab } from '../../ProjectSuggestionTabTitle'
import { PART, PARTS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	resultType: number
	filteredPartsCount: number
	projectNames: string[]
	selectedTab: TabsItem
}

const PartsSummaryPDF: FC<IProps> = ({
	filteredPartsCount,
	projectNames,
	resultType,
	selectedTab
}) => {
	const selectedText = getString('PROJECT_TAB_STRINGS')?.find(
		(tab: ITab) => tab.tabKey === selectedTab
	)

	return (
		<div className="export-pdf--parts-summary">
			<div className="export-pdf--parts-summary--top-block">
				<div className="export-pdf--parts-summary--top-block--count">
					<b>{selectedText?.tabTitle || ''}</b>:{' '}
					<span>
						{filteredPartsCount}{' '}
						<span>{filteredPartsCount > 1 ? PARTS : PART}</span>
					</span>
				</div>
			</div>
		</div>
	)
}

export default PartsSummaryPDF
