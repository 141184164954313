import extendedTablesStyle from '../../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle'
import React, { FC, useCallback } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'

import * as CustomizeUserMaterialsActions from '../CustomizeUserMaterialsActions'
import {
	EDIT,
	IN_HOUSE_PRINTERS_ACTION,
	MATERIAL_COST_DELETE_ITEM,
	USER_MATERIAL_NAME,
	USER_MATERIAL_NO_MATERIALS_FOUND,
	USER_MATERIAL_TYPE
} from '../../../../../Services/Strings'
import {
	Button,
	Muted,
	Table
} from '../../../../Components/thirdParty/CreativeTim/components'
import ItemGrid from '../../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid'
import { Material } from 'Services/models/IMaterial'
import { customizeNewUserMaterial } from 'Services/routeFuncs'
import { sortOriginalMaterials } from 'Services/Utils/sortingTools'

const extendedTablesStyleTSX: any = extendedTablesStyle

interface CustomizeTableProps {
	classes: typeof extendedTablesStyleTSX
	showEdit?: boolean
}
const CustomizeUserMaterialsTable: FC<CustomizeTableProps> = ({
	classes,
	showEdit = true
}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { userMaterials, userId, adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => ({
			userMaterials: state.CustomizeUserMaterialsReducer.userId
				? state.CustomizeUserMaterialsReducer.userMaterials
				: state.user.userMaterials,
			userId: state.CustomizeUserMaterialsReducer.userId,
			adminSelectedOrganizationId:
				state.CustomizeReducer.adminSelectedOrganizationId
		})
	)

	const onDeleteClick = useCallback(
		(itemToDelete: Material) => {
			dispatch(
				CustomizeUserMaterialsActions.onDeleteClick(
					itemToDelete,
					userId,
					adminSelectedOrganizationId
				)
			)
		},
		[dispatch, userId, adminSelectedOrganizationId]
	)

	const renderActionButtons = useCallback(
		(item: Material) => {
			return (
				<>
					{showEdit && (
						<Button
							title={EDIT}
							onClick={() =>
								history.push(customizeNewUserMaterial(userId), {
									isEdit: true,
									material: item
								})
							}
							color="transparent"
							style={{ margin: '0 10px 0 0', padding: 'unset' }}
							customClass={classes.actionButton}
						>
							<EditIcon className={classes.iconEdit} />
						</Button>
					)}
					<Button
						title={MATERIAL_COST_DELETE_ITEM}
						onClick={() => onDeleteClick(item)}
						color="dangerNoBackground"
						style={{ margin: 'unset', padding: 'unset' }}
						customClass={classes.actionButton}
					>
						<Close className={classes.icon} />
					</Button>
				</>
			)
		},
		[history, userId, onDeleteClick, classes]
	)

	const renderUserMaterialsTblData = useCallback(() => {
		if (!userMaterials.length) {
			return [['', <Muted>{USER_MATERIAL_NO_MATERIALS_FOUND}</Muted>, '']]
		}

		return userMaterials
			.sort(sortOriginalMaterials)
			.map((userMaterial: any) => [
				userMaterial.name,
				userMaterial.type,
				renderActionButtons(userMaterial)
			])
	}, [userMaterials, renderActionButtons])

	return (
		<ItemGrid
			data-qa={'data-qa-traditional-manufacturing-materials-table'}
			classes={{ grid: 'customize-user-material--table-wrapper' }}
		>
			<Table
				editTableStyle={'materialCostTableCell'}
				tableHead={[
					USER_MATERIAL_NAME,
					USER_MATERIAL_TYPE,
					IN_HOUSE_PRINTERS_ACTION
				]}
				tableData={renderUserMaterialsTblData()}
			/>
		</ItemGrid>
	)
}

export default withStyles(extendedTablesStyleTSX)(CustomizeUserMaterialsTable)
