import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'
import { reset } from 'redux-form'

import { FormControlLabel } from '@material-ui/core'
import cx from 'classnames'
import { isBoolean } from 'lodash'
import Numeral from 'numeral'

import * as MainPartAnalysisActions from '../../../../../MainPartAnalysisActions'
import * as SolutionAnalysisActions from '../../../../SolutionAnalysisActions'
import { ConfigurationResultTypes } from '../../../../ConfigurationResultTypes'
import { SolutionAnalysisInitialState } from '../../../../SolutionAnalysisReducer'
import {
	AnalysisFooter,
	ProductionNumber,
	TraditionalManufacturingMethod
} from '../common'
import ChainBenefitsCheckBoxes from './ChainBenefitsCheckBoxes'
import Co2Comparison from './Co2Comparison'
import CostAnalysisTableAndSwitch from './CostAnalysisTable/CostAnalysisTableAndSwitch'
import CostAnalysisTableButtons from './CostAnalysisTable/CostAnalysisTableButtons/CostAnalysisTableButtons'
import CostAnalysisTableSwitch from './CostAnalysisTable/CostAnalysisTableSwitch'
import {
	cncPropsFormData,
	getCostInformation,
	getTotalValues,
	useShouldShowCostTable
} from './CostComparisonService'
import CostParametersForm from './CostParametersForm'
import { ActionWithPayload } from 'global actions/ActionModels'
import CastorSwitch from 'Scenes/Components/CastorSwitch'
import ChartWithLoader from 'Scenes/Components/ChartWithLoader'
import Devider from 'Scenes/Components/Devider/Devider'
import InfoBox from 'Scenes/Components/InfoBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { format2D, METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { manufacturingMethodTypes } from 'Services/Constants'
import { toNumberStringValue } from 'Services/global/calculateRange'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import { IStateExtractorState } from 'Services/HOR/InstanceStateExtractor'
import {
	ICastFormParameters,
	IChartData,
	IMetalFormParameters
} from 'Services/models/CostComparisonModels'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import {
	AssemblingParams,
	SuggestionType
} from 'Services/models/IConfiguration'
import { Part } from 'Services/models/IPart'
import { SupplyChainResultObject } from 'Services/models/ISupplyChain'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import { IPlasticFormParameters } from 'Services/models/PlasticCostComparisonModels'
import {
	MANUFACTURING,
	PART_ANALYSIS_TOP_DETAILS_COST_ESTIMATION_POP_UP_ROWS
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import '../../SolutionNewTabStyles.scss'
import './CostComparisonTab.scss'

const COST_ESTIMATION = PART_ANALYSIS_TOP_DETAILS_COST_ESTIMATION_POP_UP_ROWS[0]
const TOTAL = PART_ANALYSIS_TOP_DETAILS_COST_ESTIMATION_POP_UP_ROWS[4]
const LabelFormControl: any = FormControlLabel
const DeviderTSX: any = Devider

interface IProps {
	part: Part
	solution: any
	configuration: any
}

interface IReduxProps {
	costFormMode: boolean
	chartData: IChartData | null
	batchSize: number
	formParameters: IPlasticFormParameters &
		IMetalFormParameters &
		ICastFormParameters
	plasticFormValues: any
	castFormValues: any
	metalFormValues: any
	showCostFormCancelButton: boolean
	reset: Function
	chosenMaterial: any
	manufacturingMethod: string
	userCurrencySign: string
	userCustomizationSettings: any
	costLoading: boolean
	costBenefit: any
	costDetails: any
	isTooling: boolean
	effectiveQuantity: any
	tempChainBenefits: IChainBenefits
	costAffectivePoint: number
	costAffectivePointQuantity: number
	printCostQuantity: number
	effectivePointIndex: number
	supplyChainCostsDetailsTM: SupplyChainResultObject | null
	configurations: any
	outsourcedCNCManufacturingToggle: boolean
	partPrintIssues: PartPrintIssue[]
	expectedYearsOfDemand: number
	chosenOrientation: any
	isCostEffective: boolean
	customConfiguration: boolean
	onCastToolingCostChange: any
	onInvestmentCastToolingCostChange: any
	onSandCastToolingCostChange: any
	onMoldPriceCostChange: any
	defaultProjectScenarios: any
	defaultProjectScenarioParameters: any
	projectScenarioSettings: any
	productLifeCycle: string
	selectedProductLifeCycle: string
	showAssemblingCostAlert: boolean
	assemblingParams: AssemblingParams | null
	isAmOriginalMaterial: boolean
	defaultLayerThickness: number | null
	onCostFormSubmit: (
		...onPlasticCostFormSubmitParams: Parameters<
			typeof SolutionAnalysisActions.onCostFormSubmit
		>
	) => any
	onMetalCostFormSubmit: (
		...onMetalCostFormSubmitParams: Parameters<
			typeof SolutionAnalysisActions.onMetalCostFormSubmit
		>
	) => any
	onCostEditClicked: (id: number) => ActionWithPayload<any>
	onCostFormCancelled: (id: number) => ActionWithPayload<any>
	resetChainBenefits: (id: number) => ActionWithPayload<any>
	resetTooling: (id: number, isTooling: boolean) => ActionWithPayload<any>
	onCostQuantityChanged: (id: number, value: number) => ActionWithPayload<any>
	onCostQuantityUpdated: (
		...onCostQuantityUpdatedParams: Parameters<
			typeof SolutionAnalysisActions.onCostQuantityUpdated
		>
	) => any
	onManufacturingMethodChange: (
		...onManufacturingMethodChangeParams: Parameters<
			typeof SolutionAnalysisActions.onManufacturingMethodChange
		>
	) => ActionWithPayload<any>
	onOutsourcedCNCManufacturingToggleUpdate: (
		...onOutsourcedCNCManufacturingToggleUpdateParams: Parameters<
			typeof SolutionAnalysisActions.onOutsourcedCNCManufacturingToggleUpdate
		>
	) => ActionWithPayload<any>
	onLifeCycleChangeFullData: (
		lifeCycle: string,
		manufacturingMethod: string,
		defaultProjectScenarios: any,
		configuration: any
	) => any
	onLifeCycleChange: (lifeCycle: string, id: number) => any
	toggleAssemblingCostModal: (id: number, open: boolean) => any
}

interface IReduxStore {
	SolutionAnalysisReducer: IStateExtractorState
	MainPartAnalysisReducer: any
	CustomizeSupplyChainReducer: any
	user: any
}

const CostComparisonTab: FC<IProps & IReduxProps> = ({
	part,
	solution,
	configuration,
	costFormMode,
	chartData,
	batchSize,
	formParameters,
	plasticFormValues,
	castFormValues,
	metalFormValues,
	showCostFormCancelButton,
	reset,
	manufacturingMethod,
	costLoading,
	userCurrencySign,
	effectiveQuantity,
	tempChainBenefits,
	supplyChainCostsDetailsTM,
	configurations,
	outsourcedCNCManufacturingToggle,
	userCustomizationSettings,
	partPrintIssues,
	expectedYearsOfDemand,
	chosenOrientation,
	isCostEffective,
	customConfiguration,
	onCastToolingCostChange,
	onInvestmentCastToolingCostChange,
	onSandCastToolingCostChange,
	onMoldPriceCostChange,
	defaultProjectScenarios,
	defaultProjectScenarioParameters,
	productLifeCycle,
	selectedProductLifeCycle,
	isTooling,
	showAssemblingCostAlert,
	assemblingParams,
	isAmOriginalMaterial,
	onOutsourcedCNCManufacturingToggleUpdate,
	onLifeCycleChange,
	onCostEditClicked,
	onCostFormCancelled,
	resetChainBenefits,
	resetTooling,
	onCostFormSubmit,
	onMetalCostFormSubmit,
	onCostQuantityChanged,
	onCostQuantityUpdated,
	onManufacturingMethodChange,
	onLifeCycleChangeFullData,
	toggleAssemblingCostModal,
	defaultLayerThickness
}) => {
	const isCluster =
		!!configuration.cluster || part.id === configuration?.cluster?.id
	const isChallenges =
		configuration.resultType === ConfigurationResultTypes.Challenges
	const [showAnalysisTable, setShowAnalysisTable] = useState(
		isCluster || isChallenges
	)

	const [showSwitchButton, setShowSwitchButton] = useState(true)
	const showCostInRange = part?.isDrawing || part?.formatType === METADATA
	const projectSettingsScenariosOn = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_SETTINGS_SCENARIOS
	)
	const showEmission =
		Feature.isFeatureOn(FeatureComponentId.CO2_EMISSIONS) &&
		configuration.suggestionType === SuggestionType.AM

	const drawingCostPercentage = useSelector((state: RootStateOrAny) => {
		return (
			state?.MainPartAnalysisReducer?.allConfigurationsOrganizationSettings[
				configuration.organizationId
			]?.drawingCostPercentage ||
			state?.user?.userCustomizationSettings?.drawingCostPercentage ||
			state?.MainPartAnalysisReducer?.drawingCostPercentage
		)
	})

	const blockManufacturingMethodOperation =
		part.blockManufacturingMethodOperation || false

	const onAnalysisTableSwitchChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setShowAnalysisTable(e.target.checked)
		},
		[]
	)
	const standardCost = configuration?.standardCost || part.standardCost
	const shouldShowStandardCost =
		manufacturingMethod === manufacturingMethodTypes.standardCost ||
		(blockManufacturingMethodOperation && standardCost)

	const shouldShowCostTable = useShouldShowCostTable(solution, configuration)
	const lowBatchSize = batchSize < 1
	const traditionalCostCalculatedCustom =
		configuration?.traditionalCostCalculatedCustom ||
		configuration?.comparedSolutionCostCalculatedCustom
	const isWRConfig =
		configuration.resultType === ConfigurationResultTypes.WeightReduction

	const onCostFormCancel = () => {
		const productLifeCycle =
			configuration.productLifeCycle ||
			defaultProjectScenarios.find((cycle: { key: string }) => {
				if (cycle.key === part.productLifeCycle) {
					return cycle
				}
				return ''
			})?.name
		onLifeCycleChange(productLifeCycle, configuration.id)
		reset(`costForm_${configuration.id}`)
		onCostFormCancelled(configuration.id)
		resetChainBenefits(configuration.id)

		if (isBoolean(configuration.isTooling)) {
			resetTooling(configuration.id, configuration.isTooling)
		}
	}
	useEffect(() => {
		if (configuration.productLifeCycle) {
			onLifeCycleChange(configuration.productLifeCycle, configuration.id)
		} else {
			const lifeCycle = defaultProjectScenarios.find(
				(cycle: { key: string }) => {
					if (cycle.key === part.productLifeCycle) {
						return cycle
					}
				}
			)
			onLifeCycleChange(lifeCycle?.name, configuration.id)
		}
	}, [])

	useEffect(() => {
		if (isWRConfig || isChallenges) {
			setShowSwitchButton(false)
			setShowAnalysisTable(true)
		}
	}, [isWRConfig, isChallenges])

	const lifeCycleChangeFullData = (lifeCycle: string) => {
		onLifeCycleChangeFullData(
			lifeCycle,
			manufacturingMethod,
			defaultProjectScenarios,
			configuration
		)
	}

	const onCostFormSubmitData = (
		data: any,
		isMolding: boolean = false,
		isTooling: boolean,
		manufacturingMethod: string,
		printJobs: boolean,
		defaultLayerThickness?: number | null
	) => {
		let updatedData = {
			...data
		}

		if (projectSettingsScenariosOn) {
			updatedData = {
				...updatedData,
				...(isMolding
					? { moldCost: part.moldCost }
					: manufacturingMethod === manufacturingMethodTypes.cast
					? { castToolingCost: part.castToolingCost }
					: manufacturingMethod === manufacturingMethodTypes.investmentCast
					? { investmentCastToolingCost: part.investmentCastToolingCost }
					: { sandCastToolingCost: part.sandCastToolingCost })
			}
		}

		const selectedLifeCycle = defaultProjectScenarios.find(
			(element: any) =>
				element.key === selectedProductLifeCycle ||
				element.name === selectedProductLifeCycle
		)

		onCostFormSubmit(
			updatedData,
			batchSize,
			part.id,
			solution,
			configuration.id,
			manufacturingMethod,
			configuration.postProcessesOptional || {},
			tempChainBenefits,
			configurations,
			expectedYearsOfDemand,
			chosenOrientation,
			isTooling,
			selectedLifeCycle?.name,
			printJobs,
			defaultLayerThickness
		)
	}

	const renderForm = () => {
		// Set the value from the part, if any.
		const partParameters = {
			puAnnualKgCO2: part.puAnnualKgCO2,
			puYearsCO2: part.puYearsCO2,
			disposeFactor: part.disposeFactor
		}
		let selectedProductLifeCyclePart = defaultProjectScenarios.find(
			(element: any) =>
				element.key === selectedProductLifeCycle ||
				element.name === selectedProductLifeCycle
		)
		return (
			<CostParametersForm
				configuration={configuration}
				isProject2dType={part?.formatType === format2D}
				formParameters={{
					...userCustomizationSettings,
					...formParameters,
					...partParameters
				}}
				showCancelButton={showCostFormCancelButton}
				initialValues={getInitialFormValues(manufacturingMethod)}
				costLoading={costLoading}
				form={`costForm_${configuration.id}`}
				manufacturingTypes={manufacturingMethod}
				onCancel={onCostFormCancel}
				outsourcedCNCManufacturingToggle={outsourcedCNCManufacturingToggle}
				onOutsourceChange={(e: ChangeEvent<HTMLInputElement>) =>
					onOutsourcedCNCManufacturingToggleUpdate(
						configuration.id,
						batchSize,
						part,
						manufacturingMethod,
						configuration,
						outsourcedCNCManufacturingToggle,
						partPrintIssues
					)
				}
				onFormSubmit={(data: any) => {
					if (
						[
							manufacturingMethodTypes.cast,
							manufacturingMethodTypes.investmentCast,
							manufacturingMethodTypes.sandCast,
							manufacturingMethodTypes.mold
						].includes(manufacturingMethod) ||
						part.clusterId
					) {
						return onCostFormSubmitData(
							data,
							manufacturingMethod === manufacturingMethodTypes.mold,
							isTooling,
							manufacturingMethod,
							selectedProductLifeCyclePart.printJobs,
							defaultLayerThickness
						)
					}
					const cncProps = cncPropsFormData(
						part,
						data,
						projectSettingsScenariosOn,
						metalFormValues
					)
					const selectedLifeCycle = defaultProjectScenarios.find(
						(scenario: any) =>
							scenario.key === selectedProductLifeCycle ||
							scenario.name === selectedProductLifeCycle
					)

					return onMetalCostFormSubmit(
						part.id,
						cncProps,
						solution,
						configuration.id,
						manufacturingMethod,
						batchSize,
						configuration.postProcessesOptional || {},
						tempChainBenefits,
						configurations,
						expectedYearsOfDemand,
						chosenOrientation,
						isTooling,
						selectedLifeCycle?.name,
						selectedProductLifeCyclePart.printJobs,
						defaultLayerThickness
					)
				}}
			/>
		)
	}

	const getInitialFormValues = (manufacturingMethod: string) => {
		switch (manufacturingMethod) {
			case manufacturingMethodTypes.mold:
				return plasticFormValues
			case manufacturingMethodTypes.cast:
			case manufacturingMethodTypes.investmentCast:
			case manufacturingMethodTypes.sandCast:
				return castFormValues
			default:
				return metalFormValues
		}
	}

	const renderCostDetailsIcon = (showShortInfo?: boolean) => {
		const costDetailsPopUpWithoutExplanation = () => {
			const {
				calculatedInventoryCost,
				calculatedUpfrontCost,
				calculatedProductionCost,
				calculatedTotalCostPerUnit
			} = getTotalValues(
				supplyChainCostsDetailsTM,
				showCostInRange,
				drawingCostPercentage,
				showShortInfo
			)

			const standardCost = toFixedOnlyIfNeeded(
				configuration?.standardCost || part.standardCost
			)

			return (
				<>
					{showShortInfo ? (
						<>
							<div className="cost-details-row-header">{COST_ESTIMATION}</div>
							<div className="cost-details-row">
								<div>{MANUFACTURING}</div>
								<div>{`${userCurrencySign}${standardCost}`}</div>
							</div>
						</>
					) : (
						<>
							<div className="cost-details-row-header">{COST_ESTIMATION}</div>
							{calculatedProductionCost && !traditionalCostCalculatedCustom && (
								<div
									className="cost-details-row"
									data-qa="data-qa-traditional-cost-row"
								>
									{getString('PRODUCTION')}
									<div>{`${userCurrencySign}${calculatedProductionCost}`}</div>
								</div>
							)}
						</>
					)}
					{toNumberStringValue(calculatedUpfrontCost) ? (
						<div
							className="cost-details-row"
							data-qa="data-qa-traditional-cost-row"
						>
							<div>{getString('UPFRONT')}</div>
							<div>{`${userCurrencySign}${calculatedUpfrontCost}`}</div>
						</div>
					) : (
						<div />
					)}
					{toNumberStringValue(calculatedInventoryCost) ? (
						<div
							className="cost-details-row"
							data-qa="data-qa-traditional-cost-row"
						>
							<div>{getString('INVENTORY')}</div>
							<div>{`${userCurrencySign}${calculatedInventoryCost}`}</div>
						</div>
					) : (
						<div />
					)}
					<DeviderTSX size="228px" className="cost-details-row-divider" />
					<div
						className="cost-details-row"
						data-qa="data-qa-traditional-cost-row"
					>
						<div className="cost-details-row-total">{TOTAL}</div>
						<div
							className="cost-details-row-total"
							data-qa="data-qa-traditional-total-cost"
						>{`${userCurrencySign}${calculatedTotalCostPerUnit}`}</div>
					</div>
					<DeviderTSX
						size="228px"
						className="cost-details-row-divider cost-details-row-divider--last"
					/>
				</>
			)
		}

		return (
			<InfoBox
				boxContactElement={costDetailsPopUpWithoutExplanation()}
				iconClassName="cost-details-icon"
				boxDirection="right-end"
				dataQaTarget="data-qa-traditional-cost-tooltip"
			/>
		)
	}

	const renderCostCheckBox = () => {
		if (manufacturingMethod === manufacturingMethodTypes.standardCost) {
			return <div />
		}
		return (
			<ChainBenefitsCheckBoxes
				isTooling={isTooling}
				onCastToolingCostChange={onCastToolingCostChange}
				onInvestmentCastToolingCostChange={onInvestmentCastToolingCostChange}
				onSandCastToolingCostChange={onSandCastToolingCostChange}
				onMoldPriceCostChange={onMoldPriceCostChange}
				castToolingCost={formParameters?.castToolingCost || ''}
				investmentCastToolingCost={
					formParameters?.investmentCastToolingCost || ''
				}
				sandCastToolingCost={formParameters?.sandCastToolingCost || ''}
				moldPrice={formParameters?.moldPrice || ''}
				manufacturingMethod={manufacturingMethod}
				defaultProjectScenarios={defaultProjectScenarios}
				defaultProjectScenarioParameters={defaultProjectScenarioParameters}
				onLifeCycle={(lifeCycle: string) => lifeCycleChangeFullData(lifeCycle)}
				selectedProductLifeCycle={selectedProductLifeCycle}
				productLifeCycle={productLifeCycle}
			/>
		)
	}

	const renderChartResultsExplanation = () => {
		if (shouldShowStandardCost) {
			const { isCostEffective, costResult } = getCostInformation(configuration)
			return (
				<div className="cost-comparison-tab--information--cost-explenation">
					<IconFactory
						iconName={isCostEffective ? 'dollar' : 'notCostEffective'}
						className="cost-comparison-tab--cost-explenation--icon"
					/>
					{costResult}
					{renderCostDetailsIcon(true)}
				</div>
			)
		}

		const costResult = getString('COST_RESULT_EXPLENATION').format(
			isCostEffective || effectiveQuantity > 0
				? getString('IS')
				: getString('ISNT')
		)
		const costUpTo = getString('COST_RESULT_EXPLENATION_UP_TO').format(
			Numeral(effectiveQuantity).format('0,0')
		)
		return (
			<div className="cost-comparison-tab--information--cost-explenation">
				<IconFactory
					iconName={
						isCostEffective || effectiveQuantity > 0
							? 'dollar'
							: 'notCostEffective'
					}
					className="cost-comparison-tab--cost-explenation--icon"
				/>
				{costResult}
				{effectiveQuantity > 0 && ` ${costUpTo}`}
				{part.isSpecifiedQuantity && renderCostDetailsIcon()}
			</div>
		)
	}

	const renderOutsourcedCNCManufacturingToggle = () => {
		if (
			[manufacturingMethodTypes.mold, manufacturingMethodTypes.cast].includes(
				manufacturingMethod
			)
		) {
			return <div></div>
		}
		return (
			<LabelFormControl
				label={getString('OUTSOURCED_CNC_MANUFACTURING')}
				control={
					<CastorSwitch
						disabled={costLoading}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							onOutsourcedCNCManufacturingToggleUpdate(
								configuration.id,
								batchSize,
								part,
								manufacturingMethod,
								configuration,
								outsourcedCNCManufacturingToggle,
								partPrintIssues
							)
						}
						checked={outsourcedCNCManufacturingToggle}
						classes={{ root: 'cost-comparison-tab--toggle--root' }}
					/>
				}
			/>
		)
	}

	const renderCostInformation = () => {
		return (
			Feature.isFeatureOn(FeatureComponentId.COST_TAB_INFORMATION) && (
				<div className="cost-comparison-tab--information">
					<div>
						{!isAmOriginalMaterial ? renderChartResultsExplanation() : null}
						<ProductionNumber
							disabled={blockManufacturingMethodOperation}
							onCostQuantityUpdated={onCostQuantityUpdated}
							configuration={configuration}
							part={part}
							manufacturingMethod={manufacturingMethod}
							tempChainBenefits={tempChainBenefits}
							expectedYearsOfDemand={expectedYearsOfDemand}
							chosenOrientation={chosenOrientation}
							batchSize={batchSize}
							onCostQuantityChanged={onCostQuantityChanged}
							lowBatchSize={lowBatchSize}
							shouldShowStandardCost={shouldShowStandardCost}
							costLoading={costLoading}
						/>
						{!shouldShowStandardCost && (
							<AnalysisFooter
								title={getString('FINANCIAL_EDIT_EXPLANATION')}
								onCostEditClicked={onCostEditClicked}
								configId={configuration.id}
							/>
						)}
						{!blockManufacturingMethodOperation && !isAmOriginalMaterial && (
							<TraditionalManufacturingMethod
								configuration={configuration}
								part={part}
								manufacturingMethod={manufacturingMethod}
								onManufacturingMethodChange={onManufacturingMethodChange}
								partPrintIssues={partPrintIssues}
							/>
						)}
					</div>
					<div className="cost-comparison-tab--switches">
						{showEmission && (
							<Co2Comparison
								configuration={configuration}
								part={part}
								shouldShowStandardCost={shouldShowStandardCost}
								isAmOriginalMaterial={isAmOriginalMaterial}
							/>
						)}
						{!!configuration.isSpecifiedQuantity && showSwitchButton && (
							<CostAnalysisTableSwitch
								openSwitch={showAnalysisTable}
								show={
									!customConfiguration &&
									!part?.isDrawing &&
									shouldShowCostTable
								}
								onChange={onAnalysisTableSwitchChange}
								costLoading={costLoading}
								dataQAAttribute="data-qa-analysis-table-switch"
							/>
						)}
						{!projectSettingsScenariosOn &&
							renderOutsourcedCNCManufacturingToggle()}
					</div>
				</div>
			)
		)
	}

	const renderSwitcher = () => {
		return (
			!Feature.isFeatureOn(FeatureComponentId.COST_TAB_INFORMATION) &&
			!isChallenges &&
			showSwitchButton && (
				<div className="cost-comparison-tab--switches">
					<CostAnalysisTableButtons
						openSwitch={showAnalysisTable}
						show={shouldShowCostTable}
						onChange={onAnalysisTableSwitchChange}
					/>
				</div>
			)
		)
	}

	const renderChartData = () => {
		return (
			<>
				{showAnalysisTable ? (
					<CostAnalysisTableAndSwitch
						isCluster={isCluster}
						costLoading={costLoading}
						traditionalMethod={manufacturingMethod}
						solution={solution}
						configuration={configuration}
						show={
							(!part?.isDrawing &&
								shouldShowCostTable &&
								configuration.isSpecifiedQuantity) ||
							isCluster
						}
						showAnalysisTable={showAnalysisTable}
						chainBenefits={tempChainBenefits}
						onAnalysisTableSwitchChange={onAnalysisTableSwitchChange}
						part={part}
						openSwitch={!isCluster}
						showSwitchButton={showSwitchButton}
						showAssemblingCostAlert={showAssemblingCostAlert}
						assemblingParams={assemblingParams}
						showTMSwitch={isWRConfig}
						partPrintIssues={partPrintIssues}
						toggleAssemblingCostModal={toggleAssemblingCostModal}
						onManufacturingMethodChange={onManufacturingMethodChange}
					/>
				) : (
					<></>
				)}
				{!showAnalysisTable && (
					<div
						className={cx('cost-comparison-tab--chart', {
							hide: showAnalysisTable
						})}
					>
						{renderSwitcher()}
						<ChartWithLoader
							chartData={chartData}
							loading={costLoading}
							loaderWrapperClassName="graphic-loader"
							wrapperClassName="financial-form-chart with-border"
							forceUpdate
						/>
						{renderCostInformation()}
					</div>
				)}
			</>
		)
	}

	const renderContent = () => {
		if (!showAnalysisTable && (costFormMode || !chartData)) {
			return (
				<div className="edit-parameters with-scroll">
					{projectSettingsScenariosOn && renderCostCheckBox()}
					{renderForm()}
					<DeviderTSX size="86%" className="cost-details-row-divider" />
					{!projectSettingsScenariosOn && renderCostCheckBox()}
				</div>
			)
		}

		return renderChartData()
	}

	return <div className="cost-comparison-tab">{renderContent()}</div>
}

const mapStateToProps = (
	{
		SolutionAnalysisReducer,
		MainPartAnalysisReducer: {
			plasticFormValues,
			castFormValues,
			metalFormValues,
			partPrintIssues,
			configurations,
			part
		},
		CustomizeSupplyChainReducer: { projectScenarioSettings },
		user: {
			userCurrencySign,
			userCustomizationSettings,
			defaultProjectScenarios,
			defaultProjectScenarioParameters
		}
	}: IReduxStore,
	ownProps: IProps
) => {
	const {
		costFormMode,
		chartData,
		batchSize,
		formParameters,
		showCostFormCancelButton,
		chosenMaterial,
		manufacturingMethod,
		costLoading,
		costBenefit,
		costDetails,
		costAffectivePoint,
		costAffectivePointQuantity,
		printCostQuantity,
		effectivePointIndex,
		supplyChainCostsDetailsTM,
		outsourcedCNCManufacturingToggle,
		chosenOrientation,
		effectiveQuantity,
		isCostEffective,
		customConfiguration,
		tempChainBenefits,
		expectedYearsOfDemand,
		isTooling,
		productLifeCycle,
		selectedProductLifeCycle,
		showAssemblingCostAlert,
		assemblingParams,
		isAmOriginalMaterial,
		defaultLayerThickness
	}: SolutionAnalysisInitialState =
		SolutionAnalysisReducer.states[ownProps.configuration.id] ||
		new SolutionAnalysisInitialState()
	return {
		costFormMode,
		chartData,
		batchSize,
		formParameters,
		plasticFormValues,
		castFormValues,
		metalFormValues,
		showCostFormCancelButton,
		chosenMaterial,
		manufacturingMethod,
		costLoading,
		userCurrencySign,
		costBenefit,
		costDetails,
		effectiveQuantity,
		tempChainBenefits,
		costAffectivePoint,
		costAffectivePointQuantity,
		printCostQuantity,
		effectivePointIndex,
		supplyChainCostsDetailsTM,
		configurations,
		outsourcedCNCManufacturingToggle,
		userCustomizationSettings,
		partPrintIssues,
		expectedYearsOfDemand:
			expectedYearsOfDemand ||
			ownProps.configuration.expectedYearsOfDemand ||
			part?.expectedYearsOfDemand,
		chosenOrientation,
		isCostEffective,
		customConfiguration,
		isTooling,
		projectScenarioSettings,
		defaultProjectScenarios,
		defaultProjectScenarioParameters,
		productLifeCycle,
		selectedProductLifeCycle,
		showAssemblingCostAlert,
		assemblingParams,
		isAmOriginalMaterial,
		defaultLayerThickness
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{ ...SolutionAnalysisActions, ...MainPartAnalysisActions, reset },
		dispatch
	)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CostComparisonTab)
)
