import React, { ChangeEvent } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import cx from 'classnames'

import { useRenderSelectValues } from './CustomizeToleranceCostService'
import { getString } from 'Services/Strings/StringService'

import '../Customize2DDrawingCost/Customize2DDrawingCost.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

interface IProps {
	styles: any
	toleranceData: Record<string, any>
	errorMessage: string
	valueInput: number
	minValue: number
	maxValue: number
	isEdit: boolean
	isApply: boolean
	isDefault: boolean
	editToleranceData: Function
	isDisabled: boolean
}

const CustomizeToleranceCostSelect: React.FC<IProps> = ({
	styles,
	toleranceData,
	valueInput,
	isEdit,
	isDefault,
	editToleranceData,
	isDisabled
}) => {
	const { selectValue, placeholder, setSelectValue } = useRenderSelectValues(
		valueInput,
		isDefault
	)

	const setToMultiply = (value: any) => {
		setSelectValue(value)
		const editToMultiply = { toMultiply: value }
		editToleranceData(toleranceData, null, editToMultiply)
	}

	if (!isEdit) {
		return (
			<div style={styles} className={cx('drawing__field')}>
				<span>{placeholder}</span>
			</div>
		)
	}

	return (
		<SelectTSX
			className="tolerance-picker--select"
			value={selectValue ? 1 : 0}
			onChange={(e: ChangeEvent<HTMLInputElement>) => {
				setToMultiply(e.target.value)
			}}
			disabled={isDisabled}
		>
			<MenuItemTSX key={1} value={1}>
				{getString('YES')}
			</MenuItemTSX>
			<MenuItemTSX key={0} value={0}>
				{getString('NO')}
			</MenuItemTSX>
		</SelectTSX>
	)
}

export default CustomizeToleranceCostSelect
