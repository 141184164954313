import React, { FC, memo } from 'react'

import DetailsPopup from 'Scenes/Components/DetailsPopup'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import StarIcon from 'Scenes/Components/StarIcon/StarIcon'
import {
	ADD_TO_FAVORITES,
	PART_ANALYSIS_REMOVE,
	REMOVE_FROM_FAVORITES
} from 'Services/Strings'

interface Props {
	starred: boolean
	onStarClicked: (e: React.MouseEvent<SVGSVGElement>) => void
	onRemoveClicked: Function
	showRemove?: boolean
}

const PartCardActionIcons: FC<Props> = ({
	starred,
	onStarClicked,
	onRemoveClicked,
	showRemove
}) => {
	return (
		<div className="part-actions">
			{showRemove && (
				<IconFactory
					iconName="remove"
					className="part-actions--remove"
					title={PART_ANALYSIS_REMOVE}
					onClick={onRemoveClicked}
				/>
			)}
			<DetailsPopup
				data={starred ? REMOVE_FROM_FAVORITES : ADD_TO_FAVORITES}
				popperDirection="left"
				isHover={true}
				popperClassName="info-box-wrapper info-box-save-part"
				popperContactClassName="info-box-data"
				inPortal
			>
				<StarIcon
					className={`part-actions--save ${
						starred ? 'part-actions--save--starred' : ''
					}`}
					starred={starred}
					onStarClicked={onStarClicked}
					customColor
				/>
			</DetailsPopup>
		</div>
	)
}

export default memo(PartCardActionIcons)
