import { FC, memo, useCallback, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { ImageList, ImageListItem } from '@material-ui/core'
import cx from 'classnames'

import PartsListView from '../PartsListView/PartsListView'
import { changePartsPage } from '../ProjectAnalysisSuggestionActions'
import { ProjectAnalysisPartsView } from '../ProjectAnalysisSuggestionInterfaces'
import {
	ClientChallengesType,
	ClientNoOpportunitiesType,
	ClientSuggestionType,
	StringChallengesTitle,
	StringNoOpportunities,
	StringOpportunities,
	TabsItem
} from '../ProjectAnalysisSuggestionService'
import ProjectSuggestionPartsFilter from '../ProjectSuggestionPartsFilter'
import PartsHeader from './PartsHeader'
import ProjectPartTile from './ProjectPartTile'
import { styles } from './style'
import CastorPagination from 'Scenes/Components/CastorPagination/CastorPagination'
import { changeSearchPhrase } from 'Scenes/Components/FilterPartsGrid/FilterPartsAction'
import FlexBox from 'Scenes/Components/FlexBox'
import SearchBar from 'Scenes/Components/SearchBar/SearchBar'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import Loader from 'Scenes/Loader/Loader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ProjectClusterStatus } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as NoOpportunitySvg } from 'assets/img/svg/no-opportunity.svg'

interface IProps {
	setShowPDFModal: (value: boolean) => void
	showPDFModal: boolean
}

const ProjectMultipleParts: FC<IProps> = ({ setShowPDFModal }) => {
	const {
		allPartsInapplicable,
		allPartsNotCostEffective,
		weightReducedParts,
		isSinglePartProject,
		amountOfLockedParts,
		project,
		showExportButton,
		is2dProject,
		totalPartsCount,
		searchPhrase,
		selectedFilterPart,
		showStandardCostFinishIcon,
		projectId,
		selectedFilterValues,
		partsView,
		leadingConfigurationData,
		partsLoading,
		paginationData,
		refetchParts,
		resultType,
		selectedTab,
		suggestions,
		projectClusterStatus
	} = useSelector((state: RootStateOrAny) => state.ProjectSuggestionReducer)
	const textKey = useMemo(() => {
		switch (selectedTab) {
			case TabsItem.opportunities:
				return StringOpportunities[resultType as ClientSuggestionType]
			case TabsItem.challenges:
				return StringChallengesTitle[resultType as ClientChallengesType]
			default:
				return StringNoOpportunities[resultType as ClientNoOpportunitiesType]
		}
	}, [resultType, selectedTab])

	const { roles, contactUsEmail } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const dispatch = useDispatch()

	const singlePartProjectViewIsOn = Feature.isFeatureOn(
		FeatureComponentId.SINGLE_PART_PROJECT_VIEW
	)

	const onExportPDFClick = useCallback(() => {
		setShowPDFModal(true)
	}, [])

	const isPcUpdating = useMemo(
		() =>
			selectedTab === TabsItem.opportunities &&
			resultType === ClientSuggestionType.amPC &&
			(project?.clusterStatus === ProjectClusterStatus.awaitingAnalysis ||
				projectClusterStatus === ProjectClusterStatus.awaitingAnalysis),
		[selectedTab, resultType, project, projectClusterStatus]
	)

	const renderParts = (partsView: ProjectAnalysisPartsView) => {
		if (isPcUpdating) {
			return (
				<ImageListItem cols={3} style={styles.partsRowsAwaiting}>
					<FlexBox
						style={styles.partsRowsAwaitingMessage}
						alignItems="center"
						justifyContent="center"
						width="100%"
						height="100%"
					>
						<IconFactory
							iconName={ProjectClusterStatus.awaitingAnalysis}
							className="cluster-section--subheader--icon"
						/>
						<div className="text">
							{getString('COMBINE_MULTIPLE_INTO_ONE_REQ_BODY')}
						</div>
					</FlexBox>
				</ImageListItem>
			)
		}

		if (partsLoading)
			return (
				<div style={styles.partsRows}>
					<Loader
						load={partsLoading}
						wrapperClassName="loading-wrapper suggestion-multiple-parts-loading"
					/>
				</div>
			)

		if (allPartsInapplicable && is2dProject) {
			return (
				<ImageListItem cols={3}>
					<p className="all-inapplicable">
						{getString('ALL_PARTS_INAPPLICABLE')}
					</p>
				</ImageListItem>
			)
		}
		if (partsView === ProjectAnalysisPartsView.list) {
			return (
				<ImageListItem
					cols={3}
					style={styles.partsRows}
					key="listView"
					className={cx('project-part-list')}
				>
					<PartsListView
						partsLoading={partsLoading}
						resultType={resultType}
						selectedTab={selectedTab}
						suggestions={suggestions}
						leadingConfigurationData={leadingConfigurationData}
						lockedParts={!!project?.lockedParts}
						amountOfLockedParts={amountOfLockedParts}
						roles={roles}
						contactUsEmail={contactUsEmail}
						isBundle={false}
						textKey={textKey}
					/>
				</ImageListItem>
			)
		}

		return (
			<ImageList
				style={{
					...styles.partsHeader,
					flexDirection: 'row',
					zIndex: 99
				}}
				cols={3}
			>
				{suggestions?.length === 0 && !partsLoading ? (
					<ImageListItem cols={3} style={styles.partsRows}>
						<div className="suggestion-parts-list--wrapper empty">
							<FlexBox
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								width="100%"
								height="100%"
								minHeight="386px"
							>
								<NoOpportunitySvg />
								<div className="text">
									{getString('NO_OPPORTUNITIES_FOUND').format(
										getString(textKey),
										resultType === ClientSuggestionType.amPC ||
											resultType === ClientSuggestionType.amWR
											? ''
											: getString('PARTS_OPPORTUNITIES')
									)}
								</div>
							</FlexBox>
						</div>
					</ImageListItem>
				) : (
					<></>
				)}

				{suggestions?.map((suggestion: Record<string, any>) => (
					<ImageListItem
						key={suggestion.id}
						style={styles.partTile}
						id="grid-list-tile"
						cols={1}
						classes={{ item: 'grid-list--tile' }}
					>
						<ProjectPartTile
							suggestion={suggestion}
							isBundle={false}
							starred={suggestion.part?.starred || suggestion.cluster?.starred}
						/>
					</ImageListItem>
				))}
			</ImageList>
		)
	}

	if (isSinglePartProject && singlePartProjectViewIsOn) {
		return <></>
	}
	return (
		<>
			<ImageList
				style={{
					...styles.partsGrid,
					flexDirection:
						partsView === ProjectAnalysisPartsView.list && allPartsInapplicable
							? 'column'
							: 'row'
				}}
				cols={3}
			>
				<ImageListItem
					id="grid-list"
					key="Subheader"
					cols={3}
					style={styles.partsHeader}
				>
					<FlexBox flexDirection="column" overflow="hidden">
						<SearchBar
							value={searchPhrase}
							onChange={(value: string) => dispatch(changeSearchPhrase(value))}
							className="suggestion-parts-header--search-bar"
						/>
						<ProjectSuggestionPartsFilter />
						<PartsHeader
							suggestions={suggestions}
							selectedTab={selectedTab}
							resultType={resultType}
							selectedFilterValues={selectedFilterValues}
							showStandardCostFinishIcon={showStandardCostFinishIcon}
							showExportButton={showExportButton}
							project={project}
							weightReducedParts={weightReducedParts}
							is2dProject={is2dProject}
							allPartsCount={totalPartsCount}
							allPartsInapplicable={allPartsInapplicable}
							allPartsNotCostEffective={allPartsNotCostEffective}
							searchPhrase={searchPhrase}
							projectId={projectId}
							selectedFilterPart={selectedFilterPart}
							partsView={partsView}
							totalPartsCount={paginationData.totalPartsCount}
							page={paginationData.page}
							limit={paginationData.limit}
							onExportPDFClick={onExportPDFClick}
							refetchParts={refetchParts}
						/>
					</FlexBox>
				</ImageListItem>
				{renderParts(partsView)}
				<ImageListItem
					id="grid-list"
					key="Pagination"
					cols={3}
					style={styles.partsPagination}
				>
					<CastorPagination
						showPagination={!isPcUpdating && paginationData.totalPagesCount > 1}
						pageNumber={paginationData.page}
						isLastPage={!paginationData.enableNext}
						total={paginationData.totalPartsCount}
						limit={paginationData.limit}
						className={{ pagination: 'parts-pagination' }}
						showingFrom={!isPcUpdating && paginationData.showingFrom}
						showingTo={!isPcUpdating && paginationData.showingTo}
						onNextClick={() =>
							dispatch(changePartsPage(paginationData.page + 1))
						}
						onPrevClick={() =>
							dispatch(changePartsPage(paginationData.page - 1))
						}
						onPageClick={(page: number) => dispatch(changePartsPage(page))}
					/>
				</ImageListItem>
			</ImageList>
		</>
	)
}

export default memo(ProjectMultipleParts)
