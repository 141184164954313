export const SAVE_LIST_SUGGESTION_CHANGE = 'SAVE_LIST_SUGGESTION_CHANGE'
export const SAVE_LIST_SUGGESTION_ERROR = 'SAVE_LIST_SUGGESTION_ERROR'
export const SAVE_COST_PERCENTAGE_SUGGESTION_CHANGE =
	'SAVE_COST_PERCENTAGE_SUGGESTION_CHANGE'
export const SAVE_COST_DOLLAR_SUGGESTION_CHANGE =
	'SAVE_COST_DOLLAR_SUGGESTION_CHANGE'
export const SAVE_COST_SUGGESTION_ERROR = 'SAVE_COST_SUGGESTION_ERROR'
export const SAVE_COST_DOLLAR_SUGGESTION_LOADING =
	'SAVE_COST_DOLLAR_SUGGESTION_LOADING'

export const SET_SELECTED_COST_SUGGESTION = 'SET_SELECTED_COST_SUGGESTION'

export const FETCH_LIST_OF_SUGGESTION = 'FETCH_LIST_OF_SUGGESTION'
export const FETCH_LIST_OF_SUGGESTION_CHANGE = 'FETCH_LIST_OF_SUGGESTION_CHANGE'
export const FETCH_LIST_OF_SUGGESTION_CHANGE_AM = 'FETCH_LIST_OF_SUGGESTION_CHANGE_AM'
export const FETCH_LIST_OF_SUGGESTION_SUCCESS =
	'FETCH_LIST_OF_SUGGESTION_SUCCESS'

export const SAVE_LIST_OF_SUGGESTION_LOADING = 'SAVE_LIST_OF_SUGGESTION_LOADING'
export const SAVE_LIST_OF_SUGGESTION_SUCCESS = 'SAVE_LIST_OF_SUGGESTION_SUCCESS'

export const CUSTOMIZE_COST_SAVING_THRESHOLD = 'CUSTOMIZE_COST_SAVING_THRESHOLD'
export const CUSTOMIZE_DEFAULT_SUGGESTIONS = 'CUSTOMIZE_DEFAULT_SUGGESTIONS'
