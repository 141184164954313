import { FC, memo, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ProjectAnalysisPartsView } from '../ProjectAnalysisSuggestionInterfaces'
import {
	ClientChallengesType,
	ClientNoOpportunitiesType,
	ClientSuggestionType,
	StringChallengesTitle,
	StringNoOpportunities,
	StringOpportunities,
	TabsItem
} from '../ProjectAnalysisSuggestionService'
import { styles } from './style'
import Flexbox from 'Scenes/Components/FlexBox'
import {
	exportPartsToExcel,
	onPartPropertiesClick,
	onRemoveProjectClick,
	onStandardCostClick
} from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionActions'
import ProjectAnalysisActivities from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionActivities/ProjectAnalysisActivities'
import SwitchView from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionMultipleParts/SwitchView/SwitchView'
import { Part } from 'Services/models/IPart'
import { Project } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'

interface PartsHeaderProps {
	showStandardCostFinishIcon: boolean
	showExportButton: boolean
	project: Project
	weightReducedParts: Part[]
	is2dProject: boolean
	bundleId?: string
	resultType?: string
	selectedTab?: TabsItem
	allPartsCount: number
	standalonePartsCount?: number
	allPartsInapplicable: boolean
	allPartsNotCostEffective: boolean
	searchPhrase: string
	projectId: string
	selectedFilterPart: string
	selectedFilterValues: string[]
	assemblyProjects?: Project[]
	partsView: ProjectAnalysisPartsView
	totalPartsCount: number
	page: number
	limit: number
	onExportPDFClick: () => void
	refetchParts: boolean
	suggestions: Record<any, any>
}

const PartsHeader: FC<PartsHeaderProps> = ({
	showStandardCostFinishIcon,
	showExportButton,
	project,
	is2dProject,
	allPartsInapplicable,
	allPartsNotCostEffective,
	projectId,
	selectedFilterPart,
	bundleId,
	partsView,
	totalPartsCount,
	onExportPDFClick,
	resultType = 1,
	selectedTab,
	suggestions
}) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const partsHeaderRef = useRef(null)

	const textKey = useMemo(() => {
		switch (selectedTab) {
			case TabsItem.opportunities:
				return StringOpportunities[resultType as ClientSuggestionType]
			case TabsItem.others:
				return StringNoOpportunities[resultType as ClientNoOpportunitiesType]
			case TabsItem.challenges:
				return StringChallengesTitle[resultType as ClientChallengesType]
			default:
				return ''
		}
	}, [selectedTab, resultType])

	return (
		<Flexbox
			width="100%"
			flexDirection="column"
			alignItems="flex-start"
			innerRef={partsHeaderRef}
		>
			<div style={styles.filterDiv}>
				<h4 className="suggestion-parts-header--title">{getString(textKey)}</h4>
				<div style={styles.filterDiv}>
					<div style={styles.flexDiv}>
						<SwitchView
							suggestions={suggestions}
							partsView={partsView}
							allPartsInapplicable={allPartsInapplicable}
						/>
					</div>
					<div style={styles.flexDiv}>
						<ProjectAnalysisActivities
							isAssembly
							is2dProject={is2dProject}
							allPartsInapplicable={allPartsInapplicable}
							allPartsNotCostEffective={allPartsNotCostEffective}
							showStandardCostFinishIcon={showStandardCostFinishIcon}
							showExportButton={showExportButton}
							onStandardCostClick={() => dispatch(onStandardCostClick())}
							onRemoveProjectClick={() =>
								dispatch(onRemoveProjectClick(project, history))
							}
							onExportClick={() =>
								dispatch(
									exportPartsToExcel(projectId, selectedFilterPart, bundleId)
								)
							}
							onPartPropertiesClick={() =>
								dispatch(onPartPropertiesClick(project.id, undefined, true))
							}
							onExportPDFClick={onExportPDFClick}
						/>
					</div>
				</div>
			</div>
		</Flexbox>
	)
}

export default memo(PartsHeader)
