import React, { FC, memo, useCallback } from 'react'

import Close from '@material-ui/icons/Close'
import cx from 'classnames'

import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import PdfViewer from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionPartDetails/PdfViewer/PdfViewer'
import { FormatType } from 'Services/models/IPart'

type IProps = {
	onImageClose: () => void
	imageURL?: string
	fileUrl?: string
	imageAlt?: string
	toleranceValueIdSelected?: string
}

const PDFViewer: FC<IProps> = ({
	imageURL,
	fileUrl,
	imageAlt,
	toleranceValueIdSelected,
	onImageClose
}) => {
	const onCloseModal = useCallback(() => {}, [])
	return (
		<div className="specific-tolerances--sticky-container">
			<div
				className={cx('specific-tolerances--pdf-viewer', {
					'img-view': toleranceValueIdSelected
				})}
			>
				<div
					className="specific-tolerances--tolerance-image--wrapper"
					id="tolerance-image-wrapper"
				>
					<div className="specific-tolerances--tolerance-image--inner-wrapper">
						<PartImageWithFallback
							src={imageURL}
							alt={imageAlt}
							formatType={FormatType.pdf}
							className="specific-tolerances--tolerance-image"
						/>
						<Close
							className="specific-tolerances--tolerance-image--close-btn"
							onClick={onImageClose}
						/>
					</div>
				</div>
				<PdfViewer
					partImageSrc={''}
					fileUrl={fileUrl}
					showModal={false}
					onCloseModal={onCloseModal}
					inModal={false}
				/>
			</div>
		</div>
	)
}

export default memo(PDFViewer)
