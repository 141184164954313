import React, { FC, memo } from 'react'
import { connect, RootStateOrAny, useSelector } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'

import { MenuItem } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import { capitalize, cloneDeep, isEmpty, lowerCase, map } from 'lodash'

import { materialTypes } from '../../../Services/Constants'
import {
	MATERIAL_TABLE_INFO,
	MATERIAL_TABLE_TEXTS,
	PRINTER_COMPANY_NOT_EXIST,
	PRINTER_MATERIAL_TABLE_TEXTS,
	SUBMIT,
	UNDO_CHANGES
} from '../../../Services/Strings'
import { getString } from '../../../Services/Strings/StringService'
import { renderCheckboxWithoutLabel } from '../../admin/AdminHome/AdminFields/AdminCheckbox'
import {
	detectNonNumberValue,
	renderNumberField,
	replaceNonNumber
} from '../../admin/AdminHome/AdminFields/AdminNumberField'
import { renderSelectFieldTouched } from '../../admin/AdminHome/AdminFields/AdminSelectField'
import { renderTextField } from '../../admin/AdminHome/AdminFields/AdminTextField'
import { Button, Muted } from '../thirdParty/CreativeTim/components'
import {
	additionalPostProcesses,
	alwaysEnabledFields,
	deviationPrinterMaterials,
	MIN_VALUE,
	NUMBER,
	printerMaterialTypes,
	specialMaterial,
	standardPrinterMaterials
} from './constants'
import {
	getExistingValue,
	materialTypeChange,
	validate
} from './PrinterMaterialFormService'
import { ICategoryAverageCO2 } from './PrinterMaterialFormTypes'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { MaterialType } from 'Services/models/IMaterial'
import { IOrganization } from 'Services/models/IOrganization'
import { PrinterMaterial } from 'Services/models/IPrinterMaterial'
import { IPrinter } from 'Services/models/IPrintersTypes'
import {
	IPrinterTechnology,
	IPrintingTechnology
} from 'Services/models/IPrintingTechnology'
import { TemperatureUnit, UnitSystem } from 'Services/UnitsConversionService'

import '../../admin/AdminHome/adminHome.scss'

type IProps = {
	handleSubmit: (onSubmit: Function) => React.FormEventHandler<HTMLFormElement>
	pristine: boolean
	reset: () => void
	submitting: boolean
	onSubmit: (data: Record<string, any>) => void
	printingTechnologies: IPrintingTechnology[]
	valid: boolean
	change: (name: string, value: string | null) => void
	addingNewItem: boolean
	selectedType: MaterialType
	selectedPrinterTechnology: string
	userCurrencySign: string
	initialized: boolean
	allPrintersCompanies: Record<string, any>
	selectedPrinterName: string
	selectedPrinterCompany: string
	selectedCategory: string
	allCategories: PrinterMaterial[]
	allSubCategories: PrinterMaterial[]
	selectedSubCategory: string
	optionalPostProcessAvailability: Record<string, any>
	selectedId: number
	isAdminForm: boolean
	categoriesAverageCO2: ICategoryAverageCO2[]
	userUnitSystem: UnitSystem
}

const makeCategoryName = (category: string) => {
	return category
		.split(' ')
		.map(word => capitalize(word))
		.join(' ')
}

const renderPrintingTechSelectedItems = (
	printingTechnologies: IPrinterTechnology[],
	change: (name: string, value: string) => void,
	selectedPrinterTechnology: string,
	initialized: boolean
) => {
	return (
		<Field
			disabled
			name="printerTechnologyName"
			component={renderSelectFieldTouched}
			label={PRINTER_MATERIAL_TABLE_TEXTS.PRINTER_TECHNOLOGY}
			custom={{
				value: selectedPrinterTechnology || '',
				onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
					change('printerTechnologyName', e.target.value)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.TECHNOLOGY}</MenuItem>
			{printingTechnologies.map((printingTechnology: IPrinterTechnology) => {
				return (
					<MenuItem
						key={printingTechnology.name}
						value={printingTechnology.name}
					>
						{printingTechnology.userReadableName}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderCategoryAndSubCategory = (
	allCategories: PrinterMaterial[],
	change: (name: string, value: string) => void,
	selectedCategory: string,
	selectedType: string,
	initialized: boolean,
	allSubCategories: PrinterMaterial[],
	selectedSubCategory: string,
	disabled: boolean
) => {
	const selectedCategories =
		selectedType && selectedType !== 'weak'
			? allCategories.filter(
					category =>
						category?.type.toLocaleLowerCase() ===
						selectedType.toLocaleLowerCase()
			  )
			: allCategories

	// filter out the plastic/Plastic, flexible plastics/Flexible Plastics, copper alloys/Copper Alloys etc.
	const filteredCategories = selectedCategories.filter(
		(categoryToCompare, pos, self) =>
			self.findIndex(
				(categoryToCheck: PrinterMaterial) =>
					categoryToCheck.category.toLocaleLowerCase() ===
					categoryToCompare.category.toLocaleLowerCase()
			) === pos
	)

	const filteredSubCategories = (
		selectedCategory
			? allSubCategories.filter(
					subCategory =>
						subCategory.category.toLocaleLowerCase() ===
						selectedCategory.toLocaleLowerCase()
			  )
			: allSubCategories
	).sort((a, b) => (a.subCategory > b.subCategory ? 1 : -1))

	return (
		<>
			<Field
				name="category"
				component={renderSelectFieldTouched}
				label={MATERIAL_TABLE_TEXTS.CATEGORY}
				custom={{
					value: selectedCategory?.toLowerCase() || '',
					onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
						const selectedValue = e.target.value
						const originalValue =
							filteredCategories.find(
								category =>
									category.category.toLowerCase() ===
									selectedValue.toLowerCase()
							)?.category || selectedValue
						change('category', originalValue)
						change('subCategory', '')
					}
				}}
				initialized={initialized}
				disabled={disabled}
			>
				{filteredCategories?.map(category => {
					return (
						<MenuItem
							key={category.category}
							value={category.category.toLowerCase()}
						>
							{makeCategoryName(category.category)}
						</MenuItem>
					)
				})}
			</Field>
			<Field
				name="subCategory"
				component={renderSelectFieldTouched}
				label={PRINTER_MATERIAL_TABLE_TEXTS.SUB_CATEGORY}
				custom={{
					value: selectedSubCategory || '',
					onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
						change('subCategory', e.target.value)
				}}
				initialized={initialized}
				disabled={disabled}
			>
				{filteredSubCategories?.map(subCategory => {
					return (
						<MenuItem
							key={subCategory.subCategory}
							value={subCategory.subCategory}
						>
							{subCategory.subCategory}
						</MenuItem>
					)
				})}
			</Field>
		</>
	)
}

const renderPrintersSelectedItems = (
	allPrintersCompanies: Record<string, any>,
	change: (name: string, value: string | null) => void,
	selectedPrinterName: string,
	selectedPrinterCompany: string,
	printingTechnologies: IPrintingTechnology[],
	initialized: boolean,
	allOrganizations: IOrganization[] = []
) => {
	const selectedCompanyKey = selectedPrinterCompany || ''
	const selectedPrinters = allPrintersCompanies[selectedCompanyKey]
	const isPrinterCompanyExist = selectedCompanyKey && selectedPrinterName
	const isNotExistOrEmpty =
		!isPrinterCompanyExist && !isEmpty(selectedPrinterName)

	return (
		<>
			<div className={'values-with-deviation'}>
				<Field
					errorMessage={isNotExistOrEmpty ? PRINTER_COMPANY_NOT_EXIST : ''}
					name="printerCompany"
					component={renderSelectFieldTouched}
					label={PRINTER_MATERIAL_TABLE_TEXTS.PRINTER_COMPANY}
					custom={{
						value: selectedCompanyKey,
						onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
							change('printerCompany', e.target.value)
							change('printers', null)
							change('printerTechnologyName', null)
							change('type', null)
							change('category', '')
						}
					}}
					initialized={initialized}
				>
					{Object.keys(allPrintersCompanies)
						?.sort((a, b) => a.localeCompare(b))
						.map(companiesName => {
							return (
								<MenuItem key={companiesName} value={companiesName}>
									{companiesName}
								</MenuItem>
							)
						})}
				</Field>
				<Field
					disabled={!selectedCompanyKey}
					name="printers"
					placeholder={selectedPrinterName}
					component={renderSelectFieldTouched}
					label={PRINTER_MATERIAL_TABLE_TEXTS.PRINTER_NAME}
					custom={{
						value: selectedPrinterName || '',
						onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
							const selectedPrinter =
								selectedPrinters?.find(
									(printer: IPrinter) => printer.id === e.target.value
								) || {}
							const selectedTechnology =
								printingTechnologies?.find(
									technology =>
										technology.name === selectedPrinter.printerTechnologyName
								) || ({} as IPrintingTechnology)

							change('printers', selectedPrinter.id)
							change('printerTechnologyName', selectedTechnology.name || null)
							change('type', selectedTechnology.type || null)
							change('category', '')
						}
					}}
					initialized={initialized}
				>
					{isNotExistOrEmpty && (
						<MenuItem value={selectedPrinterName} disabled={true}>
							{selectedPrinterName}
						</MenuItem>
					)}
					{selectedPrinters?.map((printer: IPrinter) => {
						const getOrganizationName = (
							allOrganizations: IOrganization[],
							organizationId?: number
						) => {
							const organization =
								organizationId && allOrganizations.length > 0
									? allOrganizations.find(
											(organization: IOrganization) =>
												organization.id === organizationId
									  )
									: undefined
							return organization?.name
								? ` (${getString('SITE')} ${organization?.name})`
								: ''
						}
						return (
							<MenuItem key={printer.id} value={printer.id}>
								{printer.name}
								{getOrganizationName(allOrganizations, printer?.organizationId)}
							</MenuItem>
						)
					})}
				</Field>
			</div>
			<Muted className={'note-muted'}>
				{getString('PRINTER_MATERIAL_PRINTER_NOTE')}
			</Muted>
		</>
	)
}

const renderTypeSelectedItems = (
	selectedType: MaterialType,
	change: (name: string, value: string | null) => void,
	initialized: boolean
) => {
	return (
		<Field
			disabled
			name="type"
			component={renderSelectFieldTouched}
			label={MATERIAL_TABLE_TEXTS.TYPE}
			custom={{
				value: selectedType || '',
				onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
					materialTypeChange(e, change)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.TYPE}</MenuItem>
			{printerMaterialTypes.map(printerMaterialType => {
				return (
					<MenuItem
						key={printerMaterialType}
						value={lowerCase(printerMaterialType)}
					>
						{capitalize(printerMaterialType)}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const xyzItem = (
	item: { val: number; stdev: number },
	labelVal: string,
	onChange: (value: string, label: string, isStd?: boolean) => void,
	disabled: boolean
) => {
	return (
		<div className={'block-with-deviation__item'} key={labelVal + labelVal}>
			<div className="value-label">{labelVal}:</div>
			<TextField
				value={item?.val}
				onChange={e => {
					const value = replaceNonNumber(e.target.value)
					onChange(value, labelVal)
				}}
				onKeyDown={e => detectNonNumberValue(e) && e.preventDefault()}
				onWheel={(event: any) => event.target.blur()}
				InputProps={{ inputProps: { min: MIN_VALUE } }}
				disabled={disabled}
				data-qa="data-qa-value-input"
			/>
			±
			<TextField
				value={item?.stdev}
				onChange={e => {
					const value = replaceNonNumber(e.target.value)
					onChange(value, labelVal, true)
				}}
				onKeyDown={e => detectNonNumberValue(e) && e.preventDefault()}
				onWheel={(event: any) => event.target.blur()}
				InputProps={{ inputProps: { min: MIN_VALUE } }}
				disabled={disabled}
				data-qa="data-qa-deviation-input"
			/>
		</div>
	)
}

const renderXYZField = ({
	input,
	label,
	meta: { error },
	initialized,
	disabled
}: {
	input: Record<string, any>
	label: string
	meta: { error: string }
	initialized: boolean
	disabled: boolean
}) => {
	let modifyInput = cloneDeep(input.value)

	const updateDeviationValue = (
		newValue: string,
		labelValue: string,
		isStd = false
	) => {
		const changedValue = {
			[labelValue]: {
				stdev: isStd ? newValue : modifyInput[labelValue]?.stdev,
				val: !isStd ? newValue : modifyInput[labelValue]?.val
			}
		}
		modifyInput = {
			...modifyInput,
			...changedValue
		}

		input.onChange(modifyInput)
	}

	return (
		<div className="block-with-deviation">
			<div className="label">
				<TextField
					name="name"
					error={initialized && !!error}
					label={label}
					helperText={initialized && error}
					disabled={true}
				/>
			</div>
			<div className="block-with-deviation__flex">
				{xyzItem(modifyInput.X, 'X', updateDeviationValue, disabled)}
				{xyzItem(modifyInput.Y, 'Y', updateDeviationValue, disabled)}
				{xyzItem(modifyInput.Z, 'Z', updateDeviationValue, disabled)}
			</div>
		</div>
	)
}

const renderValuesWithDeviation = (
	standard: Record<string, any>,
	initialized: boolean,
	disabled: boolean,
	temperatureUnit: string
) => {
	return (
		<div className={'values-with-deviation'}>
			<Field
				className="admin-form-field"
				name={standard.name}
				component={renderNumberField}
				label={
					standard.unitSystem
						? standard.label.format(temperatureUnit)
						: standard.label
				}
				initialized={initialized}
				disabled={disabled}
			/>
			±
			<Field
				className="admin-form-field"
				name={standard.name + 'Std'}
				component={renderNumberField}
				label={'Deviation'}
				initialized={initialized}
				disabled={disabled}
			/>
		</div>
	)
}

const renderPostProcesses = (
	optionalPostProcessAvailability: Record<string, any>,
	selectedPrinterTechnology: string,
	selectedType: string,
	selectedId: number,
	disabled: boolean
) => {
	if (selectedType) {
		return (
			<div className="checkboxes-block">
				<p>{additionalPostProcesses.label}</p>
				<div>
					{additionalPostProcesses?.checkBoxes.map(checkBox => {
						const isOn = getExistingValue(
							selectedId,
							checkBox.id,
							optionalPostProcessAvailability,
							selectedPrinterTechnology
						)
						if (selectedType !== checkBox?.typeNameShow) {
							return <div />
						}
						return (
							<FormControlLabel
								control={
									<Field
										name={checkBox?.name}
										component={renderCheckboxWithoutLabel}
										defaultChecked={isOn || checkBox?.defaultValue}
										disabled={disabled}
									/>
								}
								label={checkBox?.label}
							/>
						)
					})}
				</div>
			</div>
		)
	}
}

const renderSpecialMaterial = (disabled: boolean) => {
	return (
		<div className="checkboxes-block">
			<p>{specialMaterial.label}</p>
			<div className="special-materials">
				{specialMaterial?.checkBoxes.map(checkBox => {
					return (
						<FormControlLabel
							data-qa={`data-qa-material-filter-${checkBox?.label}`}
							control={
								<Field
									name={checkBox?.name}
									component={renderCheckboxWithoutLabel}
									defaultChecked={checkBox?.defaultChecked}
									disabled={disabled}
								/>
							}
							label={checkBox?.label}
						/>
					)
				})}
			</div>
		</div>
	)
}

const renderCO2Input = (
	standard: Record<string, any>,
	initialized: boolean,
	selectedCategory: string,
	categoriesAverageCO2: ICategoryAverageCO2[],
	disabled: boolean
) => {
	const averageByCategory =
		categoriesAverageCO2.find(
			category => category.category === selectedCategory
		)?.averageCO2 || 0

	return (
		<Field
			className="admin-form-field"
			name={standard?.name}
			component={
				standard?.type === NUMBER ? renderNumberField : renderTextField
			}
			label={standard?.label}
			initialized={initialized}
			multiline={standard?.multiline}
			disabled={disabled}
			iIcon={`${getString('CO2_PER_KG_MATERIAL_INFO_STRING')}${
				selectedCategory && averageByCategory
					? getString('CO2_PER_KG_MATERIAL_AVERAGE_PER_CATEGORY').format(
							selectedCategory,
							averageByCategory.toFixed(2)
					  )
					: ''
			}`}
			{...standard}
		/>
	)
}

const PrinterMaterialForm: FC<IProps> = props => {
	const {
		handleSubmit,
		pristine,
		reset,
		submitting,
		onSubmit,
		printingTechnologies,
		valid,
		change,
		addingNewItem,
		selectedType,
		selectedPrinterTechnology,
		userCurrencySign,
		initialized,
		allPrintersCompanies,
		selectedPrinterName,
		selectedPrinterCompany,
		selectedCategory,
		allCategories,
		allSubCategories,
		selectedSubCategory,
		optionalPostProcessAvailability,
		selectedId,
		isAdminForm,
		categoriesAverageCO2,
		userUnitSystem
	} = props

	const { roles, allOrganizations } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const customizeOrganizations = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
	)
	const isSuperAdmin = roles.includes(UserRole.SUPER_ADMIN)
	const temperatureUnit =
		userUnitSystem === UnitSystem.metric ? TemperatureUnit.C : TemperatureUnit.F

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="admin-form">
			{map(standardPrinterMaterials, standard => {
				if (standard?.isTypeSelectedItems) {
					return (
						<div key={standard.name}>
							{renderTypeSelectedItems(selectedType, change, initialized)}
						</div>
					)
				}

				if (standard?.isTechSelectedItems) {
					return (
						<div key={standard.name}>
							{renderPrintingTechSelectedItems(
								printingTechnologies,
								change,
								selectedPrinterTechnology,
								initialized
							)}
						</div>
					)
				}

				if (standard?.isCategoryAndSubCategory) {
					return (
						<div key={standard.name}>
							{renderCategoryAndSubCategory(
								allCategories,
								change,
								selectedCategory,
								selectedType,
								initialized,
								allSubCategories,
								selectedSubCategory,
								!selectedPrinterName
							)}
						</div>
					)
				}

				if (standard?.isPrintersSelectedItems) {
					return (
						<div key={standard.name}>
							{renderPrintersSelectedItems(
								allPrintersCompanies,
								change,
								selectedPrinterName,
								selectedPrinterCompany,
								printingTechnologies,
								initialized,
								customizeOrganizations && isSuperAdmin ? allOrganizations : []
							)}
						</div>
					)
				}

				if (
					(standard?.isMetalOnly &&
						(!selectedType || selectedType !== materialTypes.metal)) ||
					(standard?.isPlasticOnly &&
						(!selectedType || selectedType !== materialTypes.plastic))
				) {
					return null
				}

				if (standard?.hasStandardDeviation) {
					return (
						<div key={standard.name}>
							{renderValuesWithDeviation(
								standard,
								initialized,
								!selectedPrinterName,
								temperatureUnit
							)}
						</div>
					)
				}

				if (standard?.isCO2Input) {
					return (
						<div key={standard.name}>
							{renderCO2Input(
								standard,
								initialized,
								selectedCategory,
								categoriesAverageCO2,
								!selectedPrinterName &&
									!alwaysEnabledFields.includes(standard.name)
							)}
						</div>
					)
				}

				return (
					<div key={standard.name}>
						<Field
							className="admin-form-field"
							name={standard?.name}
							component={
								standard?.type === NUMBER ? renderNumberField : renderTextField
							}
							label={
								standard?.withSign
									? standard?.label.format(userCurrencySign)
									: standard?.label
							}
							initialized={initialized}
							multiline={standard?.multiline}
							disabled={
								!selectedPrinterName &&
								!alwaysEnabledFields.includes(standard.name)
							}
							{...(standard as any)}
						/>
					</div>
				)
			})}

			<div className="deviation">
				{deviationPrinterMaterials.map(({ name, label }) => {
					return (
						<div key={label}>
							<Field
								className="admin-form-field"
								name={name}
								component={renderXYZField}
								label={label}
								initialized={initialized}
								disabled={!selectedPrinterName}
							/>
						</div>
					)
				})}
			</div>

			{renderSpecialMaterial(!selectedPrinterName)}
			{renderPostProcesses(
				optionalPostProcessAvailability,
				selectedPrinterTechnology,
				selectedType,
				selectedId,
				!selectedPrinterName
			)}

			{isAdminForm && (
				<div className="flex-block start">
					<FormControlLabel
						disabled
						control={
							<Field
								name="heatTreatment"
								component={renderCheckboxWithoutLabel}
								defaultChecked
								disabled
							/>
						}
						label={MATERIAL_TABLE_TEXTS.HEAT_TREATED}
					/>
					<div className="flex-block">
						<Field
							className="admin-form-field"
							name="coupleId"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.COUPLE_ID}
							initialized={initialized}
							disabled
							iIcon={MATERIAL_TABLE_INFO.COUPLE_ID}
						/>
					</div>
				</div>
			)}
			<div>
				<Button
					data-qa="data-qa-submit-setting-btn"
					size="sm"
					color="primary"
					type="submit"
					disabled={!valid || addingNewItem}
				>
					{SUBMIT}
				</Button>
				<Button
					data-qa="data-qa-undo-setting-btn"
					size="sm"
					color="primary"
					type="button"
					disabled={pristine || submitting}
					onClick={reset}
				>
					{UNDO_CHANGES}
				</Button>
			</div>
		</form>
	)
}

export default memo(
	compose(
		connect((state: any, props: any) => {
			return {
				form: props.formName,
				userUnitSystem: state.user.userUnitSystem
			}
		}),
		reduxForm<any, any>({
			validate,
			enableReinitialize: true
		})
	)(PrinterMaterialForm)
)
