import { FC, memo } from 'react'

import DataUsage from '@material-ui/icons/DataUsage'

import FlexBox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	isLinear?: boolean
}

const EmptyChart: FC<IProps> = ({ isLinear }) => {
	return (
		<FlexBox
			height="100%"
			width="100%"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			className="suggestion empty-chart-block"
		>
			{isLinear ? (
				<div className="linear-chart">
					<div className="linear-chart-item grey"></div>
				</div>
			) : (
				<DataUsage className="empty-chart" />
			)}
			<p>
				<b>{getString('ADMIN_TABLE_NO_DATA_FOUND')}</b>
			</p>
		</FlexBox>
	)
}

export default memo(EmptyChart)
