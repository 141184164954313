import React from 'react'

import { FormControlLabel, RadioGroup } from '@material-ui/core'

import { SelectedRadioType } from './NewOrCopyConstants'
import CastorRadio from 'Scenes/Components/CastorRadio'

interface NewOrCopySelectorProps {
	selectedValue: SelectedRadioType
	setSelectedValue: (value: SelectedRadioType) => void
	newLabel: string
	copyLabel: string
}

const NewOrCopyRadioSelector: React.FC<NewOrCopySelectorProps> = ({
	selectedValue,
	setSelectedValue,
	newLabel,
	copyLabel
}) => {
	return (
		<RadioGroup
			row={false}
			value={selectedValue}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
				setSelectedValue(e.target.value as SelectedRadioType)
			}
			color="primary"
		>
			<FormControlLabel
				className="material-radio"
				value={SelectedRadioType.new}
				control={<CastorRadio color="primary" />}
				label={newLabel}
			/>
			<FormControlLabel
				className="material-radio"
				value={SelectedRadioType.copy}
				control={<CastorRadio color="primary" />}
				label={copyLabel}
			/>
		</RadioGroup>
	)
}

export default NewOrCopyRadioSelector
