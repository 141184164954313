import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import EditIcon from '@material-ui/icons/Edit'
import ViewPageIcon from '@material-ui/icons/Pageview'
import PublishIcon from '@material-ui/icons/Publish'
import cx from 'classnames'

import * as AdminProjectsActions from './AdminProjectsActions'
import {
	PROJECT_PARTS_ADMIN_ROUTE,
	PROJECTS_ROUTE,
	USER_HOME_ROUTE
} from '../../../../Services/Constants/RoutesConstants'
import { adminProjectsRoute } from '../../../../Services/routeFuncs'
import {
	ADMIN_PROJECTS_COMPANY,
	ADMIN_PROJECTS_CREATED_AT,
	ADMIN_PROJECTS_DEFAULT_MATERIAL,
	ADMIN_PROJECTS_EMAIL,
	ADMIN_PROJECTS_EXTERNAL_ASSEMBLY_FILE_ID,
	ADMIN_PROJECTS_FILTER_NOT_ADMIN,
	ADMIN_PROJECTS_FILTER_NOT_PUBLISHED,
	ADMIN_PROJECTS_FILTER_NOT_QA,
	ADMIN_PROJECTS_ID,
	ADMIN_PROJECTS_NAME,
	ADMIN_PROJECTS_PARTS_IN_PROJECT,
	ADMIN_PROJECTS_PUBLISHED_AT,
	ADMIN_PROJECTS_STATUS,
	ADMIN_TABLE_NO_DATA_FOUND,
	ADMIN_USERS_ERROR,
	DOWNLOAD_ASSEMBLY,
	EDIT_PROJECT,
	EXPLODE_PROJECT,
	PUBLISH,
	PUBLISH_PROJECT,
	VIEW_PROJECT
} from '../../../../Services/Strings'
import CastorAlert from '../../../Components/alerts/CastorAlert'
import CastorDatePickers from '../../../Components/CastorDatePickers/index.tsx'
import CastorExcelExport from '../../../Components/CastorExcelExport/CastorExcelExport'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorServerFilterBar from '../../../Components/CastorServerFilterBar/CastorServerFilterBar'
import CastorServerSearchBar from '../../../Components/CastorServerSearchBar/CastorServerSearchBar'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { Danger } from '../../../Components/thirdParty/CreativeTim/components'
import Table from '../../../Components/thirdParty/CreativeTim/components/Table/Table.jsx'
import Loader from '../../../Loader/Loader'
import CastorPaginationSelector from 'Scenes/Components/CastorPagination/index'
import Flexbox from 'Scenes/Components/FlexBox'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ProjectStatus } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'

import offTheShelfImg from '../../../../assets/img/offTheShelfPlaceholder.png'

import '../adminHome.scss'
import './AdminProjects.scss'

const PROJECT_SEARCH = 'searchPhraseProject'
const ADMIN_PROJECTS = 'projects'

class AdminProject extends Component {
	constructor(props) {
		super(props)
		this.state = {
			disableFilter: false
		}
		this.props = props
	}

	componentDidMount() {
		const {
			match,
			setupAdminProjectsPage,
			limitProjects,
			searchPhrase,
			filterType
		} = this.props
		const search = searchPhrase || sessionStorage.getItem(PROJECT_SEARCH) || ''
		const urlPage = parseInt(match.params['page']) || 1
		const userId = match.params['owner'] || ''
		setupAdminProjectsPage(search, urlPage, userId, limitProjects, filterType)
	}

	componentDidUpdate = prevProps => {
		const { match, limitProjects, searchPhrase, filterType, projectsOwnerId } =
			this.props
		const search = searchPhrase || sessionStorage.getItem(PROJECT_SEARCH) || ''
		const urlPage = parseInt(match.params['page']) || 1
		const userId = match.params['owner'] || ''
		if (
			(search && prevProps && search !== prevProps.searchPhrase) ||
			(prevProps && filterType !== prevProps.filterType) ||
			userId !== projectsOwnerId
		) {
			this.fetchOrUpdatePage(urlPage, userId, limitProjects, filterType, search)
		}
	}

	componentWillUnmount() {
		if (!this.props.history?.location?.pathname?.includes(ADMIN_PROJECTS)) {
			sessionStorage.removeItem(PROJECT_SEARCH)
			this.props.onAdminProjectsUnmounted()
		}
	}

	renderAlert() {
		const {
			showingSimpleAlertText,
			showingSimpleAlertTitle,
			projectWaitingForPublishing,
			publishConfirmed,
			cancelAlert
		} = this.props
		return (
			<CastorAlert
				show={showingSimpleAlertTitle}
				headerTitle={showingSimpleAlertTitle}
				onConfirm={() => publishConfirmed(projectWaitingForPublishing)}
				onCancel={() => cancelAlert()}
				confirmOptionalText={PUBLISH}
			>
				{showingSimpleAlertText}
			</CastorAlert>
		)
	}

	renderDatepickerAlert() {
		const {
			showDatepicker,
			datePickersToggled,
			downloadReport,
			match,
			userProjectsName
		} = this.props
		const userId = match.params['owner'] || ''
		return (
			<CastorDatePickers
				show={showDatepicker}
				onCancel={() => datePickersToggled(false)}
				downloadReport={(startDate, endDate) =>
					downloadReport(startDate, endDate, userId, userProjectsName)
				}
			/>
		)
	}

	publishClicked(project) {
		this.props.publishProjectClicked(project)
	}

	fetchOrUpdatePage(
		pageNumber,
		projectsOwnerId,
		limitProjects,
		filterType,
		searchPhrase
	) {
		if (pageNumber === 1) {
			this.props.setupAdminProjectsPage(
				searchPhrase || '',
				pageNumber,
				projectsOwnerId,
				limitProjects,
				filterType
			)
		} else {
			this.setState({ disableFilter: true })
			this.props.history.push(adminProjectsRoute(projectsOwnerId, 1))
		}
	}

	explodeClicked(project) {
		const { explodeProjectClicked } = this.props
		explodeProjectClicked(project)
	}

	wrapButtonWithLinkIfEnabled(button, link) {
		return button.props.disabled ? button : <Link to={link}>{button}</Link>
	}

	getStatusClassName(status) {
		switch (status) {
			case ProjectStatus.published: {
				return ProjectStatus.published
			}
			case ProjectStatus.failed: {
				return ProjectStatus.failed
			}
			default: {
				return ProjectStatus.awaitingAnalysis
			}
		}
	}

	renderTableLineButtons(project) {
		const { onProjectDownloadClick, projectDownloadUrlLoading } = this.props
		const viewButDisabled = !['published', 'complete', 'approved'].includes(
			project.status
		)
		const projectId = project.bundleId || project.id
		const viewButton = Feature.isFeatureOn(
			FeatureComponentId.ADMIN_SHOW_PROJECT
		) ? (
			this.wrapButtonWithLinkIfEnabled(
				<IconButton
					title={VIEW_PROJECT}
					disabled={viewButDisabled}
					data-qa="data-qa-admin-view-project"
				>
					<ViewPageIcon />
				</IconButton>,
				USER_HOME_ROUTE + PROJECTS_ROUTE + '/' + projectId
			)
		) : (
			<div></div>
		)
		const editButton = this.wrapButtonWithLinkIfEnabled(
			<IconButton title={EDIT_PROJECT}>
				<EditIcon />
			</IconButton>,
			PROJECT_PARTS_ADMIN_ROUTE + '/' + projectId
		)
		const downloadButton = () => {
			//TODO: hot fix CAS-4775
			const disableDownload = true
			if (!project.isAssembly || disableDownload) {
				return <div />
			}
			if (projectDownloadUrlLoading[project.id]) {
				return (
					<Loader
						load={projectDownloadUrlLoading}
						size={20}
						left={10}
						top={0}
						showFlex={false}
						wrapperClassName="admin--projects--icon-loader"
					/>
				)
			}
			return (
				<IconButton
					title={DOWNLOAD_ASSEMBLY}
					onClick={() => onProjectDownloadClick(project.id)}
				>
					<CloudDownloadIcon />
				</IconButton>
			)
		}

		const publishButton = Feature.isFeatureOn(
			FeatureComponentId.ADMIN_PUBLISH_PROJECT
		) ? (
			<IconButton
				title={PUBLISH_PROJECT}
				disabled={project.status !== 'complete'}
				onClick={this.publishClicked.bind(this, project)}
				style={styles.iconButton}
			>
				<PublishIcon />
			</IconButton>
		) : (
			<></>
		)

		return [publishButton, editButton, viewButton, downloadButton()]
	}

	tableLine(project) {
		const { allOrganizations } = this.props
		const customizeOrganizationsIsOn = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
		)
		const projectOrganization = customizeOrganizationsIsOn
			? allOrganizations.find(
					organization => organization.id === project.organizationId
			  )
			: ''
		const statusClassName = this.getStatusClassName(project.status)
		const isBundle = !!project.bundleId
		const projectId = project.bundleId || project.id
		const projectName = project.bundle ? project.bundle.name : project.name
		const imgSrc = project.assemblyImage
			? project.assemblyImage
			: offTheShelfImg
		const projectMarks = isBundle
			? [getString('ASSEMBLY'), getString('PART_PARTS')]
			: project.isAssembly
			? [getString('ASSEMBLY')]
			: [getString('PART_PARTS')]

		return [
			<div style={{ height: '100%', display: 'contents' }}>
				<PartImageWithFallback
					src={imgSrc}
					alt={projectName}
					style={styles.thumbnail}
				/>
			</div>,
			<div>
				<h4 className="admin-projects--table--project-name">
					{projectName}
					{projectMarks.map((mark, index) => {
						return (
							<>
								{index > 0 ? '+' : ''}
								<span
									className={cx('admin-projects--project-mark', {
										assembly: mark === getString('ASSEMBLY')
									})}
								>
									{mark}
								</span>
							</>
						)
					})}
				</h4>
				<p>
					<span>{ADMIN_PROJECTS_ID}</span>: {projectId}
				</p>
				<p>
					<span>{ADMIN_PROJECTS_PARTS_IN_PROJECT}</span>:{' '}
					{isBundle ? project.bundlePartsCount : project.partCount}
				</p>
				<p>
					<span>{ADMIN_PROJECTS_DEFAULT_MATERIAL}</span>:{' '}
					{project.defaultMaterial?.name || project.defaultMaterial}
				</p>
				<p>
					<span>{ADMIN_PROJECTS_STATUS}</span>:{' '}
					<span
						className={cx('admin-projects--project-status', statusClassName)}
					>
						{project.status}
					</span>
				</p>
				{projectOrganization && (
					<p>
						<span>{getString('SITE')}</span>: {projectOrganization.name}
					</p>
				)}
				<p>
					<span>{ADMIN_PROJECTS_CREATED_AT}</span>:{' '}
					{new Date(project.createdAt).toLocaleString()}
				</p>
				{project.status !== ProjectStatus.awaitingAnalysis &&
				project.publishedAt ? (
					<p>
						<span>{ADMIN_PROJECTS_PUBLISHED_AT}</span>:{' '}
						{new Date(project.publishedAt).toLocaleString()}
					</p>
				) : (
					''
				)}
			</div>,
			<div>
				<p>
					<span>{ADMIN_PROJECTS_NAME}</span>: {project.owner?.name}
				</p>
				<p>
					<span>{ADMIN_PROJECTS_EMAIL}</span>: {project.owner?.email}
				</p>
				{customizeOrganizationsIsOn ? (
					<></>
				) : (
					<p>
						<span>{ADMIN_PROJECTS_COMPANY}</span>: {project.owner?.company}
					</p>
				)}
			</div>,
			<Flexbox alignItems="center" className="admin-buttons-wrapper">
				{this.renderTableLineButtons(project)}
			</Flexbox>
		]
	}

	renderSearchBar = () => (
		<div className="admin-data-table-search-field">
			<CastorServerSearchBar
				triggerUpdateField={this.props.filterType}
				placeholder="Search by name/ ID"
				searchPhrase={this.props.searchPhrase}
				setSearchPhrase={this.setSearchPhrase}
			/>
		</div>
	)

	renderFilterBar = () => {
		const renderItems = [
			{ value: 'not_published', key: ADMIN_PROJECTS_FILTER_NOT_PUBLISHED },
			{ value: 'not_qa', key: ADMIN_PROJECTS_FILTER_NOT_QA },
			{ value: 'not_admin', key: ADMIN_PROJECTS_FILTER_NOT_ADMIN }
		]

		return (
			<CastorServerFilterBar
				disableFilter={this.state.disableFilter}
				value={this.props.filterType}
				onChange={this.setFilterType}
				items={renderItems}
			/>
		)
	}

	exportData = () => {
		return (
			<div className="admin-data-table-export-data">
				<CastorExcelExport
					disabled={!this.props.showExportExcel}
					isLoading={this.props.isAdminProjectReportLoading}
					onClick={() => this.props.datePickersToggled(true)}
				/>
			</div>
		)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
		sessionStorage.setItem(PROJECT_SEARCH, searchPhrase)
		if (!searchPhrase) {
			this.fetchOrUpdatePage(
				this.props.pageNumber,
				this.props.projectsOwnerId,
				this.props.limitProjects,
				this.props.filterType,
				searchPhrase
			)
		}
	}

	setFilterType = filterType => {
		this.props.setFilterType(filterType)
	}

	renderTable() {
		const {
			showPagination,
			pageNumber,
			isLastPage,
			projectsOwnerId,
			totalProjects,
			limitProjects,
			showNoProjectsAlert,
			showAdminUsersAlert,
			loading,
			showingTo,
			showingFrom
		} = this.props

		const renderTable = () => {
			if (showNoProjectsAlert) {
				return (
					<div className="align-start">
						<Danger>{ADMIN_TABLE_NO_DATA_FOUND}</Danger>
					</div>
				)
			}
			if (showAdminUsersAlert) {
				return (
					<div className="align-start">
						<Danger>{ADMIN_USERS_ERROR}</Danger>
					</div>
				)
			}
			return (
				<>
					<Table
						tableHead={tableHeaders}
						tableData={tableContent}
						loading={loading}
						className="admin-projects--table"
						addMinHeight
					/>
					<CastorPaginationSelector
						showPagination={showPagination}
						pageNumber={pageNumber}
						showingFrom={showingFrom}
						showingTo={showingTo}
						isLastPage={isLastPage}
						linkTo={pageNumber =>
							adminProjectsRoute(projectsOwnerId, pageNumber)
						}
						total={totalProjects}
						limit={limitProjects}
						showOfStringKey="SHOW_OF_PROJECTS"
					/>
				</>
			)
		}

		const tableHeaders = [
			'',
			'Project Information',
			'User information',
			'Actions'
		]
		const tableContent = this.props.projects.map(project =>
			this.tableLine(project)
		)
		return (
			<div className="admin-home-page">
				<div className="admin-data-table-add-item-button">
					{this.renderFilterBar()}
					{this.renderSearchBar()}
					{this.exportData()}
				</div>
				{renderTable()}
			</div>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={'Projects'}>
				<div style={{ minHeight: '50vh' }}>
					{this.renderAlert()}
					{this.renderDatepickerAlert()}
					<CastorForm
						formTitle={`${
							this.props.userProjectsName
								? `${this.props.userProjectsName}'s`
								: ''
						} Projects`}
						formSubTitle="The latest projects"
						content={this.renderTable()}
						style={{ maxWidth: 'unset' }}
						headerCardClasses={{ content: 'admin-projects--content-wrapper' }}
					/>
				</div>
			</NavBarAndMaterial>
		)
	}
}

const styles = {
	textField: { width: '70%', minWidth: 350 },
	smallTextField: { width: 150, marginLeft: 6, marginRight: 6 },
	chart: { height: '300px', marginRight: 20, marginTop: 20, flexGrow: 5 },
	horizontalFlex: { flex: 1, alignItems: 'center' },
	xAxisLabel: { textAlign: 'center' },
	divider: { margin: 10 },
	thumbnail: { width: '100%' },
	card: { marginLeft: 20, marginRight: 20 },
	iconButton: { height: 40, alignSelf: 'center' },
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	}
}

const mapStateToProps = ({ AdminProjectsReducer, user }) => {
	return {
		...AdminProjectsReducer,
		allOrganizations: user.allOrganizations
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators({ ...AdminProjectsActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdminProject)
