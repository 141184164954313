import { FC, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import TableViewIcon from '@material-ui/icons/CompareArrows'
import cx from 'classnames'

import { ConfigurationResultTypes } from '../../MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import { onSetLeadingConfigurationClick } from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import { findBetterProperties, getRedesignEffort } from '../PartAnalysisService'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import FlexBox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { displayInRange } from 'Services/global/calculateRange'
import { SuggestionType } from 'Services/models/IConfiguration'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

interface IPropsButton {
	showCompare: boolean
	setShowCompare: (value: boolean) => void
}
interface IPropsList {
	configurations: Record<string, any>[]
	showCompare: boolean
	setShowCompare: (value: boolean) => void
}

export const ConfigurationCompareButton: FC<IPropsButton> = ({
	setShowCompare,
	showCompare
}) => {
	return (
		<div
			id="add-new-configuration-tab"
			className="add-new-button others compare"
			onClick={(e: any) => {
				e.preventDefault()
				e.stopPropagation()
				setShowCompare(true)
			}}
		>
			<FlexBox
				alignItems="center"
				justifyContent="center"
				className={cx({
					active: showCompare
				})}
			>
				<TableViewIcon />
				{getString('COMPARE')}
			</FlexBox>
		</div>
	)
}

export const ConfigurationCompareListAlert: FC<IPropsList> = ({
	configurations,
	showCompare,
	setShowCompare
}) => {
	const dispatch = useDispatch()
	const allConfigurationsOrganizationSettings = useSelector(
		(state: RootStateOrAny) => {
			return state?.MainPartAnalysisReducer
				?.allConfigurationsOrganizationSettings
		}
	)
	const drawingCostPercentage = useSelector((state: RootStateOrAny) => {
		return (
			state?.user?.userCustomizationSettings?.drawingCostPercentage ||
			state?.MainPartAnalysisReducer?.drawingCostPercentage
		)
	})
	const part = useSelector((state: RootStateOrAny) => {
		return state?.MainPartAnalysisReducer
	})
	const showCostInRange = part?.isDrawing || part?.formatType === METADATA

	const betterProperties = useMemo(
		() => findBetterProperties(configurations),
		[configurations]
	)

	return (
		<CastorAlert
			showConfirm={false}
			headerTitle={getString('OPPORTUNITIES_COMPARISON')}
			alertClass={cx('add-new-solution-alert comparison-alert', {
				big: configurations.length > 3
			})}
			onCancel={() => setShowCompare(false)}
			show={showCompare}
			cancelOptionalText={getString('CLOSE')}
		>
			<div className="grid-list">
				<div className="header"></div>
				<div className="with-border">{getString('COST')}</div>
				<div className="with-border">{getString('SAVINGS')}</div>
				<div className="with-border">{getString('LEAD_TIME_LABEL')}</div>
				<div className="with-border">{getString('MATERIAL')}</div>
				<div className="with-border">{getString('REDESIGN_EFFORT')}</div>
				{configurations?.map(suggestion => {
					const orgPercentage =
						allConfigurationsOrganizationSettings[suggestion.organizationId]
							?.drawingCostPercentage
					const leadingByUserChoice =
						suggestion.leadingByUserChoice || suggestion.isLeading
					const { text, type } = getRedesignEffort(suggestion.resultType)
					const { maxCostDetails, minCostDetails, totalCost } =
						suggestion.solution?.costDetails
					let totalCostValue = displayInRange(
						minCostDetails?.totalCost,
						maxCostDetails?.totalCost,
						totalCost,
						null,
						showCostInRange ? orgPercentage || drawingCostPercentage : undefined
					)
					const notRangeCost =
						suggestion.suggestionType !== SuggestionType.AM ||
						suggestion.resultType === ConfigurationResultTypes.PartConsolidation

					return (
						<>
							<div className={'header'}>
								<FlexBox alignItems="center" justifyContent="space-between">
									{suggestion.name}
									{suggestion.resultType !==
										ConfigurationResultTypes.PartConsolidation && (
										<Button
											data-qa="data-qa-project-leading"
											color="transparent"
											onClick={() => {
												dispatch(
													onSetLeadingConfigurationClick(
														suggestion.id,
														!leadingByUserChoice
													)
												)
											}}
											className={cx(
												'part-analysis-button-text-transform solution-analysis-header-text starred',
												{
													enabled: true,
													saved: leadingByUserChoice
												}
											)}
										>
											<IconFactory
												iconName={leadingByUserChoice ? 'saved' : 'save'}
												className="solution-analysis-header-text-icon starred"
											/>
										</Button>
									)}
								</FlexBox>
							</div>
							<div
								className={cx({
									winner:
										betterProperties.cost.id === suggestion.id ||
										betterProperties.cost.value ===
											suggestion?.solution?.costDetails?.totalCost
								})}
							>
								$
								{notRangeCost
									? totalCost?.toFixed(1) || '-'
									: totalCostValue || '-'}
							</div>
							<div
								className={cx({
									winner:
										betterProperties.costSaving.id === suggestion.id ||
										betterProperties.costSaving.value ===
											suggestion?.costSavingAmount
								})}
							>
								${suggestion.costSavingAmount?.toFixed(1)} (
								{suggestion.costSavingPercentage?.toFixed(1)}%)
							</div>
							<div
								className={cx({
									winner:
										betterProperties.leadTime.id === suggestion.id ||
										betterProperties.leadTime.value ===
											suggestion?.solution?.leadTimeDetails?.lastDay
								})}
							>
								{suggestion.solution?.leadTimeDetails?.lastDay || '-'}
							</div>
							<div>{suggestion.material?.name || '-'}</div>
							<div
								className={cx({
									winner:
										betterProperties.redesignEffort.id === suggestion.id ||
										betterProperties.redesignEffort.value ===
											getRedesignEffort(suggestion?.resultType)?.impact
								})}
							>
								<span className={type}>{text}</span>
							</div>
						</>
					)
				})}
			</div>
		</CastorAlert>
	)
}
