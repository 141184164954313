import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'

import OrganizationPanel from '../OrganizationPanel/OrganizationPanel'
import CustomizeCostSuggestions from './CustomizeCostSuggestions'
import CustomizeListOfSuggestions from './CustomizeListOfSuggestions'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import {
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { NAV_TITLE_CUSTOMIZE_USER } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getThemeString } from 'themes/getThemeString'

import './index.scss'

const CustomizeSuggestions: FC = () => {
	const history = useHistory()

	const renderFormHeader = () => {
		return (
			<div className="customize-settings--header">
				<CastorFormHeader
					goBack={() => history.push(USER_HOME_ROUTE + CUSTOMIZE_ROUTE)}
					explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
					explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
				/>
				<OrganizationPanel />
			</div>
		)
	}

	const renderFormContent = () => {
		return (
			<div className="customize-cost-and-lead-settings customize-suggestions">
				<CustomizeCostSuggestions />
				<CustomizeListOfSuggestions />
			</div>
		)
	}

	return (
		<NavBarAndMaterial title={NAV_TITLE_CUSTOMIZE_USER}>
			<CastorForm
				formHeader={renderFormHeader()}
				formTitle={getString('CUSTOMIZE_SUGGESTIONS_TITLE')}
				content={renderFormContent()}
			/>
		</NavBarAndMaterial>
	)
}

export default memo(CustomizeSuggestions)
