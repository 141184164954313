import React, { memo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { TextField } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

import { updateSSOConfig } from './LicenseActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import FlexBox from 'Scenes/Components/FlexBox'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import HeaderCard from 'Scenes/Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import { loginProvider } from 'Services/Constants'
import { LICENSE_UPLOAD_SCREEN_ROUTE } from 'Services/Constants/RoutesConstants'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { cardBoxColor } = getTheme()

export interface SSOConfig {
	clientId: string
	clientSecret: string
	environmentId: string
}

const ConfigSSO: React.FC = () => {
	const { loadingSSO } = useSelector(
		(state: RootStateOrAny) => state.AdminLicenseScreenReducer
	)
	const [clientId, setClientId] = useState('')
	const [clientSecret, setClientSecret] = useState('')
	const [environmentId, setEnvironmentId] = useState('')
	const dispatch = useDispatch()
	const history = useHistory()

	const handleSubmit = () => {
		dispatch(
			updateSSOConfig(
				{ clientId, clientSecret, environmentId },
				loginProvider.ping
			)
		)
	}

	return (
		<div className="upload-script">
			<NavBarAndMaterial title={getString('CONFIGURE_SSO_TEXT')}>
				<div className="upload-script-body">
					<div onClick={() => history.push(LICENSE_UPLOAD_SCREEN_ROUTE)}>
						<FlexBox
							className="go-back-button"
							alignItems="center"
							justifyContent="flex-start"
						>
							<ArrowBack />

							{getString('BACK')}
						</FlexBox>
					</div>

					<HeaderCard
						cardTitle={getString('CONFIGURE_SSO_TEXT')}
						cardSubtitle=""
						headerColor={cardBoxColor || 'green'}
						content={
							<div>
								<TextField
									label={getString('CONFIGURE_SSO_CLIENT_ID')}
									value={clientId}
									onChange={e => setClientId(e.target.value)}
									margin="normal"
									fullWidth
								/>
								<TextField
									label={getString('CONFIGURE_SSO_SECRET_KEY')}
									value={clientSecret}
									onChange={e => setClientSecret(e.target.value)}
									margin="normal"
									fullWidth
								/>
								<TextField
									label={getString('CONFIGURE_SSO_ENVIRONMENT_ID')}
									value={environmentId}
									onChange={e => setEnvironmentId(e.target.value)}
									margin="normal"
									fullWidth
								/>
								<ButtonWithLoader
									disabled={!clientId || !clientSecret || !environmentId}
									variant="contained"
									color="primary"
									onClick={handleSubmit}
									loading={loadingSSO}
								>
									{getString('SUBMIT')}
								</ButtonWithLoader>
							</div>
						}
					/>
				</div>
			</NavBarAndMaterial>
		</div>
	)
}

export default memo(ConfigSSO)
