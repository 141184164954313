import { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { getThemeString } from '../../../themes/getThemeString'
import CastorForm from '../../Components/CastorForm/CastorForm'
import Devider from '../../Components/Devider/Devider'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import {
	renderCustomizeFilters,
	renderCustomizeInHousePrinters,
	renderFormHeader,
	renderSemiProPrinters,
	renderSustainabilityLifeCycle
} from './CustomizeFormCommon'
import CustomizeFormSection from './CustomizeFormSection'
import { History } from 'history'
import Flexbox from 'Scenes/Components/FlexBox'
import { fetchCustomizeData } from 'Scenes/Home/Customize/CustomizeActions'
import {
	CUSTOMIZE_ADDITIVE_MANUFACTURING_COST_AND_LEAD_ROUTE,
	CUSTOMIZE_CO2_CALCULATIONS_CASTOR2_ROUTE,
	CUSTOMIZE_IN_HOUSE_PRINTERS_CASTOR2_ROUTE,
	CUSTOMIZE_ROUTE,
	CUSTOMIZE_SOLUTION_FILTER_CASTOR2_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { customizeUserMaterialForCastor2 } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

import { styles } from '../styles/formStyles.css'
import './CustomizeForm.scss'

interface IProps {
	adminSelectedOrganizationId: number
	history: History
	onToggleDesktopPrinter: Function
	desktopPrinter: boolean
}

const renderInitialFormContent = (
	onToggleDesktopPrinter: Function,
	desktopPrinter: boolean,
	isShowSemiProfessionalPrintersToggle: boolean,
	adminSelectedOrganizationId: number
) => {
	return (
		<Flexbox flexDirection="column" style={styles.formSuperview}>
			{renderCustomizeInHousePrinters(
				USER_HOME_ROUTE + CUSTOMIZE_IN_HOUSE_PRINTERS_CASTOR2_ROUTE
			)}

			{renderSustainabilityLifeCycle(
				USER_HOME_ROUTE + CUSTOMIZE_CO2_CALCULATIONS_CASTOR2_ROUTE
			)}
			<Devider className="customize-form--divider" />
			<CustomizeFormSection
				explanationHeader={getString(
					'CUSTOMIZE_ADDITIVE_MANUFACTURING_COST_AND_LEAD_TITLE'
				)}
				explanationArray={getString(
					'CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY'
				)}
				linkTo={
					USER_HOME_ROUTE + CUSTOMIZE_ADDITIVE_MANUFACTURING_COST_AND_LEAD_ROUTE
				}
				qaDataElementName="data-qa-cost-calculation-params"
			/>
			<Devider className="customize-form--divider" />

			{renderCustomizeFilters(
				USER_HOME_ROUTE + CUSTOMIZE_SOLUTION_FILTER_CASTOR2_ROUTE
			)}

			{Feature.isFeatureOn(FeatureComponentId.CUSTOM_MATERIALS) ? (
				<>
					<Devider className="customize-form--divider" />
					<CustomizeFormSection
						explanationHeader={getString('CUSTOMIZE_USER_MATERIAL_TITLE')}
						explanationArray={getThemeString(
							'CUSTOMIZE_SETTINGS_MATERIAL_EXPLANATION_ARRAY'
						)}
						linkTo={customizeUserMaterialForCastor2()}
						qaDataElementName="data-qa-add-material"
					/>
				</>
			) : (
				<></>
			)}
			{renderSemiProPrinters(
				desktopPrinter,
				onToggleDesktopPrinter,
				isShowSemiProfessionalPrintersToggle,
				adminSelectedOrganizationId
			)}
		</Flexbox>
	)
}

const AdditiveManufacturingSettingsForm: FC<IProps> = ({
	adminSelectedOrganizationId,
	history,
	onToggleDesktopPrinter,
	desktopPrinter
}) => {
	const dispatch = useDispatch()

	const { settingsData, costAndLeadNames } = useSelector(
		(state: RootStateOrAny) => state.CostAndLeadReducer
	)
	const { isShowSemiProfessionalPrintersToggle } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const { printersFullData } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	useEffect(() => {
		dispatch(
			fetchCustomizeData(
				costAndLeadNames,
				settingsData.userSettings,
				undefined,
				printersFullData,
				adminSelectedOrganizationId
			)
		)
	}, [])

	useEffect(() => {
		if (adminSelectedOrganizationId) {
			dispatch(
				fetchCustomizeData(
					costAndLeadNames,
					settingsData.userSettings,
					undefined,
					printersFullData,
					adminSelectedOrganizationId
				)
			)
		}
	}, [adminSelectedOrganizationId])

	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<CastorForm
				formHeader={renderFormHeader(
					history,
					USER_HOME_ROUTE + CUSTOMIZE_ROUTE
				)}
				formTitle={getString('CUSTOMIZE_FORM_HEADER')}
				content={renderInitialFormContent(
					onToggleDesktopPrinter,
					desktopPrinter,
					isShowSemiProfessionalPrintersToggle,
					adminSelectedOrganizationId
				)}
			/>
		</NavBarAndMaterial>
	)
}
export default memo(AdditiveManufacturingSettingsForm)
