import { partsPageLimit } from './Constants'
import { get, put } from './Network'
import { SelectedType } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ChallengeFilter/ChallengeFilterAlertService'
import { ClientChallengesType } from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionService'

export const updateCostSavingThreshold = (
	projectId: number,
	costSavingThresholdPercent: number | null,
	costSavingThresholdValue: number | null
) => {
	let params = {
		costSavingThresholdPercent,
		costSavingThresholdValue,
		projectId
	}
	return put('/user/customize/costSavingThreshold', params)
}

export const updateDefaultSuggestions = (
	organizationId: number,
	defaultSuggestions: Record<string, boolean>
) => {
	let params = {
		defaultSuggestions,
		organizationId
	}
	return put('/user/customize/defaultSuggestions', params)
}

export const getDefaultSuggestions = (organizationId: number) => {
	return get(
		`/user/customize/defaultSuggestions?organizationId=${organizationId}`
	)
}

export const getProjectSuggestionsResults = (
	projectId: string | null = null,
	bundleId: string | null = null
) => {
	return get(
		`/projectsSuggestionsResults?projectId=${projectId}&bundleId=${bundleId}`
	)
}

export const getSuggestionParts = ({
	projectId = '',
	bundleId = '',
	searchPhrase = '',
	page = 1,
	limit = partsPageLimit,
	sortBy = '',
	sortingCriteria = '',
	selectedResultType = ''
}) => {
	return get(
		`/projectsPartsSuggestions?projectId=${projectId}&bundleId=${bundleId}&searchPhrase=${searchPhrase}&page=${page}&limit=${limit}&clientSuggestionType=${selectedResultType}&sortingCriteria=${sortingCriteria}&sortBy=${sortBy}`
	)
}

export const getNoSuggestionParts = ({
	projectId = '',
	bundleId = '',
	searchPhrase = '',
	page = 1,
	limit = partsPageLimit,
	sortBy = '',
	sortingCriteria = '',
	selectedResultType = ''
}) => {
	return get(
		`/noOpportunitiesParts?projectId=${projectId}&bundleId=${bundleId}&searchPhrase=${searchPhrase}&page=${page}&limit=${limit}&clientNoOpportunitiesType=${selectedResultType}&sortingCriteria=${sortingCriteria}&sortBy=${sortBy}`
	)
}

export const getChallengesParts = ({
	projectId = '',
	bundleId = '',
	searchPhrase = '',
	page = 1,
	limit = partsPageLimit,
	sortBy = '',
	sortingCriteria = '',
	selectedResultType = ''
}) => {
	return get(
		`/challengesParts?projectId=${projectId}&bundleId=${bundleId}&searchPhrase=${searchPhrase}&page=${page}&limit=${limit}&clientChallengesType=${selectedResultType}&sortingCriteria=${sortingCriteria}&sortBy=${sortBy}`
	)
}

export const updateChallengeFilersValue = (
	value: number | string,
	type: SelectedType,
	challengeType: ClientChallengesType,
	projectId: string
) => {
	let params = {
		value,
		type,
		challengeType,
		projectId
	}
	return put('/challengesFilters', params)
}
