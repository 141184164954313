import { FC } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import {
	ClientSuggestionType,
	TabsItem
} from '../ProjectAnalysisSuggestionService'
import PartRow from './PartRow'
import PartsListHeaders from './PartsListHeaders'
import FlexBox from 'Scenes/Components/FlexBox'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as NoOpportunitySvg } from 'assets/img/svg/no-opportunity.svg'

import './PartsListView.scss'

type IProps = {
	suggestions: Part[]
	leadingConfigurationData: IBestMatchData[]
	lockedParts: boolean
	resultType: number
	selectedTab: TabsItem
	amountOfLockedParts: number
	roles: number[]
	contactUsEmail: string
	partsLoading: boolean
	isBundle: boolean
	textKey: string
}

const PartsListView: FC<IProps> = ({
	suggestions,
	leadingConfigurationData,
	lockedParts,
	isBundle,
	textKey,
	resultType,
	partsLoading
}) => {
	const isQuickCADUpload = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)
	if (suggestions?.length === 0 && !partsLoading) {
		const text =
			resultType === ClientSuggestionType.amPC ||
			resultType === ClientSuggestionType.amWR ||
			ClientSuggestionType.cnc
				? ''
				: getString('PARTS_OPPORTUNITIES')
		return (
			<div className="suggestion-parts-list--wrapper empty">
				<FlexBox
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					width="100%"
					height="100%"
					minHeight="386px"
				>
					<NoOpportunitySvg />
					<div className="text">
						{getString('NO_OPPORTUNITIES_FOUND').format(
							getString(textKey),
							text
						)}
					</div>
				</FlexBox>
			</div>
		)
	}

	return (
		<div className="suggestion-parts-list--wrapper">
			<PartsListHeaders isQuickCADUpload={isQuickCADUpload} />
			<div className="suggestion-parts-list--body">
				{suggestions?.map((suggestion, idx) => (
					<PartRow
						idx={idx}
						key={suggestion.id}
						suggestion={suggestion}
						leadingConfigurationData={leadingConfigurationData}
						isBundle={isBundle}
						isQuickCADUpload={isQuickCADUpload}
					/>
				))}
			</div>
		</div>
	)
}

export default PartsListView
