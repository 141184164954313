import parseHTML from 'html-react-parser'
import { FC, memo } from 'react'

import './DataTable.scss'

interface Props {
	text: string | number
	data: any
}

const DataTableDetailedField: FC<Props> = ({
	text,
	data: { className = '', title = '', intro = '' }
}) => (
	<div className={`data-table-text-field  ${className}`}>
		<p>{title}</p>
		<p>{parseHTML(text.toString())}</p>
		<p>{intro}</p>
	</div>
)

export default memo(DataTableDetailedField)
