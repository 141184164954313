import React, { memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core'
import cx from 'classnames'

import { getString } from '../../../../Services/Strings/StringService'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import {
	DOLLAR,
	EMPTY_PLACEHOLDER,
	EMPTY_PLACEHOLDER_DOLLAR,
	MAX_DOLLAR_VALUE,
	MAX_PERCENTAGE_VALUE,
	MIN_VALUE,
	MIN_VALUE_DOLLAR,
	PERCENTAGE
} from './CustomizeSugesstionsService'
import {
	onSaveCostSuggestions,
	onSelectCostSuggestion,
	onUpdateCostDollarValue,
	onUpdateCostPercentageValue
} from './CustomizeSuggestionsActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorRadio from 'Scenes/Components/CastorRadio'
import FlexBox from 'Scenes/Components/FlexBox'
import NumberField from 'Scenes/Components/NumberField'

const CustomizeCostSuggestions = () => {
	const [error, setError] = useState<string>('')
	const dispatch = useDispatch()

	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const {
		costSavingThresholdValue: costSavingThresholdValueInitial,
		costSavingThresholdPercent: costSavingThresholdPercentInitial
	} = useSelector((state: RootStateOrAny) => state.user)

	const {
		costSavingThresholdValue,
		costSavingThresholdPercent,
		selectedCostSuggestion,
		costSavingLoading
	} = useSelector((state: RootStateOrAny) => state.CustomizeSuggestionsReducer)

	useEffect(() => {
		if (!!costSavingThresholdPercentInitial) {
			dispatch(onSelectCostSuggestion(PERCENTAGE))
		}
		if (!!costSavingThresholdValueInitial) {
			dispatch(onSelectCostSuggestion(DOLLAR))
		}
	}, [])

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value
		dispatch(onSelectCostSuggestion(value))
		setError('')

		if (value === DOLLAR) {
			dispatch(onUpdateCostPercentageValue(null))
		}

		if (value === PERCENTAGE) {
			dispatch(onUpdateCostDollarValue(null))
		}
	}

	const onSaveCostSuggestionsClick = () => {
		dispatch(
			onSaveCostSuggestions(
				adminSelectedOrganizationId,
				costSavingThresholdPercent,
				costSavingThresholdValue
			)
		)
	}

	const onUpdateCostDollarValueWithCheck = (value: number) => {
		dispatch(onUpdateCostDollarValue(value))

		if (value < MIN_VALUE_DOLLAR || value > MAX_DOLLAR_VALUE) {
			setError(
				getString('VALUE_NUMBER_BETWEEN').format(
					MIN_VALUE_DOLLAR,
					MAX_DOLLAR_VALUE
				)
			)
		} else if (!!error) {
			setError('')
		}
	}

	const onUpdateCostPercentageValueWithCheck = (value: number) => {
		dispatch(onUpdateCostPercentageValue(value))

		if (value < MIN_VALUE || value > MAX_PERCENTAGE_VALUE) {
			setError(
				getString('VALUE_NUMBER_BETWEEN').format(
					MIN_VALUE,
					MAX_PERCENTAGE_VALUE
				)
			)
		} else if (!!error) {
			setError('')
		}
	}

	return (
		<div
			className="cost-calculation-params-wrapper"
			data-qa="data-qa-suggestions-parameters-block"
		>
			<CastorFormHeader
				explanationHeader={getString('CUSTOMIZE_COST_SAVING_THRESHOLD_TITLE')}
				explanationArray={getString('CUSTOMIZE_COST_SAVING_THRESHOLD_ARRAY')}
				isInCard
			/>
			<FlexBox className="customize-suggestions_wrapper" flexDirection="column">
				<FormControl className="customize-suggestions_radio-group">
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						name="radio-buttons-group"
						value={selectedCostSuggestion}
						onChange={handleRadioChange}
					>
						<FormControlLabel
							value={DOLLAR}
							control={
								<CastorRadio
									className="upload-form-radio"
									inputProps={{ 'data-qa': `data-qa-radio-cost-dollar` }}
								/>
							}
							label={getString('CUSTOMIZE_COST_SAVING_DOLLARS')}
						/>
						{selectedCostSuggestion === DOLLAR && (
							<FlexBox alignItems="center">
								<NumberField
									disabled={false}
									allowZero={false}
									allowEmpty={true}
									placeholder={
										costSavingThresholdValueInitial || EMPTY_PLACEHOLDER_DOLLAR
									}
									variant="outlined"
									value={costSavingThresholdValue}
									onChangeValue={(value: number) =>
										onUpdateCostDollarValueWithCheck(value)
									}
									inputClass={cx('field-with-label outlined')}
									minValue={0}
									showArrows={true}
									changeOnScroll={false}
									qaDataElementName={'data-qa-radio-cost-input-dollar'}
									isCommaDisabled={false}
									isDotDisabled={false}
									step={1}
									error={!!error}
									helperText={error}
								/>
								<span>$</span>
							</FlexBox>
						)}
						<FormControlLabel
							value={PERCENTAGE}
							control={
								<CastorRadio
									className="upload-form-radio"
									inputProps={{ 'data-qa': `data-qa-radio-cost-percentage` }}
								/>
							}
							label={getString('CUSTOMIZE_COST_SAVING_PERCENT')}
						/>
						{selectedCostSuggestion === PERCENTAGE && (
							<FlexBox alignItems="center">
								<NumberField
									disabled={false}
									allowZero={true}
									allowEmpty={true}
									placeholder={
										costSavingThresholdPercentInitial || EMPTY_PLACEHOLDER
									}
									value={costSavingThresholdPercent}
									onChangeValue={(value: number) =>
										onUpdateCostPercentageValueWithCheck(value)
									}
									inputClass={cx('field-with-label outlined')}
									minValue={0}
									showArrows={true}
									changeOnScroll={false}
									qaDataElementName={'data-qa-radio-cost-input-percentage'}
									isCommaDisabled={true}
									isDotDisabled={true}
									step={1}
									error={!!error}
									helperText={error}
								/>
								<span>%</span>
							</FlexBox>
						)}
					</RadioGroup>
				</FormControl>
				<ButtonWithLoader
					qaDataElementName="data-qa-cost-suggestion-set-btn"
					loading={costSavingLoading}
					onClick={() => onSaveCostSuggestionsClick()}
					disabled={
						!selectedCostSuggestion ||
						(!costSavingThresholdValue && !costSavingThresholdPercent) ||
						!!error
					}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</FlexBox>
		</div>
	)
}

export default memo(CustomizeCostSuggestions)
