import { FC, memo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Divider } from '@material-ui/core'

import { handleLoginProviderUrl } from '../AuthActions'
import UsageAgreementCheckbox from '../Register/UsageAgreementCheckbox'
import { styles } from './styles'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import FlexBox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'

import azureProvider from '../../../assets/img/azure-provider.png'
import pingProvider from '../../../assets/img/ping-provider.png'

const AZURE = 'azure'
const PING = 'ping'

interface LoginProvider {
	name: string
	category: string
	description: string
}

const LoginProviders: FC = () => {
	const [agreedToTerms, setAgreedToTerms] = useState(false)
	const { loginProviders, loginFields } = useSelector(
		(state: RootStateOrAny) => state.auth
	)
	const dispatch = useDispatch()
	const handleLogin = async (provider: string) => {
		await dispatch(handleLoginProviderUrl(provider))
	}

	const getImgUrl = (providerName: string) => {
		switch (providerName) {
			case AZURE:
				return azureProvider
			case PING:
				return pingProvider
			default:
				return azureProvider
		}
	}

	if (!loginProviders?.length) {
		return <div></div>
	}

	return (
		<div className="login-providers_block">
			{!!loginFields ? (
				<div className="login-providers_title">
					<>
						<div className="text">{getString('OR')}</div>
						<Divider style={styles.divider} />
					</>
				</div>
			) : (
				<div />
			)}

			<FlexBox
				alignItems="center"
				justifyContent="center"
				className="login-providers"
			>
				{loginProviders.map(({ name }: LoginProvider) => {
					return (
						<ButtonWithLoader
							disabled={!agreedToTerms}
							key={name}
							className="login-button"
							color={'secondaryNewStyle'}
							style={{ ...styles.submitButtonStyle, height: 48 }}
							onClick={() => handleLogin(name)}
							qaDataElementName={`data-qa-login-${name}-btn`}
						>
							<img src={getImgUrl(name)} alt={name} style={styles.loginImage} />{' '}
							<span>
								{getString('LOGIN_WITH')}
								<span className="provider"> {name}</span>
							</span>
						</ButtonWithLoader>
					)
				})}
			</FlexBox>
			<UsageAgreementCheckbox
				agreedToTerms={agreedToTerms}
				checkboxClicked={(checked: boolean) => setAgreedToTerms(checked)}
			/>
		</div>
	)
}

export default memo(LoginProviders)
