import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import PartRow from '../../PartsListView/PartRow'
import PartsListHeaders from '../../PartsListView/PartsListHeaders'
import { TabsItem } from '../../ProjectAnalysisSuggestionService'
import PartsSummaryPDF from './PartsSummaryPDF'
import ProjectSummaryPDF from './ProjectSummaryPDF'
import { filterSuggestionsParts } from 'Scenes/Components/FilterPartsGrid/FilterPartsAction'
import FooterPdf from 'Scenes/Components/PdfComponents/FooterPdf'
import HeaderPdf from 'Scenes/Components/PdfComponents/HeaderPdf'
import RowComponent from 'Scenes/Components/PdfComponents/RowPdf'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import { useGetBundleProjectNamesFromParts } from 'Services/CustomHooks/usePdfHooks'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	isBundle: boolean
	projectId: string
	selectedFilterValues: string[]
	searchPhrase: string
	resultType: number
	selectedTab: TabsItem
	projectName: string
	opportunitiesData: Record<string, number>
	totalPartsCount: number
	partsToPrint: Part[]
	leadingConfigurationData: IBestMatchData[]
	shouldRequestAllParts: boolean
	sortBy: string
	sortingCriteria: string
	paginationData: any
}

const ProjectPDF: FC<IProps> = ({
	isBundle,
	projectId,
	selectedFilterValues,
	searchPhrase,
	selectedTab,
	resultType,
	projectName,
	opportunitiesData,
	totalPartsCount,
	partsToPrint,
	leadingConfigurationData,
	shouldRequestAllParts,
	sortBy,
	sortingCriteria,
	paginationData
}) => {
	const userDetails = useSelector((state: RootStateOrAny) => {
		return state?.user?.userDetails || ''
	})
	const user = useSelector((state: RootStateOrAny) => state.user)

	const bundleProjectsNames = useGetBundleProjectNamesFromParts(partsToPrint)

	const dispatch = useDispatch()

	const projectNames = isBundle ? bundleProjectsNames : [projectName]

	const isQuickCADUpload = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)

	const isGenerativeDesignOn = Feature.isFeatureOn(
		FeatureComponentId.GENERATIVE_DESIGN
	)

	useEffect(() => {
		dispatch(
			filterSuggestionsParts(
				projectId,
				searchPhrase,
				isBundle,
				paginationData.page,
				paginationData.limit,
				true,
				user,
				false,
				sortBy,
				sortingCriteria,
				ConfigurationResultTypes.all,
				selectedTab
			)
		)
	}, [])

	return (
		<div>
			<HeaderPdf
				userCompany={userDetails?.company}
				headerText={getString('PROJECT_NAME')}
				headerDetailsText={projectName}
			/>
			<table className="export-pdf">
				<thead>
					<RowComponent>
						<div className="header-space" />
					</RowComponent>
				</thead>
				<tbody>
					<RowComponent>
						<ProjectSummaryPDF
							selectedTab={selectedTab}
							userDetails={userDetails}
							opportunitiesData={opportunitiesData}
							projectName={projectName}
							totalPartsCount={totalPartsCount}
						/>
					</RowComponent>
					<RowComponent>
						<PartsSummaryPDF
							selectedTab={selectedTab}
							resultType={resultType}
							filteredPartsCount={partsToPrint.length}
							projectNames={projectNames}
						/>
					</RowComponent>
					<RowComponent>
						<table>
							<thead>
								<RowComponent>
									<PartsListHeaders
										isQuickCADUpload={isQuickCADUpload}
										toPrint
										isGenerativeDesignOn={isGenerativeDesignOn}
									/>
								</RowComponent>
							</thead>
							<tbody>
								{partsToPrint?.map(part => {
									return (
										<RowComponent key={part.id}>
											<PartRow
												suggestion={part}
												leadingConfigurationData={leadingConfigurationData}
												isBundle={isBundle}
												isQuickCADUpload={isQuickCADUpload}
												toPrint
												isGenerativeDesignOn={isGenerativeDesignOn}
											/>
										</RowComponent>
									)
								})}
							</tbody>
							<tfoot>
								<RowComponent>
									<div className="footer-space" />
								</RowComponent>
							</tfoot>
						</table>
					</RowComponent>
				</tbody>
			</table>
			<FooterPdf userCompany={userDetails?.company} />
		</div>
	)
}

export default ProjectPDF
