import { useEffect, useRef } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
	closeSSEConnection,
	failedConnectionNotifications,
	getPushNotifications,
	getPushNotificationsSilentUpload,
	setReconnectSilentTrigger,
	setReconnectTrigger,
	setupSSEConnection
} from './PushNotificationActions'
import {
	PushNotificationContent,
	PushNotificationSilentContent
} from './PushNotificationService'
import {
	clearUploadMessages,
	getFastProjectPoller,
	getRoutingPoller
} from 'global actions'
import { UploadingProjectInitialState } from 'Scenes/Home/NewUploadProject/UploadingProjectReducer'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getBaseURL } from 'Services/Network'
import { path } from 'Services/Network/push-notification'

const TIME_RECONNECT = 0.3 * 60 * 1000 //18s
const TIME_INTERVAL = 0.1 * 60 * 1000 //6s
const PING = 'ping'
const CLOSED = 'closed'
let time: Record<string, number> = {}

export const usePushNotificationSilentEvent = (projectId: string) => {
	const dispatch = useDispatch()
	const { projectId: projectMatchId } = useParams<{ projectId: string }>()
	const { pollerStarted } = useSelector((state: RootStateOrAny) => {
		return state?.uploadProject
	})
	const { userDetails = {} } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { isSilentReconnect } = useSelector(
		(state: RootStateOrAny) => state.PushNotificationReducer
	)
	const userId = userDetails.id || 0
	const baseUrl = getBaseURL()
	const url = `${baseUrl}${path}/silent-events/projectUpload/${userId}/${
		projectMatchId || projectId
	}`
	const [{ bundleId }] = useSelector((state: RootStateOrAny) => {
		return [
			state.UploadingProjectReducer?.projectUploading[
				projectMatchId || projectId || 0
			] || new UploadingProjectInitialState()
		]
	})
	const clearMessages = () => {
		if (!pollerStarted) {
			dispatch(
				clearUploadMessages(projectMatchId || projectId || bundleId || 0)
			)
		}
	}
	const silentUploadingNotification = Feature.isFeatureOn(
		FeatureComponentId.SILENT_UPLOADING_NOTIFICATION
	)

	useSSEConnection(
		url,
		userId,
		null,
		isSilentReconnect,
		(reconnect: boolean) => dispatch(setReconnectSilentTrigger(reconnect)),
		(notifications: PushNotificationSilentContent) =>
			dispatch(
				getPushNotificationsSilentUpload(
					notifications,
					projectMatchId || projectId
				)
			),
		() => clearMessages(),
		() => {
			dispatch(getFastProjectPoller(projectId, bundleId, true))
			dispatch(getRoutingPoller(projectId, true))
		},
		!projectMatchId && !projectId,
		!!silentUploadingNotification
	)
}

export const usePushNotificationEvent = () => {
	const dispatch = useDispatch()
	const { isReconnect } = useSelector(
		(state: RootStateOrAny) => state.PushNotificationReducer
	)
	const { userDetails = {} } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const userId = userDetails.id || 0
	const baseUrl = getBaseURL()
	const url = `${baseUrl}${path}/events/${userId}`

	const pushNotification = Feature.isFeatureOn(
		FeatureComponentId.PUSH_NOTIFICATIONS
	)

	const clearMessages = () => {}
	useSSEConnection(
		url,
		userId,
		null,
		isReconnect,
		(reconnect: boolean) => dispatch(setReconnectTrigger(reconnect)),
		(notifications: Array<PushNotificationContent>) =>
			dispatch(getPushNotifications(notifications)),
		() => clearMessages(),
		() => dispatch(failedConnectionNotifications()),
		!userId,
		!!pushNotification
	)
}

const useSSEConnection = (
	url: string,
	userId: number,
	projectId: string | null,
	isReconnect: boolean,
	setReconnect: Function,
	getNotifications: Function,
	clearMessages: Function,
	failedConnection: Function,
	disableHook: boolean,
	sseFeatureOn: boolean
) => {
	const dispatch = useDispatch()
	const reconnectAttemptsRef = useRef(0)
	const { sseConnections } = useSelector((state: RootStateOrAny) => {
		return state?.GlobalReducer
	})

	const currentConnectionIndex = sseConnections.findIndex(
		(connection: any) => connection?.url === url
	)
	const currentConnection =
		currentConnectionIndex !== -1
			? sseConnections[currentConnectionIndex]
			: null

	const establishedSSE = () => {
		if (!currentConnection) {
			let newEventSource: EventSource | any = new EventSource(url)

			newEventSource.onopen = () => {
				dispatch(setupSSEConnection(newEventSource))
				reconnectAttemptsRef.current = 0
				time[url] = Date.now()
			}

			newEventSource.onmessage = (event: any) => {
				const closedEvent = event.data === CLOSED

				if (closedEvent) {
					currentConnection?.close()
					newEventSource?.close()
					dispatch(closeSSEConnection(url))
					return
				}

				time[url] = Date.now()

				if (event.data === PING) {
					return
				}

				const notification = JSON.parse(event.data)
				getNotifications(notification)
			}

			newEventSource.onerror = () => {
				let dateNow = Date.now() - 60 * 1000

				currentConnection?.close()
				newEventSource?.close()
				dispatch(closeSSEConnection(url))
				time[url] = dateNow
			}

			newEventSource.onclose = () => {
				dispatch(closeSSEConnection(url))
			}
		} else {
			currentConnection?.close()
			dispatch(closeSSEConnection(url))
		}
	}

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				if (!currentConnection) {
					establishedSSE()
				}
			} else {
				if (!!currentConnection) {
					currentConnection.close()
					dispatch(closeSSEConnection(url))
				}
			}
		}

		document.addEventListener('visibilitychange', handleVisibilityChange)
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange)
		}
	}, [currentConnection])

	useEffect(() => {
		const handleBeforeUnload = () => {
			if (!!currentConnection) {
				clearMessages()
				dispatch(closeSSEConnection(url))
				currentConnection.close()
			}
		}

		window.addEventListener('beforeunload', handleBeforeUnload)
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [])

	useEffect(() => {
		if (!sseFeatureOn) {
			return
		}

		if (!!currentConnection) {
			clearMessages()
		}

		if (
			reconnectAttemptsRef.current === 0 &&
			!currentConnection &&
			!disableHook
		) {
			establishedSSE()
		}

		if (!!currentConnection && (disableHook || isReconnect)) {
			currentConnection.close()
			dispatch(closeSSEConnection(url))
			setReconnect(false)
		}

		const checkMessageInterval = setInterval(() => {
			const currentTime = Date.now()
			const timeSinceLastMessage = currentTime - time[url]

			if (
				timeSinceLastMessage > TIME_RECONNECT &&
				reconnectAttemptsRef.current < 1
			) {
				reconnectAttemptsRef.current += 1
				establishedSSE()
			}

			if (reconnectAttemptsRef.current === 1) {
				failedConnection()
				currentConnection?.close()
				dispatch(closeSSEConnection(url))
				clearInterval(checkMessageInterval)
			}
		}, TIME_INTERVAL)

		return () => {
			clearInterval(checkMessageInterval)

			if (!!currentConnection) {
				clearMessages()
				dispatch(closeSSEConnection(url))
				currentConnection.close()
			}
		}
	}, [
		url,
		currentConnection,
		isReconnect,
		userId,
		projectId,
		disableHook,
		sseFeatureOn
	])
}
