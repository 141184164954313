import {
	ADD_MULTIPLE_USER_MATERIALS_BOM_FILE,
	ADD_MULTIPLE_USER_MATERIALS_BOM_LOADING,
	ADD_MULTIPLE_USER_MATERIALS_BOM_POPUP,
	NEW_ITEM_ADD_CALLED,
	NEW_ITEM_ADD_FAILED,
	NEW_MATERIAL_FORM_LOADED,
	NEW_PRINTER_MATERIAL_ADDED,
	NEW_PRINTER_MATERIAL_UPDATED,
	SETUP_USER_PRINTER_MATERIAL_FORM_SUCCESS,
	USER_MACHINE_COST_FIELDS_SELECTOR_CHANGED,
	USER_MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED,
	USER_MATERIALS_DELETE_CANCELED,
	USER_MATERIALS_DELETE_CLICKED,
	USER_MATERIALS_MATERIAL_ADD_FAILED,
	USER_MATERIALS_MATERIAL_ADDED,
	USER_MATERIALS_MATERIAL_ADDED_TO_USER,
	USER_MATERIALS_MATERIAL_REMOVED,
	USER_MATERIALS_MATERIAL_SUBMITED,
	USER_MATERIALS_MATERIAL_TYPE_CHANGED,
	USER_MATERIALS_RECIEVED,
	USER_MATERIALS_RESET_DATA,
	USER_MATERIALS_USER_ID_SELECTED,
	USER_MATERIALS_USER_MATERIALS_FORMATED,
	USER_PRINTER_ADDED,
	USER_PRINTER_INITIAL_VALUES_HAD_SET,
	USER_PRINTER_MATERIAL_REMOVED_FROM_USER,
	USER_PRINTER_NEW_COMPANY_SELECTED,
	USER_PRINTERS_ADDED,
	USER_PRINTERS_MATERIAL_REMOVED,
	USER_PRINTERS_RECIEVED,
	USER_PRINTERS_USER_ID_SELECTED
} from '../../../../global actions/types'
import { initialPrinterState, OTHER } from './constants'
import { initialPrinterMaterialState } from './NewAMMaterial/constants'

const INITIAL_STATE = {
	userMaterials: [],
	userPrinters: [],
	userPrinterMaterials: [],
	userMaterialsLoading: false,
	userId: 0,
	materialAdded: false,
	showDeleteAlert: false,
	itemToDelete: null,
	materialCategoriesToShow: [],
	printerMaterials: [],
	printers: [],
	PMCategoriesAverageCO2: [],
	selectedNewPrinterMaterial: initialPrinterMaterialState,
	allPrintersCompanies: {},
	allPMCategories: [],
	allPMSubCategories: [],
	addingNewItem: false,
	printerMaterialAdded: false,
	printerAdded: false,
	isNewCompanyDisabled: true,
	onMachineCostSelector: null,
	maintenanceMachineSelectorsValueNewPrinter: 1,
	onMachineCostValue: 1,
	selectedNewPrinter: initialPrinterState,
	isOpenMaterialsNamePopup: false,
	BOMMaterialsFile: null,
	isMaterialsNameLoading: false,
	fileInputKey: Date.now()
}

const customizeUserMaterialsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case USER_MATERIALS_RESET_DATA:
			return {
				...state,
				...INITIAL_STATE
			}
		case USER_MATERIALS_MATERIAL_SUBMITED:
			return {
				...state,
				userMaterialsLoading: true
			}
		case USER_MATERIALS_MATERIAL_ADDED_TO_USER:
			return {
				...state,
				userMaterials: action.payload
			}
		case USER_MATERIALS_MATERIAL_ADDED:
			return {
				...state,
				userMaterialsLoading: false,
				materialAdded: true,
				materialCategoriesToShow: []
			}
		case USER_PRINTERS_ADDED:
			return {
				...state,
				addingNewItem: false,
				printerAdded: true,
				materialCategoriesToShow: []
			}
		case USER_MATERIALS_MATERIAL_ADD_FAILED:
			return {
				...state,
				userMaterialsLoading: false
			}
		case USER_MATERIALS_MATERIAL_REMOVED:
			return {
				...state,
				userMaterials: action.payload
			}
		case USER_PRINTERS_MATERIAL_REMOVED:
			return {
				...state,
				userPrinters: action.payload
			}
		case USER_MATERIALS_RECIEVED:
			return {
				...state,
				userMaterials: action.payload.userMaterials,
				userPrinters: action.payload.userPrinters,
				userPrinterMaterials: action.payload.userPrinterMaterials,
				materialAdded: false,
				printerMaterialAdded: false,
				printerAdded: false
			}
		case USER_PRINTERS_RECIEVED:
			return {
				...state,
				userPrinters: action.payload,
				printerAdded: false
			}
		case USER_MATERIALS_USER_ID_SELECTED:
			return {
				...state,
				userId: action.payload
			}
		case USER_PRINTERS_USER_ID_SELECTED:
			return {
				...state,
				userId: action.payload
			}
		case USER_MATERIALS_USER_MATERIALS_FORMATED:
			return {
				...state,
				userMaterials: [],
				userPrinters: [],
				userPrinterMaterials: [],
				userId: 0,
				materialAdded: false,
				printerAdded: false,
				printerMaterialAdded: false
			}
		case NEW_MATERIAL_FORM_LOADED:
			return {
				...state,
				materialAdded: false
			}
		case USER_MATERIALS_DELETE_CLICKED:
			return {
				...state,
				showDeleteAlert: true,
				itemToDelete: action.payload
			}
		case USER_MATERIALS_DELETE_CANCELED:
			return {
				...state,
				showDeleteAlert: false,
				itemToDelete: null
			}
		case USER_MATERIALS_MATERIAL_TYPE_CHANGED:
			const { selectedType, materialCategories } = action.payload
			return {
				...state,
				materialCategoriesToShow: materialCategories.filter(
					materialCategory => materialCategory.type === selectedType
				)
			}
		case SETUP_USER_PRINTER_MATERIAL_FORM_SUCCESS: {
			const {
				allPrintersCompanies,
				allCategories,
				allSubCategories,
				categoriesAverageCO2
			} = action.payload
			return {
				...state,
				allPrintersCompanies,
				allPMCategories: allCategories,
				allPMSubCategories: allSubCategories,
				PMCategoriesAverageCO2: categoriesAverageCO2,
				printerMaterialAdded: false
			}
		}
		case NEW_ITEM_ADD_CALLED: {
			return {
				...state,
				addingNewItem: true
			}
		}
		case NEW_ITEM_ADD_FAILED:
			return {
				...state,
				addingNewItem: false
			}

		case NEW_PRINTER_MATERIAL_UPDATED: {
			const { printerMaterialUpdated } = action.payload
			const updatedUserPrinterMaterials = [...state.userPrinterMaterials]
			const index = state.userPrinterMaterials.findIndex(
				material => material.id === printerMaterialUpdated.id
			)

			if (index !== -1) {
				updatedUserPrinterMaterials[index] = printerMaterialUpdated
			}

			return {
				...state,
				material: printerMaterialUpdated,
				userPrinterMaterials: updatedUserPrinterMaterials,
				printerMaterialAdded: true
			}
		}

		case NEW_PRINTER_MATERIAL_ADDED:
			const response = action.payload
			return {
				...state,
				printerMaterials: [
					...state.printerMaterials,
					response.printerMaterialAdded
				],
				printerMaterialAdded: true,
				addingNewItem: false
			}

		case USER_PRINTER_NEW_COMPANY_SELECTED:
			let isNewCompanyDisabled = true
			if (action.payload === OTHER) {
				isNewCompanyDisabled = false
			}
			return {
				...state,
				isNewCompanyDisabled
			}

		case USER_MACHINE_COST_FIELDS_SELECTOR_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				onMachineCostValue: value
			}
		}

		case USER_MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED:
			const { value } = action.payload
			return {
				...state,
				maintenanceMachineSelectorsValueNewPrinter: value
			}

		case USER_PRINTER_INITIAL_VALUES_HAD_SET:
			return {
				...state,
				selectedNewPrinter: action.payload
			}

		case USER_PRINTER_ADDED:
			return {
				...state,
				printers: action.payload
			}

		case ADD_MULTIPLE_USER_MATERIALS_BOM_FILE: {
			return {
				...state,
				BOMMaterialsFile: action.payload
			}
		}

		case ADD_MULTIPLE_USER_MATERIALS_BOM_POPUP: {
			return {
				...state,
				isOpenMaterialsNamePopup: action.payload
			}
		}

		case ADD_MULTIPLE_USER_MATERIALS_BOM_LOADING: {
			return {
				...state,
				isMaterialsNameLoading: action.payload,
				...(!action.payload && { isOpenMaterialsNamePopup: false })
			}
		}

		case USER_PRINTER_MATERIAL_REMOVED_FROM_USER: {
			const { printerMaterial } = action.payload

			const filteredUserPrinterMaterials = state.userPrinterMaterials.filter(
				({ id: existId }) => !printerMaterial.some(({ id }) => existId === id)
			)

			return {
				...state,
				userPrinterMaterials: filteredUserPrinterMaterials
			}
		}

		default:
			return state
	}
}

export default customizeUserMaterialsReducer
