import React, { FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import cx from 'classnames'

import { ConfigurationResultTypes } from '../ConfigurationResultTypes'
import { getIntegrationProviders } from '../SolutionAnalysisService'
import getExportMenu from './ExportMenu'
import DropDownListMenu from 'Scenes/Components/CastorDDLMenu/DropDownListMenu'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import Loader from 'Scenes/Loader/Loader'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { LocationPartsParams } from 'Services/models/IPart'
import { IUserProvider } from 'Services/models/IUserProvider'
import { getString } from 'Services/Strings/StringService'
import OverrideComponentHOC from 'themes/OverrideComponentHOC'

import { ReactComponent as PDF } from 'assets/img/svg/pdf.svg'

interface IProps {
	setShowModal: Function
	enableSolutionButtons: boolean
	solution: any
	quantity: number
	configurationId: number
	userProviders: IUserProvider[]
	sendToLoader: boolean
	isNewDesign?: boolean
	onGetAQClick: Function
	on3dExportClick: Function
	onPrintingOptionClick: Function
}

const PDFIcon: any = PDF

const SolutionAnalysisHeaderGetAQ: FC<IProps> = ({
	enableSolutionButtons,
	solution,
	quantity,
	configurationId,
	userProviders,
	sendToLoader,
	onGetAQClick,
	on3dExportClick,
	onPrintingOptionClick,
	setShowModal,
	isNewDesign = false
}) => {
	const configuration = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer?.states[configurationId]
	})
	const [showSendTo, setShowSendTo] = useState(false)
	const [integrationProviderOptions, setIntegrationProviderOptions]: any =
		useState([])
	const location = useLocation<LocationPartsParams>()
	const isWeightReductionConfiguration = location?.state?.isWeightReduction
	const { project: { isCastorTwo = false } = {} } = useSelector(
		(state: RootStateOrAny) => {
			return state?.MainPartAnalysisReducer
		}
	)
	useEffect(() => {
		if (userProviders?.length > 0) {
			setIntegrationProviderOptions(
				getIntegrationProviders(
					userProviders,
					solution?.printer?.id,
					solution?.printerMaterial?.id,
					isWeightReductionConfiguration,
					onPrintingOptionClick,
					on3dExportClick
				)
			)
		}
	}, [userProviders, solution, onPrintingOptionClick, on3dExportClick])

	useEffect(() => {
		setShowSendTo(false)
	}, [enableSolutionButtons])

	const [isOnPrem, part, cluster, configurationResult] = useSelector(
		(state: RootStateOrAny) => [
			state.GlobalReducer.isOnPrem,
			state.MainPartAnalysisReducer.part,
			state.MainPartAnalysisReducer.cluster,
			state.SolutionAnalysisReducer?.states[configurationId]
				?.configurationResult || {}
		]
	)

	const isCluster = cluster || configuration?.cluster
	const isDrawing = part?.isDrawing
	const isMetadata = part?.formatType === METADATA

	const printingOptionsOn = Feature.isFeatureOn(
		FeatureComponentId.EXPORT_OPTIONS
	)
	const exportToPdfOn = Feature.isFeatureOn(FeatureComponentId.EXPORT_TO_PDF)
	const disabledExportToPdfOn = !Feature.isFeatureActive(
		FeatureComponentId.EXPORT_TO_PDF
	)
	const btnGetAQElementId = `part-analysis-get-a-quote-button_${configurationId}`
	const btn3DExportElementId = `part-analysis-3d-export-button_${configurationId}`
	const showGetQAEnabled = enableSolutionButtons && solution
	const printingOptionIsOn =
		printingOptionsOn && integrationProviderOptions?.length > 0

	const onCloseSendToMenu = () => {
		setShowSendTo(false)
	}

	const exportPdfButton = exportToPdfOn ? (
		<DetailsPopup
			isHover={disabledExportToPdfOn}
			data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
			popperDirection="top"
			popperClassName="info-box-wrapper details-popup--contact-us"
			popperContactClassName="info-box-data"
			managerClassName="order-5"
		>
			<div
				data-qa="data-qa-project-generate-report"
				color="transparent"
				onClick={(e: React.MouseEvent) => {
					e.stopPropagation()
					setShowModal(true)
				}}
				className={cx(
					'part-analysis-button-text-transform with-border order-5',
					{
						enabled:
							showGetQAEnabled && !sendToLoader && !disabledExportToPdfOn,
						disabled: disabledExportToPdfOn
					}
				)}
			>
				<Button
					className="solution-analysis-header-text upper"
					color="transparent"
				>
					{isNewDesign && (
						<div className="pdf-icon">
							<PDFIcon />
						</div>
					)}
					{getString('EXPORT_TO_PDF')}
				</Button>
			</div>
		</DetailsPopup>
	) : (
		<></>
	)

	const getMenu = () =>
		getExportMenu(
			printingOptionIsOn,
			integrationProviderOptions,
			onGetAQClick,
			on3dExportClick,
			btnGetAQElementId,
			solution,
			isOnPrem,
			enableSolutionButtons,
			quantity,
			showGetQAEnabled,
			cluster || configuration?.cluster,
			setShowModal,
			sendToLoader,
			exportPdfButton,
			configurationId,
			isNewDesign,
			disabledExportToPdfOn,
			btn3DExportElementId
		)

	const loaderStyle = {
		color: 'var(--solution-color)',
		display: 'flex'
	}

	if ((isDrawing || isOnPrem) && !isCluster) return exportPdfButton

	const showSendToButton =
		!isCastorTwo && Feature.isFeatureOn(FeatureComponentId.SHOW_SEND_TO_BUTTON)
	let showConfiguration = Feature.isFeatureOn(
		FeatureComponentId.UPDATE_CONFIGURATION
	)
	let disableSendToButton = !Feature.isFeatureActive(
		FeatureComponentId.SHOW_SEND_TO_BUTTON
	)

	if (printingOptionIsOn || !isOnPrem || showConfiguration) {
		return (
			<>
				{!isCluster && isNewDesign && exportPdfButton}
				{!isMetadata && !isOnPrem && showSendToButton && solution && (
					<DetailsPopup
						isHover={disableSendToButton}
						data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
						popperDirection="bottom"
						popperClassName="info-box-wrapper details-popup--contact-us"
						popperContactClassName="info-box-data"
						managerClassName="order-6"
					>
						<Button
							component={'div'}
							data-qa="data-qa-project-send-to"
							id={btnGetAQElementId}
							color="transparent"
							onClick={(e: any) => {
								e.stopPropagation()
								setShowSendTo(!showSendTo)
							}}
							className={cx(
								'send-to-text-button part-analysis-button-text-transform with-border solution-analysis-header-text order-6',
								{
									enabled:
										showGetQAEnabled &&
										!sendToLoader &&
										!disableSendToButton &&
										!disabledExportToPdfOn,
									disabled:
										!enableSolutionButtons ||
										!solution ||
										sendToLoader ||
										disableSendToButton ||
										disabledExportToPdfOn
								}
							)}
						>
							{sendToLoader ? (
								<Loader
									load={sendToLoader}
									size={20}
									message=""
									showFlex={false}
									styles={loaderStyle}
								/>
							) : (
								<IconFactory
									iconName="getAQ"
									className="solution-analysis-header-text-icon"
								/>
							)}
							<div className="solution-analysis-header-text-text send-to-text">
								{getString('SEND_TO_INTEGRATION_BUTTON')}
							</div>
							<DropDownListMenu
								options={getMenu()}
								show={showSendTo}
								parentId={btnGetAQElementId}
								onClose={onCloseSendToMenu}
							/>
						</Button>
					</DetailsPopup>
				)}
			</>
		)
	}

	return <div />
}

export default memo(
	OverrideComponentHOC(
		WithFeatureToggleHOC(
			SolutionAnalysisHeaderGetAQ,
			FeatureComponentId.GET_A_Q
		)
	)
)
