import {
	ADMIN_PROJECT_FETCHED,
	HANDLE_NOTIFICATION,
	PART_ADMIN_ALL_CLUSTER_PUBLISHED,
	PART_ADMIN_CHECKBOX_SELECTED,
	PART_ADMIN_CLUSTER_PUBLISHED,
	PART_ADMIN_CLUSTER_REJECTED,
	PART_ADMIN_CLUSTER_REMOVED,
	PART_ADMIN_DELETE_PARTS,
	PART_ADMIN_DELETE_PARTS_SUCCESS,
	PART_ADMIN_DOWNLOAD_STL_COMPLETE,
	PART_ADMIN_DOWNLOAD_STL_SUCCESS,
	PART_ADMIN_GET_PARTS,
	PART_ADMIN_GET_PARTS_SUCCESS,
	PART_ADMIN_MERGE_PARTS,
	PART_ADMIN_MERGE_PARTS_SUCCESS,
	PART_ADMIN_MORE_CLUSTER_PARTS_FETCHED,
	PART_ADMIN_MORE_CLUSTERS_FETCHED,
	PART_ADMIN_MORE_PARTS_FETCHED,
	PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
	PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED,
	PART_ADMIN_PUBLISH_CLUSTER_TOGGLED,
	PART_ADMIN_REJECT_CLUSTER_TOGGLED,
	PART_ADMIN_REMOVE_CLUSTER_TOGGLED,
	PART_ADMIN_SELECTED_ACTION,
	PART_ADMIN_SERVER_CALL_FAILED,
	PART_ADMIN_UPDATE_CLUSTER_TOGGLED
} from '../../../../global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../../global actions/types/CastorAlertTypes'
import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import {
	adminCombineParts,
	adminDeleteParts,
	getMoreClustersForProjectAdmin,
	getMorePartsForProjectAdmin,
	machinePartsForProjectAdmin
} from '../../../../Services/Network'
import {
	publishCluster,
	publishClusters,
	rejectCluster,
	removeCluster,
	removeClusterParts
} from '../../../../Services/Network/Admin/ProjectNetwork'
import {
	CLUSTER_STRUCTURE,
	COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
	COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
	CONFIGURATION_CALCULATION_ERROR,
	OK,
	SHOW_NOTIFICATION
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import { AlertType } from '../../../Components/alerts/AlertTypes'
import { actionsENUM } from './actionsEnum'
import { getPartClusterParts } from 'Services/Network/PartAnalysisNetwork'

export const setupAdminPartsPage = projectId => {
	return async dispatch => {
		try {
			dispatch({ type: PART_ADMIN_GET_PARTS })
			const response = await machinePartsForProjectAdmin(projectId)
			const {
				parts,
				clusters,
				clusterStructure,
				projects,
				projectHasMoreParts,
				projectHasMoreClusters,
				allProjectParts
			} = response.data
			const displayComplex = Feature.isFeatureOn(
				FeatureComponentId.PART_COMPLEXITY_SCORE
			)

			dispatch({
				type: PART_ADMIN_GET_PARTS_SUCCESS,
				payload: {
					parts,
					clusters,
					clusterStructure,
					projects,
					displayComplex,
					projectHasMoreParts,
					projectHasMoreClusters,
					allProjectParts
				}
			})
			dispatch({
				type: ADMIN_PROJECT_FETCHED,
				payload: response.data
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: PART_ADMIN_SERVER_CALL_FAILED,
				payload: error.message
			})
		}
	}
}
export const selectAction = actionId => {
	return { type: PART_ADMIN_SELECTED_ACTION, payload: actionId }
}
export const submittedAction = (actionId, projectId, parts) => {
	return dispatch => {
		let serverCallFunc
		let action
		let completeAction
		switch (actionId) {
			case actionsENUM.DELETE_PARTS:
				serverCallFunc = adminDeleteParts
				action = PART_ADMIN_DELETE_PARTS
				completeAction = PART_ADMIN_DELETE_PARTS_SUCCESS
				break
			case actionsENUM.COMBINE_PARTS:
				serverCallFunc = adminCombineParts
				action = PART_ADMIN_MERGE_PARTS
				completeAction = PART_ADMIN_MERGE_PARTS_SUCCESS
				break
			default:
				return
		}

		serverCallFunc(projectId, parts)
			.then(() => {
				return machinePartsForProjectAdmin(projectId)
			})
			.then(response => {
				const displayComplex = Feature.isFeatureOn(
					FeatureComponentId.PART_COMPLEXITY_SCORE
				)
				dispatch({
					type: completeAction,
					payload: {
						parts: response.data.parts,
						projectHasMoreParts: response.data.projectHasMoreParts,
						displayComplex
					}
				})
			})
			.catch(error => {
				console.log(error)
				dispatch({
					type: PART_ADMIN_SERVER_CALL_FAILED,
					payload: parts.length
						? error.message
						: getString('SELECT_PARTS_ERROR_MESSAGE')
				})
			})
		dispatch({ type: action })
	}
}

export const fetchMoreClusterParts = (clusterId, currentClusterPartsCount) => {
	return async dispatch => {
		try {
			const response = await getPartClusterParts(
				clusterId,
				currentClusterPartsCount
			)
			const clusterParts = response?.data?.parts
			dispatch({
				type: PART_ADMIN_MORE_CLUSTER_PARTS_FETCHED,
				payload: { clusterParts, clusterId }
			})
		} catch (error) {
			console.error('error while fetching more cluster parts', error)
		}
	}
}

export const downloadSTL = part => ({
	type: PART_ADMIN_DOWNLOAD_STL_SUCCESS,
	payload: part.fileURL
})

export const downloadedSTLComplete = () => {
	return { type: PART_ADMIN_DOWNLOAD_STL_COMPLETE }
}

export const checkboxSelected = part => {
	return { type: PART_ADMIN_CHECKBOX_SELECTED, payload: part }
}

export const publishAllClusters = projectId => {
	return async dispatch => {
		try {
			dispatch({ type: PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED })
			const response = await publishClusters(projectId)
			const { clusters, projectClusterStatus } = response.data
			dispatch({
				type: PART_ADMIN_ALL_CLUSTER_PUBLISHED,
				payload: { clusters }
			})
			dispatch({
				type: PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
				payload: { projectClusterStatus }
			})
		} catch (error) {
			console.error(error.message)
			dispatch({ type: PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED })
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const getMoreProjectParts = (projectId, currentPartCount) => {
	return async dispatch => {
		try {
			const response = await getMorePartsForProjectAdmin(
				projectId,
				currentPartCount
			)
			const { parts, projectHasMoreParts } = response.data
			const displayComplex = Feature.isFeatureOn(
				FeatureComponentId.PART_COMPLEXITY_SCORE
			)
			dispatch({
				type: PART_ADMIN_MORE_PARTS_FETCHED,
				payload: { parts, projectHasMoreParts, displayComplex }
			})
		} catch (error) {
			console.error(error.message)
		}
	}
}

export const getMoreProjectClusters = (projectId, currentPartCount) => {
	return async dispatch => {
		try {
			const response = await getMoreClustersForProjectAdmin(
				projectId,
				currentPartCount
			)
			const { clusters, projectHasMoreClusters } = response.data
			dispatch({
				type: PART_ADMIN_MORE_CLUSTERS_FETCHED,
				payload: { clusters, projectHasMoreClusters }
			})
		} catch (error) {
			console.error(error.message)
		}
	}
}

export const onPublishClusterClick = clusterId => {
	return async dispatch => {
		try {
			dispatch({
				type: PART_ADMIN_PUBLISH_CLUSTER_TOGGLED,
				payload: { clusterId }
			})
			const response = await publishCluster(clusterId)
			const { clusters, projectClusterStatus } = response.data
			dispatch({
				type: PART_ADMIN_CLUSTER_PUBLISHED,
				payload: { clusters, clusterId }
			})
			dispatch({
				type: PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
				payload: { projectClusterStatus }
			})
		} catch (error) {
			console.error(error.message)
			dispatch({
				type: PART_ADMIN_PUBLISH_CLUSTER_TOGGLED,
				payload: { clusterId }
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onRejectClusterClick = clusterId => {
	return async dispatch => {
		try {
			dispatch({
				type: PART_ADMIN_REJECT_CLUSTER_TOGGLED,
				payload: { clusterId }
			})
			const response = await rejectCluster(clusterId)
			const { clusters, projectClusterStatus } = response.data
			dispatch({
				type: PART_ADMIN_CLUSTER_REJECTED,
				payload: { clusters, clusterId }
			})
			dispatch({
				type: PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
				payload: { projectClusterStatus }
			})
		} catch (error) {
			console.error(error.message)
			dispatch({
				type: PART_ADMIN_REJECT_CLUSTER_TOGGLED,
				payload: { clusterId }
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onRemoveClusterClickApprove = async (clusterId, dispatch) => {
	try {
		dispatch({
			type: PART_ADMIN_REMOVE_CLUSTER_TOGGLED,
			payload: { clusterId }
		})
		const response = await removeCluster(clusterId)
		const { clusters, projectClusterStatus } = response.data
		dispatch({
			type: PART_ADMIN_CLUSTER_REMOVED,
			payload: { clusters, clusterId }
		})
		dispatch({
			type: PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
			payload: { projectClusterStatus }
		})
	} catch (error) {
		console.error(error.message)
		dispatch({
			type: PART_ADMIN_REMOVE_CLUSTER_TOGGLED,
			payload: { clusterId }
		})
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: CONFIGURATION_CALCULATION_ERROR
			}
		})
	}
}

export const onRemoveClusterClick = clusterId => {
	return async dispatch => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('ARE_YOU_SURE_DELETE'),
				headerTitle: getString('DELETE_ITEM'),
				// showCancel: false,
				onConfirm: () => {
					dispatch({
						type: ALERT_POPUP_CANCELED
					})
					onRemoveClusterClickApprove(clusterId, dispatch)
				},
				confirmText: OK
			}
		})
	}
}

export const onUpdateClusterClick = (clusterId, transformationMatrixHashs) => {
	return async dispatch => {
		try {
			dispatch({
				type: PART_ADMIN_UPDATE_CLUSTER_TOGGLED
			})
			await removeClusterParts(clusterId, transformationMatrixHashs)
			dispatch({
				type: PART_ADMIN_UPDATE_CLUSTER_TOGGLED
			})
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
					headerTitle: COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
					showCancel: false,
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		} catch (error) {
			console.error(error.message)
			dispatch({
				type: PART_ADMIN_UPDATE_CLUSTER_TOGGLED
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onClusterStructureClick = clusterStructure => {
	return dispatch => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: clusterStructure,
				headerTitle: CLUSTER_STRUCTURE,
				showCancel: false,
				alertType: AlertType.SUCCESS,
				onConfirm: () => {
					dispatch({
						type: ALERT_POPUP_CANCELED
					})
				},
				confirmText: OK
			}
		})
	}
}
