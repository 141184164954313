import React, { FC, memo } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import cx from 'classnames'
import { sortBy } from 'lodash'

import * as SolutionAnalysisActions from '../../SolutionAnalysisActions'
import {
	fetchMoreClusterParts,
	incrementTourStep
} from '../../../MainPartAnalysisActions'
import { MainPartAnalysisInitialState } from '../../../MainPartAnalysisReducer'
import { ConfigurationResultTypes } from '../../ConfigurationResultTypes'
import { useShouldShowGeometryReviewAndFixButton } from '../../GeometryAnalysisReviewAndFixes/GeometryAnalysisReviewAndFixesService'
import { SolutionAnalysisInitialState } from '../../SolutionAnalysisReducer'
import { useSelectedTabOrder } from './SolutionAnalysisTabsService'
import AnalysisResultsTab from './Tabs/AnalysisResultsTab'
import ClusterPartsTab from './Tabs/ClusterPartsTab'
import CostComparisonTab from './Tabs/CostComparisonTab'
import CostSummaryTab from './Tabs/CostSummaryTab'
import LeadTimeTab from './Tabs/LeadTimeTab'
import MaterialComparisonTab from './Tabs/MaterialComparisonTab'
import MechanicalAnalysisTab from './Tabs/MechanicalAnalysisTab'
import ChallengeTab from './Tabs/СhallengeTab'
import { ActionWithPayload } from 'global actions/ActionModels'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import NavScrollablePills from 'Scenes/Components/thirdParty/CreativeTim/components/NavPills/NavScrollablePills'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { onPartClick } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import { IStateExtractorState } from 'Services/HOR/InstanceStateExtractor'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { SuggestionType } from 'Services/models/IConfiguration'
import { LocationPartsParams, Part } from 'Services/models/IPart'
import { IUser } from 'Services/models/IUser'
import {
	PART_ANALYSIS_TABS_TITLE_ANALYSIS_RESULTS,
	PART_ANALYSIS_TABS_TITLE_CLUSTER_PARTS,
	PART_ANALYSIS_TABS_TITLE_COST_COMPARTION,
	PART_ANALYSIS_TABS_TITLE_COST_SUMMARY,
	PART_ANALYSIS_TABS_TITLE_LEAD_TIME,
	PART_ANALYSIS_TABS_TITLE_MATERIAL_COMPARTION,
	SUGGESTION_CHALLENGE
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import '../../SolutionAnalysisTopDetails/ResultDetail.scss'
import './SolutionAnalysisTabs.scss'

const { tabOrder } = getTheme()
const NavScrollablePillsTSX: any = NavScrollablePills

interface IProps {
	part: Part
	configuration: any
	cluster: any
}

interface IBenefit {
	createdAt: string
	description: string
	difference: number
	id: number
	name: string
	partSolution: number
	score: number
	threeDCost: number
	traditionalCost: number
	type: string
	updatedAt: string
}

interface IReduxProps {
	materialComparisonRows: any[][]
	analysisResultsRows: any[][]
	initialMaterial: any
	solution: any
	features: Feature[]
	user: IUser
	showAnalysis: boolean
	showBenefits: boolean
	disableTabUpdate: boolean
	costBenefit?: IBenefit
	timeBenefit?: IBenefit
	initialSelectedTab: number
	showAllMaterialCompars: boolean
	simpleConfiguration: boolean
	customConfiguration: boolean
	currentStepTargetId: string
	tourStepIndex: number
	manufacturingMethod: string
	resultBody: string
	resultTitle: string
	clusterParts: Part[] | null
	isAmOriginalMaterial: boolean
	suggestionType: SuggestionType | ''
	onShowAllComparsClick: (id: number) => ActionWithPayload<any>
	incrementTourStep: (
		...incrementTourStepParams: Parameters<typeof incrementTourStep>
	) => any
	fetchMoreClusterParts: (
		...fetchMoreClusterPartsParams: Parameters<typeof fetchMoreClusterParts>
	) => any
	onGeometryAnalysisReviewAndFixesClick: (
		...onGeometryAnalysisReviewAndFixesClickParams: Parameters<
			typeof SolutionAnalysisActions.onGeometryAnalysisReviewAndFixesClick
		>
	) => ActionWithPayload<any>
	onRetrieveResultClick: (
		...onRetrieveResultClickParams: Parameters<
			typeof SolutionAnalysisActions.onRetrieveResultClick
		>
	) => ActionWithPayload<any>
	onConfigureClick: (
		...onConfigureClickParams: Parameters<
			typeof SolutionAnalysisActions.onConfigureClick
		>
	) => ActionWithPayload<any>
	onPartClick: (...onPartClickParams: Parameters<typeof onPartClick>) => any
}

interface IReduxStore {
	SolutionAnalysisReducer: IStateExtractorState
	user: IUser
	MainPartAnalysisReducer: MainPartAnalysisInitialState
}

const benefitsDetails = (benefit: any) => {
	if (benefit && benefit.description) {
		return (
			<div className="solution-analysis-header-benefits-data">
				<IconFactory
					iconName={benefit?.type?.replace('-saving', '')}
					className="solution-analysis-header-benefits-data-icon"
				/>
				<span className="solution-analysis-header-benefits-data-title">
					{benefit.name}{' '}
				</span>
				{benefit.description}
			</div>
		)
	}
}

const renderBenefitIcon = (benefit: any) => {
	if (!benefit) {
		return <div />
	}

	return (
		<IconFactory
			iconName={benefit?.type?.replace('-saving', '')}
			className="solution-tab-benefit-icon"
		/>
	)
}

const renderCostTabExtraData = (
	benefit: any,
	solution: any,
	showBenefits: boolean,
	isFeaOn: boolean = true
) => {
	if (!solution || !solution.id || !showBenefits) {
		return <div></div>
	}
	return (
		<DetailsPopup
			isHover={true}
			data={benefitsDetails(benefit)}
			popperDirection={'bottom-end'}
			popperClassName={cx('solution-tab-benefit-popper-message', {
				'auto-width': !isFeaOn
			})}
		>
			{renderBenefitIcon(benefit)}
		</DetailsPopup>
	)
}

const SolutionAnalysisTabs: FC<IProps & IReduxProps> = ({
	materialComparisonRows,
	analysisResultsRows,
	part,
	cluster,
	clusterParts,
	configuration,
	solution,
	showAnalysis,
	costBenefit,
	timeBenefit,
	initialSelectedTab,
	showAllMaterialCompars,
	simpleConfiguration,
	customConfiguration,
	showBenefits,
	currentStepTargetId,
	tourStepIndex,
	manufacturingMethod,
	resultBody,
	resultTitle,
	onShowAllComparsClick,
	incrementTourStep,
	fetchMoreClusterParts,
	onPartClick,
	onGeometryAnalysisReviewAndFixesClick,
	onConfigureClick,
	onRetrieveResultClick,
	disableTabUpdate,
	user,
	isAmOriginalMaterial,
	suggestionType
}) => {
	const shouldShowGeometryReviewAndFixButton =
		useShouldShowGeometryReviewAndFixButton(cluster)
	const elementIds = {
		materialComparison: `part-analysis-tabs-title-material-comparison_${configuration.id}`,
		analysisResults: `part-analysis-tabs-title-analysis-results_${configuration.id}`,
		costComparison: `part-analysis-tabs-title-cost-comparison_${configuration.id}`,
		mechanicalAnalysis: `part-analysis-tabs-title-mechanical-analysis_${configuration.id}`,
		costSummary: `part-analysis-tabs-title-cost-summary_${configuration.id}`,
		challenges: `part-analysis-tabs-title-challenges_${configuration.id}`
	}
	const location = useLocation<LocationPartsParams>()
	const isWeightReductionConfiguration =
		location?.state?.isWeightReduction ||
		configuration.resultType === ConfigurationResultTypes.WeightReduction
	const retrieveResultMode = Feature.isFeatureOn(
		FeatureComponentId.RETRIEVE_RESULTE_UNPRINTABLE
	)
	const isChallenges =
		configuration.resultType === ConfigurationResultTypes.Challenges

	const newTab = Feature.isFeatureOn(FeatureComponentId.OPEN_LINKS_IN_NEW_TAB)
	const isFeaOn =
		Feature.isFeatureOn(FeatureComponentId.FEA) && !part?.isDrawing
	const updateTourStep = (id: string) => {
		if (currentStepTargetId === `#${id}`) {
			incrementTourStep(tourStepIndex)
		}
	}

	const renderTabsArray = () => {
		const costAnalysisTooltip = Feature.isFeatureOn(
			FeatureComponentId.COST_ANALYSIS_TOOLTIP
		)
		const leadTimeTooltip = Feature.isFeatureOn(
			FeatureComponentId.LEAD_TIME_TOOLTIP
		)

		const tabs: any = []

		const disableLeadtime = !Feature.isFeatureActive(
			FeatureComponentId.LEAD_TIME_TAB
		)

		//add <Summary> tab
		if (
			// !simpleConfiguration &&
			configuration.id > 0
		) {
			if (Feature.isFeatureOn(FeatureComponentId.SUMMARY_COST_TAB)) {
				tabs.push({
					index: tabOrder.summary,
					tabButton: (
						<div
							id={elementIds.costSummary}
							className="solution-tab--cost-title"
						>
							{PART_ANALYSIS_TABS_TITLE_COST_SUMMARY}
						</div>
					),
					tabContent: (
						<CostSummaryTab
							solution={solution}
							configurationResultBody={resultBody}
							manufacturingMethodName={manufacturingMethod}
							configuration={configuration}
							resultTitle={resultTitle}
							showAnalysis={showAnalysis}
							onConfigureClick={() =>
								onConfigureClick(configuration.id, undefined, false)
							}
							onRetrieveResultClick={() =>
								onRetrieveResultClick(configuration.id)
							}
						/>
					)
				})
			}
		}
		//add <Cost> tab
		if (
			(part || cluster) &&
			(showAnalysis || !retrieveResultMode) &&
			solution &&
			solution.id
		) {
			const partDetails = part || cluster

			//add Cost Analysis tab
			tabs.push.apply(tabs, [
				{
					index: tabOrder.costAnalysis,
					tabButton: (
						<div
							data-qa={'data-qa-header-cost-analysis'}
							className="solution-tab--cost-title"
							id={elementIds.costComparison}
							onClick={() => updateTourStep(elementIds.costComparison)}
						>
							<div>{PART_ANALYSIS_TABS_TITLE_COST_COMPARTION}</div>
							{costAnalysisTooltip && !isAmOriginalMaterial && (
								<div className="block-with-the-benefit">
									{renderCostTabExtraData(costBenefit, solution, showBenefits)}
								</div>
							)}
						</div>
					),
					tabContent: (
						<CostComparisonTab
							part={partDetails}
							solution={solution}
							configuration={configuration}
						/>
					)
				}
			])

			const solLeadTimeDetails =
				solution?.leadTimeDetails ||
				configuration?.leadTimeResults?.mainLeadTimeDetails

			if (
				Feature.isFeatureOn(FeatureComponentId.LEAD_TIME_TAB) &&
				solLeadTimeDetails?.firstLeadTime &&
				!!configuration.costResults &&
				!isChallenges
			) {
				tabs.push({
					index: tabOrder.leadTime,
					tabButton: (
						<div
							data-qa={'data-qa-header-lead-time'}
							id={elementIds.costSummary}
							className="solution-tab--cost-title lead-title"
						>
							<DetailsPopup
								isHover={disableLeadtime || !configuration.isSpecifiedQuantity}
								data={
									!configuration.isSpecifiedQuantity
										? getString('LEAD_TIME_NOT_AVAILABLE')
										: getString('LIGHT_USER_BUTTONS_INFORMATION')
								}
								popperDirection="bottom-end"
								popperClassName={cx('lead-time-message-wrapper', {
									'details-popup--contact-us': configuration.isSpecifiedQuantity
								})}
							>
								<div>{PART_ANALYSIS_TABS_TITLE_LEAD_TIME}</div>
							</DetailsPopup>
							{leadTimeTooltip && !isAmOriginalMaterial && (
								<div className="block-with-the-benefit">
									{renderCostTabExtraData(timeBenefit, solution, showBenefits)}
								</div>
							)}
						</div>
					),
					tabContent: (
						<LeadTimeTab
							part={partDetails}
							solution={solution}
							configuration={configuration}
							timeBenefit={timeBenefit}
						/>
					)
				})
			}
			if (
				part &&
				!part.clusterId &&
				isFeaOn &&
				!isWeightReductionConfiguration &&
				partDetails?.formatType !== METADATA &&
				configuration.suggestionType === SuggestionType.AM &&
				configuration.resultType !== ConfigurationResultTypes.PartConsolidation
			) {
				//add Fea Analysis tab
				tabs.push.apply(tabs, [
					{
						index: tabOrder.feaAnalysis,
						tabButton: (
							<div
								data-qa={'data-qa-header-stress-analysis'}
								className="solution-tab--cost-title"
								id={elementIds.mechanicalAnalysis}
								onClick={() => updateTourStep(elementIds.mechanicalAnalysis)}
							>
								{getString('PART_ANALYSIS_TABS_TITLE_MECHANICAL_ANALYSIS')}
							</div>
						),
						tabContent: (
							<MechanicalAnalysisTab
								part={partDetails}
								configuration={configuration}
							/>
						)
					}
				])
			}
		}

		if (configuration.suggestionType === SuggestionType.AM) {
			//add <Geometry Analysis> tab
			tabs.push({
				index: tabOrder.geometryAnalysis,
				tabButton: (
					<div
						data-qa={'data-qa-header-geometry-analysis'}
						className="solution-tab--cost-title"
						id={elementIds.analysisResults}
						onClick={() => updateTourStep(elementIds.analysisResults)}
					>
						{PART_ANALYSIS_TABS_TITLE_ANALYSIS_RESULTS}
					</div>
				),
				tabContent: (
					<AnalysisResultsTab
						configurationResult={configuration?.result}
						part={part}
						meshUploadURL={part?.meshUploadURL}
						analysisResultsRows={analysisResultsRows}
						shouldShowButton={
							shouldShowGeometryReviewAndFixButton &&
							configuration.resultType !==
								ConfigurationResultTypes.PartConsolidation
						}
						onGeometryAnalysisReviewAndFixesClick={() =>
							onGeometryAnalysisReviewAndFixesClick(configuration.id)
						}
					/>
				)
			})
		}

		//add <Material> tab
		if (
			// !simpleConfiguration &&
			(showAnalysis || !retrieveResultMode) &&
			materialComparisonRows &&
			materialComparisonRows.length
		) {
			//add material analysis tab
			tabs.push({
				index: tabOrder.material,
				tabButton: (
					<div
						data-qa={'data-qa-header-material-analysis'}
						className="solution-tab--cost-title"
						id={elementIds.materialComparison}
						onClick={() => updateTourStep(elementIds.materialComparison)}
					>
						{PART_ANALYSIS_TABS_TITLE_MATERIAL_COMPARTION}
					</div>
				),
				tabContent: (
					<MaterialComparisonTab
						materialComparisonRows={materialComparisonRows}
						showAll={showAllMaterialCompars}
						onShowAllClick={() => onShowAllComparsClick(configuration.id)}
						isAmOriginalMaterial={isAmOriginalMaterial}
						isAMSuggestion={
							!suggestionType || suggestionType === SuggestionType.AM
						}
					/>
				)
			})
		}
		//add <Cluster Analysis> tab
		if (cluster || configuration.cluster) {
			cluster = configuration.cluster
			clusterParts = clusterParts || configuration.cluster?.parts
			tabs.push({
				index: tabOrder.cluster,
				tabButton: (
					<div
						data-qa={'data-qa-header-cluster-parts'}
						className="solution-tab--cost-title"
					>
						{PART_ANALYSIS_TABS_TITLE_CLUSTER_PARTS}
					</div>
				),
				tabContent: (
					<ClusterPartsTab
						parts={clusterParts}
						onPartClick={(part: Part) => onPartClick(part, newTab, user, true)}
						fetchMoreData={() => {
							fetchMoreClusterParts(cluster.id, clusterParts?.length || 0)
						}}
						hasMoreData={
							cluster.compositionSetParts?.length !== clusterParts?.length
						}
					/>
				)
			})
		}
		//add <Challenges> tab
		if (isChallenges) {
			tabs.push({
				index: tabOrder.challenges,
				tabButton: (
					<div
						data-qa={'data-qa-header-lead-time'}
						id={elementIds.challenges}
						className="solution-tab--cost-title challenges-title"
					>
						<DetailsPopup
							isHover={false}
							data={''}
							popperDirection="bottom-end"
							popperClassName={cx('lead-time-message-wrapper', {
								'details-popup--contact-us': configuration.isSpecifiedQuantity
							})}
						>
							<div>{SUGGESTION_CHALLENGE}</div>
						</DetailsPopup>
					</div>
				),
				tabContent: <ChallengeTab part={part} configuration={configuration} />
			})
		}

		return sortBy(tabs, ['index'])
	}

	const tabs = renderTabsArray()
	const selectedTabOrder = useSelectedTabOrder(
		initialSelectedTab,
		tabs,
		showAnalysis,
		costBenefit,
		disableTabUpdate,
		isChallenges
	)

	return (
		<NavScrollablePillsTSX
			initialSelectedTab={selectedTabOrder}
			benefits={solution && solution.benefits}
			alignCenter={true}
			tabButtonsClassName="solution-tab-buttons scrollable"
			color="warning"
			showSmallButtons={tabs.length > 4}
			tabButtonClassName="solution-tab-buttons-button"
			tabButtonSelectedClassName="solution-tab-buttons-button-selected"
			tabButtonLabelClassName="solution-tab-buttons-button-label"
			tabContentClassName="solution-tab-content"
			tabs={tabs}
			configuration={configuration}
		/>
	)
}

const mapStateToProps = (
	{
		SolutionAnalysisReducer,
		user,
		MainPartAnalysisReducer: { currentStepTargetId, clusterParts }
	}: IReduxStore,
	ownProps: IProps
) => {
	const {
		materialComparisonRows,
		analysisResultsRows,
		initialMaterial,
		solution,
		showAnalysis,
		timeBenefit,
		costBenefit,
		initialSelectedTab,
		showAllMaterialCompars,
		simpleConfiguration,
		customConfiguration,
		showBenefits,
		manufacturingMethod,
		tourStepIndex,
		resultBody,
		resultTitle,
		disableTabUpdate,
		isAmOriginalMaterial,
		suggestionType
	}: SolutionAnalysisInitialState =
		SolutionAnalysisReducer.states[ownProps.configuration.id] ||
		new SolutionAnalysisInitialState()

	return {
		materialComparisonRows,
		analysisResultsRows,
		initialMaterial,
		solution,
		showAnalysis,
		costBenefit,
		initialSelectedTab,
		showAllMaterialCompars,
		simpleConfiguration,
		customConfiguration,
		showBenefits,
		features: user.features,
		tourStepIndex,
		currentStepTargetId,
		manufacturingMethod,
		resultBody,
		resultTitle,
		disableTabUpdate,
		timeBenefit,
		user,
		clusterParts,
		isAmOriginalMaterial,
		suggestionType
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{
			...SolutionAnalysisActions,
			incrementTourStep,
			fetchMoreClusterParts,
			onPartClick
		},
		dispatch
	)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(SolutionAnalysisTabs)
)
