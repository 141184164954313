import { ChangeEvent, FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { isEmpty } from 'lodash'

import { getThemeString } from '../../../themes/getThemeString'
import CastorForm from '../../Components/CastorForm/CastorForm'
import Devider from '../../Components/Devider/Devider'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import {
	renderCustomizeFilters,
	renderCustomizeInHousePrinters,
	renderFormHeader,
	renderSemiProPrinters,
	renderSustainabilityLifeCycle
} from './CustomizeFormCommon'
import CustomizeFormSection from './CustomizeFormSection'
import { POST_PROCESSES_BLOCK_ID_HASH } from './CustomizeUserPostProcesses/Constants'
import { History } from 'history'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import Flexbox from 'Scenes/Components/FlexBox'
import {
	fetchCustomizeData,
	onCustomFunctionsAlertClose,
	onSelectedInputUnitsType
} from 'Scenes/Home/Customize/CustomizeActions'
import {
	CUSTOMIZE_ADDITIVE_MANUFACTURING_SETTINGS_ROUTE,
	CUSTOMIZE_CO2_CALCULATIONS_ROUTE,
	CUSTOMIZE_COST_AND_LEAD_ROUTE,
	CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE,
	CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE,
	CUSTOMIZE_MATERIAL_NAMES_MAPPING_ROUTE,
	CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE,
	CUSTOMIZE_SOLUTION_FILTER_ROUTE,
	CUSTOMIZE_SUGGESTIONS_ROUTE,
	CUSTOMIZE_TAGS_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { UnitType } from 'Services/models/IProject'
import { customizeUserMaterial, uploadProjectRoute } from 'Services/routeFuncs'
import { OK } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import { styles } from '../styles/formStyles.css'
import './CustomizeForm.scss'

interface IProps {
	adminSelectedOrganizationId: number
	history: History
	onToggleDesktopPrinter: Function
	desktopPrinter: boolean
	selectedUnitsType: Record<string, any>
	showCustomFunctionsWarning: boolean
}

const renderCustomizeAdditiveManufacturingSettings = (
	isCastorTwoSettings: boolean | undefined
) => {
	if (!isCastorTwoSettings) {
		return <div></div>
	}
	return (
		<>
			<CustomizeFormSection
				componentId={2}
				explanationHeader={getString(
					'CUSTOMIZE_ADDITIVE_MANUFACTURING_SETTINGS_TITLE'
				)}
				explanationArray={getString(
					'CUSTOMIZE_ADDITIVE_MANUFACTURING_SETTINGS_EXPLANATION_ARRAY'
				)}
				linkTo={
					USER_HOME_ROUTE + CUSTOMIZE_ADDITIVE_MANUFACTURING_SETTINGS_ROUTE
				}
				qaDataElementName="data-qa-customize-in-house-printers"
			/>
			<Devider className="customize-form--divider" componentId={2} />
		</>
	)
}
const renderCustomizeSuggestions = () => {
	if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_SUGGESTIONS)) {
		return <div></div>
	}
	return (
		<>
			<CustomizeFormSection
				componentId={2}
				explanationHeader={getString('CUSTOMIZE_SUGGESTIONS_TITLE')}
				explanationArray={getString('CUSTOMIZE_SUGGESTIONS_EXPLANATION_ARRAY')}
				linkTo={USER_HOME_ROUTE + CUSTOMIZE_SUGGESTIONS_ROUTE}
				qaDataElementName="data-qa-customize-in-house-printers"
			/>
			<Devider className="customize-form--divider" componentId={2} />
		</>
	)
}

const renderCustomFunctionsAlert = (
	showCustomFunctionsWarning: boolean,
	onCustomFunctionsAlertClose: () => void
) => {
	return (
		<CastorAlert
			headerTitle={getString('CONFIGURATION_CHANGES_WARNING')}
			show={showCustomFunctionsWarning}
			onConfirm={onCustomFunctionsAlertClose}
			confirmOptionalText={OK}
			onCancel={onCustomFunctionsAlertClose}
			alertType={AlertType.WARNING}
			showCancel={false}
		>
			<div className="custom-functions-alert-body">
				{getString('SWITCH_UNIT_SYSTEM_WITH_CUSTOM_FUNCTIONS_PART1')}
				<Link
					to={USER_HOME_ROUTE + CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE}
					target="_blank"
				>
					{getString('COST_FUNCTION_EDITOR')}
				</Link>
				{` ${getString('AND')} `}
				<Link
					to={
						USER_HOME_ROUTE +
						CUSTOMIZE_COST_AND_LEAD_ROUTE +
						POST_PROCESSES_BLOCK_ID_HASH
					}
					target="_blank"
				>
					{getString('CUSTOMIZE_MACHINING_TITLE')}
				</Link>
				{getString('SWITCH_UNIT_SYSTEM_WITH_CUSTOM_FUNCTIONS_PART2')}
			</div>
		</CastorAlert>
	)
}

const renderMaterialNamesMapping = () => {
	if (
		!Feature.isFeatureOn(FeatureComponentId.MATERIAL_NAMES_MAPPING) ||
		!Feature.isFeatureOn(FeatureComponentId.DRAWING_ANALYSIS)
	) {
		return <div />
	}
	return (
		<>
			<CustomizeFormSection
				explanationHeader={getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_TITLE')}
				explanationArray={getString(
					'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EXPLANATION_ARRAY'
				)}
				linkTo={USER_HOME_ROUTE + CUSTOMIZE_MATERIAL_NAMES_MAPPING_ROUTE}
				qaDataElementName="data-qa-customize-material-names"
			/>
			<Devider className="customize-form--divider" />
		</>
	)
}

const renderCostingFunctionEditor = () => {
	if (!Feature.isFeatureOn(FeatureComponentId.COST_FUNCTION_EDITOR)) {
		return <div></div>
	}

	return (
		<>
			<CustomizeFormSection
				explanationHeader={getString('COSTING_FUNCTION_EDITOR_TITLE')}
				explanationArray={getString(
					'COSTING_FUNCTION_EDITOR_EXPLANATION_ARRAY'
				)}
				linkTo={USER_HOME_ROUTE + CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE}
				qaDataElementName="data-qa-costing-function-editor"
			/>
			<Devider className="customize-form--divider" />
		</>
	)
}

const renderInputUnitsType = (
	selectedUnitsType: Record<string, any>,
	dispatch: Function,
	adminSelectedOrganizationId: number
) => {
	if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_UNITS_TYPE)) {
		return <div></div>
	}

	// DE support only inches and mm:
	const cadUnitTypesFiltered = getString('CAD_UNIT_TYPES')?.filter(
		(t: any) => t.key === UnitType.mm || t.key === UnitType.inch
	)

	return (
		<>
			<Devider className="customize-form--divider" componentId={2} />

			<CustomizeFormSection
				componentId={2}
				explanationHeader={getString('CUSTOMIZE_INPUT_UNITS_TYPE_TITLE')}
				explanationArray={getString('CUSTOMIZE_INPUT_UNITS_TYPE_EXPLANATION')}
				select={true}
				selectTypes={cadUnitTypesFiltered}
				selectPlaceHolder={getString('UNIT_SELECTION_PLACEHOLDER')}
				onSelectedInputUnitsType={(e: ChangeEvent<HTMLInputElement>) => {
					dispatch(
						onSelectedInputUnitsType(
							e.target.value,
							adminSelectedOrganizationId
						)
					)
				}}
				selectedValue={selectedUnitsType}
				qaDataElementName="data-qa-customize-input-units-type"
			/>
		</>
	)
}

const renderRecalculateAllParts = () => {
	if (!Feature.isFeatureOn(FeatureComponentId.RECALCULATE_PROJECTS)) {
		return <div></div>
	}

	return (
		<>
			<Devider className="customize-form--divider" componentId={2} />

			<CustomizeFormSection
				componentId={2}
				explanationHeader={getString('CUSTOMIZE_RECALCULATE_PROJECTS_TITLE')}
				explanationArray={getString(
					'CUSTOMIZE_RECALCULATE_PROJECTS_EXPLANATION'
				)}
				linkTo={USER_HOME_ROUTE + CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE}
				qaDataElementName="data-qa-customize-recalculate-projects"
				buttonName={getString('CHOOSE_PROJECTS_PLACEHOLDER')}
			/>
		</>
	)
}

const CustomizeForm: FC<IProps> = ({
	adminSelectedOrganizationId,
	history,
	onToggleDesktopPrinter,
	desktopPrinter,
	showCustomFunctionsWarning
}) => {
	const dispatch = useDispatch()

	const { settingsData, costAndLeadNames } = useSelector(
		(state: RootStateOrAny) => state.CostAndLeadReducer
	)

	const { printersFullData, userDetails } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const { selectedUnitsType, isShowSemiProfessionalPrintersToggle } =
		useSelector((state: RootStateOrAny) => state.CustomizeReducer)

	const organization_owner = userDetails.organization_owner
	const isCastorTwoSettings = Feature.isFeatureOn(
		FeatureComponentId.CASTOR_2_SETTINGS
	)

	useEffect(() => {
		if (!isEmpty(userDetails) && !organization_owner) {
			history.push(uploadProjectRoute())
		}
		dispatch(
			fetchCustomizeData(
				costAndLeadNames,
				settingsData.userSettings,
				undefined,
				printersFullData,
				adminSelectedOrganizationId
			)
		)
	}, [])

	useEffect(() => {
		if (adminSelectedOrganizationId) {
			dispatch(
				fetchCustomizeData(
					costAndLeadNames,
					settingsData.userSettings,
					undefined,
					printersFullData,
					adminSelectedOrganizationId
				)
			)
		}
	}, [adminSelectedOrganizationId])

	const onCloseFunctionAlert = () => dispatch(onCustomFunctionsAlertClose())
	useEffect(() => {
		if (showCustomFunctionsWarning) {
			onCloseFunctionAlert()
		}
	}, [showCustomFunctionsWarning])

	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<CastorForm
				formHeader={renderFormHeader(history, null)}
				formTitle={getString('CUSTOMIZE_FORM_HEADER')}
				content={
					<Flexbox flexDirection="column" style={styles.formSuperview}>
						{renderCustomizeSuggestions()}
						{!isCastorTwoSettings &&
							renderCustomizeInHousePrinters(
								USER_HOME_ROUTE + CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE
							)}
						{renderCustomizeAdditiveManufacturingSettings(isCastorTwoSettings)}

						<CustomizeFormSection
							explanationHeader={getString('CUSTOMIZE_COST_AND_LEAD_TITLE')}
							explanationArray={getString(
								'CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY'
							)}
							linkTo={USER_HOME_ROUTE + CUSTOMIZE_COST_AND_LEAD_ROUTE}
							qaDataElementName="data-qa-cost-calculation-params"
						/>

						{!isCastorTwoSettings &&
							renderSustainabilityLifeCycle(
								USER_HOME_ROUTE + CUSTOMIZE_CO2_CALCULATIONS_ROUTE
							)}
						<Devider className="customize-form--divider" />

						{!isCastorTwoSettings &&
							renderCustomizeFilters(
								USER_HOME_ROUTE + CUSTOMIZE_SOLUTION_FILTER_ROUTE
							)}
						{renderMaterialNamesMapping()}
						{renderCostingFunctionEditor()}
						<CustomizeFormSection
							explanationHeader={getString('CUSTOMIZE_OFF_THE_SHELF_TITLE')}
							explanationArray={getThemeString(
								'CUSTOMIZE_OFF_THE_SHELF_EXPLANATION_ARRAY'
							)}
							linkTo={USER_HOME_ROUTE + CUSTOMIZE_TAGS_ROUTE}
							qaDataElementName="data-customize-part-filtering"
						/>
						{Feature.isFeatureOn(FeatureComponentId.CUSTOM_MATERIALS) &&
						!isCastorTwoSettings ? (
							<>
								<Devider className="customize-form--divider" />
								<CustomizeFormSection
									explanationHeader={getString('CUSTOMIZE_USER_MATERIAL_TITLE')}
									explanationArray={getThemeString(
										'CUSTOMIZE_SETTINGS_MATERIAL_EXPLANATION_ARRAY'
									)}
									linkTo={customizeUserMaterial()}
									qaDataElementName="data-qa-add-material"
								/>
							</>
						) : (
							<></>
						)}
						{!isCastorTwoSettings &&
							renderSemiProPrinters(
								desktopPrinter,
								onToggleDesktopPrinter,
								isShowSemiProfessionalPrintersToggle,
								adminSelectedOrganizationId
							)}
						{renderInputUnitsType(
							selectedUnitsType,
							dispatch,
							adminSelectedOrganizationId
						)}
						{renderRecalculateAllParts()}
						{renderCustomFunctionsAlert(
							showCustomFunctionsWarning,
							onCloseFunctionAlert
						)}
					</Flexbox>
				}
			/>
		</NavBarAndMaterial>
	)
}

export default memo(CustomizeForm)
