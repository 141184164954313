export interface IPreparedValues {
	x: number
	y: number
}

export enum ComparisonMethodEnum {
	main = 'main',
	compared = 'compared'
}

export interface IComparisonPoint {
	days: number
	method: ComparisonMethodEnum
	quantity: number
}

export interface ILeadTimeResults {
	tmResults: ManufacturingData[]
	comparisonPoint: IComparisonPoint[]
	amResults: ManufacturingData[]
	leadTimeDetails: ILeadTimeDetails
	formParameters: IFormParameters
	comparedResults: ManufacturingData[]
	mainResults: ManufacturingData[]
}

export interface ILeadTimeDetails {
	deliveryTimeForEachBatch: number
	firstLeadTime: number
	lastDay: number
	partsForEachShipments: number
	firstShipments: number
}

export interface IFormParameters {
	tmInitialTechnologySetupTime: number
	removalRateCNC: number
	MOQ: number
}

export interface ManufacturingData {
	[key: string]: number
}
