import { ClientChallengesType } from '../ProjectAnalysisSuggestionService'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem, VolumeUnit } from 'Services/UnitsConversionService'

export enum ToleranceClassType {
	TOLERANCE_CLASS_FINE = 'f',
	TOLERANCE_CLASS_MEDIUM = 'm',
	TOLERANCE_CLASS_COARSE = 'c',
	TOLERANCE_CLASS_VERY_COARSE = 'v'
}

export const tightToleranceOptions = [
	{
		label: getString('TOLERANCE_CLASS_FINE'),
		value: ToleranceClassType.TOLERANCE_CLASS_FINE
	},
	{
		label: getString('TOLERANCE_CLASS_MEDIUM'),
		value: ToleranceClassType.TOLERANCE_CLASS_MEDIUM
	},
	{
		label: getString('TOLERANCE_CLASS_COARSE'),
		value: ToleranceClassType.TOLERANCE_CLASS_COARSE
	},
	{
		label: getString('TOLERANCE_CLASS_VERY_COARSE'),
		value: ToleranceClassType.TOLERANCE_CLASS_VERY_COARSE
	}
]

export const complexityScoreOptions = [
	{ label: getString('LOW'), value: 'low' },
	{ label: getString('MEDIUM'), value: 'medium' },
	{ label: getString('HIGH'), value: 'high' }
]

export const getComplexityScore = (value?: number, defaultScore?: number) => {
	if (!value || !defaultScore) return ''
	if (value > defaultScore) {
		return complexityScoreOptions[2].label
	}
	if (value > defaultScore / 2 && value < defaultScore) {
		return complexityScoreOptions[1].label
	} else {
		return complexityScoreOptions[0].label
	}
}

export const getCostVolumeRatioUnit = (userUnitSystem: UnitSystem) => {
	return userUnitSystem === UnitSystem.metric
		? `$/${VolumeUnit.cm3}`
		: `$/${VolumeUnit.inch3}`
}

export type SelectedType = 'input' | 'percentage' | 'select'

export const SelectedTypeEnum = {
	input: 'input' as SelectedType,
	percentage: 'percentage' as SelectedType,
	select: 'select' as SelectedType
}

export const getRadioButtonInputText = (
	challengeType: ClientChallengesType,
	unitType: UnitSystem
) => {
	let text = ''

	switch (challengeType) {
		case ClientChallengesType.hightCostToVolume:
			text = getString('CHALLENGES_COST_VOLUME_VALUE').format(
				getCostVolumeRatioUnit(unitType)
			)
			break
		case ClientChallengesType.highBuyToFly:
			text = getString('CHALLENGES_BUY_TO_FLY_VALUE')
			break
		case ClientChallengesType.countInAssembly:
			text = getString('CHALLENGES_COUNT_IN_ASSEMBLY_VALUE')
			break
		case ClientChallengesType.highCost:
			text = getString('CHALLENGES_COST_VALUE')
			break
		default:
			text = ''
			break
	}

	return text
}

export const getRadioButtonUnit = (
	challengeType: ClientChallengesType,
	unitType: UnitSystem
) => {
	let text = ''

	switch (challengeType) {
		case ClientChallengesType.hightCostToVolume:
			text = getCostVolumeRatioUnit(unitType)

			break
		case ClientChallengesType.highBuyToFly:
			text = getString('SCORE')
			break
		case ClientChallengesType.countInAssembly:
			text = getString('FINANCIAL_COUNT_LABEL_2')
			break
		case ClientChallengesType.highCost:
			text = '$'
			break
		default:
			text = ''
			break
	}

	return text
}
