import { FC, memo, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { RootStateOrAny, useSelector } from 'react-redux'

import { GridList, GridListTile } from '@material-ui/core'
import cx from 'classnames'

import DetailsPopup from 'Scenes/Components/DetailsPopup'
import ProjectPartItem from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectPartItem/ProjectPartItem'
import Loader from 'Scenes/Loader/Loader'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

import '../SolutionAnalysisTabs.scss'

const GridListTSX: any = GridList
const GridListTileTSX: any = GridListTile

interface IProps {
	parts: Part[] | null
	onPartClick: Function
	fetchMoreData: any
	hasMoreData: boolean
}

const ClusterPartsTab: FC<IProps> = ({
	parts,
	onPartClick,
	fetchMoreData,
	hasMoreData
}) => {
	const { part: existingPart } = useSelector(
		(state: RootStateOrAny) => state.MainPartAnalysisReducer
	)
	const sectionRef = useRef(null)

	return (
		<InfiniteScroll
			dataLength={parts?.length || 0}
			next={fetchMoreData}
			hasMore={hasMoreData}
			loader={
				<Loader
					load={true}
					size={50}
					showFlex={false}
					wrapperClassName="solution-tab-cluster-part-tile--loader"
				/>
			}
			height={330}
		>
			<GridListTSX
				className="solution-tab-cluster"
				spacing={15}
				cols={3}
				ref={sectionRef}
			>
				{parts?.map(part => (
					<GridListTileTSX
						className={cx('solution-tab-cluster-part-tile', {
							disabled: part.id === existingPart?.id
						})}
						classes={{ tile: 'solution-tab-cluster-part-tile--element' }}
						key={part.id}
						sm={4}
					>
						<DetailsPopup
							isHover={part.id === existingPart?.id}
							data={getString('THE_PART_IS_SELECTED')}
							popperDirection="top"
							popperClassName="info-box-wrapper wrapper-cluster"
							popperContactClassName="info-box-data"
							inPortal={true}
							sectionRef={sectionRef}
						>
							<ProjectPartItem
								part={part}
								onPartClick={() => {
									if (part.id !== existingPart?.id) {
										onPartClick(part)
									}
								}}
								cardClassName="solution-tab-cluster-part-tile--card"
								cardSizeWrapperClassName="solution-tab-cluster-part-tile--card--image-wrapper"
								cardContentClassName="solution-tab-cluster-part-tile--card--content"
							/>
						</DetailsPopup>
					</GridListTileTSX>
				))}
			</GridListTSX>
		</InfiniteScroll>
	)
}

export default memo(ClusterPartsTab)
