import { toString } from 'lodash'

import {
	ISimpleConfigurationCompany,
	ISimpleConfigurationPrinter
} from '../../../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { COPY_SUFFIX } from './NewOrCopyConstants'
import { SimpleConfigurationSelectorService } from 'Scenes/Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { Material } from 'Services/models/IMaterial'
import { Printer } from 'Services/models/IPrinter'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'

let simpleConfigurationSelectorService =
	new SimpleConfigurationSelectorService()

export const setupPrinterPropsData = (
	printersFullData: Array<ISimpleConfigurationCompany> = [],
	setupPrinterTechnologyData: IPrintingTechnology[]
) => {
	simpleConfigurationSelectorService = new SimpleConfigurationSelectorService(
		printersFullData,
		false,
		true,
		[],
		setupPrinterTechnologyData
	)
}

export const returnAllTechnologies = () => {
	const printerTechnologies =
		simpleConfigurationSelectorService.getTechnologiesList()

	return printerTechnologies
}

export const returnAllCompaniesAccordingTechnology = (
	technologyName: string
) => {
	const printerCompanies =
		simpleConfigurationSelectorService.getCompaniesByTechnology(technologyName)

	return printerCompanies
}

export const printerCompanyChange = (printerCompany: string) => {
	const printerNames =
		simpleConfigurationSelectorService.getPrintersNamesList(printerCompany)
	return printerNames
}

export const findPrinterByMaterial = (
	material: Material & { printers: string; dataValues?: Material },
	allPrinters: Array<ISimpleConfigurationCompany> = []
) => {
	const printerId = JSON.parse(material.printers)[0]
	let printerData = null

	for (const company of allPrinters) {
		printerData = company.printers.find(
			(printer: ISimpleConfigurationPrinter) =>
				toString(printer.printerId) === toString(printerId)
		)
		if (printerData) {
			printerData.company = company.companyName
			break
		}
	}

	return {
		...(material.dataValues || material),
		printers: printerData ? printerData.printerId : '',
		printer: printerData ? printerData.printerId : '',
		printerCompany: printerData ? printerData.company : '',
		printerTechnologyName: printerData ? printerData.technology : ''
	}
}

export const cleanNewMaterial = (material: Material & any) => {
	const updatedMaterial = { ...material }
	delete updatedMaterial.id

	//TODO: should be fixed with Julia updates to Redis
	delete updatedMaterial.dataValues
	delete updatedMaterial.createdAt
	delete updatedMaterial._previousDataValues
	delete updatedMaterial.uniqno
	delete updatedMaterial._changed
	delete updatedMaterial._options
	delete updatedMaterial.isNewRecord

	return updatedMaterial
}
export const cleanNewPrinter = (printer: Printer & any) => {
	const updatedPrinter = { ...printer }
	delete updatedPrinter.id
	
	//TODO: should be fixed with Julia updates to Redis
	delete updatedPrinter.dataValues

	return updatedPrinter
}

export const getPrinterByCompany = (
	printersCompanies: Record<string, Printer[]>,
	selectedCompany: string,
	selectedPinter: ISimpleConfigurationPrinter,
	selectedTechnology: string
) => {
	const selectedCompanyKey =
		Object.keys(printersCompanies).find(
			key => key.toLowerCase() === selectedCompany.toLowerCase()
		) || ''
	const fullPrinterData = printersCompanies[selectedCompanyKey].find(
		(printer: Printer) => printer.id === selectedPinter.printerId
	)

	const printer = cleanNewPrinter(fullPrinterData || selectedPinter)

	return {
		...printer,
		name: selectedPinter?.name + COPY_SUFFIX,
		machineCost: printer.machineCost || selectedPinter?.cost || 0,
		printerTechnologyName: selectedTechnology,
		company: selectedCompany,
		companies: selectedCompany
	}
}
