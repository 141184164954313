import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'

import { onSelectedUnitSystem } from 'Scenes/Home/Customize/CustomizeActions'
import { getString } from 'Services/Strings/StringService'
import { generateKey } from 'Services/Utils/uniqKeyGenerator'

const UnitSystem: FC = () => {
	const dispatch = useDispatch()
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const {
		userCustomizationSettings: { unitSystem = '' }
	} = useSelector((state: RootStateOrAny) => state.user)

	const unitSystems = getString('UNITS_SYSTEMS') || []

	return (
		<div className="start-screen--description">
			<Select
				className="select-unit-type"
				value={unitSystem || 'disabled'}
				onChange={(e: any) =>
					dispatch(
						onSelectedUnitSystem(e.target.value, adminSelectedOrganizationId)
					)
				}
				inputProps={{
					name: 'simpleSelect',
					id: 'simple-select'
				}}
				data-qa={'data-qa-customize-units-system'}
			>
				<MenuItem disabled value="disabled">
					{getString('UNITS_SYSTEM_PLACEHOLDER')}
				</MenuItem>
				{unitSystems?.map((json: any, index: any) => {
					return (
						<MenuItem
							key={generateKey(index)}
							value={json.key}
							data-qa={`data-qa-${json.key}`}
						>
							{json.name}
						</MenuItem>
					)
				})}
			</Select>
		</div>
	)
}

export default memo(UnitSystem)
