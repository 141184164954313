import React, { FC, memo } from 'react'

import { getMaterialComparisonTableHeaders } from '../SolutionAnalysisTabsService'
import DataTable from 'Scenes/Components/DataTable'
import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import { SHOW_ALL } from 'Services/Strings'

import '../SolutionAnalysisTabs.scss'
import '../SolutionNewTabStyles.scss'

interface IProps {
	materialComparisonRows: any[][]
	showAll: boolean
	onShowAllClick: Function
	isAmOriginalMaterial: boolean
	isAMSuggestion: boolean
}

const dataTableService = new DataTableService()

const MaterialComparisonTab: FC<IProps> = ({
	materialComparisonRows,
	onShowAllClick,
	showAll,
	isAmOriginalMaterial,
	isAMSuggestion
}) => {
	const materialComparisonHeaders = getMaterialComparisonTableHeaders(
		dataTableService,
		isAmOriginalMaterial,
		isAMSuggestion
	)
	const customElements = !showAll
		? [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.Button,
					text: SHOW_ALL,
					onClick: () => onShowAllClick()
				})
		  ]
		: undefined

	let tableClassName = 'solution-tab--material-table solution-tab--new-table'

	if (isAmOriginalMaterial || !isAMSuggestion) {
		tableClassName =
			'solution-tab--material-table solution-tab--material-two-column solution-tab--new-table'
	}

	return (
		<div>
			<DataTable
				tableClassName={tableClassName}
				tableHead={materialComparisonHeaders}
				tableDataRows={materialComparisonRows}
				customElements={customElements}
				showBreakLines={true}
			/>
		</div>
	)
}

export default memo(MaterialComparisonTab)
