import React, { FC } from 'react'

import ProjectMultipleParts from '../ProjectAnalysisPage/ProjectMultipleParts/ProjectMultipleParts'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { RegularCard } from 'Scenes/Components/thirdParty/CreativeTim/components'
import ProjectBundleMultipleParts from 'Scenes/Home/ProjectBundle/ProjectAnalysisBundle/ProjectBundleMultipleParts'
import { NAV_TITLE_PROJECT_ANALYSIS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as QuickCADFeedback } from 'assets/img/svg/danfoss/QuickCADFeedback.svg'

import './index.scss'

const RegularCardTSX: any = RegularCard

type IProps = {
	isBundle: boolean
}

const QuickCADProjectResults: FC<IProps> = ({ isBundle }) => {
	const headerParagraphs =
		getString('QUICK_UPLOAD_FILES_PROJECT_ANALYSIS_PARAGRAPHS') || []
	return (
		<NavBarAndMaterial title={NAV_TITLE_PROJECT_ANALYSIS}>
			<div className="quick-cad-project-results--header">
				<div className="quick-cad-project-results--header--feedback-text">
					<h2 data-qa="data-qa-thank-you-screen">
						{getString('QUICK_UPLOAD_FILES_PROJECT_ANALYSIS_HEADER')}
					</h2>
					{headerParagraphs?.map((paragraph: string, index: number) => (
						<p key={paragraph + index}>{paragraph}</p>
					))}
				</div>
				<QuickCADFeedback className="quick-cad-project-results--header--img" />
			</div>
			<div className="quick-cad-project-results--card-result-wrapper">
				<RegularCardTSX
					classes={{ cardContent: 'quick-cad-project-results--card-result' }}
					content={
						isBundle ? <ProjectBundleMultipleParts /> : <ProjectMultipleParts />
					}
				/>
			</div>
		</NavBarAndMaterial>
	)
}

export default QuickCADProjectResults
