import { FC, memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { isEmpty, toNumber } from 'lodash'

import { getProjectInformation } from '../ProjectPage/ProjectPageAction'
import ProgressBar from './ProgressBar/index'
import { UploadingProjectInitialState } from './UploadingProjectReducer'
import AdBlock from 'Scenes/Components/AdBlock'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { usePushNotificationSilentEvent } from 'Scenes/Components/PushNotification/PushNotificationHooks'
import { useUserReducer } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import {
	clearPoller,
	clearRoutingPoller,
	getFastProjectPoller,
	sendAdminUploadFilesInfo,
	uploadFailedFiles
} from 'Scenes/Home/NewUploadProject/UploadProjectActions'
import Loader from 'Scenes/Loader/Loader'
import {
	UPLOAD_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ProjectStatus } from 'Services/models/IProject'
import { projectRoute, uploadProjectRoute } from 'Services/routeFuncs'
import {
	COMPLETE_UPLOAD_FAILED_MSG,
	COMPLETE_UPLOAD_FAILED_TITLE,
	COMPLETE_UPLOAD_SUCCESS_MSG,
	COMPLETE_UPLOAD_SUCCESS_TITLE,
	NAV_TITLE_PROJECT_UPLOADING,
	OK,
	START_OVER,
	YES
} from 'Services/Strings'

interface UploadingProjectProps {}

const UploadingProject: FC<UploadingProjectProps> = ({}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { isLightUser, userEmail, defaultFileName } = useUserReducer()
	const { projectId: paramsProjectId } = useParams<{ projectId: string }>()
	const {
		pollerStarted,
		uploadSuccess,
		quickProject,
		projectId: projectPropsId,
		filesFailed,
		uploadURLs,
		bomFile,
		antivirusMessages,
		antivirusMessagingEndpoint,
		quickProjectPublished,
		quickProjectFailed,
		filesToUpload,
		progress
	} = useSelector((state: RootStateOrAny) => {
		return state?.uploadProject
	})
	const { state } = useLocation() as { state?: Record<string, boolean> }

	const { folderId, bundleId: projectBundleId } = useSelector(
		(state: RootStateOrAny) => {
			return state?.ProjectPageReducer
		}
	)
	const { show } = useSelector((state: RootStateOrAny) => {
		return state?.CastorBannerReducer
	})

	const projectId = useMemo(
		() => paramsProjectId || projectPropsId,
		[paramsProjectId, projectPropsId]
	)

	const [
		{ bundleId: uploadBundleId, projectStatus, numberOfTotalPartsAnalyzed }
	] = useSelector((state: RootStateOrAny) => {
		return [
			state.UploadingProjectReducer?.projectUploading[projectId] ||
				new UploadingProjectInitialState()
		]
	})

	const bundleId = useMemo(
		() => projectBundleId || uploadBundleId,
		[projectBundleId, uploadBundleId]
	)
	const showStartOver = filesFailed.length > 0 && filesToUpload.length === 0

	const showProgressAlert = Feature.isFeatureOn(
		FeatureComponentId.SHOW_PROGRESS_ALERT
	)
	const showContactUs = Feature.isFeatureOn(
		FeatureComponentId.SHOW_CONTACT_US_BANNER
	)
	const isProjectBundleOn = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_BUNDLE_PAGE
	)
	const isQuickUploadOn = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)

	useEffect(() => {
		if (projectId) {
			dispatch(getProjectInformation(projectId))
		}
	}, [])

	useEffect(() => {
		if (folderId === projectId && !!bundleId && bundleId !== projectId) {
			window.location.href = `/uploadingProject/${bundleId}`
		}
	}, [bundleId, projectId, folderId])

	useEffect(() => {
		if (!showStartOver && uploadSuccess && quickProject && !pollerStarted) {
			dispatch(getFastProjectPoller(projectId))
		}
	}, [uploadSuccess, quickProject, pollerStarted, projectId, showStartOver])

	useEffect(() => {
		if (
			!isLightUser &&
			!!userEmail &&
			!state?.fromUploadPage &&
			!progress &&
			!showStartOver &&
			!uploadSuccess &&
			!quickProject &&
			!pollerStarted &&
			projectId
		) {
			// if it was totally refreshed and came not from upload page, go to project
			history.push(projectRoute(projectId))
		}
	}, [
		userEmail,
		state?.fromUploadPage,
		uploadSuccess,
		quickProject,
		pollerStarted,
		projectId,
		showStartOver,
		isLightUser,
		progress
	])

	useEffect(() => {
		if (bundleId && pollerStarted) {
			dispatch(clearPoller())
			dispatch(getFastProjectPoller(projectId, bundleId))
		}
	}, [bundleId, dispatch, projectId])

	usePushNotificationSilentEvent(bundleId || projectId)

	useEffect(() => {
		return () => {
			dispatch(clearPoller())
			dispatch(clearRoutingPoller())
		}
	}, [])

	const handleCompleteSuccess = (disableBackgroundClick: boolean) => {
		if (disableBackgroundClick) {
			return
		}
		history.push(USER_HOME_ROUTE + UPLOAD_ROUTE)
	}

	const handleStartOver = () => {
		dispatch(sendAdminUploadFilesInfo(projectId))
		history.push(USER_HOME_ROUTE + UPLOAD_ROUTE)
	}

	const handleCompleteFailed = () => {
		const fileUrls = !isEmpty(uploadURLs.metadataUploadURLs)
			? uploadURLs.metadataUploadURLs
			: uploadURLs
		const filesToUpload = filesFailed.filter((file: any) => fileUrls[file.name])

		dispatch(
			uploadFailedFiles(
				uploadURLs,
				bomFile,
				antivirusMessages,
				antivirusMessagingEndpoint,
				false,
				defaultFileName,
				fileUrls,
				filesToUpload
			)
		)
	}

	useEffect(() => {
		if (projectId && !showStartOver) {
			if (!isLightUser && toNumber(progress) === 100 && showProgressAlert) {
				setTimeout(() => {
					history.push(projectRoute(projectId))
					return
				}, 1500)
			} else if (
				quickProjectPublished ||
				projectStatus === ProjectStatus.published
			) {
				const id =
					isProjectBundleOn && isQuickUploadOn && bundleId
						? bundleId
						: projectId
				window.location.replace(projectRoute(id))
			} else if (
				quickProjectFailed &&
				(paramsProjectId === projectId || paramsProjectId === bundleId)
			) {
				history.push(uploadProjectRoute())
			}
		}
	}, [
		quickProjectPublished,
		quickProjectFailed,
		progress,
		projectId,
		showStartOver,
		filesToUpload,
		paramsProjectId
	])

	return (
		<NavBarAndMaterial
			title={NAV_TITLE_PROJECT_UPLOADING}
			wrapperStyle={show && showContactUs ? 'with-contact-banner' : ''}
		>
			{!progress && !projectStatus && !numberOfTotalPartsAnalyzed ? (
				<Loader load={true} message="" showFlex={true} />
			) : (
				<></>
			)}
			<ProgressBar
				bundleId={bundleId}
				showProgressAlert={showProgressAlert}
				showAnalyze={isLightUser}
			/>

			<CastorAlert
				show={uploadSuccess && !quickProject}
				alertType={AlertType.SUCCESS}
				headerTitle={COMPLETE_UPLOAD_SUCCESS_TITLE}
				onConfirm={handleCompleteSuccess}
				onCancel={handleCompleteSuccess}
				confirmOptionalText={OK}
				showCancel={false}
			>
				{COMPLETE_UPLOAD_SUCCESS_MSG}
			</CastorAlert>
			<CastorAlert
				show={showStartOver}
				alertType={AlertType.WARNING}
				headerTitle={COMPLETE_UPLOAD_FAILED_TITLE}
				onConfirm={handleCompleteFailed}
				onCancel={handleStartOver}
				confirmOptionalText={YES}
				cancelOptionalText={START_OVER}
			>
				{COMPLETE_UPLOAD_FAILED_MSG}
			</CastorAlert>
			<AdBlock />
		</NavBarAndMaterial>
	)
}

export default memo(UploadingProject)
