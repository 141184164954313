import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { NotificationSilentType } from './PushNotificationEnum'
import {
	PushNotificationContent,
	PushNotificationSilentContent
} from './PushNotificationService'
import {
	FAILED_PUSH_NOTIFICATION,
	FAILED_SILENT_PUSH_NOTIFICATION,
	GET_PUSH_NOTIFICATIONS,
	HANDLE_NOTIFICATION_CONNECTION,
	HANDLE_NOTIFICATION_CONNECTION_SILENT,
	LAST_MESSAGE_TIMESTAMP_UPDATE,
	PUSH_NOTIFICATIONS_POPUP
} from './PushNotificationTypes'
import {
	CLOSE_SSE_CONNECTIONS,
	HANDLE_NOTIFICATION,
	QUICK_PROJECT_AWAITING_MAPPING,
	QUICK_PROJECT_FAILED,
	QUICK_PROJECT_PUBLISHED,
	SETUP_SSE_CONNECTIONS,
	UPLOAD_CHECK_STATUS,
	UPLOAD_CLEAR_STATUS
} from 'global actions/types'
import { store } from 'index'
import { ProjectStatus } from 'Services/models/IProject'
import { updateNotifications } from 'Services/Network/push-notification'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const getPushNotifications = (
	pushNotifications: Array<PushNotificationContent>
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: GET_PUSH_NOTIFICATIONS,
			payload: {
				pushNotifications
			}
		})
	}
}

export const failedSilentConnectionNotifications = (projectId: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: FAILED_SILENT_PUSH_NOTIFICATION,
			payload: {
				projectId
			}
		})
	}
}

export const failedConnectionNotifications = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: FAILED_PUSH_NOTIFICATION
		})
	}
}

export const setupSSEConnection = (eventSource: any) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SETUP_SSE_CONNECTIONS,
			payload: {
				eventSource
			}
		})
	}
}

export const updateTimeStamps = (url: string, time: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: LAST_MESSAGE_TIMESTAMP_UPDATE,
			payload: {
				url,
				time
			}
		})
	}
}

export const closeSSEConnection = (url: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CLOSE_SSE_CONNECTIONS,
			payload: {
				url: url
			}
		})
	}
}
export const getPushNotificationsSilentUpload = (
	pushNotificationsSilent: PushNotificationSilentContent,
	projectId: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		switch (pushNotificationsSilent.messageType) {
			case NotificationSilentType.NOTIFICATION_PROJECT_UPLOADING:
				if (
					projectId !== pushNotificationsSilent?.projectId &&
					projectId !== pushNotificationsSilent?.bundleId
				) {
					dispatch({
						type: UPLOAD_CLEAR_STATUS,
						payload: { id: projectId }
					})
					return
				}

				dispatch({
					type: UPLOAD_CHECK_STATUS,
					payload: {
						id: projectId,
						...pushNotificationsSilent?.messageParams,
						analyzingStatus:
							typeof pushNotificationsSilent?.messageParams?.analyzingStatus !==
							'object'
								? JSON.parse(
										pushNotificationsSilent?.messageParams?.analyzingStatus
								  )
								: pushNotificationsSilent?.messageParams?.analyzingStatus || {}
					}
				})

				if (
					pushNotificationsSilent.messageParams.projectStatus ===
					ProjectStatus.published
				) {
					dispatch({
						type: QUICK_PROJECT_PUBLISHED
					})
				}

				if (
					pushNotificationsSilent.messageParams.projectStatus ===
					ProjectStatus.failed
				) {
					dispatch({
						type: QUICK_PROJECT_FAILED
					})
				}

				if (
					pushNotificationsSilent.messageParams.projectStatus ===
					ProjectStatus.awaitingMapping
				) {
					dispatch({
						type: QUICK_PROJECT_AWAITING_MAPPING
					})
				}

				break
			default:
				return
		}
	}
}

export const setReconnectTrigger = (isUpdate: boolean) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: HANDLE_NOTIFICATION_CONNECTION,
			payload: isUpdate
		})
	}
}

export const setReconnectSilentTrigger = (isUpdate: boolean) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: HANDLE_NOTIFICATION_CONNECTION_SILENT,
			payload: isUpdate
		})
	}
}

export const toggleNotificationView = (toggleOpen: boolean) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const { isOpen, newMessagesAmount } =
			store.getState()?.PushNotificationReducer

		if (toggleOpen !== isOpen) {
			dispatch({
				type: PUSH_NOTIFICATIONS_POPUP,
				payload: {
					isOpen: toggleOpen
				}
			})

			if (!toggleOpen && newMessagesAmount > 0) {
				try {
					const userId: number = store.getState()?.user?.userDetails?.id
					if (userId && isOpen !== toggleOpen) {
						const update = await updateNotifications(userId)
						dispatch({
							type: HANDLE_NOTIFICATION_CONNECTION,
							payload: true
						})
						if (!update) {
							dispatch({
								type: HANDLE_NOTIFICATION,
								payload: {
									notificationType: SHOW_NOTIFICATION.ERROR,
									notificationMessage: getString('CANNOT_UPDATE_THE_MESSAGES')
								}
							})
						}
					}
				} catch (err) {
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.ERROR,
							notificationMessage: getString('CANNOT_UPDATE_THE_MESSAGES')
						}
					})
					dispatch({
						type: HANDLE_NOTIFICATION_CONNECTION,
						payload: false
					})
				}
			}
		}
	}
}
