import { Bundle } from './IBundle'
import { Material } from './IMaterial'
import { FormatType } from './IPart'
import { IOwner } from './IUser'
import { LengthUnit } from 'Services/UnitsConversionService'

export enum ProjectCADType {
	MODEL = 'MODEL',
	DRAWING = 'DRAWING',
	MIXED = 'MIXED'
}

export enum UnitType {
	mm = 'mm',
	cm = 'cm',
	inch = 'inch',
	meter = 'meter',
	feet = 'feet'
}

export enum ProjectClusterStatus {
	awaitingAnalysis = 'awaitingAnalysis',
	awaitingRequest = 'awaitingRequest',
	failed = 'failed',
	noClustersFound = 'noClustersFound',
	complete = 'complete'
}

export enum ProjectWeightReductionStatus {
	awaitingAnalysis = 'awaitingAnalysis',
	failed = 'failed',
	noWeightReductionFound = 'noWeightReductionFound',
	complete = 'complete'
}

export enum ProjectStatus {
	pausedBeforeExplode = 'pausedBeforeExplode',
	awaitingUnitConversion = 'awaitingUnitConversion',
	awaitingAssemblyAnalysis = 'awaitingAssemblyAnalysis',
	awaitingExplode = 'awaitingExplode',
	awaitingAnalysis = 'awaitingAnalysis',
	awaitingMapping = 'awaitingMapping',
	waiting = 'waiting',
	complete = 'complete',
	approved = 'approved',
	published = 'published',
	failed = 'failed'
}

//TODO: remove any
export interface Project {
	id: string
	formatType: FormatType
	name: string
	bucket: string
	ownerId: number
	owner: IOwner
	CADSoftware: string
	ERP: string
	quantity: number
	externalId: string
	assemblyFileURL: string
	assemblyImage: string
	isAssembly: boolean
	offerMultiplePartsIntoOne: boolean
	analysisPriority: number
	status: ProjectStatus
	unitType: LengthUnit
	application: string
	defaultMaterialId: number
	defaultMaterial: Material
	toleranceIncluded?: boolean
	lockedParts: number
	bomFileURL: string
	bomFileHandled: boolean
	clusterStatus: ProjectClusterStatus
	weightReductionThreshold: number
	weightReductionMinimumThicknessThreshold: number
	thresholdPartComplexScore: number
	initialPartsCount: number
	nextScheduledResultCheck: any
	filters: any
	weightReductionStatus: any
	reductions: number
	recalculateClusters: any
	recalculateSolutions: any
	recalculateProject: any
	showDrawingStatusWarn: any
	allPartsAnalyzed: boolean
	createdAt: string
	updatedAt: string
	bundleId: string | null
	bundle: Bundle
	isSpecifiedQuantity: boolean
	clusters?: any
	parts?: any
	reanalyzingStatus: string
	metadataParts: number
	totalParts: number
	orderDate: string
	userProjectFolderId: string | null
	forcePublished: boolean
	organizationId: number | null
	isCastorTwo: boolean
}

export enum TrayOrientationStatus {
	PRINTABLE = 'PRINTABLE',
	FAILED = 'FAILED'
}
