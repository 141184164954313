import React, { FC } from 'react'

import { TabsItem } from '../../ProjectAnalysisSuggestionService'
import { opportunitiesResults } from '../../ProjectSuggestionAnalysisChart/ProjectResultDistributionService'
import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import {
	PART_RESULTS_OPTIONS_CHALLENGES,
	PART_RESULTS_OPTIONS_OPPORTUNITIES,
	PROJECT_SUMMARY_PDF_ROW_NAMES
} from 'Services/Strings'

type IProps = {
	projectName: string
	opportunitiesData: Record<string, number>
	userDetails: Record<string, number>
	totalPartsCount: number
	selectedTab: TabsItem
}

const ProjectSummaryPDF: FC<IProps> = ({
	projectName,
	opportunitiesData,
	selectedTab,
	userDetails,
	totalPartsCount
}) => {
	const selectedData = opportunitiesData
	const titleKey: any = PART_RESULTS_OPTIONS_OPPORTUNITIES

	const opportunityTitles: any = titleKey.reduce(
		(acc: Record<string, string>, partResultOption: any) => {
			acc[partResultOption.value] = partResultOption.title
			return acc
		},
		{}
	)

	return (
		<>
			<div className="export-pdf--project-summary">
				<div>
					<div className="export-pdf--project-summary--row">
						{PROJECT_SUMMARY_PDF_ROW_NAMES.userName}:{' '}
						<span>{userDetails.name}</span>
					</div>
					<div className="export-pdf--project-summary--row">
						{PROJECT_SUMMARY_PDF_ROW_NAMES.company}:{' '}
						<span>{userDetails.company}</span>
					</div>
					<div className="export-pdf--project-summary--row">
						{PROJECT_SUMMARY_PDF_ROW_NAMES.email}:{' '}
						<span>{userDetails.email}</span>
					</div>
					<div className="export-pdf--project-summary--row">
						{PROJECT_SUMMARY_PDF_ROW_NAMES.projectName}:{' '}
						<span>{projectName}</span>
					</div>
					<div className="export-pdf--project-summary--row">
						{PROJECT_SUMMARY_PDF_ROW_NAMES.numberOfParts}:{' '}
						<span>{totalPartsCount}</span>
					</div>
				</div>
				<div>
					<>
						{Object.keys(opportunitiesResults).map(key => {
							const title = opportunityTitles[key]
							return (
								<div className="export-pdf--project-summary--row" key={key}>
									{title}:<span>{selectedData[key]}</span>
								</div>
							)
						})}
					</>
				</div>
			</div>
			<LineBreak />
		</>
	)
}

export default ProjectSummaryPDF
