import React, { FC, memo } from 'react'

import cx from 'classnames'
import { capitalize, upperCase } from 'lodash'

import { getString } from '../../../../../../../../../Services/Strings/StringService'
import IconFactory from '../../../../../../../../Components/StarIcon/IconFactory'
import { getAllDataForTMResults } from './LeadTimeService'
import { manufacturingMethodStrings } from 'Services/Constants'
import { ManufacturingMethod } from 'Services/models/IConfiguration'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'

interface IProps {
	configuration: any
	solution: any
	timeBenefit: any
	isStandard: any
	methodText: any
	isAmOriginalMaterial: boolean
}

const LeadTimeChartExplanation: FC<IProps> = ({
	configuration,
	solution,
	timeBenefit,
	isStandard,
	methodText,
	isAmOriginalMaterial
}) => {
	const {
		comparedResults,
		mainResults,
		tmResults = comparedResults,
		amResults = mainResults
	} = configuration?.leadTimeResults
	const { firstTmDay, firstTmPart } = getAllDataForTMResults(
		amResults,
		tmResults
	)
	const manufacturingMethodString: ImanufacturingTypes =
		configuration.mainManufactureMethod === ManufacturingMethod.threeD
			? ImanufacturingTypes.AM
			: configuration.mainManufactureMethod

	const suggestionType = getString(
		manufacturingMethodStrings[manufacturingMethodString]
	)

	const solLeadTimeDetails =
		solution?.leadTimeDetails ||
		configuration?.leadTimeResults?.mainLeadTimeDetails
	return (
		<div className="cost-comparison-tab--information--cost-explenation lead-time-results">
			<div className="text-with-benefit">
				<IconFactory
					iconName={'time'}
					className={cx('cost-comparison-tab--cost-explenation--icon', {
						'not-active': !timeBenefit
					})}
				/>
				{getString('PARTS_DELIVERED_IN_DAYS').format(
					solLeadTimeDetails?.firstShipments,
					configuration?.quantity,
					solLeadTimeDetails?.firstLeadTime,
					suggestionType
				)}
			</div>
			{!isStandard && !isAmOriginalMaterial && (
				<div className="text-with-explanation">
					{getString('PARTS_DELIVERED_IN_DAYS').format(
						firstTmPart,
						configuration?.quantity,
						firstTmDay,
						methodText
					)}
				</div>
			)}
		</div>
	)
}

export default memo(LeadTimeChartExplanation)
