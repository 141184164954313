import { Material } from 'Services/models/IMaterial'
import { Part } from 'Services/models/IPart'
import { IPriority } from 'Services/models/IPriority'
import { ISolution } from 'Services/models/ISolution'

export enum SuggestionType {
	cast = 'cast',
	lathe = 'lathe',
	AM = 'AM'
}
//TODO remove any
export type AssemblingParams = {
	totalTime?: number
	totalCost?: number
}
export enum ManufacturingMethod {
	mold = 'mold',
	cnc = 'cnc',
	lathe = 'lathe',
	standardCost = 'standardCost',
	cast = 'cast',
	investmentCast = 'investmentCast',
	sandCast = 'sandCast',
	threeD = '3d'
}
export interface IConfiguration {
	costSavingAmount?: number
	costSavingPercentage?: number
	weightReductionDensity?: number
	weightReducedVolume?: number
	partReductionsCount?: number
	partVolume?: number
	suggestionType?: string
	material: Material
	solution: ISolution
	part: Part
	cluster: Part
	name: string
	data: any
	filters: Record<string, unknown>
	priorities: IPriority[]
	initialPriorities: IPriority[]
	postProcessesOptional: any
	quantity: number
	type: any
	inHouseOn: boolean
	starred: boolean
	hide: boolean
	result: string
	wallThicknessScore: number
	wallThicknessTestInMM: number
	wallThickessModelURL: string
	heatMapImageURL: string
	wallThicknessFailReason: string
	forcePassingWallThicknessLookup: boolean
	failReason: string
	structuralScore: number
	tolerancesScore: number
	overhangingScore?: number
	orientedCNCScore?: number
	structuralLimitations: any
	isImportant: boolean
	manufactureMethod: ManufacturingMethod
	sentToPrint?: any
	failOnSize: boolean
	alternativeSolutions: any
	costResults: any
	leadTimeResults: any
	trayOrientation: any
	cncPrintingData: any
	supportVolume: number
	outsourcedCNCManufacturing: boolean
	quantityOfCost: number
	isAffectingPart: boolean
	unlockLeadTimeGraph: any
	isTooling: boolean
	resultType: number
	expectedYearsOfDemand: any
	firstAnalysisEnded: boolean
	order: number
	mergers: any
	chainBenefits: any
	fullTrayAssumption: any
	id: number
	createdAt: string
	updatedAt: string
	optionalPostProcess: any
	isSpecifiedQuantity: boolean
	grabCadPrintConfiguration?: boolean
	standardCost: number
	isLeading: boolean
	skippedInitialAnalysis: boolean
	loadingInitialAnalysis: boolean
	productLifeCycle: any
	traditionalCostCalculatedCustom?: boolean
	comparedSolutionCostCalculatedCustom?: boolean
	assemblingParams: null | AssemblingParams
	volume: number
	clusterCombinedCostDetails?: Record<string, any>
	organizationId: number
	printersLayerThickness: Record<number, any>
	mainManufactureMethod: ManufacturingMethod
}
