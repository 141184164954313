import React, { memo } from 'react'

import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'
import cx from 'classnames'

import PdfViewer from './PdfViewer/PdfViewer'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import ThreeDButton from 'Scenes/Components/ThreeDButton/ThreeDButton'
import { PART_IMAGES_TABS_INDEX, partResults } from 'Services/Constants'
import { CHOOSE_ORIENTATION_BUTTON } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const { ORIENTATION_TAB_INDEX } = PART_IMAGES_TABS_INDEX

interface Props {
	show3dButton: Boolean
	showHeatMap: boolean
	solutionPartImageIndex: number
	show3dModalIframe: () => void
	showHeatMapButton: Boolean
	onShowHeatToggle: Function
	onChoseOrientationClick: Function
	configurationId: number
	tourStepIndex: number
	freezeConfiguration: boolean
	customConfiguration: boolean
	isCluster: boolean
	configurationResult?: string
	showOrientationButton?: boolean
	loadingOrientation?: boolean
	isCastorTwo: boolean
	combinePdfUrl?: string
	showPDFModal: boolean
	onClosePDFModal: () => void
	onOpenPDFModal: () => void
}

const render3dButton = (
	show3dButton: Boolean,
	show3dModalIframe: () => void,
	configurationId: number
) => {
	if (!show3dButton) {
		return <div />
	}
	return (
		<ThreeDButton
			configurationId={configurationId}
			show3dModalIframe={show3dModalIframe}
			buttonClassName="threeD-button part-image-container--structural show"
			iconClassName="threeD-button-lable"
		/>
	)
}
const renderOrientationButton = (
	onChoseOrientationClick: Function,
	solutionPartImageIndex: number,
	isCluster: boolean,
	configurationResult?: string,
	loadingOrientation?: boolean
) => {
	const showSolutionOrientation =
		!isCluster &&
		(solutionPartImageIndex === ORIENTATION_TAB_INDEX ||
			configurationResult === partResults.notPrintable)

	return (
		<ButtonWithLoader
			qaDataElementName="data-qa-choose-orientation-btn"
			color="primary"
			onClick={onChoseOrientationClick}
			className={cx('orientation-button part-image-container--orientation', {
				show: showSolutionOrientation
			})}
			loading={loadingOrientation}
			muiClasses={{
				label: '',
				root: ''
			}}
		>
			{CHOOSE_ORIENTATION_BUTTON}
		</ButtonWithLoader>
	)
}

const render2DButton = (
	isCastorTwo: boolean,
	showPDFModal: boolean,
	onClosePDFModal: () => void,
	onOpenPDFModal: () => void,
	combinePdfUrl?: string
) => {
	if (!isCastorTwo || !combinePdfUrl) {
		return null
	}
	return (
		<>
			<Button
				data-qa="data-qa-part-analysis-2d-button"
				color="primary"
				onClick={onOpenPDFModal}
				className={cx('three-d--button pdf-button')}
				muiClasses={{
					label: '',
					root: ''
				}}
			>
				<PictureAsPdfOutlinedIcon className={cx('three-d--icon pdf-icon')} />
				{getString('PDF')}
			</Button>
			<PdfViewer
				onCloseModal={onClosePDFModal}
				showModal={showPDFModal}
				partImageSrc={''}
				fileUrl={combinePdfUrl}
			/>
		</>
	)
}

const renderLeftButton = (
	onChoseOrientationClick: Function,
	freezeConfiguration: boolean,
	solutionPartImageIndex: number,
	isCluster: boolean,
	configurationResult?: string,
	showOrientationButton?: boolean,
	loadingOrientation?: boolean
) => {
	if (freezeConfiguration || !showOrientationButton) {
		return <div />
	}
	return renderOrientationButton(
		onChoseOrientationClick,
		solutionPartImageIndex,
		isCluster,
		configurationResult,
		loadingOrientation
	)
}

const SolutionPartImageButton: React.FC<Props> = ({
	show3dButton,
	show3dModalIframe,
	onChoseOrientationClick,
	configurationId,
	freezeConfiguration,
	configurationResult,
	solutionPartImageIndex,
	isCluster,
	showOrientationButton = true,
	loadingOrientation,
	isCastorTwo,
	combinePdfUrl,
	showPDFModal,
	onClosePDFModal,
	onOpenPDFModal
}: Props) => {
	return (
		<Flexbox
			alignItems="center"
			justifyContent="space-between"
			width="100%"
			className={`part-image-container--bottom-buttons-wrapper part-image-container--structural show`}
		>
			{showOrientationButton &&
				renderLeftButton(
					onChoseOrientationClick,
					freezeConfiguration,
					solutionPartImageIndex,
					isCluster,
					configurationResult,
					showOrientationButton,
					loadingOrientation
				)}
			{render2DButton(
				isCastorTwo,
				showPDFModal,
				onClosePDFModal,
				onOpenPDFModal,
				combinePdfUrl
			)}
			{render3dButton(show3dButton, show3dModalIframe, configurationId)}
		</Flexbox>
	)
}

export default memo(SolutionPartImageButton)
