import React, { FC, memo, useCallback, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import cx from 'classnames'

import Card from '../../../../Components/Card/Card'
import { getPartStatusAndIcon } from '../PartsListView/PartsListViewService'
import {
	onPartClick,
	onPartPropertiesClick,
	onToggleGrabCad
} from '../ProjectAnalysisSuggestionActions'
import {
	getWeightReductionRate,
	renderConsolidationDeletionAlert,
	stopEvent
} from '../ProjectAnalysisSuggestionPartHelpers'
import { TabsItem } from '../ProjectAnalysisSuggestionService'
import PartCardActionIcons from './PartCardActionIcons'
import PartCardFooter from './PartCardFooter'
import { onRemovePartClick, onRemovePartConfirm } from 'global actions'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import FlexBox from 'Scenes/Components/FlexBox'
import { starPartClicked } from 'Scenes/Components/StarIcon/StarIconActions'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import { InapplicableStatus } from 'Services/Constants'
import {
	CLUSTER_ANALYSIS_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { setStringItemToLocalStorage } from 'Services/LocalStorageService'
import { Feature, FeatureComponentId } from 'Services/models/Features'

interface Props {
	suggestion: Record<string, any>
	starred: boolean
	cardClassName?: string
	cardSizeWrapperClassName?: string
	cardContentClassName?: string
	children?: JSX.Element[] | JSX.Element | string
}

const ProjectPartItem: FC<Props> = ({
	suggestion,
	cardClassName = '',
	cardSizeWrapperClassName = '',
	cardContentClassName = '',
	children,
	starred
}) => {
	const { isBundle } = useSelector(
		(state: RootStateOrAny) => state.ProjectPageReducer
	)
	const { paginationData: { page = 0 } = {}, selectedTab } = useSelector(
		(state: RootStateOrAny) =>
			isBundle
				? state.ProjectBundleSuggestionReducer
				: state.ProjectSuggestionReducer
	)

	const dispatch = useDispatch()
	const [showAlert, setShowAlert] = useState(false)
	const user = useSelector((state: RootStateOrAny) => state.user)
	const history = useHistory()

	const {
		id,
		name,
		part,
		cluster,
		costSavingAmount,
		costSavingPercentage,
		material,
		suggestionType,
		resultType
	} = suggestion

	const rowPart = part || cluster
	const partNumber = rowPart?.partNumber
	let imageURL = rowPart?.imageURL

	if (rowPart?.latheAnalysisData) {
		if (resultType === ConfigurationResultTypes.LatheSplit) {
			imageURL = rowPart.latheAnalysisData.combinedImageURL
		}
	}

	const { partIsFailed, isPartNotCostEffective } = getPartStatusAndIcon(
		rowPart?.status,
		rowPart?.result,
		false
	)
	const showRemovePart = Feature.isFeatureActive(FeatureComponentId.DELETE_PART)

	const allowRemoveAssemblyPart = Feature.isFeatureActive(
		FeatureComponentId.DELETE_ASSEMBLY_PART
	)

	const showRemove = suggestion?.parentAssemblyId
		? allowRemoveAssemblyPart
		: showRemovePart

	const onConfirmRemove = useCallback(() => {
		setShowAlert(false)
		dispatch(onRemovePartConfirm(rowPart.id, rowPart.externalId, isBundle))
	}, [dispatch, rowPart.externalId, rowPart.id, isBundle])

	const onShowModalOrRemove = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			stopEvent(e)
			if (rowPart.isUsedByCluster) {
				setShowAlert(true)
			} else {
				dispatch(onRemovePartClick(rowPart.id, partNumber))
			}
		},
		[dispatch, rowPart.id, rowPart.isUsedByCluster, partNumber]
	)

	const onPartRowClick = () => {
		const failedAndNonAm = selectedTab === TabsItem.opportunities

		if (partIsFailed && !isPartNotCostEffective && !failedAndNonAm) return

		if (
			rowPart?.isDrawing &&
			rowPart?.drawingStatusCode === InapplicableStatus
		) {
			dispatch(onPartPropertiesClick(rowPart.projectId))
		}

		const id = rowPart?.id
		const configId = suggestion?.id

		configId && setStringItemToLocalStorage(id, configId?.toString())

		if (isPartNotCostEffective || !rowPart.clusterId) {
			dispatch(onPartClick(rowPart, false, user, undefined, failedAndNonAm))
			return
		}

		history.push(
			`${USER_HOME_ROUTE}${CLUSTER_ANALYSIS_ROUTE}/${rowPart.projectId}/${rowPart.id}`
		)
	}

	const onStarPartClicked = () => dispatch(starPartClicked(rowPart))

	const isSameName = name === partNumber

	return (
		<ErrorBoundary extra="ProjectPartItem">
			<div
				className="card"
				data-qa="data-qa-part-thumbnail-view"
				style={{ cursor: 'pointer', height: '100%' }}
				onClick={() => onPartRowClick()}
			>
				<div className="section-header">
					<div className="section-header--text">
						<FlexBox
							flexDirection="column"
							alignItems="flex-start"
							className="title"
							title={name}
						>
							{isSameName ? <span></span> : <span>{name}</span>}
							{!!partNumber && (
								<span
									className="suggestion-parts-list--part-name"
									title={partNumber}
								>
									{partNumber}
								</span>
							)}
						</FlexBox>
						{material?.name && (
							<div
								title={material?.name}
								className={cx('', { defaultText: false })}
							>
								{material?.name}
							</div>
						)}
					</div>
					<div>
						<PartCardActionIcons
							starred={rowPart.starred || starred}
							onStarClicked={onStarPartClicked}
							onRemoveClicked={onShowModalOrRemove}
							showRemove={showRemove && !cluster}
						/>
					</div>
				</div>
				<Card
					selectedGrabCad={suggestion.grabCadPrint}
					withGrabCad={!suggestion.isDrawing}
					onToggleGrabCad={() => {
						dispatch(onToggleGrabCad(suggestion.id, suggestion.grabCadPrint))
					}}
					isCard={false}
					className={cardClassName}
					sizeWrapperClassName={cardSizeWrapperClassName}
					contentClassName={cardContentClassName}
					key={id}
					imageUrl={imageURL}
					isContentTextInclined={false}
					imageAlt="..."
					partFormat={suggestion?.formatType}
					extraTitles={getWeightReductionRate(suggestion) || []}
					contentCustomComponent={children ? <div>{children || ''}</div> : null}
					dataQATitle={partNumber}
					footer={
						<PartCardFooter
							suggestion={suggestion}
							costSavingAmount={costSavingAmount?.toFixed(1)}
							costSavingPercentage={costSavingPercentage?.toFixed(1)}
							suggestionType={suggestionType}
						/>
					}
				/>
				{showAlert &&
					renderConsolidationDeletionAlert({
						show: showAlert,
						onCancel: () => setShowAlert(false),
						onConfirm: onConfirmRemove
					})}
			</div>
		</ErrorBoundary>
	)
}

export default memo(ProjectPartItem)
