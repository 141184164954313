import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import { onChangeProjectTab } from './ProjectAnalysisSuggestionActions'
import { TabsItem } from './ProjectAnalysisSuggestionService'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	active: TabsItem
}

export interface ITab {
	tabTitle: string
	tabKey: string
}

const ProjectSuggestionTabTitle: FC<IProps> = ({ active }) => {
	const dispatch = useDispatch()
	const { isBundle } = useSelector(
		(state: RootStateOrAny) => state.ProjectPageReducer
	)
	const { projectNoOpportunitiesList, projectChallengesList } = useSelector(
		(state: RootStateOrAny) =>
			isBundle
				? state.ProjectBundleSuggestionReducer
				: state.ProjectSuggestionReducer
	)
	return (
		<div className="project-tab">
			{getString('PROJECT_TAB_STRINGS')?.map((tab: ITab) => {
				const isActive = tab.tabKey === active
				return (
					<div
						key={tab.tabKey}
						onClick={() => {
							if (!isActive) dispatch(onChangeProjectTab(tab.tabKey))
						}}
						className={cx('project-tab_item', {
							active: tab.tabKey === active,
							disabled:
								(tab.tabKey === TabsItem.others &&
									projectNoOpportunitiesList.length === 0) ||
								(tab.tabKey === TabsItem.challenges &&
									projectChallengesList.length === 0)
						})}
					>
						{tab.tabTitle}
					</div>
				)
			})}
		</div>
	)
}

export default memo(ProjectSuggestionTabTitle)
