import React, { Component, ErrorInfo } from 'react'

import cx from 'classnames'
import { toString } from 'lodash'

import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getTheme } from 'themes/getTheme'

import './index.scss'
import { getString } from 'Services/Strings/StringService'

const { colors } = getTheme()

interface Props {
	children: any
	hidden?: boolean
	withButton?: boolean
	errorClass?: string
	extra?: string
	errorMessage?: string
	isError?: (value: boolean) => void
}

interface State {
	error: string
}

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			error: ''
		}
	}

	static getDerivedStateFromError(error: Error) {
		const simpleError = error.name || error.message
		// Update state so the next render will show the fallback UI.
		return { error: simpleError? `${error.name}: ${error.message}`: 'Oppps' }
	}

	componentDidCatch(error: Error, info: ErrorInfo) {
		// Show in console error and info
		console.log(error, info)

		// Fallback function
		this.props.isError && this.props.isError(true)
	}

	render() {
		const { error } = this.state
		const {
			errorMessage,
			extra = '',
			children,
			hidden,
			errorClass,
			withButton
		} = this.props

		if (error || errorMessage) {
			const styles = {
				'--sidebar-color': colors.sideBarBackground,
				'--sidebar-opacity-color': colors.sideBarBackgroundOpacity,
				'--sidebar-text-color': colors.sideBarItemText
			}

			return (
				<div
					style={styles as React.CSSProperties}
					data-hidden={toString(!!hidden)}
					className={cx('error-text', errorClass)}
				>
					<h4>{getString('SOMETHING_WENT_WRONG')}</h4>
					<p>
						{extra} {error || errorMessage}
					</p>
					{withButton && (
						<Button
							className="error-with-image__button"
							onClick={() => window.location.reload()}
						>
							{getString('RELOAD_THE_PAGE')}
						</Button>
					)}
				</div>
			)
		}
		return error || children
	}
}

export default ErrorBoundary
