import {
	ADMIN_PROJECT_INFO_ROUTE,
	ADMIN_ROUTE,
	ADMIN_USER_INFO_ROUTE,
	ADMIN_USER_INFO_SUBSCRIPTION_EDIT_ROUTE,
	ALL_CLUSTER_VIEW,
	ALL_WEIGHT_REDUCTION_VIEW,
	CLUSTER_ANALYSIS_ROUTE,
	CLUSTER_PARTS_ADMIN_ROUTE,
	CONFIGURATION_ROUTE,
	CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE,
	CUSTOMIZE_MATERIAL_CASTOR2_ROUTE,
	CUSTOMIZE_MATERIAL_ROUTE,
	CUSTOMIZE_MATERIAL_ROUTE_NEW_PRINTER_MATERIAL,
	CUSTOMIZE_NEW_MATERIAL_ROUTE,
	CUSTOMIZE_NEW_PRINTER_ROUTE,
	CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE,
	FEA_ROUTE,
	FEATURE_TOGGLE_ADMIN_ROUTE,
	LICENSE_CREATOR_ADMIN_ROUTE,
	LICENSE_SCREEN_ADMIN_ROUTE,
	MATERIAL_PRINTER_PAIRS_ADMIN_NEW_ROUTE,
	MATERIAL_PRINTER_PAIRS_ADMIN_ROUTE,
	MATERIALS_ADMIN_EDIT_ROUTE,
	MATERIALS_ADMIN_NEW_ROUTE,
	MATERIALS_ADMIN_ROUTE,
	ONBOARDING_WIZARD_ROUTE,
	PARAMETERS_MANAGER_ADMIN_ROUTE,
	PART_ROUTE,
	PRINTER_MATERIALS_ADMIN_EDIT_ROUTE,
	PRINTER_MATERIALS_ADMIN_NEW_ROUTE,
	PRINTER_MATERIALS_ADMIN_ROUTE,
	PRINTER_NAMES_ADMIN_EDIT_ROUTE,
	PRINTER_NAMES_ADMIN_ROUTE,
	PRINTERS_ADMIN_EDIT_ROUTE,
	PRINTERS_ADMIN_NEW_ROUTE,
	PRINTERS_ADMIN_ROUTE,
	PRINTERS_TECHNOLOGY_ADMIN_ROUTE,
	PRINTERS_TECHNOLOGY_EDIT_ADMIN_ROUTE,
	PROJECT_ADMIN_ROUTE,
	PROJECT_NOT_UPLOADED,
	PROJECTS_ROUTE,
	REGISTER_ROUTE,
	RESET_PASSWORD,
	SETTINGS_ADMIN_ROUTE,
	STRINGS_ADMIN_ROUTE,
	UPLOAD_PROJECT_PART_MAPPING,
	UPLOAD_ROUTE,
	USER_HOME_ROUTE,
	USER_SETTINGS,
	USERS_ADMIN_ROUTE,
	VERSION_PAGE_ADMIN_ROUTE
} from './Constants/RoutesConstants'

export const adminProjectsRoute = (userId, page = 1) => {
	if (!userId && !page) {
		return PROJECT_ADMIN_ROUTE
	}
	if (!page) {
		return `${PROJECT_ADMIN_ROUTE}/${userId}`
	}
	if (!userId) {
		return `${PROJECT_ADMIN_ROUTE}/${page}`
	}
	return `${PROJECT_ADMIN_ROUTE}/${userId}/${page}`
}

export const adminUsersRoute = page => {
	if (!page) {
		return USERS_ADMIN_ROUTE
	}
	return `${USERS_ADMIN_ROUTE}/${page}`
}

export const adminPrintersRoute = page => {
	if (!page) {
		return PRINTERS_ADMIN_ROUTE
	}
	return `${PRINTERS_ADMIN_ROUTE}/${page}`
}

export const adminPrintersEditFormRoute = id => {
	if (!id) {
		return PRINTERS_ADMIN_EDIT_ROUTE
	}
	return `${PRINTERS_ADMIN_EDIT_ROUTE}/${id}`
}

export const adminPrintersNewFormRoute = () => {
	return PRINTERS_ADMIN_NEW_ROUTE
}

export const adminMaterialsRoute = page => {
	if (!page) {
		return MATERIALS_ADMIN_ROUTE
	}
	return `${MATERIALS_ADMIN_ROUTE}/${page}`
}

export const adminMaterialsEditFormRoute = id => {
	if (!id) {
		return MATERIALS_ADMIN_EDIT_ROUTE
	}
	return `${MATERIALS_ADMIN_EDIT_ROUTE}/${id}`
}

export const adminMaterialsNewFormRoute = () => {
	return MATERIALS_ADMIN_NEW_ROUTE
}

export const adminPrinterMaterialsRoute = page => {
	if (!page) {
		return PRINTER_MATERIALS_ADMIN_ROUTE
	}
	return `${PRINTER_MATERIALS_ADMIN_ROUTE}/${page}`
}

export const adminPrinterMaterialsEditFormRoute = id => {
	if (!id) {
		return PRINTER_MATERIALS_ADMIN_EDIT_ROUTE
	}
	return `${PRINTER_MATERIALS_ADMIN_EDIT_ROUTE}/${id}`
}

export const adminPrinterMaterialsNewFormRoute = () => {
	return PRINTER_MATERIALS_ADMIN_NEW_ROUTE
}

export const adminPrinterNamesRoute = page => {
	if (!page) {
		return PRINTER_NAMES_ADMIN_ROUTE
	}
	return `${PRINTER_NAMES_ADMIN_ROUTE}/${page}`
}

export const adminPrinterNamesEditFormRoute = id => {
	if (!id) {
		return PRINTER_NAMES_ADMIN_EDIT_ROUTE
	}
	return `${PRINTER_NAMES_ADMIN_EDIT_ROUTE}/${id}`
}

export const adminMaterialPrinterPairsRoute = page => {
	if (!page) {
		return MATERIAL_PRINTER_PAIRS_ADMIN_ROUTE
	}
	return `${MATERIAL_PRINTER_PAIRS_ADMIN_ROUTE}/${page}`
}

export const adminSettingsRoute = () => SETTINGS_ADMIN_ROUTE
export const adminStringsRoute = () => STRINGS_ADMIN_ROUTE
export const adminParametersManagerRoute = () => PARAMETERS_MANAGER_ADMIN_ROUTE
export const adminFeatureToggleRoute = () => FEATURE_TOGGLE_ADMIN_ROUTE
export const adminLicenseCreatorRoute = () => LICENSE_CREATOR_ADMIN_ROUTE
export const adminLicenseScreenRoute = () => LICENSE_SCREEN_ADMIN_ROUTE

export const adminPrintersTechnologyRoute = () =>
	PRINTERS_TECHNOLOGY_ADMIN_ROUTE

export const adminVersionPageRoute = () => VERSION_PAGE_ADMIN_ROUTE

export const adminClusterPartsRoute = (projectId, clusterId) => {
	if (!projectId || !clusterId) {
		return CLUSTER_PARTS_ADMIN_ROUTE
	}
	return `${CLUSTER_PARTS_ADMIN_ROUTE}/${projectId}/${clusterId}`
}

export const adminMaterialPrinterPairsNewFormRoute = () => {
	return MATERIAL_PRINTER_PAIRS_ADMIN_NEW_ROUTE
}

export const adminProjectInfoRoute = id => {
	if (!id) {
		return `${ADMIN_ROUTE}${ADMIN_PROJECT_INFO_ROUTE}`
	}
	return `${ADMIN_ROUTE}${ADMIN_PROJECT_INFO_ROUTE}/${id}`
}
export const adminUserInfoRoute = id => {
	if (!id) {
		return `${ADMIN_ROUTE}${ADMIN_USER_INFO_ROUTE}`
	}
	return `${ADMIN_ROUTE}${ADMIN_USER_INFO_ROUTE}/${id}`
}
export const adminUserSubscriptionEditInfoRoute = id => {
	if (!id) {
		return `${ADMIN_ROUTE}${ADMIN_USER_INFO_SUBSCRIPTION_EDIT_ROUTE}`
	}
	return `${ADMIN_ROUTE}${ADMIN_USER_INFO_SUBSCRIPTION_EDIT_ROUTE}/${id}`
}

export const uploadProjectRoute = () => {
	return homePathRoute(UPLOAD_ROUTE)
}

export const projectNotUploadedRoute = () => {
	return homePathRoute(PROJECT_NOT_UPLOADED)
}

export const customizeRecalculateProjectsRoute = () => {
	return homePathRoute(CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE)
}

export const customizeUser = userId => {
	if (!userId) {
		return homePathRoute(CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE)
	}
	return `${homePathRoute(CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE)}/${userId}`
}

export const customizeUserMaterial = userId => {
	if (!userId) {
		return homePathRoute(CUSTOMIZE_MATERIAL_ROUTE)
	}
	return `${homePathRoute(CUSTOMIZE_MATERIAL_ROUTE)}/${userId}`
}

export const customizeUserMaterialForCastor2 = userId => {
	if (!userId) {
		return homePathRoute(CUSTOMIZE_MATERIAL_CASTOR2_ROUTE)
	}
	return `${homePathRoute(CUSTOMIZE_MATERIAL_CASTOR2_ROUTE)}/${userId}`
}

export const customizeNewUserMaterial = userId => {
	if (!userId) {
		return homePathRoute(CUSTOMIZE_NEW_MATERIAL_ROUTE)
	}
	return `${homePathRoute(CUSTOMIZE_NEW_MATERIAL_ROUTE)}/${userId}`
}

export const customizeNewPrinterMaterial = () => {
	return homePathRoute(CUSTOMIZE_MATERIAL_ROUTE_NEW_PRINTER_MATERIAL)
}

export const customizeNewPrinter = userId => {
	if (!userId) {
		return homePathRoute(CUSTOMIZE_NEW_PRINTER_ROUTE)
	}
	return `${homePathRoute(CUSTOMIZE_NEW_PRINTER_ROUTE)}/${userId}`
}

export const homePathRoute = suffix => {
	return USER_HOME_ROUTE + suffix
}

export const registerRoute = () => {
	return REGISTER_ROUTE
}

export const projectRoute = projectId => {
	return `${USER_HOME_ROUTE + PROJECTS_ROUTE}/${projectId || ':projectId'}`
}

export const partRoute = (projectId, partId) => {
	return `${USER_HOME_ROUTE + PROJECTS_ROUTE}/${
		projectId || ':projectId'
	}/part/${partId || ':partId'}`
}

export const externalProjectRoute = () => {
	return `${PROJECTS_ROUTE}/:projectId/part/:partId/new-configuration`
}

export const externalProjectConfigurationsRoute = () => {
	return `${PROJECTS_ROUTE}/:projectId/part/:partId/configurations/:id`
}

export const externalSettings = () => {
	return USER_SETTINGS
}

export const resetPasswordRoute = () => {
	return `${RESET_PASSWORD}/:userEmail/:token`
}

export const allClustersView = projectId => {
	return `${projectRoute(projectId)}${ALL_CLUSTER_VIEW}`
}

export const allWeightReductionView = projectId => {
	return `${projectRoute(projectId)}${ALL_WEIGHT_REDUCTION_VIEW}`
}

export const partConfigurationRoute = (projectId, partId, configurationId) => {
	return `${partConfigurationsRoute(projectId, partId)}${CONFIGURATION_ROUTE}/${
		configurationId || ':configurationId'
	}`
}

export const partConfigurationsRoute = (projectId, partId) => {
	return `${projectRoute(projectId)}${PART_ROUTE}/${partId || ':partId'}`
}

export const partNewConfigurationsRoute = (projectId, partId) => {
	return `${projectRoute(projectId)}${PART_ROUTE}/${
		partId || ':partId'
	}/new-configuration`
}

export const clusterConfigurationsRoute = (projectId, clusterId) => {
	return `${USER_HOME_ROUTE + CLUSTER_ANALYSIS_ROUTE}/${
		projectId || ':projectId'
	}/${clusterId || ':clusterId'}`
}

export const partConfigurationsAfterFeaRoute = (projectId, partId, feaId) => {
	return `${partConfigurationsRoute(projectId, partId)}${FEA_ROUTE}/${
		feaId || ':feaId'
	}`
}

export const projectAfterFeaRoute = (projectId, feaId) => {
	return `${projectRoute(projectId)}${FEA_ROUTE}/${feaId || ':feaId'}`
}

export const adminPrintersTechnologyEditFormRoute = printerTechName => {
	if (!printerTechName) {
		return PRINTERS_TECHNOLOGY_EDIT_ADMIN_ROUTE
	}
	return `${PRINTERS_TECHNOLOGY_EDIT_ADMIN_ROUTE}/${printerTechName}`
}

export const partPropertiesRoute = projectId => {
	return `${projectRoute(projectId)}/parts-properties`
}

export const projectPartsMappingRoute = projectId => {
	return `${projectRoute(projectId)}${UPLOAD_PROJECT_PART_MAPPING}`
}

export const onboardingWizardRoute = () => {
	return homePathRoute(ONBOARDING_WIZARD_ROUTE)
}
