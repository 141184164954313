import { ReactNode } from 'react'

import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'

export enum ToleranceType {
	SURFACE = 'surface',
	HOLE = 'hole',
	POSITION = 'position',
	CONCENTRICITY = 'concentricity',
	SURFACE_PROFILE = 'surfaceProfile',
	PERPENDICULARITY = 'perpendicularity',
	FLATNESS = 'flatness',
	PARALLELISM = 'parallelism'
}

export enum ToleranceCategory {
	SIZE_TOLERANCES = 'size-tolerances',
	GEOMETRIC_TOLERANCES = 'geometric-tolerances'
}

// Type of object we get from Drawing Engine and save in MachinePart db: Record<ToleranceCategory, DrawingTolerance[]>
export interface DrawingTolerance {
	type: ToleranceType
	surface_area: number
	size?: number
	tolerance: number | { neg: number; pos: number }
	toleranceImageURL?: string
}

// template tolerance object for UI (we get it from static db table)
export interface IDefaultPartDrawingTolerance {
	id: number
	groupName: string
	name: string
	applicableToSurfaceArea: boolean
	bilateralValues: boolean
	category: ToleranceCategory
	type: ToleranceType
}

// Type of value we create for DataSheet
export type ToleranceValueObject = {
	valid: boolean
	value: string | number
	readOnly?: boolean
	component?: ReactNode
	forceComponent?: boolean
	imageURL?: string
}
export type ToleranceValueType = ToleranceValueObject[]

// Type of final object used in UI (made of template + values from DE object)
export type GroupedTolerance = {
	applicableToSurfaceArea: boolean
	bilateralValues: boolean
	category: ToleranceCategory
	groupName: string
	name: string
	type: ToleranceType
	values: ToleranceValueType[]
	error: string
}

// Type of general tolerance options
export type ToleranceOption = {
	key: string
	value: ToleranceClass
	disabled?: boolean
}
