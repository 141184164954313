import { FC } from 'react'
import {
	connect,
	DispatchProp,
	RootStateOrAny,
	useDispatch,
	useSelector
} from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import * as UserActions from '../../../../global actions/UserActions'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { goBackToRelatedCastor2 } from '../CustomizeFormCommon'
import { onInHousePrintersAlertOpen } from '../CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import OrganizationPanel from '../OrganizationPanel/OrganizationPanel'
import FilterContent from './CustomizeFilterContent'
import ThreadContent from './CustomizeThreadContent'
import { setupAdvancedFilters } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import { ADMIN } from 'Scenes/Home/Customize/CustomizeSolutionFilters/CustomizeThreadService'
import {
	ADDITIVE_MANUFACTURING_SETTINGS_ROUTE,
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { IFilter } from 'Services/models/IFilter'
import { IPrinter } from 'Services/models/IPrintersTypes'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import { IUserFilter, IUserFilterNames } from 'Services/models/IUserFilter'
import { getString } from 'Services/Strings/StringService'
import { UserFilterService } from 'Services/UserFilterService'

import './CustomizeSolutionFilters.scss'

const userFilterService = new UserFilterService()

interface IProps {
	filters: IFilter[]
	printers: IPrinter[]
	userFilters: IUserFilter[]
	printingTechnologies: IPrintingTechnology[]
	onFiltersChanged: Function
	change: any
	onInHousePrintersAlertOpen: Function
}

interface IReduxStore {
	user: any
}

const CustomizeSolutionFilters: FC<IProps> = ({
	printers,
	onFiltersChanged,
	userFilters,
	onInHousePrintersAlertOpen,
	filters
}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { tempFilters } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates['admin'] ||
			new AdvancedSettingsInitialState()
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const renderFormHeader = () => {
		return (
			<div className="customize-settings--header">
				<CastorFormHeader
					goBack={() => history.push(goBackToRelatedCastor2())}
					explanationHeader={getString('CUSTOMIZE_EXPLINATION_1_HEADER')}
					explanationArray={getString('CUSTOMIZE_EXPLANATION_ARRAY')}
				/>
				<OrganizationPanel />
			</div>
		)
	}

	const onFilterConfirm = () => {
		const inHouseOn = tempFilters.find(
			(filter: any) => filter.name === IUserFilterNames.inHousePrinters
		)?.checked
		if (!printers.length && inHouseOn) {
			return onInHousePrintersAlertOpen()
		}
		onFiltersChanged(
			tempFilters,
			userFilters,
			undefined,
			adminSelectedOrganizationId
		)
	}

	const returnToDefault = () => {
		const userFilters = userFilterService.initializeFilters(filters)
		onFiltersChanged(
			tempFilters,
			userFilters,
			true,
			adminSelectedOrganizationId
		)
		dispatch(setupAdvancedFilters(ADMIN, true))
	}
	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<CastorForm
				formHeader={renderFormHeader()}
				formTitle={getString('CUSTOMIZE_SOLUTION_FILTER_TITLE')}
				content={
					<>
						<FilterContent
							returnToDefault={returnToDefault}
							onFilterConfirm={onFilterConfirm}
						/>
						<ThreadContent showHeader />
					</>
				}
			/>
		</NavBarAndMaterial>
	)
}

const mapStateToProps = (state: IReduxStore) => {
	const {
		user: {
			filters,
			userFilters,
			printers,
			printingTechnologies,
			onFiltersChanged
		}
	} = state
	return {
		filters,
		userFilters,
		printers,
		printingTechnologies,
		onFiltersChanged
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...UserActions, onInHousePrintersAlertOpen }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizeSolutionFilters)
