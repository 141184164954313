import React, { memo, useEffect, useState } from 'react'

import { isEqual } from 'lodash'

import IconLoaderGif from 'Scenes/Components/CastorIcons/IconLoaderGif'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import { Info } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { PART_IMAGES_TABS_INDEX } from 'Services/Constants'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { OrientationData } from 'Services/models/IOrinetationData'
import { PART_ANALYSIS_WALL_THICKNESS_ANALYZE } from 'Services/Strings'
import { doesImageDontHaveCoordinates } from 'Services/Utils/ImagesTools'

import coordinateImage from 'assets/img/axes_watermark.png'

import './SolutionPartDetails.scss'

const { ORIENTATION_TAB_INDEX } = PART_IMAGES_TABS_INDEX

interface Props {
	chosenOrientation: OrientationData | null
	showImageLoader: boolean
	solutionPartImageIndex: number
	rotatedOrientation: boolean
	formatType?: string
}

const SolutionPartOrientationImage: React.FC<Props> = ({
	chosenOrientation,
	showImageLoader,
	solutionPartImageIndex,
	rotatedOrientation,
	formatType
}: Props) => {
	const imageURL =
		(rotatedOrientation
			? chosenOrientation?.rightAngleImageURL
			: chosenOrientation?.imageURL) || ''

	const renderImage = () => {
		if (showImageLoader) {
			return (
				<>
					<IconLoaderGif />
					<Info>{PART_ANALYSIS_WALL_THICKNESS_ANALYZE}</Info>
				</>
			)
		}

		return (
			<>
				{doesImageDontHaveCoordinates(imageURL) && (
					<img
						className="part-image-container-coordinate"
						src={coordinateImage}
						alt="..."
					/>
				)}
				<PartImageWithFallback
					className="part-image-container-img"
					formatType={formatType}
					src={
						rotatedOrientation
							? chosenOrientation?.rightAngleImageURL
							: chosenOrientation?.imageURL
					}
					alt="..."
				/>
			</>
		)
	}

	return (
		<div
			className={`part-image-container-div part-image-container--orientation ${
				solutionPartImageIndex === ORIENTATION_TAB_INDEX ? 'show' : ''
			}`}
		>
			{renderImage()}
		</div>
	)
}

export default memo(SolutionPartOrientationImage)
