import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'

import { CircularProgress, MenuItem, Select } from '@material-ui/core'

import { getString } from 'Services/Strings/StringService'

const chunk = 30

type IProps = {
	parts2DOptions: Record<string, string>[]
	value: string | ''
	handle2DOptionSelect: (value: string) => void
}

const DrawingSelector: FC<IProps> = ({
	value,
	handle2DOptionSelect,
	parts2DOptions
}) => {
	const [parts2DOptionsList, setParts2DOptionsList] = useState<
		Record<string, string>[]
	>([])
	const [trigger, setTrigger] = useState(false)
	const observerRef = useRef(null)
	const menuRef = useRef(null)

	const hasMore = useMemo(
		() => parts2DOptions.length > parts2DOptionsList.length,
		[parts2DOptions.length, parts2DOptionsList.length]
	)

	useEffect(() => {
		const optionsChunk = parts2DOptions.slice(0, chunk)
		setParts2DOptionsList(optionsChunk)
	}, [parts2DOptions])

	useEffect(() => {
		setTrigger(false)
		const current = observerRef.current
		if (!current) return
		const getNextChunk = () => {
			const optionsChunk = parts2DOptions.slice(
				0,
				parts2DOptionsList.length + chunk
			)
			setParts2DOptionsList(optionsChunk)
		}
		const observer = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting && hasMore) {
					getNextChunk()
				}
			},
			{
				root: menuRef.current,
				rootMargin: '0px',
				threshold: 1.0
			}
		)
		observer.observe(current)
		return () => {
			current && observer.unobserve(current)
		}
	}, [hasMore, parts2DOptions, parts2DOptionsList.length, trigger])

	return (
		<Select
			id={'drawing-selector'}
			className="without-border project-parts-mapping-table-dropdown"
			value={value}
			renderValue={selected => getString(value) || value}
			title={value}
			onChange={event => handle2DOptionSelect(event.target.value as string)}
			onOpen={() => setTrigger(true)}
			inputProps={{
				name: 'simpleSelect',
				id: 'simple-select'
			}}
			MenuProps={{
				PaperProps: {
					style: {
						transform: 'translate3d(0, 0, 0)',
						maxHeight: '80%',
						width: '430px'
					}
				},
				ref: menuRef
			}}
		>
			{parts2DOptionsList?.map((option: Record<string, string>) => {
				return (
					<MenuItem
						key={option.key}
						value={option.value}
						disabled={!!option.disabled}
					>
						{option.value}
					</MenuItem>
				)
			})}
			{hasMore && (
				<MenuItem key="-1" disabled>
					<CircularProgress size={20} style={{ margin: '0 auto' }} />
				</MenuItem>
			)}
			<div key="-2" ref={observerRef} style={{ height: 1 }} />
		</Select>
	)
}

export default memo(DrawingSelector)
