import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	HANDLE_NOTIFICATION,
	TOLERANCE_CANCEL_COST_UPDATE_DATA,
	TOLERANCE_COST_APPLY_TOGGLE,
	TOLERANCE_COST_APPLY_TOGGLE_ALL,
	TOLERANCE_COST_EDIT_ALL_TOGGLE,
	TOLERANCE_COST_EDIT_DATA,
	TOLERANCE_COST_EDIT_TOGGLE
} from 'global actions/types'
import { updateTolerancePrice } from 'Services/Network'
import {
	CONFIGURATION_CALCULATION_ERROR,
	SHOW_NOTIFICATION
} from 'Services/Strings'

export const toleranceEditToggle = (
	isEditButton: boolean,
	toleranceData: Record<string, any>
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: TOLERANCE_COST_EDIT_TOGGLE,
				payload: {
					isEditButton,
					toleranceData
				}
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const toleranceEditAllToggle = (isEditButton: boolean) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: TOLERANCE_COST_EDIT_ALL_TOGGLE,
				payload: {
					isEditButton
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const toleranceApplyToggle = (
	isApplyButton: boolean,
	toleranceData: Record<string, any>
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			let toleranceDataUpdated = {}
			if (toleranceData.editPrice || toleranceData.editToMultiply) {
				const response = await updateTolerancePrice([toleranceData])
				toleranceDataUpdated = response?.data?.defaultToleranceList[0] || {}
			}

			dispatch({
				type: TOLERANCE_COST_APPLY_TOGGLE,
				payload: {
					isApplyButton,
					toleranceDataUpdated: toleranceDataUpdated
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}
export const cancelToleranceEditData = (toleranceData: Record<string, any>) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: TOLERANCE_CANCEL_COST_UPDATE_DATA,
				payload: { toleranceData }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const updateAllToleranceData = (
	toleranceData: Record<string, any>[],
	organizationId?: number
) => {
	let toleranceDataChanged: Record<string, any>[] = []

	for (const tolerance of toleranceData) {
		if (tolerance.editPrice || tolerance.editToMultiply) {
			toleranceDataChanged.push(tolerance)
		}
	}

	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await updateTolerancePrice(toleranceDataChanged)
			const toleranceDataUpdated = response.data.defaultToleranceList
			dispatch({
				type: TOLERANCE_COST_APPLY_TOGGLE_ALL,
				payload: { toleranceData, toleranceDataUpdated }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const editToleranceData = (
	toleranceData: Record<string, any>,
	editPrice: any,
	editToMultiply: any
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: TOLERANCE_COST_EDIT_DATA,
				payload: { editPrice, editToMultiply, toleranceData }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}
