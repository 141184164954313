import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'

import { setupOrganizationDataForWizard } from '../../OnboardingWizardActions'
import { onOrganizationSelect } from 'Scenes/Home/Customize/CustomizeActions'
import { IOrganization } from 'Services/models/IOrganization'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

const OrganizationSelector: FC = () => {
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const { wizardOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { isAdminByRole, availableOrganizations, organizationDetails } =
		useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()

	const handleOrganizationSelect = (value: number) => {
		dispatch(onOrganizationSelect(value))
		dispatch(setupOrganizationDataForWizard(value))
	}

	useEffect(() => {
		if (!adminSelectedOrganizationId) {
			dispatch(onOrganizationSelect(organizationDetails?.id))
		}
	}, [adminSelectedOrganizationId, dispatch, organizationDetails?.id])

	if (
		!isAdminByRole ||
		wizardOrganizationId ||
		availableOrganizations.length <= 1
	) {
		return null
	}

	return (
		<div className="start-screen--organization-selector">
			<span>{getString('REGISTER_FORM_ORGANIZATION_LABEL')}:</span>
			<Select
				className="customize-settings--header--organization--select-field"
				value={adminSelectedOrganizationId}
				renderValue={selected =>
					renderOrganizationValue(availableOrganizations, selected as number)
				}
				title={renderOrganizationValue(
					availableOrganizations,
					adminSelectedOrganizationId
				)}
				onChange={event =>
					handleOrganizationSelect(event.target.value as number)
				}
				classes={{
					select: 'customize-settings--header--organization--select'
				}}
				inputProps={{
					name: 'simpleSelect',
					id: 'simple-select'
				}}
				MenuProps={{
					PaperProps: {
						style: {
							transform: 'translate3d(0, 0, 0)'
						}
					}
				}}
			>
				{availableOrganizations?.map((organization: IOrganization) => {
					return (
						<MenuItem key={organization.id} value={organization.id}>
							{`${organization?.name}${
								organization?.private ? ` (${getString('PRIVATE')})` : ''
							}`}
						</MenuItem>
					)
				})}
			</Select>
		</div>
	)
}

export default OrganizationSelector
