import { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import Numeral from 'numeral'

import { ConfigurationResultTypes } from '../../ConfigurationResultTypes'
import FlexBox from 'Scenes/Components/FlexBox'
import {
	calculate2DRange,
	displayInRange
} from 'Services/global/calculateRange'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import { SuggestionType } from 'Services/models/IConfiguration'
import { CustomFunctionStringCategory } from 'Services/models/IFunctionString'
import { UPLOAD_PROJECT_SCENARIO_METHODS_HEADER } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface TopCostBodyProps {
	userCurrencySign: string
	costDetails: any
	chainBenefits: IChainBenefits
	includeSupplyChainCosts?: boolean
	showCostInRange?: boolean
	drawingCostPercentage?: number
	configurationId: number
	isSpecifiedQuantity: boolean
	costCalculatedWithCustomFunction: Record<
		CustomFunctionStringCategory,
		boolean
	> | null
	isCastorTwo?: boolean
	productLifeCycle?: string
}

const TopCostBody: FC<TopCostBodyProps> = ({
	userCurrencySign,
	costDetails,
	chainBenefits,
	includeSupplyChainCosts,
	showCostInRange,
	drawingCostPercentage,
	configurationId,
	productLifeCycle,
	isSpecifiedQuantity,
	costCalculatedWithCustomFunction,
	isCastorTwo
}) => {
	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES
	)

	const { maxCostDetails, minCostDetails } = costDetails

	const { part, cluster } = useSelector(
		(state: RootStateOrAny) => state.MainPartAnalysisReducer
	)

	const configuration = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer.states[configurationId]
	})

	const { defaultProjectScenarios: userDefaultProjectScenarios } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const { allConfigurationsOrganizationSettings } = useSelector(
		(state: RootStateOrAny) => state.MainPartAnalysisReducer
	)
	const defaultProjectScenarios =
		allConfigurationsOrganizationSettings[configuration.usedOrganization?.id]
			?.defaultProjectScenarios || userDefaultProjectScenarios

	const productLifeCycleKey = defaultProjectScenarios.find(
		(scenario: any) => scenario.name === productLifeCycle
	)?.key
	const productLifeCycleName =
		UPLOAD_PROJECT_SCENARIO_METHODS_HEADER[productLifeCycleKey] ||
		productLifeCycle ||
		UPLOAD_PROJECT_SCENARIO_METHODS_HEADER[
			part?.productLifeCycle || cluster?.productLifeCycle
		]

	const productionCostCalculatedWithCustomFunction =
		costCalculatedWithCustomFunction
			? costCalculatedWithCustomFunction[
					CustomFunctionStringCategory.productionCost
			  ]
			: false

	const configurationBatch = configuration?.initialSolutionBatchSize

	const configurationBatchDisplayValue =
		configurationBatch >= 1000
			? Numeral(configurationBatch).format('0.0a')
			: configurationBatch

	const getTotalProductionCost = (
		userCurrencySign: string,
		costDetails: any,
		showCostInRange?: boolean,
		drawingCostPercentage?: number
	) => {
		const {
			threeDPrintingCostsBreakDown,
			printingCost,
			totalCost: basicTotalCost
		} = costDetails
		const cost =
			threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown?.perPartCosts
				?.totalProductionCostsPerUnit || printingCost
		const minCost =
			minCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
				?.perPartCosts?.totalProductionCostsPerUnit ||
			minCostDetails?.printingCost
		const maxCost =
			maxCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
				?.perPartCosts?.totalProductionCostsPerUnit ||
			maxCostDetails?.printingCost

		let totalCost: string | number = Numeral(cost).format('0,0')

		if (isShowValuesInRanges && minCost && maxCost) {
			totalCost = displayInRange(
				minCost,
				maxCost,
				totalCost,
				null,
				showCostInRange ? drawingCostPercentage : undefined
			)
		}

		if ((!isShowValuesInRanges || (!minCost && !maxCost)) && showCostInRange) {
			totalCost = calculate2DRange(cost, drawingCostPercentage)
		}

		if (
			isCastorTwo &&
			(configuration.suggestionType !== SuggestionType.AM ||
				configuration.resultType === ConfigurationResultTypes.PartConsolidation)
		) {
			totalCost = Numeral(basicTotalCost).format('0,0')

			if (isShowValuesInRanges && maxCostDetails && minCostDetails) {
				totalCost = displayInRange(
					minCostDetails?.totalCost,
					maxCostDetails?.totalCost,
					totalCost,
					null,
					showCostInRange ? drawingCostPercentage : undefined
				)
			}

			if (
				(!isShowValuesInRanges || (!minCostDetails && !maxCostDetails)) &&
				showCostInRange
			) {
				totalCost = calculate2DRange(basicTotalCost, drawingCostPercentage)
			}

			return (
				<div className="detail-body--cost_range">
					{!productionCostCalculatedWithCustomFunction && (
						<div data-qa="data-qa-production-cost-row">
							<b>{getString('PRODUCTION_COST')}</b>
							<b>
								<span>${totalCost}</span>
							</b>
						</div>
					)}
					{productionCostCalculatedWithCustomFunction && (
						<div>
							<span>{getString('TOTAL_COST_OF_OWNERSHIP')}:</span>
							<span data-qa="data-qa-AM-total-cost-value">${totalCost}</span>
						</div>
					)}
					<div className="product-life-cycle">
						<span>{getString('PRODUCT_LIFE_CYCLE')}:</span>
						<span className="detail-body--cost_range--value">
							{productLifeCycleName}
							{!!isSpecifiedQuantity && (
								<span
									className="upper"
									title={`${getString(
										'SEND_TO_SERVICE_BUREAU_QUANTITY_LABEL'
									)}: ${configurationBatchDisplayValue}`}
								>
									(<span>{getString('QTY')}</span>:{' '}
									{configurationBatchDisplayValue})
								</span>
							)}
						</span>
					</div>
				</div>
			)
		}

		return <span>{`${userCurrencySign}${totalCost || basicTotalCost}`}</span>
	}

	const getTotalCost = (
		userCurrencySign: string,
		costDetails: any,
		chainBenefits: IChainBenefits,
		showCostInRange?: boolean,
		drawingCostPercentage?: number
	) => {
		if (
			(chainBenefits && !chainBenefits?.Global?.on) ||
			(isCastorTwo &&
				(configuration.suggestionType !== SuggestionType.AM ||
					configuration.resultType ===
						ConfigurationResultTypes.PartConsolidation))
		) {
			return <div />
		}
		const {
			maxCostDetails,
			minCostDetails,
			totalCost: basicTotalCost
		} = costDetails

		let totalCost: string | number = Numeral(basicTotalCost).format('0,0')

		if (isShowValuesInRanges && maxCostDetails && minCostDetails) {
			totalCost = displayInRange(
				minCostDetails?.totalCost,
				maxCostDetails?.totalCost,
				totalCost,
				null,
				showCostInRange ? drawingCostPercentage : undefined
			)
		}

		if (
			(!isShowValuesInRanges || (!minCostDetails && !maxCostDetails)) &&
			showCostInRange
		) {
			totalCost = calculate2DRange(basicTotalCost, drawingCostPercentage)
		}

		return (
			<>
				<div className="detail-body--cost--secondary">
					<span>{getString('TOTAL_COST')} </span>
					<span>{`${userCurrencySign}${totalCost}`}</span>
				</div>
			</>
		)
	}

	const renderTotalCostInfo = () => {
		const showProductionCostHeader =
			configuration.suggestionType === SuggestionType.AM &&
			includeSupplyChainCosts
		return (
			<>
				<FlexBox width="100%">
					<span className="detail-body--cost--main">
						{showProductionCostHeader ? getString('PRODUCTION') : ''}
					</span>{' '}
					{getTotalProductionCost(
						userCurrencySign,
						costDetails,
						showCostInRange,
						drawingCostPercentage
					)}
				</FlexBox>
				{getTotalCost(
					userCurrencySign,
					costDetails,
					chainBenefits,
					showCostInRange,
					drawingCostPercentage
				)}
			</>
		)
	}

	const renderTotalCostRangesInfo = () => {
		const defaultProduction =
			costDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
				?.perPartCosts?.totalProductionCostsPerUnit || costDetails?.printingCost
		const minTotalProductionCostsPerUnit =
			minCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
				?.perPartCosts?.totalProductionCostsPerUnit ||
			minCostDetails?.printingCost
		const maxTotalProductionCostsPerUnit =
			maxCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
				?.perPartCosts?.totalProductionCostsPerUnit ||
			maxCostDetails?.printingCost

		const defaultTotal = costDetails?.totalCost
		const minTotalCost = minCostDetails?.totalCost
		const maxTotalCost = maxCostDetails?.totalCost

		let totalCostValue = displayInRange(
			minTotalCost,
			maxTotalCost,
			defaultTotal,
			null,
			showCostInRange ? drawingCostPercentage : undefined
		)
		let totalProductionCosts = displayInRange(
			minTotalProductionCostsPerUnit,
			maxTotalProductionCostsPerUnit,
			defaultProduction,
			null,
			showCostInRange ? drawingCostPercentage : undefined
		)

		if (!isShowValuesInRanges && showCostInRange) {
			totalCostValue = calculate2DRange(defaultTotal, drawingCostPercentage)
			totalProductionCosts = calculate2DRange(
				defaultProduction,
				drawingCostPercentage
			)
		}
		return (
			<div className="detail-body--cost_range">
				{!productionCostCalculatedWithCustomFunction && (
					<div data-qa="data-qa-production-cost-row">
						<b>{getString('PRODUCTION_COST')}</b>
						<b>
							<span>${totalProductionCosts}</span>
						</b>
					</div>
				)}
				{(productionCostCalculatedWithCustomFunction ||
					totalProductionCosts !== totalCostValue) && (
					<div>
						<span>{getString('TOTAL_COST_OF_OWNERSHIP')}:</span>
						<span data-qa="data-qa-AM-total-cost-value">${totalCostValue}</span>
					</div>
				)}
				<div className="product-life-cycle">
					<span>{getString('PRODUCT_LIFE_CYCLE')}:</span>
					<span className="detail-body--cost_range--value">
						{productLifeCycleName}
						{!!isSpecifiedQuantity && (
							<span
								className="upper"
								title={`${getString(
									'SEND_TO_SERVICE_BUREAU_QUANTITY_LABEL'
								)}: ${configurationBatchDisplayValue}`}
							>
								(<span>{getString('QTY')}</span>:{' '}
								{configurationBatchDisplayValue})
							</span>
						)}
					</span>
				</div>
			</div>
		)
	}

	// check if Ranges is allowed
	const showRanges = isShowValuesInRanges && maxCostDetails && minCostDetails

	return (
		<div className="detail-body--cost">
			{showRanges ? renderTotalCostRangesInfo() : renderTotalCostInfo()}
		</div>
	)
}

export default memo(TopCostBody)
