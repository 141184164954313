import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import { GridList, GridListTile } from '@material-ui/core'
import cx from 'classnames'

import * as PrintableClustersOverviewActions from './PrintableClustersOverviewActions'
import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from '../../../Services/models/Features'
import { PartStatus } from '../../../Services/models/IPart'
import { clusterConfigurationsRoute } from '../../../Services/routeFuncs'
import { getString } from '../../../Services/Strings/StringService'
import Card from '../../Components/Card/Card'
import { CastorPartBenefits } from '../../Components/CastorPartBenefits/CastorPartBenefits'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import PartCardFooter from '../ProjectAnalysis/ProjectAnalysisPage/PartCard/PartCardFooter'
import DetailsPopup from 'Scenes/Components/DetailsPopup'

import './PrintableClusters.scss'

const GridListTileTSX: any = GridListTile
const GridListTSX: any = GridList
const LinkTSX: any = Link

interface IProps extends RouteComponentProps<MatchParams> {
	clusters: any[]
	setupClustersOverview: (projectId: string) => any
	onPartClick: Function
}

interface IReduxStore {
	PrintableClustersOverviewReducer: any
}

interface MatchParams {
	projectId: string
}

const renderClusterData = (cluster: any) => {
	return (
		<div className="all-clusters--grid--item--subtitle">
			{cluster.materialInfo}
		</div>
	)
}

const renderBenefits = ({ feaResult, benefits }: any) => {
	return (
		<CastorPartBenefits
			feaResult={feaResult}
			benefits={benefits}
			small
			className="grid-list-benefits"
		/>
	)
}

const renderCard = (cluster: any) => {
	return (
		<Card
			imageUrl={cluster.imageURL}
			imageAlt="..."
			title={cluster.name}
			className={cx('all-clusters--card', { dark: cluster.isSameMaterial })}
			contentCustomComponent={renderClusterData(cluster)}
			sizeWrapperClassName={'all-clusters--grid--size-wrapper'}
			footer={
				<PartCardFooter
					isMissingInfo={cluster.status === PartStatus.missingInformation}
					isPartUpdating={cluster.status === PartStatus.awaitingCombinedHeatmap}
					result={cluster.result}
					customBenefitSection={renderBenefits(cluster)}
				/>
			}
		/>
	)
}
const AllClustersView: FC<IProps> = ({
	clusters,
	setupClustersOverview,
	match: {
		params: { projectId }
	}
}) => {
	useEffect(() => {
		if (!clusters?.length) {
			setupClustersOverview(projectId)
		}
	}, [])

	if (!clusters?.length) {
		return <div></div>
	}
	const disabledUnification = !Feature.isFeatureActive(
		FeatureComponentId.UNIFICATION
	)
	return (
		<NavBarAndMaterial title={getString('ADMIN_UNIFICATIONS_PAGE_HEADER')}>
			<div className="all-clusters">
				<GridListTSX cols={4} className="all-clusters--grid">
					{clusters.map(cluster => (
						<GridListTileTSX
							className="all-clusters--grid--item"
							key={cluster.id}
							sm={4}
							classes={{ tile: 'all-clusters--grid--tile' }}
						>
							{!disabledUnification && (
								<LinkTSX
									to={clusterConfigurationsRoute(projectId, cluster.id)}
									state={cluster}
								>
									{renderCard(cluster)}
								</LinkTSX>
							)}

							{disabledUnification && (
								<DetailsPopup
									isHover={disabledUnification}
									data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
									popperDirection="bottom-end"
									popperClassName="info-box-wrapper details-popup--contact-us"
									popperContactClassName="info-box-data"
									targetClassName="all-clusters--popup"
								>
									{renderCard(cluster)}
								</DetailsPopup>
							)}
						</GridListTileTSX>
					))}
				</GridListTSX>
			</div>
		</NavBarAndMaterial>
	)
}

const mapStateToProps = ({
	PrintableClustersOverviewReducer
}: IReduxStore): IProps => ({
	...PrintableClustersOverviewReducer
})

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...PrintableClustersOverviewActions }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WithFeatureToggleHOC(memo(AllClustersView), [FeatureComponentId.UNIFICATION]))
