import { useEffect, useState } from 'react'
import { isSafari } from 'react-device-detect'

import {
	capitalize,
	cloneDeep,
	findKey,
	isEmpty,
	isNil,
	map,
	uniq,
	upperCase
} from 'lodash'

import { seriesNames } from '../../../../../../PartAnalysisTab/PartAnalysisConstants'
import { maxMultiplier } from '../../../../SolutionAnalysisService'
import { getManufacturingNameWihCompare } from '../../SolutionAnalysisTabsService'
import { getStepsValue } from '../LeadTimeTab/LeadTimeService'
import { MAX_LAYER_THICKNESS } from 'Scenes/Components/PrinterForm/constants'
import { amColor, methodColor } from 'Services/colors'
import {
	chartGraphDataOptions,
	manufacturingMethodStrings,
	manufacturingMethodTypes
} from 'Services/Constants'
import {
	calculate2DRange,
	calculate2DRangeArray,
	displayInRange
} from 'Services/global/calculateRange'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import {
	CostData,
	CostDataWithSolution,
	IChartData,
	IOptionalMetalFormParameters
} from 'Services/models/CostComparisonModels'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	IConfiguration,
	ManufacturingMethod
} from 'Services/models/IConfiguration'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { Part } from 'Services/models/IPart'
import { SupplyChainResultObject } from 'Services/models/ISupplyChain'
import { getCosts } from 'Services/Network'
import { getString } from 'Services/Strings/StringService'
import { getFixedSize } from 'Services/Utils/numberService'
import { getTheme } from 'themes/getTheme'

const theme = getTheme()

export const getResultsNumbersAndQuantity = (
	moldingResults: any = {},
	AMResults: any = {},
	AMMinResults: any = {},
	AMMaxResults: any = {},
	drawingPercentage: any
) => {
	let labels: number[] = uniq(
		Object.keys(AMResults)
			.map(key => Math.round(Number(key)))
			.sort((a, b) => a - b)
	)
	const moldingResultsSortedKeys = Object.keys(moldingResults)
		.map(key => Number(key))
		.sort((a: number, b: number) => a - b)

	const molding: (number | undefined)[] = labels.map(
		(label: number, index: number) =>
			moldingResults[label]
				? Number(moldingResults[label]?.toFixed(2))
				: (index > 0 && moldingResults[moldingResultsSortedKeys[index]]) ||
				  undefined
	)

	const amResult: number[] = labels.map((label: number, index: number) =>
		AMResults[label]
			? Number(AMResults[label].toFixed(2))
			: AMResults[Object.keys(AMResults)[index]]
	)

	const amMinMaxResult: number[] = labels.map((label: number) => {
		const minResult = AMMinResults && AMMinResults[label]
		const maxResult = AMMaxResults && AMMaxResults[label]

		return displayInRange(
			minResult,
			maxResult,
			AMResults[label],
			null,
			drawingPercentage
		)
	})

	return {
		labels,
		molding,
		amResult,
		amMinMaxResult
	}
}

const round = (value: number, precision: number) => {
	const multiplier = Math.pow(10, precision || 0)
	return Math.round(value * multiplier) / multiplier
}

const roundLabels = (labels: any) => {
	return labels.map((s: any) => {
		const precision = s > 0 && s < 1 ? 2 : 0

		return round(s, precision)
	})
}

export const getCrossValue = (
	molding: number[],
	labels: number[],
	quantityOfCost: number
) => {
	let crossValue = 0
	let printCostPointIndex = -1

	if (quantityOfCost) {
		printCostPointIndex = labels.indexOf(Math.round(quantityOfCost))
		crossValue = molding[printCostPointIndex]
	}

	return {
		printCostPointIndex,
		crossValue
	}
}

export const removingDuplicates = (
	labels: number[],
	molding: (number | undefined)[],
	amResult: number[],
	quantityOfCost: number,
	amMinMaxResult?: string[]
) => {
	// need to filter Nan/undefined/null etc if they are exist
	const moldingArray = map(molding, value => (value ? value : 0))
	const { printCostPointIndex } = getCrossValue(
		moldingArray,
		labels,
		quantityOfCost
	)

	const labelCostPoint = labels[printCostPointIndex]
	let filteredLabels = labels
	let filteredMolding = moldingArray
	let filteredAmResult = amResult
	let filteredAmMinMaxResult = amMinMaxResult
	const truncLabel = Math.floor(labelCostPoint)

	labels.forEach((value, index: number) => {
		// check if we have duplicate and index is not the same as interception
		if (
			index &&
			index !== printCostPointIndex &&
			truncLabel === Math.floor(value)
		) {
			// if duplicate is exist, remember this index for feature
			filteredLabels?.splice(index, 1)
			filteredMolding?.splice(index, 1)
			filteredAmResult?.splice(index, 1)
			filteredAmMinMaxResult?.splice(index, 1)
		}
	})

	return {
		filteredLabels,
		filteredMolding,
		filteredAmResult,
		filteredAmMinMaxResult
	}
}

const getCostSeriesData = (
	labels: number[],
	TMData: number[],
	AMData: number[],
	isStandard: boolean
) => {
	const TMSeriesData = labels.reduce(
		(acc: { x: number; y: number }[], value: number, index: number) => {
			if (!TMData[index] && isStandard) {
				return acc
			} else {
				acc.push({
					x: value,
					y: Math.log10(TMData[index])
				})
				return acc
			}
		},
		[]
	)

	const AMSeriesData = labels.map((label, index) => ({
		x: label,
		y: Math.log10(AMData[index])
	}))

	return { TMSeriesData, AMSeriesData }
}

const findMinMaxLogarithmicValuesForArray = (data: number[]) => {
	const sortedData = [...data].filter(value => value).sort((a, b) => a - b)

	let minLog = Math.log10(sortedData[0])
	let maxLog = Math.log10(sortedData[0])

	for (let i = 1; i < sortedData.length; i++) {
		const currentLog = Math.log10(sortedData[i])
		if (currentLog < minLog) {
			minLog = currentLog
		}
		if (currentLog > maxLog) {
			maxLog = currentLog
		}
	}

	return {
		minLog: Math.pow(10, Math.floor(minLog)),
		maxLog: Math.pow(10, Math.ceil(maxLog))
	}
}

const findMinMaxLogarithmicValuesForChart = (
	TMValues: number[],
	AMValues: number[]
) => {
	const { minLog: minTMLog, maxLog: maxTMLog } =
		findMinMaxLogarithmicValuesForArray(TMValues)
	const { minLog: minAMLog, maxLog: maxAMLog } =
		findMinMaxLogarithmicValuesForArray(AMValues)
	return {
		minLog: Math.min(minTMLog, minAMLog) || 1,
		maxLog: Math.max(maxTMLog, maxAMLog)
	}
}

export class CostComparisonService {
	createCostChartData = (
		results: Record<string, string>,
		actualResult: number,
		solutionQuantity: number,
		manufacturingMethod: string,
		userCurrencySign: string,
		solutionCost: number,
		printCostQuantity: number,
		AMResults: Record<string, string>,
		quantityOfCost: number,
		drawingCostPercentage: number = 1,
		showCostInRange: boolean = false,
		AMMinResults: Record<string, string>,
		AMMaxResults: Record<string, string>,
		blockManufacturingMethodOperation: boolean = false,
		isAmOriginalMaterial: boolean = false,
		isSpecifiedQuantity?: boolean,
		mainManufactureMethod?: ManufacturingMethod
	): IChartData => {
		const { labels, molding, amResult, amMinMaxResult } =
			getResultsNumbersAndQuantity(
				results,
				AMResults,
				AMMinResults,
				AMMaxResults,
				showCostInRange ? drawingCostPercentage : null
			)

		return this.drawChart(
			mainManufactureMethod,
			labels,
			molding,
			actualResult,
			solutionQuantity,
			manufacturingMethod,
			userCurrencySign,
			solutionCost,
			printCostQuantity,
			amResult,
			quantityOfCost,
			isAmOriginalMaterial,
			drawingCostPercentage,
			showCostInRange,
			amMinMaxResult,
			blockManufacturingMethodOperation,
			isSpecifiedQuantity
		)
	}

	drawChart = (
		mainManufactureMethod = ManufacturingMethod.threeD,
		labels: number[],
		molding: (number | undefined)[],
		costAffectivePoint: number,
		quantity: number,
		manufacturingMethod: string,
		userCurrencySign: string,
		solutionCost: number,
		printCostQuantity: number,
		amResult: number[],
		quantityOfCost: number,
		isAmOriginalMaterial: boolean,
		drawingCostPercentage?: number,
		showCostInRange?: boolean,
		amMinMaxResult?: any,
		blockManufacturingMethodOperation?: boolean,
		isSpecifiedQuantity?: boolean
	): IChartData => {
		const mainManufacturingMethodString =
			mainManufactureMethod === ManufacturingMethod.threeD
				? ImanufacturingTypes.AM
				: mainManufactureMethod
		const suggestionTypeText = getString(
			manufacturingMethodStrings[mainManufacturingMethodString]
		)
		const componentChartData: any = cloneDeep(chartGraphDataOptions)
		const minCountOfStep = 5
		const smallQuantity = quantity < 5
		const quantityOffsetY = -5
		const isStandard =
			blockManufacturingMethodOperation ||
			manufacturingMethod === manufacturingMethodTypes.standardCost
		const amResultRound = roundLabels(amResult)
		const moldingRound = isStandard ? molding : roundLabels(molding)

		let series: any[] = []
		let graphLineText = getManufacturingNameWihCompare(manufacturingMethod)

		// filter values
		const { filteredLabels, filteredMolding, filteredAmResult } =
			removingDuplicates(
				labels,
				moldingRound,
				amResultRound,
				quantityOfCost,
				amMinMaxResult
			)
		// check again for new printCostPointIndex
		const { printCostPointIndex } = getCrossValue(
			filteredMolding,
			labels,
			quantityOfCost
		)

		componentChartData.title.text = getString(
			'PART_FINANCIAL_ANALYSIS_CARD'
		).format(suggestionTypeText, graphLineText)

		if (isAmOriginalMaterial && !isStandard) {
			componentChartData.title.text = getString(
				'PART_FINANCIAL_AM_ANALYSIS_CARD'
			)
		}

		componentChartData.yaxis.title.text = getString(
			'PART_FINANCIAL_ANALYSIS_AVARAGE_COST'
		).format(userCurrencySign)

		const maxLabelValue = Math.max(...filteredLabels)

		const { stepValue: stepXValue } = getStepsValue(
			maxLabelValue,
			maxLabelValue,
			isStandard,
			minCountOfStep
		)

		let effectivePointIndex: any = filteredLabels.indexOf(
			isSpecifiedQuantity ? Number(quantity) : 0
		)

		effectivePointIndex =
			effectivePointIndex >= 0
				? effectivePointIndex
				: filteredMolding.indexOf(Number(costAffectivePoint.toFixed(2)))

		const { minLog, maxLog } = findMinMaxLogarithmicValuesForChart(
			filteredMolding,
			filteredAmResult
		)

		componentChartData.xaxis = {
			title: {
				text: ''
			},
			categories: [],
			max: maxLabelValue,
			min: 1,
			step: stepXValue,
			tickAmount: stepXValue,
			type: 'numeric',
			labels: {
				show: true,
				formatter: (val: any) => {
					return `${val.toFixed(0)}`
				}
			},
			tooltip: {
				formatter: function (
					val: number,
					{
						dataPointIndex,
						series
					}: { dataPointIndex: number; series: any[][] }
				) {
					if (dataPointIndex === series[0].length) {
						return filteredLabels.at(-1)
					}
					return val
				}
			}
		}

		componentChartData.yaxis = {
			title: {
				text: getString('PART_FINANCIAL_ANALYSIS_AVARAGE_COST').format(
					userCurrencySign
				)
			},
			logarithmic: true,
			tickAmount: Math.log10(maxLog) - Math.log10(minLog),
			min: Math.log10(minLog),
			max: Math.log10(maxLog),
			labels: {
				formatter: function (value: any) {
					if (value < 0) return 0
					if (value === 0) return 1
					return Math.pow(10, value).toFixed(0)
				}
			}
		}

		//remove unnecessary animation
		componentChartData.chart = {
			...componentChartData.chart,
			animations: {
				enabled: false
			}
		}

		// add dotted border
		componentChartData.grid = {
			show: true,
			borderColor: theme.colors.chartLineColor,
			strokeDashArray: 3,
			position: 'back',
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: true
				}
			}
		}

		// if different between dots = 1 or 0 we need to add 15, in another case 0
		const diffOffsetY =
			Math.abs(effectivePointIndex - printCostPointIndex) <= 2 ? 15 : 0
		const getQuantityOffsetX = (
			index: number,
			costEffectivePoint?: boolean
		) => {
			return filteredLabels.length - index <= 2
				? costEffectivePoint
					? -100
					: -80
				: smallQuantity
				? 10
				: 0
		}

		componentChartData.annotations = {
			xaxis: [
				isAmOriginalMaterial
					? {}
					: {
							x: filteredLabels[printCostPointIndex],
							strokeDashArray: 3,
							borderColor: theme.colors.successColor,
							label: {
								borderColor: 'transparent',
								textAnchor: 'start',
								offsetY: quantityOffsetY,
								offsetX: getQuantityOffsetX(printCostPointIndex, true),
								orientation: 'horizontal',
								style: {
									color: '#fff',
									background: theme.colors.successColor
								},
								text: getString('COST_EFFECTIVE_POINT')
							}
					  }
			],
			texts: [
				{
					x: 0,
					y: theme.costAnalysisTextOffset,
					text: getString('PART_FINANCIAL_ANALYSIS_NUMBER_OF_PARTS_PRODUCED'),
					textAnchor: 'start',
					fontSize: '13px',
					fontWeight: 400,
					appendTo: '.apexcharts-xaxis-title',
					backgroundColor: 'transparent'
				}
			]
		}

		if (isSpecifiedQuantity) {
			componentChartData.annotations.xaxis.push({
				x: filteredLabels[effectivePointIndex],
				strokeDashArray: 3,
				borderColor: theme.colors.benefitsBackground,
				label: {
					borderColor: 'transparent',
					textAnchor: 'start',
					offsetY: quantityOffsetY + diffOffsetY,
					offsetX: getQuantityOffsetX(effectivePointIndex),
					orientation: 'horizontal',
					style: {
						color: theme.colors.benefitsBackground,
						background: 'transparent'
					},
					text: getString('CURRENT_QUANTITY')
				}
			})
		}
		componentChartData.colors = []
		componentChartData.markers.colors = []
		if (!isAmOriginalMaterial || isStandard) {
			componentChartData.colors = [methodColor, amColor]
			componentChartData.markers.colors = [methodColor, amColor]
		} else {
			componentChartData.colors = [amColor]
			componentChartData.markers.colors = [amColor]
		}

		const { TMSeriesData, AMSeriesData } = getCostSeriesData(
			filteredLabels,
			filteredMolding,
			filteredAmResult,
			isStandard
		)
		const isStandardAndAmSeriesOn = isStandard && AMSeriesData.length > 1

		if (!isAmOriginalMaterial || isStandard) {
			series.push({
				name: graphLineText,
				type: isStandard ? 'scatter' : 'line',
				data: TMSeriesData
			})
		}
		series.push({
			name: suggestionTypeText,
			type: 'line',
			data: [
				isStandardAndAmSeriesOn
					? { x: -1000, y: 0 }
					: ({} as { x: number; y: number }),
				...AMSeriesData
			].filter(e => !isEmpty(e))
		})

		// standard cost should be always on top of AM
		if (isStandard) {
			componentChartData.colors = componentChartData.colors.reverse()
			componentChartData.markers.colors =
				componentChartData.markers.colors.reverse()
			series = series.reverse()
		}

		componentChartData.markers.size = [4, 4, 10]
		if (!isAmOriginalMaterial) {
			componentChartData.markers.discrete[0].dataPointIndex =
				printCostPointIndex
			componentChartData.markers.discrete[1].dataPointIndex =
				printCostPointIndex
		}

		for (
			let i = isStandardAndAmSeriesOn ? 2 : 1;
			i < filteredLabels.length;
			i++
		) {
			if (
				(i !== printCostPointIndex || isAmOriginalMaterial) &&
				i !==
					(isStandardAndAmSeriesOn
						? effectivePointIndex + 1
						: effectivePointIndex)
			) {
				componentChartData.markers.discrete.push(
					{
						seriesIndex: 0,
						dataPointIndex: i,
						size: 0
					},
					{
						seriesIndex: 1,
						dataPointIndex: i,
						size: 0
					}
				)
			}
		}

		if (!isAmOriginalMaterial) {
			componentChartData.tooltip = {
				custom: function ({ series, dataPointIndex, seriesIndex }: any) {
					const indexOfStandardCostPoint = filteredMolding.indexOf(series[1][0])
					const hoverOnStandardCostDot = seriesIndex === 1 && isStandard
					const standardCostSeriesPointIndex = 0
					const index =
						dataPointIndex === standardCostSeriesPointIndex &&
						hoverOnStandardCostDot
							? indexOfStandardCostPoint
							: dataPointIndex === series[0].length
							? dataPointIndex - 1
							: dataPointIndex

					const amPoint = isStandard
						? index === indexOfStandardCostPoint
							? series[1][0]
							: null
						: series[1][index]
					const defaultPoint = series[0][index]
					const minMax = amMinMaxResult[index]
					const equalPoint = amPoint === defaultPoint
					const equalPointIndex = index === printCostPointIndex

					const makeNumeral = (value: number, minMax?: number) => {
						const poweredValue =
							Math.pow(10, value) < 1
								? Math.pow(10, value)
								: Math.round(Math.pow(10, value))
						const poweredMinMax =
							typeof minMax === 'number' ? Math.round(minMax) : minMax
						if (isStandard) {
							return toFixedOnlyIfNeeded(poweredValue)
						}
						if (!isEmpty(poweredMinMax)) return poweredMinMax
						return showCostInRange
							? calculate2DRange(
									toFixedOnlyIfNeeded(poweredValue),
									drawingCostPercentage
							  )
							: poweredValue
					}
					const isAMBigger = amPoint >= defaultPoint

					const amHtml = !isNil(amPoint)
						? `<div><div class="am"/></div> ${userCurrencySign}${makeNumeral(
								amPoint,
								minMax
						  )}</div>`
						: ''
					const defaultHtml = !isNil(defaultPoint)
						? `<div><div class="default"/></div> ${userCurrencySign}${makeNumeral(
								defaultPoint
						  )}</div>`
						: ''

					const allPoints = `
          <div class="custom-tooltip ${isStandard ? 'with-standard' : ''}">
            ${isAMBigger ? amHtml : defaultHtml}
            ${isAMBigger ? defaultHtml : amHtml}
          </div>
        `
					const crossedPoint = `
         <div class="custom-tooltip flex ${isStandard ? 'with-standard' : ''}">
            <div class="single-dot">
              ${defaultPoint ? `<div class="default"></div>` : ''}
              ${amPoint ? `<div class="am"/></div>` : ''}
            </div>
            <span>${userCurrencySign}${
						amPoint ? makeNumeral(amPoint, minMax) : makeNumeral(defaultPoint)
					}
            </span>
           </div>
          `
					if (equalPoint && equalPointIndex) return crossedPoint

					return allPoints
				}
			}
		}

		componentChartData.legend = {
			show: true,
			showForSingleSeries: true,
			showForZeroSeries: false,
			showForNullSeries: false,
			inverseOrder: isStandard,
			onItemClick: {
				toggleDataSeries: false
			},
			markers: {
				width: 0,
				height: 0
			},
			labels: {
				colors: ['#fff'],
				useSeriesColors: false
			},
			formatter: function (seriesName: any, opts: any) {
				let firstSeries = isStandard ? 1 : 0
				let secondSeries = isStandard ? 0 : 1

				if (isAmOriginalMaterial && !isStandard) {
					firstSeries = 1
					secondSeries = 0
				}

				return `<div class="seriesName-${
					opts.seriesIndex === firstSeries
						? 'mold'
						: opts.seriesIndex === secondSeries
						? 'am'
						: 'none'
				}">${seriesName}</div>`
			}
		}
		return { componentChartData, series, effectivePointIndex }
	}

	setChartDataLabels = (
		solutionQuantity: number,
		quantitySubmited: number = 0
	): number[] => {
		let actualQuantity = quantitySubmited || solutionQuantity
		const chartDataLabels = [
			actualQuantity * 0.5,
			actualQuantity * 0.6,
			actualQuantity * 0.7,
			actualQuantity * 0.8,
			actualQuantity * 0.9,
			actualQuantity,
			actualQuantity * 1.1,
			actualQuantity * 1.2,
			actualQuantity * 1.3,
			actualQuantity * 1.4,
			actualQuantity * 1.5
		]
		return chartDataLabels
	}

	moldCostCalculation = (part: Part, count: number): number => {
		const maintenacePresentage =
			(part.moldCost * part.moldMaintenanceCost) / 100
		return (
			part.moldCost +
			maintenacePresentage +
			part.DFMCosts +
			count * part.moldPartCost
		)
	}

	getCostDataWithSolution = async (
		solutionId: number,
		quantity: number,
		manufacturingMethod: string,
		userCurrencySign: string,
		changeQuantity: boolean,
		drawingCostPercentage: number = 1,
		showCostInRange: boolean = false,
		AMMinSolutionResults: any,
		AMMaxSolutionResults: any,
		blockManufacturingMethodOperation: boolean,
		isAmOriginalMaterial: boolean,
		isSpecifiedQuantity?: boolean,
		mainManufactureMethod?: ManufacturingMethod
	): Promise<CostDataWithSolution> => {
		let costData: CostDataWithSolution = new CostDataWithSolution()

		try {
			if (solutionId > 0) {
				const chartDataLabels = this.setChartDataLabels(quantity)
				const res = await getCosts(chartDataLabels, solutionId, changeQuantity)
				const {
					actualResult,
					actualPartQuantity,
					formParameters,
					solution,
					configuration,
					comparedSupplyChainDetails,
					supplyChainTraditionalDetails = comparedSupplyChainDetails,
					comparedResults,
					results = comparedResults,
					mainResults,
					mainMinResults,
					mainMaxResults,
					AMResults = mainResults,
					AMMinResults = mainMinResults,
					AMMaxResults = mainMaxResults,
					quantityOfCost
				} = res?.data
				const solutionCost =
					solution?.costDetails?.totalCost || solution?.cost || 0
				costData.supplyChainTraditionalDetails = supplyChainTraditionalDetails
				costData.actualResult = actualResult
				costData.formParameters = formParameters
				costData.configuration = configuration
				costData.isCostEffective = quantityOfCost
					? actualPartQuantity <= quantityOfCost
					: solutionCost <= actualResult
				if (results) {
					const printCostQuantity = parseFloat(
						findKey(results, (r: number) => r === solutionCost) || ''
					)
					costData.chartData = this.createCostChartData(
						results,
						actualResult,
						actualPartQuantity,
						manufacturingMethod,
						userCurrencySign,
						solutionCost,
						printCostQuantity,
						AMResults,
						quantityOfCost || configuration?.quantityOfCost,
						drawingCostPercentage,
						showCostInRange,
						AMMinResults || AMMinSolutionResults,
						AMMaxResults || AMMaxSolutionResults,
						blockManufacturingMethodOperation,
						isAmOriginalMaterial,
						isSpecifiedQuantity,
						mainManufactureMethod
					)
					costData.printCostQuantity = printCostQuantity
				}
				costData.solution = solution
			}
		} catch (error: any) {
			console.error(error)
		}

		return costData
	}

	getCostData = (
		costResults: any,
		manufacturingMethod: string,
		userCurrencySign: string,
		solutionCost: number,
		isAmOriginalMaterial: boolean,
		drawingCostPercentage?: number,
		showCostInRange?: boolean,
		blockManufacturingMethodOperation?: boolean,
		isSpecifiedQuantity?: boolean,
		mainManufactureMethod?: ManufacturingMethod
	): CostData => {
		let costData: CostData = new CostData()
		try {
			const {
				comparedResults,
				results = comparedResults,
				mainResults,
				mainMinResults,
				mainMaxResults,
				AMResults = mainResults,
				AMMinResults = mainMinResults,
				AMMaxResults = mainMaxResults,
				actualResult,
				actualPartQuantity,
				formParameters,
				comparedSupplyChainDetails,
				supplyChainTraditionalDetails = comparedSupplyChainDetails,
				quantityOfCost
			} = costResults

			costData.actualResult = actualResult
			costData.formParameters = formParameters
			costData.supplyChainTraditionalDetails = supplyChainTraditionalDetails
			costData.isCostEffective = quantityOfCost
				? actualPartQuantity <= quantityOfCost
				: solutionCost <= actualResult
			if (results) {
				const printCostQuantity = parseFloat(
					findKey(results, r => r === solutionCost) || ''
				)

				costData.printCostQuantity = printCostQuantity || 0
				costData.chartData = this.createCostChartData(
					results,
					actualResult,
					actualPartQuantity,
					manufacturingMethod,
					userCurrencySign,
					solutionCost,
					printCostQuantity,
					AMResults,
					quantityOfCost,
					drawingCostPercentage,
					showCostInRange,
					AMMinResults,
					AMMaxResults,
					blockManufacturingMethodOperation,
					isAmOriginalMaterial,
					isSpecifiedQuantity,
					mainManufactureMethod
				)
			}
		} catch (error: any) {
			console.error(error)
		}

		return costData
	}
}

export const useShouldShowCostTable = (solution: any, configuration: any) => {
	const [shouldShowCostTable, setShouldShowCostTable] = useState(false)

	const supplyChainResult =
		configuration?.costResults?.supplyChainTraditionalDetails ||
		configuration?.costResults?.comparedSupplyChainDetails

	const supplyChainCostsBreakDown =
		solution?.costDetails?.threeDPrintingCostsBreakDown
			?.supplyChainCostsBreakDown ||
		solution?.costDetails.supplyChainCostsBreakDown

	useEffect(() => {
		if (
			!Feature.isFeatureOn(FeatureComponentId.COST_ANALYSIS_TABLE) ||
			!supplyChainCostsBreakDown?.perPartCosts ||
			!supplyChainResult?.perPartCosts ||
			!supplyChainCostsBreakDown?.costsBreakDown ||
			!supplyChainResult?.costsBreakDown
		) {
			setShouldShowCostTable(false)
			return
		}
		setShouldShowCostTable(true)
	}, [solution, configuration])

	return shouldShowCostTable
}

export const getConvertedWeightAndUnit = (weight: number) => {
	const tonInKgs = 1000 // 1 ton in kilograms
	const kTonInKgs = 1000000 // 1 kiloton in kilograms
	let convertedWeight
	let weightUnit
	// check if weight should be converted to kilotons
	if (Math.abs(weight) >= kTonInKgs) {
		convertedWeight = getFixedSize(
			Math.sign(weight) * +(Math.abs(weight) / kTonInKgs),
			3
		)
		weightUnit = getString('EMISSIONS_COMPARISON_KT')
		// check if weight should be converted to tons
	} else if (Math.abs(weight) >= tonInKgs) {
		convertedWeight = getFixedSize(
			Math.sign(weight) * +(Math.abs(weight) / tonInKgs),
			3
		)
		weightUnit = getString('EMISSIONS_COMPARISON_T')
		// default kilograms
	} else {
		convertedWeight = Math.sign(weight) * Math.abs(weight)
		weightUnit = getString('EMISSIONS_COMPARISON_KG')
	}
	return { convertedWeight, weightUnit }
}

export const convertCoDataWithUnits = (
	coData: Record<string, number> | null
): Record<string, { weight: number; unit: string }> => {
	const data = {}
	if (coData) {
		const coDataKeys = Object.keys(coData)
		// loop coData object to get converted values and units for each value
		coDataKeys.forEach(key => {
			const convertedWeightWithUnit = getConvertedWeightAndUnit(coData[key])
			Object.assign(data, {
				[key]: {
					weight: convertedWeightWithUnit.convertedWeight,
					unit: convertedWeightWithUnit.weightUnit
				}
			})
		})
	}
	return data
}

export const showCO2Banner = (
	shouldShowStandardCost: boolean,
	isDrawing?: boolean
) => {
	if (
		shouldShowStandardCost ||
		!Feature.isFeatureOn(FeatureComponentId.CO2_EMISSIONS) ||
		isDrawing
	) {
		return false
	}

	return true
}

export const getCoDataValue = (
	gainCO2: number | any | null,
	alwaysShowCO2Details: boolean
) => {
	let coData: any | number
	if (gainCO2 && !isEmpty(gainCO2) && !Number(gainCO2)) {
		const tmCo2Result = gainCO2?.tmCo2Result || gainCO2?.comparedCo2Result
		const amCo2Result = gainCO2?.amCo2Result || gainCO2?.mainCo2Result

		const materialRaw = tmCo2Result?.materialRaw - amCo2Result?.materialRaw
		const electricity = tmCo2Result?.electricity - amCo2Result?.electricity
		const eol = tmCo2Result?.eol - amCo2Result?.eol
		const transportation =
			tmCo2Result?.transportation - amCo2Result?.transportation
		const pu = amCo2Result?.pu
		const total = tmCo2Result?.total - amCo2Result?.total
		coData = {
			total: getFixedSize(total, 2),
			materialRaw: getFixedSize(materialRaw, 2),
			electricity: getFixedSize(electricity, 2),
			pu: pu ? getFixedSize(pu, 2) : 0,
			eol: getFixedSize(eol, 2),
			transportation: getFixedSize(transportation, 2)
		}
	} else if (Number(gainCO2)) {
		coData = { total: getFixedSize(gainCO2, 2) }
	}
	let CO2HasPotential = true
	const minCo2 = 0.01

	if (
		!coData ||
		(Number(coData) && coData <= minCo2) ||
		(!Number(coData) && coData?.total <= minCo2)
	) {
		CO2HasPotential = false
	}

	if (!coData || (!alwaysShowCO2Details && !CO2HasPotential)) {
		coData = null
	}

	return {
		coData,
		CO2HasPotential
	}
}

export const getTotalValues = (
	supplyChainCostsDetailsTM: SupplyChainResultObject | null,
	showCostInRange: boolean = false,
	drawingCostPercentage?: number,
	standardCost?: boolean
) => {
	const DEFAULT_COUNT = 0

	const perPartCosts: any = supplyChainCostsDetailsTM?.perPartCosts || {}
	const {
		totalInventoryCostsPerUnit = DEFAULT_COUNT,
		totalProductionCostsPerUnit = DEFAULT_COUNT,
		totalUpfrontCostsPerUnit = DEFAULT_COUNT,
		totalCo2CostsPerUnit = DEFAULT_COUNT,
		totalCostPerUnit = DEFAULT_COUNT
	} = perPartCosts

	// if value less 1 show it with 2 decimal after .
	const smallValue = [
		totalInventoryCostsPerUnit,
		totalCo2CostsPerUnit,
		totalProductionCostsPerUnit,
		totalUpfrontCostsPerUnit,
		totalCostPerUnit
	].some((value: number) => value > 0 && value < 1)

	const precision = smallValue ? 2 : 0

	const fixedTotalInventoryCostsPerUnit = round(
		totalInventoryCostsPerUnit,
		precision
	)

	const fixedTotalCo2CostsPerUnit = round(totalCo2CostsPerUnit, precision)

	const fixedTotalProductionCostsPerUnit = round(
		totalProductionCostsPerUnit,
		precision
	)

	const fixedTotalUpfrontCostsPerUnit = round(
		totalUpfrontCostsPerUnit,
		precision
	)

	const fixedTotalCostPerUnit = round(totalCostPerUnit, precision)

	const calculatedInventoryCost = showCostInRange
		? calculate2DRange(fixedTotalInventoryCostsPerUnit, drawingCostPercentage)
		: fixedTotalInventoryCostsPerUnit

	const calculatedCo2Cost = showCostInRange
		? calculate2DRange(fixedTotalCo2CostsPerUnit, drawingCostPercentage)
		: fixedTotalCo2CostsPerUnit

	const calculatedUpfrontCost = showCostInRange
		? calculate2DRange(fixedTotalUpfrontCostsPerUnit, drawingCostPercentage)
		: fixedTotalUpfrontCostsPerUnit

	const calculatedProductionCost = showCostInRange
		? calculate2DRange(fixedTotalProductionCostsPerUnit, drawingCostPercentage)
		: fixedTotalProductionCostsPerUnit

	let calculatedTotalCostPerUnit: any = fixedTotalCostPerUnit
	if (showCostInRange) {
		calculatedTotalCostPerUnit = calculate2DRange(
			fixedTotalCostPerUnit,
			drawingCostPercentage
		)
		if (standardCost) {
			calculatedTotalCostPerUnit = calculate2DRangeArray(
				fixedTotalProductionCostsPerUnit,
				fixedTotalUpfrontCostsPerUnit + fixedTotalInventoryCostsPerUnit,
				drawingCostPercentage
			)
		}
	}

	return {
		calculatedInventoryCost,
		calculatedUpfrontCost,
		calculatedProductionCost,
		calculatedCo2Cost,
		calculatedTotalCostPerUnit
	}
}

export const getCostInformation = (configuration: IConfiguration) => {
	const {
		quantity,
		costResults: {
			mainResults,
			comparedResults,
			AMResults = mainResults,
			results = comparedResults
		} = {}
	} = configuration

	const AmCost = AMResults[quantity]
	const MethodCost = results[quantity]

	return {
		costResult: getString('COST_RESULT_EXPLENATION').format(
			MethodCost >= AmCost ? getString('IS') : getString('ISNT')
		),
		isCostEffective: MethodCost >= AmCost
	}
}

export const cncPropsFormData = (
	part: Part,
	formData: Record<string, string | boolean>,
	projectSettingsScenariosOn: boolean | undefined,
	metalFormValues: Record<string, string | boolean>
): IOptionalMetalFormParameters => {
	return {
		...formData,
		...(projectSettingsScenariosOn
			? {
					camExistence: metalFormValues.camExistence
			  }
			: {}),
		...{
			puAnnualKgCO2: formData.puAnnualKgCO2 || part.puAnnualKgCO2,
			puYearsCO2: formData.puYearsCO2 || part.puYearsCO2,
			disposeFactor: formData.disposeFactor || part.disposeFactor
		}
	}
}

export const checkMinThickness = (
	defaultLayerThickness: number,
	initialLayerThicknessValue: number
) => {
	return (
		!defaultLayerThickness ||
		defaultLayerThickness <= 0 ||
		defaultLayerThickness <= initialLayerThicknessValue / maxMultiplier
	)
}
export const checkMaxThickness = (
	defaultLayerThickness: number,
	initialLayerThicknessValue: number
) => {
	return (
		!defaultLayerThickness ||
		defaultLayerThickness > MAX_LAYER_THICKNESS ||
		defaultLayerThickness >= initialLayerThicknessValue * maxMultiplier
	)
}
