import React, { FC, memo } from 'react'
import Chart from 'react-apexcharts'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'
import { reset } from 'redux-form'

import cx from 'classnames'
import { isEmpty } from 'lodash'

import * as MainPartAnalysisActions from '../../../../../MainPartAnalysisActions'
import * as SolutionAnalysisActions from '../../../../SolutionAnalysisActions'
import { ConfigurationResultTypes } from '../../../../ConfigurationResultTypes'
import { SolutionAnalysisInitialState } from '../../../../SolutionAnalysisReducer'
import {
	AnalysisFooter,
	ProductionNumber,
	TraditionalManufacturingMethod
} from '../common'
import LeadTimeChartExplanation from './LeadTimeChartExplanation'
import LeadTimeForm from './LeadTimeForm/index'
import {
	checkOnStandard,
	getErrorMessage,
	getMethodName
} from './LeadTimeService'
import LockLeadTimeGraph from './LockLeadTimeGraph'
import { ActionWithPayload } from 'global actions/ActionModels'
import Loader from 'Scenes/Loader/Loader'
import { manufacturingMethodTypes } from 'Services/Constants'
import { IStateExtractorState } from 'Services/HOR/InstanceStateExtractor'
import { IChartLeadData } from 'Services/models/CostComparisonModels'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import { Part } from 'Services/models/IPart'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import { LEAD_TIME_LABEL, PARAMETERS } from 'Services/Strings'

import '../../SolutionNewTabStyles.scss'
import './LeadTimeTab.scss'

const ChartGraph: any = Chart

interface IProps {
	part: Part
	solution: any
	configuration: any
}

interface IReduxProps {
	solution: any
	leadTimeFormMode: boolean
	unlockLeadTimeGraph: boolean | null
	leadTimeError: boolean
	chartLeadData: IChartLeadData | null
	batchSize: number
	showLeadFormCancelButton: boolean
	reset: Function
	manufacturingMethod: string
	leadTimeLoading: boolean
	effectiveQuantity: any
	tempChainBenefits: IChainBenefits
	costAffectivePointQuantity: number
	effectivePointIndex: number
	partPrintIssues: PartPrintIssue[]
	expectedYearsOfDemand: number
	chosenOrientation: any
	timeBenefit: any
	costLoading: boolean
	isAmOriginalMaterial: boolean
	onLeadTimeFormSubmit: (
		...onPlasticCostFormSubmitParams: Parameters<
			typeof SolutionAnalysisActions.onLeadTimeFormSubmit
		>
	) => any
	configurations: any
	onLeadTimeEditClicked: (id: number) => ActionWithPayload<any>
	onChangeLockLeadTimeGraph: (id: number) => any
	onLeadTimeFormCancelled: (id: number) => ActionWithPayload<any>
	resetChainBenefits: (id: number) => ActionWithPayload<any>
	onCostQuantityChanged: (id: number, value: number) => ActionWithPayload<any>
	onCostQuantityUpdated: (
		...onCostQuantityUpdatedParams: Parameters<
			typeof SolutionAnalysisActions.onCostQuantityUpdated
		>
	) => any
	onManufacturingMethodChange: (
		...onManufacturingMethodChangeParams: Parameters<
			typeof SolutionAnalysisActions.onManufacturingMethodChange
		>
	) => ActionWithPayload<any>
}

interface IReduxStore {
	SolutionAnalysisReducer: IStateExtractorState
	MainPartAnalysisReducer: any
	user: any
}

const LeadTimeTab: FC<IProps & IReduxProps> = ({
	part,
	configuration,
	leadTimeFormMode,
	chartLeadData,
	batchSize,
	showLeadFormCancelButton,
	reset,
	manufacturingMethod,
	leadTimeLoading,
	tempChainBenefits,
	onLeadTimeFormSubmit,
	partPrintIssues,
	expectedYearsOfDemand,
	chosenOrientation,
	timeBenefit,
	onLeadTimeEditClicked,
	onLeadTimeFormCancelled,
	resetChainBenefits,
	onCostQuantityChanged,
	onCostQuantityUpdated,
	onManufacturingMethodChange,
	configurations,
	solution,
	onChangeLockLeadTimeGraph,
	unlockLeadTimeGraph,
	costLoading,
	isAmOriginalMaterial
}) => {
	const lowBatchSize = batchSize < 1
	const leadFormParameters = configuration?.leadTimeResults?.formParameters
	const leadTimeResults = configuration?.leadTimeResults
	const disableCostTab = !Feature.isFeatureActive(
		FeatureComponentId.COST_TAB_INFORMATION
	)
	const isWRConfig =
		configuration.resultType === ConfigurationResultTypes.WeightReduction
	const isCluster = !!configuration?.cluster

	const isStandard =
		checkOnStandard(manufacturingMethod) ||
		(!checkOnStandard(manufacturingMethod) &&
			!!part.standardCost &&
			!configuration.standardCost)

	const methodText = getMethodName(manufacturingMethod)
	const blockManufacturingMethodOperation =
		part.blockManufacturingMethodOperation || false

	const showLeadTimeParameters = Feature.isFeatureOn(
		FeatureComponentId.LEAD_TIME_PARAMETERS
	)

	const errorMessage = getErrorMessage(
		unlockLeadTimeGraph,
		leadTimeResults?.tmResults || leadTimeResults?.comparedResults,
		leadTimeResults?.amResults || leadTimeResults?.mainResults,
		solution.quantity,
		isStandard
	)

	const renderChart = () => {
		return (
			<div className="lead-time-chart">
				<Loader
					load={leadTimeLoading}
					message={''}
					wrapperClassName="graphic-loader"
				/>
				<div
					className={cx('financial-form-chart with-border', {
						blur: errorMessage
					})}
				>
					<ChartGraph
						type="line"
						options={chartLeadData && chartLeadData?.componentChartData}
						series={chartLeadData && chartLeadData?.series}
						height="100%"
					/>
				</div>
				{!leadTimeLoading && <LockLeadTimeGraph errorMessage={errorMessage} />}
			</div>
		)
	}

	const onCostFormCancel = () => {
		reset(`lead_time_${configuration.id}`)
		onLeadTimeFormCancelled(configuration.id)
		resetChainBenefits(configuration.id)
	}
	const standardCost = configuration?.standardCost || part.standardCost

	const shouldShowStandardCost =
		manufacturingMethod === manufacturingMethodTypes.standardCost ||
		(blockManufacturingMethodOperation && standardCost)
	const renderForm = () => {
		return (
			<div>
				<LeadTimeForm
					configuration={configuration}
					showCancelButton={showLeadFormCancelButton}
					leadTimeLoading={leadTimeLoading}
					form={`lead_time_${configuration.id}`}
					initialValues={leadFormParameters}
					onCancel={onCostFormCancel}
					onLeadFormSubmit={(data: any) => {
						onLeadTimeFormSubmit(
							data,
							batchSize,
							part.id,
							solution,
							configuration.id,
							manufacturingMethod,
							configuration.postProcessesOptional || {},
							tempChainBenefits,
							configurations,
							expectedYearsOfDemand,
							chosenOrientation
						)
					}}
					isCnc={manufacturingMethod === manufacturingMethodTypes.cnc}
				/>
			</div>
		)
	}

	const renderCostInformation = () => {
		const footerTitle = (
			<>
				<b>{LEAD_TIME_LABEL}</b> {PARAMETERS}
			</>
		)

		return (
			Feature.isFeatureOn(FeatureComponentId.COST_TAB_INFORMATION) && (
				<div
					className={cx('cost-comparison-tab--information', {
						'with-space': isWRConfig || isCluster
					})}
				>
					<div>
						<LeadTimeChartExplanation
							configuration={configuration}
							solution={solution}
							timeBenefit={timeBenefit}
							isStandard={isStandard}
							methodText={methodText}
							isAmOriginalMaterial={isAmOriginalMaterial}
						/>
						{!isWRConfig && !isCluster && (
							<ProductionNumber
								disabled={blockManufacturingMethodOperation}
								isLeadTime
								onCostQuantityUpdated={onCostQuantityUpdated}
								configuration={configuration}
								part={part}
								manufacturingMethod={manufacturingMethod}
								tempChainBenefits={tempChainBenefits}
								expectedYearsOfDemand={expectedYearsOfDemand}
								chosenOrientation={chosenOrientation}
								batchSize={batchSize}
								onCostQuantityChanged={onCostQuantityChanged}
								lowBatchSize={lowBatchSize}
								shouldShowStandardCost={shouldShowStandardCost}
								costLoading={costLoading}
							/>
						)}
						{!isEmpty(configuration?.leadTimeResults?.formParameters) &&
							showLeadTimeParameters &&
							!isWRConfig &&
							!isCluster && (
								<AnalysisFooter
									title={footerTitle}
									onCostEditClicked={onLeadTimeEditClicked}
									configId={configuration.id}
								/>
							)}

						{!blockManufacturingMethodOperation && !isAmOriginalMaterial && (
							<TraditionalManufacturingMethod
								isLeadTime
								configuration={configuration}
								part={part}
								manufacturingMethod={manufacturingMethod}
								onManufacturingMethodChange={onManufacturingMethodChange}
								partPrintIssues={partPrintIssues}
							/>
						)}
					</div>
				</div>
			)
		)
	}

	const renderChartLeadData = () => {
		return (
			<>
				<div
					className={cx('cost-comparison-tab--chart', {
						blur: disableCostTab
					})}
				>
					{renderChart()}
					{renderCostInformation()}
				</div>
			</>
		)
	}

	const renderContent = () => {
		if (leadTimeFormMode || !chartLeadData) {
			return <div className="edit-parameters with-scroll">{renderForm()}</div>
		}
		return renderChartLeadData()
	}
	let hideTab = false
	if (!Feature.isFeatureActive(FeatureComponentId.LEAD_TIME_TAB)) {
		hideTab = true
	}
	return (
		<div
			className={
				hideTab
					? 'cost-comparison-tab--table--light_user'
					: 'cost-comparison-tab'
			}
		>
			{renderContent()}
		</div>
	)
}

const mapStateToProps = (
	{
		SolutionAnalysisReducer,
		MainPartAnalysisReducer: { configurations, partPrintIssues, part }
	}: IReduxStore,
	ownProps: IProps
) => {
	const {
		leadTimeFormMode,
		chartLeadData,
		batchSize,
		showLeadFormCancelButton,
		manufacturingMethod,
		leadTimeLoading,
		costAffectivePointQuantity,
		effectivePointIndex,
		chosenOrientation,
		effectiveQuantity,
		isCostEffective,
		solution,
		tempChainBenefits,
		expectedYearsOfDemand,
		unlockLeadTimeGraph,
		leadTimeError,
		costLoading,
		isAmOriginalMaterial
	}: SolutionAnalysisInitialState =
		SolutionAnalysisReducer.states[ownProps.configuration.id] ||
		new SolutionAnalysisInitialState()

	return {
		leadTimeFormMode,
		chartLeadData,
		batchSize,
		configurations,
		showLeadFormCancelButton,
		manufacturingMethod,
		leadTimeLoading,
		effectiveQuantity,
		tempChainBenefits,
		costAffectivePointQuantity,
		effectivePointIndex,
		partPrintIssues,
		expectedYearsOfDemand:
			expectedYearsOfDemand ||
			ownProps.configuration.expectedYearsOfDemand ||
			part?.expectedYearsOfDemand,
		chosenOrientation,
		isCostEffective,
		solution,
		unlockLeadTimeGraph,
		leadTimeError,
		costLoading,
		isAmOriginalMaterial
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{ ...SolutionAnalysisActions, ...MainPartAnalysisActions, reset },
		dispatch
	)

export default memo(connect(mapStateToProps, mapDispatchToProps)(LeadTimeTab))
