import {
	ADD_CUSTOM_ORIENTATION,
	CUSTOM_ORIENTATION_COMPLETED,
	SHOW_HIDE_CUSTOM_ORIENTATION_ALERT
} from './SolutionCustomOrientationTypes'
import { Action } from 'global actions/ActionModels'

export class SolutionCustomOrientationInitialState {
	loading: boolean = false
	showCustomOrientationAlert: boolean = false
}
const initialSolutionCustomOrientationState: SolutionCustomOrientationInitialState =
	new SolutionCustomOrientationInitialState()
const SolutionCustomOrientationReducer = (
	state = initialSolutionCustomOrientationState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case ADD_CUSTOM_ORIENTATION: {
			return {
				...state,
				loading: payload.loading
			}
		}
		case SHOW_HIDE_CUSTOM_ORIENTATION_ALERT: {
			return {
				...state,
				showCustomOrientationAlert: payload.showAlert
			}
		}
		case CUSTOM_ORIENTATION_COMPLETED: {
			return {
				...state,
				loading: false,
				showCustomOrientationAlert: false
			}
		}
		default:
			return state
	}
}
export default SolutionCustomOrientationReducer
