import { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { onFullTrayAssumptionChanged } from '../../../../global actions'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import Devider from '../../../Components/Devider/Devider'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import Customize2DDrawingCost from '../Customize2DDrawingCost/Customize2DDrawingCost'
import CustomizeAnalysisCost from '../CustomizeAnalysisCost'
import { renderFormHeader, scrollToElement } from '../CustomizeFormCommon'
import CustomizeFormSection from '../CustomizeFormSection'
import CustomizeFullTrayAssumption from '../CustomizeFullTrayAssumption/CustomizeFullTrayAssumption'
import CustomizeMaterialCost from '../CustomizeMaterialCost/CustomizeMaterialCost'
import CustomizeMetadataCost from '../CustomizeMetadataCost'
import CustomizeSupplyChain from '../CustomizeSupplyChain/CustomizeSupplyChain'
import CustomizeSupplyChainRadioButton from '../CustomizeSupplyChain/CustomizeSupplyChainRadioButton'
import CustomizeToleranceCost from '../CustomizeToleranceCost'
import CustomizeUserPostProcesses from '../CustomizeUserPostProcesses/CustomizeUserPostProcesses'
import CostCalculationParams from './CostCalculationParams'
import CustomizePrinterProps from './CustomizePrinterProps'
import { History } from 'history'
import { fetchCustomizeData } from 'Scenes/Home/Customize/CustomizeActions'
import { setupPrinterPropsData } from 'Scenes/Home/Customize/CustomizeCostAndLeadSettings/CostAndLeadActions'
import {
	CUSTOMIZE_ADDITIVE_MANUFACTURING_COST_AND_LEAD_ROUTE,
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	drawingCost2dNames: Record<string, any>
	history: History
}

const setSettingsContent = (
	userCustomizationSettings: Record<string, any>,
	isCastorTwoSettings: boolean | undefined
) => {
	const customizeSupplyChain = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_SUPPLY_CHAIN
	)
	const isDrawingCost = Feature.isFeatureOn(
		FeatureComponentId.DRAWING_COST_COST_AND_LEAD
	)
	const customizeSupplyChainRadioButton = Feature.isFeatureOn(
		FeatureComponentId.DEFAULT_SCENARIO
	)

	const { fullTrayAssumption, includeSupplyChainCosts } =
		userCustomizationSettings

	return (
		<div className="customize-cost-and-lead-settings">
			<CostCalculationParams />

			{!isCastorTwoSettings && <CustomizePrinterProps />}
			{!isCastorTwoSettings && <CustomizeMaterialCost />}
			{!customizeSupplyChain && (
				<CustomizeFullTrayAssumption
					fullTrayAssumptionOn={!!fullTrayAssumption}
					onFullTrayAssumptionToggleChange={onFullTrayAssumptionChange}
				/>
			)}
			{!isCastorTwoSettings && <CustomizeUserPostProcesses />}
			{customizeSupplyChain && <CustomizeSupplyChain disabled={false} />}
			{customizeSupplyChainRadioButton && <CustomizeSupplyChainRadioButton />}
			<CustomizeAnalysisCost />
			<CustomizeToleranceCost />
			{isDrawingCost && !isCastorTwoSettings && <Customize2DDrawingCost />}
			{!isCastorTwoSettings && <CustomizeMetadataCost />}
			{isCastorTwoSettings ? (
				<>
					<Devider className="customize-form--divider" />

					<CustomizeFormSection
						explanationHeader={getString(
							'CUSTOMIZE_ADDITIVE_MANUFACTURING_COST_AND_LEAD_TITLE'
						)}
						explanationArray={getString(
							'CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY'
						)}
						linkTo={
							USER_HOME_ROUTE +
							CUSTOMIZE_ADDITIVE_MANUFACTURING_COST_AND_LEAD_ROUTE
						}
						qaDataElementName="data-qa-cost-calculation-params"
					/>
				</>
			) : (
				<></>
			)}
		</div>
	)
}

const onFullTrayAssumptionChange = (fullTrayAssumptionOn: boolean) => {
	onFullTrayAssumptionChanged(fullTrayAssumptionOn)
}
const CustomizeCostAndLeadSettings: FC<IProps> = ({
	drawingCost2dNames,
	history
}) => {
	const dispatch = useDispatch()

	const { settingsData, costAndLeadNames } = useSelector(
		(state: RootStateOrAny) => state.CostAndLeadReducer
	)
	const isCastorTwoSettings = Feature.isFeatureOn(
		FeatureComponentId.CASTOR_2_SETTINGS
	)

	const { printersFullData, userCustomizationSettings } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	useEffect(() => {
		dispatch(
			fetchCustomizeData(
				costAndLeadNames,
				settingsData.userSettings,
				drawingCost2dNames,
				undefined,
				adminSelectedOrganizationId
			)
		)
	}, [])
	useEffect(() => {
		dispatch(setupPrinterPropsData(printersFullData))
	}, [])

	useEffect(() => {
		if (window.location.hash) {
			scrollToElement(window.location.hash)
		}
	}, [])

	useEffect(() => {
		if (window.location.hash) {
			scrollToElement(window.location.hash)
		}
	}, [window.location.hash])

	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<CastorForm
				formHeader={renderFormHeader(
					history,
					USER_HOME_ROUTE + CUSTOMIZE_ROUTE
				)}
				formTitle={getString('CUSTOMIZE_FORM_COST_AND_LEAD_HEADER')}
				content={setSettingsContent(
					userCustomizationSettings,
					isCastorTwoSettings
				)}
			/>
		</NavBarAndMaterial>
	)
}
export default memo(CustomizeCostAndLeadSettings)
