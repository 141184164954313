import { FC, memo } from 'react'

import { isEmpty } from 'lodash'
import moment from 'moment'

import {
	ADMIN_PROJECTS_ASSEMBLY_OR_INDIVIDUAL,
	ADMIN_PROJECTS_CREATED_AT,
	ADMIN_PROJECTS_DEFAULT_MATERIAL,
	ADMIN_PROJECTS_EMAIL,
	ADMIN_PROJECTS_ID,
	ADMIN_PROJECTS_MATERIAL_OR_BOM,
	ADMIN_PROJECTS_NAME,
	ADMIN_PROJECTS_PARTS_IN_PROJECT,
	ADMIN_PROJECTS_PUBLISHED_AT,
	ADMIN_PROJECTS_STATUS,
	AM_TYPE_IMPROVEMENTS,
	AM_VALUE_TEXT,
	ASSEMBLY,
	BOM,
	MANUFACTURING_METHOD_TEXT,
	MULTI_MATERIAL,
	OWNER_ID,
	OWNER_NAME,
	PART_STATUSES_TEXTS,
	PRODUCT_LIFE_CYCLE,
	PROGRESS,
	PROJECT_INFORMATION,
	PROJECT_UNIT_TYPE,
	STANDARD_COST,
	STATUS_BREAKDOWN,
	STATUS_INFORMATION,
	UPLOAD_INDIVIDUAL_PARTS_SELECTION,
	UPLOAD_PROJECT_SCENARIO_METHODS_HEADER,
	UPLOAD_PROJECT_YEARLY_PROD,
	UPLOADING_MESSAGE,
	USER_INFORMATION
} from '../../../../../Services/Strings'
import { getPartStatusForAdmin } from '../../adminActionsService'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import { manufacturingMethodTypes } from 'Services/Constants'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { Part, PartStatus } from 'Services/models/IPart'
import { Project } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './AdminProjectInfo.scss'

const { adminProjectExtendedInfo } = getTheme()

const generateHttpLineProperty = (title: string, data: number | string) => {
	return (
		<p>
			<b>{title}:</b> {data}
		</p>
	)
}

const generateNumOfPartsByStatus = (
	statusName: string,
	sumOfStatus: number
) => {
	if (sumOfStatus) {
		return (
			<p>
				<b>{statusName}</b>: {sumOfStatus}
			</p>
		)
	}
	return <></>
}

type IProps = {
	project: Record<string, any>
	allProjectParts: any[]
}

const AdminProjectInfo: FC<IProps> = ({ project, allProjectParts }) => {
	const progress = UPLOADING_MESSAGE.format(
		project?.numberOfPartsAnalyzed,
		project?.totalParts
	)

	const statusPartsCounts = allProjectParts.reduce(
		(acc: Record<PartStatus, number>, part: Part) => {
			const partStatus: PartStatus = getPartStatusForAdmin(part)
			acc[partStatus] = (acc[partStatus] || 0) + 1
			return acc
		},
		{}
	)

	const completeParts =
		(Number(statusPartsCounts[PartStatus.published]) || 0) +
		(Number(statusPartsCounts[PartStatus.complete]) || 0)
	const failedParts = statusPartsCounts[PartStatus.failed]
	const awaitingCombinedHeatmapParts =
		statusPartsCounts[PartStatus.awaitingCombinedHeatmap]
	const awaitingFileParts = statusPartsCounts[PartStatus.awaitingFile]
	const toBeSentForAnalysisParts =
		statusPartsCounts[PartStatus.toBeSentForAnalysis]
	const awaitingAnalysisParts = statusPartsCounts[PartStatus.awaitingAnalysis]
	const awaitingForBOMParts = statusPartsCounts[PartStatus.awaitingForBOM]
	const toBeSentForWallThicknessParts =
		statusPartsCounts[PartStatus.toBeSentForWallThickness]
	const awaitingWallThicknessParts =
		statusPartsCounts[PartStatus.awaitingWallThickness]
	const awaitingTrayOrientationParts =
		statusPartsCounts[PartStatus.awaitingTrayOrientation]
	const rejectedParts = statusPartsCounts[PartStatus.rejected]
	const missingInformationParts =
		statusPartsCounts[PartStatus.missingInformation]

	const empty = '-'
	const amProjectValue = project?.amValue && JSON.parse(project?.amValue)
	const defaultAmType = AM_TYPE_IMPROVEMENTS

	const productLifeCycle =
		UPLOAD_PROJECT_SCENARIO_METHODS_HEADER[project?.productLifeCycle] || empty

	const projectManufactureMethod: ImanufacturingTypes =
		project?.manufactureMethod
	const manufactureMethod =
		manufacturingMethodTypes[projectManufactureMethod] || empty
	const amValue = isEmpty(amProjectValue)
		? empty
		: amProjectValue.map((el: any) => defaultAmType[el]).join(', ')

	const materialOrBom =
		project?.defaultMaterialId && !project?.bomFileURL
			? project?.defaultMaterial?.name || project?.defaultMaterial
			: project?.bomFileURL
			? BOM
			: MULTI_MATERIAL

	const assembly = project?.isBundle
		? `${getString('ASSEMBLY')} + ${UPLOAD_INDIVIDUAL_PARTS_SELECTION}`
		: project?.isAssembly
		? ASSEMBLY
		: UPLOAD_INDIVIDUAL_PARTS_SELECTION
	const momentPublishDate = moment(project?.publishedAt)
	const publishDate = momentPublishDate.isValid() ? project?.publishedAt : '-'

	return (
		<Flexbox className="admin-project-form" justifyContent="space-around">
			<Flexbox className="admin-project-form" flexDirection="column">
				<h3>{PROJECT_INFORMATION}</h3>
				{generateHttpLineProperty(ADMIN_PROJECTS_NAME, project?.name)}
				{generateHttpLineProperty(ADMIN_PROJECTS_ID, project?.id)}
				{generateHttpLineProperty(
					ADMIN_PROJECTS_MATERIAL_OR_BOM,
					materialOrBom
				)}

				<p>
					<b>{ADMIN_PROJECTS_PARTS_IN_PROJECT}:</b> {project?.partCount}
				</p>
				<p>
					<b>{ADMIN_PROJECTS_DEFAULT_MATERIAL}:</b>{' '}
					{project?.defaultMaterial?.name || project?.defaultMaterial}
				</p>
				{generateHttpLineProperty(ADMIN_PROJECTS_STATUS, project?.status)}
				{generateHttpLineProperty(
					ADMIN_PROJECTS_CREATED_AT,
					project?.createdAt
				)}
				{generateHttpLineProperty(ADMIN_PROJECTS_PUBLISHED_AT, publishDate)}
			</Flexbox>

			<Flexbox className="admin-project-form" flexDirection="column">
				<h3>
					<br />
				</h3>
				{generateHttpLineProperty(
					UPLOAD_PROJECT_YEARLY_PROD,
					project?.quantity
				)}
				<p>
					<b>{ADMIN_PROJECTS_ASSEMBLY_OR_INDIVIDUAL}:</b> {assembly}
					{project?.isBundle ? (
						<InfoBox
							boxContact={
								<div>
									{getString('BUNDLE_PROJECTS_HINT').format(
										project?.bundleProjects.length
									)}
									<br />
									<ul>
										{project?.bundleProjects.map((project: Project) => {
											return <li key={project.name}>{project.name}</li>
										})}
									</ul>
								</div>
							}
							managerClassName="admin-project--info-button"
							boxDirection="right-start"
						/>
					) : null}
				</p>
				{generateHttpLineProperty(PROJECT_UNIT_TYPE, project?.unitType)}
				{adminProjectExtendedInfo && (
					<>
						{generateHttpLineProperty(
							MANUFACTURING_METHOD_TEXT,
							manufactureMethod
						)}
						{generateHttpLineProperty(AM_VALUE_TEXT, amValue)}
					</>
				)}
				{generateHttpLineProperty(PRODUCT_LIFE_CYCLE, productLifeCycle)}
				{generateHttpLineProperty(
					STANDARD_COST,
					project?.projectStandardCost || empty
				)}
			</Flexbox>
			<Flexbox className="admin-project-form" flexDirection="column">
				<h3>{USER_INFORMATION}</h3>
				{generateHttpLineProperty(OWNER_NAME, project?.owner?.name)}
				{generateHttpLineProperty(OWNER_ID, project?.owner?.id)}
				{generateHttpLineProperty(ADMIN_PROJECTS_EMAIL, project?.owner?.email)}
			</Flexbox>
			<Flexbox className="admin-project-form" flexDirection="column">
				<h3>{STATUS_INFORMATION}</h3>
				{generateHttpLineProperty(PROGRESS, progress)}
				<h4>{STATUS_BREAKDOWN}</h4>
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.complete,
					completeParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingCombinedHeatmap,
					awaitingCombinedHeatmapParts
				)}
				{generateNumOfPartsByStatus(PART_STATUSES_TEXTS.failed, failedParts)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingFile,
					awaitingFileParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.toBeSentForAnalysis,
					toBeSentForAnalysisParts
				)}

				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingAnalysis,
					awaitingAnalysisParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingForBOMParts,
					awaitingForBOMParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.toBeSentForWallThickness,
					toBeSentForWallThicknessParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingWallThickness,
					awaitingWallThicknessParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingTrayOrientation,
					awaitingTrayOrientationParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.rejected,
					rejectedParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.missingInformation,
					missingInformationParts
				)}
			</Flexbox>
		</Flexbox>
	)
}

export default memo(AdminProjectInfo)
