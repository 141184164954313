import { cloneDeep, every, find } from 'lodash'

import { Action } from '../../../../global actions/ActionModels'
import { amResults } from './CustomizeListOfSuggestions'
import {
	checkAllIsOn,
	DOLLAR,
	PERCENTAGE,
	prepareListOfSuggestions
} from './CustomizeSugesstionsService'
import {
	CUSTOMIZE_COST_SAVING_THRESHOLD,
	FETCH_LIST_OF_SUGGESTION,
	FETCH_LIST_OF_SUGGESTION_CHANGE,
	FETCH_LIST_OF_SUGGESTION_CHANGE_AM,
	FETCH_LIST_OF_SUGGESTION_SUCCESS,
	SAVE_COST_DOLLAR_SUGGESTION_CHANGE,
	SAVE_COST_DOLLAR_SUGGESTION_LOADING,
	SAVE_COST_PERCENTAGE_SUGGESTION_CHANGE,
	SAVE_LIST_OF_SUGGESTION_LOADING,
	SAVE_LIST_OF_SUGGESTION_SUCCESS,
	SAVE_LIST_SUGGESTION_CHANGE,
	SET_SELECTED_COST_SUGGESTION
} from './CustomizeSuggestionsTypes'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import { IDefaultSuggestions } from 'Services/models/IDefaultSuggestions'

interface IState {
	costSavingThresholdPercent: number | null
	costSavingThresholdValue: number | null
	selectedCostSuggestion: string
	costSavingLoading: boolean
	listOfSuggestion: Array<IDefaultSuggestions>
	initialListOfSuggestion: Array<IDefaultSuggestions>
	listOfSuggestionLoading: boolean
	fetchListOfSuggestionLoading: boolean
	isAmChecked: boolean
	isBestMatchChecked: boolean
}

const initialState: IState = {
	costSavingThresholdPercent: null,
	costSavingThresholdValue: null,
	selectedCostSuggestion: '',
	costSavingLoading: false,
	listOfSuggestionLoading: false,
	fetchListOfSuggestionLoading: false,
	initialListOfSuggestion: [],
	listOfSuggestion: [],
	isAmChecked: false,
	isBestMatchChecked: false
}

const CustomizeSuggestionsReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case CUSTOMIZE_COST_SAVING_THRESHOLD: {
			return {
				...state,
				costSavingThresholdPercent: initialState.costSavingThresholdPercent,
				costSavingThresholdValue: initialState.costSavingThresholdValue
			}
		}

		case SAVE_COST_DOLLAR_SUGGESTION_LOADING: {
			return { ...state, costSavingLoading: payload }
		}

		case SAVE_COST_DOLLAR_SUGGESTION_CHANGE: {
			return { ...state, costSavingThresholdValue: payload }
		}

		case SAVE_COST_PERCENTAGE_SUGGESTION_CHANGE: {
			return { ...state, costSavingThresholdPercent: payload }
		}

		case SAVE_LIST_SUGGESTION_CHANGE: {
			return state
		}

		case SET_SELECTED_COST_SUGGESTION: {
			return {
				...state,
				selectedCostSuggestion: payload.selectedCostSuggestion,
				costSavingThresholdPercent:
					payload.selectedCostSuggestion === DOLLAR
						? initialState.costSavingThresholdPercent
						: state.costSavingThresholdPercent,
				costSavingThresholdValue:
					payload.selectedCostSuggestion === PERCENTAGE
						? initialState.costSavingThresholdValue
						: state.costSavingThresholdValue
			}
		}

		case SAVE_LIST_OF_SUGGESTION_LOADING: {
			return {
				...state,
				listOfSuggestionLoading: payload
			}
		}

		case SAVE_LIST_OF_SUGGESTION_SUCCESS: {
			const allOn = checkAllIsOn(state.listOfSuggestion)

			return {
				...state,
				isAmChecked: allOn,
				initialListOfSuggestion: state.listOfSuggestion
			}
		}

		case FETCH_LIST_OF_SUGGESTION: {
			return {
				...state,
				fetchListOfSuggestionLoading: payload
			}
		}

		case FETCH_LIST_OF_SUGGESTION_SUCCESS: {
			const { defaultSuggestions, userDefaultSuggestions } = payload
			const preparedSuggestions = prepareListOfSuggestions(
				defaultSuggestions,
				userDefaultSuggestions
			)

			const allOn = checkAllIsOn(preparedSuggestions)

			return {
				...state,
				isBestMatchChecked: preparedSuggestions.find(
					suggestion =>
						suggestion.resultType === ConfigurationResultTypes.BestMatch
				)?.showAsDefault,
				isAmChecked: allOn,
				initialListOfSuggestion: preparedSuggestions,
				listOfSuggestion: preparedSuggestions
			}
		}

		case FETCH_LIST_OF_SUGGESTION_CHANGE: {
			const { resultType, checked } = payload
			const listOfSuggestion = cloneDeep(state.listOfSuggestion)
			let updatedListOfSuggestion = listOfSuggestion
			let isBestMatchChecked = state.isBestMatchChecked

			const othersChecked = updatedListOfSuggestion
				.filter(suggestion => !amResults.includes(suggestion.resultType))
				.every(suggestion => suggestion.showAsDefault)

			const index = updatedListOfSuggestion.findIndex(
				suggestion => suggestion.resultType === resultType
			)

			if (resultType === ConfigurationResultTypes.BestMatch) {
				isBestMatchChecked = checked

				if (!checked) {
					updatedListOfSuggestion = updatedListOfSuggestion.map(suggestion => {
						if (amResults.includes(suggestion.resultType)) {
							suggestion.showAsDefault = checked
						}

						return suggestion
					})
				}
			}

			if (index >= 0) {
				updatedListOfSuggestion[index].showAsDefault = checked

				if (
					!othersChecked &&
					resultType === ConfigurationResultTypes.BestMatch
				) {
					isBestMatchChecked = true
					updatedListOfSuggestion[index].showAsDefault = true
				}
			}

			const allOn = checkAllIsOn(updatedListOfSuggestion)

			return {
				...state,
				isBestMatchChecked: isBestMatchChecked,
				isAmChecked: allOn,
				listOfSuggestion: updatedListOfSuggestion
			}
		}

		case FETCH_LIST_OF_SUGGESTION_CHANGE_AM: {
			const { checked } = payload
			const listOfSuggestion = cloneDeep(state.listOfSuggestion)

			const updatedListOfSuggestion = listOfSuggestion.map(suggestion => {
				if (amResults.includes(suggestion.resultType)) {
					suggestion.showAsDefault = checked
				}

				return suggestion
			})

			const isAllUnchecked = every(
				updatedListOfSuggestion,
				suggestion => !suggestion.showAsDefault
			)

			if (isAllUnchecked) {
				const index = updatedListOfSuggestion.findIndex(
					suggestion =>
						suggestion.resultType === ConfigurationResultTypes.BestMatch
				)
				if (index >= 0) {
					updatedListOfSuggestion[index].showAsDefault = true
				}
			}

			const allOn = checkAllIsOn(updatedListOfSuggestion)

			return {
				...state,
				isBestMatchChecked: allOn,
				isAmChecked: allOn,
				listOfSuggestion: updatedListOfSuggestion
			}
		}

		default:
			return state
	}
}

export default CustomizeSuggestionsReducer
