import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import {
	DrawingTolerance,
	GroupedTolerance,
	IDefaultPartDrawingTolerance,
	ToleranceCategory,
	ToleranceValueObject,
	ToleranceValueType
} from 'Services/models/SpecificTolerance'
import { getString } from 'Services/Strings/StringService'
import {
	LengthUnit,
	UnitsConversionService,
	UnitSystem
} from 'Services/UnitsConversionService'

export const createDataSheetRow = (
	bilateralValues: boolean,
	applicableToSurfaceArea: boolean,
	main?: string | number,
	bilateralOne?: string | number,
	bilateralTwo?: string | number,
	imageUrl?: string,
	onHideToleranceImageClick?: () => void,
	onShowToleranceImageClick?: (
		imageUrl: string,
		toleranceValueId: string
	) => void,
	toleranceValueIdSelected?: string,
	toleranceValueId = ''
): ToleranceValueType => {
	const firstColumn: ToleranceValueObject = {
		value: '',
		valid: true,
		readOnly: true
	}

	if (imageUrl) {
		firstColumn.forceComponent = true
		firstColumn.imageURL = imageUrl
		firstColumn.component = (
			<div className="specific-tolerances--show-image-icon--wrapper">
				{toleranceValueIdSelected === toleranceValueId ? (
					<VisibilityOffIcon
						className="specific-tolerances--show-image-icon"
						onClick={() => {
							onHideToleranceImageClick && onHideToleranceImageClick()
						}}
					/>
				) : (
					<VisibilityIcon
						className="specific-tolerances--show-image-icon"
						onClick={() =>
							onShowToleranceImageClick &&
							onShowToleranceImageClick(imageUrl, toleranceValueId)
						}
					/>
				)}
			</div>
		)
	}
	return [
		firstColumn,
		{ value: main || '', valid: true },
		{ value: bilateralOne || '', valid: true, readOnly: !bilateralValues },
		{ value: bilateralTwo || '', valid: true, readOnly: !bilateralValues },
		{
			value: applicableToSurfaceArea ? 1 : getString('NOT_APPLICABLE'),
			valid: true,
			readOnly: true
		}
	]
}

export const setupTolerancesTable = (
	defaultPartDrawingTolerances: IDefaultPartDrawingTolerance[],
	partSpecificTolerances: Record<string, any>,
	unitSystem: UnitSystem,
	onHideToleranceImageClick: () => void,
	onShowToleranceImageClick: (
		imageUrl: string,
		toleranceValueId: string
	) => void,
	toleranceValueIdSelected: string
) => {
	const conversionService = new UnitsConversionService(
		UnitSystem.metric,
		unitSystem
	)

	const tolerances = defaultPartDrawingTolerances.reduce(
		(acc: Record<string, GroupedTolerance[]>, tolerance) => {
			// create initial object
			const toleranceToAdd: GroupedTolerance = {
				applicableToSurfaceArea: tolerance.applicableToSurfaceArea,
				bilateralValues: tolerance.bilateralValues,
				category: tolerance.category,
				groupName: tolerance.groupName,
				name: tolerance.name,
				type: tolerance.type,
				error: '',
				values: [
					createDataSheetRow(
						tolerance.bilateralValues,
						tolerance.applicableToSurfaceArea
					)
				]
			}

			// fill with initial values
			const existingValues: DrawingTolerance[] =
				partSpecificTolerances[toleranceToAdd.category]?.filter(
					(tolerance: DrawingTolerance) =>
						tolerance.type === toleranceToAdd.type
				) || []
			if (existingValues.length) {
				const valuesToAdd = existingValues.map((value, valueIndex) => {
					const mainValue =
						typeof value.tolerance === 'object' ? value.size : value.tolerance
					const bilateralOne =
						typeof value.tolerance === 'object' ? value.tolerance.pos : ''
					const bilateralTwo =
						typeof value.tolerance === 'object' ? value.tolerance.neg : ''
					const toleranceValueId = value.type + '_' + valueIndex

					const valueToReturn: ToleranceValueType = createDataSheetRow(
						toleranceToAdd.bilateralValues,
						toleranceToAdd.applicableToSurfaceArea,
						mainValue && conversionService.outputSystem === UnitSystem.imperial
							? conversionService.convertLength(mainValue, false).toFixed(5)
							: mainValue,
						bilateralOne &&
							conversionService.outputSystem === UnitSystem.imperial
							? conversionService.convertLength(bilateralOne, false).toFixed(5)
							: bilateralOne,
						bilateralTwo &&
							conversionService.outputSystem === UnitSystem.imperial
							? conversionService.convertLength(bilateralTwo, false).toFixed(5)
							: bilateralTwo,
						value.toleranceImageURL,
						onHideToleranceImageClick,
						onShowToleranceImageClick,
						toleranceValueIdSelected,
						toleranceValueId
					)
					return valueToReturn
				})

				toleranceToAdd.values = [...valuesToAdd, ...toleranceToAdd.values]
			}

			// add to tolerance group
			if (acc[toleranceToAdd.groupName]) {
				acc[toleranceToAdd.groupName].push(toleranceToAdd)
			} else {
				acc[toleranceToAdd.groupName] = [toleranceToAdd]
			}
			return acc
		},
		{}
	)

	return tolerances
}

export const validateData = (datum: ToleranceValueType) => {
	let error = ''

	const mainValue = datum[1].value
	if (mainValue && (isNaN(+mainValue) || +mainValue < 0)) {
		datum[1].valid = false
		error = getString('TOLERANCE_MAIN_VALUE_ERROR')
	}

	const bilateralValueOne = datum[2].value
	if (bilateralValueOne && isNaN(+bilateralValueOne)) {
		datum[2].valid = false
		error = getString('TOLERANCE_BILATERAL_VALUE_ERROR')
	}

	const bilateralValueTwo = datum[3].value
	if (bilateralValueTwo && isNaN(+bilateralValueTwo)) {
		datum[3].valid = false
		error = getString('TOLERANCE_BILATERAL_VALUE_ERROR')
	}

	return error
}

export const prepareSpecificTolerancesData = (
	tolerances: Record<string, GroupedTolerance[]>,
	unitSystem: UnitSystem
) => {
	const conversionService = new UnitsConversionService(
		unitSystem,
		UnitSystem.metric
	)
	const specificTolerances = {} as Record<string, any>

	const tolerancesArray = Object.values(tolerances).flat(1)

	for (const tolerance of tolerancesArray) {
		const values = tolerance.values.filter(
			innerValues =>
				innerValues.filter(cell => !cell.readOnly && cell.value).length > 0
		)

		const toleranceObjects = values.reduce((acc: DrawingTolerance[], value) => {
			const mainValue = conversionService.convertLength(+value[1].value, false)
			const pos = conversionService.convertLength(+value[2].value, false)
			const neg = conversionService.convertLength(+value[3].value, false)
			const surface_area = isNaN(+value[4].value) ? 0 : +value[4].value
			const toleranceImageURL = value[0].imageURL

			const toleranceObject = {
				type: tolerance.type,
				surface_area: surface_area
			} as DrawingTolerance

			if (tolerance.category === ToleranceCategory.SIZE_TOLERANCES) {
				toleranceObject.size = mainValue
				toleranceObject.tolerance = { pos, neg }
			} else {
				toleranceObject.tolerance = mainValue
			}

			if (toleranceImageURL) {
				toleranceObject.toleranceImageURL = toleranceImageURL
			}

			acc.push(toleranceObject)
			return acc
		}, [])

		if (specificTolerances[tolerance.category]) {
			specificTolerances[tolerance.category] = [
				...specificTolerances[tolerance.category],
				...toleranceObjects
			]
		} else {
			specificTolerances[tolerance.category] = toleranceObjects
		}
	}

	return specificTolerances
}

export const createHeaders = (
	tolerance: GroupedTolerance,
	unitSystem: UnitSystem
) => {
	const lengthUnitType =
		unitSystem === UnitSystem.metric ? LengthUnit.mm : LengthUnit.inch
	const nameColumn = (
		<div className="specific-tolerances--tolerance-name">
			<span title={getString(tolerance.name)}>{getString(tolerance.name)}</span>
			<IconFactory iconName={tolerance.type} />
		</div>
	)
	return [
		nameColumn,
		`[${lengthUnitType}]`,
		getString('BILATERAL_TOLERANCE_HEADER'),
		getString('BILATERAL_TOLERANCE_HEADER'),
		getString('SURFACE_AREA_TOLERANCE_HEADER').format(lengthUnitType)
	]
}
