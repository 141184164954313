import { FC, memo } from 'react'

import cx from 'classnames'

import ProjectPartItem from './PartsThumbnailView/ProjectPartItem'
import ProjectAnalysisActivities from './ProjectSuggestionActivities/ProjectAnalysisActivities'
import { History } from 'history'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorLockedParts from 'Scenes/Components/CastorLockedParts'
import { format2D } from 'Scenes/Home/NewUploadProject/constants'
import { InapplicableStatus } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

interface Props {
	amountOfLockedParts: string
	partResultTitle: string
	suggestion: Record<string, any>
	showStandardCostFinishIcon: boolean
	showExportButton: boolean
	history: History
	userEmail: string
	lightUser: boolean
	contactUsEmail: string
	onStandardCostClick: Function
	onRemoveProjectClick: Function
	onExportClick: Function
	starPartClicked: (e: React.MouseEvent<SVGSVGElement>) => void
	onRemovePartClick: Function
	onRemovePartConfirm: Function
	onPartClick: Function
	onPartPropertiesClick: Function
	is2dProject: boolean
	allPartsNotCostEffective: boolean
}

const SinglePartProjectContent: FC<Props> = ({
	amountOfLockedParts,
	partResultTitle,
	suggestion,
	history,
	starPartClicked,
	onRemovePartClick,
	onStandardCostClick,
	showStandardCostFinishIcon,
	showExportButton,
	userEmail,
	lightUser,
	contactUsEmail,
	onExportClick,
	onRemoveProjectClick,
	onRemovePartConfirm,
	onPartClick,
	onPartPropertiesClick,
	is2dProject,
	allPartsNotCostEffective
}) => {
	if (amountOfLockedParts) {
		return (
			<CastorLockedParts
				amountOfLockedParts={amountOfLockedParts}
				lightUser={lightUser}
				contactUsEmail={contactUsEmail}
			/>
		)
	}

	const isInapplicable =
		suggestion.isDrawing && suggestion.drawingStatusCode === InapplicableStatus

	return (
		<div className="single-part-project">
			<div className="single-part-project--actions">
				<div className="single-part-project--actions--result">
					<div className="single-part-project--actions--result--text">
						{partResultTitle}
					</div>
					{!isInapplicable && (
						<ButtonWithLoader
							color="primary"
							onClick={onPartClick}
							qaDataElementName={'data-qa-printing-configuration-button'}
						>
							{getString('PARTS_GO_TO_CONFIGURATION')}
						</ButtonWithLoader>
					)}
				</div>
				<div className="single-part-project--actions--activities">
					<ProjectAnalysisActivities
						disableProperties
						is2dProject={is2dProject}
						isInapplicable={isInapplicable}
						showStandardCostFinishIcon={showStandardCostFinishIcon}
						showExportButton={showExportButton}
						onStandardCostClick={onStandardCostClick}
						onRemoveProjectClick={onRemoveProjectClick}
						onExportClick={onExportClick}
						deleteButtonClassName="single-part-project--actions--delete"
						deleteButtonIconClassName="single-part-project--actions--delete--icon"
						deleteButtonText={getString('DELETE')}
						onPartPropertiesClick={() => {}}
						allPartsNotCostEffective={allPartsNotCostEffective}
					/>
				</div>
			</div>
			<div className="single-part-project--part">
				<ProjectPartItem
					starred={suggestion.part?.starred || suggestion.cluster?.starred}
					suggestion={suggestion}
					cardClassName="single-part-project--part--card"
					cardSizeWrapperClassName={cx(
						'single-part-project--part--card--size-wrapper project-result',
						{ drawing: suggestion?.formatType === format2D }
					)}
				/>
			</div>
		</div>
	)
}

export default memo(SinglePartProjectContent)
