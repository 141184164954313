import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import cx from 'classnames'
import { some } from 'lodash'

import { changePartsView } from '../../ProjectAnalysisActions'
import { ProjectAnalysisPartsView } from '../../ProjectAnalysisInterfaces'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'

import { ReactComponent as GraphViewIcon } from '../../../../../../assets/img/svg/graph-view-icon.svg'
import { ReactComponent as ListViewIcon } from '../../../../../../assets/img/svg/list-view-icon.svg'
import { ReactComponent as ThumbnailViewIcon } from '../../../../../../assets/img/svg/thumbnail-view-icon.svg'

import './SwitchView.scss'

type IProps = {
	partsView: ProjectAnalysisPartsView
	parts: Part[]
	allPartsInapplicable: boolean
}

const SwitchView: FC<IProps> = ({ partsView, parts, allPartsInapplicable }) => {
	const isGraphViewOn = Feature.isFeatureOn(
		FeatureComponentId.GENERATIVE_DESIGN
	)
	const dispatch = useDispatch()
	const isMetadata =
		parts && some(parts, ({ formatType }) => formatType === METADATA)

	const switchPartsView = (view: string) => {
		dispatch(changePartsView(view))
	}

	if (isMetadata || allPartsInapplicable) {
		return <></>
	}

	return (
		<div className="switch-view--wrapper" data-qa="data-qa-switch-view-button">
			<div
				className={cx('switch-view--button', {
					selected: partsView === ProjectAnalysisPartsView.thumbnail
				})}
				data-qa="data-qa-switch-thumbnail-button"
				onClick={() => switchPartsView(ProjectAnalysisPartsView.thumbnail)}
			>
				<ThumbnailViewIcon />
			</div>
			<div
				className={cx('switch-view--button', {
					selected: partsView === ProjectAnalysisPartsView.list
				})}
				data-qa="data-qa-switch-list-view-button"
				onClick={() => switchPartsView(ProjectAnalysisPartsView.list)}
			>
				<ListViewIcon />
			</div>
			{isGraphViewOn && (
				<div
					className={cx('switch-view--button', {
						selected: partsView === ProjectAnalysisPartsView.graph
					})}
					data-qa="data-qa-chart-view-button"
					onClick={() => switchPartsView(ProjectAnalysisPartsView.graph)}
				>
					<GraphViewIcon />
				</div>
			)}
		</div>
	)
}

export default WithFeatureToggleHOC(
	SwitchView,
	FeatureComponentId.SWITCH_PARTS_VIEW_BUTTON
)
