import React, { FC, memo, useCallback, useMemo } from 'react'

import { FormHelperText } from '@material-ui/core'

import DrawingSelector from './DrawingSelector'
import {
	get2DPartName,
	getStatus,
	getStatusColor
} from './ProjectPartsMappingService'
import { IProjectPartsMapping } from './ProjectPartsMappingTypes'
import DataTableStatusBar from 'Scenes/Components/DataTable/DataTableStatusBar'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	parts2DOptions: Record<string, string>[]
	partMapping: IProjectPartsMapping
	updatePartMapping: (projectId: string, name3D: string, value: string) => void
}
const TableRow: FC<IProps> = ({
	parts2DOptions,
	partMapping,
	updatePartMapping
}) => {
	const drawingSelectorValue = useMemo(
		() => get2DPartName(partMapping),
		[partMapping]
	)
	const status = useMemo(() => getStatus(partMapping), [partMapping])
	const statusData = useMemo(
		() => ({ color: getStatusColor(partMapping) }),
		[partMapping]
	)

	const handle2DOptionSelect = useCallback(
		(value: string) => {
			updatePartMapping(
				partMapping.projectId,
				partMapping.name3D as string,
				value
			)
		},
		[partMapping.name3D, partMapping.projectId, updatePartMapping]
	)

	return (
		<>
			<div>{partMapping.name3D}</div>
			<div className="drawing-selector-wrapper">
				<DrawingSelector
					parts2DOptions={parts2DOptions}
					value={drawingSelectorValue}
					handle2DOptionSelect={handle2DOptionSelect}
				/>
				{!drawingSelectorValue && (
					<FormHelperText className="data-table-dropdown-placeholder">
						{getString('REQUIRED')}
					</FormHelperText>
				)}
			</div>
			<div>
				<DataTableStatusBar text={status} className="" data={statusData} />
			</div>
		</>
	)
}

export default memo(TableRow)
