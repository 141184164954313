import { isEmpty, isEqual, isNil, isObject, transform } from 'lodash'

import { store } from '../../../../..'
import { IConfiguration } from '../../NewPartConfiguration/NewPartConfigurationTypes'
import { ConfigurationResultTypes } from './ConfigurationResultTypes'
import { showCustomFunctionWarningPopup } from './SolutionAnalysisActions'
import {
	CostComparisonService,
	getCrossValue,
	getResultsNumbersAndQuantity,
	removingDuplicates
} from './SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostComparisonService'
import { LeadTimeService } from './SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/LeadTimeTab/LeadTimeService'
import { OptionalPostProcessesIds } from './SolutionAnalysisTopDetails/SolutionAnalysisTopConstants'
import { getUserFeaValues } from './SolutionFea/SolutionFeaContentService'
import {
	CONFIGURATION_CALCULATED,
	HANDLE_NOTIFICATION
} from 'global actions/types'
import { ALERT_POPPED } from 'global actions/types/CastorAlertTypes'
import {
	COST_COMPARISON_EDIT_CLICKED,
	COST_COMPARISON_FORM_CANCELLED,
	MAIN_PART_ANALYSIS_DISABLE_PART_TOGGLED,
	SOLUTION_ANALYSIS_PART_DISABLED,
	SOLUTION_CONFIGURATION_SOLUTION_UPDATED
} from 'global actions/types/partAnalysisTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import { IDataTableField } from 'Scenes/Components/DataTable/IDataTableField'
import { MAX_LAYER_THICKNESS } from 'Scenes/Components/PrinterForm/constants'
import {
	ISimpleConfigurationMaterial,
	ISimpleConfigurationPrinter,
	SimpleConfigurationSelectorService
} from 'Scenes/Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { ProviderGuid } from 'Scenes/IntegrationProvider/providerGuid'
import {
	benefitTypes,
	GA_TAB_SORTING_COLUMN_INDEX,
	LOADING,
	MACHINING_ID,
	manufacturingMethodTypes,
	materialTypes,
	partResults,
	POLLER_COUNTER_CHANGE_UI_TIMEOUT,
	POLLER_COUNTER_TIMEOUT,
	POLLER_COUNTER_WARNING_TIMEOUT,
	POLLER_DELAY,
	printStatusScore
} from 'Services/Constants'
import { timeout } from 'Services/global/timeout'
import { IAlternativePrinters } from 'Services/models/AlternativePrinters'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { IConfigurationColors } from 'Services/models/ConfigurationColors'
import {
	CostDataWithSolution,
	LeadDataWithSolution
} from 'Services/models/CostComparisonModels'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	ChainBenefitsNames,
	IChainBenefits
} from 'Services/models/IChainBenefits'
import { IConfiguration as Configuration, ManufacturingMethod } from 'Services/models/IConfiguration'
import { IFilter } from 'Services/models/IFilter'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { IOrganization } from 'Services/models/IOrganization'
import { OrientationData } from 'Services/models/IOrinetationData'
import { Part, PartStatus, WeightReductionType } from 'Services/models/IPart'
import { IPostProcess } from 'Services/models/IPostProcess'
import { PrinterMaterial } from 'Services/models/IPrinterMaterial'
import { IPriority } from 'Services/models/IPriority'
import { IProviderPrinter } from 'Services/models/IProviderPrinter'
import { SolutionConfigurationTypes } from 'Services/models/ISolutionConfiguration'
import { FeaAnalysisResults, ISolutionFea } from 'Services/models/ISolutionFea'
import { IUserProvider } from 'Services/models/IUserProvider'
import {
	PartPrintIssue,
	PrintIssue,
	PrintIssueId
} from 'Services/models/PartPrintIssue'
import { getPrintingOrientation, updateWallThickness } from 'Services/Network'
import {
	getConfiguration,
	getWallThickness
} from 'Services/Network/PartAnalysisNetwork'
import {
	checkResultsIssues,
	getCNCOrientedIssueString,
	getHeatDeformationIssue,
	getIconNameForScore,
	getIssue,
	getMaterialScore,
	getOrientationStabilityString,
	getResultScore,
	getSizeScore,
	getTextForPartSize,
	getTextForPartThreads,
	getToleranceString
} from 'Services/PrintIssueService'
import {
	CONFIGURATION_CALCULATION_ERROR,
	CONFIGURATION_CALCULATION_LONG_TIME,
	CONFIGURATION_RESULT_BORDERLINE,
	CONFIGURATION_RESULT_NOT_PRINTABLE,
	INTERNAL_CAVITIES,
	OK,
	PART_ANALYSIS_NEW_SOLUTION_NAME,
	PART_RESULTS_CAD,
	PART_RESULTS_CNC,
	PART_RESULTS_MATERIAL,
	PART_RESULTS_SIZE,
	PART_RESULTS_WALL_THICKNESS,
	SHOW_NOTIFICATION,
	THREADS,
	WALL_THICKNESS_UPDATE_ERROR
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'
import { printLeadTimeBreakDown } from 'Services/Utils/consoleQAUtils'
import { getTheme } from 'themes/getTheme'

const costComparisonService = new CostComparisonService()
const leadTimeService = new LeadTimeService()
const theme = getTheme()
const { tabOrderActive, tabOrder } = getTheme()

export const maxMultiplier = 5

export class SolutionAnalysisService {
	result: string
	configuration: any
	optionalPostProcessAvailability: any
	allOptionalPostProcessesData: any
	postProcessesBreakDownData: Array<any>

	constructor(
		configuration: any,
		optionalPostProcessAvailability: any,
		allOptionalPostProcessesData: any,
		postProcessesBreakDownData: any
	) {
		this.result = configuration.result
		this.configuration = configuration
		this.optionalPostProcessAvailability = optionalPostProcessAvailability
		this.allOptionalPostProcessesData = allOptionalPostProcessesData
		this.postProcessesBreakDownData = postProcessesBreakDownData
	}

	getSolutionColor = (disablePart?: boolean): IConfigurationColors => {
		if (disablePart) {
			return {
				color: theme.colors.failedColor,
				colorOnBackground: theme.colors.failedColorOnBackground
			}
		}
		if (this.configuration.id <= 0) {
			return {
				color: theme.colors.successColor,
				colorOnBackground: theme.colors.successColorOnBackground
			}
		}
		if (!this.configuration.solution) {
			return {
				color: theme.colors.failedColor,
				colorOnBackground: theme.colors.failedColorOnBackground
			}
		}

		switch (this.result) {
			case partResults.printable:
				return {
					color: theme.colors.passedColor,
					colorOnBackground: theme.colors.passedColorOnBackground
				}
			case partResults.notPrintable:
				return {
					color: theme.colors.failedColor,
					colorOnBackground: theme.colors.failedColorOnBackground
				}
			case partResults.borderline:
				return {
					color: theme.colors.passedColor,
					colorOnBackground: theme.colors.passedColorOnBackground
				}

			default:
				return {
					color: theme.colors.failedColor,
					colorOnBackground: theme.colors.failedColorOnBackground
				}
		}
	}

	getSolutionName = (configuration: any): string => {
		if (this.configuration.id === -1) {
			return getString('PART_ANALYSIS_NEW_SIMPLE_SOLUTION_NAME')
		}
		if (this.configuration.id === 0) {
			return PART_ANALYSIS_NEW_SOLUTION_NAME
		}
		return configuration.name
	}
	disabledConfiguration = (configuration: any): boolean => {
		return configuration?.type === SolutionConfigurationTypes.custom
	}
	isCustomConfiguration = (configuration: any): boolean => {
		return configuration?.type === SolutionConfigurationTypes.custom
	}
	getSolutionResultTitle = (): string => {
		if (!this.configuration.solution) {
			return 'unprintable'
		} else if (
			this.configuration.resultType === ConfigurationResultTypes.Desktop
		) {
			return 'Can Pass'
		}
		switch (this.result) {
			case partResults.printable:
				return 'Pass'
			case partResults.borderline:
				return 'Can Pass'
			case partResults.notPrintable:
				return 'unprintable'

			default:
				return 'unprintable'
		}
	}

	getSolutionResult = (disablePart?: boolean): string => {
		if (disablePart) {
			return LOADING
		} else if (!this.configuration.solution) {
			return partResults.notPrintable
		} else if (
			this.configuration.resultType === ConfigurationResultTypes.Desktop &&
			this.result !== partResults.notPrintable
		) {
			return partResults.borderline
		}
		return this.result
	}

	getSolutionResultBody = (disablePart?: boolean): string => {
		if (disablePart) {
			return getString('UPDATING...')
		}
		if (!this.configuration.solution) {
			return CONFIGURATION_RESULT_NOT_PRINTABLE
		}
		switch (this.result) {
			case partResults.printable:
				return getString('CONFIGURATION_RESULT_PRINTABLE')
			case partResults.borderline:
				return CONFIGURATION_RESULT_BORDERLINE
			case partResults.notPrintable:
				return CONFIGURATION_RESULT_NOT_PRINTABLE
			default:
				return CONFIGURATION_RESULT_NOT_PRINTABLE
		}
	}
	setupSolutionPostPostProcessToggles = (
		configurationId: number,
		toleranceIncluded: boolean,
		postProcessInitialValues?: any,
		solution?: any
	): any => {
		let postProcessToggles: any = {}
		const solutionExist = solution && !!Object.keys(solution).length

		if (!solutionExist || !postProcessInitialValues || configurationId === 0) {
			return this.postProcessesTogglesNewConfiguration(
				toleranceIncluded,
				solutionExist,
				solution
			)
		}
		this.allOptionalPostProcessesData &&
			postProcessInitialValues &&
			this.allOptionalPostProcessesData.forEach((process: IPostProcess) => {
				let disableProcess: boolean = true
				if (solution) {
					if (postProcessInitialValues[process.id]?.disabled) {
						disableProcess = true
					} else {
						disableProcess = this.isPostProcessValid(
							solution,
							process.id as string
						)
					}
				}
				const postProcessToggled =
					postProcessInitialValues[process.id] &&
					postProcessInitialValues[process.id].toggled === true
				// do not add custom post processes that are unavailable
				if (!process.custom || (process.custom && !disableProcess)) {
					postProcessToggles[process.id] = {
						toggled: postProcessToggled || false,
						disabled: disableProcess
					}
				}
			})

		return postProcessToggles
	}

	postProcessesTogglesNewConfiguration = (
		toleranceIncluded: boolean,
		solutionExists: boolean,
		solution?: any
	) => {
		const postProcessToggles: any = {}
		this.allOptionalPostProcessesData &&
			this.allOptionalPostProcessesData.forEach((process: IPostProcess) => {
				let addPostProcess = true
				let toggled = false
				if (process.custom && solutionExists) {
					const customPostProcessIsValid = this.isPostProcessValid(
						solution,
						process.id as string
					)
					if (!customPostProcessIsValid) {
						addPostProcess = false
					}
				}
				if (
					process.id == MACHINING_ID &&
					solutionExists &&
					process.on === null
				) {
					toggled = toleranceIncluded
				}
				if (addPostProcess) {
					postProcessToggles[process.id] = {
						toggled: toggled,
						disabled: false
					}
				}
			})

		return postProcessToggles
	}
	isPostProcessValid = (solution: any, processId: string) => {
		const printerMaterialTechnology =
			this.getPrinterMaterialTechnology(solution)
		const printerTechnology = solution?.printerTechnology
		const printerMaterialId = this.getPrinterMaterialId(solution)
		const argsValid =
			!!printerMaterialTechnology &&
			!!printerMaterialId &&
			!!this.optionalPostProcessAvailability

		// don't allow user to turn on support removal machining
		// if it's not allowed by technology's machiningIncluded
		if (
			processId ==
				OptionalPostProcessesIds.SupportRemovalMachining.toString() &&
			printerTechnology?.machiningIncluded === false
		) {
			return true
		}

		if (argsValid) {
			const materialPermissions =
				this.optionalPostProcessAvailability[processId][
					printerMaterialTechnology
				]
			if (materialPermissions && materialPermissions?.materials_ids?.length) {
				return !materialPermissions.materials_ids.some(
					(id: number) => id == printerMaterialId
				)
			}
			if (materialPermissions && materialPermissions.all) {
				return false
			}
		}
		return true
	}
	getPrinterMaterialTechnology = (solution: any) => {
		return solution?.printerMaterial?.printerTechnologyName?.toLowerCase()
	}
	getPrinterMaterialId = (solution: any) => {
		return solution?.printerMaterialId
	}
	howManyPostProcessesOn = (configuration: any): number => {
		let postProcesses
		if (typeof configuration.postProcessesOptional === 'object') {
			if (!configuration || !configuration.postProcessesOptional) {
				return 0
			}
			if (configuration.id <= 0) {
				return 0
			}
			postProcesses = configuration.postProcessesOptional
		} else {
			postProcesses = configuration
		}
		return this.postProcessesOnCounter(postProcesses)
	}
	howManyPostProcessesOnTempToggle = (togglesObject: any): number => {
		if (!togglesObject) {
			return 0
		}
		return this.postProcessesOnCounter(togglesObject)
	}
	setupSolutionFilterToggles = (
		filterInitialValues: any,
		configurationId: number,
		filters: IFilter[]
	): any => {
		const filterToggles: any = {}
		filters &&
			filters
				.sort(
					(x: any, y: any) => Number(y.showAsDefault) - Number(x.showAsDefault)
				) //Number(true) = 1, Number(false) = 0
				.map(filter => {
					if (configurationId === 0) {
						filterToggles[filter.name] =
							filter.mandatory || filter.showAsDefault
					} else {
						if (
							filterInitialValues &&
							filterInitialValues[filter.name] == null &&
							filter.type === 'boolean' &&
							filter.defaultValue != null
						) {
							filterToggles[filter.name] = !!filter.defaultValue
						} else {
							filterToggles[filter.name] = !!(
								(filterInitialValues &&
									filterInitialValues[filter.name] != null) ||
								filter.mandatory
							)
						}
					}
				})
		return filterToggles
	}

	setupSolutionPrioritiesToggles = (
		prioritiesInitialValues: any,
		priorities: IPriority[]
	): any => {
		const prioritiesToggles: any = {}
		priorities &&
			priorities.map(priority => {
				prioritiesToggles[priority.name] =
					prioritiesInitialValues &&
					prioritiesInitialValues[priority.name] != null
			})
		return prioritiesToggles
	}

	setupSolutionPriorities = (
		prioritiesInitialValues: any,
		priorities: IPriority[]
	): Map<string, number> => {
		const solutionPriorities = new Map<string, number>()
		priorities &&
			priorities.map(priority => {
				const priorityValue =
					prioritiesInitialValues && prioritiesInitialValues[priority.name]
				if (priorityValue) {
					solutionPriorities.set(priority.name, priorityValue)
				}
			})

		return solutionPriorities
	}

	addSolutionPriorities = (
		priorities: IPriority[],
		showSolutionPriorities: any,
		solutionPriorities: Map<string, number>
	): Map<string, number> => {
		const newSolutionPriorities = new Map<string, number>()
		priorities &&
			priorities.map(priority => {
				if (solutionPriorities.has(priority.name)) {
					newSolutionPriorities.set(
						priority.name,
						solutionPriorities.get(priority.name) || 1
					)
				} else if (showSolutionPriorities[priority.name]) {
					newSolutionPriorities.set(priority.name, 1)
				}
			})
		return newSolutionPriorities
	}

	calculateNewSolutionPriorities = (
		solutionPriorities: Map<string, number>,
		priorityName: string,
		priorityValue: number
	): Map<string, number> => {
		const newSolutionPriorities: Map<string, number> = new Map<string, number>(
			solutionPriorities
		)
		newSolutionPriorities.set(priorityName, priorityValue)
		return newSolutionPriorities
	}

	getCostBenefit = (solution: any) => {
		let costBenefit: any = undefined
		if (!solution?.benefits) {
			return costBenefit
		}
		const { benefits } = solution
		if (benefits && benefits.length) {
			costBenefit = benefits.find(
				(benefit: any) => benefit.type === benefitTypes.costSaving
			)
		}
		return costBenefit
	}

	getTimeBenefit = (solution: any) => {
		let timeBenefit: any = undefined
		if (!solution?.benefits) {
			return timeBenefit
		}
		const { benefits } = solution
		if (benefits && benefits.length) {
			timeBenefit = benefits.find(
				(benefit: any) => benefit.type === benefitTypes.timeSaving
			)
		}
		return timeBenefit
	}
	postProcessesOnCounter = (postProcesses: any) => {
		let numberOfPostProcessesOn = 0

		const postProcessesIds = Object.keys(postProcesses)

		if (postProcesses) {
			postProcessesIds?.forEach(id => {
				const { editDisabled = false } =
					this.allOptionalPostProcessesData.find(
						(process: IPostProcess) => process.id == id
					) || {}
				if (postProcesses[id] && postProcesses[id].toggled && !editDisabled) {
					numberOfPostProcessesOn++
				}
			})
			return numberOfPostProcessesOn
		}
		return 0
	}
	createCostDetailsPostProcessesLabels = (
		postProcessesOptional: any,
		costDetails: any
	) => {
		if (!costDetails || !postProcessesOptional) {
			return null
		}
		let labels: any = {}
		Object.keys(postProcessesOptional).map((processId: any) => {
			labels[processId] = { mainLabel: null, breakdown: [] }
			const post = this.allOptionalPostProcessesData.find(
				(postProcess: any) =>
					postProcess.id == processId &&
					postProcessesOptional[processId].toggled === true
			)
			if (post) {
				labels[post.id].mainLabel = post.labelName || post.name
			}
			return undefined
		})

		Object.keys(postProcessesOptional).map((processId: any) => {
			if (labels[processId].mainLabel !== null) {
				labels[processId]?.breakdown.push(
					...this.findPostProcessesBreakDownLabels(processId).filter(Boolean)
				)
			}
		})

		costDetails.labels = labels
		return costDetails
	}

	findPostProcessesBreakDownLabels = (processId: number | string) => {
		return (
			this.postProcessesBreakDownData?.filter(
				(postProcessBreakdown: any) =>
					postProcessBreakdown.process_id == processId
			) || []
		)
	}

	updateSolution = async (
		configuration: any,
		solution: any,
		dispatch: any,
		manufacturingMethod: string,
		partPrintIssues: PartPrintIssue[],
		alternativeSolutions?: Array<IAlternativePrinters> | null,
		keepForm: boolean = false,
		keepAnalysis: boolean = false,
		changeQuantity: boolean = false,
		isLeadTime: boolean = false,
		doNotCalculateCostData: boolean = false
	) => {
		const state = store.getState()
		const {
			user: { userCurrencySign, defaultSettings, userUnitSystem },
			MainPartAnalysisReducer: {
				allConfigurationsOrganizationSettings,
				drawingCostPercentage: drawingPartPercentage
			},
			SolutionAnalysisReducer: { states }
		} = state
		const customizationSettings =
			allConfigurationsOrganizationSettings?.[configuration.organizationId]
				?.customizationSettings || defaultSettings
		const drawingCostPercentage =
			customizationSettings.drawingCostPercentage || drawingPartPercentage
		const customizeUnitSystem = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
		)
		const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric

		const {
			actualResult,
			formParameters,
			chartData,
			printCostQuantity,
			isCostEffective,
			supplyChainCostsDetailsTM,
			chartLeadData,
			wallThickessModelURL,
			isAmOriginalMaterial
		} = states[configuration.id] || {}

		if (doNotCalculateCostData && wallThickessModelURL) {
			// do not rerender 3d viewer model image
			configuration.wallThickessModelURL = wallThickessModelURL
		}
		// if Manufacturing or quantity changed we should disable Tab switch
		const disableTabUpdate =
			changeQuantity || configuration.manufactureMethod !== manufacturingMethod
		let costData: CostDataWithSolution = new CostDataWithSolution(
			solution,
			configuration,
			actualResult,
			formParameters,
			chartData,
			printCostQuantity,
			isCostEffective,
			supplyChainCostsDetailsTM
		)
		let leadTimeData: LeadDataWithSolution = new LeadDataWithSolution(
			solution,
			configuration,
			actualResult,
			formParameters,
			chartLeadData,
			printCostQuantity,
			isCostEffective,
			supplyChainCostsDetailsTM
		)

		if (!alternativeSolutions) {
			alternativeSolutions = configuration.alternativeSolutions
		}
		if (configuration.id > 0 && solution && !doNotCalculateCostData) {
			costData = await costComparisonService.getCostDataWithSolution(
				solution.id,
				configuration.quantity,
				manufacturingMethod,
				userCurrencySign,
				changeQuantity,
				drawingCostPercentage,
				configuration.part?.isDrawing ||
					configuration.part?.formatType === METADATA,
				configuration?.costResults?.AMMinResults,
				configuration?.costResults?.AMMaxResults,
				configuration?.part?.blockManufacturingMethodOperation,
				isAmOriginalMaterial,
				configuration.isSpecifiedQuantity,
				configuration.mainManufactureMethod
			)

			leadTimeData = leadTimeService.getLeadDataWithSolution(
				costData?.solution?.id,
				manufacturingMethod,
				costData?.configuration,
				costData?.configuration?.quantity,
				isAmOriginalMaterial
			)

			const newAlternativeSolutions =
				costData?.configuration?.alternativeSolutions
			if (newAlternativeSolutions) {
				alternativeSolutions = newAlternativeSolutions
			}
		}

		const updatedConfiguration = costData?.configuration || configuration

		const configurationPrintIssues = partPrintIssues.filter(
			partPrintIssue =>
				partPrintIssue.configurationId === configuration.id ||
				!partPrintIssue.configurationId
		)

		const trayOrientationVector: number[] = this.getSolutionOrientationVector(
			updatedConfiguration,
			costData?.solution || solution
		)

		let costBenefit: any = null
		let timeBenefit: any = null

		if (costData.solution) {
			costBenefit = this.getCostBenefit(costData.solution)
			timeBenefit = this.getTimeBenefit(costData.solution)
		}
		const postProcessToggles: any = this.setupSolutionPostPostProcessToggles(
			updatedConfiguration.id,
			updatedConfiguration?.toleranceIncluded,
			updatedConfiguration?.postProcessesOptional,
			costData?.solution
		)
		const PostProcessOnCounter =
			this.howManyPostProcessesOnTempToggle(postProcessToggles)
		const costDetails: any = this.createCostDetailsPostProcessesLabels(
			updatedConfiguration?.postProcessesOptional,
			costData?.solution?.costDetails
		)

		const analysisResultsRows = this.getAnalysisResultsRows(
			costData?.solution || solution,
			updatedConfiguration,
			configurationPrintIssues,
			trayOrientationVector,
			configuration.part?.isDrawing,
			configuration.part?.formatType === METADATA
		)
		const failedPrintIssuesIds = this.getFailedPrintIssues(analysisResultsRows)

		let solutionOrientationVector: number[] =
			trayOrientationVector && trayOrientationVector.length
				? trayOrientationVector
				: []

		const trayOrientationCustom = this.isOrientationCustom(
			updatedConfiguration,
			costData?.solution || solution
		)
		const chosenOrientation = this.getChosenOrientation(
			configuration.part?.trayOrientations?.data ||
				configuration.part?.trayOrientations ||
				[],
			trayOrientationVector
		)

		const { effectiveQuantity } = findEffectivePoints(
			costData.configuration,
			undefined,
			drawingCostPercentage
		)

		const confLeadTimeDetails =
			updatedConfiguration?.leadTimeResults?.leadTimeDetails ||
			updatedConfiguration?.leadTimeResults?.comparedLeadTimeDetails ||
			{}
		const solLeadTimeDetails =
			costData?.solution?.leadTimeDetails ||
			updatedConfiguration?.leadTimeResults?.mainLeadTimeDetails
		{
		}

		//Only for lead time testing
		printLeadTimeBreakDown(
			configuration?.name || '',
			confLeadTimeDetails?.printingLeadTimeBreakDown || {},
			solLeadTimeDetails?.printingLeadTimeBreakDown || {}
		)

		if (
			costData?.configuration?.traditionalCostIsAbnormal ||
			costData?.configuration?.comparedSolutionCostIsAbnormal
		) {
			dispatch(showCustomFunctionWarningPopup())
		}

		if (!costData?.configuration) {
			costData.configuration = updatedConfiguration
		}

		dispatch({
			type: SOLUTION_CONFIGURATION_SOLUTION_UPDATED,
			payload: {
				id: configuration.id,
				costBenefit,
				timeBenefit,
				postProcessToggles,
				costDetails,
				PostProcessOnCounter,
				...costData,
				analysisResultsRows,
				failedPrintIssuesIds,
				manufacturingMethod,
				alternativeSolutions,
				keepForm,
				keepAnalysis,
				solutionOrientationVector,
				chosenOrientation,
				trayOrientationCustom,
				effectiveQuantity,
				disableTabUpdate,
				filterInitialValues: configuration.filters,
				tabIndex: isLeadTime ? tabOrder.leadTime : tabOrder.costAnalysis,
				chartLeadData: leadTimeData.chartData,
				unitSystem
			}
		})

		dispatch({
			type: CONFIGURATION_CALCULATED
		})
		if (!keepForm) {
			// The code beneath is a plaster for the 'react-apexChart' rerender xAxis
			// bug. issue: https://github.com/apexcharts/react-apexcharts/issues/94
			dispatch({
				type: COST_COMPARISON_EDIT_CLICKED,
				payload: {
					id: configuration.id
				}
			})
			dispatch({
				type: COST_COMPARISON_FORM_CANCELLED,
				payload: {
					id: configuration.id
				}
			})
		}
	}

	getInitialSelectedTab = (
		solution: any,
		showMechanicalTab: boolean = false,
		isAMSuggestion: boolean = true
	) => {
		if (!solution) {
			return tabOrderActive.activeTab
		}
		if (showMechanicalTab) {
			return tabOrderActive.mechanicalTab
		}
		if (
			isAMSuggestion &&
			(this.result === partResults.notPrintable ||
				this.result === partResults.borderline)
		) {
			return tabOrderActive.unprintableTab
		}
		if (Feature.isFeatureOn(FeatureComponentId.SUMMARY_COST_TAB)) {
			return tabOrderActive.summaryTab
		}
		return tabOrderActive.defaultTab
	}
	toShowWeightReductionButton = (
		type: SolutionConfigurationTypes,
		configurationResult: string,
		part: Part
	) => {
		const printable = configurationResult === CADAnalysisResult.printable
		const customType = type === SolutionConfigurationTypes.custom
		const weightReductionPossible =
			part?.weightReductionType === WeightReductionType.SUITABLE_FOR_WR
		if (!printable || customType) {
			return false
		}
		return weightReductionPossible
	}

	failOnStructuralLimitations = (
		configurationFailOnSize: boolean,
		score?: number,
		overhangingScore?: number
	) => {
		if (configurationFailOnSize) {
			return true
		}
		if (
			overhangingScore === printStatusScore.failed ||
			overhangingScore === 95
		) {
			return true
		}
		if (score == null || score === printStatusScore.passed) {
			return false
		}
		if (score === printStatusScore.failed) {
			return true
		}
		return true
	}

	getIssueScore = ({
		printIssuesList,
		printIssueId,
		isPartLevel,
		vector
	}: {
		printIssuesList: PartPrintIssue[]
		printIssueId: PrintIssueId
		isPartLevel?: boolean
		vector?: number[]
	}) => {
		const issue = getIssue({
			printIssuesList,
			printIssueId,
			isPartLevel,
			vector
		})
		if (!issue) {
			return printStatusScore.passed
		}
		return issue.score
	}

	createOrientationsAnalysisObjectsForTable = (
		orientationsData: Array<OrientationData>,
		configuration: any,
		partSolution: any,
		configurationPrintIssues: PartPrintIssue[]
	) => {
		return orientationsData.forEach((orientationData: OrientationData) => {
			orientationData.analysisResult = this.getOrientationAnalysisResultsRows(
				partSolution,
				configuration,
				orientationData,
				configurationPrintIssues,
				true
			)
		})
	}

	getPartSizeFieldRow = (
		dataTableService: DataTableService,
		configurationPrintIssues: PartPrintIssue[],
		orientationVector: number[],
		part: Part,
		solution: any,
		quantity: number,
		isOrientation: boolean,
		unitSystem: UnitSystem
	) => {
		const { partSizeIssue, configurationSizeVectorIssue } = getIssues(
			configurationPrintIssues,
			orientationVector
		)

		const sizeScore = Math.min(
			partSizeIssue == null ? printStatusScore.passed : partSizeIssue.score,
			configurationSizeVectorIssue == null
				? printStatusScore.passed
				: configurationSizeVectorIssue.score
		)
		if (sizeScore === printStatusScore.passed) {
			return []
		} else {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: PART_RESULTS_SIZE,
					order: partSizeIssue?.printIssue.order,
					iconName: getIconNameForScore(sizeScore)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: getTextForPartSize(
						quantity,
						unitSystem,
						partSizeIssue,
						configurationSizeVectorIssue,
						undefined,
						part,
						solution
					),
					className:
						'solution-orientation--data-table--row--text data-table-opacity-field',
					score: sizeScore,
					withAdditiveMind: !isOrientation
				})
			]
		}
	}

	getWallThicknessFieldRow = (
		dataTableService: DataTableService,
		wallThicknessIssue?: PartPrintIssue,
		isOrientation?: boolean
	) => {
		if (
			wallThicknessIssue &&
			wallThicknessIssue?.score !== printStatusScore.passed
		) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: PART_RESULTS_WALL_THICKNESS,
					order: wallThicknessIssue?.printIssue.order,
					iconName: getIconNameForScore(
						wallThicknessIssue?.score,
						wallThicknessIssue?.active
					)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: wallThicknessIssue?.message || '',
					score: wallThicknessIssue?.score,
					withAdditiveMind: !isOrientation,
					className:
						'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
				})
			]
		}
		return []
	}

	getOverhangingFailRow = (
		dataTableService: DataTableService,
		overhangingIssue?: PartPrintIssue,
		isOrientation?: boolean
	) => {
		const overhangingIssueScore =
			overhangingIssue == null
				? printStatusScore.passed
				: overhangingIssue.score
		if (overhangingIssueScore < printStatusScore.passed) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: getString('OVERHANGING'),
					order: overhangingIssue?.printIssue.order,
					iconName: getIconNameForScore(
						overhangingIssue?.score,
						overhangingIssue?.active
					)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: overhangingIssue?.message || '',
					className:
						'solution-orientation--data-table--row--text data-table-opacity-field',
					score: overhangingIssue?.score,
					withAdditiveMind: !isOrientation
				})
			]
		}
		return []
	}

	getTolerancesFailRow = (
		dataTableService: DataTableService,
		unitSystem: UnitSystem,
		tolerancesIssue?: PartPrintIssue,
		part?: Part,
		solution?: any,
		isOrientation?: boolean
	) => {
		if (tolerancesIssue && tolerancesIssue?.score !== printStatusScore.passed) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: getString('TOLERANCE'),
					order: tolerancesIssue?.printIssue.order,
					iconName: getIconNameForScore(
						tolerancesIssue?.score,
						tolerancesIssue?.active
					)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: getToleranceString(
						unitSystem,
						tolerancesIssue?.score,
						part,
						solution
					),
					score: tolerancesIssue?.score,
					withAdditiveMind: !isOrientation,
					className:
						'solution-orientation--data-table--row--text data-table-opacity-field'
				})
			]
		}
		return []
	}

	getHolesFailRow = (
		dataTableService: DataTableService,
		holesIssue?: PartPrintIssue,
		isOrientation?: boolean
	) => {
		const holesIssueScore =
			holesIssue == null ? printStatusScore.passed : holesIssue.score
		if (
			holesIssueScore < printStatusScore.passed &&
			FeatureComponentId.HOLES_ANALYSIS
		) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: getString('HOLES'),
					order: holesIssue?.printIssue.order,
					iconName: getIconNameForScore(holesIssue?.score, holesIssue?.active)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: holesIssue?.message || '',
					className:
						'solution-orientation--data-table--row--text data-table-opacity-field',
					score: holesIssue?.score,
					withAdditiveMind: !isOrientation
				})
			]
		}
		return []
	}

	getThreadsFailRow = (
		dataTableService: DataTableService,
		threadsIssue?: PartPrintIssue,
		configuration?: any,
		isOrientation?: boolean
	) => {
		const threadIssueScore =
			threadsIssue == null ? printStatusScore.passed : threadsIssue.score
		if (
			threadIssueScore < printStatusScore.passed &&
			FeatureComponentId.THREAD_DETECTION
		) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: getString('THREADS'),
					order: threadsIssue?.printIssue.order,
					iconName: getIconNameForScore(
						threadsIssue?.score,
						threadsIssue?.active
					)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text:
						threadsIssue?.message ||
						getTextForPartThreads(
							configuration?.part || configuration?.cluster
						),
					className:
						'solution-orientation--data-table--row--text' +
						' data-table-opacity-field',
					score: threadsIssue?.score,
					withAdditiveMind: !isOrientation
				})
			]
		}
		return []
	}

	getCadFailRow = (
		dataTableService: DataTableService,
		CADIssue?: PartPrintIssue,
		isOrientation?: boolean
	) => {
		const CADIssueScore =
			CADIssue == null ? printStatusScore.passed : CADIssue.score
		if (CADIssueScore < printStatusScore.passed) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: PART_RESULTS_CAD,
					order: CADIssue?.printIssue.order,
					iconName: getIconNameForScore(CADIssueScore, CADIssue?.active)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: CADIssue?.message || getString('MESH_HEALING_COMPLETED'),
					score: CADIssueScore,
					withAdditiveMind: !isOrientation,
					className:
						'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
				})
			]
		}
		return []
	}

	getInternalCavitiesFailRow = (
		dataTableService: DataTableService,
		internalCavitiesIssue?: PartPrintIssue,
		isOrientation?: boolean
	) => {
		const internalCavitiesIssueScore =
			internalCavitiesIssue == null
				? printStatusScore.passed
				: internalCavitiesIssue.score
		if (internalCavitiesIssueScore < printStatusScore.passed) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: INTERNAL_CAVITIES,
					order: internalCavitiesIssue?.printIssue.order,
					iconName: getIconNameForScore(
						internalCavitiesIssueScore,
						internalCavitiesIssue?.active
					)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: internalCavitiesIssue?.message || '',
					score: internalCavitiesIssueScore,
					withAdditiveMind: !isOrientation,
					className:
						'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
				})
			]
		}
		return []
	}

	getCNCFailRow = (
		dataTableService: DataTableService,
		CNCIssue?: PartPrintIssue,
		solution?: any,
		isOrientation?: boolean
	) => {
		if (!Feature.isFeatureOn(FeatureComponentId.CNC_SUPPORT_REMOVAL)) {
			return []
		}
		const materialIsMetal =
			solution?.printerMaterial?.type === materialTypes.metal
		if (solution && !materialIsMetal) {
			return []
		}
		if (CNCIssue && CNCIssue?.score < printStatusScore.passed) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: PART_RESULTS_CNC,
					order: CNCIssue.printIssue.order,
					iconName: getIconNameForScore(CNCIssue?.score, CNCIssue.active)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: CNCIssue?.message || '',
					score: CNCIssue?.score,
					withAdditiveMind: !isOrientation,
					className:
						'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
				})
			]
		}
		return []
	}

	getPrintStabilityIssue = (
		dataTableService: DataTableService,
		featureComponentId: FeatureComponentId,
		text: string,
		printIssue = {} as PartPrintIssue,
		withActive: boolean = false,
		isOrientation?: boolean
	) => {
		if (!Feature.isFeatureOn(featureComponentId)) {
			return []
		}
		if (printIssue?.score === printStatusScore.failed) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: text,
					order: printIssue?.printIssue.order,
					iconName: getIconNameForScore(printIssue?.score, withActive)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: printIssue?.message || '',
					score: printIssue?.score,
					withAdditiveMind: !isOrientation,
					className:
						'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
				})
			]
		}
		return []
	}

	getHeatDeformationIssue = (
		dataTableService: DataTableService,
		heatDeformationIssue?: PartPrintIssue,
		solution?: any,
		isOrientation?: boolean
	) => {
		if (!Feature.isFeatureOn(FeatureComponentId.HEAT_DEFORMATION)) {
			return []
		}
		const materialIsMetal =
			solution?.printerMaterial?.type === materialTypes.metal
		if (!materialIsMetal) {
			return []
		}

		if (
			heatDeformationIssue &&
			heatDeformationIssue?.score < printStatusScore.passed
		) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: getString('PART_RESULTS_HEAT_DEFORMATION'),
					order: heatDeformationIssue.printIssue.order,
					iconName: getIconNameForScore(
						heatDeformationIssue?.score,
						heatDeformationIssue?.active
					)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text:
						heatDeformationIssue?.message ||
						getString('PART_RESULTS_HEAT_DEFORMATION_SUCCESS'),
					score: heatDeformationIssue?.score,
					withAdditiveMind: !isOrientation,
					className:
						'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
				})
			]
		}
		return []
	}

	getMaterialFailRow = (
		solution: any,
		configuration: any,
		dataTableService: DataTableService,
		solutionWithError: boolean,
		isOrientation?: boolean,
		materialPrintIssue?: PrintIssue
	) => {
		const materialScore = getMaterialScore(
			solution,
			configuration,
			solutionWithError
		)
		if (materialScore < printStatusScore.passed) {
			return [
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: PART_RESULTS_MATERIAL,
					order: materialPrintIssue?.order,
					iconName: getIconNameForScore(materialScore)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					text: configuration.failReason || '',
					score: materialScore,
					withAdditiveMind: !isOrientation,
					className:
						'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
				})
			]
		}
		return []
	}

	getOrientationAnalysisResultsRows = (
		solution: any,
		configuration: any,
		orientationData: OrientationData,
		configurationPrintIssues: PartPrintIssue[],
		isOrientation: boolean
	): any[][] => {
		const dataTableService = new DataTableService()
		const {
			partSizeIssue,
			orientationSizeIssue,
			configurationSizeIssue,
			wallThicknessIssue,
			overhangingIssue,
			tolerancesIssue,
			holesIssue,
			CADIssue,
			CNCIssue,
			printStabilityIssue,
			orientedCNCIssue,
			heatDeformationIssue,
			threadsIssue,
			internalCavitiesIssue
		} = getIssues(configurationPrintIssues, orientationData.trayNormalVector)

		const {
			user: { printIssues, userUnitSystem }
		} = store.getState()
		const customizeUnitSystem = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
		)
		const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric
		// we need material issue to know its order
		const materialPrintIssue = printIssues?.find(
			(issue: PrintIssue) => issue.id === PrintIssueId.Material
		)

		const sizeScore = getSizeScore(
			partSizeIssue,
			orientationSizeIssue,
			configurationSizeIssue
		)
		const analysisSizes = [
			sizeScore,
			getResultScore(CADIssue),
			getResultScore(holesIssue),
			getResultScore(threadsIssue),
			getResultScore(tolerancesIssue),
			getResultScore(overhangingIssue),
			getResultScore(wallThicknessIssue),
			getResultScore(CNCIssue),
			getResultScore(printStabilityIssue),
			getResultScore(orientedCNCIssue),
			getResultScore(heatDeformationIssue),
			getResultScore(internalCavitiesIssue)
		]

		const solutionWithError = checkResultsIssues(analysisSizes)

		const partSizeRow = this.getPartSizeFieldRow(
			dataTableService,
			configurationPrintIssues,
			orientationData.trayNormalVector,
			configuration?.part || configuration?.cluster,
			solution,
			configuration?.quantity,
			isOrientation,
			unitSystem
		)

		if (
			!configuration?.cluster?.smallPart &&
			!configuration?.part?.smallPart &&
			partSizeRow.length > 0 &&
			partSizeRow[0].data.iconName === CADAnalysisResult.notPrintable
		) {
			return [partSizeRow]
		}

		const rows = [
			partSizeRow,
			this.getWallThicknessFieldRow(
				dataTableService,
				wallThicknessIssue,
				isOrientation
			),
			this.getOverhangingFailRow(
				dataTableService,
				overhangingIssue,
				isOrientation
			),
			this.getTolerancesFailRow(
				dataTableService,
				unitSystem,
				tolerancesIssue,
				configuration?.part,
				solution,
				isOrientation
			),
			this.getHolesFailRow(dataTableService, holesIssue, isOrientation),
			this.getThreadsFailRow(
				dataTableService,
				threadsIssue,
				configuration,
				isOrientation
			),
			this.getInternalCavitiesFailRow(
				dataTableService,
				internalCavitiesIssue,
				isOrientation
			),
			this.getCadFailRow(dataTableService, CADIssue, isOrientation),
			this.getMaterialFailRow(
				solution,
				configuration,
				dataTableService,
				solutionWithError,
				isOrientation,
				materialPrintIssue
			),
			this.getPrintStabilityIssue(
				dataTableService,
				FeatureComponentId.CNC_ORIENTED_SUPPORT_REMOVAL,
				getString('PART_RESULTS_ORIENTATION_CNC'),
				orientedCNCIssue,
				orientedCNCIssue?.active,
				isOrientation
			),
			this.getPrintStabilityIssue(
				dataTableService,
				FeatureComponentId.ORIENTATION_STRUCTURAL_STABILITY,
				getString('PART_RESULTS_ORIENTATION_STABILITY'),
				printStabilityIssue,
				printStabilityIssue?.active,
				isOrientation
			),
			this.getCNCFailRow(dataTableService, CNCIssue, solution, isOrientation),
			this.getHeatDeformationIssue(
				dataTableService,
				heatDeformationIssue,
				solution,
				isOrientation
			)
		]

		const filteredRows = rows.filter(row => row && row.length)
		return sortPrintIssueTableRows(filteredRows, GA_TAB_SORTING_COLUMN_INDEX)
	}

	getAnalysisResultsRows = (
		solution: any,
		configuration: any,
		configurationPrintIssues: PartPrintIssue[],
		orientationVector: number[],
		isDrawing?: boolean,
		isMetaData?: boolean
	): any[][] => {
		const {
			user: { printIssues, userUnitSystem }
		} = store.getState()
		// we need material issue to know its order
		const materialPrintIssue = printIssues?.find(
			(issue: PrintIssue) => issue.id === PrintIssueId.Material
		)
		const dataTableService = new DataTableService()
		const isMaterialMetalType =
			solution?.printerMaterial?.type === materialTypes.metal
		const cncSupportRemovalFeatureOn = Feature.isFeatureOn(
			FeatureComponentId.CNC_SUPPORT_REMOVAL
		)
		const customizeUnitSystem = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
		)
		const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric
		const isCluster = !!configuration?.cluster

		let isSurfaceAreaOn =
			configuration.postProcessesOptional &&
			configuration.postProcessesOptional[
				OptionalPostProcessesIds.SurfaceAreaMachining.toString()
			]?.toggled
		if (isSurfaceAreaOn === null) {
			isSurfaceAreaOn = false
		}

		const showCNCIssue =
			isMaterialMetalType &&
			solution &&
			cncSupportRemovalFeatureOn &&
			isSurfaceAreaOn

		const showHeatDeformationIssue =
			isMaterialMetalType &&
			solution &&
			Feature.isFeatureOn(FeatureComponentId.HEAT_DEFORMATION)
		const {
			partSizeIssue,
			orientationSizeIssue,
			configurationSizeIssue,
			wallThicknessIssue,
			overhangingIssue,
			tolerancesIssue,
			holesIssue,
			CADIssue,
			internalCavitiesIssue,
			CNCIssue,
			printStabilityIssue,
			orientedCNCIssue,
			heatDeformationIssue,
			threadsIssue
		} = getIssues(configurationPrintIssues, orientationVector)
		const showCNCOrientedIssue =
			isMaterialMetalType &&
			solution?.printerTechnology?.machiningIncluded &&
			!solution?.printerTechnology?.needSurfaceAreaMachining &&
			Feature.isFeatureOn(FeatureComponentId.CNC_ORIENTED_SUPPORT_REMOVAL)
		const showInternalCavitiesIssue =
			Feature.isFeatureOn(FeatureComponentId.INTERNAL_CAVITY) &&
			internalCavitiesIssue?.message

		const sizeScore = getSizeScore(
			partSizeIssue,
			orientationSizeIssue,
			configurationSizeIssue
		)
		const threadsScore = getResultScore(threadsIssue)

		const analysisSizes = [
			sizeScore,
			getResultScore(printStabilityIssue),
			getResultScore(CADIssue),
			getResultScore(holesIssue),
			getResultScore(tolerancesIssue),
			getResultScore(overhangingIssue),
			getResultScore(wallThicknessIssue),
			getResultScore(internalCavitiesIssue),
			getResultScore(CNCIssue),
			getResultScore(orientedCNCIssue),
			getResultScore(heatDeformationIssue),
			threadsScore
		]

		const solutionWithError = checkResultsIssues(analysisSizes)

		let geometryAnalysisRows = [
			[
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.TextWithIcon,
					text: PART_RESULTS_SIZE,
					order:
						partSizeIssue?.printIssue.order ||
						// for cluster
						printIssues?.find(
							(issue: PrintIssue) => issue.id === PrintIssueId.Size
						)?.order,
					extraData: { printIssueId: partSizeIssue?.printIssue.id },
					iconName: getIconNameForScore(sizeScore)
				}),
				dataTableService.RenderFieldObject({
					type: DataTableFieldType.LongText,
					score: sizeScore,
					withAdditiveMind: true,
					text: getTextForPartSize(
						configuration?.quantity,
						unitSystem,
						partSizeIssue,
						orientationSizeIssue,
						configurationSizeIssue,
						configuration?.part || configuration?.cluster,
						solution
					)
				})
			]
		]

		if (
			!configuration?.cluster?.smallPart &&
			!configuration?.part?.smallPart &&
			geometryAnalysisRows[0][0].data.iconName ===
				CADAnalysisResult.notPrintable
		) {
			return geometryAnalysisRows
		}

		if (isDrawing || isMetaData) {
			geometryAnalysisRows = [
				...geometryAnalysisRows,
				solution && Feature.isFeatureOn(FeatureComponentId.TOLERANCES)
					? [
							dataTableService.RenderFieldObject({
								type: DataTableFieldType.TextWithIcon,
								text: getString('TOLERANCE'),
								order: tolerancesIssue?.printIssue.order,
								extraData: { printIssueId: tolerancesIssue?.printIssue.id },
								iconName: getIconNameForScore(
									tolerancesIssue?.score,
									tolerancesIssue?.active
								)
							}),
							dataTableService.RenderFieldObject({
								type: DataTableFieldType.LongText,
								text: getToleranceString(
									unitSystem,
									tolerancesIssue?.score,
									configuration?.part,
									solution
								),
								score: tolerancesIssue?.score,
								withAdditiveMind: true
							})
					  ]
					: [],
				isCluster && !configuration.failReason
					? []
					: [
							dataTableService.RenderFieldObject({
								type: DataTableFieldType.TextWithIcon,
								text: PART_RESULTS_MATERIAL,
								order: materialPrintIssue.order,
								extraData: { printIssueId: materialPrintIssue.id },
								iconName: getIconNameForScore(
									getMaterialScore(solution, configuration, solutionWithError)
								)
							}),
							dataTableService.RenderFieldObject({
								type: DataTableFieldType.LongText,
								text: configuration.failReason || '',
								score: getMaterialScore(
									solution,
									configuration,
									solutionWithError
								),
								withAdditiveMind: true,
								className:
									'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
							})
					  ],
				isMetaData
					? [
							dataTableService.RenderFieldObject({
								type: DataTableFieldType.TextWithIcon,
								text: getString('METADATA_ISSUE'),
								iconName: 'warning'
							}),
							dataTableService.RenderFieldObject({
								type: DataTableFieldType.LongText,
								withAdditiveMind: true,
								score: 0,
								text: getString('METADATA_ISSUE_DESCRIPTION')
							})
					  ]
					: [
							dataTableService.RenderFieldObject({
								type: DataTableFieldType.TextWithIcon,
								text: getString('DRAWING_ISSUE'),
								iconName: 'warning'
							}),
							dataTableService.RenderFieldObject({
								type: DataTableFieldType.LongText,
								withAdditiveMind: true,
								score: 0,
								text: getString('DRAWING_ISSUE_DESCRIPTION')
							})
					  ]
			]

			return sortPrintIssueTableRows(
				geometryAnalysisRows.filter(arr => arr.length),
				GA_TAB_SORTING_COLUMN_INDEX
			)
		}

		geometryAnalysisRows = [
			...geometryAnalysisRows,
			isCluster || !wallThicknessIssue?.message
				? []
				: [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: PART_RESULTS_WALL_THICKNESS,
							order: wallThicknessIssue?.printIssue.order,
							extraData: { printIssueId: wallThicknessIssue?.printIssue.id },
							iconName: getIconNameForScore(
								wallThicknessIssue?.score,
								wallThicknessIssue?.active
							)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: wallThicknessIssue?.message || '',
							score: wallThicknessIssue?.score,
							withAdditiveMind: true
						})
				  ],
			Feature.isFeatureOn(FeatureComponentId.OVERHANGING) &&
			solution?.printer?.hasSupportIssues &&
			!isCluster &&
			overhangingIssue?.message
				? [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: getString('OVERHANGING'),
							extraData: { printIssueId: overhangingIssue?.printIssue.id },
							order: overhangingIssue?.printIssue.order,
							iconName: getIconNameForScore(
								overhangingIssue?.score,
								overhangingIssue?.active
							)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: overhangingIssue?.message || '',
							score: overhangingIssue?.score,
							withAdditiveMind: true
						})
				  ]
				: [],
			!isCluster &&
			Feature.isFeatureOn(FeatureComponentId.TOLERANCES) &&
			tolerancesIssue
				? [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: getString('TOLERANCE'),
							order: tolerancesIssue?.printIssue.order,
							extraData: { printIssueId: tolerancesIssue?.printIssue.id },
							iconName: getIconNameForScore(
								tolerancesIssue?.score,
								tolerancesIssue?.active
							)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: getToleranceString(
								unitSystem,
								tolerancesIssue?.score,
								configuration?.part,
								solution
							),
							score: tolerancesIssue?.score,
							withAdditiveMind: true
						})
				  ]
				: [],
			!isCluster && Feature.isFeatureOn(FeatureComponentId.HOLES_ANALYSIS)
				? [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: getString('HOLES'),
							order: holesIssue?.printIssue.order,
							extraData: { printIssueId: holesIssue?.printIssue.id },
							iconName: getIconNameForScore(
								holesIssue?.score,
								holesIssue?.active
							)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: holesIssue?.message || '',
							score: holesIssue?.score,
							withAdditiveMind: true
						})
				  ]
				: [],
			Feature.isFeatureOn(FeatureComponentId.THREAD_DETECTION) && !isCluster
				? [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: THREADS,
							order: threadsIssue?.printIssue.order,
							extraData: { printIssueId: threadsIssue?.printIssue.id },
							iconName: getIconNameForScore(threadsScore, threadsIssue?.active)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text:
								threadsIssue?.message ||
								getTextForPartThreads(
									configuration?.part || configuration?.cluster
								),
							score: threadsScore,
							withAdditiveMind: true
						})
				  ]
				: [],
			!isCluster &&
			showInternalCavitiesIssue &&
			configuration.resultType !== ConfigurationResultTypes.WeightReduction
				? [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: getString('INTERNAL_CAVITIES'),
							order: internalCavitiesIssue?.printIssue.order,
							extraData: { printIssueId: internalCavitiesIssue?.printIssue.id },
							iconName: getIconNameForScore(
								internalCavitiesIssue?.score,
								internalCavitiesIssue?.active
							)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: internalCavitiesIssue?.message,
							className:
								'solution-orientation--data-table--row--text' +
								' data-table-long-text-field-without-read-more',
							score: internalCavitiesIssue?.score,
							withAdditiveMind: true
						})
				  ]
				: [],
			isCluster ||
			(!CADIssue?.message && CADIssue?.score !== printStatusScore.passed) ||
			!Feature.isFeatureOn(FeatureComponentId.CAD_FILE_INTACT)
				? []
				: [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: PART_RESULTS_CAD,
							order: CADIssue?.printIssue.order,
							extraData: { printIssueId: CADIssue?.printIssue.id },
							iconName: getIconNameForScore(CADIssue?.score, CADIssue?.active)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: CADIssue?.message || getString('MESH_HEALING_COMPLETED'),
							score: CADIssue?.score,
							withAdditiveMind: true,
							className: 'data-table-long-text-field-without-read-more'
						})
				  ],
			!configuration.failReason
				? []
				: [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: PART_RESULTS_MATERIAL,
							order: materialPrintIssue.order,
							extraData: { printIssueId: materialPrintIssue.id },
							iconName: getIconNameForScore(
								getMaterialScore(solution, configuration, solutionWithError)
							)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: configuration.failReason || '',
							score: getMaterialScore(
								solution,
								configuration,
								solutionWithError
							),
							withAdditiveMind: true,
							className:
								'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
						})
				  ],
			!isCluster && showCNCOrientedIssue
				? [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: getString('PART_RESULTS_ORIENTATION_CNC'),
							order: orientedCNCIssue?.printIssue.order,
							extraData: { printIssueId: orientedCNCIssue?.printIssue.id },
							iconName: getIconNameForScore(
								orientedCNCIssue?.score,
								orientedCNCIssue?.active
							)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: getCNCOrientedIssueString(orientedCNCIssue),
							score: orientedCNCIssue?.score,
							withAdditiveMind: true,
							className:
								'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
						})
				  ]
				: [],
			!isCluster && showCNCIssue
				? [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: getString('PART_RESULTS_CNC'),
							order: CNCIssue?.printIssue.order,
							extraData: { printIssueId: CNCIssue?.printIssue.id },
							iconName: getIconNameForScore(CNCIssue?.score, CNCIssue?.active)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: `${
								CNCIssue?.message ||
								getString('CNC_SUPPORT_REMOVAL_SUCCESS_MSG')
							}${getCNCEnableRatio(
								configuration?.part,
								configuration,
								CNCIssue
							)}`,
							score: CNCIssue?.score,
							withAdditiveMind: true
						})
				  ]
				: [],
			!isCluster &&
			showHeatDeformationIssue &&
			configuration.resultType !== ConfigurationResultTypes.WeightReduction
				? [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: getString('PART_RESULTS_HEAT_DEFORMATION'),
							order: heatDeformationIssue?.printIssue.order,
							extraData: { printIssueId: heatDeformationIssue?.printIssue.id },
							iconName: getIconNameForScore(
								heatDeformationIssue?.score,
								heatDeformationIssue?.active
							)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text:
								heatDeformationIssue?.message ||
								getString('PART_RESULTS_HEAT_DEFORMATION_SUCCESS'),
							score: heatDeformationIssue?.score,
							withAdditiveMind: true
						})
				  ]
				: [],
			!isCluster && solution?.printerTechnology?.hasStabilityIssues
				? [
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.TextWithIcon,
							text: getString('PART_RESULTS_ORIENTATION_STABILITY'),
							order: printStabilityIssue?.printIssue.order,
							extraData: { printIssueId: printStabilityIssue?.printIssue.id },
							iconName: getIconNameForScore(
								printStabilityIssue?.score,
								printStabilityIssue?.active
							)
						}),
						dataTableService.RenderFieldObject({
							type: DataTableFieldType.LongText,
							text: getOrientationStabilityString(printStabilityIssue),
							score: printStabilityIssue?.score,
							withAdditiveMind: true
						})
				  ]
				: []
		]
		return sortPrintIssueTableRows(
			geometryAnalysisRows.filter(arr => arr.length),
			GA_TAB_SORTING_COLUMN_INDEX
		)
	}

	getFailedPrintIssues = (analysisResultsRows: IDataTableField[][]) => {
		if (analysisResultsRows.length === 0) return []
		const failedPrintIssuesIds = analysisResultsRows.reduce((acc, row) => {
			if (row[0]?.data.iconName === CADAnalysisResult.notPrintable) {
				acc.push(row[0].data.printIssueId)
			}
			return acc
		}, [])
		return failedPrintIssuesIds
	}

	updateWallThickness = async (
		customWallThickness: number,
		configuration: any,
		configurations: any[],
		updateOrientation?: boolean
	) => {
		this.configuration = configuration
		const wallThicknessUpdated = await updateWallThickness(
			customWallThickness,
			configuration.id,
			updateOrientation
		)
		if (wallThicknessUpdated?.status !== 200) {
			throw new Error(WALL_THICKNESS_UPDATE_ERROR)
		}

		const { partStatus } = wallThicknessUpdated?.data || {}
		let pollerPerformed = true
		let res = await this.startWTConditionPoller(
			partStatus,
			configuration.id,
			configurations,
			configuration.part.id
		)
		if (!res || !res.data) {
			pollerPerformed = false
			res = wallThicknessUpdated
		}

		res.data.pollerPerformed = pollerPerformed

		this.result = res.data.result
		return res.data
	}

	startConditionPoller = async (
		configuration: any,
		id: number,
		configurations: any[],
		dispatch?: any,
		restartNeeded?: boolean
	) => {
		let counter = 1
		let response: any = null
		this.configuration = configuration

		while (
			configuration.part &&
			configuration.part.status === PartStatus.awaitingCombinedHeatmap
		) {
			const pathArray = window.location.pathname.split('/')
			const partIdIndex = pathArray.indexOf('part') + 1
			const pathPartId = Number(pathArray[partIdIndex])
			if (pathPartId != configuration.part.id) {
				response = null
				break
			}
			if (counter === POLLER_COUNTER_CHANGE_UI_TIMEOUT) {
				this.dispatchUpdatingPartUI(configurations, id, true)
			}
			await timeout(counter * POLLER_DELAY)
			response = await getConfiguration(id)
			configuration = response.data.configuration
			counter++
		}

		if (restartNeeded) {
			this.handleConfigurationsPollerResponseToRestart(response, dispatch)
		}

		return response
	}

	handleConfigurationsPollerResponseToRestart = (res: any, dispatch: any) => {
		if (res?.data) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('ANALYSIS_FINISHED_TEXT'),
					headerTitle: getString('ANALYSIS_FINISHED'),
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						window.location.reload()
					},
					onCancel: () => {
						window.location.reload()
					},
					confirmText: OK,
					showCancel: false
				}
			})
		}
	}

	checkTaryOrientationPollerResponseStatus = (response: any) => {
		const part = response.data.part
		if (
			!part.trayOrientations ||
			!part.trayOrientations.hasOwnProperty('status')
		) {
			return true
		}
		return (
			part.trayOrientations.status === 'awaitingTrayOrientation' ||
			part.trayOrientations.status === 'failed'
		)
	}
	/**
	 * @param cb1 {Function} callback function for sending poller http request
	 * @param cb2 {Function} callback function for checking response status
	 *   message
	 * @param cb1Args {string} args for cb1 function
	 */
	conditionPollerFactory = async (
		cb1: Function,
		cb2: Function,
		cb1Args: Array<any>
	) => {
		const poller = async () => {
			let counter = 1
			let response: any = null
			let keepPollerRunning: boolean = true
			while (keepPollerRunning) {
				if (counter === POLLER_COUNTER_WARNING_TIMEOUT) {
					this.dispatchWarning(CONFIGURATION_CALCULATION_LONG_TIME)
				} else if (counter === POLLER_COUNTER_TIMEOUT) {
					console.error(`error in ${cb1.name} - too many calls`)
					throw new Error(CONFIGURATION_CALCULATION_ERROR)
				}
				await timeout(counter * POLLER_DELAY)
				response = await cb1.apply(this, cb1Args)
				keepPollerRunning = cb2(response)
				counter++
			}
			return response
		}
		return poller()
	}

	startTrayOrientationPoller = async (
		partId: number | null,
		partSolutionId: number,
		batchSize: number,
		updatedPostProcesses: any
	) => {
		return await this.conditionPollerFactory(
			getPrintingOrientation,
			this.checkTaryOrientationPollerResponseStatus,
			[partId, partSolutionId, batchSize, updatedPostProcesses]
		)
	}
	startWTConditionPoller = async (
		status: string,
		id: number,
		configurations: any[],
		partId: number
	) => {
		let counter = 1
		let response: any = null
		while (status === PartStatus.awaitingCombinedHeatmap) {
			const pathArray = window.location.pathname.split('/')
			const pathPartId = Number(pathArray[pathArray.length - 1])
			if (pathPartId != partId && pathPartId != id) {
				response = null
				break
			}
			if (counter === POLLER_COUNTER_CHANGE_UI_TIMEOUT) {
				this.dispatchUpdatingPartUI(configurations, id, true)
			}
			await timeout(counter * POLLER_DELAY)
			response = await getWallThickness(id)
			status = response.data.partStatus
			counter++
		}
		return response
	}
	getChosenOrientationVector = (
		orientationsData: Array<OrientationData>,
		configurationVector: Array<number>
	) => {
		const chosenOrientation =
			orientationsData.find(
				(orientation: OrientationData) =>
					JSON.stringify(orientation.trayNormalVector) ===
					JSON.stringify(configurationVector)
			) || null
		if (!chosenOrientation) {
			return '1'
		}
		return chosenOrientation.name
	}
	getChosenOrientation = (
		orientationsData: Array<OrientationData>,
		configurationVector: Array<number>
	) => {
		let chosenOrientation: OrientationData | null = null
		chosenOrientation =
			orientationsData.find(
				orientation =>
					JSON.stringify(orientation.trayNormalVector) ===
					JSON.stringify(configurationVector)
			) || null
		return chosenOrientation
	}
	getSolutionOrientationVector = (configuration: any, solution: any) => {
		const configurationOrientationVector =
			configuration?.trayOrientation?.trayNormalVector
		const solutionOrientationVector = solution?.trayOrientationVector
		let trayOrientationVector: Array<number> =
			configurationOrientationVector || solutionOrientationVector

		return trayOrientationVector || []
	}
	isOrientationCustom = (configuration: any, solution: any) => {
		let orientationVector = this.getSolutionOrientationVector(
			configuration,
			solution
		)
		const ZIndex = orientationVector.indexOf(1)
		return ZIndex !== 2
	}

	dispatchUpdatingPartUI = (
		configurations: any[],
		currentConfigurationId: number,
		updating?: boolean
	) => {
		updating && this.dispatchWarning(CONFIGURATION_CALCULATION_LONG_TIME)
		for (const configuration of configurations) {
			const updateConfiguration =
				updating ||
				(configuration.id > 0 && configuration.id !== currentConfigurationId)
			if (updateConfiguration) {
				this.configuration = configuration
				this.result = configuration.result
				const colors = this.getSolutionColor(updating)
				const configurationResult = this.getSolutionResult(updating)
				const resultBody = this.getSolutionResultBody(updating)
				store.dispatch({
					type: SOLUTION_ANALYSIS_PART_DISABLED,
					payload: {
						id: configuration.id,
						...colors,
						configurationResult,
						resultBody,
						pollerStarted: !!updating
					}
				})
			}
		}
		store.dispatch({
			type: MAIN_PART_ANALYSIS_DISABLE_PART_TOGGLED,
			payload: {
				updating: !!updating
			}
		})
	}

	dispatchWarning = (notificationMessage: string) => {
		store.dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.WARN,
				notificationMessage,
				notificationTime: 10000
			}
		})
	}

	didConfigurationSolutionChanged = (
		oldConfiguration: any,
		newConfiguration: any
	) => {
		const newPrinterId = newConfiguration?.printer?.id
		const oldPrinterId = oldConfiguration?.printer?.id
		const newPrinterMaterialId = oldConfiguration?.printerMaterial?.id
		const oldPrinterMaterialId = oldConfiguration?.printerMaterial?.id
		const printerMaterialChanged = newPrinterMaterialId !== oldPrinterMaterialId
		const printerChanged = newPrinterId !== oldPrinterId
		return printerMaterialChanged || printerChanged
	}
	generateAlternativeSolutionsPrintersList = (
		alternativeSolutions: Array<any>,
		selectedSolution: any
	): Array<IAlternativePrinters> | [] => {
		let alternativePrintersList: any = []
		let chosenPrinter = null
		const solutions = alternativeSolutions && alternativeSolutions.length
		if (solutions) {
			alternativePrintersList = alternativeSolutions.map(
				(solutionItem: any) => {
					return {
						name: solutionItem?.printer?.name,
						astmIsoName: solutionItem?.printer?.astmIsoName || '',
						id: solutionItem?.id,
						cost: solutionItem?.costDetails?.totalCost || 0,
						materialName: solutionItem?.printerMaterial?.name || '',
						company: solutionItem?.printer?.company || '',
						description: solutionItem?.printer?.description || '',
						technology: solutionItem?.printerTechnology?.userReadableName || '',
						organizationId: solutionItem?.printer?.organizationId || null
					}
				}
			)
		}
		if (selectedSolution) {
			const chosenSolutionPrinter =
				selectedSolution && selectedSolution?.printer
			chosenPrinter = {
				name: chosenSolutionPrinter?.name,
				astmIsoName: selectedSolution?.printerTechnology?.astmIsoName || '',
				id: selectedSolution.id,
				cost: selectedSolution.costDetails?.totalCost,
				materialName: selectedSolution?.printerMaterial?.name || '',
				company: selectedSolution?.printer?.company || '',
				description: selectedSolution?.printer?.description || '',
				technology: selectedSolution?.printerTechnology?.userReadableName || '',
				organizationId: chosenSolutionPrinter?.organizationId || null
			}
		}
		return [chosenPrinter, ...alternativePrintersList]
	}
	getManufacturingMethod = (configuration: any): ImanufacturingTypes => {
		return (
			configuration.manufactureMethod ||
			(configuration.material?.type === materialTypes.plastic
				? ImanufacturingTypes.mold
				: ImanufacturingTypes.cnc)
		)
	}

	getSimpleConfigurationData = (
		configuration: any,
		simpleConfigurationSelectorService: SimpleConfigurationSelectorService
	) => {
		const fullDataCompany =
			simpleConfigurationSelectorService.getPrinterMaterialFullDataByMaterialID(
				configuration.filters?.printerMaterialID
			)
		const printersCompaniesList: Array<string> =
			simpleConfigurationSelectorService.getCompaniesNamesList()
		let simpleConfiguration: boolean =
			configuration.type === SolutionConfigurationTypes.simple ||
			configuration.type === SolutionConfigurationTypes.custom
		let simpleConfigurationMaterialsList: Array<ISimpleConfigurationMaterial> =
			[]
		let simpleConfigurationSelectorMaterialValue:
			| ISimpleConfigurationMaterial
			| string = ''
		let simpleConfigurationPrinters: Array<ISimpleConfigurationPrinter> = []
		let simpleConfigurationSelectorCompanyValue: string = ''
		let simpleConfigurationSelectorPrinterValue:
			| ISimpleConfigurationPrinter
			| string = ''
		if (
			simpleConfiguration &&
			(configuration.printer || fullDataCompany?.printers[0]) &&
			(configuration.printerMaterial ||
				fullDataCompany?.printers[0]?.materials[0])
		) {
			simpleConfigurationSelectorCompanyValue =
				configuration.printer?.company || fullDataCompany?.companyName
			simpleConfigurationPrinters =
				simpleConfigurationSelectorService.getPrintersNamesList(
					simpleConfigurationSelectorCompanyValue
				)
			simpleConfigurationSelectorPrinterValue =
				simpleConfigurationPrinters.find(
					simpleConfigurationPrinter =>
						simpleConfigurationPrinter.printerId ===
						(configuration.printer?.id ||
							fullDataCompany?.printers[0]?.printerId)
				) || ''
			simpleConfigurationMaterialsList =
				simpleConfigurationSelectorService.getPrinterMaterialsList(
					simpleConfigurationSelectorPrinterValue
				)
			simpleConfigurationSelectorMaterialValue =
				simpleConfigurationMaterialsList.find(
					simpleConfigurationMaterial =>
						simpleConfigurationMaterial.id ===
						(configuration.printerMaterial?.id ||
							fullDataCompany?.printers[0]?.materials[0]?.id)
				) || ''
		}
		return {
			printersCompaniesList,
			simpleConfiguration,
			simpleConfigurationMaterialsList,
			simpleConfigurationSelectorMaterialValue,
			simpleConfigurationPrinters,
			simpleConfigurationSelectorCompanyValue,
			simpleConfigurationSelectorPrinterValue,
			simpleInhouseConfiguration:
				configuration?.filters?.inHousePrinters || false
		}
	}

	getFeaData = (
		solution: any,
		solutionFea: ISolutionFea,
		feaAnalysisResults?: FeaAnalysisResults
	) => {
		if (
			solution?.id > 0 &&
			solution?.printerMaterial &&
			!isEmpty(solution?.printerMaterial)
		) {
			const showFeaAnalysisOldAnalysis: boolean =
				!!solutionFea?.feaAnalysisResults
			const solutionPrinterMaterial: PrinterMaterial = solution.printerMaterial
			if (solutionFea && feaAnalysisResults) {
				solutionFea.feaAnalysisResults = { ...feaAnalysisResults }
			}

			let solutionFeaStrengthTitle = getString(
				'MECHANICAL_ANALYSIS_ALERT_STRENGTH_TITLE_PLASTIC'
			)
			let solutionFeaUserInputTitle = getString(
				'MECHANICAL_ANALYSIS_ALERT_USER_INPUT_PLASTIC'
			)
			let solutionFeaStrength =
				solutionPrinterMaterial.ultimateTensileStrengthExt.X.val
			let solutionFeaSDStrength = 0
			let solutionFeaSliderUnits = '%'
			let solutionFeaSliderMarks: Record<string, string> = {
				0: '0%',
				25: '25%',
				50: '50%',
				75: '75%'
			}
			let solutionFeaSliderMaxValue = 75
			let solutionFeaSliderMinValue = 0
			let solutionFeaSliderValue =
				(solutionFea?.feaAnalysisResults?.userInputs &&
					solutionFea.feaAnalysisResults.userInputs[0]) ||
				20
			let solutionFeaSliderStartValue = 0
			let solutionFeaSliderIsRange = false
			const solutionFeaResult =
				solutionFea?.feaAnalysisResults?.maxVonMisses || 0
			if (solutionPrinterMaterial.type === materialTypes.metal) {
				solutionFeaStrengthTitle = getString(
					'MECHANICAL_ANALYSIS_ALERT_STRENGTH_TITLE_METAL'
				)
				solutionFeaUserInputTitle = getString(
					'MECHANICAL_ANALYSIS_ALERT_USER_INPUT_METAL'
				)
				solutionFeaStrength = solutionPrinterMaterial.yieldStrengthMPaExt.X.val
				solutionFeaSDStrength =
					solutionPrinterMaterial.yieldStrengthMPaExt.X.stdev
				solutionFeaSliderUnits = 'σ'
				solutionFeaSliderMarks = {
					1: '1σ',
					2: '2σ',
					3: '3σ',
					4: '4σ',
					5: '5σ',
					6: '6σ',
					7: '7σ'
				}
				solutionFeaSliderMinValue = 1
				solutionFeaSliderMaxValue = 7
				solutionFeaSliderValue =
					(solutionFea?.feaAnalysisResults?.userInputs &&
						solutionFea.feaAnalysisResults.userInputs.filter(
							userInput => userInput !== 2
						)[0]) ||
					6
				solutionFeaSliderStartValue = 2
				solutionFeaSliderIsRange = true
			}
			const solutionFeaSelectedValues = getUserFeaValues(
				solutionFea?.feaAnalysisResults?.userInputs,
				solutionFeaStrength,
				solutionFeaSDStrength,
				!solutionFeaSliderIsRange
			)
			return {
				solutionFeaStrengthTitle: solutionFeaStrengthTitle.format(
					solutionPrinterMaterial.name.trimEnd()
				),
				solutionFeaStrength,
				solutionFeaSDStrength,
				solutionFeaUserInputTitle,
				solutionFeaSliderUnits,
				solutionFeaSliderMarks,
				solutionFeaSliderMaxValue,
				solutionFeaSliderValue,
				solutionFeaSliderStartValue,
				solutionFeaSliderIsRange,
				solutionFeaSliderMinValue,
				solutionFeaResult,
				solutionFeaSelectedValues,
				feaAnalysisResultsId: feaAnalysisResults?.id || '',
				showFeaAnalysisOldAnalysis
			}
		}
		return {}
	}
	turnOnChainBenefit = (
		ChainBenefitsName: ChainBenefitsNames,
		chainBenefits: IChainBenefits
	) => {
		if (!ChainBenefitsName || !chainBenefits) {
			return chainBenefits
		}
		if (!chainBenefits[ChainBenefitsName]) {
			return chainBenefits
		}
		chainBenefits[ChainBenefitsName].on = true
		return chainBenefits
	}
	updateConfigurations = async (
		configurations: any,
		dispatch: any,
		partPrintIssues: PartPrintIssue[],
		solutions?: any,
		doNotCalculateCostData: boolean = false
	) => {
		for (const configuration of configurations) {
			let solutionWithBenefits
			if (solutions) {
				solutionWithBenefits = solutions.find(
					(solution: any) => solution.id === configuration.solution?.id
				)
			} else {
				solutionWithBenefits = configuration.solution
			}
			await this.updateSolution(
				configuration,
				solutionWithBenefits,
				dispatch,
				getManufacturingMethodName(
					configuration.manufactureMethod,
					configuration.material.type
				),
				partPrintIssues,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				doNotCalculateCostData
			)
		}
	}
}

export const findEffectivePoints = (
	configuration: IConfiguration | any,
	costResultName: string = 'costResults',
	_drawingCostPercentage?: number
) => {
	let effectiveQuantity = null

	let drawingCostPercentage = _drawingCostPercentage
	if (!drawingCostPercentage) {
		const state = store.getState()
		drawingCostPercentage =
			state.user?.userCustomizationSettings?.drawingCostPercentage
	}
	if (
		(configuration && configuration[costResultName]?.results) ||
		(configuration && configuration[costResultName]?.comparedResults)
	) {
		const { labels, molding, amResult } = getResultsNumbersAndQuantity(
			configuration[costResultName]?.results ||
				(configuration && configuration[costResultName]?.comparedResults),
			configuration[costResultName]?.AMResults ||
				(configuration && configuration[costResultName]?.mainResults),
			configuration[costResultName]?.AMMinResults ||
				(configuration && configuration[costResultName]?.mainMinResults),
			configuration[costResultName]?.AMMaxResults ||
				(configuration && configuration[costResultName]?.mainMaxResults),
			drawingCostPercentage
		)
		const { filteredLabels, filteredMolding } = removingDuplicates(
			labels,
			molding,
			amResult,
			configuration?.quantityOfCost ||
				configuration[costResultName]?.quantityOfCost
		)
		const { printCostPointIndex } = getCrossValue(
			filteredMolding,
			labels,
			configuration[costResultName]?.quantityOfCost ||
				configuration?.quantityOfCost
		)

		effectiveQuantity = filteredLabels[printCostPointIndex]
	}

	return {
		effectiveQuantity
	}
}

export const getManufacturingMethodName = (
	manufacturingMethod: ImanufacturingTypes,
	materialType: string
): string => {
	switch (manufacturingMethod) {
		case ImanufacturingTypes.mold:
			return manufacturingMethodTypes.mold

		case ImanufacturingTypes.cnc:
			return manufacturingMethodTypes.cnc

		case ImanufacturingTypes.standardCost:
			return manufacturingMethodTypes.standardCost

		default:
			return materialType === materialTypes?.plastic
				? manufacturingMethodTypes.mold
				: manufacturingMethodTypes.cnc
	}
}
const getCNCEnableRatio = (part: Part, configuration: any, CNCIssue: any) => {
	if (CNCIssue?.score < printStatusScore.passed) {
		return ''
	}
	if (
		!Feature.isFeatureOn(FeatureComponentId.CNC_SUPPORT_REMOVAL_RESULTS_DISPLAY)
	) {
		return ''
	}
	const partAreaSurface = part?.area
	const cncPrintableArea = configuration?.cncPrintingData?.printableArea

	if (cncPrintableArea === -1 || !cncPrintableArea) {
		return `, no CNC removal data`
	}
	const CNCEnableInPercentage = (cncPrintableArea / partAreaSurface) * 100
	return ` ${CNCEnableInPercentage.toFixed(2)}% of supports can be removed.`
}

// Compare 2 filter objects and find differences
export const filterDifference = (object: any, base: any) => {
	const changes = (object: any, base: any) =>
		transform(object, (result: any, value, key) => {
			if (!isEqual(value, base[key])) {
				result[key] =
					isObject(value) && isObject(base[key])
						? changes(value, base[key])
						: value
			}
		})

	return changes(object, base)
}

export const getIntegrationProviders = (
	providers: IUserProvider[],
	printerId: string,
	materialId: string,
	isWeightReductionConfiguration: boolean,
	onPrintingOptionClick: Function,
	on3dExportClick: Function
): { name: string; onClick: (e: any) => any }[] | null => {
	const is3DExpertButton = Feature.isFeatureActive(
		FeatureComponentId.THREED_EXPERT_INTEGRATION_BUTTON
	)
	const providerPrinterOptions: { name: string; onClick: (e: any) => any }[] =
		[]
	for (const provider of providers) {
		if (
			provider.showAlways &&
			is3DExpertButton &&
			!isWeightReductionConfiguration
		) {
			if (provider.auth.guid === ProviderGuid.Oqton) {
				providerPrinterOptions.push({
					name: getString('3Dexpert'),
					onClick: (e: any) => on3dExportClick(e, provider, printerId)
				})
				continue
			}
			providerPrinterOptions.push({
				name: provider.auth.name,
				onClick: (e: any) => onPrintingOptionClick(e, provider, printerId)
			})
			continue
		}
		provider.printers?.forEach((providerPrinter: IProviderPrinter) => {
			if (
				providerPrinter.id === printerId &&
				providerPrinter.materials.includes(materialId)
			) {
				providerPrinterOptions.push({
					name: provider.auth.name,
					onClick: (e: any) =>
						onPrintingOptionClick(e, provider, providerPrinter.id)
				})
			}
		})
	}

	//remove if we have an empty first element
	if (providerPrinterOptions?.length === 1 && !providerPrinterOptions[0]?.name)
		return null

	return providerPrinterOptions
}

// Get Issues
export const getIssues = (
	configurationPrintIssues: PartPrintIssue[],
	orientationVector: number[]
) => {
	const partSizeIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.Size,
		isPartLevel: true
	})
	const orientationSizeIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.Size,
		vector: orientationVector
	})
	const configurationSizeIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.Size
	})
	const wallThicknessIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.WallThickness
	})
	const overhangingIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.Overhanging,
		isPartLevel: false,
		vector: orientationVector
	})
	const tolerancesIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.Tolerance
	})
	const holesIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.Holes
	})
	const CADIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.CAD,
		isPartLevel: true
	})
	const CNCIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.CNC,
		isPartLevel: false
	})
	const printStabilityIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.OrientationStability,
		isPartLevel: false,
		vector: orientationVector
	})
	const orientedCNCIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.OrientedCNC,
		isPartLevel: false,
		vector: orientationVector
	})

	const internalCavitiesIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.InternalCavities,
		isPartLevel: false
	})

	const configurationSizeVectorIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.Size,
		vector: orientationVector
	})
	const heatDeformationOrientationIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.HeatDeformation,
		vector: orientationVector
	})
	const heatDeformationConfigurationIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.HeatDeformation
	})
	const heatDeformationPartIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.HeatDeformation,
		isPartLevel: true
	})
	const threadsIssue = getIssue({
		printIssuesList: configurationPrintIssues,
		printIssueId: PrintIssueId.Threads,
		isPartLevel: false
	})

	const heatDeformationIssue = getHeatDeformationIssue(
		heatDeformationOrientationIssue,
		heatDeformationConfigurationIssue,
		heatDeformationPartIssue
	)

	return {
		partSizeIssue,
		orientationSizeIssue,
		configurationSizeIssue,
		wallThicknessIssue,
		overhangingIssue,
		tolerancesIssue,
		holesIssue,
		CADIssue,
		CNCIssue,
		internalCavitiesIssue,
		printStabilityIssue,
		configurationSizeVectorIssue,
		orientedCNCIssue,
		heatDeformationIssue,
		threadsIssue
	}
}

export const sortPrintIssueTableRows = (
	printIssueTableRows: IDataTableField[][],
	indexOfColumnToSortBy: number
): IDataTableField[][] => {
	if (printIssueTableRows.length === 0) return []
	return printIssueTableRows.sort((a, b) => {
		const aIsUnprintable =
			a[indexOfColumnToSortBy]?.data?.iconName ===
			CADAnalysisResult.notPrintable
		const bIsUnprintable =
			b[indexOfColumnToSortBy]?.data?.iconName ===
			CADAnalysisResult.notPrintable
		if (
			(aIsUnprintable && !bIsUnprintable) ||
			isNil(b[indexOfColumnToSortBy]?.order)
		) {
			return -1 // a comes before b
		} else if (
			(!aIsUnprintable && bIsUnprintable) ||
			isNil(a[indexOfColumnToSortBy]?.order)
		) {
			return 1 // a comes after b
		} else {
			return (
				(a[indexOfColumnToSortBy]?.order || 0) -
				(b[indexOfColumnToSortBy]?.order || 0)
			)
		}
	})
}

export const getPostProcessIdsWithAbnormalValues = (solution: any) => {
	let postProcessesIds: string[] = []
	if (solution) {
		const postProcessesIdsWithAbnormalPrice =
			(!isEmpty(solution?.postProcessAbnormalPrice) &&
				Object.keys(solution.postProcessAbnormalPrice)?.filter(
					key => solution.postProcessAbnormalPrice[key]
				)) ||
			[]
		const postProcessesIdsWithAbnormalLeadTime =
			(!isEmpty(solution?.postProcessAbnormalPrice) &&
				Object.keys(solution.postProcessAbnormalLeadTime)?.filter(
					key => solution.postProcessAbnormalLeadTime[key]
				)) ||
			[]
		postProcessesIds = [
			...postProcessesIdsWithAbnormalPrice,
			...postProcessesIdsWithAbnormalLeadTime
		]
	}
	return postProcessesIds
}

export const getUsedOrganization = (
	configuration: Configuration,
	part: Part
) => {
	const allOrganizations: IOrganization[] =
		store.getState().user.allOrganizations || []

	const organizationId =
		configuration.organizationId ||
		part.organizationId ||
		part.project?.organizationId ||
		part.owner?.organizationId

	return allOrganizations.find(
		organization => organization.id === organizationId
	)
}

export const makePartImageGallery = (
	part: Part | null,
	configuration: IConfiguration,
	isCastorTwo: boolean
) => {
	let gallery: Array<Record<string, string>> = []
	const isClusterConfig =
		isCastorTwo &&
		configuration?.resultType === ConfigurationResultTypes.PartConsolidation &&
		!!part

	if (isClusterConfig) {
		gallery = [
			{
				name: getString('PART_CONSOLIDATION_SUGGESTION'),
				assemblyImage: configuration?.cluster?.imageURL
			},
			{
				name: getString('ORIGINAL_PART'),
				assemblyImage: part?.imageURL
			}
		]
	}

	if (!isClusterConfig && part?.latheAnalysisData) {
		gallery = [
			{
				name: '',
				assemblyImage: part?.imageURL || ''
			},
			{
				name: getString('PART_GEOMETRY_AFTER_TURNING'),
				assemblyImage: part?.latheAnalysisData?.imageURL
			}
		]
	}

	return gallery
}

export const checkMinMaxLayerThickness = (
	initialValue: number,
	updatedValue: number | null
) => {
	return (
		updatedValue &&
		updatedValue <= initialValue * maxMultiplier &&
		updatedValue >= initialValue / maxMultiplier &&
		updatedValue > 0 &&
		updatedValue < MAX_LAYER_THICKNESS
	)
}
