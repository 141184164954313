import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as CustomizeInHousePrintersActions from './CustomizeInHousePrintersActions'
import {
	CUSTOMIZE_FORM_IN_HOUSE_PRINTERS_HEADER,
	CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY,
	CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE,
	NAV_TITLE_CUSTOMIZE_USER
} from '../../../../Services/Strings'
import { getThemeString } from '../../../../themes/getThemeString'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { goBackToRelatedCastor2 } from '../CustomizeFormCommon'
import OrganizationPanel from '../OrganizationPanel/OrganizationPanel'
import CustomizeInHousePrintersTable from './CustomizeInHousePrintersTable'
import InHouseMaterialOtherSelector from './InHouseMaterialOtherSelector'
import InHousePrintersOnlyCheckbox from './InHousePrintersOnlyCheckbox'
import InHousePrintersOtherSelector from './InHousePrintersOtherSelector'
import InHousePrintersSelector from './InHousePrintersSelector'
import {
	ADDITIVE_MANUFACTURING_SETTINGS_ROUTE,
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'

class CustomizeInHousePrinters extends Component {
	componentDidMount = () => {
		const {
			setupInHousePrinters,
			printersCompanies,
			match,
			adminSelectedOrganizationId
		} = this.props

		setupInHousePrinters(printersCompanies, match, adminSelectedOrganizationId)
	}

	renderFormHeader = () => {
		return (
			<div className="customize-settings--header">
				<CastorFormHeader
					goBack={() => this.props.history.push(goBackToRelatedCastor2())}
					explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
					explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
				/>
				<OrganizationPanel />
			</div>
		)
	}

	setInHouseContent = () => {
		return (
			<div>
				<CastorFormHeader
					explanationHeader={CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE}
					explanationArray={CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY}
					isInCard={true}
				/>
				<InHousePrintersOnlyCheckbox />
				<InHousePrintersSelector />
				<InHousePrintersOtherSelector />
				<InHouseMaterialOtherSelector />
				<CustomizeInHousePrintersTable />
			</div>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={NAV_TITLE_CUSTOMIZE_USER}>
				<CastorForm
					formHeader={this.renderFormHeader()}
					formTitle={CUSTOMIZE_FORM_IN_HOUSE_PRINTERS_HEADER}
					content={this.setInHouseContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({
	CustomizeInHousePrintersReducer,
	CustomizeReducer
}) => {
	const {
		printersCompanies,
		showPrinterNotExistAlert,
		selectedPrinter,
		userId,
		printerAmount
	} = CustomizeInHousePrintersReducer
	const { adminSelectedOrganizationId } = CustomizeReducer
	return {
		printersCompanies,
		showPrinterNotExistAlert,
		selectedPrinter,
		userId,
		printerAmount,
		adminSelectedOrganizationId
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CustomizeInHousePrintersActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizeInHousePrinters)
