export enum UserRole {
	SUPER_ADMIN = 1,
	ADMIN = 2,
	USER_MANAGER = 3,
	DB_MANAGER = 4,
	USER = 5,
	ADMIN_SETTINGS = 6,
	ENTERPRISE = 7,
	API = 8,
	ORGANIZATIONS_ADMIN = 10,
	LIGHT = 11,
	GRABCAD = 12,
	FUNCTION_EDITOR = 13,
	EXPERT3D = 14,
	GENERATIVE_DESIGN = 15,
	SITE_ADMIN = 17,
	QA = 100,
	QA_SPECIFIC = 101
}
