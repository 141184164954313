export const MATERIAL_VALUE_CHANGED_UPLOAD_PROJ: string =
	'MATERIAL_VALUE_CHANGED_UPLOAD_PROJ'

export const HEAT_MAP_TOGGLE_CLICK: string = 'HEAT_MAP_TOGGLE_CLICK'
export const PART_ANALYSIS_3D_MODAL_OPENED: string =
	'PART_ANALYSIS_3D_MODAL_OPENED'
export const PART_ANALYSIS_3D_MODAL_CLOSED: string =
	'PART_ANALYSIS_3D_MODAL_CLOSED'
export const PART_ANALYSIS_PLASTIC_FORM_SUBMITED: string =
	'PART_ANALYSIS_PLASTIC_FORM_SUBMITED'
export const PART_ANALYSIS_CAST_FORM_SUBMITED: string =
	'PART_ANALYSIS_CAST_FORM_SUBMITED'
export const PART_ANALYSIS_METAL_FORM_SUBMITED: string =
	'PART_ANALYSIS_METAL_FORM_SUBMITED'
export const TOLERANCES_CLICKED = 'TOLERANCES_CLICKED'
export const TOLERANCES_CANCELLED = 'TOLERANCES_CANCELLED'
export const SOLUTIONS_FETCHED: string = 'SOLUTIONS_FETCHED'
export const GET_ALTERNATIVE_SOLUTION_CALCULATION: string =
	'GET_ALTERNATIVE_SOLUTION_CALCULATION'
export const GET_ALTERNATIVE_SOLUTION_CALCULATED: string =
	'GET_ALTERNATIVE_SOLUTION_CALCULATED'
export const NEW_ALTERNATIVE_SOLUTION_CALCULATION: string =
	'NEW_ALTERNATIVE_SOLUTION_CALCULATION'
export const NEW_ALTERNATIVE_SOLUTION_CALCULATED: string =
	'NEW_ALTERNATIVE_SOLUTION_CALCULATED'
export const SOLUTIONS_FETCHED_FINISHED: string = 'SOLUTIONS_FETCHED_FINISHED'
export const SOLUTIONS_FETCHED_FORMATED: string = 'SOLUTIONS_FETCHED_FORMATED'
export const ALTERNATIVE_SOLUTION_MAP_FILTER_CHANGE: string =
	'ALTERNATIVE_SOLUTION_MAP_FILTER_CHANGE'
export const SOLUTIONS_FETCHED_CLEARED: string = 'SOLUTIONS_FETCHED_CLEARED'
export const CONFIGURATION_INHOUSE_ALERT_CANCELED: string =
	'CONFIGURATION_INHOUSE_ALERT_CANCELED'
export const CONFIGURATION_INHOUSE_ALERT_WITH_MATERIAL: string =
	'CONFIGURATION_INHOUSE_ALERT_WITH_MATERIAL'
export const CONFIGURATION_INHOUSE_ALERT_OPENED: string =
	'CONFIGURATION_INHOUSE_ALERT_OPENED'
export const SOLUTION_FETCHED: string = 'SOLUTION_FETCHED'
export const SOLUTION_PRESSED: string = 'SOLUTION_PRESSED'
export const SOLUTION_DO_SETUP: string = 'SOLUTION_DO_SETUP'
export const SOLUTION_TOUR_STEP_UPDATED: string = 'SOLUTION_TOUR_STEP_UPDATED'
export const SOLUTION_REMOVED: string = 'SOLUTION_REMOVED'
export const SOLUTION_CONFIGURE_PRESSED: string = 'SOLUTION_CONFIGURE_PRESSED'
export const SOLUTION_CONFIGURE_CANCELLED: string =
	'SOLUTION_CONFIGURE_CANCELLED'
export const SOLUTION_PRINTER_MATERIAL_CONFIGURE_PRESSED: string =
	'SOLUTION_PRINTER_MATERIAL_CONFIGURE_PRESSED'
export const SOLUTION_PRINTER_MATERIAL_CONFIGURE_CANCELLED: string =
	'SOLUTION_PRINTER_MATERIAL_CONFIGURE_CANCELLED'
export const MATERIAL_ID_CONVERTED: string = 'MATERIAL_ID_CONVERTED'
export const SOLUTION_CONFIGURATION_ADDED: string =
	'SOLUTION_CONFIGURATION_ADDED'
export const SOLUTION_PRINTER_MATERIAL_CONFIGURATION_ADDED: string =
	'SOLUTION_PRINTER_MATERIAL_CONFIGURATION_ADDED'
export const NEW_SOLUTION_CONFIGURATION_ADDED: string =
	'NEW_SOLUTION_CONFIGURATION_ADDED'
export const SOLUTION_CONFIGURATION_SOLUTION_UPDATED: string =
	'SOLUTION_CONFIGURATION_SOLUTION_UPDATED'
export const SOLUTION_CONFIGURATION_UPDATED: string =
	'SOLUTION_CONFIGURATION_UPDATED'
export const SOLUTION_CONFIGURATION_MESH_HEALING_PART_UPDATED: string =
	'SOLUTION_CONFIGURATION_MESH_HEALING_PART_UPDATED'
export const SOLUTION_NAME_FOCUSED_OUT: string = 'SOLUTION_NAME_FOCUSED_OUT'
export const SOLUTION_NAME_CHANGED: string = 'SOLUTION_NAME_CHANGED'
export const SOLUTION_NAME_UPDATED: string = 'SOLUTION_NAME_UPDATED'
export const SOLUTION_NAME_FOCUSED_IN: string = 'SOLUTION_NAME_FOCUSED_IN'
export const SOLUTION_CONFIGURE_CLICKED: string = 'SOLUTION_CONFIGURE_CLICKED'
export const SOLUTION_ORIENTATION_CLICKED: string =
	'SOLUTION_ORIENTATION_CLICKED'
export const SOLUTION_RETRIEVE_RESULTS_CLICKED: string =
	'SOLUTION_RETRIEVE_RESULTS_CLICKED'
export const BATCH_SIZE_CHANGED = 'BATCH_SIZE_CHANGED'
export const EXPORT_3D_LINK_UPDATED = 'EXPORT_3D_LINK_UPDATED'
export const SOLUTION_CONFIGURE_BACK_CLICKED: string =
	'SOLUTION_CONFIGURE_BACK_CLICKED'
export const SOLUTION_CONFIGURE_RESET_CLICKED: string =
	'SOLUTION_CONFIGURE_RESET_CLICKED'
export const SOLUTION_CONFIGURE_PRIORITIZE_CHANGED: string =
	'SOLUTION_CONFIGURE_PRIORITIZE_CHANGED'
export const SOLUTION_CONFIGURE_PRIORITIZE_COST_CHANGED: string =
	'SOLUTION_CONFIGURE_PRIORITIZE_COST_CHANGED'
export const SOLUTION_CONFIGURE_PRIORITIZE_STRENGTH_CHANGED: string =
	'SOLUTION_CONFIGURE_PRIORITIZE_STRENGTH_CHANGED'
export const SOLUTION_CONFIGURE_CALCULATION_CLICKED: string =
	'SOLUTION_CONFIGURE_CALCULATION_CLICKED'
export const SOLUTION_CONFIGURE_CALCULATION_FAILED: string =
	'SOLUTION_CONFIGURE_CALCULATION_FAILED'
export const HEAT_TREATMENT_ALERT_SHOW: string = 'HEAT_TREATMENT_ALERT_SHOW'
export const HEAT_TREATMENT_ALERT_CONFIRM: string =
	'HEAT_TREATMENT_ALERT_CONFIRM'
export const HEAT_TREATMENT_ALERT_CANCEL: string = 'HEAT_TREATMENT_ALERT_CANCEL'
export const HEAT_TREATMENT_ALERT_HIDE: string = 'HEAT_TREATMENT_ALERT_HIDE'
export const SOLUTION_CONFIGURE_FILTER_TOGGLED: string =
	'SOLUTION_CONFIGURE_FILTER_TOGGLED'
export const SOLUTION_CONFIGURE_POST_PROCESSES_TOGGLED: string =
	'SOLUTION_CONFIGURE_POST_PROCESSES_TOGGLED'
export const SOLUTION_CONFIGURE_PRIORITY_TOGGLED: string =
	'SOLUTION_CONFIGURE_PRIORITY_TOGGLED'
export const SOLUTION_CONFIGURE_FILTER_MODAL_CANCELED: string =
	'SOLUTION_CONFIGURE_FILTER_MODAL_CANCELED'
export const SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CANCELED: string =
	'SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CANCELED'
export const SOLUTION_CONFIGURE_POST_PROCESSES_CANCELED: string =
	'SOLUTION_CONFIGURE_POST_PROCESSES_CANCELED'
export const SOLUTION_CONFIGURE_STATE_UPDATED: string =
	'SOLUTION_CONFIGURE_STATE_UPDATED'
export const SOLUTION_CONFIGURE_FILTER_MODAL_CONFIRMED: string =
	'SOLUTION_CONFIGURE_FILTER_MODAL_CONFIRMED'
export const SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CONFIRMED: string =
	'SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CONFIRMED'
export const SOLUTION_CONFIGURE_POST_PROCESSES_MODAL_CONFIRMED: string =
	'SOLUTION_CONFIGURE_POST_PROCESSES_MODAL_CONFIRMED'
export const SOLUTION_CONFIGURE_FILTER_FEATURE_CLICKED: string =
	'SOLUTION_CONFIGURE_FILTER_FEATURE_CLICKED'
export const SOLUTION_CONFIGURE_POST_PROCESSES_FEATURE_CLICKED: string =
	'SOLUTION_CONFIGURE_POST_PROCESSES_FEATURE_CLICKED'
export const START_POLLING_TRAY_ORIENTATION_DATA =
	'START_POLLING_TRAY_ORIENTATION_DATA'
export const END_POLLING_TRAY_ORIENTATION_DATA =
	'END_POLLING_TRAY_ORIENTATION_DATA'
export const SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED: string =
	'SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED'
export const SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CANCELED: string =
	'SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CANCELED'
export const SOLUTION_CONFIGURE_SHOW_ORIENTATION_PRINTING_MODEL: string =
	'SOLUTION_CONFIGURE_SHOW_ORIENTATION_PRINTING_MODEL'
export const SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CANCELED: string =
	'SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CANCELED'
export const SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CONFIRM: string =
	'SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CONFIRM'
export const SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CONFIRM: string =
	'SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CONFIRM'
export const SOLUTION_CONFIGURE_ORIENTATION_PRINTING_CHANGE: string =
	'SOLUTION_CONFIGURE_ORIENTATION_PRINTING_CHANGE'
export const SOLUTION_CONFIGURE_SHOW_POP_UP = 'SOLUTION_CONFIGURE_SHOW_POP_UP'
export const SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CANCELED: string =
	'SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CANCELED'
export const SOLUTION_CONFIGURE_PRIORITIZE_FEATURE_CLICKED: string =
	'SOLUTION_CONFIGURE_PRIORITIZE_FEATURE_CLICKED'
export const SOLUTION_CONFIGURE_FILTER_REMOVED: string =
	'SOLUTION_CONFIGURE_FILTER_REMOVED'
export const SOLUTION_CONFIGURE_PRIORITY_REMOVED: string =
	'SOLUTION_CONFIGURE_PRIORITY_REMOVED'
export const SOLUTION_CONFIGURE_TRAY_ORIENTATION_TOGGLED =
	'SOLUTION_CONFIGURE_TRAY_ORIENTATION_TOGGLED'
export const SOLUTION_UNMOUNTED: string = 'SOLUTION_UNMOUNTED'
export const SOLUTION_STARRED: string = 'SOLUTION_STARRED'
export const COMBINED_AROUND_PART: string = 'COMBINED_AROUND_PART'
export const CONFIGURE_MATERIALS_VALUE_CHANGED: string =
	'CONFIGURE_MATERIALS_VALUE_CHANGED'
export const COST_COMPARISON_EDIT_CLICKED: string =
	'COST_COMPARISON_EDIT_CLICKED'
export const COST_COMPARISON_EDIT_STARTED: string =
	'COST_COMPARISON_EDIT_STARTED'
export const COST_COMPARISON_EDIT_FINISHED: string =
	'COST_COMPARISON_EDIT_FINISHED'
export const COST_COMPARISON_FORM_CANCELLED: string =
	'COST_COMPARISON_FORM_CANCELLED'
export const COST_COMPARISON_QUANTITY_CHANGED: string =
	'COST_COMPARISON_QUANTITY_CHANGED'
export const MATERIAL_COMPARISON_SHOW_ALL_CLICKED =
	'MATERIAL_COMPARISON_SHOW_ALL_CLICKED'
export const CONFIGURATION_WALL_THICKNESS_CLICKED =
	'CONFIGURATION_WALL_THICKNESS_CLICKED'
export const CONFIGURATION_WALL_THICKNESS_CANCELLED =
	'CONFIGURATION_WALL_THICKNESS_CANCELLED'
export const CONFIGURATION_WALL_THICKNESS_LOADING_TOGGLED =
	'CONFIGURATION_WALL_THICKNESS_LOADING_TOGGLED'
export const CONFIGURATION_WALL_THICKNESS_LOADING_UPDATED =
	'CONFIGURATION_WALL_THICKNESS_LOADING_UPDATED'
export const CONFIGURATION_ANALYSIS_3D_MODAL_OPENED =
	'CONFIGURATION_ANALYSIS_3D_MODAL_OPENED'
export const CONFIGURATION_ANALYSIS_3D_MODAL_CLOSED =
	'CONFIGURATION_ANALYSIS_3D_MODAL_CLOSED'
export const ALTERNATIVE_SOLUTION_CHANGED = 'ALTERNATIVE_SOLUTION_CHANGED'
export const ALTERNATIVE_SOLUTION_ADDED = 'ALTERNATIVE_SOLUTION_ADDED'
export const ALTERNATIVE_SOLUTION_HOVER_ELEMENT =
	'ALTERNATIVE_SOLUTION_HOVER_ELEMENT'
export const CHAIN_BENEFIT_VALUE_CHANGED = 'CHAIN_BENEFIT_VALUE_CHANGED'
export const MAIN_PART_UPDATE_CHAIN_BENEFITS = 'MAIN_PART_UPDATE_CHAIN_BENEFITS'
export const MAIN_PART_PART_PRINT_ISSUES_UPDATED =
	'MAIN_PART_PART_PRINT_ISSUES_UPDATED'
export const MAIN_PART_CHAIN_BENEFITS_QUANTITY_CHANGED =
	'MAIN_PART_CHAIN_BENEFITS_QUANTITY_CHANGED'
export const COST_COMPARISON_RESET_CHAIN_BENEFITS =
	'COST_COMPARISON_RESET_CHAIN_BENEFITS'
export const CHAIN_BENEFITS_CHECK_BOX_CHANGED =
	'CHAIN_BENEFITS_CHECK_BOX_CHANGED'
export const CHAIN_BENEFITS_YEARS_OF_DEMAND_CHANGED =
	'CHAIN_BENEFITS_YEARS_OF_DEMAND_CHANGED'
export const OUT_SOURCED_CNC_MANUFACTURING_TOGGLE_CHANGE =
	'OUT_SOURCED_CNC_MANUFACTURING_TOGGLE_CHANGE'

export const CHAIN_BENEFITS_UPDATE_STARTED = 'CHAIN_BENEFITS_UPDATE_STARTED'
export const CHAIN_BENEFITS_UPDATE_FINISHED = 'CHAIN_BENEFITS_UPDATE_FINISHED'
export const NEW_PAGE_VISITED = 'NEW_PAGE_VISITED'
export const PART_ANALYSIS_ADD_TOUR_STEPS = 'PART_ANALYSIS_ADD_TOUR_STEPS'
export const PART_ANALYSIS_SET_CURRENT_STEP = 'PART_ANALYSIS_SET_CURRENT_STEP'
export const CLUSTER_PARTS_FETCHED = 'CLUSTER_PARTS_FETCHED'
export const MAIN_PART_ANALYSIS_SHOW_TOUR_UPDATED =
	'MAIN_PART_ANALYSIS_SHOW_TOUR_UPDATED'
export const SOLUTION_ANALYSIS_PART_DISABLED = 'SOLUTION_ANALYSIS_PART_DISABLED'
export const SOLUTION_ANALYSIS_POLLER_TOGGLED =
	'SOLUTION_ANALYSIS_POLLER_TOGGLED'
export const MAIN_PART_ANALYSIS_DISABLE_PART_TOGGLED =
	'MAIN_PART_ANALYSIS_DISABLE_PART_TOGGLED'
export const UPDATE_QUANTITY_TOGGLED = 'UPDATE_QUANTITY_TOGGLED'
export const TOGGLE_PRINTING_ALERT = 'TOGGLE_PRINTING_ALERT'
export const UPDATE_CONFIGURATION_NAME_TOGGLED =
	'UPDATE_CONFIGURATION_NAME_TOGGLED'
export const TOUR_CONFIGURATION_CALCULATION_LOADING =
	'TOUR_CONFIGURATION_CALCULATION_LOADING'
export const PART_IMAGE_TABS_CLICKED = 'PART_IMAGE_TABS_CLICKED'
export const TRAY_ORIENTATION_INFO_BUTTON_CLICKED =
	'TRAY_ORIENTATION_INFO_BUTTON_CLICKED'
export const CONFIGURATION_TOLERANCES_LOADING_TOGGLED =
	'CONFIGURATION_TOLERANCES_LOADING_TOGGLED'
export const PART_CONFIGURATIONS_SOLUTIONS_UPDATED =
	'PART_CONFIGURATIONS_SOLUTIONS_UPDATED'
export const ORIENTATION_CONFIRM_NO_CHANGE = 'ORIENTATION_CONFIRM_NO_CHANGE'
export const GEOMETRY_ANALYSIS_ALERT_OPENED = 'GEOMETRY_ANALYSIS_ALERT_OPENED'
export const GEOMETRY_ANALYSIS_ALERT_CANCELLED =
	'GEOMETRY_ANALYSIS_ALERT_CANCELLED'
export const GEOMETRY_ANALYSIS_PRINT_ISSUE_LOAD_TOGGLED =
	'GEOMETRY_ANALYSIS_PRINT_ISSUE_LOAD_TOGGLED'
export const SEND_PART_TO_CONSUMER_REMOVE = 'SEND_PART_TO_CONSUMER_REMOVE'
export const BEFORE_SEND_PART_TO_CONSUMER = 'BEFORE_SEND_PART_TO_CONSUMER'
export const START_SEND_PART_TO_CONSUMER = 'START_SEND_PART_TO_CONSUMER'
export const FINISH_SEND_PART_TO_CONSUMER = 'FINISH_SEND_PART_TO_CONSUMER'
export const GET_NEW_CONFIGURATION_FIELDS = 'GET_NEW_CONFIGURATION_FIELDS'
export const SAVE_NEW_MATERIAL_CONFIGURATION = 'SAVE_NEW_MATERIAL_CONFIGURATION'
export const NEW_CONFIGURATION_SAVE_MATERIAL = 'NEW_CONFIGURATION_SAVE_MATERIAL'
export const NEW_CONFIGURATION_CHANGE_MANUFACTURING =
	'NEW_CONFIGURATION_CHANGE_MANUFACTURING'
export const NEW_CONFIGURATION_CHANGE_PRINTER =
	'NEW_CONFIGURATION_CHANGE_PRINTER'
export const NEW_CONFIGURATION_SAVE_PROPERTIES =
	'NEW_CONFIGURATION_SAVE_PROPERTIES'
export const SEND_NEW_CONFIGURATION_METHOD = 'SEND_NEW_CONFIGURATION_METHOD'
export const NEW_CONFIGURATION_LOADING = 'NEW_CONFIGURATION_LOADING'
export const NEW_CONFIGURATION_LOADED = 'NEW_CONFIGURATION_LOADED'
export const NEW_CONFIGURATION_POST_PROCESSES_TOGGLED =
	'NEW_CONFIGURATION_POST_PROCESSES_TOGGLED'
export const NEW_CONFIGURATION_POST_PROCESSES_SAVED =
	'NEW_CONFIGURATION_POST_PROCESSES_SAVED'
export const NEW_CONFIGURATION_POST_PROCESSES_RESET =
	'NEW_CONFIGURATION_POST_PROCESSES_RESET'

export const GLOBAL_CHAIN_BENEFITS_CHECK_BOX_CHANGED =
	'GLOBAL_CHAIN_BENEFITS_CHECK_BOX_CHANGED'
export const NEW_CONFIGURATION_PROPERTIES =
	'NEW_CONFIGURATION_POST_PROCESSES_RESET'
export const NEW_CONFIGURATION_CHANGE_PROPERTY_NAME =
	'NEW_CONFIGURATION_CHANGE_PROPERTY_NAME'
export const NEW_CONFIGURATION_CHANGE_OBJECTIVE =
	'NEW_CONFIGURATION_CHANGE_OBJECTIVE'
export const SOLUTION_START_REMOVED = 'SOLUTION_START_REMOVED'
export const SOLUTION_FINISH_REMOVED = 'SOLUTION_FINISH_REMOVED'

export const LEAD_TIME_EDIT_STARTED = 'LEAD_TIME_EDIT_STARTED'
export const LEAD_TIME_EDIT_FINISHED = 'LEAD_TIME_EDIT_FINISHED'
export const LEAD_TIME_FORM_EDIT_CLICKED = 'LEAD_TIME_FORM_EDIT_CLICKED'
export const LEAD_TIME_FORM_FORM_CANCELLED = 'LEAD_TIME_FORM_FORM_CANCELLED'

export const PART_ANALYSIS_LEAD_FORM_SUBMITED =
	'PART_ANALYSIS_LEAD_FORM_SUBMITED'
export const PART_ANALYSIS_CAM_EXISTENCE_CHANGE =
	'PART_ANALYSIS_CAM_EXISTENCE_CHANGE'

export const LEAD_TIME_GRAPH_UNLOCKED_STARTED =
	'LEAD_TIME_GRAPH_UNLOCKED_STARTED'
export const LEAD_TIME_GRAPH_UNLOCKED_ERROR = 'LEAD_TIME_GRAPH_UNLOCKED_ERROR'
export const LEAD_TIME_GRAPH_UNLOCKED_SUCCESS =
	'LEAD_TIME_GRAPH_UNLOCKED_SUCCESS'

export const TOOLING_TOGGLE_CHANGE_START = 'TOOLING_TOGGLE_CHANGE_START'
export const TOOLING_TOGGLE_CHANGE_SUCCESS = 'TOOLING_TOGGLE_CHANGE_SUCCESS'
export const TOOLING_TOGGLE_CHANGE_ERROR = 'TOOLING_TOGGLE_CHANGE_ERROR'
export const TOOLING_TOGGLE_RESET = 'TOOLING_TOGGLE_RESET'
export const PART_ANALYSIS_CAST_TOOLING_CHANGE =
	'PART_ANALYSIS_CAST_TOOLING_CHANGE'
export const PART_ANALYSIS_INVESTMENT_CAST_TOOLING_CHANGE =
	'PART_ANALYSIS_INVESTMENT_CAST_TOOLING_CHANGE'
export const PART_ANALYSIS_SAND_CAST_TOOLING_CHANGE =
	'PART_ANALYSIS_SAND_CAST_TOOLING_CHANGE'
export const PART_ANALYSIS_MOLD_PRICE_CHANGE = 'PART_ANALYSIS_MOLD_PRICE_CHANGE'
export const UPDATE_PART_STATUS = 'UPDATE_PART_STATUS'

export const PROJECT_PDF_OPTIONS_SUCCESS = 'PROJECT_PDF_OPTIONS_SUCCESS'
export const PROJECT_PDF_OPTIONS_FAILED = 'PROJECT_PDF_OPTIONS_SUCCESS'
export const PROJECT_PDF_OPTIONS_CHANGE = 'PROJECT_PDF_OPTIONS_CHANGE'
export const CONFIGURATION_IS_SET_LEADING = 'CONFIGURATION_IS_SET_LEADING'
export const TOGGLE_ASSEMBLING_COST_MODAL = 'TOGGLE_ASSEMBLING_COST_MODAL'
export const ASSEMBLING_PARAMS_UPDATED = 'ASSEMBLING_PARAMS_UPDATED'
export const CHANGE_ORGANIZATION_TO_UPDATE_CONFIGURATION =
	'CHANGE_ORGANIZATION_TO_UPDATE_CONFIGURATION'
export const EXTEND_CONFIGURATION_ORGANIZATION_SETTINGS =
	'EXTEND_CONFIGURATION_ORGANIZATION_SETTINGS'
export const START_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS =
	'START_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS'
export const FINISHED_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS =
	'FINISHED_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS'

export const TOGGLE_HIDDEN_CONFIGURATIONS = 'TOGGLE_HIDDEN_CONFIGURATIONS'
