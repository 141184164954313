import React, { FC, memo } from 'react'

import classnames from 'classnames'

import CastorPartResultIndex from 'Scenes/Components/CastorPartResultIndex/CastorPartResultIndex'
import { INDEX } from 'Services/Strings'

interface PieFooterProps {
	hoverItem?: (item: string) => void
	is2dProject: any
	isAssembly: boolean
	partResultHovered: any
	currentData?: any
	chartIsEmpty: boolean
	isSuggestion?: boolean
	partStatuses: Array<Record<string, any>>
}

const PieFooter: FC<PieFooterProps> = ({
	partResultHovered,
	chartIsEmpty,
	isSuggestion,
	currentData,
	partStatuses,
	hoverItem
}) => {
	let partResultIndexes = partStatuses

	if (chartIsEmpty) {
		return null
	}

	return (
		<div
			className={classnames('project-results--label--index', {
				'optimization-label': isSuggestion
			})}
		>
			<h5>{INDEX}</h5>
			<CastorPartResultIndex
				hoverItem={hoverItem}
				isSuggestion={isSuggestion}
				className={classnames('led-elements')}
				indexes={partResultIndexes}
				onlyColors={true}
				currentTitle={partResultHovered}
				currentData={currentData}
				circleSize={20}
			/>
		</div>
	)
}

export default memo(PieFooter)
