import { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { match, useHistory } from 'react-router-dom'

import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'
import { getString } from '../../../Services/Strings/StringService'
import CastorForm from '../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../Components/CastorForm/CastorFormHeader'
import FlexBox from '../../Components/FlexBox'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import ProjectPartMappingTable from './ProjectPartMappingTable'
import {
	clearLoading,
	getProjectPartsMapping,
	ignoreAllBlanksProjectPartMapping,
	startAnalyzing,
	updateProjectPartMapping
} from './ProjectPartsMappingActions'
import { MappingStatus } from './ProjectPartsMappingTypes'
import { clearRoutingPoller } from 'global actions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import TransparentButton from 'Scenes/Components/TransparentButton'
import Loader from 'Scenes/Loader/Loader'
import { ProjectStatus } from 'Services/models/IProject'
import { projectRoute } from 'Services/routeFuncs'

import './ProjectPartsMapping.scss'

interface IProps {
	match: match<any>
	showProjectPartsMappingAlert: boolean
	setProjectPartsMappingAlert: (value: boolean) => void
	updatePartMapping: Function
}

const ProjectPartsMapping: FC<IProps> = ({
	match: {
		params: { projectId: projectOrBundleId }
	}
}) => {
	const history = useHistory()
	const { partsMapping, loading, projectStatus, partsLoading } = useSelector(
		(state: RootStateOrAny) => state.ProjectPartsMappingReducer
	)
	const dispatch = useDispatch()
	const disableButton = useMemo(
		() =>
			partsMapping?.some(
				(mapping: Record<string, any>) =>
					mapping.status === MappingStatus.UN_MAP || !mapping.name2D
			),
		[partsMapping]
	)

	const updatePartMapping = useCallback(
		(projectId: string, name3D: string, name2D: string) => {
			dispatch(
				updateProjectPartMapping(projectOrBundleId, projectId, name3D, name2D)
			)
		},
		[dispatch, projectOrBundleId]
	)

	const onStartAnalyzing = useCallback(() => {
		dispatch(startAnalyzing(projectOrBundleId))
	}, [dispatch, projectOrBundleId])

	const onIgnoreAllBlanks = useCallback(() => {
		dispatch(ignoreAllBlanksProjectPartMapping(projectOrBundleId))
	}, [])

	useEffect(() => {
		dispatch(clearLoading())
		dispatch(clearRoutingPoller())
	}, [])

	useEffect(() => {
		if (projectOrBundleId) {
			dispatch(getProjectPartsMapping(projectOrBundleId))
		}
		if (!!projectStatus && projectStatus !== ProjectStatus.awaitingMapping) {
			history.push(projectRoute(projectOrBundleId))
		}
	}, [])

	const setProjectPartsMappingContent = () => {
		return (
			<FlexBox
				flexDirection="column"
				alignItems="baseline"
				justifyContent="flex-start"
				className="project-parts-mapping-section"
			>
				<CastorFormHeader
					explanationHeader={getString('PROJECT_PARTS_MAPPING_TITLE')}
					explanationArray={getString('PROJECT_PARTS_MAPPING_DESCRIPTION')}
					isInCard
				/>
				{partsLoading ? (
					<FlexBox
						alignItems="center"
						justifyContent="center"
						width="100%"
						height="100%"
					>
						<Loader load={true} showFlex={false} />
					</FlexBox>
				) : (
					<ProjectPartMappingTable updatePartMapping={updatePartMapping} />
				)}

				<FlexBox
					className="project-parts-mapping-buttons"
					flexDirection="row"
					alignItems="baseline"
					justifyContent="flex-end"
				>
					<TransparentButton
						className="project-parts-mapping-buttons-ignore-all"
						onClick={onIgnoreAllBlanks}
						disabled={partsLoading}
					>
						{getString('IGNORE_ALL_BLANKS')}
					</TransparentButton>
					<ButtonWithLoader
						className="project-parts-mapping-buttons-submit"
						color="primary"
						disabled={disableButton || partsLoading}
						loading={loading}
						onClick={onStartAnalyzing}
					>
						{getString('START_ANALYZING')}
					</ButtonWithLoader>
				</FlexBox>
			</FlexBox>
		)
	}

	return (
		<NavBarAndMaterial title={getString('PROJECT_PARTS_MAPPING')}>
			<CastorForm
				formTitle={getString('PROJECT_PARTS_MAPPING')}
				content={setProjectPartsMappingContent()}
				style={{ maxWidth: '80%', minWidth: '1000px' }}
			/>
		</NavBarAndMaterial>
	)
}

export default WithFeatureToggleHOC(
	memo(ProjectPartsMapping),
	FeatureComponentId.PARTS_MAPPING_2D_3D
)
