import React from 'react'

import { MenuItem, MenuList } from '@material-ui/core'

import { generateKey } from '../../../Services/Utils/uniqKeyGenerator'

export default props => {
	const { loading, suggestions, getSuggestionItemProps } = props
	if (loading) {
		return <div />
	}

	return (
		<MenuList role="menu">
			{suggestions?.map((suggestion, index) => {
				const className = suggestion.active
					? 'suggestion-item--active'
					: 'suggestion-item'
				return (
					<MenuItem
						key={generateKey(index)}
						{...getSuggestionItemProps(suggestion, { className })}
					>
						{suggestion.description}
					</MenuItem>
				)
			})}
		</MenuList>
	)
}
