import React, { FC } from 'react'

import { getString } from 'Services/Strings/StringService'

const EmptyTableRow: FC = () => {
	return (
		<>
			<div></div>
			<div className="project-parts-mapping-table-text">
				{getString('ADMIN_TABLE_NO_DATA_FOUND')}
			</div>
			<div></div>
		</>
	)
}

export default EmptyTableRow
