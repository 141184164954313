import { getString } from 'Services/Strings/StringService'
import {
	TemperatureUnit,
	UnitSystem,
	WeightUnit
} from 'Services/UnitsConversionService'

const VERY_LOW = 'very-low'
const LOW = 'low'
const HIGH = 'high'
const VERY_HIGH = 'very-high'
const MEDIUM = 'medium'

export const materialInfo = {
	cost: getString('COST'),
	ultimateTensileStrength: getString('STRENGTH'),
	density: getString('WEIGHT'),
	percentElongationAtBreak: getString('ELONGATION'),
	maximumServiceTemperature: getString('TEMPERATURE')
}

export const panelConfiguration = 'panelConfiguration'
export const panelMap = 'panelMap'

export const addNewConfigurationId = 0
export const addPrinterConfigurationId = -1

export const solutionMapConstant = {
	cost: 'cost',
	score: 'score',
	strength: 'strength',
	weight: 'weight',
	stiffness: 'stiffness',
	elongation: 'elongation',
	heatResistance: 'heatResistance',
	conductivity: 'conductivity'
}

export const solutionMapScoreConstants = {
	low: 32.99,
	medium: 65.99,
	high: 100
}

export const yAxisDropDownValues = [
	{
		name: getString('COST'),
		iIcon: '',
		value: 'cost',
		units: ''
	}
]

export const getXAxisDropDownValues = (
	unitSystem: UnitSystem = UnitSystem.metric
) => {
	const weightUnit =
		unitSystem === UnitSystem.imperial ? WeightUnit.lb : WeightUnit.kg
	const temperatureUnit =
		unitSystem === UnitSystem.imperial ? TemperatureUnit.F : TemperatureUnit.C
	return [
		{
			name: getString('PROPERTIES_MATCH'),
			iIcon: getString('SCORE_TOOLTIP'),
			value: 'score',
			units: ''
		},
		{
			name: getString('STRENGTH'),
			iIcon: getString('STRENGTH_TOOLTIP'),
			value: 'strength',
			units: '[Mpa]'
		},
		{
			name: getString('WEIGHT'),
			iIcon: getString('WEIGHT_TOOLTIP').format(weightUnit),
			value: 'weight',
			units: `[${weightUnit}]`
		},
		{
			name: getString('STIFFNESS'),
			iIcon: getString('STIFFNESS_TOOLTIP'),
			value: 'stiffness',
			units: '[Gpa]'
		},
		{
			name: getString('ELONGATION'),
			iIcon: getString('ELONGATION_TOOLTIP'),
			value: 'elongation',
			units: '[%]'
		},
		{
			name: getString('HEAT_RESISTANCE'),
			iIcon: getString('HEAT_RESISTANCE_TOOLTIP').format(temperatureUnit),
			value: 'heatResistance',
			units: `[°${temperatureUnit}]`
		},
		{
			name: getString('THERMAL_CONDUCTIVITY'),
			iIcon: getString('THERMAL_CONDUCTIVITY_TOOLTIP'),
			value: 'conductivity',
			units: '[W/(m•°K)]'
		}
	]
}

export const configurationOrder = [2, 1, 3, 4, null, 0]

export const defaultTabOpen = 0

export const exponentialLineColor = '#00e296'
export const dotsColor = '#008ffb'
export const fillAreaColor = 'rgba(255,255,107,0.5)'

export const seriesNames = {
	bestChoices: 2,
	alternativeSolutions: 1,
	exponentialLine: 0,
	highlightedArea: 3
}
export const fadeAnimation = { enter: 600, exit: 1000, appear: 1000 }

export const opportunitiesComparison = {
	bestMatch: {
		text: getString('LOW'),
		type: LOW,
		impact: 2
	},
	weightReduction: {
		text: getString('HIGH'),
		type: HIGH,
		impact: 4
	},
	partConsolidation: {
		text: getString('VERY_HIGH'),
		type: VERY_HIGH,
		impact: 5
	},
	casting: {
		text: getString('MEDIUM'),
		type: MEDIUM,
		impact: 3
	},
	lathe: {
		text: getString('VERY_LOW'),
		type: VERY_LOW,
		impact: 1
	}
}
