import { orderBy } from 'lodash'

import { createPaginationData } from '../ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestionService'
import {
	CHANGE_PARTS_MAPPING_PAGE,
	GET_PROJECT_PART_MAPPING,
	GET_PROJECT_PART_MAPPING_LOADING,
	PROJECT_PART_MAPPING_CHANGE_3D_PART,
	PROJECT_PART_MAPPING_LOADING
} from './ProjectPartsMappingTypes'

const INITIAL_STATE = {
	parts2DNames: [],
	partsMapping: [],
	loading: false,
	projectStatus: '',
	partsLoading: false,
	partsMappingList: [],
	paginationData: {
		page: 1,
		limit: 30,
		totalPartsCount: 0,
		totalPagesCount: 1,
		enableNext: false,
		enablePrev: false,
		showingFrom: 0,
		showingTo: 0
	}
}
const ProjectPartsMappingReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case GET_PROJECT_PART_MAPPING: {
			const { parts2DNames, partsMapping, projectStatus } = action.payload
			const _partsMapping = orderBy(partsMapping, ['name3D'], ['asc'])
			const partsMappingList = _partsMapping.slice(
				0,
				INITIAL_STATE.paginationData.limit
			)
			const paginationData = createPaginationData(
				INITIAL_STATE.paginationData.page,
				INITIAL_STATE.paginationData.limit,
				partsMapping.length,
				partsMapping.length > INITIAL_STATE.paginationData.limit
					? INITIAL_STATE.paginationData.limit
					: partsMapping.length
			)
			return {
				...state,
				parts2DNames,
				partsMapping: _partsMapping,
				projectStatus,
				paginationData,
				partsMappingList
			}
		}

		case GET_PROJECT_PART_MAPPING_LOADING: {
			return {
				...state,
				partsLoading: action.payload
			}
		}

		case PROJECT_PART_MAPPING_CHANGE_3D_PART: {
			const { partsMapping } = action.payload

			const _partsMapping = orderBy(partsMapping, ['name3D'], ['asc'])
			const start = (state.paginationData.page - 1) * state.paginationData.limit
			const end = start + state.paginationData.limit
			const partsMappingList = _partsMapping.slice(start, end)

			return {
				...state,
				partsMapping: _partsMapping,
				partsMappingList
			}
		}

		case PROJECT_PART_MAPPING_LOADING: {
			return {
				...state,
				loading: action.payload
			}
		}

		case CHANGE_PARTS_MAPPING_PAGE: {
			const { page } = action.payload

			const start = (page - 1) * state.paginationData.limit
			const end = start + state.paginationData.limit
			const partsMappingList = state.partsMapping.slice(start, end)

			const paginationData = createPaginationData(
				page,
				state.paginationData.limit,
				state.partsMapping.length,
				partsMappingList.length
			)

			return {
				...state,
				partsMappingList,
				paginationData
			}
		}

		default:
			return state
	}
}

export default ProjectPartsMappingReducer
