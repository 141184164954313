import React, { Component } from 'react'

import cx from 'classnames'
import { chunk } from 'lodash'

import IconFactory from './StarIcon/IconFactory.js'
import Muted from './thirdParty/CreativeTim/components/Typography/Muted.jsx'
import Flexbox from 'Scenes/Components/FlexBox'

export const formatMoney = value => {
	if (!value) return null
	let parts = value?.toString().split('.') || []

	parts[0] = chunk(parts?.[0].split('').reverse(), 3)
		.map(chunk => chunk.reverse().join(''))
		.reverse()
		.join(',')

	return '$' + parts.join('.')
}

class LedWithTitle extends Component {
	renderText() {
		const {
			title,
			jsx,
			bolded,
			percent,
			dollar,
			isSuggestion,
			hoverItem,
			result
		} = this.props

		if (title) {
			return (
				<Muted
					style={styles.text}
					className={cx({
						bolded: bolded,
						'optimization-led': isSuggestion
					})}
				>
					<div
						onMouseEnter={() => {
							hoverItem?.(result)
						}}
						onMouseLeave={() => {
							hoverItem?.('')
						}}
					>
						{title}{' '}
						{dollar || percent ? ` | ${formatMoney(dollar) || percent}` : ''}
					</div>
				</Muted>
			)
		} else if (jsx) {
			return jsx
		}
	}

	renderIcon = () => {
		const { result, color, gradientStartColor, onlyColors, circleSize } =
			this.props
		if (!result || onlyColors) {
			return (
				<div
					style={{
						width: circleSize
							? `${circleSize}px`
							: onlyColors
							? '18px'
							: '16px',
						height: circleSize
							? `${circleSize}px`
							: onlyColors
							? '18px'
							: '15px',
						background: `linear-gradient(${
							gradientStartColor || color
						}, ${color})`,
						borderRadius: onlyColors ? '50%' : '20px'
					}}
				/>
			)
		}
		return <IconFactory small iconName={result} width="16px" height="13px" />
	}

	render() {
		const { addStyle, isSuggestion } = this.props
		return (
			<Flexbox
				flexDirection="row"
				className={cx({ 'optimization-led-section': isSuggestion })}
				style={{ ...addStyle, ...styles.horizontalFlex }}
			>
				{this.renderIcon()}
				{this.renderText()}
			</Flexbox>
		)
	}
}

const styles = {
	xAxisLabel: { textAlign: 'center' },
	text: {
		width: '100%',
		paddingLeft: '5px',
		textAlign: 'left',
		marginTop: '-3px'
	},
	circle: { display: 'flex' }
}

export default LedWithTitle
