import React, { FC, memo } from 'react'

import classnames from 'classnames'
import { VictoryPie } from 'victory'
import ProjectResultDistributionCustomLabel from '../ProjectResultDistributionCustomLabel'


const VictoryPieTSX: any = VictoryPie

interface CreateChartProps {
	chartData: any
	isAssembly: boolean
	partResultHovered: Function
}

const CreateChart: FC<CreateChartProps> = ({
	chartData,
	isAssembly,
	partResultHovered
}) => {
	if (!chartData) {
		return <div />
	}

	return (
		<div
			className={classnames('project-results--cost-saving-chart', {
				'limited-height': isAssembly
			})}
		>
			<VictoryPieTSX
				labelComponent={<ProjectResultDistributionCustomLabel />}
				data={chartData}
				style={{
					labels: { fill: 'dark gray', fontSize: 19 },
					parent: { maxWidth: 345 },
					data: {
						stroke: (datum: any) => datum.y && datum.stroke,
						strokeWidth: 2
					}
				}}
				innerRadius={80}
				radius={125}
				colorScale={chartData.map((data: any) => data.color)}
				events={[
					{
						target: 'data',
						eventHandlers: {
							onMouseEnter: () => {
								return [
									{
										mutation: (props: any) =>
											partResultHovered(props.datum.name)
									}
								]
							},
							onMouseLeave: () => {
								return [
									{
										mutation: () => partResultHovered('')
									}
								]
							}
						}
					}
				]}
			/>
		</div>
	)
}

export default memo(CreateChart)
