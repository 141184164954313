import React, { FC, memo, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import classnames from 'classnames'

import CreateChartLabel from './CreateChartLabel'
import {
	createChartData,
	makeHeaders,
	PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES
} from './CreateChartService'
import EmptyChart from './EmptyChart'
import PieFooter from './PieFooter'
import ResultsLabel from './ResultsLabel'
import { stylesChart } from './styles'
import FlexBox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'

const OptimizationAnalysisChart: FC = () => {
	const { isBundle } = useSelector(
		(state: RootStateOrAny) => state.ProjectPageReducer
	)

	const {
		project,
		is2dProject,
		isMetaDataProject,
		opportunitiesData,
		totalPartsCount,
		costSavingPartsCount,
		totalSuggestionsCount
	} = useSelector((state: RootStateOrAny) =>
		isBundle
			? state.ProjectBundleSuggestionReducer
			: state.ProjectSuggestionReducer
	)

	const chartData = createChartData(totalSuggestionsCount, opportunitiesData)

	const isAssembly = project.isAssembly

	const [partResultHovered, setPartResultHovered] = useState('')
	const changedHeader = makeHeaders(
		chartData,
		PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES
	)

	const isEmptyChart = !chartData?.[0]?.total

	return (
		<FlexBox
			flexDirection="column"
			className={classnames('project-results--block optimization', {
				'without-assembly-block': !isAssembly
			})}
			style={stylesChart.footerFlex}
		>
			<ResultsLabel
				subTitleKey={
					isEmptyChart ? '' : 'PROJECT_ANALYSIS_SUGGESTIONS_OPTIMIZATION_OUT_OF'
				}
				title={getString('BOM_OPTIMIZATION_ANALYSIS')}
				partsCount={costSavingPartsCount}
				printablePartsCount={totalPartsCount}
				isAssembly={isAssembly}
			/>
			{isEmptyChart ? (
				<EmptyChart />
			) : (
				<FlexBox
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="100%"
				>
					<>
						<CreateChartLabel
							activeItem={partResultHovered}
							chartData={chartData}
							isAssembly={isAssembly}
							partResultHovered={(data: any) => setPartResultHovered(data)}
						/>
						<PieFooter
							hoverItem={(name: string) => setPartResultHovered(name)}
							currentData={chartData}
							isSuggestion={true}
							partStatuses={changedHeader}
							chartIsEmpty={!chartData}
							is2dProject={is2dProject || isMetaDataProject}
							isAssembly={isAssembly}
							partResultHovered={partResultHovered}
						/>
					</>
				</FlexBox>
			)}
		</FlexBox>
	)
}

export default memo(OptimizationAnalysisChart)
