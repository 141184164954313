import { useMemo } from 'react'
import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isNumber } from 'lodash'

import {
	partToResultLabel,
	TabsItem
} from '../ProjectAnalysisSuggestionService'
import { HANDLE_NOTIFICATION } from 'global actions/types'
import { findEffectivePoints } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisService'
import { InapplicableStatus, LOADING } from 'Services/Constants'
import { INAPPLICABLE } from 'Services/Constants'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { Part, PartStatus } from 'Services/models/IPart'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import {
	UnitsConversionService,
	UnitSystem
} from 'Services/UnitsConversionService'

export const getPartsListViewHeaders = (selectedTab: TabsItem) => {
	const partListViewHeaders =
		selectedTab === TabsItem.challenges
			? getString('PARTS_LIST_CHALLENGE_TABLE_HEADERS')
			: getString('PARTS_LIST_SUGGESTION_TABLE_HEADERS')

	return partListViewHeaders || []
}

export const useGetPartInfo = (
	leadingConfigurationData: IBestMatchData[],
	part: Part
) => {
	const { result, id } = part
	const isPrintable =
		result === CADAnalysisResult.printable ||
		result === CADAnalysisResult.borderline

	const leadingConfigurationPartData = useMemo(
		() => leadingConfigurationData?.find(data => data.part === id),
		[id, leadingConfigurationData]
	)

	const materialName = part.materialName
	const printerTechnologyName = isPrintable
		? leadingConfigurationPartData?.printerTechnologyReadableName
		: '-'
	const printerTechnologyDescription = isPrintable
		? leadingConfigurationPartData?.printerTechnologyDescription
		: '-'
	const printerMaterialName = isPrintable
		? leadingConfigurationPartData?.printerMaterialName
		: '-'
	const printerMaterialDescription = isPrintable
		? leadingConfigurationPartData?.printerMaterialDescription
		: '-'
	const isInapplicable =
		part?.isDrawing && part?.drawingStatusCode === InapplicableStatus
	const { effectiveQuantity } = useMemo(
		() => findEffectivePoints(leadingConfigurationPartData || {}),
		[leadingConfigurationPartData]
	)
	const costEffectiveQuantity =
		isPrintable && isNumber(effectiveQuantity) && effectiveQuantity > 0
			? effectiveQuantity
			: '-'
	return {
		materialName,
		isInapplicable,
		isPrintable,
		costEffectiveQuantity,
		printerTechnologyName,
		printerMaterialName,
		printerTechnologyDescription,
		printerMaterialDescription
	}
}

export const usePartGeometryAnalysis = (
	leadingConfigurationData: IBestMatchData[],
	part: Part
) => {
	const leadingConfigurationPartData = useMemo(
		() => leadingConfigurationData?.find(data => data.part === part.id),
		[leadingConfigurationData, part.id]
	) as IBestMatchData

	const configurationPrintIssues = leadingConfigurationPartData?.partPrintIssues
		? leadingConfigurationPartData?.partPrintIssues.filter(
				partPrintIssue =>
					partPrintIssue.configurationId ===
						leadingConfigurationPartData?.configuration?.id ||
					!partPrintIssue.configurationId
		  )
		: []

	return {
		configuration: leadingConfigurationPartData?.configuration,
		solution: leadingConfigurationPartData?.configuration
			? leadingConfigurationPartData?.configuration.solution
			: {},
		configurationPrintIssues,
		chosenOrientationVector:
			leadingConfigurationPartData?.trayOrientation?.trayNormalVector || [],
		wallThickessModelURL: leadingConfigurationPartData?.configuration
			? leadingConfigurationPartData?.configuration.wallThickessModelURL
			: ''
	}
}

export const getPartStatusAndIcon = (
	status: PartStatus,
	result: CADAnalysisResult,
	isInapplicable: boolean
) => {
	const MissingAndNotCostEffective =
		status === PartStatus.missingInformation &&
		result !== CADAnalysisResult.notCostEffective

	const partResultStatus = isInapplicable
		? INAPPLICABLE
		: MissingAndNotCostEffective
		? PartStatus.missingInformation
		: result

	const iconName = MissingAndNotCostEffective
		? PartStatus.missingInformation
		: result || CADAnalysisResult.notPrintable

	const isPartUpdating = status === PartStatus.awaitingCombinedHeatmap

	const resultLabel = partToResultLabel(
		isPartUpdating ? LOADING : partResultStatus
	)
	const isPartNotCostEffective = result === CADAnalysisResult.notCostEffective
	const partIsFailed = result === CADAnalysisResult.failed

	const isPartUnprintable =
		result === CADAnalysisResult.notPrintable &&
		!(status === PartStatus.missingInformation)

	return {
		iconName,
		resultLabel,
		partIsFailed,
		isPartNotCostEffective,
		isPartUpdating,
		isPartUnprintable
	}
}

export const onViewerModelError = (
	error: any,
	dispatch: Dispatch<AnyAction>,
	closeModal: () => void
) => {
	console.error(error)
	dispatch({
		type: HANDLE_NOTIFICATION,
		payload: {
			notificationType: SHOW_NOTIFICATION.ERROR,
			notificationMessage: `error rendering model`
		}
	})
	closeModal()
}

export const convertMMtoCM = (value: number) => value * 1000
export const convertCMtoMM = (value: number) => value / 1000

export const convertPriceToVolume = (
	volume: number,
	isCustomizeOn: boolean = true,
	userUnitSystem: UnitSystem = UnitSystem.metric
) => {
	const userUnitSystems = isCustomizeOn ? userUnitSystem : UnitSystem.metric
	const userConversionService = new UnitsConversionService(
		UnitSystem.metric,
		userUnitSystems
	)

	return userConversionService.convertPricePerVolumeUnit(volume, false)
}

export const convertBackPriceToVolume = (
	volume: number,
	userUnitSystem: UnitSystem = UnitSystem.metric
) => {
	const userConversionService = new UnitsConversionService(
		userUnitSystem,
		UnitSystem.metric
	)

	return userConversionService.convertPricePerVolumeUnit(volume, false)
}
