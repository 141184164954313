import { StatusBarColor } from '../../Components/DataTable/DataTableStatusBar'
import {
	IProjectPartsMapping,
	MappingOptions,
	MappingStatus
} from './ProjectPartsMappingTypes'
import {
	getProjectPartsMappingByProject,
	ignoreAllBlanksProjectPartMappingByProject,
	updateProjectPartMappingByProject,
	updateProjectPartsByMapping
} from 'Services/Network'
import { getString } from 'Services/Strings/StringService'

export const get2DPartName = (partMapping: IProjectPartsMapping): string => {
	if (partMapping?.name2D) {
		return partMapping?.name2D
	}

	if (partMapping?.status === MappingStatus.IGNORE_MAPPING) {
		return MappingOptions.IGNORE
	}

	return ''
}

export const getStatus = (partMapping: IProjectPartsMapping): MappingStatus => {
	let textKey = partMapping.status || MappingStatus.UN_MAP
	if (!partMapping.name2D) {
		textKey = MappingStatus.UN_MAP
	}
	return getString(textKey)
}

export const getStatusColor = (partMapping: IProjectPartsMapping) => {
	let status = StatusBarColor.ORANGE

	switch (partMapping.status) {
		case MappingStatus.IGNORE_MAPPING:
			status = StatusBarColor.BLUE
			break
		case MappingStatus.UN_MAP:
			status = StatusBarColor.ORANGE
			break
		case MappingStatus.MAPPED:
			status = StatusBarColor.GREEN
			break
		default:
			status = StatusBarColor.ORANGE
			break
	}

	if (!partMapping.name2D) {
		status = StatusBarColor.ORANGE
	}

	return status
}

export const getParts2DOptions = (
	parts2DNames: string[],
	partsMapping: IProjectPartsMapping[]
): Record<string, string>[] => {
	const options: Record<string, any>[] = []
	Object.entries(MappingOptions).map(([key, value]) =>
		options.push({
			key: key,
			value: value
		})
	)
	parts2DNames?.map(partName => {
		const option = options.find(option => option.key === partName)
		if (!option) {
			options.push({
				key: partName,
				value: partName,
				disabled: partsMapping.find(part => part.name2D === partName)
			})
		}
	})
	return options
}

export const getProjectPartsMappingByProjectId = async (
	projectId: string
): Promise<{
	parts2DNames: string[]
	partsMapping: IProjectPartsMapping[]
	projectStatus: string
}> => {
	const response = await getProjectPartsMappingByProject(projectId)
	return response?.data?.data || {}
}

export const ignoreBlanksProjectPartMapping = async (
	projectOrBundleId: string
): Promise<{
	partsMapping: IProjectPartsMapping[]
}> => {
	const response = await ignoreAllBlanksProjectPartMappingByProject(
		projectOrBundleId
	)
	return response?.data?.data || {}
}

export const updateProjectPartMappingInfo = async (
	projectOrBundleId: string,
	projectId: string,
	name3D: string,
	name2D: string
): Promise<{
	parts2DNames: string[]
	partsMapping: IProjectPartsMapping[]
}> => {
	const response = await updateProjectPartMappingByProject(
		projectOrBundleId,
		projectId,
		name3D,
		name2D
	)
	return response?.data?.data || {}
}

export const saveProjectPartsMapping = async (projectId: string) => {
	try {
		await updateProjectPartsByMapping(projectId)
	} catch (err) {
		console.log(err)
	}
}
