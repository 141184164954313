import React, { FC, memo, useEffect, useState } from 'react'
import Slider from 'react-slick'

import cx from 'classnames'

import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import { getString } from 'Services/Strings/StringService'

import '../ProjectResultDistributionChart.scss'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

interface AssemblyGalleryTSX {
	projectsAssembly: Record<string, string>[]
	disableThumbnail?: boolean
	inPart?: boolean
	sliderChange?: (changed: boolean) => void
}

const AssemblyGallery: FC<AssemblyGalleryTSX> = ({
	projectsAssembly,
	inPart,
	disableThumbnail,
	sliderChange
}) => {
	const [active, setActive] = useState<number>(0)
	const [nav1, setNav1] = useState(null)
	const [nav2, setNav2] = useState(null)
	const [slider1, setSlider1] = useState(null)
	const [slider2, setSlider2] = useState(null)

	const updateSlider = (slider: any) => {
		sliderChange?.(active !== 0)
		setSlider1(slider)
	}
	useEffect(() => {
		setNav1(slider1)
		setNav2(slider2)
	}, [])

	useEffect(() => {
		setNav1(slider1)
		setNav2(slider2)
	}, [slider1, slider2])

	const withThumbs = !disableThumbnail && projectsAssembly.length > 1
	const settings = {
		dots: disableThumbnail,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		beforeChange: (i: number, next: number) => {
			setActive(next)
		}
	}

	const settingsThumbs = {
		infinite: false,
		dots: false,
		arrows: true,
		slidesToShow: 4,
		slidesToScroll: 4,
		centerPadding: '10px',
		swipeToSlide: true,
		focusOnSelect: true
	}

	return (
		<div className="slider_section">
			<div className="slider_section-header">
				{projectsAssembly[active]?.name ? (
					<div
						title={`${getString('ASSEMBLY')}/${projectsAssembly[active]?.name}`}
					>
						{inPart ? '' : `${getString('ASSEMBLY')}/`}
						{projectsAssembly[active]?.name}
					</div>
				) : (
					<></>
				)}
			</div>
			<Slider
				asNavFor={nav2}
				ref={(slider: any) => updateSlider(slider)}
				className={cx('slider_section--main', {
					'with-thumbs': withThumbs
				})}
				{...settings}
			>
				{projectsAssembly.map((project: any, i: any) => (
					<div className="slider_section-item" key={project.name}>
						<div
							className="slider_section-image"
							data-qa="data-qa-main-assembly-img"
						>
							<PartImageWithFallback
								src={project.assemblyImage}
								alt="..."
								className={'fsd'}
								style={{
									marginTop: 0
								}}
							/>
						</div>
					</div>
				))}
			</Slider>
			{withThumbs && (
				<Slider
					asNavFor={nav1}
					ref={(slider: any) => setSlider2(slider)}
					className="slider_section--thumb"
					{...settingsThumbs}
				>
					{projectsAssembly.map((project: any, i: any) => (
						<div
							className={cx('slider_section-item', {
								'with-border': active === i
							})}
							key={project.name}
						>
							<div
								className="slider_section-image"
								data-qa="data-qa-thumb-assembly-img"
							>
								<PartImageWithFallback
									src={project.assemblyImage}
									alt="..."
									className={'fsd'}
									style={{
										marginTop: 0
									}}
								/>
							</div>
						</div>
					))}
				</Slider>
			)}
		</div>
	)
}

export default memo(AssemblyGallery)
