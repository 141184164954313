import { FC, memo } from 'react'

import {
	FormControl,
	FormHelperText,
	MenuItem,
	Select
} from '@material-ui/core'
import cx from 'classnames'

import IconFactory from '../StarIcon/IconFactory'
import { getString } from 'Services/Strings/StringService'

import './DataTable.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

interface IProps {
	text: string | number
	className: string
	data: any
}

const DataTableDropdown: FC<IProps> = ({ text, className, data = {} }) => {
	return (
		<FormControl className={cx(data?.extra?.wrapperClassName)}>
			<SelectTSX
				displayEmpty
				className={`data-table-dropdown ${className} ${data.className}`}
				value={text}
				onChange={data.onChange}
				disableUnderline={true}
			>
				{data.options.map((option: any) => {
					return (
						<MenuItemTSX
							key={option.key}
							value={option.value}
							disabled={option.disabled}
						>
							{getString(option.key) || option.key}
						</MenuItemTSX>
					)
				})}
			</SelectTSX>
			{!text && data.placeholder && (
				<FormHelperText className="data-table-dropdown-placeholder">
					{data.placeholder}
				</FormHelperText>
			)}
			{data.extra && (
				<IconFactory
					onClick={data.extra.onClick}
					iconName={data.extra.iconName}
					className={cx(
						`data-table-row-${data.extra.iconClassName}`,
						'cursor-pointer'
					)}
					data-qa={`data-qa-parts-properties-cad-icon-${text}`}
				/>
			)}
		</FormControl>
	)
}

export default memo(DataTableDropdown)
