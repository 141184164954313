import { ChangeEvent, FC, memo } from 'react'

import { MenuItem, Select } from '@material-ui/core'
import cx from 'classnames'
import Numeral from 'numeral'

import { manufacturingMethodTypes } from '../../../../../../../../../Services/Constants'
import WithFeatureToggleHOC from '../../../../../../../../../Services/HOC/WithFeatureToggleHOC'
import {
	Feature,
	FeatureComponentId
} from '../../../../../../../../../Services/models/Features'
import { getString } from '../../../../../../../../../Services/Strings/StringService'
import TextField from '../../../../../../../../Components/TextField/TextField'
import Flexbox from 'Scenes/Components/FlexBox'
import { UPLOAD_PROJECT_SCENARIO_METHODS_HEADER } from 'Services/Strings'

import './CostComparisonTab.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

const MIN_CAST = 0
const MIN_YEARS = 1

interface IProps {
	castToolingCost: number | null
	investmentCastToolingCost: number | null
	sandCastToolingCost: number | null
	moldPrice: number | null
	manufacturingMethod: string
	isTooling: boolean
	onCastToolingCostChange: Function
	onInvestmentCastToolingCostChange: Function
	onSandCastToolingCostChange: Function
	onMoldPriceCostChange: Function
	defaultProjectScenarios: any
	onLifeCycle: (lifeCycle: string) => any
	selectedProductLifeCycle: string
}

const renderTextField = (
	onChange: Function,
	label: string,
	placeholder: string,
	min: number
) => {
	return (
		<TextField
			wrapperClassName="financial-form--text-field"
			labelInfoWrapperClassName="financial-form--text-field-wrapper"
			labelClassName="financial-form--text-field-label"
			inputClassName="financial-form--text-field-input"
			label={label}
			disableMinus
			disablePlus
			disableZero
			type="number"
			input={{
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					const value = parseInt(e.currentTarget.value)
					onChange(value)
				},
				min: min,
				step: 1
			}}
			placeholder={placeholder}
		/>
	)
}

const ChainBenefitsCheckBoxes: FC<IProps> = ({
	castToolingCost,
	investmentCastToolingCost,
	sandCastToolingCost,
	moldPrice,
	manufacturingMethod,
	onCastToolingCostChange,
	onInvestmentCastToolingCostChange,
	onSandCastToolingCostChange,
	onMoldPriceCostChange,
	isTooling,
	defaultProjectScenarios,
	selectedProductLifeCycle,
	onLifeCycle
}) => {
	const projectSettingsScenariosOn = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_SETTINGS_SCENARIOS
	)

	const selectedLifeCycle = defaultProjectScenarios.find(
		(element: any) =>
			element.key === selectedProductLifeCycle ||
			element.name === selectedProductLifeCycle
	)

	return (
		<div className="check-boxs" id="chain_benefits">
			<Flexbox>
				<div
					className={cx('check-boxs--header', {
						note: !projectSettingsScenariosOn,
						top: projectSettingsScenariosOn
					})}
				>
					{projectSettingsScenariosOn
						? getString('CHECK_BOXES_STATE_HEADER_TEXT_CUSTOM')
						: getString('CHECK_BOXES_STATE_HEADER_TEXT')}
				</div>
				<SelectTSX
					displayEmpty={true}
					className="customize-material-selector--material-name"
					value={selectedLifeCycle?.key || ''}
					renderValue={(value?: string) =>
						(value && UPLOAD_PROJECT_SCENARIO_METHODS_HEADER[value]) || (
							<div className="custom-placeholder">
								{getString('PRODUCT_LIFE_CYCLE')}
							</div>
						)
					}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onLifeCycle(e.target.value)
					}
				>
					<MenuItemTSX disabled>{getString('PRODUCT_LIFE_CYCLE')}</MenuItemTSX>
					{defaultProjectScenarios ? (
						defaultProjectScenarios?.map((item: any) => {
							return (
								<MenuItemTSX key={item.id} value={item.key}>
									{UPLOAD_PROJECT_SCENARIO_METHODS_HEADER[item.key]}
								</MenuItemTSX>
							)
						})
					) : (
						<div />
					)}
				</SelectTSX>
			</Flexbox>
			<div>
				{projectSettingsScenariosOn &&
				isTooling &&
				manufacturingMethod === manufacturingMethodTypes.mold ? (
					renderTextField(
						onMoldPriceCostChange,
						getString('PART_FINANCIAL_ANALYSIS_CAST_PRICE_PLACEHOLDER').format(
							'$'
						),
						Numeral(moldPrice ? moldPrice.toFixed() : '').format('0,0'),
						MIN_CAST
					)
				) : (
					<></>
				)}
				{projectSettingsScenariosOn &&
				isTooling &&
				manufacturingMethod === manufacturingMethodTypes.cast ? (
					renderTextField(
						onCastToolingCostChange,
						getString('PART_FINANCIAL_ANALYSIS_CAST_PRICE_PLACEHOLDER').format(
							'$'
						),
						Numeral(castToolingCost ? castToolingCost.toFixed() : '').format(
							'0,0'
						),
						MIN_CAST
					)
				) : (
					<></>
				)}
				{projectSettingsScenariosOn &&
				isTooling &&
				manufacturingMethod === manufacturingMethodTypes.investmentCast ? (
					renderTextField(
						onInvestmentCastToolingCostChange,
						getString('PART_FINANCIAL_ANALYSIS_CAST_PRICE_PLACEHOLDER').format(
							'$'
						),
						Numeral(
							investmentCastToolingCost
								? investmentCastToolingCost.toFixed()
								: ''
						).format('0,0'),
						MIN_CAST
					)
				) : (
					<></>
				)}
				{projectSettingsScenariosOn &&
				isTooling &&
				manufacturingMethod === manufacturingMethodTypes.sandCast ? (
					renderTextField(
						onSandCastToolingCostChange,
						getString('PART_FINANCIAL_ANALYSIS_CAST_PRICE_PLACEHOLDER').format(
							'$'
						),
						Numeral(
							sandCastToolingCost ? sandCastToolingCost.toFixed() : ''
						).format('0,0'),
						MIN_CAST
					)
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(ChainBenefitsCheckBoxes),
	FeatureComponentId.CHAIN_BENEFITS
)
