import React from 'react'

import {
	onApplyToggle,
	onEditToggleChange
} from './CustomizeToleranceCostService'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { getString } from 'Services/Strings/StringService'

import '../Customize2DDrawingCost/Customize2DDrawingCost.scss'

interface IProps {
	toleranceData: Record<string, any>
	isEdit: boolean
	toleranceApplyToggle: Function
	toleranceEditToggle: Function
	onEditToggle: Function
	cancelToleranceEditData: Function
}

const CustomizeToleranceCostButton: React.FC<IProps> = ({
	toleranceData,
	isEdit,
	toleranceApplyToggle,
	onEditToggle,
	toleranceEditToggle,
	cancelToleranceEditData
}) => {
	return (
		<div>
			<TransparentButton
				onClick={() =>
					onEditToggleChange(
						!isEdit,
						toleranceData,
						toleranceEditToggle,
						toleranceApplyToggle,
						cancelToleranceEditData
					)
				}
				className="custom-input--action"
			>
				{isEdit ? getString('CANCEL') : getString('EDIT')}
			</TransparentButton>
			{isEdit ? (
				<TransparentButton
					onClick={() =>
						onApplyToggle(
							!isEdit,
							toleranceData,
							toleranceApplyToggle,
							toleranceEditToggle,
							onEditToggle,
							cancelToleranceEditData
						)
					}
					className="custom-input--action"
				>
					{getString('APPLY')}
				</TransparentButton>
			) : (
				<div></div>
			)}
		</div>
	)
}

export default CustomizeToleranceCostButton
