import { FC, memo } from 'react'

import cx from 'classnames'

import { UnitSystem } from '../../../../../Services/UnitsConversionService'
import {
	getWeightReducedValue,
	getWeightReductionRate
} from 'Scenes/Components/WeightReduction/WeightReductionService'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	resultType: number
	partReductionsCount?: number
	partVolume: number
	weightReductionDensity?: number | null
	isPartRow?: boolean
	weightReducedVolume?: number
	unitSystem: UnitSystem
}

const PartAdditionalData: FC<IProps> = ({
	resultType,
	partReductionsCount,
	partVolume,
	weightReducedVolume,
	weightReductionDensity,
	isPartRow,
	unitSystem
}) => {
	if (resultType === ConfigurationResultTypes.PartConsolidation) {
		const pcText = getString('PARTS_CONSOLIDATION_COUNT').format(
			partReductionsCount
		)
		return (
			<div
				className={cx('additional-parts-consolidation', {
					coloring: isPartRow
				})}
				data-qa="data-qa-additional-parts-consolidation"
				title={pcText}
			>
				{pcText}
			</div>
		)
	}

	if (resultType === ConfigurationResultTypes.WeightReduction) {
		const wtText = getString('WEIGHT_REDUCED_PART').format(
			getWeightReductionRate(partVolume, weightReducedVolume),
			getWeightReducedValue(
				partVolume,
				weightReducedVolume,
				unitSystem,
				weightReductionDensity
			)
		)
		return (
			<div
				title={wtText}
				className={cx('additional-parts-weight-reduction', {
					coloring: isPartRow
				})}
				data-qa="data-qa-additional-parts-weight-reduction"
			>
				{wtText}
			</div>
		)
	}

	if (isPartRow) return <div data-qa="data-qa-additional-parts-no-found">-</div>

	return <></>
}

export default memo(PartAdditionalData)
