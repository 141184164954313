import React, { memo, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import cx from 'classnames'

import { ConfigurationResultTypes } from '../../ConfigurationResultTypes'
import PartDetails from './PartDetails/PartDetails'
import PdfViewer from './PdfViewer/PdfViewer'
import SolutionPartImage from './SolutionPartImage'
import SolutionPartImageButton from './SolutionPartImageButton'
import SolutionPartOrientationImage from './SolutionPartOrientationImage'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { partResults } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { OrientationData } from 'Services/models/IOrinetationData'
import { FormatType, LocationPartsParams, Part } from 'Services/models/IPart'
import {
	UnitsConversionService,
	UnitSystem,
	LengthUnit
} from 'Services/UnitsConversionService'

import './SolutionPartDetails.scss'

interface Props {
	part: Part | null
	cluster: any
	partImageSrc: string
	showHeatMap: boolean
	show3dButton: Boolean
	isCluster: boolean
	showHeatMapButton: Boolean
	showImageLoader: boolean
	solutionPartImageIndex: number
	tourStepIndex: number
	configurationId: number
	chosenOrientation: OrientationData | null
	rotatedOrientation: boolean
	freezeConfiguration: boolean
	customConfiguration: boolean
	disableConfiguration: boolean
	onShowHeatToggle: Function
	show3dModalIframe: any | Function
	hide3dModalIframe: Function
	onPartImageTabClicked: Function
	onChooseOrientationClick: any | Function
	onChangeViewer: any | Function
	configurationResult?: string
	configurationVolume: number
	loadingOrientation?: boolean
	resultType?: number
}

const partSizeInfoPopUp = (
	part: Part | null,
	cluster: any,
	configurationVolume: number,
	isWeightReductionConfiguration: boolean,
	conversionService: UnitsConversionService
) => {
	let width = (part && part.width) || (cluster && cluster.width)
	let height = (part && part.height) || (cluster && cluster.height)
	let depth = (part && part.depth) || (cluster && cluster.depth)
	let volume = isWeightReductionConfiguration
		? part?.weightReducedVolume
		: configurationVolume ||
		  (part && part.volume) ||
		  (cluster && cluster.volume)

	// convert values
	const lengthUnit =
		conversionService.outputSystem === UnitSystem.imperial
			? LengthUnit.inch
			: LengthUnit.mm
	width = conversionService.convertLength(width)
	height = conversionService.convertLength(height)
	depth = conversionService.convertLength(depth)
	volume = conversionService.convertVolume(volume)

	return (
		<div className="part-size-details-wrapper">
			<PartDetails
				width={width}
				height={height}
				depth={depth}
				volume={volume}
				countInAssembly={(part && part.countInAssembly) || 0}
				lengthUnit={lengthUnit}
			/>
		</div>
	)
}

const renderInfoButton = (
	part: Part | null,
	cluster: any,
	configurationVolume: number,
	isWeightReductionConfiguration: boolean,
	conversionService: UnitsConversionService
) => {
	return (
		<InfoBox
			boxContactElement={partSizeInfoPopUp(
				part,
				cluster,
				configurationVolume,
				isWeightReductionConfiguration,
				conversionService
			)}
			iconClassName="part-size-details__info_icon with-color"
			boxDirection="right-start"
		/>
	)
}

const SolutionPartImagesTabs: React.FC<Props> = ({
	part,
	cluster,
	partImageSrc,
	onShowHeatToggle,
	showHeatMap,
	show3dModalIframe,
	show3dButton,
	showHeatMapButton,
	showImageLoader,
	solutionPartImageIndex,
	configurationId,
	chosenOrientation,
	rotatedOrientation,
	onChooseOrientationClick,
	tourStepIndex,
	freezeConfiguration,
	customConfiguration,
	onChangeViewer,
	configurationResult,
	isCluster,
	configurationVolume,
	loadingOrientation,
	resultType
}: Props) => {
	const configuration = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer.states[configurationId]
	})
	const { project: { isCastorTwo = false } = {} } = useSelector(
		(state: RootStateOrAny) => {
			return state?.MainPartAnalysisReducer
		}
	)
	const [showModal, setShowModal] = useState(false)
	const [showPDFModal, setShowPDFModal] = useState(false)
	const [isSliderChanged, sliderChange] = useState(false)
	const { userUnitSystem } = useSelector((state: RootStateOrAny) => state.user)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const userConversionService = new UnitsConversionService(
		UnitSystem.metric,
		customizeUnitSystem ? userUnitSystem : UnitSystem.metric
	)

	const isDrawing = part?.isDrawing
	const isMetadata = part?.formatType === METADATA
	const show3DForDrawing =
		(configurationResult === partResults.printable ||
			configurationResult === partResults.borderline) &&
		part?.meshUploadURL &&
		isDrawing &&
		Feature.isFeatureOn(FeatureComponentId.DRAWING_3D)

	const onCloseModal = () => {
		onChangeViewer(false)
		setShowModal(false)
	}
	const changeModalView = (isOpen: boolean) => {
		onChangeViewer(isOpen)
		setShowModal(isOpen)
	}

	const location = useLocation<LocationPartsParams>()
	const isWeightReductionConfiguration = location?.state?.isWeightReduction

	if (part?.latheAnalysisData) {
		if (resultType === ConfigurationResultTypes.Lathe) {
			//TODO: show lathe image url
			//partImageSrc = part.latheAnalysisData.imageURL
			partImageSrc = part?.imageURL || ''
		}
		if (resultType === ConfigurationResultTypes.LatheSplit) {
			partImageSrc = part.latheAnalysisData.combinedImageURL
		}
	}

	const renderImage = (isDrawing?: boolean, fileUrl?: string) => {
		if (isDrawing) {
			return (
				<>
					<div className="pdf-file" onClick={() => changeModalView(true)}>
						<SolutionPartImage
							sliderChange={sliderChange}
							part={part}
							configurationId={configurationId}
							formatType={FormatType.pdf}
							partImageSrc={partImageSrc}
							showImageLoader={showImageLoader}
							solutionPartImageIndex={solutionPartImageIndex}
						/>
					</div>
					<PdfViewer
						onCloseModal={onCloseModal}
						showModal={showModal}
						partImageSrc={partImageSrc}
						fileUrl={fileUrl}
					/>
				</>
			)
		}

		return (
			<>
				<SolutionPartImage
					sliderChange={sliderChange}
					part={part}
					configurationId={configurationId}
					formatType={part?.formatType}
					partImageSrc={partImageSrc}
					showImageLoader={showImageLoader}
					solutionPartImageIndex={solutionPartImageIndex}
				/>

				<SolutionPartOrientationImage
					formatType={part?.formatType}
					chosenOrientation={chosenOrientation}
					rotatedOrientation={rotatedOrientation}
					showImageLoader={showImageLoader}
					solutionPartImageIndex={solutionPartImageIndex}
				/>
			</>
		)
	}

	return (
		<>
			<Flexbox
				flexDirection="column"
				alignItems="center"
				justifyContent="space-around"
				className="part-image-container part-image-container--image-content"
				data-qa="data-qa-part-image-container"
			>
				{renderImage(isDrawing, part?.fileURL || '')}
				{!isDrawing || show3DForDrawing ? (
					<>
						{!isSliderChanged && !isDrawing && (
							<div className={cx('part-image-container--part-size')}>
								{renderInfoButton(
									configuration?.cluster || part,
									configuration?.cluster || cluster,
									configurationVolume,
									isWeightReductionConfiguration,
									userConversionService
								)}
							</div>
						)}
						{configuration?.resultType ===
						ConfigurationResultTypes.PartConsolidation ? (
							<></>
						) : (
							<SolutionPartImageButton
								isCluster={isCluster}
								showOrientationButton={
									!show3DForDrawing &&
									!isMetadata &&
									configuration?.resultType !== ConfigurationResultTypes.Lathe
								}
								customConfiguration={customConfiguration}
								show3dButton={show3dButton || show3DForDrawing}
								tourStepIndex={tourStepIndex}
								show3dModalIframe={show3dModalIframe}
								showHeatMapButton={showHeatMapButton}
								onShowHeatToggle={onShowHeatToggle}
								showHeatMap={showHeatMap}
								solutionPartImageIndex={solutionPartImageIndex}
								onChoseOrientationClick={onChooseOrientationClick}
								configurationId={configurationId}
								freezeConfiguration={freezeConfiguration}
								configurationResult={configurationResult}
								loadingOrientation={loadingOrientation}
								combinePdfUrl={part?.combinePdfUrl}
								isCastorTwo={isCastorTwo}
								showPDFModal={showPDFModal}
								onClosePDFModal={() => setShowPDFModal(false)}
								onOpenPDFModal={() => setShowPDFModal(true)}
							/>
						)}
					</>
				) : (
					<></>
				)}
			</Flexbox>
		</>
	)
}

export default memo(SolutionPartImagesTabs)
