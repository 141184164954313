import React, { FC, memo, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import EmptyTableRow from './EmptyTableRow'
import { changePartsMappingPage } from './ProjectPartsMappingActions'
import { getParts2DOptions } from './ProjectPartsMappingService'
import { IProjectPartsMapping } from './ProjectPartsMappingTypes'
import TableRow from './TableRow'
import CastorPaginationSelector from 'Scenes/Components/CastorPagination/index'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	updatePartMapping: (projectId: string, name3D: string, value: string) => void
}
const ProjectPartMappingTable: FC<IProps> = ({ updatePartMapping }) => {
	const { parts2DNames, partsMapping, paginationData, partsMappingList } =
		useSelector((state: RootStateOrAny) => state.ProjectPartsMappingReducer)
	const dispatch = useDispatch()

	const parts2DOptions = useMemo(
		() => getParts2DOptions(parts2DNames, partsMapping),
		[parts2DNames, partsMapping]
	)

	return (
		<div>
			<div className="project-parts-mapping-table">
				<div className="project-parts-mapping-table-header">
					{getString('PROJECT_PARTS_MAPPING_3D_NAME')}
				</div>
				<div className="project-parts-mapping-table-header">
					{getString('PROJECT_PARTS_MAPPING_2D_NAME')}
				</div>
				<div className="project-parts-mapping-table-header">
					{getString('PROJECT_PARTS_MAPPING_STATUS')}
				</div>
				{partsMapping.length ? (
					partsMappingList.map((partMapping: IProjectPartsMapping) => {
						return (
							<TableRow
								key={partMapping.id}
								parts2DOptions={parts2DOptions}
								partMapping={partMapping}
								updatePartMapping={updatePartMapping}
							/>
						)
					})
				) : (
					<EmptyTableRow />
				)}
			</div>
			{partsMapping.length && (
				<CastorPaginationSelector
					showPagination={paginationData.totalPagesCount > 1}
					pageNumber={paginationData.page}
					isLastPage={!paginationData.enableNext}
					total={paginationData.totalPartsCount}
					limit={paginationData.limit}
					className={{ pagination: 'parts-pagination' }}
					showingFrom={paginationData.showingFrom}
					showingTo={paginationData.showingTo}
					onNextClick={() =>
						dispatch(changePartsMappingPage(paginationData.page + 1))
					}
					onPrevClick={() =>
						dispatch(changePartsMappingPage(paginationData.page - 1))
					}
					onPageClick={(page: number) => dispatch(changePartsMappingPage(page))}
				/>
			)}
		</div>
	)
}

export default memo(ProjectPartMappingTable)
