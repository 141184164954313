import parseHTML from 'html-react-parser'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Portal } from '@material-ui/core'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import EditIcon from '@material-ui/icons/Edit'
import cx from 'classnames'
import { isNumber, toLower } from 'lodash'

import ChallengeFilterAlert from './ChallengeFilter/ChallengeFilterAlert'
import { SelectedType } from './ChallengeFilter/ChallengeFilterAlertService'
import {
	ClientChallengesType,
	ClientSuggestionType,
	ProjectSuggestionsItem,
	StringInfoChallenges,
	TabsItem
} from './ProjectAnalysisSuggestionService'
import FilterContent from './ProjectSuggestionPartsFilterContent'
import { confirmConsolidationAnalysis } from 'global actions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import { CastorBannerType } from 'Scenes/Components/CastorBanner/CastorBannerType.enum'
import { IconLockCreditCard } from 'Scenes/Components/CastorIcons/iconCreditCard'
import { buttonOnClick } from 'Scenes/Components/CastorLockedParts/CastorLockedPartsAction'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import {
	changeResultType,
	filterSuggestionsParts,
	onUpdateChallengesFilters
} from 'Scenes/Components/FilterPartsGrid/FilterPartsAction'
import FlexBox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ProjectClusterStatus } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'

import './ProjectAnalysisSuggestion.scss'

const resetValue = undefined

const ProjectSuggestionPartsFilter: FC<any> = () => {
	const { isBundle } = useSelector(
		(state: RootStateOrAny) => state.ProjectPageReducer
	)
	const [selectedSuggestion, setShowSelectedSuggestion] = useState<
		ProjectSuggestionsItem | undefined
	>(resetValue)

	const {
		searchPhrase,
		refetchParts,
		paginationData: { page, limit },
		sortingCriteria,
		sortBy,
		resultType,
		projectSuggestionsList,
		projectNoOpportunitiesList,
		projectChallengesList,
		selectedTab,
		project,
		projectClusterStatus,
		totalClustersCount,
		amountOfLockedParts,
		challengesFilterLoading
	} = useSelector((state: RootStateOrAny) =>
		isBundle
			? state.ProjectBundleSuggestionReducer
			: state.ProjectSuggestionReducer
	)
	const user = useSelector((state: RootStateOrAny) => state.user)
	const { disabledButton, buttonText, loadingCalculation } = useSelector(
		(state: RootStateOrAny) => state.CastorLockedPartsReducer
	)
	const { userDetails: { contactUsEmail = '', email = '', name = '' } = {} } =
		user
	const { projectId } = useParams() as { projectId: string }

	const selectedFilter = useMemo(() => {
		switch (selectedTab) {
			case TabsItem.opportunities:
				return projectSuggestionsList
			case TabsItem.challenges:
				return projectChallengesList
			default:
				return projectNoOpportunitiesList
		}
	}, [
		selectedTab,
		projectSuggestionsList,
		projectNoOpportunitiesList,
		projectChallengesList
	])

	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const dispatch = useDispatch()

	useEffect(() => {
		let delayTime = 200

		const delayDebounceFn = setTimeout(() => {
			dispatch(
				filterSuggestionsParts(
					projectId,
					searchPhrase,
					isBundle,
					page,
					limit,
					false,
					user,
					false,
					sortBy,
					sortingCriteria,
					resultType,
					selectedTab
				)
			)
		}, delayTime)

		return () => clearTimeout(delayDebounceFn)
	}, [searchPhrase, page, sortBy, sortingCriteria, resultType, selectedTab])

	useEffect(() => {
		if (refetchParts) {
			dispatch(
				filterSuggestionsParts(
					projectId,
					searchPhrase,
					isBundle,
					page,
					limit,
					false,
					user,
					false,
					sortBy,
					sortingCriteria,
					resultType,
					selectedTab
				)
			)
		}
	}, [refetchParts])

	const handleChange = (value: number) => {
		dispatch(changeResultType(value))
	}

	const onFilterSubmit = useCallback(
		async (
			value: string | number,
			type: SelectedType,
			challengeType: ClientChallengesType
		) => {
			await dispatch(
				onUpdateChallengesFilters(
					value,
					type,
					challengeType,
					projectId,
					isBundle,
					customizeUnitSystem,
					user.userUnitSystem
				)
			)
			setShowSelectedSuggestion(resetValue)
		},
		[dispatch, projectId, isBundle]
	)

	return (
		<>
			<FlexBox
				width="100%"
				flexDirection="column"
				className="project-suggestion-filter"
			>
				<FlexBox className="project-suggestion-filter__block">
					{amountOfLockedParts > 0 ? (
						<div className={cx('filter-item locked')}>
							<div className="title">
								<FlexBox alignItems="center" justifyContent="center">
									<IconLockCreditCard />
									<div>{getString('ADMIN_USER_LOCKED_PARTS')}</div>
								</FlexBox>
								<div>
									<DetailsPopup
										data={getString('CONTACT_US_TO_RESOLVE')}
										popperDirection="bottom"
										isHover={true}
										popperClassName="admin-feature-toggle--popper"
										popperContactClassName="admin-feature-toggle--popper--data auto-width"
										inPortal
									>
										<ButtonWithLoader
											disabled={disabledButton}
											color="primary"
											loading={loadingCalculation}
											onClick={() => {
												dispatch(
													buttonOnClick(
														email,
														contactUsEmail,
														CastorBannerType.UPGRADE_NOW,
														name
													)
												)
											}}
										>
											{buttonText || getString('UPGRADE_NOW')}
										</ButtonWithLoader>
									</DetailsPopup>
								</div>
							</div>
							<div className="body">
								<div className="statistic">
									<div className="number">{amountOfLockedParts}</div>{' '}
									<div className="text">{getString('NOT_ANALYZED')}</div>
								</div>
								<div className="sub-text">
									<div>{getString('LOCKED_FOR_UPLOADS')}</div>
								</div>
							</div>
						</div>
					) : (
						<></>
					)}
					{selectedFilter?.map((suggestion: ProjectSuggestionsItem) => {
						const isChallenges = isNumber(suggestion.challengesResultType)
						const suggestionType =
							suggestion.clientSuggestionType ||
							suggestion.noOpportunitiesResultType ||
							suggestion.challengesResultType ||
							0

						const countText =
							suggestion.clientSuggestionType === ClientSuggestionType.amPC
								? getString('CONSOLIDATION_OPPORTUNITIES')
								: suggestion.noOpportunitiesResultType
								? toLower(getString('PARTS'))
								: isChallenges
								? getString('CHALLENGES')
								: getString('OPPORTUNITIES')

						const suggestionCount =
							suggestion.suggestionsCount || suggestion.partsCount || 0

						const isPC =
							suggestion.clientSuggestionType === ClientSuggestionType.amPC
						const isPCAWaiting =
							project?.clusterStatus ===
								ProjectClusterStatus.awaitingAnalysis ||
							projectClusterStatus === ProjectClusterStatus.awaitingAnalysis

						return (
							<div
								key={suggestion.name}
								className={cx('filter-item', {
									active: resultType === suggestionType
								})}
								onClick={() => {
									if (resultType !== suggestionType) {
										handleChange(suggestionType)
									}
								}}
							>
								<div className="title">
									<FlexBox alignItems="center">
										{suggestion.name}{' '}
										{isChallenges ? (
											<DetailsPopup
												data={getString(
													StringInfoChallenges[
														suggestion.challengesResultType as ClientChallengesType
													]
												).format(suggestion.editField?.value)}
												isHover={true}
												popperDirection="bottom"
												popperClassName="info-box-wrapper"
												popperContactClassName="info-box-data"
												inPortal
											>
												<IconFactory iconName="info" className="info-icon" />
											</DetailsPopup>
										) : (
											<></>
										)}
									</FlexBox>

									{!isPCAWaiting && isPC && project?.recalculateClusters ? (
										<div
											onClick={e => {
												e.preventDefault()
												e.stopPropagation()
												dispatch(
													confirmConsolidationAnalysis(
														isBundle ? null : project.id,
														totalClustersCount,
														isBundle ? project.id : null
													)
												)
											}}
										>
											<DetailsPopup
												data={getString('SEARCH_AGAIN_FOR_RESULT')}
												popperDirection="bottom-end"
												isHover={true}
												popperClassName="admin-feature-toggle--popper"
												popperContactClassName="admin-feature-toggle--popper--data auto-width"
												inPortal
											>
												<AutorenewIcon />
											</DetailsPopup>
										</div>
									) : (
										<></>
									)}
								</div>

								{isPCAWaiting && isPC ? (
									<div className="body">
										<div className="statistic">
											<IconFactory
												iconName={ProjectClusterStatus.awaitingAnalysis}
												className="cluster-section--subheader--icon"
											/>
											<div className="text">
												{getString('WEIGHT_REDUCTION_REQUESTED_TXT')}
											</div>
										</div>
									</div>
								) : (
									<div className="body">
										<div className="statistic">
											<div className="number">{suggestionCount}</div>{' '}
											<div className="text">{countText}</div>
										</div>
										<div className="sub-text">
											<FilterContent
												customizeUnitSystem={customizeUnitSystem}
												userUnitSystem={user.userUnitSystem}
												setShowSelectedSuggestion={setShowSelectedSuggestion}
												suggestion={suggestion}
												suggestionCount={suggestionCount}
											/>
										</div>
									</div>
								)}
							</div>
						)
					})}
				</FlexBox>
			</FlexBox>
			{selectedSuggestion && (
				<Portal>
					<ChallengeFilterAlert
						customizeUnitSystem={customizeUnitSystem}
						userUnitSystem={user.userUnitSystem}
						challengesFilterLoading={challengesFilterLoading}
						show={!!selectedSuggestion}
						value={selectedSuggestion?.editField?.value}
						type={selectedSuggestion?.editField?.type}
						challengeType={
							selectedSuggestion?.challengesResultType as ClientChallengesType
						}
						onSubmit={onFilterSubmit}
						onCancel={() => {
							setShowSelectedSuggestion(resetValue)
						}}
					/>
				</Portal>
			)}
		</>
	)
}

export default memo(ProjectSuggestionPartsFilter)
