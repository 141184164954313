import { Action } from '../../../../global actions/ActionModels'
import {
	PARAMETERS_MANAGER_COSTING_3DP_ON_SUBMIT,
	PARAMETERS_MANAGER_COSTING_CNC_ON_SUBMIT,
	PARAMETERS_MANAGER_COSTING_GENERAL_ON_SUBMIT,
	PARAMETERS_MANAGER_DATA_CALLED,
	PARAMETERS_MANAGER_DATA_FETCHED,
	PARAMETERS_MANAGER_INJECTION_COSTING_ON_SUBMIT,
	PARAMTER_UPDATE_SUCCEED,
	UPDATING_PARAMTER
} from '../../../../global actions/types'
import { MAX_PERCENTAGE_FIELD_VALUE } from '../../../../Services/Constants'
import { ParametersFormTypes } from '../../../../Services/models/ParametersManager'

export class ParametersManagerInitialState {
	readonly updatingLoading: boolean = false
	readonly loading: boolean = false
	readonly parametersObject: any = {}
	readonly parametersCNCObject: any = {}
	readonly parameters3DObject: any = {}
	readonly parametersInjectionObject: any = {}
	readonly parametersCastingObject: any = {}
	readonly parametersInvestmentCastingObject: any = {}
	readonly parametersSandCastingObject: any = {}
	readonly parametersGeneralObject: any = {}
	readonly parametersSupplyChainObject: any = {}
	readonly parametersInfoObject: any = {}
	readonly parametersPermissionsObject: any = {}
	readonly parameters2dDrawingObject: any = {}
	readonly parametersCo2TmObject: any = {}
	readonly costing3DParametersName: Array<string> = []
	readonly costingCastingParametersName: Array<string> = []
	readonly costingInvestmentCastingParametersName: Array<string> = []
	readonly costingSandCastingParametersName: Array<string> = []
	readonly costingCNCParametersName: Array<string> = []
	readonly costingInjectionMoldingParametersName: Array<string> = []
	readonly costingGeneralParametersName: Array<string> = []
	readonly costingSupplyChainParametersName: Array<string> = []
	readonly costingCo2TmParametersName: Array<string> = []
	readonly costing2dDrawingParametersName: Array<string> = []
}

const initialState: ParametersManagerInitialState =
	new ParametersManagerInitialState()

export default (state = initialState, { type, payload }: Action<any>) => {
	switch (type) {
		case PARAMETERS_MANAGER_DATA_CALLED:
			return { ...state, loading: true }
		case PARAMETERS_MANAGER_DATA_FETCHED: {
			const {
				data: {
					parametersObject,
					parametersPermissionsObject,
					parametersInfoObject
				}
			} = payload

			// add maxValue to each percentage fieldType
			Object.keys(parametersInfoObject).map((key: any) => {
				if (parametersInfoObject[key].fieldType === 'percentage') {
					parametersInfoObject[key].maxValue = MAX_PERCENTAGE_FIELD_VALUE
				}
			})

			return {
				...state,
				parametersObject,
				parametersInfoObject,
				loading: false,
				...getParametersNames(parametersPermissionsObject),
				...getParametersInitObjects(parametersObject)
			}
		}
		case PARAMETERS_MANAGER_INJECTION_COSTING_ON_SUBMIT: {
			return { ...state }
		}
		case PARAMETERS_MANAGER_COSTING_3DP_ON_SUBMIT: {
			return {
				...state
			}
		}
		case PARAMETERS_MANAGER_COSTING_GENERAL_ON_SUBMIT: {
			return { ...state }
		}
		case PARAMETERS_MANAGER_COSTING_CNC_ON_SUBMIT: {
			return { ...state }
		}
		case UPDATING_PARAMTER: {
			return { ...state, updatingLoading: true }
		}
		case PARAMTER_UPDATE_SUCCEED: {
			const {
				parametersObject,
				parametersPermissionsObject,
				parametersInfoObject
			} = payload
			return {
				...state,
				parametersObject: { ...state.parametersObject, ...parametersObject },
				parametersPermissionsObject: {
					...state.parametersInfoObject,
					...parametersInfoObject
				},
				...getParametersNames(parametersPermissionsObject),
				...getParametersInitObjects(parametersObject),
				updatingLoading: false
			}
		}
		default:
			return state
	}
}

const getParametersInitObjects = (parametersObject: any) => {
	if (!parametersObject) {
		return {}
	}
	const keys = Object.keys(parametersObject)
	if (!keys.length) {
		return {}
	}
	return keys.reduce((acc: any, key: any) => {
		return {
			...acc,
			...getParameterInitStateType(key, parametersObject)
		}
	}, {})
}

const getParametersNames = (parametersPermissionsObject: any) => {
	if (!parametersPermissionsObject) {
		return {}
	}
	const keys = Object.keys(parametersPermissionsObject)
	if (!keys.length) {
		return {}
	}

	return keys.reduce((acc: any, key: any) => {
		return {
			...acc,
			...getParameterStateType(key, parametersPermissionsObject[key])
		}
	}, {})
}

const getParameterStateType = (keyType: string, value: Array<string>) => {
	switch (keyType) {
		case ParametersFormTypes.COSTING_3DP:
			return { costing3DParametersName: value || [] }

		case ParametersFormTypes.COSTING_CASTING:
			return { costingCastingParametersName: value || [] }

		case ParametersFormTypes.COSTING_INVESTMENT_CASTING:
			return { costingInvestmentCastingParametersName: value || [] }

		case ParametersFormTypes.COSTING_SAND_CASTING:
			return { costingSandCastingParametersName: value || [] }

		case ParametersFormTypes.COSTING_CNC:
			return { costingCNCParametersName: value || [] }

		case ParametersFormTypes.COSTING_INJECTION_MOLDING:
			return { costingInjectionMoldingParametersName: value || [] }

		case ParametersFormTypes.GENERAL:
			return { costingGeneralParametersName: value || [] }

		case ParametersFormTypes.COSTING_SUPPLY_CHAIN:
			return { costingSupplyChainParametersName: value || [] }

		case ParametersFormTypes.COSTING_2D_DRAWING:
			return { costing2dDrawingParametersName: value || [] }

		case ParametersFormTypes.COSTING_CO2_TRADITIONAL_MENUFACTURING:
			return { costingCo2TmParametersName: value || [] }

		default:
			break
	}
}
const getParameterInitStateType = (keyType: string, parametersObject: any) => {
	switch (keyType) {
		case ParametersFormTypes.COSTING_3DP:
			return { parameters3DObject: { ...(parametersObject[keyType] || {}) } }

		case ParametersFormTypes.COSTING_CASTING:
			return {
				parametersCastingObject: { ...(parametersObject[keyType] || {}) }
			}

		case ParametersFormTypes.COSTING_INVESTMENT_CASTING:
			return {
				parametersInvestmentCastingObject: {
					...(parametersObject[keyType] || {})
				}
			}
		case ParametersFormTypes.COSTING_SAND_CASTING:
			return {
				parametersSandCastingObject: { ...(parametersObject[keyType] || {}) }
			}
		case ParametersFormTypes.COSTING_CNC:
			return { parametersCNCObject: { ...(parametersObject[keyType] || {}) } }

		case ParametersFormTypes.COSTING_INJECTION_MOLDING:
			return {
				parametersInjectionObject: { ...(parametersObject[keyType] || {}) }
			}
		case ParametersFormTypes.GENERAL:
			return {
				parametersGeneralObject: { ...(parametersObject[keyType] || {}) }
			}

		case ParametersFormTypes.COSTING_SUPPLY_CHAIN:
			return {
				parametersSupplyChainObject: { ...(parametersObject[keyType] || {}) }
			}
		case ParametersFormTypes.COSTING_2D_DRAWING:
			return {
				parameters2dDrawingObject: { ...(parametersObject[keyType] || {}) }
			}

		case ParametersFormTypes.COSTING_CO2_TRADITIONAL_MENUFACTURING:
			return {
				parametersCo2TmObject: { ...(parametersObject[keyType] || {}) }
			}
		default:
			break
	}
}
