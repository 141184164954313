import React, { FC, memo, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Close } from '@material-ui/icons'
import { isEmpty } from 'lodash'

import {
	onCo2PerKwChange,
	onUpdateCo2CarbonTypicalKwh
} from '../../CustomizeCO2CalculationAction'
import {
	Button,
	ItemGrid,
	Table
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import {
	manufactureTypeByCosting,
	manufacturingMethodStrings,
	manufacturingMethodTypes,
	ZERO_NUMBER_FIELD_VALUE
} from 'Services/Constants'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import '../../CustomizeCO2Calculation.scss'

const ItemGridTSX: any = ItemGrid
const CloseTSX: any = Close

const includedMethods = [
	manufactureTypeByCosting.COSTING_CO2_CNC,
	manufactureTypeByCosting.COSTING_CO2_DIE_CASTING,
	manufactureTypeByCosting.COSTING_CO2_INJECTION_MOLDING,
	manufactureTypeByCosting.COSTING_CO2_SAND_CASTING,
	manufactureTypeByCosting.COSTING_CO2_INVESTMENT_CASTING
]

const CustomizeCO2СarbonTypicalTable: FC = () => {
	const dispatch = useDispatch()
	const { co2PerKW = {}, userSettingsDefaultValues } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { co2PerKwValue } = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const onDeleteManufactureMethod = (selectedManufacture: string) => {
		const co2PerKWDefault = isEmpty(co2PerKwValue) ? co2PerKW : co2PerKwValue
		dispatch(onCo2PerKwChange(selectedManufacture, ZERO_NUMBER_FIELD_VALUE))
		dispatch(
			onUpdateCo2CarbonTypicalKwh(
				{
					...co2PerKWDefault,
					[selectedManufacture]: ZERO_NUMBER_FIELD_VALUE
				},
				undefined,
				adminSelectedOrganizationId
			)
		)
	}

	const renderDeleteButton = (itemToDelete: string) => {
		return (
			<Button
				title={CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM}
				onClick={() => onDeleteManufactureMethod(itemToDelete)}
				color="dangerNoBackground"
				style={{ margin: 'unset', padding: 'unset' }}
			>
				<CloseTSX className="custom-calculation-co2--table--icon" />
			</Button>
		)
	}

	const renderCo2MaterialData = useMemo(() => {
		const filteredCo2PerKw = Object.keys(co2PerKW).reduce((acc, key) => {
			const co2ManufactureValue = co2PerKW?.[key]
			const co2ManufactureDefault = userSettingsDefaultValues.co2PerKW?.[key]

			if (
				includedMethods.includes(key) &&
				co2ManufactureValue !== co2ManufactureDefault &&
				co2ManufactureValue > 0
			) {
				acc[key as ImanufacturingTypes] = co2ManufactureValue
			}

			return acc
		}, {} as typeof manufacturingMethodTypes)

		return Object.entries(filteredCo2PerKw).map(([key, value]) => [
			getString(manufacturingMethodStrings[key as ImanufacturingTypes]),
			value,
			renderDeleteButton(key)
		])
	}, [co2PerKW])

	if (renderCo2MaterialData.length === 0) return <></>
	return (
		<div className="customize__table">
			<ItemGridTSX>
				<Table
					editTableStyle={'co2CalculationTable'}
					tableHead={[
						getString('MATERIAL_COST_TECHNOLOGY'),
						getString('CARBON_CO2_CALCULATION'),
						getString('ACTIONS')
					]}
					tableData={renderCo2MaterialData}
				/>
			</ItemGridTSX>
		</div>
	)
}

export default memo(CustomizeCO2СarbonTypicalTable)
