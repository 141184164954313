import React, { memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'
import { isEqual } from 'lodash'

import { getString } from '../../../../Services/Strings/StringService'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import {
	fetchListOfSuggestions,
	onChangeAMListSuggestions,
	onChangeListSuggestions,
	onSaveListSuggestions
} from './CustomizeSuggestionsActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import FlexBox from 'Scenes/Components/FlexBox'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import Loader from 'Scenes/Loader/Loader'
import { IDefaultSuggestions } from 'Services/models/IDefaultSuggestions'

export const amWithoutBMResults = [
	ConfigurationResultTypes.PartConsolidation,
	ConfigurationResultTypes.WeightReduction
]

export const amResults = [
	ConfigurationResultTypes.BestMatch,
	...amWithoutBMResults
]

const CustomizeListOfSuggestions = () => {
	const dispatch = useDispatch()

	const {
		listOfSuggestionLoading,
		fetchListOfSuggestionLoading,
		listOfSuggestion,
		initialListOfSuggestion,
		isAmChecked,
		isBestMatchChecked
	} = useSelector((state: RootStateOrAny) => state.CustomizeSuggestionsReducer)

	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const onSaveListSuggestionsClick = () => {
		dispatch(onSaveListSuggestions(adminSelectedOrganizationId))
	}

	useEffect(() => {
		dispatch(fetchListOfSuggestions(adminSelectedOrganizationId))
	}, [])

	const lastChecked = useMemo(
		() =>
			listOfSuggestion.filter(
				(suggestion: Record<string, any>) => suggestion.showAsDefault
			).length === 1,
		[listOfSuggestion]
	)

	const isDataEqual = useMemo(
		() => isEqual(initialListOfSuggestion, listOfSuggestion),
		[initialListOfSuggestion, listOfSuggestion]
	)

	return (
		<div
			className="cost-calculation-params-wrapper customize-suggestions_list-wrapper"
			data-qa="data-qa-suggestions-parameters-block"
		>
			<CastorFormHeader
				explanationHeader={getString('CUSTOMIZE_LIST_OF_SUGGESTIONS_TITLE')}
				explanationArray={getString('CUSTOMIZE_LIST_OF_SUGGESTIONS_ARRAY')}
				isInCard
			/>
			<FlexBox flexDirection="column">
				{fetchListOfSuggestionLoading ? (
					<Loader load={true} />
				) : (
					<>
						<FlexBox alignItems="center">
							<CastorCheckbox
								onClick={() =>
									dispatch(onChangeAMListSuggestions(!isAmChecked))
								}
								className={cx('cost-analysis-item__checkbox')}
								data-qa={`data-qa-cost-calculation-parameters-checkbox-all-am`}
								checked={!!isAmChecked}
							/>

							<div>{getString('COST_SUMMARY_ADDITIVE_METHOD')}</div>
						</FlexBox>

						{listOfSuggestion.map((suggestion: IDefaultSuggestions) => {
							return (
								<FlexBox key={suggestion.name} alignItems="center">
									<CastorCheckbox
										disabled={
											(!!amWithoutBMResults.includes(suggestion.resultType) &&
												!isBestMatchChecked) ||
											(!!lastChecked && !!suggestion.showAsDefault)
										}
										onClick={() => {
											dispatch(
												onChangeListSuggestions(
													suggestion.resultType,
													!suggestion.showAsDefault
												)
											)
										}}
										className={cx('cost-analysis-item__checkbox', {
											am: amResults.includes(suggestion.resultType)
										})}
										data-qa={`data-qa-cost-calculation-parameters-checkbox-${suggestion.name}`}
										checked={suggestion.showAsDefault}
									/>

									<div>{getString(suggestion.stringKey)}</div>
								</FlexBox>
							)
						})}
					</>
				)}
				<ButtonWithLoader
					qaDataElementName="data-qa-list-of-suggestion-set-btn"
					loading={listOfSuggestionLoading}
					onClick={() => onSaveListSuggestionsClick()}
					disabled={isDataEqual}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</FlexBox>
		</div>
	)
}

export default memo(CustomizeListOfSuggestions)
