import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import TextField from '@material-ui/core/TextField'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import cx from 'classnames'

import { resetEditId } from 'global actions'

type IProps = {
	oldProjectName: string
	id: string
	onUpdateName: Function
	placeholder: string
}

const UpdateProjectName: FC<IProps> = ({
	oldProjectName,
	id,
	onUpdateName,
	placeholder
}) => {
	const [name, setInputName] = useState(oldProjectName)
	const dispatch = useDispatch()

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputName(e.target.value)
	}

	const onCancelClick = () => {
		setInputName('')
		dispatch(resetEditId())
	}

	const onConfirmClick = () => {
		dispatch(onUpdateName(id, name.trim(), oldProjectName))
		dispatch(resetEditId())
	}

	return (
		<div className="sidebar--projects-toolbar--edit-name">
			<div className="sidebar--projects-toolbar--edit-name--input">
				<TextField
					InputProps={{
						startAdornment: (
							<CheckIcon
								data-qa="data-qa-side-bar-new-folder-confirm-input-btn"
								onClick={onConfirmClick}
								className={cx('confirm-input', {
									hidden: !name.trim().length
								})}
							/>
						),
						endAdornment: (
							<ClearIcon
								onClick={onCancelClick}
								className={cx('clear-input')}
							/>
						)
					}}
					id="edit-name-input"
					type="text"
					value={name}
					placeholder={placeholder}
					margin="none"
					onChange={onChange}
					autoComplete="off"
					autoFocus
				/>
			</div>
		</div>
	)
}

export default UpdateProjectName
