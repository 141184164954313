import { isInteger } from 'lodash'
import Numeral from 'numeral'

/**
 * Convert a long amount to a short amount with a currency mark.
 * @param {string | undefined} num Optional. Amount
 * @param {string} format Format template for converting the amount..
 * @returns Short amount with a currency mark or 0.
 */
export const currencyFormatter = (
	num: number,
	format: string
): number | string => {
	return num ? Numeral(num).format(format).toUpperCase() : 0
}

export const getFixedSize = (size: number, fixed: number): string => {
	return isInteger(size) ? size.toFixed(0) : size.toFixed(fixed)
}

export const eulerToNormalVector = (
	alpha: number = 0,
	beta: number = 0,
	gamma: number = 0
): number[] => {
	const nx = Math.cos(beta) * Math.cos(gamma)
	const ny =
		Math.cos(alpha) * Math.sin(gamma) +
		Math.sin(alpha) * Math.sin(beta) * Math.cos(gamma)
	const nz =
		Math.sin(alpha) * Math.sin(gamma) -
		Math.cos(alpha) * Math.sin(beta) * Math.cos(gamma)
	return [nx, ny, nz]
}
