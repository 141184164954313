import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { isEmpty } from 'lodash'

import CostAnalysisTable from '.'
import {
	Feature,
	FeatureComponentId
} from '../../../../../../../../../../Services/models/Features'
import { Part } from '../../../../../../../../../../Services/models/IPart'
import { TraditionalManufacturingMethod } from '../../common'
import CostAnalysisTableButtons from './CostAnalysisTableButtons/CostAnalysisTableButtons'
import CostAnalysisTableSwitch from './CostAnalysisTableSwitch'
import Devider from 'Scenes/Components/Devider/Devider'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import Loader from 'Scenes/Loader/Loader'
import {
	CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { AssemblingParams } from 'Services/models/IConfiguration'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	traditionalMethod: string
	show: boolean
	openSwitch: boolean
	showAnalysisTable: boolean
	solution: any
	configuration: any
	onAnalysisTableSwitchChange: Function
	chainBenefits: any
	costLoading: boolean
	part: Part
	showSwitchButton: boolean
	showAssemblingCostAlert: boolean
	assemblingParams: AssemblingParams | null
	showTMSwitch: boolean
	partPrintIssues: PartPrintIssue[]
	toggleAssemblingCostModal: (id: number, open: boolean) => void
	onManufacturingMethodChange: Function
	isCluster: boolean
}

const CostAnalysisTableAndSwitch: FC<IProps> = ({
	traditionalMethod,
	solution,
	configuration,
	showAnalysisTable,
	show,
	openSwitch,
	onAnalysisTableSwitchChange,
	chainBenefits,
	costLoading,
	part,
	showSwitchButton,
	showAssemblingCostAlert,
	assemblingParams,
	showTMSwitch,
	partPrintIssues,
	toggleAssemblingCostModal,
	onManufacturingMethodChange,
	isCluster
}) => {

	if (!show) {
		return <div></div>
	}

	const costTabOn = Feature.isFeatureOn(FeatureComponentId.COST_TAB_INFORMATION)


	if (
		isCluster &&
		(!configuration.clusterCombinedCostDetails ||
			isEmpty(configuration.clusterCombinedCostDetails) ||
			!configuration.costResults)
	) {
		return (
			<div className="no-combined-cost-details-wrapper">
				<div className="no-combined-cost-details">
					<IconFactory iconName="info" />
					{getString('COST_ANALYSIS_NO_COMBINED_COST_DETAILS_WARNING_PART1')}
					<Link to={USER_HOME_ROUTE + CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE}>
						{getString(
							'COST_ANALYSIS_NO_ORIGINAL_DESIGN_SOLUTION_WARNING_PART2'
						)}
					</Link>
				</div>
			</div>
		)
	}

	return (
		<div
			className={`cost-comparison-tab--table ${
				showAnalysisTable ? 'show' : ''
			}`}
		>
			<Loader
				load={showTMSwitch && costLoading}
				wrapperClassName="cost-comparison-tab--table--loader"
			/>
			{!costTabOn && (
				<CostAnalysisTableButtons
					openSwitch={showAnalysisTable}
					onChange={onAnalysisTableSwitchChange}
					show={show}
				/>
			)}

			<CostAnalysisTable
				traditionalMethod={traditionalMethod}
				solution={solution}
				configuration={configuration}
				chainBenefits={chainBenefits}
				part={part}
				showAssemblingCostAlert={showAssemblingCostAlert}
				assemblingParams={assemblingParams}
				toggleAssemblingCostModal={toggleAssemblingCostModal}
			/>

			{costTabOn && showSwitchButton && openSwitch && (
				<CostAnalysisTableSwitch
					openSwitch={showAnalysisTable}
					onChange={onAnalysisTableSwitchChange}
					show={show}
					costLoading={costLoading}
				/>
			)}

			{showTMSwitch && (
				<>
					<Devider />
					<TraditionalManufacturingMethod
						isLeadTime={false}
						configuration={configuration}
						part={part}
						manufacturingMethod={traditionalMethod}
						onManufacturingMethodChange={onManufacturingMethodChange}
						partPrintIssues={partPrintIssues}
					/>
				</>
			)}
		</div>
	)
}

export default memo(CostAnalysisTableAndSwitch)
