import React, { ChangeEvent, FC, memo, useState } from 'react'
import { useDispatch } from 'react-redux'

import cx from 'classnames'

import { incrementTourStep } from '../../../MainPartAnalysis/MainPartAnalysisActions'
import {
	onConfigurationQuoteClick,
	onConfigureClick,
	onExport3dClick,
	onSetLeadingConfigurationClick,
	onWeightReductionButtonClick
} from '../../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import SolutionAnalysisHeaderButtons from '../../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisHeader/SolutionAnalysisHeaderButtons'
import SolutionPdfModal from '../../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisHeader/SolutionExportPdf/SolutionPdfModal'
import ConfigurationCombineAlert from '../../common/ConfigurationCombineAlert'
import {
	useAllData,
	useProjectReducer,
	useSolutionReducer
} from '../../PartAnalysisSelector'
import { CastorPartBenefits } from 'Scenes/Components/CastorPartBenefits/CastorPartBenefits'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import { format2D } from 'Scenes/Home/NewUploadProject/constants'
import { onToggleGrabCad } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import { onSendPartToProviderClick } from 'Scenes/IntegrationProvider/IntegrationProviderActions'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { benefitType } from 'Services/models/ISolutionBenefit'
import { IUserProvider } from 'Services/models/IUserProvider'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

interface ConfigurationTabButtonsProps {
	partSolution: any
	configuration: any
}

const ConfigurationTabButtons: FC<ConfigurationTabButtonsProps> = ({
	partSolution,
	configuration
}) => {
	const partAnalysis = useAllData()
	const { onlyPart } = useProjectReducer()

	const {
		part,
		cluster,
		sendToLoader,
		userProviders,
		showWeightReductionButton,
		isWeightReductionPart,
		freezeConfiguration,
		tourConfigurationId,
		currentStepTargetId,
		tourStepIndex,
		userEmail
	} = partAnalysis

	const partEntity = part || cluster

	const benefits = partSolution?.benefits || onlyPart?.benefits || []

	const {
		leadingByUserChoice,
		isLeading,
		loadingCalculation,
		isAmOriginalMaterial,
		usedOrganization
	} = useSolutionReducer(configuration.id)

	const dispatch = useDispatch()

	const [showModal, setShowModal] = useState(false)
	const [sameMaterial, setSameMaterial] = useState(true)
	const [showCombineAlert, setShowCombineAlert] = useState(false)

	const isChallenges =
		configuration.resultType === ConfigurationResultTypes.Challenges
	const showHeaderButtons =
		(Feature.isFeatureOn(FeatureComponentId.CONFIGURATION_HEADER) &&
			!isChallenges) ||
		false

	const customizeOrganizationsIsOn = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
	)

	const onCombineClick = () => {
		setShowCombineAlert(true)
	}

	const onConfigurationClick = (
		e: ChangeEvent<HTMLInputElement>,
		configurationId: number
	): void => {
		e.stopPropagation()
		dispatch(onConfigureClick(configurationId, tourConfigurationId, true))
	}

	const onGetAQClick = (
		e: ChangeEvent<HTMLInputElement>,
		btnElementId: string
	): void => {
		e.stopPropagation()
		dispatch(onConfigurationQuoteClick(configuration.id))
		if (currentStepTargetId === `#${btnElementId}`) {
			dispatch(incrementTourStep(tourStepIndex))
		}
	}
	const on3dExportClick = (e: ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation()
		dispatch(onExport3dClick(configuration.id, part, userProviders))
	}
	const onPrintingOptionClick = (
		e: ChangeEvent<HTMLInputElement>,
		userProvider: IUserProvider,
		providerPrinterId: number
	): void => {
		e.stopPropagation()
		dispatch(
			onSendPartToProviderClick(
				part,
				configuration,
				window.location.href,
				userProvider,
				providerPrinterId,
				userEmail
			)
		)
	}

	const onSetLeadingClick = (e: ChangeEvent<HTMLInputElement>): void => {
		e.stopPropagation()
		dispatch(
			onSetLeadingConfigurationClick(configuration.id, !leadingByUserChoice)
		)
	}
	const siteNameWithBorder =
		benefits.filter(
			(benefit: Record<string, any>) =>
				!(
					isAmOriginalMaterial &&
					(benefit.type === benefitType.costSaving ||
						benefit.type === benefitType.timeSaving ||
						benefit.type === benefitType.co2Saving)
				)
		).length > 0

	return (
		<div className="part-tabpanel--block">
			<div className="part-tabpanel--benefits-wrapper">
				{customizeOrganizationsIsOn && usedOrganization?.name && (
					<div
						className={cx('part-tabpanel--organization', {
							'with-border': siteNameWithBorder
						})}
						title={usedOrganization?.name}
					>
						{getString('REGISTER_FORM_ORGANIZATION_LABEL')}:{' '}
						<b>{usedOrganization?.name}</b>
					</div>
				)}
				{!isChallenges && (
					<div className="part-tabpanel--benefits">
						<CastorPartBenefits
							showHoverData={false}
							expandIconOnHover={false}
							showFullIcon={true}
							small={false}
							benefits={benefits}
							className={'solution-analysis-header-benefits'}
							showWeightBenefit={false}
							isAmOriginalMaterial={isAmOriginalMaterial}
						/>
					</div>
				)}
			</div>
			<div className="part-tabpanel--buttons">
				{showHeaderButtons && (
					<SolutionAnalysisHeaderButtons
						isNewDesign={true}
						loadingCalculation={loadingCalculation}
						setShowModal={setShowModal}
						isCluster={!!cluster || !!configuration?.cluster}
						canCombine={!configuration?.cluster && part?.canCombine}
						showSolutionDetails={true}
						configurationId={configuration.id}
						showConfigure={true}
						enableSolutionButtons={true}
						freezeConfiguration={freezeConfiguration}
						combineButtonText={getString('COMBINE')}
						disableRemove={true}
						leadingByUserChoice={leadingByUserChoice}
						isLeading={isLeading}
						solution={partSolution}
						quantity={configuration.quantity}
						showDownloadButton={isWeightReductionPart}
						partWeightReducedStlURL={part?.fileURL || cluster?.fileURL}
						showWeightReductionButton={showWeightReductionButton}
						userProviders={userProviders}
						sendToLoader={sendToLoader}
						onCombineClick={onCombineClick}
						onConfigureClick={onConfigurationClick}
						onGetAQClick={onGetAQClick}
						on3dExportClick={on3dExportClick}
						onPrintingOptionClick={onPrintingOptionClick}
						onRemoveClick={() => {}}
						onSetLeadingClick={onSetLeadingClick}
						onWeightReductionClick={() =>
							dispatch(
								onWeightReductionButtonClick(configuration.id, partSolution)
							)
						}
						showCrabCad={!part?.isDrawing}
						selectedGrabCad={!!configuration.grabCadPrintConfiguration}
						onToggleGrabCad={() => {
							dispatch(
								onToggleGrabCad(
									part.id,
									configuration.grabCadPrintConfiguration,
									configuration.id
								)
							)
						}}
					/>
				)}
				{showModal && (
					<SolutionPdfModal
						showModal={showModal}
						configurationId={configuration.id}
						setShowModal={setShowModal}
						isPartIn2dFormat={partEntity?.formatType === format2D}
						configurationOrganizationId={configuration.organizationId}
					/>
				)}
				{showCombineAlert && (
					<ConfigurationCombineAlert
						sameMaterial={sameMaterial}
						setSameMaterial={setSameMaterial}
						showCombineAlert={showCombineAlert}
						setShowCombineAlert={setShowCombineAlert}
						partId={partEntity.id}
						projectId={partEntity.projectId?.id || partEntity.projectId}
					/>
				)}
			</div>
		</div>
	)
}

export default memo(ConfigurationTabButtons)
