import {
	BIO_COMPATIBLE,
	DYEING,
	ESD_SUPPORTED,
	EU_REACH,
	FILTER_CORROSION_RESISTANT,
	FLAME_RETARDANT,
	FOOD_SAFE,
	FST,
	MACHINING,
	MATERIAL_TABLE_INFO,
	MATERIAL_TABLE_TEXTS,
	POLISHING,
	ROHS,
	SKIN_CONTACT,
	ULTRA_LOW_OUTGASSING
} from 'Services/Strings'

export const NUMBER = 'number'
export const MIN_VALUE = 0
export const MAX_PERCENTAGE_STDEV = 30

export const allowedCategories = ['Stainless Steels', 'Aluminum Alloys']

export const deviationPrinterMaterials = [
	{ name: 'youngsModulusExt', label: MATERIAL_TABLE_TEXTS.YOUNGS_MODULUS_EXT },
	{ name: 'poissonRatioExt', label: MATERIAL_TABLE_TEXTS.POISSON_RATIO_EXT },
	{ name: 'shearModulusExt', label: MATERIAL_TABLE_TEXTS.SHEAR_MODULUS_EXT },
	{
		name: 'ultimateTensileStrengthExt',
		label: MATERIAL_TABLE_TEXTS.ULTIMATE_TENSILE
	},
	{
		name: 'yieldStrengthMPaExt',
		label: MATERIAL_TABLE_TEXTS.YIELD_STRENGTH_EXT
	},
	{
		name: 'percentElongationAtBreakExt',
		label: MATERIAL_TABLE_TEXTS.ELONGATION
	}
]

export const specialMaterial = {
	name: 'specialMaterial',
	label: MATERIAL_TABLE_TEXTS.SPECIAL_MATERIAL,
	checkBoxes: [
		{ name: 'foodSafe', label: FOOD_SAFE, defaultChecked: false },
		{ name: 'bioCompatible', label: BIO_COMPATIBLE, defaultChecked: false },
		{ name: 'esd', label: ESD_SUPPORTED, defaultChecked: false },
		{ name: 'flameRetardant', label: FLAME_RETARDANT, defaultChecked: false },
		{ name: 'euReach', label: EU_REACH, defaultChecked: true },
		{ name: 'rohs', label: ROHS, defaultChecked: true },
		{ name: 'fst', label: FST, defaultChecked: false },
		{ name: 'skinContact', label: SKIN_CONTACT, defaultChecked: false },
		{
			name: 'ultraLowOutgassing',
			label: ULTRA_LOW_OUTGASSING,
			defaultChecked: false
		},
		{
			name: 'corrosionResistant',
			label: FILTER_CORROSION_RESISTANT,
			defaultChecked: false
		}
	]
}

export const additionalPostProcesses = {
	name: 'additionalPostProcesses',
	label: MATERIAL_TABLE_TEXTS.ADDITIONAL_POST_PROCESSES,
	checkBoxes: [
		{ id: 1, name: 'dyeing', label: DYEING },
		{ id: 2, name: 'polishing', label: POLISHING },
		{
			id: 4,
			name: 'machining',
			label: MACHINING,
			withSomeMetal: true,
			defaultValue: true
		}
	]
}

export const standardPrinterMaterials = [
	{ name: 'name', label: MATERIAL_TABLE_TEXTS.NAME },
	{ name: 'subCategory', label: 'Sub-Category Name' },
	{ name: 'printers', label: 'printers', isPrintersSelectedItems: true },
	{ name: 'selectedTechItems', label: '', isTechSelectedItems: true },
	{ name: 'selectedTypeItems', label: '', isTypeSelectedItems: true },
	{
		name: 'Category',
		label: MATERIAL_TABLE_TEXTS.CATEGORY,
		isCategorySelectedItems: true
	},
	{
		name: 'wallThickness',
		label: MATERIAL_TABLE_TEXTS.WALL_THICKNESS,
		type: NUMBER,
		iIcon: MATERIAL_TABLE_INFO.WALL_THICKNESS
	},
	{
		name: 'description',
		label: MATERIAL_TABLE_TEXTS.DESCRIPTION,
		multiline: true
	},
	{ name: 'pros', label: MATERIAL_TABLE_TEXTS.PROS, multiline: true },
	{ name: 'cons', label: MATERIAL_TABLE_TEXTS.CONS, multiline: true },
	{
		name: 'surfaceFinish',
		label: MATERIAL_TABLE_TEXTS.SURFACE_FINISH,
		isMetalOnly: true,
		type: NUMBER,
		hasStandardDeviation: true
	},
	{
		name: 'accuracy',
		label: MATERIAL_TABLE_TEXTS.ACCURACY,
		type: NUMBER,
		hasStandardDeviation: true
	},
	{
		name: 'maximumServiceTemperature',
		label: MATERIAL_TABLE_TEXTS.SERVICE_TEMPERATURE,
		type: NUMBER,
		hasStandardDeviation: true,
		unitSystem: true
	},
	{
		name: 'thermalConductivity',
		label: MATERIAL_TABLE_TEXTS.THERMAL_CONDUCTIVITY,
		type: NUMBER,
		hasStandardDeviation: true
	},
	{
		name: 'density',
		label: MATERIAL_TABLE_TEXTS.DENCITY,
		type: NUMBER,
		iIcon: MATERIAL_TABLE_INFO.DENCITY
	},
	{
		name: 'costPerCubicCM',
		label: MATERIAL_TABLE_TEXTS.COST.replace(`\{${0}\}`, '$'), // format('$') doesn't work for nested text object
		type: NUMBER,
		withSign: true,
		iIcon: MATERIAL_TABLE_INFO.COST
	},
	{
		name: 'co2perKgMaterial',
		label: MATERIAL_TABLE_TEXTS.CO2_PER_KG,
		type: NUMBER,
		iIcon: MATERIAL_TABLE_INFO.CO2_PER_KG
	}
]

export const checkErrorValues = [
	{ name: 'name', checkIfEmpty: true },
	{ name: 'subCategory', checkIfEmpty: true },

	{ name: 'type', checkIfEmpty: false },
	{ name: 'category', checkIfEmpty: true },
	{ name: 'printers', checkIfEmpty: true },
	{ name: 'printerCompany', checkIfEmpty: true },
	{ name: 'youngsModulus', checkIfEmpty: false },
	{ name: 'ultimateTensileStrength', checkIfEmpty: false },
	{ name: 'printerTechnologyName', checkIfEmpty: false },
	{ name: 'description', checkIfEmpty: true },
	{
		name: 'wallThickness',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0.1,
		max: 3.6
	},
	{ name: 'pros', checkIfEmpty: true },
	{ name: 'cons', checkIfEmpty: true },
	{
		name: 'costPerCubicCM',
		checkIfEmpty: true,
		min: 0.1,
		max: 15
	},

	{ name: 'poissonRatioExt', checkIfEmpty: false, checkNestedElements: true },
	{ name: 'youngsModulusExt', checkIfEmpty: false, checkNestedElements: true },
	{ name: 'shearModulusExt', checkIfEmpty: false, checkNestedElements: true },
	{
		name: 'ultimateTensileStrengthExt',
		checkIfEmpty: false,
		checkNestedElements: true
	},
	{
		name: 'yieldStrengthMPaExt',
		checkIfEmpty: false,
		checkNestedElements: true
	},
	{
		name: 'percentElongationAtBreakExt',
		checkIfEmpty: false,
		checkNestedElements: true
	},
	{
		name: 'density',
		checkIfEmpty: false,
		checkIfMinMax: true,
		min: 0.1,
		max: 10
	},
	{
		name: 'co2perKgMaterial',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0.1,
		max: 20
	}
]

export const initialPrinterMaterialState = {
	name: '',
	subCategory: '',
	type: '',
	category: '',
	printers: '',
	printerCompany: '',
	youngsModulusExt: '',
	ultimateTensileStrengthExt: '',
	yieldStrengthMPaExt: '',
	// printerTechnology: '',
	printerTechnologyName: '',
	description: '',
	wallThickness: '',
	costPerCubicCM: '',
	co2perKgMaterial: '',
	poissonRatioExt: '',
	density: '',
	heatTreatment: false,
	euReach: true,
	rohs: true
}

export const adminPrinterMaterialFormName = 'adminPrinterMaterialFormState'

export const poissonRatioExtDefaultValues = {
	metal: {
		X: { stdev: 0.05, val: 0.336 },
		Y: { stdev: 0.05, val: 0.336 },
		Z: { stdev: 0.05, val: 0.332 }
	},
	plastic: {
		X: { stdev: 0.05, val: 0.4 },
		Y: { stdev: 0.05, val: 0.4 },
		Z: { stdev: 0.05, val: 0.4 }
	}
}

export const shearModulusExtDefaultValues = {
	metal: {
		X: { stdev: 5, val: 50 },
		Y: { stdev: 5, val: 50 },
		Z: { stdev: 5, val: 50 }
	},
	plastic: {
		X: { stdev: 0.01, val: 0.11 },
		Y: { stdev: 0.01, val: 0.11 },
		Z: { stdev: 0.01, val: 0.11 }
	}
}
