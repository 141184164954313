import { FC, memo } from 'react'

import cx from 'classnames'

import ProjectPartItem from './ProjectPartItem/ProjectPartItem'
import { History } from 'history'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorLockedParts from 'Scenes/Components/CastorLockedParts'
import { format2D } from 'Scenes/Home/NewUploadProject/constants'
import ProjectAnalysisActivities from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectActivities/ProjectAnalysisActivities'
import { InapplicableStatus } from 'Services/Constants'
import { Part } from 'Services/models/IPart'
import { PartProperties } from 'Services/models/IPartPropertiest'
import { getString } from 'Services/Strings/StringService'

interface Props {
	amountOfLockedParts: string
	partResultTitle: string
	part: Part
	showStandardCostFinishIcon: boolean
	showExportButton: boolean
	history: History
	userEmail: string
	lightUser: boolean
	contactUsEmail: string
	onStandardCostClick: Function
	onRemoveProjectClick: Function
	onExportClick: Function
	starPartClicked: (e: React.MouseEvent<SVGSVGElement>) => void
	onRemovePartClick: Function
	onRemovePartConfirm: Function
	onPartClick: Function
	onPartPropertiesClick: Function
	is2dProject: boolean
	allPartsNotCostEffective: boolean
}

const SinglePartProjectContent: FC<Props> = ({
	amountOfLockedParts,
	partResultTitle,
	part,
	history,
	starPartClicked,
	onRemovePartClick,
	onStandardCostClick,
	showStandardCostFinishIcon,
	showExportButton,
	userEmail,
	lightUser,
	contactUsEmail,
	onExportClick,
	onRemoveProjectClick,
	onRemovePartConfirm,
	onPartClick,
	onPartPropertiesClick,
	is2dProject,
	allPartsNotCostEffective
}) => {
	if (amountOfLockedParts) {
		return (
			<CastorLockedParts
				amountOfLockedParts={amountOfLockedParts}
				lightUser={lightUser}
				contactUsEmail={contactUsEmail}
			/>
		)
	}

	const isInapplicable =
		part.isDrawing && part.drawingStatusCode === InapplicableStatus

	return (
		<div className="single-part-project">
			<div className="single-part-project--actions">
				<div className="single-part-project--actions--result">
					<div className="single-part-project--actions--result--text">
						{partResultTitle}
					</div>
					{!isInapplicable && (
						<ButtonWithLoader
							color="primary"
							onClick={onPartClick}
							qaDataElementName={'data-qa-printing-configuration-button'}
						>
							{getString('PARTS_GO_TO_CONFIGURATION')}
						</ButtonWithLoader>
					)}
				</div>
				<div className="single-part-project--actions--activities">
					<ProjectAnalysisActivities
						disableProperties
						is2dProject={is2dProject}
						isInapplicable={isInapplicable}
						showStandardCostFinishIcon={showStandardCostFinishIcon}
						showExportButton={showExportButton}
						onStandardCostClick={onStandardCostClick}
						onRemoveProjectClick={onRemoveProjectClick}
						onExportClick={onExportClick}
						deleteButtonClassName="single-part-project--actions--delete"
						deleteButtonIconClassName="single-part-project--actions--delete--icon"
						deleteButtonText={getString('DELETE')}
						onPartPropertiesClick={() => onPartPropertiesClick(part.projectId)}
						allPartsNotCostEffective={allPartsNotCostEffective}
					/>
				</div>
			</div>
			<div className="single-part-project--part">
				<ProjectPartItem
					part={part}
					cardClassName="single-part-project--part--card"
					cardSizeWrapperClassName={cx(
						'single-part-project--part--card--size-wrapper project-result',
						{ drawing: part?.formatType === format2D }
					)}
					starPartClicked={starPartClicked}
					onRemovePartClick={onRemovePartClick}
					onRemovePartConfirm={onRemovePartConfirm}
					onPartClick={onPartClick}
				/>
			</div>
		</div>
	)
}

export default memo(SinglePartProjectContent)
