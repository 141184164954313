import React, { useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import AddIcon from '@material-ui/icons/Add'

import { getString } from '../../../../Services/Strings/StringService'
import { getThemeString } from '../../../../themes/getThemeString'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import { goBackToRelatedCastor2 } from '../CustomizeFormCommon'
import { setupInHousePrinters } from '../CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import AddMultipleMaterialsPopup from '../CustomizeMaterialNamesMapping/AddMultipleMaterialsPopup'
import OrganizationPanel from '../OrganizationPanel/OrganizationPanel'
import {
	openMaterialsNamePopup,
	sendMaterialsNameBom,
	setupUserMaterials,
	uploadMaterialsNameBom
} from './CustomizeUserMaterialsActions'
import CustomizeUserPrinterMaterialsTable from './NewAMMaterial/CustomizeUserPrinterMaterialsTable'
import PopupMaterial from './NewOrCopyMaterials/PopupMaterial'
import PopupPrinter from './NewOrCopyMaterials/PopupPrinter'
import CustomizeUserPrintersTable from './NewPrinter/CustomizeUserPrintersTable'
import CustomizeUserMaterialsTable from './NewTMMaterial/CustomizeUserMaterialsTable'

import './CustomizeUserMaterials.css'

const CustomizeUserMaterials: React.FC = () => {
	const [showAMMaterial, setShowAMMaterial] = React.useState<boolean>(false)
	const [showTMMaterial, setShowTMMaterial] = React.useState<boolean>(false)
	const [showPrinter, setShowPrinter] = React.useState<boolean>(false)

	const dispatch = useDispatch()
	const history = useHistory()
	const match = useRouteMatch()

	const {
		organizationId,
		isOpenMaterialsNamePopup,
		BOMMaterialsFile,
		adminSelectedOrganizationId,
		fileInputKey,
		isMaterialsNameLoading,
		printersCompanies
	} = useSelector((state: RootStateOrAny) => ({
		userId: state.CustomizeUserMaterialsReducer.userId,
		isOpenMaterialsNamePopup:
			state.CustomizeUserMaterialsReducer.isOpenMaterialsNamePopup,
		BOMMaterialsFile: state.CustomizeUserMaterialsReducer.BOMMaterialsFile,
		fileInputKey: state.CustomizeReducer.fileInputKey,
		isMaterialsNameLoading: state.CustomizeReducer.isMaterialsNameLoading,
		adminSelectedOrganizationId:
			state.CustomizeReducer.adminSelectedOrganizationId,
		organizationId: state.auth.organizationId,
		printersCompanies: state.CustomizeInHousePrintersReducer.printersCompanies
	}))

	const onUploadMaterialsNameBom = (file: File) =>
		dispatch(uploadMaterialsNameBom(file))

	useEffect(() => {
		dispatch(setupUserMaterials(match))
	}, [dispatch, match])

	useEffect(() => {
		dispatch(
			setupInHousePrinters(
				printersCompanies,
				match,
				organizationId || adminSelectedOrganizationId
			)
		)
	}, [])

	const renderFormHeader = () => (
		<div className="customize-settings--header">
			<CastorFormHeader
				goBack={() => history.push(goBackToRelatedCastor2())}
				explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
			<OrganizationPanel />
		</div>
	)

	const onCancelMultipleMaterials = () => {
		dispatch(openMaterialsNamePopup(false))
		dispatch(uploadMaterialsNameBom(null))
	}

	const onConfirmMultipleMaterials = () => {
		dispatch(
			sendMaterialsNameBom(BOMMaterialsFile, adminSelectedOrganizationId)
		)
	}

	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<CastorForm
				formHeader={renderFormHeader()}
				formTitle={getString('CUSTOMIZE_FORM_USER_MATERIAL_HEADER')}
				content={
					<div>
						<CastorFormHeader
							explanationHeader={getString(
								'CUSTOMIZE_TRADITIONAL_MANUFACTURING_MATERIALS_TITLE'
							)}
							explanationArray={getThemeString(
								'CUSTOMIZE_USER_MATERIAL_EXPLANATION_ARRAY'
							)}
							isInCard={true}
						/>
						<div className="new-material-btn">
							<div className="new-material-link">
								<Button
									color="primary"
									className="customize-user-btn"
									onClick={() => dispatch(openMaterialsNamePopup(true))}
									data-qa="data-qa-add-multiple-materials-btn"
								>
									<AddIcon />
									{getString('ADD_MULTIPLE_MATERIALS')}
								</Button>
							</div>
							<div className="new-material-link">
								<Button
									data-qa="data-qa-add-new-user-original-material-btn"
									color="primary"
									className="customize-user-btn"
									onClick={() => setShowAMMaterial(true)}
								>
									<AddIcon />
									{getString('USER_MATERIAL_ADD_NEW')}
								</Button>
							</div>
						</div>

						<CustomizeUserMaterialsTable />

						<CastorFormHeader
							explanationHeader={getString(
								'CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_TITLE'
							)}
							explanationArray={getThemeString(
								'CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_EXPLANATION_ARRAY'
							)}
							isInCard={true}
						/>
						<div className="new-material-btn">
							<div className="new-material-link">
								<Button
									data-qa="data-qa-add-new-user-additive-material-btn"
									color="primary"
									className="customize-user-btn"
									onClick={() => setShowTMMaterial(true)}
								>
									<AddIcon />
									{getString('USER_MATERIAL_ADD_NEW')}
								</Button>
							</div>
						</div>
						<CustomizeUserPrinterMaterialsTable />
						<CastorFormHeader
							explanationHeader={getString('CUSTOMIZE_3D_PRINTERS_TITLE')}
							explanationArray={getThemeString(
								'CUSTOMIZE_USER_3D_PRINTERS_EXPLANATION_ARRAY'
							)}
							isInCard={true}
						/>
						<div className="new-material-btn">
							<div className="new-material-link">
								<Button
									data-qa="data-qa-add-new-user-printer-btn"
									color="primary"
									className="customize-user-btn"
									onClick={() => setShowPrinter(true)}
								>
									<AddIcon />
									{getString('USER_PRINTER_ADD_NEW')}
								</Button>
							</div>
						</div>
						<CustomizeUserPrintersTable />
						<AddMultipleMaterialsPopup
							isOpen={isOpenMaterialsNamePopup}
							onConfirmMultipleMaterials={onConfirmMultipleMaterials}
							onCancelMultipleMaterials={onCancelMultipleMaterials}
							fileInputKey={fileInputKey}
							BOMMaterialsFile={BOMMaterialsFile}
							isMaterialsNameLoading={isMaterialsNameLoading}
							isMaterialsNames={false}
							isUserMaterialsNames={true}
							uploadBom={onUploadMaterialsNameBom}
						/>
					</div>
				}
			/>
			<PopupPrinter show={showPrinter} setShow={setShowPrinter} />
			<PopupMaterial show={showAMMaterial} setShow={setShowAMMaterial} />
			<PopupMaterial
				isAmMaterial={true}
				show={showTMMaterial}
				setShow={setShowTMMaterial}
			/>
		</NavBarAndMaterial>
	)
}

export default CustomizeUserMaterials
