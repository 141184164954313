import {
	ADD_MATERIALS_BOM_FILE,
	ADD_MATERIALS_BOM_LOADING,
	ADD_MATERIALS_BOM_POPUP,
	CHANGE_ORGANIZATION_NAME,
	CHANGE_ORGANIZATION_PRIVACY,
	CLOSE_CUSTOM_FUNCTIONS_ALERT,
	COST_AND_LEAD_TBL_DATA_ARRANGED,
	RESET_SETTINGS_STATE,
	SELECT_ORGANIZATION_TO_CUSTOMIZE,
	UPDATE_ORGANIZATION_INFO_ERROR,
	USER_DESKTOP_MATERIAL_TOGGLED,
	USER_UNIT_SYSTEM_SELECTED,
	USER_UNITS_TYPE_SELECTED
} from '../../../global actions/types'
import { getSavedOrganizationId } from './CustomizeLogic'

const INITIAL_STATE = {
	desktopPrinter: false,
	isShowSemiProfessionalPrintersToggle: false,
	BOMMaterialsFile: null,
	isMaterialsNameLoading: false,
	isOpenMaterialsNamePopup: false,
	fileInputKey: Date.now(),
	selectedUnitsType: '',
	selectedUnitSystem: '',
	organizationName: '',
	organizationNameError: '',
	organizationIsPrivate: true,
	adminSelectedOrganizationId: getSavedOrganizationId(),
	showCustomFunctionsWarning: false
}

const customizeReducer = (state = INITIAL_STATE, { type, payload }) => {
	switch (type) {
		case USER_DESKTOP_MATERIAL_TOGGLED:
			return {
				...state,
				desktopPrinter: !state.desktopPrinter
			}
		case USER_UNITS_TYPE_SELECTED: {
			const { unitsType } = payload
			return {
				...state,
				selectedUnitsType: unitsType
			}
		}
		case USER_UNIT_SYSTEM_SELECTED: {
			const { unitSystem, unitsType, organizationHasCustomFunctions } = payload
			return {
				...state,
				selectedUnitSystem: unitSystem,
				selectedUnitsType: unitsType,
				showCustomFunctionsWarning: organizationHasCustomFunctions
			}
		}
		case COST_AND_LEAD_TBL_DATA_ARRANGED:
			const {
				originalUserSettingsValues: { desktopPrinter },
				isShowSemiProfessionalPrintersToggle,
				selectedUnitsType,
				tolerancePricesArranged,
				selectedUnitSystem
			} = payload
			return {
				...state,
				desktopPrinter,
				isShowSemiProfessionalPrintersToggle,
				selectedUnitsType,
				tolerancePricesArranged,
				selectedUnitSystem
			}
		case ADD_MATERIALS_BOM_FILE: {
			return {
				...state,
				BOMMaterialsFile: payload
			}
		}
		case ADD_MATERIALS_BOM_LOADING: {
			return {
				...state,
				isMaterialsNameLoading: payload,
				...(!payload && { isOpenMaterialsNamePopup: false })
			}
		}
		case ADD_MATERIALS_BOM_POPUP: {
			return {
				...state,
				isOpenMaterialsNamePopup: payload
			}
		}
		case CHANGE_ORGANIZATION_NAME: {
			const { name, organizationNameError } = payload
			return {
				...state,
				organizationName: name,
				organizationNameError
			}
		}
		case CHANGE_ORGANIZATION_PRIVACY: {
			return {
				...state,
				organizationIsPrivate: payload
			}
		}
		case UPDATE_ORGANIZATION_INFO_ERROR: {
			return {
				...state,
				organizationNameError: payload
			}
		}
		case SELECT_ORGANIZATION_TO_CUSTOMIZE: {
			const { organizationId } = payload
			return {
				...state,
				adminSelectedOrganizationId: organizationId
			}
		}
		case RESET_SETTINGS_STATE: {
			return { ...INITIAL_STATE, adminSelectedOrganizationId: '' }
		}
		case CLOSE_CUSTOM_FUNCTIONS_ALERT: {
			return { ...state, showCustomFunctionsWarning: false }
		}
		default:
			return state
	}
}

export default customizeReducer
