import { Action } from '../../../../global actions/ActionModels'
import {
	GET_LICENSE_INFORMATION,
	GET_LICENSE_INFORMATION_ERROR,
	GET_LICENSE_INFORMATION_SUCCESS,
	UPDATE_CONFIGURATION_PROVIDER,
	UPLOAD_LICENSE_FILE_END,
	UPLOAD_LICENSE_FILE_FINISH,
	UPLOAD_LICENSE_FILE_START,
	UPLOAD_LICENSE_SCRIPT_SUCCESS
} from '../../../../global actions/types'

export interface CastorAlertInitialState {
	readonly licenseInfo: Record<string, any>
	readonly licenseData: Record<string, any>
	readonly loading: boolean
	readonly loadingSSO: boolean
	readonly dataLoading: boolean
	readonly error: string
}

const initialState: CastorAlertInitialState = {
	error: '',
	loading: false,
	dataLoading: false,
	loadingSSO: false,
	licenseInfo: {},
	licenseData: {}
}
const AdminLicenseScreenReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case GET_LICENSE_INFORMATION:
			return {
				...state,
				loading: true
			}
		case GET_LICENSE_INFORMATION_ERROR:
			return {
				...state,
				error: payload.error ?? state.error,
				loading: false
			}

		case GET_LICENSE_INFORMATION_SUCCESS:
			return {
				...state,
				error: '',
				loading: false,
				licenseInfo: payload.licenseInfo ?? state.licenseInfo,
				licenseData: payload.licenseData ?? state.licenseData
			}

		case UPLOAD_LICENSE_SCRIPT_SUCCESS:
			return {
				...state,
				error: '',
				loading: false,
				licenseData: payload
			}

		case UPLOAD_LICENSE_FILE_START:
			return {
				...state,
				dataLoading: true
			}

		case UPLOAD_LICENSE_FILE_FINISH:
		case UPLOAD_LICENSE_FILE_END:
			return {
				...state,
				dataLoading: false
			}

		case UPDATE_CONFIGURATION_PROVIDER: {
			return {
				...state,
				loadingSSO: payload
			}
		}	

		default:
			return state
	}
}
export default AdminLicenseScreenReducer
