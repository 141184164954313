import React, { FC, memo } from 'react'

import DetailsPopup from '../DetailsPopup'
import IconFactory from '../StarIcon/IconFactory'

import './infoBox.scss'

interface IProps {
	boxContact?: string | React.ReactNode
	boxContactElement?: any
	boxClassName?: string
	boxContactClassName?: string
	iconClassName?: string
	targetClassName?: string
	managerClassName?: string
	iconName?: string
	icon?: any
	shouldPreventOverflow?: boolean
	inPortal?: boolean
	boxDirection?:
		| 'auto'
		| 'top'
		| 'right'
		| 'bottom'
		| 'left'
		| 'auto-start'
		| 'auto-end'
		| 'top-start'
		| 'right-start'
		| 'bottom-start'
		| 'left-start'
		| 'top-end'
		| 'right-end'
		| 'bottom-end'
		| 'left-end'
	dataQaTarget?: string
}

const InfoBox: FC<IProps> = ({
	boxContact,
	boxDirection,
	boxClassName,
	boxContactClassName,
	boxContactElement,
	icon,
	iconName,
	iconClassName,
	targetClassName,
	managerClassName,
	shouldPreventOverflow,
	inPortal,
	dataQaTarget
}) => {
	if (!boxContactElement && !boxContact) {
		return <div></div>
	}
	const renderBoxData = (boxContact: any) => {
		return <div className="info-box-data">{boxContact}</div>
	}
	return (
		<DetailsPopup
			isHover={true}
			data={boxContactElement || renderBoxData(boxContact)}
			popperDirection={boxDirection || 'auto-start'}
			popperClassName={boxClassName || 'info-box-wrapper'}
			popperContactClassName={boxContactClassName || 'info-box-data'}
			targetClassName={targetClassName}
			managerClassName={managerClassName}
			shouldPreventOverflow={shouldPreventOverflow}
			inPortal={inPortal}
		>
			{icon || (
				<IconFactory
					iconName={iconName || 'info'}
					className={`info-box-icon ${iconClassName}`}
					data-qa={dataQaTarget}
				/>
			)}
		</DetailsPopup>
	)
}
export default memo(InfoBox)
