import { FC, memo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ProjectAnalysisPartsView } from '../ProjectAnalysisInterfaces'
import { styles } from './style'
import {
	changeFilterKey,
	changeSearchPhrase
} from 'Scenes/Components/FilterPartsGrid/FilterPartsAction'
import FiltersForParts from 'Scenes/Components/FilterPartsGrid/FiltersForParts'
import FilterTags from 'Scenes/Components/FilterPartsGrid/FilterTags'
import Flexbox from 'Scenes/Components/FlexBox'
import SearchBar from 'Scenes/Components/SearchBar/SearchBar'
import ProjectAnalysisActivities from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectActivities/ProjectAnalysisActivities'
import {
	exportPartsToExcel,
	onPartPropertiesClick,
	onRemoveProjectClick,
	onStandardCostClick
} from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import SwitchView from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectMultipleParts/SwitchView/SwitchView'
import { Part } from 'Services/models/IPart'
import { Project } from 'Services/models/IProject'
import { PARTS } from 'Services/Strings'

interface PartsHeaderProps {
	showStandardCostFinishIcon: boolean
	showExportButton: boolean
	project: Project
	parts: Part[]
	weightReducedParts: Part[]
	is2dProject: boolean
	bundleId?: string
	allPartsCount: number
	standalonePartsCount?: number
	allPartsInapplicable: boolean
	allPartsNotCostEffective: boolean
	searchPhrase: string
	projectId: string
	selectedFilterPart: string
	selectedFilterValues: string[]
	assemblyProjects?: Project[]
	partsView: ProjectAnalysisPartsView
	totalPartsCount: number
	page: number
	limit: number
	onExportPDFClick: () => void
	refetchParts: boolean
}

const PartsHeader: FC<PartsHeaderProps> = ({
	showStandardCostFinishIcon,
	showExportButton,
	project,
	is2dProject,
	allPartsCount,
	standalonePartsCount = 0,
	allPartsInapplicable,
	allPartsNotCostEffective,
	searchPhrase,
	projectId,
	selectedFilterPart,
	selectedFilterValues,
	bundleId,
	assemblyProjects,
	partsView,
	totalPartsCount,
	page,
	limit,
	onExportPDFClick,
	parts,
	refetchParts
}) => {
	const history = useHistory()
	const dispatch = useDispatch()

	const filteredCount = allPartsInapplicable ? 0 : totalPartsCount
	const theSameLength = allPartsCount === filteredCount
	const partsHeaderRef = useRef(null)

	return (
		<Flexbox
			width="100%"
			flexDirection="column"
			alignItems="flex-start"
			innerRef={partsHeaderRef}
		>
			<div style={styles.filterDiv}>
				<h4 className="parts-header--title">
					{PARTS} (
					{theSameLength ? (
						<span data-qa="data-qa-total-parts-count">{filteredCount}</span>
					) : (
						<>
							<span data-qa="data-qa-filtered-parts-count">
								{filteredCount}
							</span>
							/<span data-qa="data-qa-total-parts-count">{allPartsCount}</span>
						</>
					)}
					)
				</h4>
				<div style={styles.filterDiv}>
					<div style={styles.flexDiv}>
						<SearchBar
							value={searchPhrase}
							onChange={(value: string) => dispatch(changeSearchPhrase(value))}
							className="parts-header--search-bar"
						/>
						<SwitchView
							parts={parts}
							partsView={partsView}
							allPartsInapplicable={allPartsInapplicable}
						/>
					</div>
					<div style={styles.flexDiv}>
						<ProjectAnalysisActivities
							isAssembly
							is2dProject={is2dProject}
							allPartsInapplicable={allPartsInapplicable}
							allPartsNotCostEffective={allPartsNotCostEffective}
							showStandardCostFinishIcon={showStandardCostFinishIcon}
							showExportButton={showExportButton}
							onStandardCostClick={() => dispatch(onStandardCostClick())}
							onRemoveProjectClick={() =>
								dispatch(onRemoveProjectClick(project, history))
							}
							onExportClick={() =>
								dispatch(
									exportPartsToExcel(projectId, selectedFilterPart, bundleId)
								)
							}
							onPartPropertiesClick={() =>
								dispatch(onPartPropertiesClick(project.id, undefined, true))
							}
							onExportPDFClick={onExportPDFClick}
							exportPDFDisabled={filteredCount === 0}
						/>
					</div>
				</div>
			</div>
			<div style={styles.filterDivStart}>
				<FiltersForParts
					is2dProject={is2dProject}
					allPartsCount={allPartsCount}
					standalonePartsCount={standalonePartsCount}
					searchPhrase={searchPhrase}
					selectedFilterValues={selectedFilterValues}
					assemblyProjects={assemblyProjects}
					page={page}
					limit={limit}
					refetchParts={refetchParts}
				/>
				<FilterTags
					handleChange={(value: string) =>
						dispatch(changeFilterKey(value, !!assemblyProjects))
					}
					selectedFilterValues={selectedFilterValues}
					assemblyProjects={assemblyProjects}
				/>
			</div>
		</Flexbox>
	)
}

export default memo(PartsHeader)
