import { FC, memo } from 'react'

import CustomizeToleranceCostTbl from './CustomizeToleranceCostTbl'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const CustomizeToleranceCost: FC = () => {
	return (
		<div className="tolerance-cost" data-qa="data-qa-tolerance-cost">
			<CastorFormHeader
				explanationHeader={getString('CUSTOMIZE_TOLERANCE_COST_TITLE')}
				explanationArray={getString('CUSTOMIZE_TOLERANCE_COST_EXPLANATION')}
				isInCard={true}
			/>
			<CustomizeToleranceCostTbl />
		</div>
	)
}
export default memo(CustomizeToleranceCost)
