import { memo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import ExtensionIcon from '@material-ui/icons/Extension'
import cx from 'classnames'

import { ICluster } from '../../../../../Services/models/ICluster'
import { adminClusterPartsRoute } from '../../../../../Services/routeFuncs'
import {
	ADMIN_PARTS_AREA,
	ADMIN_PARTS_CLUSTER_PARTS_NO,
	ADMIN_PARTS_ORIGINAL_MATERIAL,
	ADMIN_PARTS_PART_ID,
	ADMIN_PARTS_RESULT,
	ADMIN_PARTS_SIZE,
	ADMIN_PARTS_STATUS,
	ADMIN_PARTS_VOLUME,
	PART_ANALYSIS_REMOVE,
	PARTS
} from '../../../../../Services/Strings'
import ButtonWithLoader from '../../../../Components/ButtonWithLoader'
import CastorForm from '../../../../Components/CastorForm/CastorForm'
import IconFactory from '../../../../Components/StarIcon/IconFactory'
import {
	Button,
	Table
} from '../../../../Components/thirdParty/CreativeTim/components'
import Flexbox from 'Scenes/Components/FlexBox'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import Loader from 'Scenes/Loader/Loader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { PartStatus } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'
import {
	LengthUnit,
	UnitsConversionService,
	UnitSystem
} from 'Services/UnitsConversionService'

import './AdminClusters.scss'

const ExtensionIconTSX: any = ExtensionIcon
const LinkTSX: any = Link
const ButtonTSX: any = Button

const clustersTableHead = ['', 'Cluster Information', 'Results', 'Actions']

interface IProps {
	clusters: ICluster[]
	projectId: string
	publishAllClustersLoading: boolean
	setMaxHeightForClustersTable: boolean
	projectHasMoreClusters: boolean
	publishClusterLoaders: any
	rejectClusterLoaders: any
	removeClusterLoaders: any
	projectClusterHeaderText: string
	publishAllClusters: Function
	getMoreProjectClusters: Function
	onPublishClusterClick: Function
	onRejectClusterClick: Function
	onClusterStructureClick: Function
	onRemoveClusterClick: Function
}

export default memo(function AdminClusters({
	clusters,
	projectId,
	projectClusterHeaderText,
	projectHasMoreClusters,
	setMaxHeightForClustersTable,
	getMoreProjectClusters,
	onRemoveClusterClick,
	removeClusterLoaders
}: IProps) {
	const user = useSelector((state: RootStateOrAny) => state.user)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const userUnitSystem = customizeUnitSystem
		? user.userUnitSystem
		: UnitSystem.metric
	const userConversionService = new UnitsConversionService(
		UnitSystem.metric,
		userUnitSystem
	)
	const lengthUnitType =
		userUnitSystem === UnitSystem.metric ? LengthUnit.mm : LengthUnit.inch

	const getStatusClassName = (status: PartStatus) => {
		switch (status) {
			case PartStatus.complete:
			case PartStatus.published: {
				return PartStatus.published
			}
			case PartStatus.failed:
			case PartStatus.rejected: {
				return PartStatus.failed
			}
			default: {
				return PartStatus.awaitingAnalysis
			}
		}
	}

	const renderClusterButtons = (cluster: ICluster) => (
		<Flexbox flexDirection="column" gap="10px" key={cluster.id}>
			<ButtonWithLoader
				onClick={() => onRemoveClusterClick(cluster.id)}
				className="admin-clusters--action-button"
				style={{ width: '60%' }}
				color="primary"
				loading={removeClusterLoaders[cluster.id]}
			>
				<IconFactory iconName="clear" />
				{PART_ANALYSIS_REMOVE}
			</ButtonWithLoader>
			<LinkTSX to={adminClusterPartsRoute(cluster.projectId, cluster.id)}>
				<ButtonTSX className="admin-clusters--action-button" color="primary">
					<ExtensionIconTSX />
					{PARTS}
				</ButtonTSX>
			</LinkTSX>
		</Flexbox>
	)

	const renderClusterResults = (cluster: ICluster) => {
		const statusClassName = getStatusClassName(cluster.status)
		return (
			<div key={cluster.id}>
				<p>
					<span>{ADMIN_PARTS_STATUS}</span>:{' '}
					<span
						className={cx('admin-projects--project-status', statusClassName)}
					>
						{cluster.status}
					</span>
				</p>
				<p>
					<span>{ADMIN_PARTS_RESULT}</span>: {cluster.result}
				</p>
				<p>
					<span>{ADMIN_PARTS_ORIGINAL_MATERIAL}</span>:{' '}
					{`${cluster.material?.name || ''}`}
				</p>
			</div>
		)
	}

	const renderClusterInfo = (cluster: ICluster) => {
		const clusterWidth = userConversionService.convertLength(cluster.width)
		const clusterHeight = userConversionService.convertLength(cluster.height)
		const clusterDepth = userConversionService.convertLength(cluster.depth)
		const clusterVolume = userConversionService.convertVolume(cluster.volume)
		const clusterArea = userConversionService.convertArea(cluster.area)

		return (
			<div key={cluster.id}>
				<p>
					<span>{ADMIN_PARTS_PART_ID}</span>: {cluster.id}
				</p>
				<p>
					<span>{ADMIN_PARTS_SIZE}</span>:{' '}
					{`${clusterWidth} * ${clusterHeight} * ${clusterDepth} ${lengthUnitType}`}
				</p>
				<p>
					<span>{ADMIN_PARTS_VOLUME}</span>:{' '}
					{`${clusterVolume} ${lengthUnitType}³`}
				</p>
				<p>
					<span>{ADMIN_PARTS_AREA}</span>: {`${clusterArea} ${lengthUnitType}²`}
				</p>
				<p>
					<span>{ADMIN_PARTS_CLUSTER_PARTS_NO}</span>:{' '}
					{`${cluster.compositionSetParts?.length}`}
				</p>
			</div>
		)
	}

	const renderClusterImage = (cluster: ICluster) => (
		<PartImageWithFallback
			src={cluster.imageURL}
			alt={cluster.partNumber}
			className="admin-clusters--image"
			key={cluster.id}
		/>
	)

	const renderClustersTableData = () => {
		return clusters.map(cluster => {
			return [
				renderClusterImage(cluster),
				renderClusterInfo(cluster),
				renderClusterResults(cluster),
				renderClusterButtons(cluster)
			]
		})
	}

	const renderClusterHeaderText = () => (
		<h4 className="align-start">
			<span>{getString('ADMIN_PARTS_CLUSTER_HEADER_TEXT_1')} </span>
			{projectClusterHeaderText}
		</h4>
	)

	const renderClustersTable = () => {
		if (!clusters.length) {
			return renderClusterHeaderText()
		}
		return (
			<div>
				{renderClusterHeaderText()}
				<InfiniteScroll
					dataLength={clusters.length}
					next={() => getMoreProjectClusters(projectId, clusters.length)}
					hasMore={projectHasMoreClusters}
					loader={
						<Loader
							load={true}
							size={50}
							showFlex={false}
							wrapperClassName="admin-parts--parts-table--loader"
						/>
					}
					height={setMaxHeightForClustersTable ? 1200 : 'unset'}
				>
					<Table
						tableHead={clustersTableHead}
						tableData={renderClustersTableData()}
						className="admin-projects--table clusters-table"
					/>
				</InfiniteScroll>
			</div>
		)
	}

	return (
		<CastorForm
			formTitle={'Unifications'}
			formSubTitle="List of all project unification options"
			content={renderClustersTable()}
			style={{ maxWidth: 'unset' }}
		/>
	)
})
