import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { ADD_PRINTER_IDS, STEP_ID } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import AddPrinterButton from './AddPrinterButton'
import { resetData } from 'Scenes/Home/Customize/CustomizeUserMaterials/CustomizeUserMaterialsActions'
import CustomizeUserPrintersTable from 'Scenes/Home/Customize/CustomizeUserMaterials/NewPrinter/CustomizeUserPrintersTable'

const AddPrinterComponents = {
	[ADD_PRINTER_IDS.ADD_PRINTER_BUTTON]: <AddPrinterButton />,
	[ADD_PRINTER_IDS.PRINTERS_TABLE]: (
		<CustomizeUserPrintersTable showEdit={false} />
	)
}

const AddPrinter: FC = () => {
	const { steps, parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { userPrinters: customUserPrinters, userId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeUserMaterialsReducer
	)
	const { userPrinters } = useSelector((state: RootStateOrAny) => state.user)

	const { step, stepContent, childSteps } = useStep(
		steps,
		STEP_ID.ADD_PRINTER,
		true
	)
	const dispatch = useDispatch()

	const changesInCurrentStep = userId
		? customUserPrinters.length > 0
		: userPrinters.length > 0

	const onContinueClick = () => {
		const stepIndexInChildSteps =
			childSteps && childSteps.findIndex(childStep => childStep.id === step.id)
		const stepID =
			stepIndexInChildSteps !== childSteps.length - 1
				? childSteps[stepIndexInChildSteps + 1].id
				: undefined
		dispatch(goToNextStep(parentSteps, currentStep, stepID))
	}

	useEffect(() => {
		dispatch(resetData())
	}, [dispatch])

	return (
		<OnboardingStepFrame
			step={step}
			childSteps={childSteps}
			onNextClickAnotherAction={onContinueClick}
			changesInCurrentStep={changesInCurrentStep}
		>
			<div className="tm-material--wrapper">
				{stepContent.map((component, index) => {
					return (
						<React.Fragment key={component.id}>
							{
								AddPrinterComponents[
									component.id as keyof typeof AddPrinterComponents
								]
							}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default AddPrinter
