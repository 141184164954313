export enum MappingStatus {
	IGNORE_MAPPING = 'IGNORE_MAPPING',
	MAPPED = 'MAPPED',
	UN_MAP = 'UN_MAP'
}

export enum MappingOptions {
	IGNORE = 'IGNORE'
}

export interface IProjectPartsMapping {
	id: string
	name2D?: string
	name3D?: string
	projectId: string
	status: MappingStatus
}

export const PROJECT_PART_MAPPING_LOADING = 'PROJECT_PART_MAPPING_LOADING'
export const GET_PROJECT_PART_MAPPING = 'GET_PROJECT_PART_MAPPING'
export const GET_PROJECT_PART_MAPPING_LOADING =
	'GET_PROJECT_PART_MAPPING_LOADING'
export const PROJECT_PART_MAPPING_CHANGE_3D_PART =
	'PROJECT_PART_MAPPING_CHANGE_3D_PART'
export const CHANGE_PARTS_MAPPING_PAGE = 'CHANGE_PARTS_MAPPING_PAGE'
