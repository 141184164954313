import { every, isEmpty, isNil, map } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'

import {
	CHANGE_FILTER_PHRASE,
	CHANGE_FILTERS_KEY,
	CHANGE_INITIAL_PROJECT_SETUP,
	CHANGE_ORDER_NAME,
	CHANGE_PARTS_PAGE,
	CHANGE_PARTS_PROPERTIES_PAGE,
	CHANGE_PARTS_VIEW,
	CHANGE_RESULT_TYPE,
	CHANGE_SEARCH_PHRASE,
	CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP,
	CONFIGURATION_CALCULATED,
	DEFAULT_SCENARIO_CHANGED,
	DO_REFRESH_CONFIGURATIONS,
	GET_PARTS_PROPERTIES_LOADING,
	GET_PROJECT_ANALYSIS,
	GET_PROJECT_ANALYSIS_FAILED,
	GET_PROJECT_ANALYSIS_NO_PERMISSION,
	GET_PROJECT_CLUSTERS_SUCCESS,
	GET_PROJECT_FEA_ANALYSIS_SUCCESS,
	GET_PROJECT_PARTS_STARTED,
	GET_PROJECT_PARTS_TO_PRINT_FROM_STATE,
	GET_PROJECT_SUGGESTIONS_PARTS_TO_PRINT_STARTED,
	GET_PROJECT_SUGGESTIONS_PRINT_SUCCESS,
	GRAB_CAD_PRINT_UPDATED,
	MATERIAL_ADDED_TO_USER,
	MATERIAL_REMOVED_FROM_USER,
	MPIO_REQUEST,
	NEW_PRINTER_ADDED_TO_USER,
	NEW_PRINTER_MATERIAL_ADDED,
	OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP,
	PART_ANALYSIS_WALL_THICKNESS_UPDATED,
	PART_FINANCIAL_UPDATED,
	POST_PROCESS_UPDATED,
	PROJECT_ANALYSIS_ADD_TOUR_STEPS,
	PROJECT_ANALYSIS_PART_REMOVED,
	PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR,
	PROJECT_ANALYSIS_REMOVE_ALERT,
	PROJECT_ANALYSIS_REMOVE_PART_CANCELED,
	PROJECT_ANALYSIS_REMOVE_PART_CLICKED,
	PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED,
	PROJECT_ANALYSIS_SHOW_ALERT_FOR_FAILED_PART,
	PROJECT_ANALYSIS_SHOW_TOUR_UPDATED,
	PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED,
	PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED,
	PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED,
	PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED,
	PROJECT_FEA_ANALYSIS_CANCELLED,
	PROJECT_FEA_ANALYSIS_DATA_FETCHED,
	PROJECT_FEA_ANALYSIS_LOADER_TOGGLED,
	PROJECT_PART_WEIGHT_REDUCTION_CANCELLED,
	PROJECT_PART_WEIGHT_REDUCTION_SENT,
	PROJECT_PARTS_PROPERTIES_CALCULATING,
	PROJECT_PARTS_PROPERTIES_CALCULATING_RESET,
	PROJECT_PARTS_PROPERTIES_CHANGE_PART_ROW_DATA,
	PROJECT_PARTS_PROPERTIES_CHANGE_SPECIFIC_TOLERANCE,
	PROJECT_PARTS_PROPERTIES_FETCHED,
	PROJECT_PARTS_PROPERTIES_RESET,
	PROJECT_PARTS_PROPERTIES_UPDATE_PART,
	PROJECT_SUGGESTION_UPDATE_SUGGESTIONS_LIST,
	PROJECT_WEIGHT_REDUCTION_CHANGED,
	PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED,
	PROJECT_WEIGHT_REDUCTION_SUBMIT_TOGLLED,
	PROJECT_WEIGHT_REDUCTION_SUBMITED,
	PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED,
	PROJECT_WEIGHT_REDUCTION_UPDATED,
	RESET_WEIGHT_REDUCTION_PROGRESS_TOUR_FLAG,
	SOLUTION_CONFIGURATION_WAS_CHANGED,
	STAR_PART_SUCCESS,
	UPDATE_PROJECT_CHALLENGES,
	UPDATE_PROJECT_CHALLENGES_VALUE,
	USER_FILTERS_CHANGE,
	USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE,
	USER_PRINTER_MATERIAL_REMOVED_FROM_USER,
	USER_PRINTER_REMOVED_FROM_USER,
	WEIGHT_REDUCTION_PROCESS_STARTED_FROM_PROJECT
} from '../../../../global actions/types'
import { partResults, partsPageLimit } from '../../../../Services/Constants'
import { PROJECT_ANALYSIS } from '../../../../Services/Constants/pageNamesConstants'
import {
	getJsonItemFromLocalStorage,
	getStringItemFromLocalStorage,
	setJsonItemToLocalStorage,
	setStringItemToLocalStorage
} from '../../../../Services/LocalStorageService'
import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import {
	ProjectClusterStatus,
	ProjectStatus,
	ProjectWeightReductionStatus
} from '../../../../Services/models/IProject'
import { partConfigurationsRoute } from '../../../../Services/routeFuncs'
import {
	DOWNLOAD,
	FAILED_PROCESSING_PART_ALERT_TITLE,
	REDUCE_WEIGHT_BUTTON_TEXT
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import { filters } from '../../../Components/FilterPartsGrid/filterPartsEnum'
import {
	changeFilterValue,
	prepareFiltersForLocalStorage,
	prepareFiltersForReducer
} from '../../../Components/FilterPartsGrid/FilterPartsService'
import {
	getWeightReducedValue,
	getWeightReductionRate,
	getWeightReductionRequestText,
	isPartWeightReduced
} from '../../../Components/WeightReduction/WeightReductionService'
import { ProjectAnalysisPartsView } from './ProjectAnalysisSuggestionInterfaces'
import {
	checkLockedPartsNumber,
	createPaginationData,
	getClusterRequestText,
	getOnlyPartState,
	getPartsStandardCosts,
	getPartValue,
	getResultTypeAndSelectedTab,
	getWeightReductionStatus,
	showFailedPartsWarning,
	TabsItem
} from './ProjectAnalysisSuggestionService'
import {
	GET_PROJECT_SUGGESTION_ANALYSIS_SUCCESS,
	GET_PROJECT_SUGGESTIONS,
	UPDATE_PROJECT_TAB
} from './ProjectAnalysisSuggestionTypes'
import { PartPropertyName } from 'Services/models/IPartPropertiest'

const CHANGED = 'changed'

const INITIAL_STATE = {
	loading: false,
	parts: [],
	partsPropertiesAll: [],
	error: null,
	partId: null,
	projectId: null,
	project: null,
	showMultiplePartsIntoOneRequest: false,
	showMultiplePartsIntoOneResults: false,
	showMoreParts: true,
	searchPhrase: '',
	showingSimpleAlertTitle: null,
	showingSimpleAlertText: null,
	selectedFilterPart: null,
	showExportButton: true,
	numberOfFailedParts: 0,
	doRefresh: false,
	requestedRemovePartId: null,
	requestedRemovePartName: '',
	showRemovePartAlert: false,
	loadingRemovePart: false,
	amountOfLockedParts: '',
	clusterRequested: false,
	clusterRequestText: '',
	projectClusterStatus: '',
	geometryAnalysisPart: {},
	weightReductionRequested: false,
	weightReductionLoading: false,
	weightReductionRequestText: '',
	weightReductionItemsWithPictures: [],
	weightReducedSuggestedParts: [],
	weightReducedParts: [],
	projectWeightReductionStatus: '',
	projectWeightReductionThreshold: 0,
	projectWeightReductionMinimumThicknessThreshold: 0,
	updateWeightReductionButtonLoading: false,
	showStandardCostAlert: false,
	partsStandardCosts: null,
	initialStandardCosts: null,
	disableStandardCostSubmit: true,
	showStandardCostError: false,
	showStandardCostNumberError: false,
	showNameDuplicationError: false,
	updateStandardCostLoading: false,
	showStandardCostFinishIcon: false,
	isSinglePartProject: true,
	onlyPart: {},
	onlyPartResultTitle: '',
	pageName: PROJECT_ANALYSIS,
	tourSteps: [],
	selectedFilterValues: [filters.PRINTABLE],
	showWeightReduceProgressPopup: false,
	WeightReductionMaterialsData: {},
	leadingConfigurationData: [],
	reduceWeightPartIdClick: null,
	reduceWeightPartSelectedIsStl: false,
	reduceWeightPartSelectedHasBrepData: false,
	showFeaAnalysisProjectAlert: false,
	solutionFeaStrengthTitle: '',
	solutionFeaUserInputTitle: '',
	solutionFeaStrength: 0,
	solutionFeaSDStrength: 0,
	solutionFeaSliderUnits: '',
	solutionFeaSliderMarks: {},
	solutionFeaSliderMaxValue: 0,
	solutionFeaSliderMinValue: 0,
	solutionFeaSliderValue: 0,
	solutionFeaSliderStartValue: 0,
	solutionFeaResult: 0,
	solutionFeaSliderIsRange: false,
	solutionFeaAlertLoading: false,
	solutionFeaSelectedValues: [],
	feaAnalysisResultsId: '',
	showFeaAnalysisOldAnalysis: false,
	solutionFea: {},
	weightReductionProgressFromProject: false,
	numberOfPrintableParts: 0,
	numberOfAllBenefits: 0,
	benefitsState: [],
	partsProperties: [],
	inapplicablePartsProperties: [],
	partsPropertiesCalculating: { calculating: false },
	partsPropertiesReset: { reset: false },
	is2dProject: false,
	isMetaDataProject: false,
	doRefreshConfigurations: false,
	allPartsInapplicable: false,
	allPartsNotCostEffective: false,
	userHasNoPermissions: false,
	partsStandardCostsBeforeChanges: [],
	partsView: ProjectAnalysisPartsView.list,
	clusters: [],
	paginationData: {
		page: 1,
		limit: partsPageLimit,
		totalPartsCount: 0,
		totalPagesCount: 1,
		enableNext: false,
		enablePrev: false,
		showingFrom: 0,
		showingTo: 0
	},
	partPropertiesPaginationData: {
		page: 1,
		limit: partsPageLimit,
		totalPartsCount: 0,
		totalPagesCount: 1,
		enableNext: false,
		enablePrev: false,
		showingFrom: 0,
		showingTo: 0
	},
	partsLoading: false,
	partsPropertiesLoading: false,
	partsToPrintLoading: false,
	partsToPrint: [],
	partsToPrintSummary: [],
	totalPartsCount: 0,
	weightReductionPartsLeadingData: [],
	initialSetup: true,
	refetchParts: false,
	standardCostAllowedNames: [],
	selectedTab: TabsItem.opportunities,
	totalSuggestionsCount: 0,
	costSavingPartsCount: 0,
	sortBy: '',
	sortingCriteria: '',
	resultType: '',
	costSavingDataPerUnit: {
		amNoDesignChanges: 0,
		amPC: 0,
		amWR: 0,
		casting: 0,
		lathe: 0,
		noOpportunities: 0
	},
	costSavingDataPerYear: {
		amNoDesignChanges: 0,
		amPC: 0,
		amWR: 0,
		casting: 0,
		lathe: 0,
		noOpportunities: 0
	},
	opportunitiesData: {
		amNoDesignChanges: 0,
		amPC: 0,
		amWR: 0,
		casting: 0,
		lathe: 0,
		noOpportunities: 0
	},
	projectSuggestionsList: [],
	projectNoOpportunitiesList: [],
	projectChallengesList: [],
	suggestions: [],
	totalSuggestionsCount: 0,
	totalClustersCount: 0,
	defaultPartDrawingTolerances: [],
	challengesFilterLoading: false
}

const projectSuggestionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PROJECT_ANALYSIS: {
			return {
				...INITIAL_STATE,
				doRefreshConfigurations: state.doRefreshConfigurations,
				loading: true,
				projectId: action.payload
			}
		}

		case GET_PROJECT_CLUSTERS_SUCCESS: {
			const { clusters } = action.payload
			const changedParts = state.parts

			// pass isUsedByCluster if part.externalId is existed in compositionSetPart
			for (let cluster of clusters) {
				for (let compositionSetPart of cluster.compositionSetParts) {
					// check and changed All parts
					for (let part of changedParts) {
						if (compositionSetPart.partId === part.externalId) {
							part.isUsedByCluster = true
						}
					}
				}
			}

			return {
				...state,
				clusters,
				parts: changedParts
			}
		}

		case GET_PROJECT_SUGGESTION_ANALYSIS_SUCCESS: {
			const { project, partsResults, features, userId } = action.payload
			const projectWeightReductionThreshold = project?.weightReductionThreshold
			const projectWeightReductionMinimumThicknessThreshold =
				project?.weightReductionMinimumThicknessThreshold

			const getFilterFromLocal = getJsonItemFromLocalStorage(
				`filter-${state.projectId}`
			)
			const partsViewFromLocal = getStringItemFromLocalStorage(
				`view-${state.projectId}`
			)

			const selectedFilterValuesFromLocal =
				(!isEmpty(getFilterFromLocal) && getFilterFromLocal.split(',')) || null
			const preparedFiltersFromLocal = prepareFiltersForReducer(
				selectedFilterValuesFromLocal
			)
			// if there is no selected filter we need to pass by default and save it
			// if some part is Printable => by default Printable, if not => All
			const selectedFilterValues = preparedFiltersFromLocal
				? preparedFiltersFromLocal
				: partsResults?.isSomePrintable
				? state.selectedFilterValues
				: [filters.ALL]
			const selectedPartsView = partsViewFromLocal
				? partsViewFromLocal
				: state.partsView
			const selectedFilterValuesToLS =
				prepareFiltersForLocalStorage(selectedFilterValues)
			// do not set filters to ls until all parts are analyzed
			if (project.status === ProjectStatus.published) {
				setJsonItemToLocalStorage(
					`filter-${state.projectId}`,
					`${selectedFilterValuesToLS}`
				)
				setStringItemToLocalStorage(
					`view-${state.projectId}`,
					selectedPartsView
				)
			}

			const partsStandardCosts = getPartsStandardCosts(
				partsResults?.partsWithStandardCost,
				partsResults?.totalPartsCount
			)

			const { resultType, selectedTab } =
				getResultTypeAndSelectedTab(partsResults)

			return {
				...state,
				project,
				geometryAnalysisPart: project.geometryAnalysisPart,
				is2dProject: partsResults?.is2dProject,
				isMetaDataProject: partsResults?.isMetaDataProject,
				selectedFilterValues,
				loading: false,
				numberOfFailedParts: partsResults?.numberOfFailedParts,
				totalPartsCount: partsResults?.totalPartsCount,
				allPartsFailed:
					partsResults?.numberOfFailedParts === partsResults?.totalPartsCount &&
					!project.lockedParts,
				showFailedPartsWarning: showFailedPartsWarning(
					partsResults?.numberOfFailedParts,
					partsResults?.totalPartsCount,
					project.forcePublished,
					project.allPartsAnalyzed
				),
				isSinglePartProject: partsResults?.isSinglePartProject,
				...getOnlyPartState(
					partsResults?.isSinglePartProject,
					partsResults?.onlyPart,
					state
				),
				amountOfLockedParts: checkLockedPartsNumber(project?.lockedParts),
				searchPhrase: '',
				projectWeightReductionThreshold,
				projectWeightReductionMinimumThicknessThreshold,
				partsStandardCosts,
				initialStandardCosts: partsStandardCosts,
				showStandardCostFinishIcon: false,
				allPartsInapplicable: partsResults?.allPartsInapplicable,
				allPartsNotCostEffective: partsResults?.allPartsNotCostEffective,
				partsView: selectedPartsView,
				costSavingDataPerUnit: partsResults?.costSavingDataPerUnit,
				costSavingDataPerYear: partsResults?.costSavingDataPerYear,
				opportunitiesData: partsResults?.opportunitiesData,
				totalSuggestionsCount: partsResults?.totalSuggestionsCount,
				totalClustersCount: partsResults?.totalClustersCount,
				costSavingPartsCount: partsResults?.costSavingPartsCount,
				selectedTab,
				resultType,
				projectSuggestionsList: partsResults?.projectSuggestionsList,
				projectNoOpportunitiesList: partsResults?.projectNoOpportunitiesList,
				projectChallengesList: partsResults?.projectChallengesList,
				standardCostAllowedNames: partsResults?.standardCostAllowedNames
			}
		}

		case PROJECT_PART_WEIGHT_REDUCTION_SENT: {
			const { partId } = action.payload
			const partsWeightReductionNumber = state.partsWeightReductionNumber - 1

			const projectWeightReductionStatus = getWeightReductionStatus(
				state.project,
				partsWeightReductionNumber
			)

			const weightReductionRequested =
				projectWeightReductionStatus != null &&
				projectWeightReductionStatus !== ProjectWeightReductionStatus.failed

			const weightReductionRequestText = getWeightReductionRequestText(
				projectWeightReductionStatus,
				partsWeightReductionNumber
			)
			return {
				...state,
				projectWeightReductionStatus,
				weightReductionRequested,
				weightReductionRequestText,
				weightReducedSuggestedParts: state.weightReducedSuggestedParts.filter(
					weightReducedSuggestedPart => weightReducedSuggestedPart.id !== partId
				),
				weightReductionItemsWithPictures:
					state.weightReductionItemsWithPictures.filter(
						weightReductionItemWithPicture =>
							weightReductionItemWithPicture.id !== partId
					)
			}
		}

		case PROJECT_PART_WEIGHT_REDUCTION_CANCELLED: {
			const { part, features, unitSystem } = action.payload
			if (!part) {
				return { ...state }
			}
			const partsWeightReductionNumber = state.partsWeightReductionNumber + 1

			const projectWeightReductionStatus = getWeightReductionStatus(
				state.project,
				partsWeightReductionNumber
			)

			const weightReductionRequested =
				projectWeightReductionStatus != null &&
				projectWeightReductionStatus !== ProjectWeightReductionStatus.failed

			const weightReductionRequestText = getWeightReductionRequestText(
				projectWeightReductionStatus,
				partsWeightReductionNumber
			)

			let subTitleLink = {
				url: part.weightReducedStlURL,
				text: REDUCE_WEIGHT_BUTTON_TEXT
			}

			if (
				Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION_OLD, features)
			) {
				subTitleLink = {
					url: part.weightReducedStlURL,
					text: DOWNLOAD
				}
			}

			const isWeightReductionInGrams = Feature.isFeatureOn(
				FeatureComponentId.WEIGHT_REDUCTION_IN_GRAMS,
				features
			)

			return {
				...state,
				projectWeightReductionStatus,
				weightReductionRequested,
				weightReductionRequestText,
				weightReducedSuggestedParts: [
					...state.weightReducedSuggestedParts.push(part)
				],
				weightReductionItemsWithPictures: [
					...state.weightReductionItemsWithPictures.push({
						id: part.id,
						image: part.imageURL,
						title: part.partNumber,
						stepURL: part.stepURL,
						linkTo: partConfigurationsRoute(part.projectId, part.id),
						subTitle: getString('WEIGHT_REDUCED_PART').format(
							getWeightReductionRate(part.volume, part.weightReducedVolume),
							getWeightReducedValue(
								part.volume,
								part.weightReducedVolume,
								unitSystem,
								part.weightReductionDensity
							)
						),
						subTitleLink,
						hasBrepData: part?.hasBrepData
					})
				]
			}
		}

		case GET_PROJECT_FEA_ANALYSIS_SUCCESS:
			return { ...state, ...action.payload }

		case PROJECT_FEA_ANALYSIS_CANCELLED:
			return { ...state, showFeaAnalysisProjectAlert: false }

		case PROJECT_FEA_ANALYSIS_LOADER_TOGGLED:
			return {
				...state,
				solutionFeaAlertLoading: !state.solutionFeaAlertLoading
			}

		case PROJECT_FEA_ANALYSIS_DATA_FETCHED:
			const { feaValues, solutionFea } = action.payload
			return {
				...state,
				solutionFeaSelectedValues: feaValues,
				showFeaAnalysisProjectAlert: false,
				solutionFeaAlertLoading: false,
				showFeaAnalysisOldAnalysis: true,
				solutionFea
			}

		case PROJECT_ANALYSIS_SHOW_TOUR_UPDATED:
			return { ...state, showTour: action.payload.showTour }

		case PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED:
			return {
				...state,
				updateWeightReductionButtonLoading:
					!state.updateWeightReductionButtonLoading
			}
		case PROJECT_WEIGHT_REDUCTION_UPDATED: {
			const { partsResults, features, userId } = action.payload
			const {
				is2dProject,
				isMetaDataProject,
				allPartsInapplicable,
				allPartsNotCostEffective,
				onlyPart,
				weightReductionParts,
				printablePartsCount,
				totalPartsCount,
				benefitsData
			} = partsResults

			return {
				...state,
				project: {
					...state.project,
					weightReductionThreshold: state.projectWeightReductionThreshold
				},
				is2dProject,
				isMetaDataProject,
				updateWeightReductionButtonLoading:
					!state.updateWeightReductionButtonLoading,
				onlyPart,
				allPartsInapplicable,
				allPartsNotCostEffective,
				totalPartsCount
			}
		}

		case MPIO_REQUEST:
			return {
				...state,
				clusterRequested: true,
				clusterRequestText: getClusterRequestText(
					ProjectClusterStatus.awaitingAnalysis
				),
				projectClusterStatus: ProjectClusterStatus.awaitingAnalysis
			}

		case GET_PROJECT_ANALYSIS_NO_PERMISSION: {
			return {
				...state,
				loading: false,
				error: action.payload,
				userHasNoPermissions: true
			}
		}
		case GET_PROJECT_ANALYSIS_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
			}
		case PROJECT_WEIGHT_REDUCTION_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				projectWeightReductionThreshold: value
			}
		}
		case PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				projectWeightReductionMinimumThicknessThreshold: value
			}
		}
		case PROJECT_WEIGHT_REDUCTION_SUBMIT_TOGLLED:
			return {
				...state,
				weightReductionLoading: !state.weightReductionLoading
			}
		case PROJECT_WEIGHT_REDUCTION_SUBMITED:
			const projectWeightReductionStatus =
				ProjectWeightReductionStatus.awaitingAnalysis
			return {
				...state,
				weightReductionLoading: false,
				projectWeightReductionStatus,
				weightReductionRequestText: getWeightReductionRequestText(
					projectWeightReductionStatus
				),
				weightReductionRequested: true
			}
		case PROJECT_ANALYSIS_SHOW_ALERT_FOR_FAILED_PART:
			let showingSimpleAlertTitle = ''
			let showingSimpleAlertText = ''
			const part = action.payload
			if (!part) {
				return state
			}
			if (
				part.result === partResults.failed ||
				part.result === partResults.dependencyAnalysisFailed
			) {
				showingSimpleAlertTitle = FAILED_PROCESSING_PART_ALERT_TITLE
				showingSimpleAlertText = part.errorMessage
			}
			return {
				...state,
				showingSimpleAlertTitle,
				showingSimpleAlertText
			}

		case PROJECT_ANALYSIS_REMOVE_ALERT:
			return {
				...state,
				showingSimpleAlertTitle: null,
				showingSimpleAlertText: null
			}

		case PART_ANALYSIS_WALL_THICKNESS_UPDATED:
		case PART_FINANCIAL_UPDATED:
		case STAR_PART_SUCCESS:
			const starredPart = action.payload
			const suggestions = state.suggestions.map(suggestion => {
				if (suggestion.part.id === starredPart.id) {
					suggestion.part.starred = starredPart.starred
				}
				return suggestion
			})

			return { ...state, suggestions }

		case CHANGE_FILTER_PHRASE:
			const selectedFilterPart = action.payload
			return {
				...state,
				selectedFilterPart
				/*showExportButton:
         selectedFilterPart === filters.STARRED ||
         selectedFilterPart === filters.PRINTABLE ||
         selectedFilterPart === filters.ALL*/
			}

		case CHANGE_FILTERS_KEY: {
			const { selectedFilter, isBundle, singleFilter } = action.payload
			const { selectedFilterValues } = state
			if (isBundle) return state
			let updatedFilterValues = changeFilterValue(
				selectedFilter,
				selectedFilterValues,
				singleFilter
			)
			const updatedValuesToLS =
				prepareFiltersForLocalStorage(updatedFilterValues)

			setJsonItemToLocalStorage(
				`filter-${state.projectId}`,
				`${updatedValuesToLS}`
			)

			return {
				...state,
				selectedFilterValues: updatedFilterValues,
				paginationData: {
					...state.paginationData,
					page: 1
				}
			}
		}

		case CHANGE_SEARCH_PHRASE:
			const searchPhrase = action.payload
			return { ...state, searchPhrase }

		case CHANGE_ORDER_NAME:
			const { sortBy, sortingCriteria } = action.payload
			return { ...state, sortBy, sortingCriteria }

		case CONFIGURATION_CALCULATED:
			return { ...state, doRefresh: true }

		case PROJECT_ANALYSIS_REMOVE_PART_CLICKED: {
			const { partId, partName } = action.payload
			return {
				...state,
				requestedRemovePartId: partId,
				requestedRemovePartName: partName,
				showRemovePartAlert: true
			}
		}

		case PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED:
			return {
				...state,
				loadingRemovePart: true
			}

		case PROJECT_ANALYSIS_PART_REMOVED: {
			const { partId, partsResults, isBundle } = action.payload
			if (isBundle) return state
			const suggestions = state.suggestions.filter(
				suggestion => suggestion.part.id !== partId
			)
			const {
				is2dProject,
				isMetaDataProject,
				numberOfFailedParts,
				isSinglePartProject,
				totalPartsCount,
				partsWithStandardCost,
				costSavingDataPerUnit,
				costSavingDataPerYear,
				opportunitiesData,
				totalSuggestionsCount,
				totalClustersCount,
				costSavingPartsCount,
				projectSuggestionsList,
				projectNoOpportunitiesList,
				projectChallengesList,
				standardCostAllowedNames
			} = partsResults

			const suggestionsEmpty = every(
				projectSuggestionsList,
				item => !item.suggestionsCount
			)
			const noSuggestionsEmpty = every(
				projectNoOpportunitiesList,
				item => !item.partsCount
			)
			let resultType =
				suggestionsEmpty && !noSuggestionsEmpty
					? projectNoOpportunitiesList?.[0]?.noOpportunitiesResultType
					: projectSuggestionsList?.[0]?.clientSuggestionType
			let selectedTab =
				suggestionsEmpty && !noSuggestionsEmpty
					? TabsItem.others
					: TabsItem.opportunities

			return {
				...state,
				suggestions,
				project: {
					...state.project,
					recalculateClusters: true
				},
				loadingRemovePart: false,
				requestedRemovePartId: null,
				requestedRemovePartName: '',
				showRemovePartAlert: false,
				initialStandardCosts: getPartsStandardCosts(
					partsWithStandardCost,
					totalPartsCount
				),
				is2dProject,
				isMetaDataProject,
				numberOfFailedParts,
				totalPartsCount,
				isSinglePartProject,
				updateStandardCostLoading: false,
				showStandardCostAlert: false,
				showStandardCostFinishIcon: true,
				partsStandardCostsBeforeChanges: [],
				costSavingDataPerUnit,
				costSavingDataPerYear,
				opportunitiesData,
				totalSuggestionsCount,
				totalClustersCount,
				costSavingPartsCount,
				selectedTab,
				resultType,
				projectSuggestionsList,
				projectNoOpportunitiesList,
				standardCostAllowedNames,
				projectChallengesList
			}
		}

		case PROJECT_ANALYSIS_REMOVE_PART_CANCELED:
		case PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR:
			return {
				...state,
				loadingRemovePart: false,
				requestedRemovePartId: null,
				requestedRemovePartName: '',
				showRemovePartAlert: false
			}
		case PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED:
			return {
				...state,
				showStandardCostAlert: !state.showStandardCostAlert,
				partsStandardCosts: state.initialStandardCosts,
				showStandardCostError: false,
				showStandardCostNumberError: false,
				showNameDuplicationError: false
			}
		case PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED:
			const { data } = action.payload
			const prevPartsStandardCosts = state.partsStandardCosts
			let disableStandardCostSubmit = false
			let showStandardCostError = false
			let showStandardCostNumberError = false
			let showNameDuplicationError = false

			const partsStandardCosts = data.map((row, key) => {
				// remove the space at the beginning and at the end
				let partName = getPartValue(row[0].value)
				let partStandardCost = getPartValue(row[1].value)

				// const partId = row[0].id
				const partExists = state.standardCostAllowedNames.find(
					name => name.trim().toLowerCase() === partName.trim().toLowerCase()
				)

				// check if part is already exist
				prevPartsStandardCosts &&
					prevPartsStandardCosts.forEach((part, i) => {
						if (key !== i) {
							const existPartName = getPartValue(part[0].value)

							if (partName && partName === existPartName) {
								showNameDuplicationError = true
							}
						}
					})

				const partNameValid = !partName || !!partExists
				const standardCostValid =
					(!isNaN(partStandardCost) && partStandardCost > 0) ||
					partStandardCost === ''

				if (!partNameValid) {
					showStandardCostError = true
				}

				if (!standardCostValid) {
					showStandardCostNumberError = true
				}

				if (!partNameValid || !standardCostValid || showNameDuplicationError) {
					disableStandardCostSubmit = true
				}

				if (partStandardCost === '' && partName) {
					disableStandardCostSubmit = false
				}

				if (isEmpty(partName) && !!+partStandardCost) {
					showStandardCostError = true
					disableStandardCostSubmit = true
				}

				return [
					{ value: partName, valid: partNameValid },
					{ value: partStandardCost, valid: standardCostValid }
				]
			})
			return {
				...state,
				partsStandardCosts,
				showStandardCostError,
				showStandardCostNumberError,
				showNameDuplicationError,
				disableStandardCostSubmit: disableStandardCostSubmit,
				partsStandardCostsBeforeChanges:
					state.partsStandardCostsBeforeChanges?.length === 0
						? state.partsStandardCosts
						: state.partsStandardCostsBeforeChanges
			}
		case PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED:
			return {
				...state,
				updateStandardCostLoading: !state.updateStandardCostLoading
			}
		case PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED: {
			const { partsResults } = action.payload
			const {
				is2dProject,
				isMetaDataProject,
				numberOfFailedParts,
				isSinglePartProject,
				totalPartsCount,
				partsWithStandardCost,
				costSavingDataPerUnit,
				costSavingDataPerYear,
				opportunitiesData,
				totalSuggestionsCount,
				totalClustersCount,
				costSavingPartsCount,
				projectSuggestionsList,
				projectNoOpportunitiesList,
				projectChallengesList,
				standardCostAllowedNames
			} = partsResults || {}

			const { resultType, selectedTab } =
				getResultTypeAndSelectedTab(partsResults)

			return {
				...state,
				is2dProject,
				isMetaDataProject,
				numberOfFailedParts,
				totalPartsCount,
				isSinglePartProject,
				updateStandardCostLoading: false,
				showStandardCostAlert: false,
				showStandardCostFinishIcon: true,
				partsStandardCostsBeforeChanges: [],
				initialStandardCosts: getPartsStandardCosts(
					partsWithStandardCost,
					totalPartsCount
				),
				costSavingDataPerUnit,
				costSavingDataPerYear,
				opportunitiesData,
				totalSuggestionsCount,
				totalClustersCount,
				costSavingPartsCount,
				selectedTab,
				resultType,
				projectSuggestionsList,
				projectNoOpportunitiesList,
				standardCostAllowedNames,
				projectChallengesList
			}
		}
		case PROJECT_ANALYSIS_ADD_TOUR_STEPS:
			return { ...state, tourSteps: action.payload }
		case OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP: {
			const { partId, stlFile, hasBrepData } = action.payload
			return {
				...state,
				showWeightReduceProgressPopup: true,
				reduceWeightPartIdClick: partId,
				reduceWeightPartSelectedIsStl: !stlFile,
				reduceWeightPartSelectedHasBrepData: hasBrepData
			}
		}
		case CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP:
			return {
				...state,
				showWeightReduceProgressPopup: false,
				reduceWeightPartIdClick: null,
				reduceWeightPartSelectedIsStl: false,
				reduceWeightPartSelectedHasBrepData: false
			}

		case WEIGHT_REDUCTION_PROCESS_STARTED_FROM_PROJECT: {
			return {
				...state,
				weightReductionProgressFromProject: true
			}
		}
		case RESET_WEIGHT_REDUCTION_PROGRESS_TOUR_FLAG: {
			return {
				...state,
				doRefresh: true,
				weightReductionProgressFromProject: false
			}
		}

		case SOLUTION_CONFIGURATION_WAS_CHANGED: {
			const { projectId, recalculateClusters } = action.payload

			if (projectId === state.projectId) {
				return {
					...state,
					project: {
						...state.project,
						recalculateClusters: recalculateClusters
					}
				}
			}

			return {
				...state
			}
		}

		case PROJECT_PARTS_PROPERTIES_CHANGE_PART_ROW_DATA: {
			const { propIndex, partId, value } = action.payload
			const changedProperties = cloneDeep(state.partsProperties)
			const partIndex = changedProperties.findIndex(
				part => part.partId === partId
			)

			if (partIndex !== -1 && !isNil(partIndex)) {
				changedProperties[partIndex].data[propIndex].value = value
				changedProperties[partIndex].data[propIndex].text = value
				changedProperties[partIndex][CHANGED] = true
			}

			return {
				...state,
				partsProperties: changedProperties
			}
		}

		case PROJECT_PARTS_PROPERTIES_CHANGE_SPECIFIC_TOLERANCE: {
			const { partId, specificTolerances, generalTolerance } = action.payload
			const changedProperties = cloneDeep(state.partsProperties)
			const partIndex = changedProperties.findIndex(
				part => part.partId === partId
			)

			if (partIndex !== -1 && !isNil(partIndex)) {
				changedProperties[partIndex].specificTolerances = specificTolerances
				const toleranceIndex = changedProperties[partIndex].data.findIndex(
					cell => cell.propertyName === PartPropertyName.customToleranceValue
				)
				changedProperties[partIndex].data[toleranceIndex].text =
					generalTolerance
				changedProperties[partIndex].data[toleranceIndex].value =
					generalTolerance
				changedProperties[partIndex][CHANGED] = true
			}

			return {
				...state,
				partsProperties: changedProperties
			}
		}

		case PROJECT_PARTS_PROPERTIES_FETCHED: {
			const {
				partsProperties: properties,
				rowIndex,
				inapplicablePartsProperties,
				parts,
				totalPartsPropertiesCount,
				defaultPartDrawingTolerances
			} = action.payload
			const { partsProperties } = state

			let updatedPartProperties = cloneDeep(properties)

			if (rowIndex !== -1 && !isNil(rowIndex)) {
				updatedPartProperties = cloneDeep(partsProperties)
				updatedPartProperties[rowIndex] = properties[rowIndex]
				updatedPartProperties[rowIndex][CHANGED] = false
			} else {
				updatedPartProperties = updatedPartProperties.map(property => {
					property[CHANGED] = false
					return property
				})
			}

			return {
				...state,
				partsProperties: updatedPartProperties,
				inapplicablePartsProperties,
				partsPropertiesAll: parts,
				partPropertiesPaginationData: createPaginationData(
					state.partPropertiesPaginationData.page,
					state.partPropertiesPaginationData.limit,
					totalPartsPropertiesCount,
					parts.length
				),
				partsPropertiesLoading: false,
				defaultPartDrawingTolerances:
					defaultPartDrawingTolerances || state.defaultPartDrawingTolerances
			}
		}

		case PROJECT_PARTS_PROPERTIES_CALCULATING: {
			const { calculating, rowIndex } = action.payload
			return {
				...state,
				partsPropertiesCalculating: {
					calculating,
					rowIndex
				}
			}
		}
		case PROJECT_PARTS_PROPERTIES_CALCULATING_RESET: {
			return {
				...state,
				partsPropertiesCalculating: INITIAL_STATE.partsPropertiesCalculating
			}
		}
		case PROJECT_PARTS_PROPERTIES_RESET: {
			const { reset, rowIndex } = action.payload
			return {
				...state,
				partsPropertiesReset: {
					reset,
					rowIndex
				}
			}
		}

		case PROJECT_PARTS_PROPERTIES_UPDATE_PART: {
			const { partId } = action.payload
			return {
				...state,
				partId
			}
		}

		case GRAB_CAD_PRINT_UPDATED: {
			const { partId, toggleGrabCad } = action.payload
			const onlyPart = state.onlyPart

			if (!isEmpty(onlyPart) && onlyPart.id === partId) {
				onlyPart.grabCadPrint = !toggleGrabCad
			}

			return {
				...state,
				onlyPart,
				parts: map(state.parts, part => {
					if (part.id === partId) {
						return {
							...part,
							grabCadPrint: !toggleGrabCad
						}
					}

					return part
				})
			}
		}

		case DO_REFRESH_CONFIGURATIONS: {
			const { doRefreshConfigurations, refetchParts } = action.payload

			return {
				...state,
				doRefreshConfigurations,
				refetchParts
			}
		}

		case CHANGE_PARTS_VIEW: {
			const { view } = action.payload

			setStringItemToLocalStorage(`view-${state.projectId}`, view)

			return {
				...state,
				partsView: view
			}
		}

		case CHANGE_RESULT_TYPE: {
			return {
				...state,
				partsLoading: true,
				resultType: action.payload.resultType,
				paginationData: INITIAL_STATE.paginationData
			}
		}

		case GET_PROJECT_PARTS_STARTED: {
			return {
				...state,
				partsLoading: true
			}
		}

		case GET_PARTS_PROPERTIES_LOADING: {
			const { loading } = action.payload

			return {
				...state,
				partsPropertiesLoading: loading
			}
		}

		case CHANGE_PARTS_PAGE: {
			const { page } = action.payload

			return {
				...state,
				paginationData: {
					...state.paginationData,
					page
				}
			}
		}

		case GET_PROJECT_SUGGESTIONS_PARTS_TO_PRINT_STARTED: {
			return {
				...state,
				partsToPrintLoading: true
			}
		}

		case GET_PROJECT_SUGGESTIONS_PRINT_SUCCESS: {
			const { suggestions, leadingConfigurationData } = action.payload

			return {
				...state,
				partsToPrintLoading: false,
				partsToPrint: suggestions,
				leadingConfigurationData
			}
		}

		case GET_PROJECT_PARTS_TO_PRINT_FROM_STATE: {
			return {
				...state,
				partsToPrint: state.suggestions
			}
		}

		case CHANGE_INITIAL_PROJECT_SETUP: {
			return {
				...state,
				initialSetup: action.payload
			}
		}

		case CHANGE_PARTS_PROPERTIES_PAGE: {
			const { page } = action.payload

			return {
				...state,
				partPropertiesPaginationData: {
					...state.partPropertiesPaginationData,
					page
				}
			}
		}

		case GET_PROJECT_SUGGESTIONS: {
			const {
				suggestions,
				totalSuggestionsCount,
				updatePagination,
				page,
				limit
			} = action.payload

			let selectedTab = state.selectedTab
			let resultType = state.resultType

			if (!suggestions.length && state.selectedTab === TabsItem.others) {
				if (!state.projectNoOpportunitiesList.length) {
					selectedTab = TabsItem.opportunities
					resultType =
						state.projectSuggestionsList?.[0]?.clientSuggestionType ||
						resultType
				}

				if (state.projectNoOpportunitiesList.length) {
					const otherType = state.projectNoOpportunitiesList.filter(
						el => el.noOpportunitiesResultType !== state.resultType
					)
					resultType = otherType?.[0]?.noOpportunitiesResultType || resultType
				}
			}

			return {
				...state,
				partsLoading: false,
				refetchParts: false,
				resultType,
				suggestions,
				selectedTab,
				paginationData: createPaginationData(
					updatePagination ? page : state.paginationData.page,
					updatePagination ? limit : state.paginationData.limit,
					totalSuggestionsCount,
					suggestions.length
				)
			}
		}

		case PROJECT_SUGGESTION_UPDATE_SUGGESTIONS_LIST: {
			const { partsResults } = action.payload

			return {
				...state,
				partsLoading: false,
				projectChallengesList:
					partsResults?.projectChallengesList || state.projectChallengesList,
				projectSuggestionsList:
					partsResults?.projectSuggestionsList || state.projectSuggestionsList,
				projectNoOpportunitiesList:
					partsResults?.projectNoOpportunitiesList ||
					state.projectSuggestionsList
			}
		}

		case NEW_PRINTER_ADDED_TO_USER:
		case USER_PRINTER_REMOVED_FROM_USER:
		case MATERIAL_ADDED_TO_USER:
		case MATERIAL_REMOVED_FROM_USER:
		case USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE:
		case NEW_PRINTER_MATERIAL_ADDED:
		case USER_PRINTER_MATERIAL_REMOVED_FROM_USER:
		case USER_FILTERS_CHANGE:
		case DEFAULT_SCENARIO_CHANGED:
		case POST_PROCESS_UPDATED: {
			return {
				...state,
				doRefreshConfigurations: true
			}
		}

		case UPDATE_PROJECT_TAB: {
			const { selectedTab } = action.payload
			let resultType

			switch (selectedTab) {
				case TabsItem.opportunities:
					resultType = state.projectSuggestionsList?.[0]?.clientSuggestionType
					break
				case TabsItem.others:
					resultType =
						state.projectNoOpportunitiesList?.[0]?.noOpportunitiesResultType
					break
				case TabsItem.challenges:
					resultType = state.projectChallengesList?.[0]?.challengesResultType
					break
			}

			return {
				...state,
				selectedTab,
				partsLoading: true,
				resultType,
				paginationData: INITIAL_STATE.paginationData
			}
		}

		case UPDATE_PROJECT_CHALLENGES: {
			return {
				...state,
				challengesFilterLoading: action.payload.loading
			}
		}
		case UPDATE_PROJECT_CHALLENGES_VALUE: {
			const { value, type, challengeType } = action.payload
			let projectChallengesList = state.projectChallengesList

			const updatedIndex = projectChallengesList.findIndex(
				challenge => challenge.challengesResultType === challengeType
			)

			if (
				updatedIndex !== -1 &&
				projectChallengesList[updatedIndex].editField
			) {
				projectChallengesList[updatedIndex].editField.type = type
				projectChallengesList[updatedIndex].editField.value = value
			}

			return {
				...state,
				projectChallengesList
			}
		}

		default:
			return state
	}
}

export default projectSuggestionReducer
