import parseHTML from 'html-react-parser'
import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import { materialSelected } from '../../UploadProjectActions'
import Flexbox from 'Scenes/Components/FlexBox'
import AmMaterialSelector from 'Scenes/Components/MaterialSelector/AmMaterialSelector/AmMaterialSelector'
import { useMaterial } from 'Scenes/Home/NewUploadProject/UploadBlocks/UploadBlocksHooks'
import { defaultPrinterMetal } from 'Services/Constants'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { Material } from 'Services/models/IMaterial'
import { PrinterMaterial } from 'Services/models/IPrinterMaterial'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { defaultPrinterMaterial } = getTheme()

interface UploadAmMaterialProps {
	disabled: boolean
}

const UploadAmMaterial: FC<UploadAmMaterialProps> = ({ disabled }) => {
	const user = useSelector((state: RootStateOrAny) => state.user)
	const [defaultChosenMaterial] = useMaterial()

	const dispatch = useDispatch()

	const materialChangedUpload = (material: Material | PrinterMaterial) => {
		const isMachining = user?.userCustomizationSettings?.machining
		dispatch(materialSelected(material, isMachining))
	}
	return (
		<div>
			<Flexbox flexDirection="column" alignItems="flex-start">
				<div
					className={cx('hide-input', {
						disabledText: disabled,
						bomUploadInput: !disabled
					})}
				>
					{parseHTML(getString('UNIFORM_AM_MATERIAL_RADIO_TEXT_ON_UPLOAD'))}
				</div>
				{!disabled && (
					<AmMaterialSelector
						hideMaterialNameSelector={false}
						hiddenMaterialNameSelector={true}
						isButtonSelector={true}
						onChange={(e: PrinterMaterial) => materialChangedUpload(e)}
						disabled={disabled}
						defaultMaterialForTypeArr={[
							defaultPrinterMetal,
							defaultPrinterMaterial
						]}
						defaultPrinterMaterial={defaultChosenMaterial}
						selectClassName="upload-project-material-selector-field"
						qaDataElementNameType={'data-qa-original-material-type-'}
						qaDataElementNameCategory={'data-qa-original-category-'}
						qaDataElementNameMaterial={'data-qa-original-material-'}
					/>
				)}
			</Flexbox>
		</div>
	)
}

export default WithFeatureToggleHOC(
	UploadAmMaterial,
	FeatureComponentId.UPLOAD_WITH_AM_MATERIAL
)
