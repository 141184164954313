export enum ConfigurationResultTypes {
	all = 0,
	BestMatch = 1,
	Cheapest = 2,
	InHouse = 3,
	Desktop = 4,
	WeightReduction = 5,
	PolymerAlternative = 6,
	PartConsolidation = 7,
	Casting = 8,
	Lathe = 9,
	LatheSplit = 10,
	Challenges = 11
}
