import React, { FC, useEffect, useRef, useState } from 'react'
import { browserName } from 'react-device-detect'
import { useReactToPrint } from 'react-to-print'

import { IPartsToPrintSummary } from '../../ProjectAnalysisSuggestionInterfaces'
import { TabsItem } from '../../ProjectAnalysisSuggestionService'
import { pageStyle } from './pdfPageStyles'
import ProjectPDF from './ProjectPDF'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import Loader from 'Scenes/Loader/Loader'
import { Chrome, pdfBuggyVersionOfChrome } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

import './ProjectExportPDF.scss'

type IProps = {
	showModal: boolean
	closeModal: () => void
	isBundle: boolean
	projectId: string
	selectedFilterValues: string[]
	resultType: number
	selectedTab: TabsItem
	searchPhrase: string
	projectName: string
	opportunitiesData: Record<string, number>
	partsToPrintLoading: boolean
	totalPartsCount: number
	partsToPrintSummary: IPartsToPrintSummary[]
	partsToPrint: Part[]
	leadingConfigurationData: IBestMatchData[]
	shouldRequestAllParts: boolean
	sortBy: string
	paginationData: any
	sortingCriteria: string
}

const ProjectPdfModal: FC<IProps> = ({
	showModal,
	closeModal,
	isBundle,
	projectId,
	selectedFilterValues,
	searchPhrase,
	projectName,
	resultType,
	selectedTab,
	opportunitiesData,
	partsToPrintLoading,
	totalPartsCount,
	partsToPrint,
	leadingConfigurationData,
	shouldRequestAllParts,
	sortBy,
	sortingCriteria,
	paginationData
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const componentRef = useRef() as React.RefObject<HTMLDivElement>
	const [isBrokenChrome, setIsBrokenChrome] = useState(false)
	useEffect(() => {
		if (browserName === Chrome) {
			const match = /Chrome\/(\d+\.\d+)/.exec(navigator.userAgent)
			if (match && +match[1] === pdfBuggyVersionOfChrome) {
				setIsBrokenChrome(true)
			}
		}
	}, [])

	const isGenerativeDesignOn = Feature.isFeatureOn(
		FeatureComponentId.GENERATIVE_DESIGN
	)
	const handlePrint = useReactToPrint({
		content: () => componentRef?.current,
		onBeforePrint: () => {
			return new Promise(resolve => {
				setTimeout(() => {
					setIsLoading(false)
					resolve(true)
				}, 5000)
			})
		},
		documentTitle: getString('EXPORT_AS_PDF_PROJECT_DOCUMENT_TITLE').format(
			projectName
		),
		pageStyle: () => pageStyle(isBrokenChrome, isGenerativeDesignOn),
		suppressErrors: true,
		onAfterPrint: closeModal,
		onPrintError: (errorLocation, error) => {
			console.error('errorLocation:', errorLocation, 'error:', error)
		}
	})

	const handleConfirm = () => {
		setIsLoading(true)
		handlePrint()
	}

	const onPreventEvent = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
	}

	return (
		<div onClick={onPreventEvent}>
			<CastorAlert
				headerTitle={getString('EXPORT_AS_PDF_MODAL_HEADER')}
				show={showModal}
				onCancel={closeModal}
				onConfirm={handleConfirm}
				preventCancel={isLoading || partsToPrintLoading}
				loadingCalculation={isLoading}
				confirmOptionalText={getString('YES')}
				cancelOptionalText={getString('NO')}
				disabled={partsToPrintLoading}
			>
				<div className="pdf-suggestion--wrapper">
					{partsToPrintLoading ? (
						<Loader
							load={partsToPrintLoading}
							wrapperClassName="pdf-suggestion--wrapper--loader"
							message={getString('EXPORT_AS_PDF_GETTING_PARTS_MESSAGE')}
						/>
					) : (
						<div className="pdf-suggestion--wrapper--text">
							{getString('EXPORT_AS_PDF_MODAL_DESCRIPTION')}
						</div>
					)}
					<div className="pdf-suggestion--wrapper-hidden">
						<div ref={componentRef} className="pdf-suggestion--document">
							<ProjectPDF
								paginationData={paginationData}
								sortBy={sortBy}
								sortingCriteria={sortingCriteria}
								selectedTab={selectedTab}
								resultType={resultType}
								isBundle={isBundle}
								projectId={projectId}
								selectedFilterValues={selectedFilterValues}
								searchPhrase={searchPhrase}
								projectName={projectName}
								opportunitiesData={opportunitiesData}
								totalPartsCount={totalPartsCount}
								partsToPrint={partsToPrint}
								leadingConfigurationData={leadingConfigurationData}
								shouldRequestAllParts={shouldRequestAllParts}
							/>
						</div>
					</div>
				</div>
			</CastorAlert>
		</div>
	)
}

export default ProjectPdfModal
