import React, { memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import cx from 'classnames'

import { ConfigurationResultTypes } from '../../ConfigurationResultTypes'
import { makePartImageGallery } from '../../SolutionAnalysisService'
import IconLoaderGif from 'Scenes/Components/CastorIcons/IconLoaderGif'
import FlexBox from 'Scenes/Components/FlexBox'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import { Info } from 'Scenes/Components/thirdParty/CreativeTim/components'
import AssemblyGallery from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectSuggestionAnalysisChart/components/AssemblyGallery'
import { PART_IMAGES_TABS_INDEX } from 'Services/Constants'
import { FormatType, Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'
import { doesImageDontHaveCoordinates } from 'Services/Utils/ImagesTools'

import coordinateImage from 'assets/img/axes_watermark.png'

import './SolutionPartDetails.scss'

const { STRUCTURAL_LIMITATION_TAB_INDEX } = PART_IMAGES_TABS_INDEX

interface Props {
	partImageSrc: string
	showImageLoader: boolean
	formatType?: string
	solutionPartImageIndex: number
	configurationId: number
	part: Part | null
	sliderChange: (changed: boolean) => void
}

const SolutionPartImage: React.FC<Props> = ({
	partImageSrc,
	showImageLoader,
	solutionPartImageIndex,
	formatType,
	configurationId,
	part,
	sliderChange
}: Props) => {
	const { project: { isCastorTwo = false } = {} } = useSelector(
		(state: RootStateOrAny) => {
			return state?.MainPartAnalysisReducer
		}
	)
	const configuration = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer.states[configurationId]
	})
	const isPdfFile = formatType === FormatType.pdf

	const showGallery =
		(isCastorTwo &&
			configuration?.resultType ===
				ConfigurationResultTypes.PartConsolidation &&
			!!part) ||
		(part?.latheAnalysisData &&
			configuration?.resultType === ConfigurationResultTypes.Lathe)

	let gallery = makePartImageGallery(part, configuration, isCastorTwo)

	const renderImage = () => {
		if (showGallery) {
			return (
				<FlexBox
					flexDirection="column"
					className={cx('project-results--block with-image suggestion-slider', {
						gallery: true
					})}
					styles={{ width: 500 }}
				>
					<AssemblyGallery
						sliderChange={sliderChange}
						inPart={true}
						disableThumbnail={true}
						projectsAssembly={gallery}
					/>
				</FlexBox>
			)
		}

		if (showImageLoader) {
			return (
				<>
					<IconLoaderGif />
					<Info>{getString('PART_ANALYSIS_WALL_THICKNESS_ANALYZE')}</Info>
				</>
			)
		}
		return (
			<>
				{doesImageDontHaveCoordinates(partImageSrc) && (
					<img
						className="part-image-container-coordinate"
						src={coordinateImage}
						alt="..."
					/>
				)}
				<PartImageWithFallback
					formatType={formatType}
					className="part-image-container-img"
					src={partImageSrc}
					alt="..."
				/>
			</>
		)
	}

	return (
		<>
			<div
				className={`part-image-container-div part-image-container--structural ${
					solutionPartImageIndex === STRUCTURAL_LIMITATION_TAB_INDEX ||
					isPdfFile
						? 'show'
						: ''
				}`}
			>
				{renderImage()}
			</div>
		</>
	)
}

export default memo(SolutionPartImage)
