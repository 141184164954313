import extendedTablesStyle from '../../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle'
import React, { useCallback } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'

import * as CustomizeUserMaterialsActions from '../CustomizeUserMaterialsActions'
import {
	EDIT,
	IN_HOUSE_PRINTERS_ACTION,
	MATERIAL_COST_DELETE_ITEM,
	USER_MATERIAL_NAME,
	USER_PRINTERS_NO_PRINTERS_FOUND
} from '../../../../../Services/Strings.js'
import ItemGrid from '../../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid'
import {
	Button,
	Muted,
	Table
} from '../../../../Components/thirdParty/CreativeTim/components/index'
import { Printer } from 'Services/models/IPrinter'
import { customizeNewPrinter } from 'Services/routeFuncs'

const extendedTablesStyleTSX: any = extendedTablesStyle

interface CustomizeTableProps {
	classes: typeof extendedTablesStyleTSX
	showEdit?: boolean
}

const CustomizeUserPrintersTable = ({
	classes,
	showEdit = true
}: CustomizeTableProps) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const { userId, userPrinters, adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => ({
			userId: state.CustomizeUserMaterialsReducer.userId,
			userPrinters: state.CustomizeUserMaterialsReducer.userId
				? state.CustomizeUserMaterialsReducer.userPrinters
				: state.user.userPrinters,
			adminSelectedOrganizationId:
				state.CustomizeReducer.adminSelectedOrganizationId
		})
	)

	const onDeletePrinterClick = useCallback(
		(itemToDelete: Printer) => {
			dispatch(
				CustomizeUserMaterialsActions.onDeletePrinterClick(
					itemToDelete,
					userId,
					adminSelectedOrganizationId
				)
			)
		},
		[dispatch, userId, adminSelectedOrganizationId]
	)

	const renderActionButtons = useCallback(
		(item: Printer) => {
			return (
				<>
					{showEdit && (
						<Button
							title={EDIT}
							onClick={() => {
								history.push(customizeNewPrinter(userId), {
									isEdit: true,
									printer: {
										...item,
										companies: item.company,
										company: item.company
									}
								})
							}}
							color="transparent"
							style={{ margin: '0 10px 0 0', padding: 'unset' }}
							customClass={classes.actionButton}
						>
							<EditIcon className={classes.iconEdit} />
						</Button>
					)}

					<Button
						title={MATERIAL_COST_DELETE_ITEM}
						onClick={() => onDeletePrinterClick(item)}
						color="dangerNoBackground"
						style={{ margin: 'unset', padding: 'unset' }}
						customClass={classes.actionButton}
					>
						<Close className={classes.icon} />
					</Button>
				</>
			)
		},
		[history, userId, onDeletePrinterClick, classes]
	)

	const renderUserPrintersTblData = useCallback(() => {
		if (!userPrinters.length) {
			return [['', <Muted>{USER_PRINTERS_NO_PRINTERS_FOUND}</Muted>, '']]
		}

		return userPrinters.map((userPrinter: Printer) => [
			userPrinter.name,
			renderActionButtons(userPrinter)
		])
	}, [userPrinters, renderActionButtons])

	const tableHead = [USER_MATERIAL_NAME, IN_HOUSE_PRINTERS_ACTION]
	if (!userPrinters.length) {
		tableHead.splice(1, 0, '')
	}

	return (
		<ItemGrid
			data-qa={'data-qa-3d-printers-table'}
			classes={{ grid: 'customize-user-material--table-wrapper' }}
		>
			<Table
				editTableStyle={'materialCostTableCell'}
				tableHead={tableHead}
				tableData={renderUserPrintersTblData()}
			/>
		</ItemGrid>
	)
}

export default withStyles(extendedTablesStyleTSX)(CustomizeUserPrintersTable)
