import { FC, memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { isBoolean, isEmpty } from 'lodash'

import {
	removeUpdatingProjectBanner,
	showLeadingConfigurationChangedAlert
} from '../ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysisActions'
import ProjectSuggestionBundle from '../ProjectBundle/ProjectSuggestionBundle'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import { clearRoutingPoller, clearUploadMessages } from 'Scenes/Home/NewUploadProject/UploadProjectActions'
import ProjectAnalysis from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisPage/ProjectAnalysis'
import ProjectSuggestion from 'Scenes/Home/ProjectAnalysis/ProjectSuggestionPage/ProjectAnalysisSuggestion'
import ProjectBundleAnalysis from 'Scenes/Home/ProjectBundle/ProjectAnalysisBundle'
import { getProjectInformation } from 'Scenes/Home/ProjectPage/ProjectPageAction'
import { leadingPopupLSKey } from 'Services/Constants'
import {
	getJsonItemFromLocalStorage,
	removeItemFromLocalStorage
} from 'Services/LocalStorageService'
import { Feature, FeatureComponentId } from 'Services/models/Features'

const ProjectPage: FC = ({ ...props }) => {
	const { projectId } = useParams() as { projectId: string }
	const dispatch = useDispatch()
	const projectBundle = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_BUNDLE_PAGE
	)
	const { folderId, isBundle, isCastorTwo } = useSelector(
		(state: RootStateOrAny) => state.ProjectPageReducer
	)

	useEffect(() => {
		if (projectId) {
			dispatch(getProjectInformation(projectId))
			dispatch(clearRoutingPoller())
		}
		return () => {
			dispatch(clearUploadMessages(projectId))
			dispatch(removeUpdatingProjectBanner())
		}
	}, [projectId])

	useEffect(() => {
		const partNamesWithNewLeadingConfigurations = getJsonItemFromLocalStorage(
			leadingPopupLSKey(projectId)
		)
		if (!isEmpty(partNamesWithNewLeadingConfigurations)) {
			dispatch(
				showLeadingConfigurationChangedAlert(
					partNamesWithNewLeadingConfigurations
				)
			)
			removeItemFromLocalStorage(leadingPopupLSKey(projectId))
		}
	}, [dispatch, projectId])

	const showProjectPage = useMemo(
		() =>
			folderId === projectId && isBoolean(isBundle) && isBoolean(projectBundle),
		[folderId, projectId, isBundle, projectBundle]
	)

	if (showProjectPage) {
		if (isCastorTwo) {
			return (
				<ErrorBoundary
					extra="ProjectSuggestionPage"
					withButton
					errorClass="full-page"
				>
					{isBundle && projectBundle ? (
						<ProjectSuggestionBundle />
					) : (
						<ProjectSuggestion {...props} />
					)}
				</ErrorBoundary>
			)
		}

		return (
			<ErrorBoundary extra="ProjectPage" withButton errorClass="full-page">
				{isBundle && projectBundle ? (
					<ProjectBundleAnalysis />
				) : (
					<ProjectAnalysis {...props} />
				)}
			</ErrorBoundary>
		)
	}

	return <></>
}

export default memo(ProjectPage)
