import { FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'

import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import HeaderCard from '../../Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import LicenseDropZone from './LicenseDropZone'
import { getLicenseMessage, LicenseData } from './LicenseManagerService'
import { UPLOAD_LICENSE_SCRIPT } from 'global actions/types'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import {
	SKIP_KEY_CODE,
	SSO_KEY_CODE
} from 'Scenes/Home/OnboardingWizard/constants'
import { SKIP_LICENSE_LS_KEY } from 'Services/Constants'
import { LOGIN_ROUTE } from 'Services/Constants/RoutesConstants'
import { useKeyDown } from 'Services/CustomHooks/useSkipKeyDown'
import {
	getJsonItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { LICENSE_UPDATE } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './index.scss'

const { cardBoxColor } = getTheme()

interface ILocationState {
	licenseData: LicenseData
}

const License: FC = () => {
	const [showSSO, setShowSSO] = useState(false)
	const dispatch = useDispatch()
	const history = useHistory()
	const { isLicense } = useSelector(
		(state: RootStateOrAny) => state.LicenseReducer
	)
	const { state: { licenseData } = {} } = useLocation() as {
		state?: ILocationState
	}
	const massageLicense = getLicenseMessage(licenseData)

	useKeyDown(SKIP_KEY_CODE, () => {
		dispatch({
			type: UPLOAD_LICENSE_SCRIPT,
			payload: true
		})
		setStringItemToLocalStorage(SKIP_LICENSE_LS_KEY, 'true')
		history.push(LOGIN_ROUTE)
	})

	useKeyDown(SSO_KEY_CODE, () => setShowSSO(value => !value))

	useEffect(() => {
		const isSkipLicense = getJsonItemFromLocalStorage(SKIP_LICENSE_LS_KEY)
		if (isSkipLicense === true) {
			dispatch({
				type: UPLOAD_LICENSE_SCRIPT,
				payload: true
			})

			history.push(LOGIN_ROUTE)
		}
	}, [dispatch])

	useEffect(() => {
		if (isLicense) history.push(LOGIN_ROUTE)
	}, [isLicense])

	const handleButtonClick = () => history.push('/license/sso')

	return (
		<div className="upload-script">
			<NavBarAndMaterial title={LICENSE_UPDATE}>
				<div className="upload-script-body">
					<HeaderCard
						cardTitle={LICENSE_UPDATE}
						cardSubtitle=""
						headerColor={cardBoxColor || 'green'}
						content={
							<div>
								<br />
								<p className="upload-script-error">{massageLicense}</p>
								<br />
								<Divider />
								<br />
								<LicenseDropZone />
								{showSSO && (
									<ButtonWithLoader
										variant="contained"
										color="primary"
										onClick={handleButtonClick}
									>
										{getString('CONFIGURE_SSO_TEXT')}
									</ButtonWithLoader>
								)}
							</div>
						}
					/>
				</div>
			</NavBarAndMaterial>
		</div>
	)
}

export default memo(License)
