import React, { FC, memo } from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'

import ParametersManagerFields from './ParametersManagerFields'

interface IProps {
	onSubmitClick: any
	parametersUpdateLoading: boolean
	parametersEditableNames: Array<string>
	parametersInfoObject: any
}

interface IFormData {}

const AdminCostingSandCastingForm: FC<
	InjectedFormProps<IFormData, IProps> & IProps
> = ({
	handleSubmit,
	onSubmitClick,
	reset,
	pristine,
	submitting,
	valid,
	parametersUpdateLoading,
	parametersEditableNames,
	parametersInfoObject
}) => {
	return (
		<ParametersManagerFields
			onSubmitClick={onSubmitClick}
			parametersUpdateLoading={parametersUpdateLoading}
			parametersInfoObject={parametersInfoObject}
			parametersEditableNames={parametersEditableNames}
			handleSubmit={handleSubmit}
			valid={valid}
			reset={reset}
			pristine={pristine}
			submitting={submitting}
		></ParametersManagerFields>
	)
}

export default memo(
	reduxForm<IFormData, IProps>({
		form: 'CostingSandCasting',
		enableReinitialize: true
	})(AdminCostingSandCastingForm)
)
