import parseHTML from 'html-react-parser'
import React, { FC, memo } from 'react'

import classnames from 'classnames'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { partResults } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

interface ResultsLabelProps {
	partsCount: number
	printablePartsCount: number
	isAssembly: boolean
	isCostSaving?: boolean
	title: string
	subTitleKey: string
}

const ResultsLabel: FC<ResultsLabelProps> = ({
	partsCount,
	printablePartsCount,
	isAssembly,
	isCostSaving,
	title,
	subTitleKey
}) => {
	return (
		<div className="project-results--label--title">
			<div className="project-results--label--title--info">
				<IconFactory
					iconName={isCostSaving ? 'dollar' : partResults.printable}
					className="project-results--label--title--icon"
				/>
				<div className="project-results--label--text">{title}</div>
			</div>

			<div
				className={classnames('project-results--label--info', {
					high: isAssembly
				})}
			>
				<span className="project-results--label--title--out-of">
					{parseHTML(
						getString(subTitleKey).format(
							`<b>${partsCount}</b>`,
							`<b>${printablePartsCount}</b>`
						)
					)}
				</span>
			</div>
		</div>
	)
}

export default memo(ResultsLabel)
