import { MaterialTypeEnum } from 'Services/models/IMaterial'
import {
	BIO_COMPATIBLE,
	DYEING,
	ESD_SUPPORTED,
	EU_REACH,
	FILTER_CORROSION_RESISTANT,
	FLAME_RETARDANT,
	FOOD_SAFE,
	FST,
	MACHINING,
	MATERIAL_TABLE_INFO,
	MATERIAL_TABLE_TEXTS,
	POLISHING,
	PRINTER_MATERIAL_TABLE_TEXTS,
	ROHS,
	SKIN_CONTACT,
	ULTRA_LOW_OUTGASSING
} from 'Services/Strings'

export const printerMaterialTypes = [
	MaterialTypeEnum.plastic,
	MaterialTypeEnum.metal
]
export const alwaysEnabledFields = ['name']
export const MAX_PERCENTAGE_STDEV = 30
export const MIN_VALUE = 0
export const NUMBER = 'number'
export const allowedCategories = ['Stainless Steels', 'Aluminum Alloys']

export const checkErrorValues = [
	{ name: 'name', checkIfEmpty: true },
	{ name: 'subCategory', checkIfEmpty: true },

	{ name: 'type', checkIfEmpty: false },
	{ name: 'category', checkIfEmpty: true },
	{ name: 'printers', checkIfEmpty: true },
	{ name: 'printerCompany', checkIfEmpty: true },
	{
		name: 'ultimateTensileStrength',
		checkIfEmpty: true,
		checkNestedElements: true,
		checkNestedForMinMax: true,
		min: 0,
		max: 100000
	},
	{ name: 'printerTechnologyName', checkIfEmpty: false },
	{ name: 'description', checkIfEmpty: true },
	{
		name: 'wallThickness',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0,
		max: 3.6
	},
	{
		name: 'costPerCubicCM',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0,
		max: 15
	},

	{
		name: 'poissonRatioExt',
		checkIfEmpty: false,
		checkNestedElements: true,
		checkNestedForMinMax: true,
		min: 0,
		max: 1
	},
	{
		name: 'youngsModulusExt',
		checkIfEmpty: true,
		checkNestedElements: true,
		checkNestedForMinMax: true,
		min: 0,
		max: 30000
	},
	{
		name: 'shearModulusExt',
		checkIfEmpty: false,
		checkNestedElements: true,
		checkNestedForMinMax: true,
		min: 0,
		max: 400
	},
	{
		name: 'ultimateTensileStrengthExt',
		checkIfEmpty: true,
		checkNestedElements: true,
		relatedTo: 'yieldStrengthMPaExt',
		checkNestedForMinMax: true,
		min: 0,
		max: 100000
	},
	{
		name: 'yieldStrengthMPaExt',
		checkIfEmpty: true,
		checkNestedElements: true,
		relatedTo: 'ultimateTensileStrengthExt',
		checkNestedForMinMax: true,
		min: 0,
		max: 5000
	},
	{
		name: 'percentElongationAtBreakExt',
		checkIfEmpty: true,
		checkNestedElements: true,
		checkNestedForMinMax: true,
		min: 0,
		max: 1000
	},
	{
		name: 'density',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0,
		max: 10
	},
	{
		name: 'co2perKgMaterial',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0,
		max: 20
	},
	{
		name: 'surfaceFinish',
		checkIfEmpty: true,
		checkIfOnlyMin: true,
		min: 0
	},
	{
		name: 'accuracy',
		checkIfEmpty: true,
		checkIfOnlyMin: true,
		min: 0
	},
	{
		name: 'maximumServiceTemperature',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0,
		max: 4000
	},
	{
		name: 'thermalConductivity',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0,
		max: 4000
	},
	{
		name: 'buildRate',
		checkIfMinMax: true,
		min: 0,
		max: 10000000
	},
	{
		name: 'surfaceFinishStd',
		checkIfEmpty: true,
		deviationFor: 'surfaceFinish'
	},
	{
		name: 'accuracyStd',
		checkIfEmpty: true,
		deviationFor: 'accuracy'
	},
	{
		name: 'maximumServiceTemperatureStd',
		checkIfEmpty: true,
		deviationFor: 'maximumServiceTemperature'
	},
	{
		name: 'thermalConductivityStd',
		checkIfEmpty: true,
		deviationFor: 'thermalConductivity'
	}
]

export const standardPrinterMaterials = [
	{ name: 'name', label: PRINTER_MATERIAL_TABLE_TEXTS.NAME },
	{ name: 'printers', label: 'printers', isPrintersSelectedItems: true },
	{ name: 'selectedTechItems', label: '', isTechSelectedItems: true },
	{ name: 'selectedTypeItems', label: '', isTypeSelectedItems: true },
	{
		name: 'category',
		label: MATERIAL_TABLE_TEXTS.CATEGORY,
		isCategoryAndSubCategory: true
	},
	{
		name: 'wallThickness',
		label: MATERIAL_TABLE_TEXTS.WALL_THICKNESS,
		type: NUMBER,
		iIcon: MATERIAL_TABLE_INFO.WALL_THICKNESS
	},
	{
		name: 'description',
		label: MATERIAL_TABLE_TEXTS.DESCRIPTION,
		multiline: true
	},
	{
		name: 'surfaceFinish',
		label: MATERIAL_TABLE_TEXTS.SURFACE_FINISH,
		isMetalOnly: true,
		type: NUMBER,
		hasStandardDeviation: true
	},
	{
		name: 'accuracy',
		label: MATERIAL_TABLE_TEXTS.ACCURACY,
		type: NUMBER,
		hasStandardDeviation: true
	},
	{
		name: 'maximumServiceTemperature',
		label: MATERIAL_TABLE_TEXTS.SERVICE_TEMPERATURE,
		isPlasticOnly: true,
		type: NUMBER,
		hasStandardDeviation: true,
		unitSystem: true
	},
	{
		name: 'thermalConductivity',
		label: MATERIAL_TABLE_TEXTS.THERMAL_CONDUCTIVITY,
		type: NUMBER,
		isMetalOnly: true,
		hasStandardDeviation: true
	},
	{
		name: 'density',
		label: MATERIAL_TABLE_TEXTS.DENCITY,
		type: NUMBER,
		iIcon: MATERIAL_TABLE_INFO.DENCITY
	},
	{
		name: 'costPerCubicCM',
		label: MATERIAL_TABLE_TEXTS.COST.replace(`\{${0}\}`, '$'), // format('$') doesn't work for nested text object
		type: NUMBER,
		withSign: true,
		iIcon: MATERIAL_TABLE_INFO.COST
	},
	{
		name: 'co2perKgMaterial',
		label: MATERIAL_TABLE_TEXTS.CO2_PER_KG,
		type: NUMBER,
		isCO2Input: true
	},
	{
		name: 'buildRate',
		label: PRINTER_MATERIAL_TABLE_TEXTS.MAX_BUILD_RATE,
		type: NUMBER,
		iIcon: MATERIAL_TABLE_INFO.BUILD_RATE
	}
]

export const deviationPrinterMaterials = [
	{
		name: 'youngsModulusExt',
		label: PRINTER_MATERIAL_TABLE_TEXTS.YOUNGS_MODULUS_EXT
	},
	{
		name: 'poissonRatioExt',
		label: PRINTER_MATERIAL_TABLE_TEXTS.POISSON_RATIO_EXT
	},
	{
		name: 'shearModulusExt',
		label: PRINTER_MATERIAL_TABLE_TEXTS.SHEAR_MODULUS_EXT
	},
	{
		name: 'ultimateTensileStrengthExt',
		label: PRINTER_MATERIAL_TABLE_TEXTS.ULTIMATE_TENSILE
	},
	{
		name: 'yieldStrengthMPaExt',
		label: PRINTER_MATERIAL_TABLE_TEXTS.YIELD_STRENGTH_EXT
	},
	{
		name: 'percentElongationAtBreakExt',
		label: PRINTER_MATERIAL_TABLE_TEXTS.ELONGATION
	}
]

export const additionalPostProcesses = {
	name: 'additionalPostProcesses',
	label: MATERIAL_TABLE_TEXTS.ADDITIONAL_POST_PROCESSES,
	checkBoxes: [
		{
			id: 1,
			name: 'dyeing',
			label: DYEING,
			typeNameShow: MaterialTypeEnum.plastic
		},
		{
			id: 2,
			name: 'polishing',
			label: POLISHING,
			typeNameShow: MaterialTypeEnum.plastic
		},
		{
			id: 4,
			name: 'machining',
			label: MACHINING,
			defaultValue: true,
			typeNameShow: MaterialTypeEnum.metal
		}
	]
}

export const specialMaterial = {
	name: 'specialMaterial',
	label: MATERIAL_TABLE_TEXTS.SPECIAL_MATERIAL,
	checkBoxes: [
		{ name: 'foodSafe', label: FOOD_SAFE, defaultChecked: false },
		{ name: 'bioCompatible', label: BIO_COMPATIBLE, defaultChecked: false },
		{ name: 'esd', label: ESD_SUPPORTED, defaultChecked: false },
		{ name: 'flameRetardant', label: FLAME_RETARDANT, defaultChecked: false },
		{ name: 'euReach', label: EU_REACH, defaultChecked: true },
		{ name: 'rohs', label: ROHS, defaultChecked: true },
		{ name: 'fst', label: FST, defaultChecked: false },
		{ name: 'skinContact', label: SKIN_CONTACT, defaultChecked: false },
		{
			name: 'ultraLowOutgassing',
			label: ULTRA_LOW_OUTGASSING,
			defaultChecked: false
		},
		{
			name: 'corrosionResistant',
			label: FILTER_CORROSION_RESISTANT,
			defaultChecked: false
		}
	]
}
