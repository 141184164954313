import React, { memo, ReactNode } from 'react'

import cx from 'classnames'
import { isString } from 'lodash'

import './DetailWithTitle.scss'

interface Props {
	title: string | ReactNode
	body: string | ReactNode
	description?: string
	className?: string
	titleClassName?: string
	titleWrapperClassName?: string
	bodyClassName?: string
	children?: any
	icon?: any
	extraBodyClassName?: string
	extraBodyClassText?: string | ReactNode
	qaDataElementName?: string
}

const DetailWithTitle: React.FC<Props> = ({
	title,
	body,
	description,
	className,
	bodyClassName,
	titleClassName,
	titleWrapperClassName,
	children,
	extraBodyClassName,
	extraBodyClassText,
	icon,
	qaDataElementName
}) => {
	return (
		<div className={cx('castor-detail', className)} title={description || ''}>
			<div className={titleWrapperClassName}>
				{children}
				<div className={cx('castor-detail-title', titleClassName)}>{title}</div>
			</div>
			<div className={cx('castor-detail-body', bodyClassName)}>
				<div className={cx('castor-detail-body--text', { icon })}>
					{icon || ''}
					<span
						title={isString(body) ? body : ''}
						data-qa={qaDataElementName || `data-qa-${title}-body`}
					>
						{body}
					</span>
				</div>
				<span
					className={cx(
						'castor-detail-body--extra-details',
						extraBodyClassName
					)}
				>
					{extraBodyClassText}
				</span>
			</div>
		</div>
	)
}

export default memo(DetailWithTitle)
