import React, { FC, memo } from 'react'

import classnames from 'classnames'
import { VictoryPie } from 'victory'

import ProjectResultDistributionCustomLabel from '../ProjectResultDistributionCustomLabel'

const VictoryPieTSX: any = VictoryPie

interface CreateChartProps {
	activeItem?: any
	chartData: any
	isAssembly: boolean
	partResultHovered: Function
}

const CreateChart: FC<CreateChartProps> = ({
	chartData,
	isAssembly,
	partResultHovered,
	activeItem
}) => {
	let hovered: number | null = null

	if (!chartData) {
		return <div />
	}

	if (activeItem) {
		hovered = chartData.findIndex((item: any) => item.name === activeItem)
	}

	return (
		<div
			className={classnames('project-results--cost-saving-chart', {
				'limited-height': isAssembly
			})}
		>
			<VictoryPieTSX
				labelComponent={
					<ProjectResultDistributionCustomLabel
						active={({ index }: { index: number }) => index === hovered}
					/>
				}
				data={chartData}
				style={{
					labels: { fill: 'dark gray', fontSize: 19 },
					parent: { maxWidth: 265 },
					data: {
						stroke: ({ index }: { index: number }) =>
							index === hovered ? 'white' : 'none',
						strokeWidth: ({ index }: { index: number }) =>
							index === hovered ? 3 : 1,
						filter: ({ index }: { index: number }) =>
							index === hovered
								? 'drop-shadow(0 0 3px rgba(21, 157, 190, 0.6))'
								: 'none'
					}
				}}
				innerRadius={80}
				radius={125}
				colorScale={chartData.map((data: any) => data.color)}
				events={[
					{
						target: 'data',
						eventHandlers: {
							onMouseEnter: () => {
								return [
									{
										mutation: (props: any) =>
											partResultHovered(props.datum.name)
									}
								]
							},
							onMouseLeave: () => {
								return [
									{
										mutation: () => partResultHovered('')
									}
								]
							}
						}
					}
				]}
			/>
		</div>
	)
}

export default memo(CreateChart)
