import { FC, memo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Element } from 'react-scroll'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import EditIcon from '@material-ui/icons/Edit'
import cx from 'classnames'

import { ReanalyzingStatus } from '../SideBarTypes'
import LinkItemStatus from './LinkItemStatus'
import UpdateProjectName from './UpdateProjectName'
import { onUpdateProjectName, updateEditId } from 'global actions'
import Flexbox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'

const ElementTSX: any = Element
const ListItemTSX: any = ListItem
const ListItemTextTSX: any = ListItemText

interface NavLinkItemProps {
	keyProp: string
	collapseItemText: string
	collapseItem: string
	path: string
	navLinkClasses: string
	collapseItemMini: string
	mini?: string
	name: string
	icon: string
	id: string
	popperDirection?: any
	reanalyzingStatus?: string
}

const NavLinkItem: FC<NavLinkItemProps> = ({
	keyProp,
	collapseItem,
	path,
	navLinkClasses,
	collapseItemMini,
	mini,
	name,
	collapseItemText,
	icon,
	id,
	reanalyzingStatus,
	popperDirection
}) => {
	const itemId = path?.split('/')[3]
	const Icon: JSX.Element | any = icon
	const { editId } = useSelector((state: RootStateOrAny) => state.userHome)
	const dispatch = useDispatch()

	return (
		<ElementTSX
			key={`scrollToMenu_${itemId + keyProp}`}
			id={`scrollToMenu_${itemId}`}
			name={`scrollToMenu_${itemId}`}
		>
			<ListItemTSX
				id={itemId}
				className={cx(collapseItem, {
					'sidebar-with-icon': !!icon
				})}
			>
				{editId === id && reanalyzingStatus === ReanalyzingStatus.complete ? (
					<div className="sidebar--projects-toolbar--new-folder--input">
						<UpdateProjectName
							oldProjectName={name}
							id={id}
							onUpdateName={onUpdateProjectName}
							placeholder={getString('EDIT_PROJECT_PLACEHOLDER')}
						/>
					</div>
				) : (
					<NavLink
						to={path}
						className={cx(navLinkClasses, 'sidebar--user-folder--link')}
					>
						{icon ? <Icon /> : <></>}
						{mini && <span className={collapseItemMini}>{mini}</span>}
						<Flexbox alignItems="center" justifyContent="space-between">
							<ListItemTextTSX
								title={name}
								disableTypography={true}
								className={`${collapseItemText} sidebar--text`}
								data-qa={`data-qa-side-bar-${name}`}
								primary={
									<div
										className="sidebar--user-folder--folder-name-wrapper"
										data-qa={`data-qa-sidebar-folder-${name}`}
									>
										<span>{name}</span>
										{reanalyzingStatus === ReanalyzingStatus.complete && (
											<EditIcon
												data-qa={`data-qa-sidebar-remove-folder-${name}`}
												onClick={(e: React.MouseEvent) => {
													e.preventDefault()
													dispatch(updateEditId(id))
												}}
												className={cx(
													'clear-input',
													'sidebar--user-folder--delete-or-edit-icon'
												)}
											/>
										)}
									</div>
								}
							/>
							{reanalyzingStatus && (
								<LinkItemStatus
									reanalyzingStatus={reanalyzingStatus}
									popperDirection={popperDirection}
								/>
							)}
						</Flexbox>
					</NavLink>
				)}
			</ListItemTSX>
		</ElementTSX>
	)
}

export default memo(NavLinkItem)
