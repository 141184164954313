import parseHTML from 'html-react-parser'
import { FC, memo } from 'react'

import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import StandardCostAlert from 'Scenes/Components/StandardCost/StandardCostAlert'
import {
	DELETE_ITEM,
	NO,
	OK,
	YES
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const ProjectAlerts: FC<any> = ({
	showingSimpleAlertText,
	showingSimpleAlertTitle,
	removeAlert,
	showRemovePartAlert,
	onRemovePartCancel,
	onRemovePartConfirm,
	requestedRemovePartName,
	loadingRemovePart,
	requestedRemovePartId,
	onlyPart,
	showStandardCostAlert,
	onStandardCostClick,
	partsStandardCosts,
	onPartsStandardCostUpdated,
	projectId,
	updateStandardCostLoading,
	disableStandardCostSubmit,
	onPartsStandardConfirmed,
	showStandardCostError,
	showStandardCostNumberError,
	showNameDuplicationError,
	partsStandardCostsBeforeChanges,
	isBundle
}) => {
	return (
		<>
			<CastorAlert
				show={showingSimpleAlertText || showingSimpleAlertTitle}
				headerTitle={showingSimpleAlertTitle}
				onConfirm={removeAlert.bind(this)}
				onCancel={removeAlert.bind(this)}
				showCancel={false}
				confirmOptionalText={OK}
			>
				{showingSimpleAlertText}
			</CastorAlert>
			<CastorAlert
				headerTitle={DELETE_ITEM}
				onCancel={onRemovePartCancel}
				show={showRemovePartAlert}
				onConfirm={() =>
					onRemovePartConfirm(
						requestedRemovePartId,
						onlyPart.externalId,
						isBundle
					)
				}
				cancelOptionalText={NO}
				confirmOptionalText={YES}
				loadingCalculation={loadingRemovePart}
				alertType={AlertType.WARNING}
			>
				{parseHTML(
					getString('PROJECT_SUGGESTIONS_DELETE_PART_ALERT').format(
						`<span title="${requestedRemovePartName}" className="ellipsis-text">${requestedRemovePartName}</span>`
					)
				)}
			</CastorAlert>
			<StandardCostAlert
				showStandardCostAlert={showStandardCostAlert}
				onCancel={onStandardCostClick}
				partsStandardCosts={partsStandardCosts}
				onDataUpdated={(data: any) => onPartsStandardCostUpdated(data)}
				updateStandardCostLoading={updateStandardCostLoading}
				disableStandardCostSubmit={disableStandardCostSubmit}
				onConfirm={() =>
					onPartsStandardConfirmed(
						partsStandardCosts,
						projectId,
						partsStandardCostsBeforeChanges
					)
				}
				showStandardCostError={showStandardCostError}
				showStandardCostNumberError={showStandardCostNumberError}
				showNameDuplicationError={showNameDuplicationError}
			/>
		</>
	)
}

export default memo(ProjectAlerts)
