import React, { useCallback, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { formValueSelector } from 'redux-form'

import { customizeUserMaterial } from '../../../../../Services/routeFuncs'
import {
	CUSTOMIZE_FORM_USER_PRINTER_HEADER,
	NAV_TITLE_CUSTOMIZE_USER
} from '../../../../../Services/Strings'
import CastorForm from '../../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../../Components/NavBarAndMaterial'
import {
	createNewPrinterAndRemovePrinterName,
	newCompanySelected,
	onMaintenanceMachineCostFieldsSelectorNewPrinterChange,
	resetData
} from '../CustomizeUserMaterialsActions'
import { companies, technology } from './constants'
import PrinterForm from 'Scenes/Components/PrinterForm/PrinterForm'
import { Material } from 'Services/models/IMaterial'

const NewPrinter: React.FC = () => {
	const dispatch = useDispatch()
	const location = useLocation<any>()

	const {
		printerAdded,
		userId,
		isNewCompanyDisabled,
		selectedPrinterCompany,
		selectedPrinterTechnology,
		maintenanceMachineSelectorsValueNewPrinter,
		onMachineCostValue,
		onMachineCostSelector,
		selectedNewPrinter,
		printingTechnologies,
		addingNewItem,
		printersFullData,
		adminSelectedOrganizationId
	} = useSelector((state: RootStateOrAny) => {
		const selector = formValueSelector('adminPrinterFormState')
		return {
			printerAdded: state.CustomizeUserMaterialsReducer.printerAdded,
			userId: state.CustomizeUserMaterialsReducer.userId,
			isNewCompanyDisabled:
				state.CustomizeUserMaterialsReducer.isNewCompanyDisabled,
			selectedPrinterCompany: selector(state, companies),
			selectedPrinterTechnology: selector(state, technology),
			maintenanceMachineSelectorsValueNewPrinter:
				state.CustomizeUserMaterialsReducer
					.maintenanceMachineSelectorsValueNewPrinter,
			onMachineCostValue:
				state.CustomizeUserMaterialsReducer.onMachineCostValue,
			onMachineCostSelector:
				state.CustomizeUserMaterialsReducer.onMachineCostSelector,
			selectedNewPrinter:
				state.CustomizeUserMaterialsReducer.selectedNewPrinter,
			printingTechnologies: state.user.printingTechnologies,
			addingNewItem: state.CustomizeUserMaterialsReducer.addingNewItem,
			printersFullData: state.user.printersFullData,
			adminSelectedOrganizationId:
				state.CustomizeReducer.adminSelectedOrganizationId
		}
	})

	const resetDataAction = useCallback(() => {
		dispatch(resetData())
	}, [dispatch])

	useEffect(() => {
		resetDataAction()
	}, [resetDataAction])

	const onSubmit = useCallback(
		(data: Material) => {
			const isEdit = location.state?.isEdit || false
			dispatch(
				createNewPrinterAndRemovePrinterName(
					data,
					adminSelectedOrganizationId,
					isEdit
				)
			)
		},
		[dispatch, adminSelectedOrganizationId, location.state]
	)

	const renderFormContent = () => {
		const initialValues = location.state?.printer || selectedNewPrinter

		return (
			<PrinterForm
				selectedPrinterTechnology={selectedPrinterTechnology}
				isNewCompanyDisabled={isNewCompanyDisabled}
				onSubmit={onSubmit}
				newCompanySelected={(company: string) =>
					dispatch(newCompanySelected(company))
				}
				selectedPrinterCompany={selectedPrinterCompany}
				onMaintenanceMachineCostFieldsSelectorChange={(event: Event) =>
					dispatch(
						onMaintenanceMachineCostFieldsSelectorNewPrinterChange(event)
					)
				}
				onMachineCostSelector={onMachineCostSelector}
				maintenanceMachineCostFieldsSelectorValue={
					maintenanceMachineSelectorsValueNewPrinter
				}
				onMachineCostValue={onMachineCostValue}
				initialValues={initialValues}
				printingTechnologies={printingTechnologies}
				addingNewItem={addingNewItem}
				printersFullData={printersFullData}
				isAdminForm={false}
			/>
		)
	}

	if (printerAdded) {
		return <Redirect to={customizeUserMaterial(userId)} />
	}

	return (
		<NavBarAndMaterial title={NAV_TITLE_CUSTOMIZE_USER}>
			<CastorForm
				formTitle={CUSTOMIZE_FORM_USER_PRINTER_HEADER}
				content={renderFormContent()}
			/>
		</NavBarAndMaterial>
	)
}

export default NewPrinter
