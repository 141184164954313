import React, { FC, memo, ReactNode, useEffect, useRef, useState } from 'react'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css'

import cx from 'classnames'

import { generateKey } from 'Services/Utils/uniqKeyGenerator'

import './DataSheet.scss'

const MyReactDataSheet: any = ReactDataSheet

export interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
	value: any
	valid: boolean
	component?: any
}

interface IProps {
	data: GridElement[][]
	headers: (string | React.ReactNode)[]
	containerClassName: string
	onDataUpdated: (data: GridElement[][]) => any
}

let cellRenderer: ReactDataSheet.CellRenderer<GridElement, number> = (
	props: any
) => {
	const {
		cell: { valid },
		className,
		onMouseDown,
		onMouseOver,
		onDoubleClick,
		children
	} = props
	return (
		<td
			onMouseDown={onMouseDown}
			onMouseOver={onMouseOver}
			onDoubleClick={onDoubleClick}
			className={cx(className, { 'data-sheet--cell-not-valid': valid })}
			data-qa="data-qa-table-cell"
		>
			{children}
		</td>
	)
}

const DataSheet: FC<IProps> = ({
	data,
	headers,
	onDataUpdated,
	containerClassName
}) => {
	const [grid, setGrid] = useState<GridElement[][]>([])
	const [scrollPosition, setScrollPosition] = useState<number>()
	const listRef = useRef<HTMLDivElement>()

	useEffect(() => {
		setGrid(data.map((row: GridElement[]) => [...row]))
	}, [data])

	useEffect(() => {
		listRef.current?.scrollTo({ top: scrollPosition })
	})

	const onCellsChange = (
		changes: ReactDataSheet.CellsChangedArgs<GridElement, number>
	) => {
		const myGrid = grid.map((row: GridElement[]) => [...row])
		changes.forEach(({ cell, row, col, value }) => {
			myGrid[row][col] = { ...myGrid[row][col], value }
		})
		setGrid(myGrid)
		onDataUpdated(myGrid)
		setScrollPosition(listRef?.current?.scrollTop || 0)
	}

	const renderSheet = (props: any) => {
		const { className, onColumnDrop, children } = props
		return (
			<div
				// @ts-ignore
				ref={listRef}
				className={`data-sheet--container ${containerClassName}`}
			>
				<table className={className}>
					<thead>
						<tr>
							{headers.map((col: string | ReactNode, index: number) => (
								<th className={'cell read-only'} key={generateKey(index)}>
									{col}
								</th>
							))}
						</tr>
					</thead>
					<tbody>{children}</tbody>
				</table>
			</div>
		)
	}

	return (
		<MyReactDataSheet
			data={grid}
			className="data-sheet"
			valueRenderer={(cell: GridElement) => cell.value}
			onCellsChanged={onCellsChange}
			sheetRenderer={renderSheet}
			cellRenderer={cellRenderer}
		/>
	)
}

export default memo(DataSheet)
