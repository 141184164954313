import { uniqBy } from 'lodash'

import { defaultPrinterMetal } from '../../../../Services/Constants'
import { Material } from 'Services/models/IMaterial'
import { getTheme } from 'themes/getTheme'

const { defaultPrinterMaterial } = getTheme()

export class AmMaterialSelectorService {
	subCategories: Array<{ name: string } & any>
	categoriesList: Array<string>
	materialTypesList: Array<string>
	chosenSubCategory: any
	chosenCategory: any
	chosenType: any
	default: any
	defaultMaterialForTypeArr: Array<any> = [
		defaultPrinterMaterial,
		defaultPrinterMetal
	]
	constructor(
		private printerMaterialsData: Array<any>,
		type: string,
		category: string,
		initialSubCategory: any,
		private printerMaterialsSubCategories: any[]
	) {
		this.default = this.defaultMaterialForTypeArr[0]
		this.printerMaterialsData = printerMaterialsData
		this.chosenType = type || this.default.type
		this.chosenCategory = category?.toLowerCase() || this.default.category
		this.chosenSubCategory = initialSubCategory

		this.categoriesList = generateMaterialCategories(
			printerMaterialsData,
			this.chosenType
		)

		this.subCategories = filteredMaterialsByCategory(
			printerMaterialsData,
			this.chosenCategory
		)

		this.subCategories = uniqBy(this.subCategories, 'subCategory')
		this.subCategories = this.subCategories.sort((a: any, b: any) => {
			if (a.subCategory < b.subCategory) {
				return -1
			}
			if (a.subCategory > b.subCategory) {
				return 1
			}
			return 0
		})

		this.materialTypesList = generateMaterialTypes(printerMaterialsData)
	}

	getDataList() {
		return {
			amMaterialTypesList: this.materialTypesList,
			amCategoriesList: this.categoriesList,
			amSubCategories: this.subCategories
		}
	}
	getDefault(fieldType: 'type' | 'category' | 'material', fieldValue: string) {
		return this.defaultMaterialForTypeArr.find(
			material => material[fieldType] == fieldValue
		)
	}
	materialTypeChanged = (value: any) => {
		const defaultMaterial = this.getDefault('type', value)
		const newMaterialsCategoriesList = generateMaterialCategories(
			this.printerMaterialsData,
			value
		)

		this.categoriesList = newMaterialsCategoriesList
		const newMaterials = filteredMaterialsByCategory(
			this.printerMaterialsData,
			defaultMaterial?.category?.toLowerCase() || this.categoriesList[0]
		)

		this.subCategories = newMaterials
		this.subCategories = uniqBy(this.subCategories, 'subCategory')
		this.subCategories = this.subCategories.sort((a: any, b: any) => {
			if (a.subCategory < b.subCategory) {
				return -1
			}
			if (a.subCategory > b.subCategory) {
				return 1
			}
			return 0
		})
		this.chosenSubCategory = defaultMaterial || newMaterials[0]
		this.chosenCategory = defaultMaterial?.category || newMaterials[0].category
		this.chosenType = defaultMaterial?.type || newMaterials[0].type

		return {
			chosenAmMaterialType: this.chosenType,
			chosenAmMaterialCategory: this.chosenCategory,
			chosenSubCategory: this.chosenSubCategory
		}
	}
	materialCategoryChanged = (value: any) => {
		const defaultMaterial = this.printerMaterialsSubCategories.find(
			material =>
				material.defaultMaterialSelection?.category?.toLowerCase() ===
				value?.toLowerCase()
		)?.defaultMaterialSelection

		const newMaterials = filteredMaterialsByCategory(
			this.printerMaterialsData,
			defaultMaterial?.category?.toLowerCase() || value
		)

		const newMaterialsCategoriesList = generateMaterialCategories(
			this.printerMaterialsData,
			defaultMaterial?.type || newMaterials[0].type
		)

		this.categoriesList = newMaterialsCategoriesList
		this.subCategories = newMaterials
		this.subCategories = uniqBy(this.subCategories, 'subCategory')
		this.subCategories = this.subCategories.sort((a: any, b: any) => {
			if (a.subCategory < b.subCategory) {
				return -1
			}
			if (a.subCategory > b.subCategory) {
				return 1
			}
			return 0
		})

		this.chosenCategory =
			defaultMaterial?.category?.toLowerCase() ||
			newMaterials[0]?.category?.toLowerCase()
		this.chosenSubCategory = defaultMaterial || newMaterials[0]
		return {
			chosenAmMaterialCategory: this.chosenCategory,
			chosenSubCategory: this.chosenSubCategory
		}
	}

	subCategoryChanged = (value: any) => {
		this.chosenSubCategory = this.printerMaterialsData.find(
			material =>
				value.toLowerCase() === material.subCategory.toLowerCase() &&
				!material.heatTreatment
		)
		return {
			chosenSubCategory: this.chosenSubCategory
		}
	}
	materialChanged = (value: any) => {
		this.chosenSubCategory = this.printerMaterialsData.find(
			material => value == material.id
		)

		return {
			chosenSubCategory: this.chosenSubCategory
		}
	}
}

export const generateMaterialCategories = (
	printerMaterials: any[],
	type: string
) => {
	return printerMaterials
		.map(material => {
			if (material.type?.toLowerCase() === type?.toLowerCase()) {
				return material.category.toLowerCase()
			}
		})
		.filter((category, pos, self) => category && self.indexOf(category) === pos)
		.sort()
}

export const filteredMaterialsByCategory = (
	printerMaterials: any[],
	category: string
) => {
	printerMaterials = printerMaterials.filter(
		(material, pos, self) =>
			self.indexOf(material) == pos &&
			material.category?.toLowerCase() === category?.toLowerCase() &&
			!material.heatTreatment
	)

	printerMaterials = uniqBy(printerMaterials, 'subCategory')
	printerMaterials = printerMaterials.sort((a, b) => {
		if (a.subCategory < b.subCategory) {
			return -1
		}
		if (a.subCategory > b.subCategory) {
			return 1
		}
		return 0
	})
	return printerMaterials
}
export const generateMaterialTypes = (printerMaterials: Material[]) => {
	return printerMaterials
		.map(material => material.type)
		.sort()
		.filter((material, pos, self) => {
			if (self.indexOf(material) == pos) return material
		})
}
