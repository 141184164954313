import React, { FC, useEffect, useMemo, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { SelectedRadioType } from './NewOrCopyConstants'
import NewOrCopyRadioSelector from './NewOrCopyRadioSelector'
import {
	getPrinterByCompany,
	printerCompanyChange,
	returnAllCompaniesAccordingTechnology,
	returnAllTechnologies,
	setupPrinterPropsData
} from './NewOrCopyService'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import SimpleConfigurationSelector from 'Scenes/Components/SimpleConfigurationSelector'
import { customizeNewPrinter } from 'Services/routeFuncs'
import { OK } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface CopyTMMaterialProps {
	show: boolean
	setShow: (show: boolean) => void
}

const PopupPrinter: FC<CopyTMMaterialProps> = ({ show, setShow }) => {
	const [selectedValue, setSelectedValue] = React.useState(
		SelectedRadioType.new
	)
	const [selectedCompany, setSelectedCompany] = useState('')
	const [selectedTechnology, setSelectedTechnology] = useState('')
	const [selectedPinter, setSelectedPrinter] = useState<any>('')
	const history = useHistory()
	const { userId, printingTechnologies, printersCompanies } =
		useSelector((state: RootStateOrAny) => ({
			userId: state.CustomizeUserMaterialsReducer.userId,
			printingTechnologies: state.user.printingTechnologies,
			printersCompanies: state.CustomizeInHousePrintersReducer.printersCompanies
		}))

	const formattedPrintersCompanies = Object.keys(printersCompanies).map(
		companyName => ({
			companyName,
			printers: printersCompanies[companyName]
		})
	)

	useEffect(() => {
		setupPrinterPropsData(formattedPrintersCompanies, printingTechnologies)
	}, [])

	const printerTechnologies = useMemo(
		() => returnAllTechnologies(),
		[formattedPrintersCompanies, printingTechnologies, printersCompanies]
	)

	const printerCompanies = useMemo(
		() => returnAllCompaniesAccordingTechnology(selectedTechnology),
		[selectedTechnology]
	)

	const printersList = useMemo(
		() => printerCompanyChange(selectedCompany),
		[selectedCompany]
	)

	const onSelectTechnology = (value: string) => {
		setSelectedTechnology(value)
		setSelectedCompany('')
		setSelectedPrinter('')
	}

	const onSelectCompany = (value: string) => {
		setSelectedCompany(value)
		setSelectedPrinter('')
	}

	const onConfirm = () => {
		setShow(false)
		if (selectedValue === SelectedRadioType.new) {
			history.push(customizeNewPrinter(userId))
		} else {
			const printer = getPrinterByCompany(
				printersCompanies,
				selectedCompany,
				selectedPinter,
				selectedTechnology
			)

			history.push(customizeNewPrinter(userId), {
				printer: printer
			})
		}
	}

	const onCancel = () => {
		setShow(false)
		setSelectedValue(SelectedRadioType.new)
	}

	return (
		<CastorAlert
			alertClass="customize-material-alert"
			show={show}
			headerTitle={getString('USER_MATERIAL_ADD_NEW')}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmOptionalText={OK}
			disabled={selectedValue === SelectedRadioType.copy && !selectedPinter}
		>
			<NewOrCopyRadioSelector
				selectedValue={selectedValue}
				setSelectedValue={setSelectedValue}
				newLabel={getString('CREATE_NEW_PRINTER_TITLE')}
				copyLabel={getString('CREATE_COPIED_PRINTER_TITLE')}
			/>
			{SelectedRadioType.copy === selectedValue && (
				<div className="add-new-configuration--form printer-material ">
					<SimpleConfigurationSelector
						isNewDesign
						displayAsRow
						printerCompanyValue={selectedCompany}
						printerValue={selectedPinter}
						technologyValue={selectedTechnology}
						technologyList={printerTechnologies}
						companiesList={printerCompanies}
						printersList={printersList}
						showMaterialList={false}
						showTechnologyList={true}
						onTechnologyChange={(value: string) => {
							onSelectTechnology(value)
						}}
						onCompanyChange={(value: string) => {
							onSelectCompany(value)
						}}
						onPrinterChange={(value: string) => {
							setSelectedPrinter(value)
						}}
						selectMenuClassName="printer-material-config--select-menu"
						itemWrapperClassname="select-printer"
					/>
				</div>
			)}
		</CastorAlert>
	)
}

export default PopupPrinter
